import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const DesignStudioSlice = createSlice({
  name: "designStudio",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resourceCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities && [
        ...state.entities,
        action.payload.resource,
      ];
    },
    floorPlansFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.floorPlans = entities;
    },
    poiCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pointsOfInterest = state.pointsOfInterest && [
        ...state.pointsOfInterest,
        action.payload,
      ];
    },
    pointsOfInterestFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.pointsOfInterest = entities;
    },
    pointOfInterestFetchedToUpdate: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.pointOfInterestToUpdate = entities;
    },
    pointOfInterestDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    resourceFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.resourceForEdit = entities;
    },
    resourcesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    resourceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities =
        state.entities &&
        state.entities.map((entity) => {
          if (entity.id === action.payload.resource.id) {
            return action.payload.resource;
          }
          return entity;
        });
    },
    resourceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    resourcesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    zonesInDBFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.zonesInDB = entities;
    },
    resourcesMeetingsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.resourcesMeetings = entities;
    },
  },
});
