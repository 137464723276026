export async function deleteCustomer(customerId) {
   console.log("Delete!")
   var myHeaders = new Headers()
   myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
   myHeaders.append("Content-Type", "application/json")

   const raw = JSON.stringify({
      "customerId": customerId,
      "confirm": "true"
   })

   const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
   }

   await fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/deleteCustomer`, requestOptions)
      .catch(error => console.log('error', error))
}