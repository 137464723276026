import React from "react"

export function Small({ text, color, style }) {
   return <span
      style={{
         ...style,
         ...{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "13px",
            color: color
         }
      }}>
      {text}
   </span>
}
