import React, { useState, useRef } from "react"
import { Formik, Form, Field } from "formik"
import { Input } from "../../../../../_metronic/_partials/controls"
import Button from '@material-ui/core/Button'
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { useDispatch } from "react-redux"

const FifthStep = ({ setForm, formData, navigation }) => {
   const dispatch = useDispatch()
   const formRef = useRef()

   const { next, previous } = navigation
   const { levels } = formData.merakiNetworks[0]

   function handleSubmit(values) {
      var levelsCheck = []
      var map = Object.keys(values).map((key) => {
         var currFloor = levels.find(f => f.floorId == key)
         levelsCheck.push(values[key])
         return {
            ...currFloor,
            netId: key,
            level: values[key]
         }
      })
      if ((new Set(levelsCheck)).size !== levelsCheck.length) {
         dispatch({
            type: "SNACKBAR_ERROR",
            payload: `Levels cannot repeat`,
         })
         return
      }
      var merakiNetworks = [{
         ...formData.merakiNetworks[0],
         levels: map
      }]
      setForm({ target: { name: "data.merakiNetworks", value: merakiNetworks } })
      switch (formData.completedStep) {
         case 4: navigation.go("sixthStep")
            break
         case 5: navigation.go("sevenStep")
            break
         default: setForm({
            target: {
               name: "data.completedStep",
               value: 4
            }
         })
            next()
            break
      }
   }

   var obj = {}
   levels.forEach(val => obj[val.floorId] = val.level)

   return <Card style={{ height: "100%" }}>
      <CardHeader
         title="Assign levels"
         toolbar={<>
            {formData.completedStep < 4 && <Button
               variant="contained"
               color="default"
               style={{ marginRight: "10px" }}
               size="large"
               disableElevation
               onClick={previous}>
               Previous
            </Button>}
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => formRef.current && formRef.current.handleSubmit()}
            >
               {formData.completedStep >= 4 ? "Submit" : "Next"}
            </Button>
         </>}
      />
      <CardBody>
         <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={obj}
            onSubmit={(values) => {
               handleSubmit(values)
            }}
         >
            {() => (
               <Form className="form form-label-right">
                  <div className="form-group row justify-content-center">
                     {levels.map((floor, i) => <div key={i} className="col-12 col-sm-6 col-md-3 mb-4 mb-md-0">
                        <Field
                           type="number"
                           name={floor.floorId}
                           component={Input}
                           label={floor.name}
                           inputStyle={["large"]}
                        />
                     </div>)}
                  </div>
               </Form>
            )}
         </Formik >
      </CardBody>
   </Card>
}

export default FifthStep