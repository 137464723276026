import { path } from "d3-path";

function constant(x) {
  return () => x;
}

function pointX(p) {
  return p[0];
}

function pointY(p) {
  return p[1];
}

function linkSource(d) {
  return d.source;
}

function linkTarget(d) {
  return d.target;
}

function link(curve) {
  let source = linkSource,
    target = linkTarget,
    x = pointX,
    y = pointY,
    additionalParams = constant,
    context = null;

  function link() {
    let buffer;
    if (!context) {
      context = buffer = path();
    }

    const argv = Array.prototype.slice.call(arguments);
    const s = source.apply(this, argv);
    const t = target.apply(this, argv);

    const argvWithoutFirst = argv.slice(1);
    const sourceArgv = [s, ...argvWithoutFirst];
    const targetArgv = [t, ...argvWithoutFirst];
    const x0 = +x.apply(this, sourceArgv);
    const y0 = +y.apply(this, sourceArgv);
    const x1 = +x.apply(this, targetArgv);
    const y1 = +y.apply(this, targetArgv);
    const ap = additionalParams.apply(this, argv);

    curve(context, x0, y0, x1, y1, ap);

    if (buffer) {
      context = null;

      return buffer + "" || null;
    }
  }

  link.source = function(_) {
    return arguments.length ? ((source = _), link) : source;
  };

  link.target = function(_) {
    return arguments.length ? ((target = _), link) : target;
  };

  link.x = function(_) {
    return arguments.length
      ? ((x = typeof _ === "function" ? _ : constant(+_)), link)
      : x;
  };

  link.y = function(_) {
    return arguments.length
      ? ((y = typeof _ === "function" ? _ : constant(+_)), link)
      : y;
  };

  link.context = function(_) {
    return arguments.length
      ? ((context = _ == null ? null : _), link)
      : context;
  };

  link.additionalParams = function(_) {
    return arguments.length
      ? ((additionalParams = typeof _ === "function" ? _ : constant(+_)), link)
      : additionalParams;
  };

  return link;
}

function curveHorizontalThick(context, x0, y0, x1, y1, d) {
  // console.log(d, "d")
  const avgX = (x0 + x1) / 2;
  const strokeSemiWidth = d.width / 2;

  context.moveTo(x0, y0 - strokeSemiWidth);
  context.bezierCurveTo(
    avgX,
    y0 - strokeSemiWidth,
    avgX,
    y1 - strokeSemiWidth,
    x1,
    y1 - strokeSemiWidth
  );
  context.lineTo(x1, y1 + strokeSemiWidth);
  context.bezierCurveTo(
    avgX,
    y1 + strokeSemiWidth,
    avgX,
    y0 + strokeSemiWidth,
    x0,
    y0 + strokeSemiWidth
  );
}

function horizontalSource(d) {
  return [d.source.x1, d.y0];
}

function horizontalTarget(d) {
  return [d.target.x0, d.y1];
}

function identity(value) {
  return value;
}

export default function() {
  return link(curveHorizontalThick)
    .source(horizontalSource)
    .target(horizontalTarget)
    .additionalParams(identity);
}
