import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { FailuresCountChart } from "./FailuresCountChart";

export function FailuresCount({ queryParams }) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "UIProps.queryParams APsWorstSNR");

    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await FailuresCountChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader title='Failures' subtitle='Floors'></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
