import React, { useMemo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/reporting/people/peopleActions";
import { useUIContext } from "../UIContext";
import { PeopleDistribution } from "./Floor/PeopleDistribution";
import { Occupancy } from "./Floor/Occupancy";
import { FloorAverageOccupancy } from "./Floor/FloorAverageOccupancy";
import { ZoneOccupancy } from "./Zone/ZoneOccupancy";
import { ZoneAverageOccupancy } from "./Zone/ZoneAverageOccupancy";
import { ZonePeopleDistribution } from "./Zone/ZonePeopleDistribution";
import { ZoneAvgTimeSpent } from "./Zone/ZoneAvgTimeSpent";
import { ChordDependency } from "./Zone/ChordDependency";
import { SankeyCircularChartPage } from "./Zone/SankeyCircularChartPage";
import { SankeyChart3 } from "./Zone/SankeyChart3";

export function OccupancyReporting() {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const { group, customer } = useSelector(
    (state) => ({
      group: state.persistentReporting?.group,
      customer: state.profile?.currentCustomer,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!customer) return;
    if (group === "floor") {
      dispatch(
        actions.fetchPeopleByFloor({
          customerId: customer.customerId,
          ...UIProps.queryParams,
        })
      );
    }
    if (group === "zone") {
      dispatch(
        actions.fetchPeopleByZone({
          customerId: customer.customerId,
          ...UIProps.queryParams,
        })
      );
    }
  }, [customer, UIProps.queryParams, group]);

  return (
    <div className='row' id='peopleDivToPdf'>
      {group === "floor" && (
        <>
          <div className='col-md-12 col-xxl-4'>
            <PeopleDistribution />
          </div>
          <div className='col-md-12 col-xxl-8'>
            <Occupancy />
          </div>
          <div className='col-12'>
            <FloorAverageOccupancy />
          </div>
        </>
      )}
      {group === "zone" && (
        <>
          <div className='col-12'>
            <ZoneOccupancy />
          </div>
          {/* //!! Chart to delete - Chart not needed ↓ */}
          {/* <div className='col-12'>
          <ZoneOccupancy2ByLevel />
        </div> */}
          <div className='col-12'>
            <ZoneAverageOccupancy />
          </div>
          <div className='col-12'>
            <ZonePeopleDistribution />
          </div>
          <div className='col-12'>
            <ZoneAvgTimeSpent />
          </div>
          <div className='col-12'>
            <ChordDependency />
          </div>
          <div className='col-12'>
            <SankeyChart3 />
          </div>
          <div className='col-12'>
            <SankeyCircularChartPage />
          </div>
        </>
      )}
    </div>
  );
}
