import moment from "moment"

export function getDayFloorData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  startDate.subtract(24, "hour")
  //endDate.subtract(1, "day")
  var SSIDs
  if (queryParams.location.customerType === "office") {
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType === "shop") {
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  const raw = JSON.stringify({
    typechart: "hourlyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: `${startDate.format("YYYY-MM-DD")}T${startDate.format("HH:00:00")}`,
    endDate: `${endDate.format("YYYY-MM-DD")}T${endDate.format("HH:00:00")}`,
  })
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
}

export function getWeekFloorData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "week")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()

  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getMonthFloorData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "month")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getDayZoneData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  //endDate.subtract(1, "day")
  var SSIDs
  if (queryParams.location.customerType === "office") {
    SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    SSIDs && SSIDs.push("")
  } else if (queryParams.location.customerType === "shop") {
    SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.guestSSID))
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "hourlyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getWeekZoneData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "week")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    SSIDs && SSIDs.push("")
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.guestSSID))
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getMonthZoneData(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "month")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    SSIDs && SSIDs.push("")
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.guestSSID))
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getWeekDailyPeople(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "week")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD[T00:00:00]"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getMonthDailyPeople(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "month")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD[T00:00:00]"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getWeekAvgTime(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "week")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "day",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD[T00:00:00]"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}

export function getMonthAvgTime(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  var startDate = new moment()
  var endDate = new moment()
  //startDate.subtract(1, "day")
  startDate.subtract(1, "month")
  //endDate.subtract(1, "day")

  if (queryParams.location.customerType == "office") {
    if (queryParams?.location?.employeeSSID) {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      var SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
  } else if (queryParams.location.customerType == "shop") {
    var SSIDs
    if (queryParams?.location?.employeeSSID) {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.employeeSSID))
    } else {
      SSIDs = JSON.parse(JSON.stringify(queryParams?.location?.SSIDs))
    }
    SSIDs && SSIDs.push("")
  }

  var myHeaders = new Headers()
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
  myHeaders.append("Content-Type", "application/json")
  var raw = JSON.stringify({
    typechart: "day",
    networkId: queryParams.location.netId,
    ssid: SSIDs,
    startDate: moment(startDate).format("YYYY-MM-DD[T00:00:00]"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  })
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${queryParams.customer.customerId}`,
    requestOptions
  ).catch(error => console.log("Error", error))
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${queryParams.customer.customerId}`,
  //   requestOptions
  // )
}
