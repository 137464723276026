import moment from "moment";
import { firestore as db } from "../../../../firebase";
import firebase from "firebase/app";

const customersRef = db.collection("Customers");

const filterSSIDs = (queryParams) => {
  let SSIDs = [];
  if (queryParams.peopleType.includes("visitors")) {
    SSIDs.push("");
  }
  if (queryParams.peopleType.includes("allConnected")) {
    queryParams.locations.forEach((l) => {
      SSIDs = SSIDs.concat(l.SSIDs);
    });
    return SSIDs;
  }
  if (queryParams.peopleType.includes("employees")) {
    queryParams.locations.forEach((l) => {
      if (l.employeeSSID) {
        SSIDs = SSIDs.concat(l.employeeSSID);
      }
    });
  }
  if (queryParams.peopleType.includes("guests")) {
    queryParams.locations.forEach((l) => {
      if (l.guestSSID) {
        SSIDs = SSIDs.concat(l.guestSSID);
      }
    });
  }
  if (!SSIDs) {
    return [];
  }
  return SSIDs;
};

//★━━━━━━━━━━━━━━━━★ PDF Report Wifi ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export async function scheduledReportWifi(queryParams) {
  console.log(queryParams, "queryParams CRUD");
  const {
    data,
    customerId,
    netId,
    user,
    floorPlanId,
    group,
    selectedFloors,
    selectedZones,
    selectedSSIDs,
    reportDate,
    selectedTimeframe,
  } = queryParams;

  // Parse, Convert and add date + time to Firebase Timestamp
  const datePlusTime = moment(data.startDate).format(
    `ddd MMM DD YYYY ${data.startTime}`
  );
  // console.log(datePlusTime, "datePlusTime");
  const datePlusTimeParsed = new Date(datePlusTime);
  // console.log("🚀 ~ datePlusTimeParsed", datePlusTimeParsed);

  const timestamp = firebase.firestore.Timestamp.fromDate(datePlusTimeParsed);
  console.log("🚀 ~ timestamp", timestamp);
  console.log(timestamp.toDate(), "timestamp1.toDate() 1");

  // Parsed Report selected date in data filter
  // console.log(reportDate._d, "reportDate._d");
  const reportDateTimestamp = firebase.firestore.Timestamp.fromDate(
    reportDate._d
  );
  // console.log("🚀 ~ reportDate", reportDateTimestamp);
  // console.log(reportDateTimestamp.toDate(), "reportDate.toDate() 1");

  const ref = customersRef
    .doc(`${customerId}`)
    .collection("Reporting")
    .doc();

  await ref.set({
    floorPlanId: floorPlanId,
    networkId: netId,
    worker: user.fullname,
    from: user.email,
    to: data.to,
    performAt: timestamp,
    repeat: data.repeat,
    status: "scheduled",
    group: group,
    selectedFloors: selectedFloors,
    selectedZones: selectedZones,
    selectedSSIDs: selectedSSIDs,
    reportDate: reportDateTimestamp,
    selectedTimeframe: selectedTimeframe,
    created: {
      id: user.id,
      email: user.email,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    },
  });
  return ref.id;
}

//★━━━━━━━━━━━━━━━━★ Wifi ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export function getWifiAccuracy(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "floorsGlobal",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/wifi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/wifi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getDailyWifiAccuracy(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "floorsDay",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/wifi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  //   return fetch(
  //     `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/wifi/${queryParams.customerId}`,
  //     requestOptions
  //   );
}

export function getRSSI(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "floorsGlobal",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/rssi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/rssi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getDailyRSSI(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "floorsDay",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/rssi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/rssi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getZonesWifiAccuracy(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesGlobal",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/wifi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/wifi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getTreeMapRSSI(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesGlobal",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/rssi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/rssi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getZonesDailyWifiAccuracy(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesDay",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/wifi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/wifi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getZonesRSSI(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesGlobal",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/rssi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/rssi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

export function getZonesDailyRSSI(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.location.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesDay",
    networkId: locations,
    zones: zones,
    ssid: queryParams.SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/rssi/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/rssi/${queryParams.customerId}`,
  //   requestOptions
  // );
}

//★━━━━━━━━━━━━━━★ Occupancy ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export function getFloorOccupancy({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);

  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getTotalPeople({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getBusiestFloors({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getFloorOccupancy2({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getTotalPeople2({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locations,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}
export function getTotalPeopleZones({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locations,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getTotalPeople2ByLevel({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locations,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getDayTotalPeopleData({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalHour",
    networkId: locations,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${customerId}`,
  //   requestOptions
  // );
}

export function getZoneTotal({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getZoneByHour({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "hourlyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getZoneOccupancy2({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getZoneOccupancy2ByLevel({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getBusiestZones({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getZoneOccupancy({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailyInfo",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/zones/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/zones/${customerId}`,
  //   requestOptions
  // );
}

export function getAvgTimeSpentTopZones({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesDay",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${customerId}`,
  //   requestOptions
  // );
}

export function getAverageTimeSpentByHour({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesHour",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${customerId}`,
  //   requestOptions
  // );
}

export function getAvgTimeZone(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return new Promise(() => {});
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "zonesGlobal",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${queryParams.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${queryParams.customerId}`,
  //   requestOptions
  // );
}

//★━━━━━━━━━━━━━━★ Employee ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export function getAvgTimeDay({ customerId, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }
  if (!queryParams.selectedZones) {
    return Promise.resolve(null);
  }

  var locations = [];
  var zones = [];

  queryParams.locations.forEach((location) => {
    locations.push(location.netId);
  });
  queryParams.selectedZones.forEach((z) => {
    zones.push(z.name);
  });

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return new Promise(() => {});
  }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "day",
    networkId: locations,
    zones: zones,
    ssid: SSIDs,
    startDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.endDate).format("YYYY-MM-DD[T23:59:59]"),
    zones: ["all"],
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/avgtime/${customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));

  // return fetch(
  //   `https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/avgtime/${customerId}`,
  //   requestOptions
  // );
}

//★━━━━━━━━━━━━━━★ Teams ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export function getTeamsOccupancy({
  customer,
  queryParams,
  users,
  department,
}) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  // var locations = [];
  // queryParams.locations.forEach((location) => {
  //   locations.push(location.netId);
  // });
  // const SSIDs = filterSSIDs(queryParams);
  // if (SSIDs === null || SSIDs.length === 0) {
  //   return Promise.resolve(null);
  // }

  const _networks = customer.merakiNetworks.filter((val) => !val.disabled);
  const locationsIds = _networks.map((location) => location.netId);

  // (a variável users são todos os users que existem em algum departamento)
  // filteredUsers = users que existem por departamento.
  const filteredUsers = users?.filter((user) =>
    department.employees?.includes(user.id)
  );
  // console.log("🚀 ~ filteredUsers", filteredUsers);

  const macsArray = [];
  const usersMacAdresses = filteredUsers?.map((mac) => mac.devicesArray);
  // console.log("🚀 ~ usersMacAdresses", usersMacAdresses);
  // eslint-disable-next-line no-unused-vars
  const flattenMacs = usersMacAdresses?.flat().filter((val) => {
    if (val !== undefined) {
      macsArray.push(val);
    } else {
      macsArray.push("");
    }
  });

  // macsArray && console.log(macsArray, "macsArray");

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "globalInfo",
    networkId: locationsIds,
    ssid: [],
    // ssid: SSIDs,
    macAddress: macsArray,
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD[T23:59:59]"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${customer.customerId}`,
    requestOptions
  );
}

//★━━━━━━━━━━━━━━★ Bookings ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export function getAllBookings({ customer, queryParams }) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  var endDate = moment(queryParams.startDate);
  // if (queryParams.timeframe === "day") {
  //   endDate.subtract(1, "days");
  // }
  if (queryParams.timeframe === "week") {
    endDate.subtract(6, "days");
  }
  if (queryParams.timeframe === "month") {
    endDate.subtract(1, "months");
  }

  // var locations = [];
  // queryParams.locations.forEach((location) => {
  //   locations.push(location.netId);
  // });

  // const locationsIds = customer.merakiNetworks?.map(
  //   (location) => location.netId
  // );

  // const SSIDs = filterSSIDs(queryParams);
  // if (SSIDs === null || SSIDs.length === 0) {
  //   return Promise.resolve(null);
  // }

  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    typechart: "dailybookings",
    // networkId: locationsIds,
    // ssid: SSIDs,
    users: [],
    startDate: moment(endDate).format("YYYY-MM-DD"),
    endDate: moment(queryParams.startDate).format("YYYY-MM-DD"),
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/reporting/bookings/${customer.customerId}`,
    requestOptions
  ).catch((error) => console.log("Error", error));
}
