import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import Chart from "react-apexcharts"
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_partials/Card"

export function ZoneLocationAccuracyPerDay() {
  const { isLoading, zoneLocationAccuracyPerDay } = useSelector((state) => ({
    isLoading: state.reporting.zoneLocationAccuracyPerDayLoading,
    zoneLocationAccuracyPerDay: state.reporting.zoneLocationAccuracyPerDay,
  }), shallowEqual)

  return <Card isLoading={isLoading}>
    <CardHeader title='Location Accuracy' />
    <CardBody style={{ height: "380px" }}>
      <Chart
        options={getChartOptions(zoneLocationAccuracyPerDay)}
        series={zoneLocationAccuracyPerDay?.series || []}
        type='bar'
        height="100%"
      />
    </CardBody>
  </Card>
}

function getChartOptions(options) {
  return {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: "LocationAccuracyByZone",
            columnDelimiter: ',',
            headerCategory: 'Zone',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: "LocationAccuracyByZone",
          },
          png: {
            filename: "LocationAccuracyByZone",
          }
        },
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "55%",
      },
    },
    colors: ["#323389", "#882e8c", "#c7297c", "#f1425f", "#ff713b", "#ffa600"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value) =>  value + "%",
      },
    },
    xaxis: {
      //Default xaxis so it doesnt break when new options arrive
      tickPlacement: "between",
      ...options?.xaxis,
    },
    labels: options?.labels || [],
    yaxis: {
      tickAmount: 5,
      max: 100,
      labels: {
        formatter: (value) =>  value + "%",
      }
    },
  }
}
