import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { OverviewZonesChart } from "./OverviewZonesChart";

export function OverviewZones({ queryParams }) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "UIProps.queryParams APsWorstSNR");
    // allDevicesSerial &&
    //   allDevicesSerial.length !== 0 &&
    //   queryParams &&
    //   queryParams.locations &&
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await OverviewZonesChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Overview - Zones'
        subtitle='Net failures & SNR'
      ></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
