import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useUIContext } from "../../UIContext";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import Chart from "react-apexcharts";
import { Nav, Tab } from "react-bootstrap";

export function PeopleDistribution() {
  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
    };
  }, [UIContext]);

  const { isLoading, peopleDistribution } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorPeopleDistributionLoading,
      peopleDistribution: state.reporting.floorPeopleDistribution,
    }),
    shallowEqual
  );

  const [key, setKey] = useState("all week");
  const [graphData, setGraphData] = useState({
    labels: [],
    series: [],
  });

  useEffect(() => {
    if (!peopleDistribution) return;

    if (key === "all week") {
      setGraphData({
        series: peopleDistribution.allDaysSeries,
        labels: peopleDistribution.allDaysLabels,
      });
    }
    if (key === "working days") {
      setGraphData({
        series: peopleDistribution.workDaysSeries,
        labels: peopleDistribution.workDaysLabels,
      });
    }
    if (key === "weekends") {
      setGraphData({
        series: peopleDistribution.weekendsSeries,
        labels: peopleDistribution.weekendsLabels,
      });
    }
  }, [peopleDistribution, key]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='People Distribution'
        subtitle='Total number of people'
      />
      <CardBody style={{ height: "380px" }}>
        <Chart
          options={getChartOptions(graphData)}
          series={graphData.series}
          type='donut'
          height={
            UIProps.queryParams.timeframe === "day" ||
            !peopleDistribution ||
            !peopleDistribution.weekendsSeries ||
            !peopleDistribution.weekendsLabels
              ? "100%"
              : "92%"
          }
        />
        <FilterButtons
          timeframe={UIProps.queryParams.timeframe}
          peopleDistribution={peopleDistribution}
          setKey={setKey}
        />
      </CardBody>
    </Card>
  );
}

function FilterButtons({ timeframe, peopleDistribution, setKey }) {
  //Use of inside key because normal key was being received undefined
  const [insideKey, setInsideKey] = useState("all week");
  if (timeframe === "day") return <div />;
  if (!peopleDistribution) return <div />;
  if (!peopleDistribution.weekendsSeries || !peopleDistribution.weekendsLabels)
    return <div />;
  return (
    <div className='card-toolbar row d-flex justify-content-center'>
      <Tab.Container activeKey={insideKey}>
        <Nav
          as='ul'
          onSelect={(_key) => {
            setInsideKey(_key);
            setKey(_key);
          }}
          className='nav nav-pills nav-pills-sm nav-dark-75'
        >
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='all week'
              //onClick={() => handleSubmit("all week")}
              className={`nav-link py-2 px-4 ${
                insideKey === "all week" ? "active" : ""
              }`}
            >
              All Week
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='working days'
              //onClick={() => handleSubmit("working days")}
              className={`nav-link py-2 px-4 ${
                insideKey === "working days" ? "active" : ""
              }`}
            >
              Working Days
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='weekends'
              //onClick={() => handleSubmit("weekends")}
              className={`nav-link py-2 px-4 ${
                insideKey === "weekends" ? "active" : ""
              }`}
            >
              Weekends
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </div>
  );
}

function getChartOptions(graphData) {
  return {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "PeopleDistributionByFloor",
            columnDelimiter: ",",
            headerCategory: "Floor",
            headerValue: "Value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "PeopleDistributionByFloor",
          },
          png: {
            filename: "PeopleDistributionByFloor",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display.",
    },
    labels: graphData.labels,
    legend: {
      position: "bottom",
    },
    // Color palette from u-find purple 6 colors
    colors: ["#323389", "#882e8c", "#c7297c", "#f1425f", "#ff713b", "#ffa600"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              fontSize: "12px",
              fontWeight: 500,
              color: "black",
              offsetY: 2,
            },
            total: {
              show: true,
              label: "Total People (average)",
              color: "black",
              fontSize: "11px",
              fontWeight: 500,
              formatter: (w) => {
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                const average = total / w.globals.seriesTotals.length;
                return Math.floor(average);
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
}
