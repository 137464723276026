import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  LinearProgress,
  withStyles,
  Badge,
  Popover,
  makeStyles,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import { BsInfoCircle } from "react-icons/bs";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function ZonesRSSICustom({ showChartZones, setShowChartZones, hide }) {
  const { isLoading, zonesRSSI } = useSelector(
    (state) => ({
      isLoading: state.reporting.zonesRSSILoading,
      zonesRSSI: state.reporting.zonesRSSI,
    }),
    shallowEqual
  );

  /* useEffect(() => {
    if (floorPlans && UIProps.queryParams) {
      dispatch(
        actions.getZonesRSSI({
          customerId: customer.customerId,
          location: UIProps.queryParams.locations,
          startDate: UIProps.queryParams.startDate,
          endDate: UIProps.queryParams.endDate,
          SSIDs: UIProps.queryParams.SSIDs,
          floorPlans: floorPlans,
          selectedZones: UIProps.queryParams.selectedZones,
        })
      )
    }
  }, [customer, UIProps.queryParams, floorPlans]) */

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title={<Title />}
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChartZones({
                  ...showChartZones,
                  ZonesRSSICustom: false,
                })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <CardBody
      //   style={{ height: "300px" }}
      >
        <div className='container overflow-auto'>
          <div className='row flex-row'>
            {zonesRSSI &&
              zonesRSSI.map((val, i) => {
                return (
                  <div
                    key={i}
                    className='col-10 col-sm-8 col-md-6 col-xl-6'
                    style={{
                      marginBottom: "2rem",
                    }}
                  >
                    <Chart
                      options={getChartOptions(val)}
                      series={[val.excelent, val.good, val.bad, val.terrible]}
                      type='donut'
                      height='100%'
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

function getChartOptions(options) {
  return {
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => val + "%",
      },
    },
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              fontWeight: 800,
              offsetY: -3,
            },
            value: {
              show: true,
              fontSize: "12px",
              fontWeight: 500,
              color: "black",
              offsetY: 3,
              formatter: (val) => val + "%",
            },
            total: {
              show: true,
              label: options.sampleSize + " / " + options.numMacs,
              color: "black",
              fontWeight: 800,
              formatter: () => "Samples",
            },
          },
        },
      },
    },
    labels: ["Excelent", "Good", "Average", "Bad"],
    title: {
      text: options.zoneName,
    },
  };
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    padding: "0",
  },
}))(Badge);

const colors = [
  {
    color: "#09ff00",
    text: (
      <span className='ml-2 mt-n1'>
        Excelent
        <span style={{ fontSize: "12px" }}>
          {" "}
          = <b>{">"}</b> -65dbm
        </span>
      </span>
    ),
  },
  {
    color: "#00b015",
    text: (
      <span className='ml-2 mt-n1'>
        Good{" "}
        <span style={{ fontSize: "12px" }}>
          = <b>{">"}</b> -80dbm{" "}
        </span>
      </span>
    ),
  },
  {
    color: "#f7a100",
    text: (
      <span className='ml-2 mt-n1'>
        Average{" "}
        <span style={{ fontSize: "12px" }}>
          = <b>{">"}</b> -90dbm
        </span>
      </span>
    ),
  },
  {
    color: "#f70000",
    text: (
      <span className='ml-2 mt-n1'>
        Bad{" "}
        <span style={{ fontSize: "12px" }}>
          = <b>{"<"}</b> -90dbm
        </span>
      </span>
    ),
  },
];

function Title() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  return (
    <StyledBadge
      style={{ fontSize: "14px" }}
      //   badgeContent={
      //     <>
      //       <span
      //         className='w-100 text-center'
      //         onMouseEnter={handlePopoverOpen}
      //         onMouseLeave={handlePopoverClose}
      //       >
      //         <BsInfoCircle style={{ fontSize: "1.3rem" }} />
      //       </span>
      //       <Popover
      //         id='mouse-over-popover'
      //         className={classes.popover}
      //         classes={{
      //           paper: classes.paper,
      //         }}
      //         open={open}
      //         anchorEl={anchorEl}
      //         anchorOrigin={{
      //           vertical: "bottom",
      //           horizontal: "left",
      //         }}
      //         transformOrigin={{
      //           vertical: "top",
      //           horizontal: "left",
      //         }}
      //         onClose={handlePopoverClose}
      //         disableRestoreFocus
      //       >
      //         <div style={{ width: "250px", wordWrap: "break-word" }}>
      //           <div className='pb-3'>
      //             <b>RSSI: </b>
      //             <br />
      //             <br />
      //             <div
      //               className='ml-3 mb-n5'
      //               style={{ width: "150px", wordWrap: "break-word" }}
      //             >
      //               {colors.map((val) => {
      //                 return (
      //                   <div className='row pb-2 pl-2' key={val.color}>
      //                     <div
      //                       style={{
      //                         height: "10px",
      //                         width: "10px",
      //                         backgroundColor: val.color,
      //                       }}
      //                     />
      //                     {val.text}
      //                   </div>
      //                 );
      //               })}
      //             </div>
      //           </div>
      //         </div>
      //       </Popover>
      //     </>
      //   }
    >
      RSSI
    </StyledBadge>
  );
}
