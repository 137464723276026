import React from "react";
import { Header3 } from "../typography/Header3";
import { Small } from "../typography/Small";

export function GeralInfoRow({ data }) {
  return (
    <div className='row' style={{ margin: "auto" }}>
      {data &&
        data.map((val, i) => {
          return (
            <div
              key={i}
              id={i}
              className={`col ${val.className}`}
              style={{
                background: "#DAE3E8",
                borderRadius: "5px",
                marginLeft: i === 0 ? 0 : "10px",
                marginRight: i === data.length - 1 ? 0 : "10px",
                paddingBottom: "10px",
              }}
            >
              <div className='d-flex justify-content-end pt-5 pr-4 mb-8'>
                {val.icon}
              </div>
              <Header3 text={val.value ? val.value : 0} />
              <br />
              <Small text={val.text} />
            </div>
          );
        })}
    </div>
  );
}
