import * as requestFromServer from "./peopleCrud";
import { reportingSlice, callTypes } from "../reportingSlice";
import * as ActionHelpers from "./ActionHelpers";

const { actions } = reportingSlice;

export const fetchPeopleByFloor = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return Promise.all([
    fetchFloorPeopleDistribution(queryParams, dispatch),
    fetchFloorOccupancy(queryParams, dispatch),
    fetchFloorAverageOccupancy(queryParams, dispatch),
  ]).catch((error) => {
    console.log("Fetch People By Floor: ", error);
    error.clientMessage = "Can't fetch People By Floor";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

function fetchFloorPeopleDistribution(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorPeopleDistribution }));
  if (!queryParams) dispatch(actions.floorPeopleDistributionFetched(null));
  return requestFromServer
    .getFloorPeopleDistribution(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorPeopleDistributionFetched(null));
      const data = ActionHelpers.handlePeopleDistributionResult({
        result,
        queryParams,
      });
      dispatch(actions.floorPeopleDistributionFetched(data));
    })
    .catch((error) => {
      console.log("Fetch People Distribution: ", error);
      error.clientMessage = "Can't fetch People Distribution";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

async function fetchFloorOccupancy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorOccupancy }));
  if (!queryParams) dispatch(actions.floorOccupancyFetched(null));
  const floorOccupancyResult = await requestFromServer
    .getFloorOccupancy(queryParams)
    .then((response) => response && response.json())
    .catch((error) => {
      console.log("Fetch floor occupancy: ", error);
      error.clientMessage = "Can't fetch floor occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });

  const totalPeopleResult = await requestFromServer
    .getTotalPeople(queryParams)
    .then((response) => response && response.json())
    .catch((error) => {
      console.log("Fetch Total People: ", error);
      error.clientMessage = "Can't fetch Total People";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });

  if (floorOccupancyResult && totalPeopleResult) {
    const data = ActionHelpers.handleFloorOccupancyResult({
      floorOccupancyResult,
      totalPeopleResult,
      queryParams,
    });
    data && dispatch(actions.floorOccupancyFetched(data));
  } else {
    dispatch(actions.floorOccupancyFetched(null));
  }
}

function fetchFloorAverageOccupancy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorAverageOccupancy }));
  if (!queryParams) dispatch(actions.floorAverageOccupancyFetched(null));
  return requestFromServer
    .getFloorAverageOccupancy(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorAverageOccupancyFetched(null));

      const data = ActionHelpers.handleFloorAverageOccupancyResult({
        result,
        queryParams,
      });
      dispatch(actions.floorAverageOccupancyFetched(data));
    })
    .catch((error) => {
      console.log("Fetch average occupancy", error);
      error.clientMessage = "Can't fetch average occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

//★━━━━━━━━━━━━━━━━★ Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchPeopleByZone = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return Promise.all([
    fetchZoneOccupancy(queryParams, dispatch),
    fetchZoneAverageOccupancy(queryParams, dispatch),
    fetchZonePeopleDistribution(queryParams, dispatch),
    fetchZoneAvgTimeSpent(queryParams, dispatch),
    fetchFloorPeopleForChordDiagram(queryParams, dispatch),
    fetchFloorPeopleForSankeyCircularChart(queryParams, dispatch),
    fetchFloorPeopleForSankeyChart(queryParams, dispatch),
  ]).catch((error) => {
    console.log("Fetch People By Zone: ", error);
    error.clientMessage = "Can't fetch People By Zone";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

async function fetchZoneOccupancy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneOccupancy }));
  if (!queryParams) dispatch(actions.zoneOccupancyFetched(null));
  const zoneOccupancyResult = await requestFromServer
    .getZoneOccupancy(queryParams)
    .then((response) => response && response.json())
    .catch((error) => {
      console.log("Fetch zone occupancy: ", error);
      error.clientMessage = "Can't fetch zone occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });

  const totalPeopleResult = await requestFromServer
    .getTotalPeople(queryParams)
    .then((response) => response && response.json())
    .catch((error) => {
      console.log("Fetch Total People: ", error);
      error.clientMessage = "Can't fetch Total People";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });

  if (zoneOccupancyResult && totalPeopleResult) {
    const data = ActionHelpers.handleZoneOccupancyResult({
      zoneOccupancyResult,
      totalPeopleResult,
      queryParams,
    });
    dispatch(actions.zoneOccupancyFetched(data));
  } else {
    dispatch(actions.zoneOccupancyFetched(null));
  }
}

function fetchZoneAverageOccupancy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneAverageOccupancy }));
  if (!queryParams) dispatch(actions.zoneAverageOccupancyFetched(null));
  return requestFromServer
    .getZoneAverageOccupancy(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneAverageOccupancyFetched(null));
      const data = ActionHelpers.handleZoneAverageOccupancy({
        result,
        queryParams,
      });

      dispatch(actions.zoneAverageOccupancyFetched(data));
    })
    .catch((error) => {
      console.log("Fetch zone average occupancy: ", error);
      error.clientMessage = "Can't fetch zone average occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchZonePeopleDistribution(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zonePeopleDistribution }));
  if (!queryParams) dispatch(actions.zonePeopleDistributionFetched(null));
  return requestFromServer
    .getZonePeopleDistribution(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zonePeopleDistributionFetched(null));
      const data = ActionHelpers.handleZonePeopleDistribution({
        result,
        queryParams,
      });
      dispatch(actions.zonePeopleDistributionFetched(data));
    })
    .catch((error) => {
      console.log("Fetch Zone People Distribution: ", error);
      error.clientMessage = "Can't fetch Zone People Distribution";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchZoneAvgTimeSpent(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneAvgTimeSpent }));
  if (!queryParams) dispatch(actions.zoneAvgTimeSpentFetched(null));
  return requestFromServer
    .getAvgTimeSpent(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneAvgTimeSpentFetched(null));
      const data = ActionHelpers.avgTimeSpentTopZonesResultToGraphData({
        result,
        queryParams,
      });

      dispatch(actions.zoneAvgTimeSpentFetched(data));
    })
    .catch((error) => {
      console.log("Fetch avg time spent: ", error);
      error.clientMessage = "Can't fetch avg time spent";
      dispatch(
        actions.catchError({ error, callType: callTypes.zoneAvgTimeSpent })
      );
    });
}

function fetchFloorPeopleForChordDiagram(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForChord }));
  if (!queryParams) dispatch(actions.zoneOccupancyFetchedForChord(null));
  return requestFromServer
    .getZoneOccupancyForChord(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneOccupancyFetchedForChord(null));

      const data = ActionHelpers.handleZoneOccupancyForChord({
        result,
        queryParams,
      });

      dispatch(actions.zoneOccupancyFetchedForChord(data));
    })
    .catch((error) => {
      console.log("Fetch people per zone for Chord error: ", error);
      error.clientMessage = "Can't fetch People per zone for Chord ";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchFloorPeopleForSankeyCircularChart(queryParams, dispatch) {
  dispatch(
    actions.startCall({ callType: callTypes.zoneOccupancyForSankeyCircular })
  );
  if (!queryParams)
    dispatch(actions.zoneOccupancyFetchedForSankeyCircular(null));
  return requestFromServer
    .getZoneOccupancyForSankey(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result)
        dispatch(actions.zoneOccupancyFetchedForSankeyCircular(null));

      const data = ActionHelpers.handleZoneOccupancyForSankeyCircular({
        result,
        queryParams,
      });

      dispatch(actions.zoneOccupancyFetchedForSankeyCircular(data));
    })
    .catch((error) => {
      console.log("Fetch people per zone for Sankey Circular error: ", error);
      error.clientMessage = "Can't fetch People per zone for Sankey Circular";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchFloorPeopleForSankeyChart(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForSankey }));
  if (!queryParams) dispatch(actions.zoneOccupancyFetchedForSankey(null));
  return requestFromServer
    .getZoneOccupancyForSankey(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneOccupancyFetchedForSankey(null));

      const data = ActionHelpers.handleZoneOccupancyForSankey({
        result,
        queryParams,
      });

      dispatch(actions.zoneOccupancyFetchedForSankey(data));
    })
    .catch((error) => {
      console.log("Fetch people per zone for Sankey error: ", error);
      error.clientMessage = "Can't fetch People per zone for Sankey ";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}
