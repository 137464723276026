import React, { useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody } from "../../../_partials/Card"
import moment from "moment"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import { Header3 } from "../../../_partials/typography/Header3"
import { IconButton, Popover } from "@material-ui/core"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { Small } from "../../../_partials/typography/Small"

import "./Teammates.css"

export function Teammates() {
  //━━━ Get data from redux ━━━\\
  const { bookings, users } = useSelector(
    state => ({
      bookings: state.booking?.bookings,
      users: state.booking?.users,
    }),
    shallowEqual
  )

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  const [date, setDate] = useState(
    moment()
      .startOf("isoWeek")
      .toDate()
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [popoverContent, setPopoverContent] = useState(null)
  const [popoverContent2, setPopoverContent2] = useState(null)

  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)

  const handlePopoverOpen = ({ target, content }) => {
    setPopoverContent(content)
    setAnchorEl(target)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverOpen2 = ({ target, content }) => {
    setPopoverContent2(content)
    setAnchorEl2(target)
  }
  const handlePopoverClose2 = () => {
    setAnchorEl2(null)
  }

  return (
    <>
      <Header3 text="Teammates" />
      <Card
        className="mt-4"
        style={{
          flexGrow: "1",
        }}
      >
        <div className="w-100 d-flex align-items-center">
          <div className="col d-flex justify-content-center">
            <IconButton aria-label="Previous week" onClick={() => setDate(moment(date).subtract(7, "days"))}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Small
              text={`${moment(date).format("MMM DD")} to ${moment(date)
                .add(6, "days")
                .format("MMM DD")}`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50px",
              }}
            />
            <IconButton aria-label="Next week" onClick={() => setDate(moment(date).add(7, "days"))}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </div>
        <CardBody className="pt-6" style={{ overflowY: "auto" }}>
          <table className="bookignTable">
            <thead>
              <tr>
                <th className="bookingTableHeader" style={{ borderBottom: "1px solid rgb(155, 155, 155)", borderLeft: 0 }}></th>
                <th className="bookingTableHeader">Mon</th>
                <th className="bookingTableHeader">Tue</th>
                <th className="bookingTableHeader">Wed</th>
                <th className="bookingTableHeader">Thu</th>
                <th className="bookingTableHeader">Fri</th>
                <th className="bookingTableHeader">Sat</th>
                <th className="bookingTableHeader">Sun</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map(val => {
                  const _bookings = bookings ? bookings.filter(value => value.created.id === val.uid || value.attendees?.includes(val.email)) : []
                  return (
                    <tr key={val.uid}>
                      <td className="bookingDataCell" style={{ borderLeft: 0, textAlign: "right" }}>
                        <div
                          style={{ cursor: "pointer" }}
                          onMouseEnter={e =>
                            handlePopoverOpen({
                              target: e.currentTarget,
                              content: { user: val },
                            })
                          }
                          onMouseLeave={handlePopoverClose}
                        >
                          <div
                            //title={u?.displayName || u?.email || "Error"}
                            style={{
                              margin: "7px 7px 7px 0",
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                              backgroundSize: "cover",
                              backgroundImage: `url(${val?.photoURL ? val.photoURL : "/media/users/default.jpg"}), url(/media/users/default.jpg)`,
                            }}
                            //src={val?.photoURL ? val.photoURL : "/media/users/default.jpg"}
                          />
                        </div>
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(1, "day"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(1, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(2, "days"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(2, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(3, "days"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(3, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(4, "days"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(4, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(5, "days"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(5, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="bookingDataCell">
                        {_bookings.some(value => moment(value.date.toDate()).isSame(moment(date).add(6, "days"), "day")) ? (
                          <CalendarTodayOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onMouseEnter={e =>
                              handlePopoverOpen2({
                                target: e.currentTarget,
                                content: {
                                  user: val,
                                  date: moment(date).add(6, "day"),
                                },
                              })
                            }
                            onMouseLeave={handlePopoverClose2}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <Popover
            id="mouse-over-popover"
            style={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            disableRestoreFocus
          >
            <div
              style={{
                padding: "12px 20px 20px 20px",
                backgroundColor: "#f7f7f7",
                width: "300px",
              }}
            >
              <div
                className="row d-flex justify-content-between"
                style={{
                  margin: "auto",
                }}
              >
                <div
                  className="row"
                  style={{
                    margin: 0,
                    //paddingTop: "10px"
                  }}
                >
                  <div>
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "45px",
                        height: "45px",
                        backgroundSize: "cover",
                        backgroundImage: `url(${
                          popoverContent?.user?.photoURL ? popoverContent.user.photoURL : "/media/users/default.jpg"
                        }), url(/media/users/default.jpg)`,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: "5px",
                      marginTop: "auto",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#4A4A68",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {popoverContent?.user?.displayName || popoverContent?.user?.email}
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "160%",
                        color: "#4A4A68",
                      }}
                    >
                      {popoverContent?.user?.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
          <Popover
            id="mouse-over-popover-2"
            style={{
              pointerEvents: "none",
            }}
            open={open2}
            anchorEl={anchorEl2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose2}
            disableRestoreFocus
          >
            <div
              style={{
                padding: "10px",
                backgroundColor: "#f7f7f7",
                width: "150px",
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "160%",
                  color: "#4A4A68",
                }}
              >
                <b>{popoverContent2?.user?.displayName || popoverContent2?.user?.email}</b> has a booking for{" "}
                {popoverContent2?.date?.format("MMM DD")}
              </span>
            </div>
          </Popover>
        </CardBody>
      </Card>
    </>
  )
}
