import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { withStyles, Badge, Popover, makeStyles } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";

export function ZoneLocationAccuracy() {
  const { isLoading, zoneLocationAccuracy } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneLocationAccuracyLoading,
      zoneLocationAccuracy: state.reporting.zoneLocationAccuracy,
    }),
    shallowEqual
  );

  return (
    <Card isLoading={isLoading}>
      <CardHeader title={<Title />} />
      <CardBody style={{ height: "380px" }}>
        <Chart
          options={getChartOptions()}
          series={zoneLocationAccuracy || []}
          type='treemap'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

const colors = [
  {
    from: 0,
    to: 10,
    color: "#8e8fd6",
    text: "up to 10%",
  },
  {
    from: 10.1,
    to: 20,
    color: "#696ac8",
    text: "up to 20%",
  },
  {
    from: 20.1,
    to: 35,
    color: "#4445ba",
    text: "up to 35%",
  },
  {
    from: 35.1,
    to: 50,
    color: "#363795",
    text: "up to 50%",
  },
  {
    from: 50.1,
    to: 65,
    color: "#282970",
    text: "up to 65%",
  },
  {
    from: 65.1,
    to: 80,
    color: "#1b1b4a",
    text: "up to 80%",
  },
  {
    from: 80.1,
    to: 100,
    color: "#0d0d25",
    text: "up to 100%",
  },
];

function getChartOptions() {
  return {
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "LocationAccuracyByZone",
            columnDelimiter: ",",
            headerCategory: "Zone",
            headerValue: "Accuracy (%)",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "LocationAccuracyByZone",
          },
          png: {
            filename: "LocationAccuracyByZone",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        colorScale: {
          ranges: colors,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value + "%";
        },
      },
    },
  };
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    padding: "0",
  },
}))(Badge);

function Title() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  return (
    <StyledBadge
      badgeContent={
        <>
          <span
            className='w-100 text-center'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <BsInfoCircle style={{ fontSize: "1.3rem" }} />
          </span>
          <Popover
            id='mouse-over-popover'
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div style={{ width: "250px", wordWrap: "break-word" }}>
              <div className='pb-3'>
                <b>Location Accuracy: </b>
                <br />
                <p>Represents the accuracy of the locations for each zone.</p>
                <div
                  className='ml-3 mb-n5'
                  style={{ width: "150px", wordWrap: "break-word" }}
                >
                  {colors.map((val) => {
                    return (
                      <div className='row pb-2 pl-2' key={val.color}>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: val.color,
                          }}
                        ></div>
                        <span className='ml-2 mt-n1'>{val.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Popover>
        </>
      }
    >
      Location Accuracy
    </StyledBadge>
  );
}
