import React from "react"
import { Header3 } from "../typography/Header3"

export function PageTitle({ title, icon }) {
   return <div className="row" style={{ margin: "auto" }}>
      <div className="d-flex align-items-center pr-3">
         {icon}
      </div>
      <Header3 text={title} />
   </div>
}
