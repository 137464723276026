import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";

import { AnimateLoading } from "../../../../_metronic/_partials/controls";
import HeaderSelections from "./HeaderSelections";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Button } from "../../../_partials/Button";
import { useHistory } from "react-router-dom";
import "./headerCSS.css";

export function Header() {
  const uiService = useHtmlClassService();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id='kt_header'
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={`topbar ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}
        >
          <AnimateLoading />

          {/*begin::Topbar*/}
          <div style={{ overflow: "auto", marginRight: "10px" }}>
            <HeaderSelections />
          </div>
          <div style={{ minWidth: "165px", display: "flex" }}>
            {layoutProps.viewUserDisplay && (
              <>
                <QuickUserToggler />
              </>
            )}
            <Button
              className='signOutBtn'
              style={{
                marginLeft: "20px",
                maxWidth: "100px",
              }}
              text='SIGN OUT'
              onClick={() => history.push("/logout")}
            />
          </div>
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
