import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import { DataGrid } from "@material-ui/data-grid";
import { parse } from "date-fns";

export const OverviewGlobalChart = async (queryParams, showCart) => {
  // console.log("🚀 ~ showCart", showCart);
  //   console.log(queryParams, "SNRFloorsChart queryParams");

  const netFailuresSeries = [];
  const netFailuresData = await getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      const netFailuresParsed = netFailuresFloorsToGraphData({
        result,
        queryParams,
      });

      return netFailuresParsed;
    });

  // console.log(netFailuresData, "netFailuresData");
  netFailuresSeries.push(netFailuresData);

  return getFloorSNRPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = badSNRFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(netFailuresSeries, "netFailuresSeries 2");
      // console.log(data, "data SNRFloorsChart");

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            <div className='container'>
              <div
                className='row pt-4 pr-4 pl-4 pb-0'
                style={{ background: "#EEF0F8" }}
              >
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
              </div>
              <div
                className='row pb-4 pl-4 pr-4 pt-0'
                style={{ background: "#EEF0F8" }}
              >
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center'
                  style={{
                    height: "8rem",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
              </div>
            </div>
          </div>
        );
        return mainDiv;
      } else {
        const newRows = [];

        // console.log(data, "data");
        data.map((d, i) =>
          newRows.push({
            // id: i + 1,
            device: d.device,
            snr: d.count,
            netFailures: 0,
            location: d.location,
            floorPlan: d.floorPlan,
          })
        );
        // console.log(newRows, "newRows");
        // console.log(netFailuresSeries[0], " netFailuresSeries[0]");

        const failuresArr = netFailuresSeries && netFailuresSeries[0];

        const mergeArr = (arr1, arr2) => {
          const obj = {};

          arr1.forEach((item) => {
            obj[item.device] = item;
          });

          arr2.forEach((item) => {
            // console.log("🚀 ~ item", item);
            // console.log("🚀 ~ obj[item.device]", obj[item.device]);
            let newSerie = {
              // date: item.date,
              device: item.device,
              netFailures: item.count,
              snr: 0,
              location: item.location,
              floorPlan: item.floorPlan,
            };

            obj[item.device]
              ? (obj[item.device] = {
                  ...obj[item.device],
                  netFailures: item.count,
                  location: item.location,
                  floorPlan: item.floorPlan,
                })
              : (obj[item.device] = newSerie);
          });

          return Object.values(obj);
        };

        const output = mergeArr(newRows, failuresArr);
        // console.log(output, "output");

        const parsedOutput = parseOutput(output, queryParams);
        // console.log("🚀 ~ parsedOutput", parsedOutput);

        const mainDiv = !showCart ? (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            <div
              className='container'
              //  style={{ border: "1px solid green" }}
            >
              <div
                className='row pt-4 pr-4 pl-4 pb-0'
                style={{
                  background: "#EEF0F8",
                  borderRadius: "3px",
                  //   border: "1px solid green",
                }}
              >
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Locations</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[0].locations}</b>
                  </h3>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Floors</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[1].floors}</b>
                  </h3>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>AP's</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[2].aps}</b>
                  </h3>
                </div>
              </div>

              <div
                className='row pb-4 pl-4 pr-4 pt-0'
                style={{ background: "#EEF0F8" }}
              >
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Zones</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[3].zones}</b>
                  </h3>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>SNR</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[4].snr}</b>
                  </h3>
                </div>
                <div
                  className='col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Net failures</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[5].netFailures}</b>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            <div
              className='container'
              //  style={{ border: "1px solid green" }}
            >
              <div
                className='row pt-4 pr-4 pl-4 pb-0 d-flex align-items-center justify-content-around'
                style={{
                  background: "#EEF0F8",
                  borderRadius: "3px",
                  //   border: "1px solid green",
                }}
              >
                <div
                  className='col-4 m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Locations</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[0].locations}</b>
                  </h3>
                </div>
                <div
                  className='col-4 m-4 col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Floors</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[1].floors}</b>
                  </h3>
                </div>
                <div
                  className='col-4 m-4 col m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>AP's</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[2].aps}</b>
                  </h3>
                </div>

                {/* <div
                className='row pb-4 pl-4 pr-4 pt-0'
                style={{ background: "#EEF0F8" }}
              > */}
                <div
                  className='col-4 m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Zones</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[3].zones}</b>
                  </h3>
                </div>
                <div
                  className='col-4 m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>SNR</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[4].snr}</b>
                  </h3>
                </div>
                <div
                  className='col-4 m-4 d-flex align-items-center justify-content-center flex-column'
                  style={{
                    height: "8rem",
                    // border: "1px solid green",
                    textAlign: "center",
                    background: "#FFFFFF",
                    boxShadow: "-6px 5px 10px -3px rgba(0,0,0,0.15)",
                    borderRadius: "3px",
                  }}
                >
                  <h6>Net failures</h6>
                  <h3>
                    <b>{parsedOutput && parsedOutput[5].netFailures}</b>
                  </h3>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        );

        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch SNR bad global", error);
      error.clientMessage = "Can't fetch SNR global";
    });
};

// API fetch SNR
function getFloorSNRPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "devicesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/snr/${queryParams.customerId}`,
  });
}

// API fetch NetFailures
async function getFloorNetFailuresPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorNetFailuresPerDay", queryParams);
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "devicesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/netFailures/${queryParams.customerId}`,
  });
}

// Parse result NetFailures
export const netFailuresFloorsToGraphData = ({ result, queryParams }) => {
  // console.log("🚀 ~ queryParams netFailuresFloorsToGraphData", queryParams);
  if (!result) return;

  // console.log("🚀 ~ result 3", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        date: val.date,
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        device: val.serial ? val.serial : "",
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
  });

  // console.log("🚀 ~ data", data);
  const newData = sumDevicesValuesNetFailures(data);
  // console.log("🚀 ~ newData", newData);

  const filteredData = newData.filter((d) => d.device !== "");
  // console.log("🚀 ~ filteredData", filteredData);

  return filteredData;
};

// Parse result SNR
export const badSNRFloorsToGraphData = ({ result, queryParams }) => {
  // console.log("🚀 ~ badSNRFloorsToGraphData queryParams", queryParams);
  if (!result) return;

  // console.log("🚀 ~ result", result);

  const data = [];
  // const mondaysTotal = [];
  // const tuesdaysTotal = [];
  // const wednesdaysTotal = [];
  // const thursdaysTotal = [];
  // const fridaysTotal = [];
  // const saturdaysTotal = [];
  // const sundaysTotal = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        date: val.date,
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        device: val.serial ? val.serial : "",
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
  });

  // console.log("🚀 ~ data", data);

  const newData = sumDevicesValues(data);
  // console.log("🚀 ~ newData", newData);

  return newData;
};

const sumDevicesValuesNetFailures = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.device === d.device)) {
      const index = holderOne.findIndex((val) => val.device === d.device);

      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        date: d.date,
        location: d.location,
        floorPlan: d.floorPlan,
        device: d.device,
        count: d.count,
        counter: 1,
      });
    }
  });
  return holderOne.map((val, i) => {
    return {
      date: val.date,
      location: val.location,
      floorPlan: val.floorPlan,
      device: val.device,
      count: val.count,
      // Aqui não vou retornar a média, vou retornar o numero real do failures
      // count: (val.count / val.counter).toFixed(2) * 1,
    };
  });
};
const sumDevicesValues = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.device === d.device)) {
      const index = holderOne.findIndex((val) => val.device === d.device);

      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        date: d.date,
        location: d.location,
        floorPlan: d.floorPlan,
        device: d.device,
        count: d.count,
        counter: 1,
      });
    }
  });
  return holderOne.map((val, i) => {
    return {
      date: val.date,
      location: val.location,
      floorPlan: val.floorPlan,
      device: val.device,
      // Aqui vou retornar a média go SNR Global
      count: (val.count / val.counter).toFixed(2) * 1,
    };
  });
};

const parseOutput = (output, queryParams) => {
  //   console.log("🚀 ~ queryParams", queryParams);
  // console.log(output, "output");

  const locations =
    queryParams && queryParams?.locations ? queryParams?.locations.length : 0;
  const floors =
    queryParams && queryParams?.levels ? queryParams?.levels.length : 0;
  const zones =
    queryParams && queryParams?.zones ? queryParams?.zones.length : 0;

  var uniq = {};
  var arrFilteredAps = output.filter(
    (obj) => !uniq[obj.device] && (uniq[obj.device] = true)
  );
  //   console.log("arrFilteredAps", arrFilteredAps);

  const aps = arrFilteredAps ? arrFilteredAps.length : 0;
  //   console.log("🚀 ~ aps", aps);

  const snr = getAvgSNR(output).toFixed(2) * 1;
  //   console.log("🚀 ~ snr", snr);

  const netFailures = getAvgNetFailures(output).toFixed(2) * 1;
  // console.log("🚀 ~ netFailures", netFailures);

  return [
    { locations },
    { floors },
    { aps },
    { zones },
    { snr },
    { netFailures },
  ];
};

const getAvgSNR = (array) => {
  if (!array) {
    return null;
  } else {
    const total = array.reduce((acc, c) => acc + c.snr, 0);
    const average = total / array.length;
    return average;
  }
};

const getAvgNetFailures = (array) => {
  if (!array) {
    return null;
  } else {
    const total = array.reduce((acc, c) => acc + c.netFailures, 0);
    const average = total;
    return average;
  }
};
