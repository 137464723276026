import React from "react";
import ReactDOM from "react-dom";
import GroupIcon from "@material-ui/icons/Group";
import { Tooltip } from "@material-ui/core";

export default class OccupancyControl {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";
    this.container.id = "occupancyControlId";
    this.fullscreen = false;

    this.content = (
      <OccupancyControlComponent
        showOccupancy={this.props.showOccupancy}
        setShowOccupancy={this.props.setShowOccupancy}
      />
    );

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

class OccupancyControlComponent extends React.Component {
  state = {
    occupancy: this.props.showOccupancy,
  };

  handleClick = (val) => {
    this.props.setShowOccupancy(!this.state.occupancy);
    this.setState({ occupancy: !this.state.occupancy });
  };

  render() {
    return (
      <Tooltip
        title={(this.state.occupancy ? "Hide" : "Show") + " occupancy"}
        placement='bottom'
        arrow
      >
        <div
          className={
            this.state.occupancy
              ? "occupancy-button-pressed"
              : "occupancy-button"
          }
          /*  style={{
          backgroundColor: this.state.occupancy ? "rgb(150, 150, 150)" : "white"
        }} */
        >
          <GroupIcon
            onClick={this.handleClick}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </Tooltip>
    );
  }
}
