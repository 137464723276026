import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import * as d3 from "d3";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import { Group } from "@vx/group";
import { Text } from "@vx/text";
import {
  scaleOrdinal,
  schemeCategory10,
  scaleSequential,
  interpolateCool,
  scaleLinear,
} from "d3-scale";
import SankeyCircular from "./SankeyCircular";
import "./Sankey.css";
import {
  useTooltip,
  useTooltipInPortal,
  withTooltip,
  TooltipWithBounds,
} from "@vx/tooltip";
import { localPoint } from "@vx/event";
import { sankeyCircular as d3Sankey } from "d3-sankey-circular";
// const d3sankey = require("d3-sankey-circular");
// import { pathArrows } from "d3-path-arrows";
// const pathArrows = require("d3-path-arrows").pathArrows;

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export const SankeyCircularChartPage = () => {
  const classes = useStyles();

  const { isLoading, zoneOccupancyForSankey } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneOccupancyFetchedForSankeyCircularLoading,
      zoneOccupancyForSankey: state.reporting.zoneOccupancyForSankeyCircular,
    }),
    shallowEqual
  );

  // ____________ States ___________ \\
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [highlightLinkIndexes, setHighlightLinkIndexes] = useState([]);
  const [showLegend, setShowLegend] = useState("");
  const [newMargin, setNewMargin] = useState(0);

  // ____________ Functions ___________ \\
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const mockData = {
    nodes: [
      { name: "Babylon Bus" },
      { name: "Hostem" },
      { name: "Library" },
      { name: "Mode de Vue" },
      { name: "Refectory Tower" },
      { name: "Wok Store" },
    ],
    links: [
      { source: "Babylon Bus", target: "Hostem", value: 2 },
      { source: "Babylon Bus", target: "Library", value: 164 },
      { source: "Babylon Bus", target: "Mode de Vue", value: 3 },
      { source: "Babylon Bus", target: "Refectory Tower", value: 178 },
      { source: "Babylon Bus", target: "Wok Store", value: 25 },
      { source: "Hostem", target: "Refectory Tower", value: 221 },
      { source: "Hostem", target: "Wok Store", value: 1 },
      { source: "Library", target: "Babylon Bus", value: 164 },
      { source: "Library", target: "Refectory Tower", value: 30 },
      { source: "Library", target: "Wok Store", value: 7 },
      { source: "Mode de Vue", target: "Babylon Bus", value: 3 },
      { source: "Mode de Vue", target: "Hostem", value: 1 },
      { source: "Mode de Vue", target: "Library", value: 1 },
      { source: "Mode de Vue", target: "Refectory Tower", value: 4 },
      { source: "Mode de Vue", target: "Wok Store", value: 108 },
      { source: "Refectory Tower", target: "Babylon Bus", value: 168 },
      { source: "Refectory Tower", target: "Hostem", value: 218 },
      { source: "Refectory Tower", target: "Library", value: 51 },
      { source: "Refectory Tower", target: "Mode de Vue", value: 5 },
      { source: "Refectory Tower", target: "Wok Store", value: 49 },
      { source: "Wok Store", target: "Babylon Bus", value: 21 },
      { source: "Wok Store", target: "Hostem", value: 1 },
      { source: "Wok Store", target: "Library", value: 6 },
      { source: "Wok Store", target: "Mode de Vue", value: 110 },
      { source: "Wok Store", target: "Refectory Tower", value: 53 },
    ],
  };

  const mockData2 = {
    nodes: [
      { name: "Babylon Bus" },
      { name: "Hostem" },
      { name: "Library" },
      { name: "Mode de Vue" },
      { name: "Refectory Tower" },
      { name: "Wok Store" },
    ],
    links: [
      { source: 0, target: 1, value: 2 },
      { source: 0, target: 2, value: 164 },
      { source: 0, target: 3, value: 3 },
      { source: 0, target: 4, value: 178 },
      { source: 0, target: 5, value: 25 },
      { source: 1, target: 4, value: 221 },
      { source: 1, target: 5, value: 1 },
      { source: 2, target: 0, value: 164 },
      { source: 2, target: 4, value: 30 },
      { source: 2, target: 5, value: 7 },
      { source: 3, target: 0, value: 3 },
      { source: 3, target: 1, value: 1 },
      { source: 3, target: 2, value: 1 },
      { source: 3, target: 4, value: 4 },
      { source: 3, target: 5, value: 108 },
      { source: 4, target: 0, value: 168 },
      { source: 4, target: 1, value: 218 },
      { source: 4, target: 2, value: 51 },
      { source: 4, target: 3, value: 5 },
      { source: 4, target: 5, value: 49 },
      { source: 5, target: 0, value: 21 },
      { source: 5, target: 1, value: 1 },
      { source: 5, target: 2, value: 6 },
      { source: 5, target: 3, value: 110 },
      { source: 5, target: 4, value: 53 },
    ],
  };
  const colors1 = [
    "#c7297c",
    "#f1425f",
    "#ff713b",
    "#ffa600",
    "#323389",
    "#0058A9",
    "#0077B8",
    "#0095BA",
    "#00B0B3",
    "#52C8AB",
    "#882e8c",
  ];

  const color = scaleLinear().range(colors1);
  // const color = scaleSequential(interpolateCool);

  // if window decreases, put margin-left in chart.
  useEffect(() => {
    // window.onresize = function(event) {
    //   console.log("x: " + window.innerWidth + "      y: " + window.innerHeight);

    //   if (window.innerWidth < 1320) {
    //     setNewMargin(55);
    //   }
    // };

    // if (window.innerWidth < 1320) {
    //   // console.log(
    //   //   "x 2: " + window.innerWidth + "      y 2: " + window.innerHeight
    //   // );
    //   setNewMargin(65);
    // }
    // if (window.innerWidth < 768) {
    //   setNewMargin(95);
    // }
    if (window.innerWidth > 1205) {
      setNewMargin(65);
    }
    if (window.innerWidth < 1205) {
      setNewMargin(85);
    }
    if (window.innerWidth < 768) {
      setNewMargin(105);
    }
    if (window.innerWidth < 600) {
      setNewMargin(155);
    }
  }, []);

  const width = 800;
  const height = 600;
  const margin = { top: 0, left: 0, right: 0, bottom: 0 };
  // const margin = { top: 0, left: 300, right: 0, bottom: 0 };

  // Parsed data
  const parsedData =
    zoneOccupancyForSankey &&
    JSON.parse(JSON.stringify(zoneOccupancyForSankey));

  const onHoverPaths = (link, highlightLinkIndexes, i) => {
    if (link.circular && highlightLinkIndexes.includes(i)) {
      return "#ffa600";
      // return "#004e61";
    }
    if (!link.circular && !highlightLinkIndexes.includes(i)) {
      return "#323389";
    }
    if (link.circular) {
      return "#0095BA";
    }
    if (!link.circular) {
      return "#f1425f";
      // return "#0f0f2a";
    }
  };

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const handleMouseOverBar = (event, datum) => {
    // console.log("🚀 ~ datum", datum);
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x - 50,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  };

  // const onHoverShowDivNodes = (node, i, e) => {
  //   if (node) {
  //     document.getElementById(`node-${i}`);
  //     if (document.getElementById(`node-${i}`)) {
  //       const el = document.getElementById(`node-${i}`);

  //       function getPos(e) {
  //         const x = e?.clientX - 270;
  //         const y = e?.clientY - 270;
  //         const cursor = "Your Mouse Position Is : " + x + " and " + y;
  //         // console.log(cursor, "cursor");
  //         if (e) {
  //           el.style.position = "absolute";
  //           el.style.left = x + "px";
  //           el.style.top = y + "px";
  //           el.style.cursor = "pointer";
  //         }
  //       }
  //       getPos(e);

  //       document.getElementById(`node-${i}`).style.display = "block";
  //       document.getElementById(`spanValueNode-${i}`).innerHTML = node.value;
  //     }
  //   }
  // };

  // const onHoverRemoveDivNodes = (node, i) => {
  //   if (node) {
  //     document.getElementById(`node-${i}`);
  //     if (document.getElementById(`node-${i}`)) {
  //       document.getElementById(`node-${i}`).style.display = "none";
  //     }
  //   }
  // };

  // ____________ useEffects ___________ \\

  useEffect(() => {
    // console.log(zoneOccupancyForSankey, "zoneOccupancyForSankey");
    // console.log(parsedData, "parsedData");
    // console.log(parsedData?.links.length, "parsedData?.links.length");
    // console.log(tooltipOpen, "tooltipOpen");
    // console.log(tooltipData, "tooltipData");
    // console.log(tooltipTop, "tooltipTop");
    // console.log(tooltipLeft, "tooltipLeft");
    // console.log(newMargin, "newMargin");
  }, [
    newMargin,
    zoneOccupancyForSankey,
    parsedData,
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
  ]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='People circular flow '
        subtitle='People circular flow through zones'
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Floor Plans
            </Typography>
          </>
        }
      />
      <CardBody style={{ height: "600px", width: "auto" }}>
        <div
          // className='d-flex justify-content-center align-items-center'
          // style={{
          //   height: "100%",
          //   width: "100%",
          //   textAlign: "center",
          //   display: "block",
          //   margin: "auto",
          // }}
          id='svgSankeyContainerCircular'
          // ref={svgRef}
        >
          {parsedData && parsedData?.links.length !== 0 ? (
            <svg
              // ref={svgRef}
              viewBox={`0 0 ${width}  ${height} `}
              preserveAspectRatio='xMidYMid meet'
              width='100%'
              height='100%'
              // width={width + margin.left + margin.right}
              // height={height}
              className='svgSankeyCircular'
              id='svgSankeyId'
            >
              {parsedData && (
                <SankeyCircular
                  top={margin.top}
                  left={margin.left}
                  right={margin.right}
                  data={parsedData}
                  size={[width, height]}
                  nodeWidth={15}
                  nodePadding={40}
                  nodePaddingRatio={0.1}
                  nodeId={(d) => d.name}
                  iterations={32}
                  extent={[
                    [1, 1],
                    [width - newMargin, height - 120],
                  ]}
                >
                  {({ data }) => {
                    return (
                      <Group>
                        {/* Chart paths */}
                        <Group strokeOpacity={0.5}>
                          {data.links.map((link, i) => {
                            return (
                              <path
                                id={`link-${i}`}
                                key={`link-${i}`}
                                d={link.path}
                                // stroke={link.circular ? "#0095BA" : "#323389"}
                                stroke={onHoverPaths(
                                  link,
                                  highlightLinkIndexes,
                                  i
                                )}
                                strokeWidth={Math.max(1, link.width)}
                                className='pathSvg'
                                stroke-opacity={0.5}
                                opacity={0.7}
                                fill='none'
                                onMouseOver={(e) => {
                                  setShowLegend("link");
                                  handleMouseOverBar(e, link);
                                  setHighlightLinkIndexes([i]);
                                }}
                                onMouseOut={(e) => {
                                  hideTooltip();
                                  setHighlightLinkIndexes([]);
                                }}
                              />
                            );
                          })}
                        </Group>

                        {data.nodes.map((node, i) => (
                          <>
                            <Group
                              top={node.y0}
                              left={node.x0}
                              key={`node-${i}`}
                            >
                              {/* Chart node boxes */}
                              <rect
                                id={`rect-${i}`}
                                className='rectNodeCircular'
                                width={node.x1 - node.x0}
                                height={node.y1 - node.y0}
                                // fill="blue"
                                fill={color(node.depth)}
                                opacity={0.9}
                                stroke='white'
                                strokeWidth={2}
                                onMouseOver={(e) => {
                                  setShowLegend("node");
                                  handleMouseOverBar(e, node);
                                  // document.getElementById(
                                  //   `rect-${i}`
                                  // ).style.cursor = "pointer";
                                  // onHoverShowDivNodes(node, i, e);
                                  setHighlightLinkIndexes([
                                    ...node.sourceLinks.map((l) => l.index),
                                    ...node.targetLinks.map((l) => l.index),
                                  ]);
                                }}
                                onMouseOut={(e) => {
                                  hideTooltip();
                                  // onHoverRemoveDivNodes(node, i);
                                  setHighlightLinkIndexes([]);
                                }}
                              />
                            </Group>
                          </>
                        ))}

                        {data.nodes.map((node, i) => (
                          <>
                            <Group
                              top={node.y0}
                              left={node.x0}
                              key={`node-${i}`}
                            >
                              {/* Chart nodes text */}
                              <Text
                                x={18}
                                y={(node.y1 - node.y0) / 2}
                                verticalAnchor='middle'
                                fill='#262a2b'
                                // style={{
                                //   fontWeight: "500",
                                //   fontSize: "12px",
                                //   fontFamily: "Poppins",
                                // }}
                                className='textSvg'
                              >
                                {node.name}
                              </Text>
                            </Group>
                          </>
                        ))}
                      </Group>
                    );
                  }}
                </SankeyCircular>
              )}
            </svg>
          ) : (
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#3B4143",
              }}
            >
              No data to display.
            </p>
          )}

          {tooltipOpen && showLegend === "node" && (
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              <div
                className='row'
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                  paddingRight: "0.5rem",
                  paddingLeft: "0.5em",
                }}
              >
                <div className='col'>
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    {tooltipData && tooltipData?.name}
                  </h6>
                  {zoneOccupancyForSankey &&
                    zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                      (l) => {
                        const nameWithLocation =
                          l.zone === tooltipData.name ? l.floor : null;
                        return (
                          <h6
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              color: "#3B4143",
                            }}
                          >
                            {nameWithLocation}
                          </h6>
                        );
                      }
                    )}
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    People count:{" "}
                    <span>{tooltipData && tooltipData?.value}</span>
                  </p>
                </div>
              </div>
            </TooltipWithBounds>
          )}

          {tooltipOpen && showLegend === "link" && (
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              <div
                className='row'
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                  paddingRight: "0.5rem",
                  paddingLeft: "0.5em",
                }}
              >
                <div className='col'>
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    {tooltipData &&
                      tooltipData?.source.name +
                        " → " +
                        tooltipData?.target.name}
                  </h6>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    People count:{" "}
                    <span>{tooltipData && tooltipData?.value}</span>
                  </p>
                </div>
              </div>
            </TooltipWithBounds>
          )}

          {/* {parsedData &&
            parsedData.nodes.map((node, i) => {
              return (
                <div
                  className='svgInfoDiv'
                  id={`node-${i}`}
                >
                  <div
                    className='row'
                    style={{
                      textAlign: "center",
                      padding: "1rem",
                    }}
                  >
                    <div
                      className='col'
                      style={{
                        fontWeight: "400",
                        fontSize: "10px",
                        fontFamily: "Poppins",
                        color: "#3B4143",
                      }}
                    >
                      <h6>{node && node.name}</h6>
                      <p style={{ fontSize: "12px" }}>
                        Total: <span id={`spanValueNode-${i}`}></span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })} */}
        </div>

        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (z, i) => <p key={i}>{z.zone}</p>
                )}
            </div>
            <div style={{ marginRight: "0.5rem", fontWeight: "500" }}>
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (f, i) => <p key={i}>- &nbsp;&nbsp;{f.floor}</p>
                )}
            </div>
            <div>
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (l, i) => <p key={i}>- &nbsp;&nbsp;{l.location}</p>
                )}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
};

// export const SankeyChartWithTooltip = withTooltip(SankeyChartPage);
