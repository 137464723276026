import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import { OverviewChart } from "./OverviewChart";
// import * as actions from "../../_redux/liveData/liveDataActions";
import * as actions from "../../../_redux/reporting/wifi/wifiActions";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function Overview({ queryParams }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const { allLocationsMerakiDevices } = useSelector(
  //   (state) => ({
  //     allLocationsMerakiDevices: state.reporting.allLocationsMerakiDevices,
  //   }),
  //   shallowEqual
  // );

  // const [allDevicesSerial, setAllDevicesSerial] = useState([]);
  const [graph, setGraph] = useState();

  // useEffect(() => {
  //   // Fetch Meraki Devices of all locations
  //   queryParams.locations &&
  //     dispatch(
  //       actions.fetchAllLocationsMerakiDevices({
  //         customerId: queryParams.customerId,
  //         locations: queryParams.locations,
  //       })
  //     );
  // }, [queryParams]);

  // useEffect(() => {
  //   // console.log("🚀 ~ allLocationsMerakiDevices", allLocationsMerakiDevices);
  //   const finalSerials = [];
  //   allLocationsMerakiDevices &&
  //     allLocationsMerakiDevices.map((d) =>
  //       d.wireless.forEach((ap) => {
  //         finalSerials.push(ap.serial);
  //       })
  //     );

  //   // console.log("🚀 ~ finalSerials", finalSerials);
  //   setAllDevicesSerial(finalSerials);
  // }, [allLocationsMerakiDevices]);

  // useEffect(() => {
  //   allDevicesSerial &&
  //     allDevicesSerial.length !== 0 &&
  //     console.log("🚀 ~ allDevicesSerial", allDevicesSerial);
  // }, [allDevicesSerial]);

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "UIProps.queryParams APsWorstSNR");
    // allDevicesSerial &&
    //   allDevicesSerial.length !== 0 &&
    //   queryParams &&
    //   queryParams.locations &&
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await OverviewChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Overview - Floors'
        subtitle='Net failures & SNR'
      ></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
