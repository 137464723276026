import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import { OverviewGlobalChart } from "../../../wifiSnapshot/APsHealth/OverviewGlobalChart";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function OverviewGlobalCustom({
  queryParams,
  showChart,
  setShowChart,
  showChartZones,
  setShowChartZones,
  hide,
}) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "queryParams 3");
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await OverviewGlobalChart(queryParams, showChart);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Overview - Global'
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() => {
                setShowChart({
                  ...showChart,
                  OverviewGlobalCustom: false,
                });
                setShowChartZones({
                  ...showChartZones,
                  OverviewGlobalCustom: false,
                });
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "420px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
