import moment from "moment";

export const handleLocationAccuracyResult = ({ result, queryParams }) => {
  if (!result) return;

  var labels = [];
  var series = [];
  var totalValue = 0;
  var samples = 0;

  result.forEach((val) => {
    if (!val[1]) return;
    const floor = queryParams.levels.find((floor) => floor.floorId === val[1]);
    labels.push(floor?.name);
    series.push(val[3]);
    totalValue = totalValue + val[2] * val[3];
    samples = samples + val[2];
  });

  return {
    labels: labels,
    series: series,
    total: totalValue / samples,
  };
};

export const handleLocationAccuracyPerDayResult = ({ result, queryParams }) => {
  if (!result) return;

  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (!val || !val[1]) return;
    const floor = queryParams.levels.find((floor) => floor.floorId === val[1]);
    data.push({
      name: floor?.name || "",
      count: val[3],
      date: val[0],
    });
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: floor?.name || "",
        count: val[3],
        date: val[0],
      });
    }
  });

  if (queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);

          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    const xaxis = {
      labels: {
        style: {
          colors: [
            "#626768",
            "#626768",
            "#626768",
            "#626768",
            "#626768",
            "#2ec4b6",
            "#F9CE1D",
          ],
          fontWeight: 500,
        },
      },
      tickPlacement: "between",
    };

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
      xaxis: xaxis,
    };
  }

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  let color = [];
  dates.forEach((val) => {
    if (moment(val).format("dd") === "Su") {
      color.push("#F9CE1D");
    } else if (moment(val).format("dd") === "Sa") {
      color.push("#2ec4b6");
    } else {
      color.push("#626768");
    }
  });

  const formattedDates = dates.map((val) => {
    return moment(val).format("ddd MMM D");
  });

  const xaxis = {
    labels: {
      style: {
        colors: color,
        fontWeight: 500,
      },
    },
    tickPlacement: "between",
  };

  return {
    labels: formattedDates,
    series: series,
    xaxis: xaxis,
  };
};

export const handleFloorRSSIResult = ({ result, queryParams }) => {
  if (!result) return;

  var data = [];

  result.forEach((value) => {
    if (!value[1]) return;
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === value[1]
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === value[0]
    );

    floor &&
      location &&
      data.push({
        location: location.netName,
        floorPlan: floor.name,
        sampleSize: value[2],
        numMacs: value[3],
        terrible: value[4],
        bad: value[5],
        good: value[6],
        excelent: value[7],
      });
  });

  const sortedDataByLocation = data.sort(
    (a, b) => (a.location < b.location) - (a.location > b.location)
  );

  return sortedDataByLocation;
};

export const handleFloorRSSIPerDayResult = ({ result, queryParams }) => {
  if (!result) return;

  var unique = [...new Set(result.map((item) => item[2]))];
  var filter = unique.filter((u) => {
    return u != null && u;
  });
  var final = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  filter.forEach((floor) => {
    var floorData = result.filter((f) => floor === f[2]);
    var data = [];

    floorData.forEach((val) => {
      if (!val) return;
      data.push({
        name: "Excelent",
        count: val[8],
        date: val[0],
      });
      data.push({
        name: "Good",
        count: val[7],
        date: val[0],
      });
      data.push({
        name: "Average",
        count: val[6],
        date: val[0],
      });
      data.push({
        name: "Bad",
        count: val[5],
        date: val[0],
      });
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          name: "Excelent",
          count: val[8],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Good",
          count: val[7],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Average",
          count: val[6],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Bad",
          count: val[5],
          date: val[0],
        });
      }
    });

    if (queryParams.timeframe === "month") {
      const weekdaysSumFloors = (array) => {
        let holderOne = [];
        array.forEach(function(d) {
          if (holderOne.some((val) => val.name === d.name)) {
            const index = holderOne.findIndex((val) => val.name === d.name);

            holderOne[index] = {
              ...holderOne[index],
              count: holderOne[index].count + d.count,
              counter: holderOne[index].counter + 1,
            };
          } else {
            holderOne.push({
              name: d.name,
              count: d.count,
              counter: 1,
            });
          }
        });
        return holderOne.map((val, i) => {
          return { name: val.name, value: Math.round(val.count / val.counter) };
        });
      };

      const mondayData = weekdaysSumFloors(mondaysTotal);
      const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
      const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
      const thursdayData = weekdaysSumFloors(thursdaysTotal);
      const fridayData = weekdaysSumFloors(fridaysTotal);
      const saturdayData = weekdaysSumFloors(saturdaysTotal);
      const sundayData = weekdaysSumFloors(sundaysTotal);

      let finalSeries = [];
      data.forEach(function(d) {
        if (!finalSeries.some((val) => val.name === d.name)) {
          finalSeries.push({
            name: d.name,
            data: [
              mondayData.find((val) => val.name === d.name)?.value || 0,
              tuesdayData.find((val) => val.name === d.name)?.value || 0,
              wednesdayData.find((val) => val.name === d.name)?.value || 0,
              thursdayData.find((val) => val.name === d.name)?.value || 0,
              fridayData.find((val) => val.name === d.name)?.value || 0,
              saturdayData.find((val) => val.name === d.name)?.value || 0,
              sundayData.find((val) => val.name === d.name)?.value || 0,
            ],
            type: "column",
          });
        }
      });

      const xaxis = {
        labels: {
          style: {
            colors: [
              "#626768",
              "#626768",
              "#626768",
              "#626768",
              "#626768",
              "#2ec4b6",
              "#F9CE1D",
            ],
            fontWeight: 500,
          },
        },
        tickPlacement: "between",
      };

      const floorName = queryParams.levels.find((f) => f.floorId === floor);

      final.push({
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        series: finalSeries,
        floorPlan: floorName?.name,
      });
      return;
    }

    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    var floorName = queryParams.levels.find((f) => f.floorId === floor);
    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    final.push({
      labels: formattedDates,
      series: series,
      floorPlan: floorName?.name,
    });
  });
  return final;
};

// export const handleFloorRSSIPerDayBadGlobalResult = ({
//   result,
//   queryParams,
// }) => {
//   if (!result) return;

//   //   console.log(result, "result");

//   var unique = [...new Set(result.map((item) => item[2]))];
//   var filter = unique.filter((u) => {
//     return u != null && u;
//   });
//   var final = [];
//   let mondaysTotal = [];
//   let tuesdaysTotal = [];
//   let wednesdaysTotal = [];
//   let thursdaysTotal = [];
//   let fridaysTotal = [];
//   let saturdaysTotal = [];
//   let sundaysTotal = [];

//   const finalLabels = [];
//   const finalSeries = [];

//   const handleDataSet = (finalSeries) => {
//     if (finalSeries) {
//       const seriesAscending = [];

//       finalSeries.forEach((f) => {
//         var reduced = f.data.reduce((a, b) => {
//           return Math.max(a, b);
//         });
//         // console.log("🚀 ~ reduced", reduced);
//         seriesAscending.push({ name: f.name, data: reduced });
//       });

//       var sorted = seriesAscending.sort((a, b) => (a.data < b.data ? 1 : -1));
//       // console.log(seriesAscending, "seriesAscending");
//       // console.log("🚀 ~ sorted", sorted);

//       // ↓ Remove all elements from index spliced
//       sorted.splice(5);
//       return sorted;
//     }
//   };

//   filter.forEach((floor) => {
//     var floorData = result.filter((f) => floor === f[2]);
//     var data = [];

//     var floorName = queryParams.levels.find((f) => f.floorId === floor);

//     floorData.forEach((val) => {
//       if (!val) return;
//       // data.push({
//       //   name: "Excelent",
//       //   count: val[8],
//       //   date: val[0],
//       // });
//       // data.push({
//       //   name: "Good",
//       //   count: val[7],
//       //   date: val[0],
//       // });
//       // data.push({
//       //   name: "Average",
//       //   count: val[6],
//       //   date: val[0],
//       // });
//       data.push({
//         name: floorName?.name,
//         count: val[5],
//         date: val[0],
//       });
//       if (val[0].includes("Mon")) {
//         //   mondaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   mondaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   mondaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         mondaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Tue")) {
//         //   tuesdaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   tuesdaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   tuesdaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         tuesdaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Wed")) {
//         //   wednesdaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   wednesdaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   wednesdaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         wednesdaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Thu")) {
//         //   thursdaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   thursdaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   thursdaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         thursdaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Fri")) {
//         //   fridaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   fridaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   fridaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         fridaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Sat")) {
//         //   saturdaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   saturdaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   saturdaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         saturdaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//       if (val[0].includes("Sun")) {
//         //   sundaysTotal.push({
//         //     name: "Excelent",
//         //     count: val[8],
//         //     date: val[0],
//         //   });
//         //   sundaysTotal.push({
//         //     name: "Good",
//         //     count: val[7],
//         //     date: val[0],
//         //   });
//         //   sundaysTotal.push({
//         //     name: "Average",
//         //     count: val[6],
//         //     date: val[0],
//         //   });
//         sundaysTotal.push({
//           name: floorName?.name,
//           count: val[5],
//           date: val[0],
//         });
//       }
//     });

//     if (queryParams.timeframe === "month") {
//       const weekdaysSumFloors = (array) => {
//         let holderOne = [];
//         array.forEach(function(d) {
//           if (holderOne.some((val) => val.name === d.name)) {
//             const index = holderOne.findIndex((val) => val.name === d.name);

//             holderOne[index] = {
//               ...holderOne[index],
//               count: holderOne[index].count + d.count,
//               counter: holderOne[index].counter + 1,
//             };
//           } else {
//             holderOne.push({
//               name: d.name,
//               count: d.count,
//               counter: 1,
//             });
//           }
//         });
//         return holderOne.map((val, i) => {
//           return { name: val.name, value: Math.round(val.count / val.counter) };
//         });
//       };

//       const mondayData = weekdaysSumFloors(mondaysTotal);
//       const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
//       const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
//       const thursdayData = weekdaysSumFloors(thursdaysTotal);
//       const fridayData = weekdaysSumFloors(fridaysTotal);
//       const saturdayData = weekdaysSumFloors(saturdaysTotal);
//       const sundayData = weekdaysSumFloors(sundaysTotal);

//       let finalSeries = [];
//       data.forEach(function(d) {
//         if (!finalSeries.some((val) => val.name === d.name)) {
//           finalSeries.push({
//             name: d.name,
//             data: [
//               mondayData.find((val) => val.name === d.name)?.value || 0,
//               tuesdayData.find((val) => val.name === d.name)?.value || 0,
//               wednesdayData.find((val) => val.name === d.name)?.value || 0,
//               thursdayData.find((val) => val.name === d.name)?.value || 0,
//               fridayData.find((val) => val.name === d.name)?.value || 0,
//               saturdayData.find((val) => val.name === d.name)?.value || 0,
//               sundayData.find((val) => val.name === d.name)?.value || 0,
//             ],
//             // type: "column",
//           });
//         }
//       });

//       const xaxis = {
//         labels: {
//           style: {
//             colors: [
//               "#626768",
//               "#626768",
//               "#626768",
//               "#626768",
//               "#626768",
//               "#2ec4b6",
//               "#F9CE1D",
//             ],
//             fontWeight: 500,
//           },
//         },
//         tickPlacement: "between",
//       };

//       const floorName = queryParams.levels.find((f) => f.floorId === floor);

//       final.push({
//         labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//         series: finalSeries,
//         //   floorPlan: floorName?.name,
//       });
//       return;
//     }

//     //  console.log(data, "data");

//     let dates = data
//       .map((item) => item.date)
//       .filter((item, index, array) => array.indexOf(item) === index);
//     //  console.log("🚀 ~ dates", dates);

//     const productTotals = data.reduce((obj, curr) => {
//       if (!obj[curr.name]) {
//         obj[curr.name] = [];
//       }
//       obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
//       return obj;
//     }, {});
//     //  console.log("🚀 ~ productTotals", productTotals);

//     const series = Object.entries(productTotals).map(([name, prodArr]) => {
//       return {
//         name: floorName?.name,
//         //   name: name,
//         data: dates.map((month, monthIndex) => {
//           if (!prodArr[monthIndex]) {
//             return 0;
//           } else {
//             return prodArr[monthIndex];
//           }
//         }),
//       };
//     });
//     // console.log("🚀 ~ series", series);

//     finalSeries.push({
//       name: series[0].name,
//       data: series[0].data,
//     });

//     //  let color = "#626768";
//     //  dates.forEach((val) => {
//     //    if (moment(val).format("dd") === "Su") {
//     //      color = "#F9CE1D";
//     //    }
//     //    if (moment(val).format("dd") === "Sa") {
//     //      color = "#2ec4b6";
//     //    }
//     //  });

//     const formattedDates = dates.map((val) => {
//       return moment(val).format("ddd MMM D");
//     });

//     finalLabels.push({
//       labels: formattedDates,
//       // color: color,
//       // series: series,
//       // floorPlan: floorName?.name,
//     });
//   });

//   //   console.log(finalLabels, "finalLabels");
//   // console.log("🚀 ~ finalSeries", finalSeries);

//   // const arr = [
//   //   { name: "bathroom", value: 54, timeStamp: 1562318089713 },
//   //   { name: "bathroom", value: 55, timeStamp: 1562318090807 },
//   //   { name: "bedroom", value: 48, timeStamp: 1562318092084 },
//   //   { name: "bedroom", value: 49, timeStamp: 1562318092223 },
//   //   { name: "room", value: 41, timeStamp: 1562318093467 },
//   // ];

//   // const seriesAscending = Object.values(
//   //   finalSeries.reduce((r, o) => {
//   //     r[o.name] = r[o.name] && r[o.name].data[0] > o.data[0] ? r[o.name] : o;

//   //     return r;
//   //   }, {})
//   // );

//   const handledData = handleDataSet(finalSeries);
//   // console.log(handledData, "handledData");

//   const finalGraphData = {
//     labels: finalLabels[0]?.labels,
//     series: handledData,
//   };
//   //   console.log("🚀 ~ finalGraphData", finalGraphData);
//   //   console.log(queryParams, "queryParams");

//   if (queryParams.timeframe === "month") {
//     //  console.log(final, "final");

//     const finalSeriesPerMonth = [];
//     final.forEach((s) => {
//       finalSeriesPerMonth.push({
//         name: s.series[0].name,
//         data: s.series[0].data,
//       });
//     });

//     const finalGraphDataPerMonth = {
//       labels: final[0]?.labels,
//       series: finalSeriesPerMonth,
//     };
//     //  console.log("🚀 ~ finalGraphDataPerMonth", finalGraphDataPerMonth);

//     return finalGraphDataPerMonth;
//   } else {
//     return finalGraphData;
//   }
// };

export const handleZoneLocationAccuracyResult = ({ result, queryParams }) => {
  if (!result) return;

  var values = [];

  result.forEach((value) => {
    if (!value[1]) return;
    values.push({ x: value[2], y: value[5] });
  });

  return [{ data: values }];
};

export const handleZoneLocationAccuracyPerDayResult = ({
  result,
  queryParams,
}) => {
  if (!result) return;

  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (!val || !val[3]) return;
    data.push({
      name: val[3],
      count: val[6],
      date: val[0],
    });
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[3],
        count: val[6],
        date: val[0],
      });
    }
  });

  if (queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);

          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    const xaxis = {
      labels: {
        style: {
          colors: [
            "#626768",
            "#626768",
            "#626768",
            "#626768",
            "#626768",
            "#2ec4b6",
            "#F9CE1D",
          ],
          fontWeight: 500,
        },
      },
      tickPlacement: "between",
    };

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
      xaxis: xaxis,
    };
  }

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  let color = [];
  dates.forEach((val) => {
    if (moment(val).format("dd") === "Su") {
      color.push("#F9CE1D");
    } else if (moment(val).format("dd") === "Sa") {
      color.push("#2ec4b6");
    } else {
      color.push("#626768");
    }
  });

  const formattedDates = dates.map((val) => {
    return moment(val).format("ddd MMM D");
  });

  const xaxis = {
    labels: {
      style: {
        colors: color,
        fontWeight: 500,
      },
    },
    tickPlacement: "between",
  };

  return {
    labels: formattedDates,
    series: series,
    xaxis: xaxis,
  };

  //return { labels: formattedDates, series: series }
};

export const handleZoneRSSIResult = ({ result, queryParams }) => {
  if (!result) return;

  var data = [];

  result.forEach((value) => {
    if (!value) return;
    data.push({
      zoneName: value[2],
      sampleSize: value[3],
      numMacs: value[4],
      terrible: value[5],
      bad: value[6],
      good: value[7],
      excelent: value[8],
    });
  });

  return data;
};

export const handleZoneRSSIPerDayResult = ({ result, queryParams }) => {
  if (!result) return;

  var unique = [...new Set(result.map((item) => item[3]))];
  var filter = unique.filter((u) => {
    return u != null && u;
  });
  var final = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  filter.forEach((zone) => {
    var zoneData = result.filter((f) => zone === f[3]);
    var data = [];

    zoneData.forEach((val) => {
      if (!val) return;
      data.push({
        name: "Excelent",
        count: val[9],
        date: val[0],
      });
      data.push({
        name: "Good",
        count: val[8],
        date: val[0],
      });
      data.push({
        name: "Average",
        count: val[7],
        date: val[0],
      });
      data.push({
        name: "Bad",
        count: val[6],
        date: val[0],
      });
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        mondaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        tuesdaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        wednesdaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        thursdaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        fridaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        saturdaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          name: "Excelent",
          count: val[9],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Good",
          count: val[8],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Average",
          count: val[7],
          date: val[0],
        });
        sundaysTotal.push({
          name: "Bad",
          count: val[6],
          date: val[0],
        });
      }
    });

    if (queryParams.timeframe === "month") {
      const weekdaysSumFloors = (array) => {
        let holderOne = [];
        array.forEach(function(d) {
          if (holderOne.some((val) => val.name === d.name)) {
            const index = holderOne.findIndex((val) => val.name === d.name);

            holderOne[index] = {
              ...holderOne[index],
              count: holderOne[index].count + d.count,
              counter: holderOne[index].counter + 1,
            };
          } else {
            holderOne.push({
              name: d.name,
              count: d.count,
              counter: 1,
            });
          }
        });
        return holderOne.map((val, i) => {
          return { name: val.name, value: Math.round(val.count / val.counter) };
        });
      };

      const mondayData = weekdaysSumFloors(mondaysTotal);
      const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
      const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
      const thursdayData = weekdaysSumFloors(thursdaysTotal);
      const fridayData = weekdaysSumFloors(fridaysTotal);
      const saturdayData = weekdaysSumFloors(saturdaysTotal);
      const sundayData = weekdaysSumFloors(sundaysTotal);

      let finalSeries = [];
      data.forEach(function(d) {
        if (!finalSeries.some((val) => val.name === d.name)) {
          finalSeries.push({
            name: d.name,
            data: [
              mondayData.find((val) => val.name === d.name)?.value || 0,
              tuesdayData.find((val) => val.name === d.name)?.value || 0,
              wednesdayData.find((val) => val.name === d.name)?.value || 0,
              thursdayData.find((val) => val.name === d.name)?.value || 0,
              fridayData.find((val) => val.name === d.name)?.value || 0,
              saturdayData.find((val) => val.name === d.name)?.value || 0,
              sundayData.find((val) => val.name === d.name)?.value || 0,
            ],
            type: "column",
          });
        }
      });

      const xaxis = {
        labels: {
          style: {
            colors: [
              "#626768",
              "#626768",
              "#626768",
              "#626768",
              "#626768",
              "#2ec4b6",
              "#F9CE1D",
            ],
            fontWeight: 500,
          },
        },
        tickPlacement: "between",
      };

      final.push({
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        series: finalSeries,
        zoneName: zone,
      });
      return;
    }

    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    final.push({
      labels: formattedDates,
      series: series,
      zoneName: zone,
    });
  });
  return final;
};
