import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
// import * as d3 from "d3";
// import { Chord } from "./initChord";
// import "./chordStyles.css";
// import ChordDiagram from "react-chord-diagram";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import ChordDiagram from "react-chord-diagram";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function ChordDependencyCustom({
  showChartZones,
  setShowChartZones,
  hide,
}) {
  const classes = useStyles();

  const { isLoading, zoneOccupancyForChord } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneOccupancyFetchedForChordLoading,
      zoneOccupancyForChord: state.reporting.zoneOccupancyForChord,
    }),
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const colors1 = [
    "#323389",
    "#0058A9",
    "#0077B8",
    "#0095BA",
    "#00B0B3",
    "#52C8AB",
    "#882e8c",
    "#c7297c",
    "#f1425f",
    "#ff713b",
    "#ffa600",
  ];

  // const ColorDepartments = zoneOccupancyForChord?.zones.map(getRandomColor);
  // const ColorDepartments = Departments.map(getRandomColor);

  // useEffect(() => {
  //   console.log(zoneOccupancyForChord, "zoneOccupancyForChord");
  // }, [zoneOccupancyForChord]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='People zone movements'
        //   subtitle="Daily number of people"
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Floor Plans
            </Typography>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChartZones({
                  ...showChartZones,
                  ChordDependencyCustom: false,
                })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <CardBody style={{ height: "450px" }}>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: "100%" }}
        >
          <ChordDiagram
            matrix={zoneOccupancyForChord?.matrix || []}
            // matrix={matrix}
            componentId={3}
            width={450}
            height={450}
            style={{
              font: "12px Roboto, sans-serif",
              color: "#0000",
              padding: "1rem",
              letterSpacing: "0.1px",
              // border: "2px solid",
            }}
            groupLabels={zoneOccupancyForChord?.zones || []}
            // groupLabels={Departments}
            groupColors={colors1}
            // groupColors={ColorDepartments}
            persistHoverOnClick={true}
            outerRadius={130}
            innerRadius={115}
          />
        </div>
        {/* <svg ref={svgRef} style={{ border: "2px solid green" }}></svg> */}

        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (z, i) => <p key={i}>{z.zone}</p>
                )}
            </div>
            <div style={{ marginRight: "0.5rem", fontWeight: "500" }}>
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (f, i) => <p key={i}>- &nbsp;&nbsp;{f.floor}</p>
                )}
            </div>
            <div>
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (l, i) => <p key={i}>- &nbsp;&nbsp;{l.location}</p>
                )}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
}
