import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_partials/Card"
import Chart from "react-apexcharts"

export function ZoneOccupancy() {
  const { isLoading, occupancy } = useSelector((state) => ({
    isLoading: state.reporting.zoneOccupancyLoading,
    occupancy: state.reporting.zoneOccupancy,
  }), shallowEqual)

  return <Card isLoading={isLoading}>
    <CardHeader
      title='Occupancy'
      subtitle="Daily number of people"
    />
    <CardBody style={{ height: "380px" }}>
      <Chart
        options={getChartOptions(occupancy)}
        series={occupancy?.series || []}
        type='bar'
        height="100%"
      />
    </CardBody>
  </Card>
}

function getChartOptions(occupancy) {
  return {
    chart: {
      type: "bar",
      stacked: false,
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: "OccupancyByZone",
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'Value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: "OccupancyByZone",
          },
          png: {
            filename: "OccupancyByZone",
          }
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display."
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
    },
    colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    stroke: {
      show: true,
      width: 0,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: occupancy && [occupancy.series.length - 1],
      style: {
        colors: ["#546E7A"],
      },
      background: {
        enabled: true,
      },
    },
    labels: occupancy?.labels || [],
    xaxis: {
      //Default xaxis so it doesnt break when new options arrive
      tickPlacement: "between",
      ...occupancy?.xaxis
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    yaxis: {
      title: {
        text: "People count",
      },
    },
  }
}
