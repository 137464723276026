import {
  firestore as db,
  //firebaseApp as firebase,
} from "../../../../firebase";

const customersRef = db.collection("Customers");

// READ
export function getFloorPlans(customerId) {
  if (!customerId) {
    return Promise.resolve(null);
  }

  return customersRef
    .doc(customerId)
    .collection(`FloorPlans`)
    .get();
}

export function getZones({ customerId, netId }) {
  if (!customerId || !netId) {
    return Promise.resolve(null);
  }

  return customersRef
    .doc(customerId)
    .collection(`Zones`)
    .where("netId", "==", netId)
    .get();
}

export function saveZones({ data, customerId, netId }) {
  return Promise.all(
    data.map((zone) => {
      console.log(zone, "zone no mapzone ");
      if (zone.id !== undefined && zone.delete) {
        return customersRef
          .doc(`${customerId}`)
          .collection("Zones")
          .doc(zone.id)
          .delete();
      }

      if (zone.id !== undefined && zone.edit) {
        return customersRef
          .doc(`${customerId}`)
          .collection("Zones")
          .doc(zone.id)
          .set({
            name: zone.name,
            color: zone.color,
            polygon: zone.polygon,
            wirelessAP: zone.wirelessAP,
            floorPlanId: zone.floorPlanId,
            netId: netId,
            limit: zone.limit,
          });
      }
      if (zone.id === undefined) {
        return customersRef
          .doc(`${customerId}`)
          .collection("Zones")
          .doc()
          .set({
            name: zone.name,
            color: zone.color,
            polygon: zone.polygon,
            wirelessAP: zone.wirelessAP,
            floorPlanId: zone.floorPlanId,
            netId: netId,
            limit: zone.limit,
          });
      }
      return null;
    })
  );
}
