/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react"

import Leaflet from 'leaflet'
import { Map, Marker, FeatureGroup, TileLayer } from "react-leaflet"
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { EditControl } from "react-leaflet-draw"
import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-draw/dist/leaflet.draw.css'
//import "leaflet-distortableimage"
import "@turf/angle"

import { useSelector, useDispatch } from "react-redux"
import * as actions from "../../_redux/mapZones/mapZonesActions"
import classifyPoint from "robust-point-in-polygon"
import { useUIContext } from "../UIContext"
import { firestore } from "../../../../firebase"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import firebase from "firebase/app"
import * as turf from "@turf/turf"
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay'
import Slider from '@material-ui/core/Slider';

delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

const deskMarker = new Leaflet.Icon({
  iconUrl: require('../../../assets/deskBlack.svg'),
  iconRetinaUrl: require('../../../assets/deskBlack.svg'),
  iconAnchor: [10, 15],
  popupAnchor: [0, -15],
  iconSize: [20, 20],
})


export function MapWidget2() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()
  const [map, setMap] = useState()
  const [map2, setMap2] = useState()
  const [map3, setMap3] = useState()
  const [map4, setMap4] = useState()
  const [map5, setMap5] = useState()

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData()
  }, [selectedCustomer, UIProps.selectedFloor])


  {/* UseEffect */ }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  const onCornersUpdated = (e) => {
    console.log(e)
  }

  const [photo, setPhoto] = useState()

  const uploadPhoto = (e) => {
    setPhoto(URL.createObjectURL(e.target.files[0]))
  }
  const [opacity, setOpacity] = useState(0.5)

  const handleChange = (event, newValue) => {
    setOpacity(newValue)
  }

  useEffect(() => {
    if (floorPlanFiltered && map2) {

      let overlay = new Leaflet.imageOverlay.rotated(
        floorPlanFiltered.imageUrl,
        { lat: 38.5779350006989, lng: -9.157934971289869 },
        { lat: 38.57802306815716, lng: -9.157988279921778 },
        { lat: 38.57786501844528, lng: -9.158135464507977 },
      ).addTo(map2.leafletElement)

      Leaflet.marker([38.5779350006989, -9.157934971289869]).addTo(map2.leafletElement)
      Leaflet.marker([38.57802306815716, -9.157988279921778]).addTo(map2.leafletElement)
      Leaflet.marker([38.57786501844528, -9.158135464507977]).addTo(map2.leafletElement)
      Leaflet.marker([38.57794941651048, -9.158187432171509]).addTo(map2.leafletElement)
    }
  }, [floorPlanFiltered, map2])

  useEffect(() => {
    if (floorPlanFiltered && map3) {
      var imageUrl = floorPlanFiltered.imageUrl
      var imageBounds = [[38.5779350006989, -9.157934971289869], [38.57786501844528, -9.158135464507977]]

      let overlay = new Leaflet.imageOverlay(
        imageUrl,
        imageBounds
      ).addTo(map3.leafletElement)

      /*let overlay = new Leaflet.imageOverlay(
        floorPlanFiltered.imageUrl,
        [[38.5779350006989, -9.157934971289869]
        [38.57786501844528, -9.158135464507977]]
      ).addTo(map3.leafletElement)*/

      /*var polygon = Leaflet.polygon([
        [38.5779350006989, -9.157934971289869],
        [38.57802306815716, -9.157988279921778],
        [38.57786501844528, -9.158135464507977]], { transform: true }).addTo(map3.leafletElement);

      polygon.transform.enable()*/
    }
  }, [floorPlanFiltered, map3])

  useEffect(() => {
    if (floorPlanFiltered && map4) {
      var angle = turf.angle(
        [38.5779454849258, -9.158180569016103],
        [38.577867377398775, -9.158180569016103],
        [38.577867377398775, -9.158132891879665],
      )
      console.log("Angle: ", angle)

      var poly = turf.polygon([[
        [38.5779454849258, -9.158180569016103],
        [38.5780414155307, -9.157926866265157],
        [38.57798349023541, -9.157892350520354],
        [38.577995022877126, -9.157855495805975],
        [38.57791350802901, -9.15780921905652],
        [38.57786606686905, -9.157936004887755],
        [38.577941553340885, -9.15798832919908],
        [38.577929496479186, -9.158023882897783],
        [38.57791245960593, -9.15801449135472],
        [38.577867377398775, -9.158132891879665],
        [38.5779454849258, -9.158180569016103],
      ]])

      var options = { pivot: [38.577867377398775, -9.158132891879665] }

      var rotatedPoly = turf.transformRotate(poly, 260, options)

      console.log("Rotated: ", rotatedPoly)

      var polygon = Leaflet.polygon(
        rotatedPoly.geometry.coordinates,
        {
          fillColor: "red",
          weight: 2,
          opacity: 1,
          color: 'red',
          fillOpacity: 0.5
        }
      ).addTo(map4.leafletElement)

      var latlngs = [
        [38.5779454849258, -9.158180569016103],
        [38.5780414155307, -9.157926866265157],
        [38.57798349023541, -9.157892350520354],
        [38.577995022877126, -9.157855495805975],
        [38.57791350802901, -9.15780921905652],
        [38.57786606686905, -9.157936004887755],
        [38.577941553340885, -9.15798832919908],
        [38.577929496479186, -9.158023882897783],
        [38.57791245960593, -9.15801449135472],
        [38.577867377398775, -9.158132891879665],
      ]

      var polygon = Leaflet.polygon(
        latlngs,
        {
          fillColor: "grey",
          weight: 2,
          opacity: 1,
          color: 'black',
          fillOpacity: 0.5
        }
      ).addTo(map4.leafletElement)
      /*
            var latlngs = [
              [38.5779454849258, -9.158180569016103],
              [38.57796029389365, -9.158141506352582],
              [38.577929496479186, -9.158121090241679],
              [38.57791665329819, -9.158162681360928]
            ]
      
            var polygon = Leaflet.polygon(
              latlngs,
              {
                fillColor: "white",
                weight: 2,
                opacity: 1,
                color: 'white',
                fillOpacity: 0.9
              }
            ).addTo(map4.leafletElement)
      
            polygon.bindTooltip("Office")*/
    }
  }, [floorPlanFiltered, map4])

  useEffect(() => {
    if (floorPlanFiltered && map4) {
      var poly = {
        "geometry": {
          "coordinates": [
            [
              [
                -9.158160309789388,
                38.577942339657895
              ],
              [
                -9.158124420678426,
                38.57788651112967
              ],
              [
                -9.157960662133487,
                38.57794764734656
              ],
              [
                -9.157997023348601,
                38.57800596521062
              ],
              [
                -9.158160309789388,
                38.577942339657895
              ]
            ]
          ],
          "type": "Polygon"
        },
        "type": "Feature",
        "properties": {}
      }
      Leaflet.geoJSON(poly).addTo(map5.leafletElement)
    }
  }, [floorPlanFiltered, map5])

  const [edit, setEdit] = useState("rotate")

  return <div>
    <input type="file" onChange={uploadPhoto} />
    <button onClick={e => setEdit("rotate")}>Rotate</button>
    <button onClick={e => setEdit("distort")}>Distort</button>
    <button onClick={e => setEdit("translate")}>Translate</button>
    <button onClick={e => setEdit("scale")}>Scale</button>
    <br />
    <br />
    Opacity
    <br />
    <Slider
      value={opacity}
      onChange={handleChange}
      step={0.1}
      min={0}
      max={1}
    />
    <br />
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        noWrap={true}
        attribution=""
        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
      />
      {photo && <ReactDistortableImageOverlay
        //url="https://www.lettingagenttoday.co.uk/upload/Floorplans-Generic-400x310.jpg"
        url={photo}
        editMode={edit}
        onCornersUpdated={onCornersUpdated}
        //onWellKnownTextUpdated={this.onWellKnownTextUpdated.bind(this)}
        opacity={opacity}
        corners={[
          new Leaflet.latLng(38.57791706312266, -9.15795247569133),
          new Leaflet.latLng(38.57803202428736, -9.15800739325824),
          new Leaflet.latLng(38.577837977108935, -9.158118029643585),
          new Leaflet.latLng(38.57795293827363, -9.158172947210494)
        ]}
      />}
    </Map>}
    <br />
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap2(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        noWrap={true}
        attribution=""
        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
      />
    </Map>}
    <br />
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap3(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        noWrap={true}
        attribution=""
        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
      />
    </Map>}
    <br />
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap4(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        noWrap={true}
        attribution=""
        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
      />
    </Map>}
    <br />
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap5(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        noWrap={true}
        attribution=""
        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
      />
    </Map>}
  </div>
}





/*export function MapWidget() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()
  const [map, setMap] = useState()
  const [editableFG, setEditableFG] = useState()
  const [zones, setZones] = useState([])
  const [layersEdit, setLayersEdit] = useState()
  const [layersDelete, setLayersDelete] = useState()
  const [colorNumber, setColorNumber] = useState(0)
  const [points, setPoints] = useState([])
  const [markers, setMarkers] = useState([])

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
          setPoints(acessPoints)
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData();
  }, [selectedCustomer, UIProps.selectedFloor]);


  {/* UseEffect * }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      setZones([])
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  useEffect(() => {
    var data = []
    console.log("FloorPlan X: ", floorPlanFiltered?.width)
    console.log("FloorPlan Y: ", floorPlanFiltered?.height)
    console.log("Points: ", points)
    floorPlanFiltered && points.forEach((value) => {
      console.log("Point X: ", value.loc.x)
      console.log("Point Y: ", value.loc.y)
      var x = (value.loc.x * 100) / floorPlanFiltered.width
      var y = (value.loc.y * 100) / floorPlanFiltered.height
      console.log("X: ", x + "%")
      console.log("Y: ", (100 - y) + "%")
      data.push({
        id: value.clientMac,
        name: value.clientMac,
        x: x + "%",
        y: (100 - y) + "%",
      })
    })
    setMarkers(data)
  }, [points])

/*
  useEffect(() => {
    if (floorPlanFiltered) {
      var data = []
      console.log("FloorPlan X: ", floorPlanFiltered?.width)
      console.log("FloorPlan Y: ", floorPlanFiltered?.height)
      var from = turf.point([floorPlanFiltered?.bottomLeftCorner.lat, floorPlanFiltered?.bottomLeftCorner.lng])
      var to = turf.point([floorPlanFiltered?.bottomRightCorner.lat, floorPlanFiltered?.bottomRightCorner.lng])
      var options = { units: 'meters' }

      var xDistance = turf.distance(from, to, options)
      var from = turf.point([floorPlanFiltered?.bottomLeftCorner.lat, floorPlanFiltered?.bottomLeftCorner.lng])
      var to = turf.point([floorPlanFiltered?.topLeftCorner.lat, floorPlanFiltered?.topLeftCorner.lng])
      var options = { units: 'meters' }

      var yDistance = turf.distance(from, to, options)
      console.log("FloorPlan X: ", xDistance)
      console.log("FloorPlan Y: ", yDistance)

      points.forEach((value) => {
        console.log("Point X: ", value.loc.x)
        console.log("Point Y: ", value.loc.y)
        var x = (value.loc.x * 100) / xDistance
        var y = (value.loc.y * 100) / yDistance
        console.log("X: ", x + "%")
        console.log("Y: ", (100 - y) + "%")
        console.log("Client: ", value.loc.accurate, value.clientMac)
        data.push({
          id: value.clientMac,
          name: value.clientMac,
          x: x + "%",
          y: (100 - y) + "%",
        })
      })
      setMarkers(data)
    }
  }, [points])
*
  return (
    <>
      {floorPlanFiltered && <div style={{ position: "relative" }}>
        <img
          src={floorPlanFiltered.imageUrl}
          style={{ position: "relative", maxWidth: "100%", height: "auto" }}
        />
        {resources && resources.map((marker) => {
          return marker.percentageX && marker.floorPlanId == UIProps.selectedFloor && <img
            src={toAbsoluteUrl("/deskBlack.svg")}
            style={{ position: "absolute", left: marker.percentageX+"%", top: marker.percentageY+"%" }}
          />
        })}
      </div>
      }
    </>
  )
}
*/