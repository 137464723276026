import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "../../_redux/historicalData/historicalDataActions";
import { LinearProgress } from "@material-ui/core/";
import { Card, CardBody, CardHeader } from "../../../_partials/Card";
import { Button } from "@material-ui/core";

export function PeakOccupancy() {
  const dispatch = useDispatch();

  const { customer, location, floorPlans } = useSelector(
    (state) => ({
      customer: state.profile?.currentCustomer,
      location: state.profile?.currentLocation,
      floorPlans: state.basePage.floorPlans,
    }),
    shallowEqual
  );

  const {
    isLoading,
    dayFloorData,
    weekFloorData,
    monthFloorData,
  } = useSelector(
    (state) => ({
      isLoading: state.historicalData.listLoading,
      dayFloorData: state.historicalData.dayFloorData,
      weekFloorData: state.historicalData.weekFloorData,
      monthFloorData: state.historicalData.monthFloorData,
    }),
    shallowEqual
  );

  const [key, setKey] = useState("day");
  const [graphData, setGraphData] = useState();
  const [oldCustomer, setOldCustomer] = useState(customer.customerId);
  const [oldLocation, setOldLocation] = useState(location?.netId);

  useEffect(() => {
    if (!oldLocation || !location) {
      return;
    }
    if (customer.customerId === oldCustomer && location.netId === oldLocation) {
      key === "day" && dayFloorData && handleDataSet(dayFloorData);
      key === "week" &&
        (weekFloorData
          ? handleDataSet(weekFloorData)
          : !isLoading &&
            dispatch(
              actions.getWeekFloorData({
                customer: customer,
                location: location,
              })
            ));
      key === "month" &&
        (monthFloorData
          ? handleDataSet(monthFloorData)
          : !isLoading &&
            dispatch(
              actions.getMonthFloorData({
                customer: customer,
                location: location,
              })
            ));
    } else {
      setOldCustomer(customer.customerId);
      setOldLocation(location.netId);
      setKey("day");
      setGraphData();
      dispatch(actions.clearData());
      dispatch(
        actions.getDayFloorData({
          customer: customer,
          location: location,
        })
      );
    }
  }, [key, customer, location, dayFloorData, weekFloorData, monthFloorData]);

  const handleDataSet = (values) => {
    if (values) {
      var data = [];
      values.yAxis.forEach((f) => {
        var reduced = f.data.reduce((a, b) => {
          return Math.max(a, b);
        });
        data.push({ name: f.name, value: reduced });
      });
      var sorted = data.sort((a, b) => (a.value < b.value ? 1 : -1));
      setGraphData(sorted);
    }
  };

  return (
    <Card>
      <CardHeader
        title='Peak occupancy'
        subtitle='Busiest floors'
        toolbar={
          <>
            <Button
              color={key === "day" ? "primary" : "default"}
              onClick={() => setKey("day")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "day" && "#B8BCCA",
              }}
            >
              Day
            </Button>
            <Button
              color={key === "week" ? "primary" : "default"}
              onClick={() => setKey("week")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "week" && "#B8BCCA",
              }}
            >
              Week
            </Button>
            <Button
              color={key === "month" ? "primary" : "default"}
              onClick={() => setKey("month")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "month" && "#B8BCCA",
              }}
            >
              Month
            </Button>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "350px", overflow: "auto" }}>
        {isLoading && <LinearProgress color='primary' />}
        {graphData &&
          graphData.map((values, i) => {
            const floor =
              floorPlans && floorPlans.find((v) => v.id === values.name);
            const barProgress = (values.value * 100) / graphData[0].value;
            return (
              <div key={i} className='d-flex align-items-end mb-8'>
                <div
                  className='d-flex flex-column font-weight-bold mr-3'
                  style={{ width: "30%" }}
                >
                  <span className='text-truncate align-text-bottom'>
                    {floor?.name || values.name}
                  </span>
                </div>
                <div
                  className='d-flex flex-column font-weight-bolder'
                  style={{ width: "60%" }}
                >
                  <span>{values.value}</span>
                  <LinearProgress
                    color='primary'
                    variant='determinate'
                    value={barProgress}
                  />
                </div>
              </div>
            );
          })}
      </CardBody>
    </Card>
  );
}
