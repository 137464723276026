import React, { useState } from "react"
import {
   makeStyles,
   TextField,
   FormHelperText
} from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

const useStyles = makeStyles((theme) => ({
   autoComplete: {
      marginTop: "2px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      margin: 0,
      '& .MuiOutlinedInput-notchedOutline': {
         borderColor: '#FFFFFF',
         borderRadius: "5px"
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
         borderColor: '#8C8CA1',
         borderRadius: "5px",
      },
      '&:hover .MuiInputBase-input': {
         cursor: "pointer"
      },
      '& .MuiInputBase-root': {
         cursor: "pointer"
      },
   },
   helperText: {
      margin: 0,
      color: theme.palette.error.main
   },
}))

export function Autocomplete({
   field,
   form,
   label,
   withFeedbackLabel = true,
   allOptions = [],
   ...props
}) {
   const classes = useStyles()
   const { touched, errors, setFieldValue, setFieldTouched } = form
   const { name, value } = field
   const error = (withFeedbackLabel && touched[name] && errors[name])

   const [options, setOptions] = useState([])
   return <>
      {label && <b>{label}</b>}
      <MuiAutocomplete
         className={classes.autoComplete}
         autoComplete
         autoHighlight
         multiple
         size="small"
         noOptionsText=""
         options={options}
         value={value}
         onInputChange={(event, newInputValue) => {
            var newOptions = allOptions.filter(val => val.toLowerCase().includes(newInputValue.toLowerCase()))
            newOptions.push(newInputValue)
            !newInputValue && setOptions([])
            newInputValue && setOptions(newOptions)
         }}
         renderInput={(params) => (
            <TextField {...params} error={error ? true : false} variant="outlined" fullWidth />
         )}
         renderOption={(option, state) => {
            const match2 = match(option, state.inputValue)
            const parts = parse(option, match2)
            return <>
               {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                     {part.text}
                  </span>
               ))}
            </>
         }}
         onClose={() => {
            /*!touched[name] so ti doesn't set touched true when it's already true*/
            /*Without this errors will always be one update behind the real value*/
            !touched[name] && setFieldTouched(name, true)
         }}
         onChange={(event, newValue) => {
            !touched[name] && setFieldTouched(name, true)
            setFieldValue(name, newValue)
         }}
         {...props}
      />
      {error && <FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>}
   </>
}
