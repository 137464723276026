import React, { useEffect, useMemo, useState } from "react";
import "./PDF.css";
import Logo from "../../../../assets/ufind-purple-logo.png";
import { Modal } from "react-bootstrap";
import PDFModalWifi from "./PDFModalWifi";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { LocationAccuracyCustom } from "../CustomPDFCharts/WifiFloors/LocationAccuracyCustom";
import { LocationAccuracyPerDayCustom } from "../CustomPDFCharts/WifiFloors/LocationAccuracyPerDayCustom";
import { RSSICustom } from "../CustomPDFCharts/WifiFloors/RSSICustom";
import { Button, CircularProgress } from "@material-ui/core";
import { RSSIPerDayCustom } from "../CustomPDFCharts/WifiFloors/RSSIPerDayCustom";
import { ZoneLocationAccuracyCustom } from "../CustomPDFCharts/WifiZones/ZoneLocationAccuracyCustom";
import { ZoneLocationAccuracyPerDayCustom } from "../CustomPDFCharts/WifiZones/ZoneLocationAccuracyPerDayCustom";
import { ZonesRSSICustom } from "../CustomPDFCharts/WifiZones/ZonesRSSICustom";
import { ZonesRSSIPerDayCustom } from "../CustomPDFCharts/WifiZones/ZonesRSSIPerDayCustom";

export default function PDFPreviewModalWifi({
  showPDFPreviewModal,
  setShowPDFPreviewModal,
  user,
  pdfFormData,
  setPdfFormData,
  group,
  showPDFModal,
  setShowPDFModal,
  selectedFloors,
  selectedZones,
  selectedSSIDs,
  date,
  selectedTimeframe,
  hide,
  setHide,
}) {
  // const dispatch = useDispatch();

  // * ____________ States ____________ * \\
  const [showChart, setShowChart] = useState({
    LocationAccuracyCustom: true,
    LocationAccuracyPerDayCustom: true,
    RSSICustom: true,
    RSSIPerDayCustom: true,
  });
  const [showChartZones, setShowChartZones] = useState({
    ZoneLocationAccuracyCustom: true,
    ZoneLocationAccuracyPerDayCustom: true,
    ZonesRSSICustom: true,
    ZonesRSSIPerDayCustom: true,
  });
  const [loading, setLoading] = useState(false);

  const downloadingPDFData = () => {
    setLoading(true);

    //Faking API call here
    setTimeout(() => {
      setLoading(false);
      // Closes preview Modal
      setShowPDFPreviewModal(false);
    }, 5000);
  };

  // useEffect(() => {
  //   // console.log(showChart, "showChart");
  //   console.log(group, "group");
  //   // console.log(UIProps, "UIProps");
  // }, [group, showChart]);

  const displayingZeroChartsZones =
    !showChartZones.ZoneLocationAccuracyCustom &&
    !showChartZones.ZoneLocationAccuracyPerDayCustom &&
    !showChartZones.ZonesRSSICustom &&
    !showChartZones.ZonesRSSIPerDayCustom
      ? true
      : false;

  const displayingZeroChartsFloors =
    !showChart.LocationAccuracyCustom &&
    !showChart.LocationAccuracyPerDayCustom &&
    !showChart.RSSICustom &&
    !showChart.RSSIPerDayCustom
      ? true
      : false;

  return (
    <>
      <Modal
        show={showPDFPreviewModal}
        onHide={() => setShowPDFPreviewModal(false)}
        backdrop='static'
        dialogClassName='modal-100w'
        aria-labelledby='example-custom-modal-styling-title'
        size='xl'
        id='modalContent'
      >
        {/* ━━★  BODY  ★━━ */}
        <Modal.Body id='modalBodyPDF'>
          <div id='wrapper'>
            <div className='d-flex bd-highlight mb-3'>
              <div className='mr-auto p-2 bd-highlight'>
                <h3 style={{ fontSize: "20px", textDecoration: "underline" }}>
                  Wifi {group === "floor" ? "Floors" : "Zones"} Report
                </h3>
              </div>
              <div className='p-2 bd-highlight'>
                <img src={Logo} alt='u-find logo' className='ufind-logo' />{" "}
              </div>
            </div>
            <div className='row'>
              {group === "floor" && (
                <>
                  {showChart.LocationAccuracyCustom && (
                    <div
                      className={
                        showChart.LocationAccuracyPerDayCustom
                          ? "col-md-12 col-xxl-4"
                          : "col-12"
                      }
                      // style={{ border: "2px solid green" }}
                      id='wifiDivToPdf1'
                    >
                      <LocationAccuracyCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.LocationAccuracyPerDayCustom && (
                    <div
                      className={
                        showChart.LocationAccuracyCustom
                          ? "col-md-12 col-xxl-8"
                          : "col-12"
                      }
                      // style={{ border: "2px solid green" }}
                      id='wifiDivToPdf2'
                    >
                      <LocationAccuracyPerDayCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.RSSICustom && (
                    <div className='col-12' id='wifiDivToPdf3'>
                      <RSSICustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.RSSIPerDayCustom && (
                    <div className='col-12' id='wifiDivToPdf4'>
                      <RSSIPerDayCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                </>
              )}

              {group === "zone" && (
                <>
                  {showChartZones.ZoneLocationAccuracyCustom && (
                    <div className='col-12'>
                      <ZoneLocationAccuracyCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiDivToPdf1'
                      />
                    </div>
                  )}
                  {showChartZones.ZoneLocationAccuracyPerDayCustom && (
                    <div className='col-12'>
                      <ZoneLocationAccuracyPerDayCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiDivToPdf2'
                      />
                    </div>
                  )}
                  {showChartZones.ZonesRSSICustom && (
                    <div className='col-12'>
                      <ZonesRSSICustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiDivToPdf3'
                      />
                    </div>
                  )}
                  {showChartZones.ZonesRSSIPerDayCustom && (
                    <div className='col-12'>
                      <ZonesRSSIPerDayCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiDivToPdf4'
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id='dlPDFCancelBtnWifi'
            disabled={loading}
            color='default'
            variant='contained'
            className='mr-2'
            onClick={() => {
              setShowPDFPreviewModal(false);
              // Reset showing all charts on preview modal
              setShowChart({
                LocationAccuracyCustom: true,
                LocationAccuracyPerDayCustom: true,
                RSSICustom: true,
                RSSIPerDayCustom: true,
              });
              setShowChartZones({
                ZoneLocationAccuracyCustom: true,
                ZoneLocationAccuracyPerDayCustom: true,
                ZonesRSSICustom: true,
                ZonesRSSIPerDayCustom: true,
              });
            }}
          >
            CANCEL
          </Button>
          {/* Schedule Email delivery */}
          {/* <button
            onClick={() => {
              setShowPDFModal(true);
              setShowPDFPreviewModal(false);
            }}
          >
            EMAIL DELIVERY
          </button> */}
          <Button
            disabled={
              loading || displayingZeroChartsFloors || displayingZeroChartsZones
            }
            color='primary'
            variant='contained'
            className='ml-2'
            onClick={() => {
              // Spinner appears in button
              downloadingPDFData();

              // Hide close Icons
              setHide(true);

              // ↓ Download PDF
              const now = moment().format("YYYY-MM-DD HH:mm");
              const element = document.getElementById("modalBodyPDF");
              const options = {
                margin: 0,
                filename: `Wifi_Report_${now}`,
                jsPDF: {
                  // unit: "in",
                  // format: "letter",
                  unit: "pt",
                  format: "a4",
                  orientation: "p",
                  // hotfixes: ["px_scaling"],
                  // floatPrecision: "smart",
                  // precision: 15,
                },
                image: {
                  type: "jpeg",
                  quality: 1,
                  // width: 494,
                  // height: 494,
                },
                html2canvas: {
                  scale: 2,
                  scrollX: 0,
                  scrollY: 0,
                  // windowWidth: 1224,
                },
                // html2canvas: { scale: 2, windowWidth: 1100 },
                // makes pages break to another pdf page after chosen elements id.
                pagebreak: {
                  mode: "avoid-all",
                  before: [
                    // "#wifiDivToPdf2",
                    // "#wifiDivToPdf3",
                    "#wifiDivToPdf4",
                  ],
                  after: [
                    // "#wifiDivToPdf1",
                    // "#wifiDivToPdf2",
                    "#wifiDivToPdf3",
                    // "#wifiDivToPdf4",
                  ],
                },
              };
              // Save PDF
              html2pdf()
                .set(options)
                .from(element)
                .save();

              // Preview PDF
              // html2pdf()
              //   .set(options)
              //   .from(element)
              //   .toPdf()
              //   .get("pdf")
              //   .then(function(pdf) {
              //     window.open(pdf.output("bloburl"), "_blank");
              //   });

              // Reset showing all charts on preview modal
              setShowChart({
                LocationAccuracyCustom: true,
                LocationAccuracyPerDayCustom: true,
                RSSICustom: true,
                RSSIPerDayCustom: true,
              });
              setShowChartZones({
                ZoneLocationAccuracyCustom: true,
                ZoneLocationAccuracyPerDayCustom: true,
                ZonesRSSICustom: true,
                ZonesRSSIPerDayCustom: true,
              });
            }}
          >
            {loading && (
              <CircularProgress style={{ marginRight: "15px" }} size={15} />
            )}
            {loading && <span>DOWNLOADING...</span>}
            {!loading && <span>DOWNLOAD PDF</span>}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ━━★  PDF MODAL SCHEDULE   ★━━ */}
      <PDFModalWifi
        showPDFModal={showPDFModal}
        setShowPDFModal={setShowPDFModal}
        user={user}
        pdfFormData={pdfFormData}
        setPdfFormData={setPdfFormData}
        setShowPDFPreviewModal={setShowPDFPreviewModal}
        group={group}
        selectedFloors={selectedFloors}
        selectedZones={selectedZones}
        selectedSSIDs={selectedSSIDs}
        date={date}
        selectedTimeframe={selectedTimeframe}
      />
    </>
  );
}
