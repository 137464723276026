import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import moment from "moment"
import firebase from "firebase"
import { DropdownSelect } from "../DropdownSelect"

export function NumberOfBookings() {
   //━━━ Get data from redux ━━━\\
   const { bookings, user, department, profileLvl } = useSelector(
      (state) => ({
         bookings: state.booking?.bookings,
         user: state.auth?.user,
         department: state.basePage?.department,
         profileLvl: state.auth?.claims?.profileLvl,
      }), shallowEqual)

   //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
   //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
   const [graphData, setGraphData] = useState({
      labels: [],
      data: [{
         name: 'Bookings',
         data: []
      }]
   })
   const [dataFilter, setDataFilter] = useState({ value: 'me', label: 'Me' })


   useEffect(() => {
      const startDate = moment(firebase.firestore.Timestamp.now().toDate()).startOf("month")
      const endDate = moment(firebase.firestore.Timestamp.now().toDate()).endOf("month")
      const _bookings = bookings &&
         bookings.filter(val =>
            moment(val.end.toDate()).isAfter(startDate) &&
            moment(val.start.toDate()).isBefore(endDate) &&
            (
               dataFilter.value === "me" ?
                  val.created.id === user.id :
                  dataFilter.value === "department" &&
                  (department.employees?.includes(val.created.id) || department.manager === val.created.id)
            )
         ) || []

      if (!_bookings) { return }

      let dates = []
      while (startDate.isSameOrBefore(endDate)) {
         dates.push(startDate.format())
         startDate.add(1, "day")
      }

      const _books = dates.map(val => {
         return _bookings.filter(book => {
            return moment(book.date.toDate()).isSame(moment(val))
         }).length || 0
      })

      setGraphData({
         labels: dates,
         data: [{
            name: 'Bookings',
            data: _books
         }]
      })
   }, [bookings, department, dataFilter])

   return <Card>
      <CardHeader
         title="Number of bookings"
         subtitle="This month"
         toolbar={profileLvl >= 2 && department &&
            <DropdownSelect
               dataFilter={dataFilter}
               setDataFilter={setDataFilter}
               options={[
                  { value: 'me', label: 'Me' },
                  { value: 'department', label: 'Department' }
               ]}
            />
         }
      />
      <CardBody>
         <Chart
            options={getChartOptions(graphData.labels)}
            series={graphData.data}
            type="bar"
            height={300}
         />
      </CardBody>
   </Card>
}

function getChartOptions(labels) {
   return {
      chart: {
         type: 'bar',
         toolbar: { show: false }
      },
      noData: {
         text: "No bookings this month",
      },
      xaxis: {
         categories: labels,
         labels: {
            formatter: (val) => moment(val).format("DD")
         }
      },
      yaxis: {
         labels: {
            formatter: (val) => val.toFixed(0)
         }
      },
      tooltip: {
         x: {
            formatter: (val => moment(val).format("MMM DD")),
         },
      }
   }
}