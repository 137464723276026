import { auth, firestore } from "../../../../firebase/index"
import firebase from "firebase/app"
import _ from "lodash"

export const LOGIN_URL = "api/auth/login"
export const REGISTER_URL = "api/auth/register"
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password"

export const ME_URL = "api/me"

export async function login(email, password, domain) {
  var customerData

  var snapshot = await firebase
    .firestore()
    .collection("LoginData")
    .where("domain", "==", domain)
    .get()

  snapshot.forEach(doc => {
    customerData = doc.data()
  })

  // console.log("Data2")
  // console.log("Data2: ", customerData)

  customerData
    ? (firebase.auth().tenantId = customerData.tenantID)
    : (firebase.auth().tenantId = null)

  return (
    auth
      .signInWithEmailAndPassword(email, password)
      /*.then((user) => {
      const currentUser = auth.currentUser;
      currentUser.getIdTokenResult().then(idTokenResult => {
        console.log("Start");
        console.log(currentUser);
        console.log(idTokenResult.claims);
        console.log("Finish");
      })
    })*/
      .catch(error => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // ...
        //console.log("error login:", errorCode, errorMessage);
      })
  )

  //return axios.post(LOGIN_URL, { email, password });
}
/*
export function register(values) {
  return addDocToCollection("Contact",
    [{ ...values }]
  )
}*/
export function register(email, password) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code
      var errorMessage = error.message
      // ...
      console.log("error register:", errorCode, errorMessage)
    })
}

export function requestPassword(email) {
  var myHeaders = new Headers()
  myHeaders.append("token", process.env.REACT_APP_FORGOTPASSWORD_KEY)
  myHeaders.append("Content-Type", "application/json")

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ email: email }),
    redirect: "follow",
  }

  return fetch(process.env.REACT_APP_FORGOTPASSWORD_URL, requestOptions)
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const getUserClaims = async user => {
  let userClaims
  try {
    var idToken = await user.getIdTokenResult()
    userClaims = _.omit(idToken.claims, [
      "aud",
      "auth_time",
      "email",
      "email_verified",
      "exp",
      "firebase",
      "iat",
      "iss",
      "sub",
      "user_id",
    ])
  } catch (err) {
    console.log("Error while fetching custom claims: ", err)
  }
  return userClaims
}
/*export const getUserClaims = async (uid) => {

  const userClaimsRef = firestore.doc(`user-claims/${uid}`);
  let userClaims;
  try{

    await userClaimsRef.get().then(doc => {
      doc.exists ? userClaims = doc.data()
        : userClaims = undefined;
    })

  } catch(err) {
    console.log("Error while fetching custom claims: ", err)
  }

  return userClaims;
}
*/

/*import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}*/
