import React from "react";
import ReactDOM from "react-dom";
import { Switch } from "@material-ui/core";
import { FaFilter } from "react-icons/fa";

export default class Options {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";
    this.container.id = "showOptionsControlId";

    this.content = (
      <div className='options'>
        <div className='options-button'>
          <FaFilter
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div className='option-content'>
          <div className='options-switchs'>
            Zone information
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowZoneInfoMarkers(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Employees
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowEmployees(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Guests
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowGuests(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Visitors
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowVisitors(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Points Of Interest
            <Switch
              defaultChecked={true}
              onChange={(event) => this.props.setShowPOIs(event.target.checked)}
            />
          </div>
          <div className='options-switchs'>
            Sensors
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowSensors(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Connectors
            <Switch
              defaultChecked={true}
              onChange={(event) =>
                this.props.setShowConnectors(event.target.checked)
              }
            />
          </div>
          <div className='options-switchs'>
            Access Points
            <Switch
              defaultChecked={false}
              onChange={(event) => this.props.setShowAPS(event.target.checked)}
            />
          </div>
          <div className='options-switchs'>
            Heat Map
            <Switch
              defaultChecked={false}
              onChange={(event) => {
                // this.props.setShowHeatMap(event.target.checked)
                if (event.target.checked === true) {
                  this.props.setShowHeatMap(true);
                }
                if (event.target.checked === false) {
                  this.props.setShowHeatMap(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    );

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
