import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
import * as actions from "../../_redux/reporting/wifi/wifiActions"
import { LocationAccuracy } from "./Floor/LocationAccuracy"
import { LocationAccuracyPerDay } from "./Floor/LocationAccuracyPerDay"
import { RSSI } from "./Floor/RSSI"
import { RSSIPerDay } from "./Floor/RSSIPerDay"
import { ZoneLocationAccuracy } from "./Zone/ZoneLocationAccuracy"
import { ZoneLocationAccuracyPerDay } from "./Zone/ZoneLocationAccuracyPerDay"
import { ZonesRSSI } from "./Zone/ZonesRSSI"
import { ZonesRSSIPerDay } from "./Zone/ZonesRSSIPerDay"

export function WifiReporting() {
  const dispatch = useDispatch()

  const UIContext = useUIContext()
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    }
  }, [UIContext])

  const { group, customer } = useSelector(
    state => ({
      group: state.persistentReporting?.group,
      customer: state.profile?.currentCustomer,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (!customer) return

    if (group === "floor") {
      dispatch(
        actions.fetchWifiByFloor({
          customerId: customer.customerId,
          ...UIProps.queryParams,
        })
      )
    }
    if (group === "zone") {
      dispatch(
        actions.fetchWifiByZone({
          customerId: customer.customerId,
          ...UIProps.queryParams,
        })
      )
    }
  }, [customer, UIProps.queryParams, group])

  return (
    <div className="row">
      {group === "floor" && (
        <>
          <div className="col-md-12 col-xxl-4">
            <LocationAccuracy />
          </div>
          <div className="col-md-12 col-xxl-8">
            <LocationAccuracyPerDay />
          </div>
          <div className="col-12">
            <RSSI />
          </div>
          <div className="col-12">
            <RSSIPerDay />
          </div>
        </>
      )}
      {group === "zone" && (
        <>
          <div className="col-12">
            <ZoneLocationAccuracy />
          </div>
          <div className="col-12">
            <ZoneLocationAccuracyPerDay />
          </div>
          <div className="col-12">
            <ZonesRSSI />
          </div>
          <div className="col-12">
            <ZonesRSSIPerDay />
          </div>
        </>
      )}
    </div>
  )
}
