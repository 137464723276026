import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { badZonesChart } from "./BadZonesChart";
import { Popover, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function RSSIBadZones({ queryParams }) {
  const classes = useStyles();

  const [graph, setGraph] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await badZonesChart(queryParams);
    // console.log("🚀 ~ res", res);
    setGraph(res);
  };

  const zonesAndFloors = graph && graph.props.children.props.options;

  const floorPlansArray =
    graph && zonesAndFloors && zonesAndFloors?.noData?.text[0];

  const locationsArray =
    graph && zonesAndFloors && zonesAndFloors?.noData?.text[1];

  // useEffect(() => {
  //   // console.log(group, "group");
  //   // console.log(customer, "customer");
  //   // console.log(queryParams, "UIProps.queryParams Bad Zones");
  //   // console.log(graph, "graph");
  //   console.log(zonesAndFloors, "zonesAndFloors");
  //   console.log(floorPlansArray, "floorPlansArray");
  //   // graph && console.log(graph.props.children.props.options.xaxis?.categories);
  // }, [graph, zonesAndFloors, floorPlansArray]);

  return (
    <Card>
      <CardHeader
        title='Bad RSSI - Zones'
        subtitle='Top 10 RSSI < -90dbm'
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Floor Plans
            </Typography>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {zonesAndFloors?.xaxis?.categories.map((z, i) => (
                <p key={i}>{z}</p>
              ))}
            </div>
            <div style={{ marginRight: "0.5rem", fontWeight: "500" }}>
              {floorPlansArray &&
                Array.isArray(floorPlansArray) &&
                floorPlansArray.map((f, i) => <p key={i}>- &nbsp;&nbsp;{f}</p>)}
            </div>
            <div>
              {locationsArray &&
                Array.isArray(locationsArray) &&
                locationsArray.map((l, i) => <p key={i}>- &nbsp;&nbsp;{l}</p>)}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
}
