/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect, useMemo } from "react"

import { useSelector, useDispatch } from "react-redux"
import { useUIContext } from "../UIContext"
import { firestore } from "../../../../firebase"
import firebase from "firebase/app"
import * as turf from "@turf/turf"
import "@turf/angle"

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import MapboxDraw from "@mapbox/mapbox-gl-draw"
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import RotateMode from 'mapbox-gl-draw-rotate-mode'
import ScaleMode from 'mapbox-gl-draw-scale-mode'

mapboxgl.accessToken = 'pk.eyJ1IjoiZGlvZ29tIiwiYSI6ImNra3p5aXoxeTAxZ3Eybm80aXNhbTN0dDAifQ.QBLSLKu6azLM9HZkUogIRA'

var draw

export function MapWidget3() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()
  const [map, setMap] = useState()

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData()
  }, [selectedCustomer, UIProps.selectedFloor])


  {/* UseEffect */ }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  const mapContainerRef = useRef(null)
  const mapContainerRef2 = useRef(null)
  const mapContainerRef3 = useRef(null)
  const [lng, setLng] = useState(-9.158062711431398)
  const [lat, setLat] = useState(38.57793500061726)
  const [zoom, setZoom] = useState(19)

  useEffect(() => {
    var angle = turf.angle(
      [38.5778506681433, -9.15793027728796],
      [38.5778506681433, -9.15814116597176],
      [38.577929365426, -9.15793027728796],
    )

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/diogom/ckl8j3mpn00hg17qyup4hx9kt',
      center: [lng, lat],
      zoom: zoom,
      bearing: 0 - angle - 5
    })

    console.log("Angle: ", angle)

    //Top Left
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.15814116597176, 38.5778506681433])
      .addTo(map)
    //Top Right
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.15819615125656, 38.5779401772861])
      .addTo(map)
    //Bottom Left
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.15793027728796, 38.577929365426])
      .addTo(map)
    //Bottom Right
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.15798492729664, 38.5780188089448])
      .addTo(map)

    //AP
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.15810193866491, 38.5779140322406])
      .addTo(map)

    map.on('load', function () {
      map.addSource("myImageSource", {
        "type": "image",
        "url": require("./floorplan.png"),//"https://www.diagrams.net/assets/img/blog/floorplan-apartment-ground-floor.png",
        "coordinates": [
          [-9.15814116597176, 38.5778506681433],
          [-9.15819615125656, 38.5779401772861],
          [-9.15798492729664, 38.5780188089448],
          [-9.15793027728796, 38.577929365426],
        ]
      })

      map.addLayer({
        "id": "overlay",
        "source": "myImageSource",
        "type": "raster",
        "paint": {
          "raster-opacity": 0.85
        }
      })
    })

    return () => map.remove()
  }, [])

  useEffect(() => {
    var angle = turf.angle(
      [38.57785877600085, -9.158124222272079],
      [38.57785877600085, -9.158196157260534],
      [38.577886096324676, -9.158124222272079],
    )

    const map = new mapboxgl.Map({
      container: mapContainerRef2.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      bearing: 0 - angle - 5
    })

    console.log("Angle: ", angle)


    map.on('load', function () {
      map.addSource("myImageSource", {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Big meeting room",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "green"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158196,
                      38.57794
                    ],
                    [
                      -9.158171340823174,
                      38.577893587987774
                    ],
                    [
                      -9.158061,
                      38.577933
                    ],
                    [
                      -9.158088,
                      38.577979
                    ],
                    [
                      -9.158196,
                      38.57794
                    ]
                  ]
                ]
              },
              "id": "029d5e04b56d976e8a59c23180a793e9"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Big meeting walls",
                "level": 1,
                "height": 2,
                "base_height": 0,
                "color": "grey"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158196,
                      38.57794
                    ],
                    [
                      -9.158171676099284,
                      38.57789332588193
                    ],
                    [
                      -9.15806133527611,
                      38.577932737894294
                    ],
                    [
                      -9.158070087432845,
                      38.57794705755968
                    ],
                    [
                      -9.158072769641876,
                      38.57794574703144
                    ],
                    [
                      -9.158065728843212,
                      38.57793369017043
                    ],
                    [
                      -9.158169329166412,
                      38.577896209046145
                    ],
                    [
                      -9.15819313377142,
                      38.5779386701785
                    ],
                    [
                      -9.1580894,
                      38.5779755
                    ],
                    [
                      -9.1580755,
                      38.5779504
                    ],
                    [
                      -9.158072,
                      38.5779516
                    ],
                    [
                      -9.158088,
                      38.577979
                    ],
                    [
                      -9.158196,
                      38.57794
                    ]
                  ]
                ]
              },
              "id": "029d5e04b56d976e8a59c23180a793e9"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Big meeting Door",
                "level": 1,
                "height": 2,
                "base_height": 1.5,
                "color": "grey"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158070087432845,
                      38.57794705755968
                    ],
                    [
                      -9.158072769641876,
                      38.57794574703144
                    ],
                    [
                      -9.1580755,
                      38.5779504
                    ],
                    [
                      -9.158072,
                      38.5779516
                    ],
                    [
                      -9.158070087432845,
                      38.57794705755968
                    ]
                  ]
                ]
              },
              "id": "029d5e04b56d976e8a59c23180a793e9"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Small meeting room",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "red"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.157995656132698,
                      38.57794705755968
                    ],
                    [
                      -9.157949052751064,
                      38.57796409442475
                    ],
                    [
                      -9.15796112269163,
                      38.577983228129895
                    ],
                    [
                      -9.158007055521011,
                      38.57796619126939
                    ],
                    [
                      -9.157995656132698,
                      38.57794705755968
                    ]
                  ]
                ]
              },
              "id": "20a506d4c44c0a00e8743856363ed48c"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Meeting room 2",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "yellow"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158018790185452,
                      38.577976151280645
                    ],
                    [
                      -9.157969169318676,
                      38.577995022877126
                    ],
                    [
                      -9.157984,
                      38.578018
                    ],
                    [
                      -9.158033,
                      38.578
                    ],
                    [
                      -9.158018790185452,
                      38.577976151280645
                    ]
                  ]
                ]
              },
              "id": "d0da69cf6ee5d55cd5d2fa47d5dc3ad4"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Tech room",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "purple"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158035889267921,
                      38.57789018061174
                    ],
                    [
                      -9.157985597848892,
                      38.57790826591344
                    ],
                    [
                      -9.158019000000001,
                      38.577960999999995
                    ],
                    [
                      -9.158067,
                      38.577944
                    ],
                    [
                      -9.158035889267921,
                      38.57789018061174
                    ]
                  ]
                ]
              },
              "id": "df3e4e5b146d4fdd482ec2de182c83bf"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Break room",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "orange"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.157926589250565,
                      38.5779300206906
                    ],
                    [
                      -9.157949052751064,
                      38.577963832319156
                    ],
                    [
                      -9.157995656132698,
                      38.57794731966533
                    ],
                    [
                      -9.15797520428896,
                      38.57791245960593
                    ],
                    [
                      -9.157926589250565,
                      38.5779300206906
                    ]
                  ]
                ]
              },
              "id": "e1a7c1c8ac6330255f100f8e2bb8a9ec"
            },
            {
              "type": "Feature",
              "properties": {
                "type": "room",
                "name": "Meeting room 1",
                "level": 1,
                "height": 0,
                "base_height": 0,
                "color": "pink"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      -9.158088,
                      38.577979
                    ],
                    [
                      -9.15807444602251,
                      38.57795570704552
                    ],
                    [
                      -9.158018790185452,
                      38.577976151280645
                    ],
                    [
                      -9.158033,
                      38.578
                    ],
                    [
                      -9.158088,
                      38.577979
                    ]
                  ]
                ]
              },
              "id": "f545c5dad6802aafda02d76497ef2e6c"
            }
          ]
        }
      });

      map.addLayer({
        "id": "overlay",
        "source": "myImageSource",
        "type": "fill-extrusion",
        'paint': {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          'fill-extrusion-color': ['get', 'color'],

          // Get fill-extrusion-height from the source 'height' property.
          'fill-extrusion-height': ['get', 'height'],

          // Get fill-extrusion-base from the source 'base_height' property.
          'fill-extrusion-base': ['get', 'base_height'],

          // Make extrusions slightly opaque for see through indoor walls.
          'fill-extrusion-opacity': 0.5
        }
      })
    })
    var popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    })

    map.on('mousemove', 'overlay', function (e) {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

      var coordinates = e.lngLat
      var description = e.features[0].properties.name;

      popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
    })

    map.on('mouseleave', 'overlay', function () {
      map.getCanvas().style.cursor = '';
      popup.remove();
    })

    return () => map.remove()
  }, [])


  useEffect(() => {

    const map = new mapboxgl.Map({
      container: mapContainerRef3.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-9.158039, 38.577937],//[lng, lat],
      zoom: zoom,
      //bearing: 0 - angle - 5
    })

    draw = new MapboxDraw({
      defaultMode: 'simple_select',
      modes: Object.assign({
        RotateMode: RotateMode,
        ScaleMode: ScaleMode
      }, MapboxDraw.modes)
    })


    map.addControl(draw)

    map.on('load', function () {
      /*  map.addSource("myImageSource", {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": [
              { "type": "Feature", "properties": { "properties": null }, "geometry": { "type": "Polygon", "coordinates": [[[-121.972277, 37.362716], [-121.972277, 37.35242], [-121.958594, 37.35242], [-121.958594, 37.362716], [-121.972277, 37.362716]]] } },
              { "type": "Feature", "properties": { "properties": null }, "geometry": { "type": "Polygon", "coordinates": [[[-121.963971, 37.367512], [-121.963971, 37.362716], [-121.958752, 37.362716], [-121.958752, 37.367512], [-121.963971, 37.367512]]] } },
              { "type": "Feature", "properties": { "properties": null }, "geometry": { "type": "Polygon", "coordinates": [[[-121.969134, 37.374494], [-121.969134, 37.368605], [-121.958752, 37.368605], [-121.958752, 37.374494], [-121.969134, 37.374494]]] } },
              { "type": "Feature", "properties": { "properties": null }, "geometry": { "type": "Polygon", "coordinates": [[[-121.969134, 37.368605], [-121.969134, 37.362716], [-121.965621, 37.362716], [-121.965621, 37.368605], [-121.969134, 37.368605]]] } }
            ]
          }
        })
  
        map.addLayer({
          "id": "overlay",
          "source": "myImageSource",
          "type": "fill",
          "paint": {
            'fill-color': "#3bb2d0",
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.5
          }
        })*/

      draw.add({
        type: "FeatureCollection",
        features: [
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-121.972277, 37.362716], [-121.972277, 37.35242], [-121.958594, 37.35242], [-121.958594, 37.362716], [-121.972277, 37.362716]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-121.963971, 37.367512], [-121.963971, 37.362716], [-121.958752, 37.362716], [-121.958752, 37.367512], [-121.963971, 37.367512]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-121.969134, 37.374494], [-121.969134, 37.368605], [-121.958752, 37.368605], [-121.958752, 37.374494], [-121.969134, 37.374494]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-121.969134, 37.368605], [-121.969134, 37.362716], [-121.965621, 37.362716], [-121.965621, 37.368605], [-121.969134, 37.368605]]] } }
        ]
      }
      )

      draw.add({
        type: "FeatureCollection",
        features: [
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-9.158039, 38.577937], [-9.158039, 38.577857], [-9.157895, 38.577857], [-9.157895, 38.577937], [-9.158039, 38.577937]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-9.157952, 38.577974], [-9.157952, 38.577937], [-9.157897, 38.577937], [-9.157897, 38.577974], [-9.157952, 38.577974]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-9.158006, 38.578029], [-9.158006, 38.577983], [-9.157897, 38.577983], [-9.157897, 38.578029], [-9.158006, 38.578029]]] } },
          { type: "Feature", properties: { properties: null }, geometry: { type: "Polygon", coordinates: [[[-9.158006, 38.577983], [-9.158006, 38.577937], [-9.157969, 38.577937], [-9.157969, 38.577983], [-9.158006, 38.577983]]] } }
        ]
      })

      draw.add({
        type: 'Feature',
        properties: { properties: null },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-67.13734351262877, 45.137451890638886],
              [-66.96466, 44.8097],
              [-68.03252, 44.3252],
              [-69.06, 43.98],
              [-70.11617, 43.68405],
              [-70.64573401557249, 43.090083319667144],
              [-70.75102474636725, 43.08003225358635],
              [-70.79761105007827, 43.21973948828747],
              [-70.98176001655037, 43.36789581966826],
              [-70.94416541205806, 43.46633942318431],
              [-71.08482, 45.3052400000002],
              [-70.6600225491012, 45.46022288673396],
              [-70.30495378282376, 45.914794623389355],
              [-70.00014034695016, 46.69317088478567],
              [-69.23708614772835, 47.44777598732787],
              [-68.90478084987546, 47.184794623394396],
              [-68.23430497910454, 47.35462921812177],
              [-67.79035274928509, 47.066248887716995],
              [-67.79141211614706, 45.702585354182816],
              [-67.13734351262877, 45.137451890638886]
            ]
          ]
        }
      })

      /*map.addSource('maine', {
        'type': 'geojson',
        'data': {
        'type': 'Feature',
        'geometry': {
        'type': 'Polygon',
        'coordinates': [
        [
        [-67.13734351262877, 45.137451890638886],
        [-66.96466, 44.8097],
        [-68.03252, 44.3252],
        [-69.06, 43.98],
        [-70.11617, 43.68405],
        [-70.64573401557249, 43.090083319667144],
        [-70.75102474636725, 43.08003225358635],
        [-70.79761105007827, 43.21973948828747],
        [-70.98176001655037, 43.36789581966826],
        [-70.94416541205806, 43.46633942318431],
        [-71.08482, 45.3052400000002],
        [-70.6600225491012, 45.46022288673396],
        [-70.30495378282376, 45.914794623389355],
        [-70.00014034695016, 46.69317088478567],
        [-69.23708614772835, 47.44777598732787],
        [-68.90478084987546, 47.184794623394396],
        [-68.23430497910454, 47.35462921812177],
        [-67.79035274928509, 47.066248887716995],
        [-67.79141211614706, 45.702585354182816],
        [-67.13734351262877, 45.137451890638886]
        ]
        ]
        }
        }
        });
        map.addLayer({
        'id': 'maine',
        'type': 'fill',
        'source': 'maine',
        'layout': {},
        'paint': {
        'fill-color': '#088',
        'fill-opacity': 0.8
        }
        });*/
    })

    //draw.changeMode('RotateMode')

    return () => map.remove()
  }, [])


  return <div>
    {/* <img src={require("./floorplan.png")}></img>*/}
    <div style={{ height: "500px" }}
      ref={mapContainerRef} />
    <br></br>
    <div style={{ height: "500px" }}
      ref={mapContainerRef2} />
    <br></br>
    <div style={{ height: "500px" }}
      ref={mapContainerRef3} />
    <br></br>
    <button onClick={e => draw.changeMode('simple_select')}>Simple</button>
    <button onClick={e => draw.changeMode('RotateMode')}>Rotate</button>
    <button onClick={e => draw.changeMode('ScaleMode')}>Scale</button>
  </div>
}

/*

  useEffect(() => {
    if (floorPlanData) {
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  const mapContainerRef = useRef(null)
  const mapContainerRef2 = useRef(null)
  const [lng, setLng] = useState(-9.158062711431398)
  const [lat, setLat] = useState(38.57793500061726)
  const [zoom, setZoom] = useState(19)

  useEffect(() => {
    var point1 = turf.point([38.57794941651048, -9.158187432171509])
    var point2 = turf.point([38.57786501844528, -9.158187432171509])
    var bearing = turf.bearing(point1, point2)

    console.log(bearing)

    var angle = turf.angle(
      [38.57786501844528, -9.157934971289869],
      [38.57786501844528, -9.158135464507977],
      [38.5779350006989, -9.157934971289869],
    )

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      bearing: 0 - angle - 5
    })

    console.log("Angle: ", angle)

    //Top Left
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.158187432171509, 38.57794941651048])
      .addTo(map)
    //Bottom Left
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.158135464507977, 38.57786501844528])
      .addTo(map)
    //Top Right
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.157988279921778, 38.57802306815716])
      .addTo(map)
    //Bottom Right
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.157934971289869, 38.5779350006989])
      .addTo(map)

    var marker = new mapboxgl.Marker()
      .setLngLat([-9.158187432171509, 38.57786501844528])
      .addTo(map)

    var marker = new mapboxgl.Marker()
      .setLngLat([-9.157934971289869, 38.57786501844528])
      .addTo(map)

    map.on('load', function () {
      map.addSource("myImageSource", {
        "type": "image",
        "url": "https://www.diagrams.net/assets/img/blog/floorplan-apartment-ground-floor.png",
        "coordinates": [
          [-9.157934971289869, 38.5779350006989],
          [-9.157988279921778, 38.57802306815716],
          [-9.158187432171509, 38.57794941651048],
          [-9.158135464507977, 38.57786501844528],
        ]
      });

      map.addLayer({
        "id": "overlay",
        "source": "myImageSource",
        "type": "raster",
        "paint": {
          "raster-opacity": 0.85
        }
      })
    })

    return () => map.remove()
  }, [])

  useEffect(() => {
    var point1 = turf.point([38.57794941651048, -9.158187432171509])
    var point2 = turf.point([38.57786501844528, -9.158187432171509])
    var bearing = turf.bearing(point1, point2)

    console.log(bearing)

    var angle = turf.angle(
      [38.57785877600085, -9.158124222272079],
      [38.57785877600085, -9.158196157260534],
      [38.577886096324676, -9.158124222272079],
    )

    const map = new mapboxgl.Map({
      container: mapContainerRef2.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      bearing: 0 - angle - 5
    })

    console.log("Angle: ", angle)
    //Bottom Right
    var marker = new mapboxgl.Marker()
      .setLngLat([-9.158124222272079, 38.577886096324676])
      .addTo(map)
    //Top Right
    var marker = new mapboxgl.Marker()
      .setLngLat([
        -9.158176260348654,
        38.577967857815366])
      .addTo(map)
    //Top Left
    var marker = new mapboxgl.Marker()
      .setLngLat([
        -9.158243093566625,
        38.57794372821439])
      .addTo(map)
    //Bottom Left
    var marker = new mapboxgl.Marker()
      .setLngLat([
        -9.158196157260534,
        38.57785877600085])
      .addTo(map)

    map.on('load', function () {
      map.addSource("myImageSource", {
        "type": "image",
        "url": "https://www.diagrams.net/assets/img/blog/floorplan-apartment-ground-floor.png",
        "coordinates": [
          [
            -9.158124222272079,
            38.577886096324676
          ],
          [
            -9.158176260348654,
            38.577967857815366
          ],
          [
            -9.158243093566625,
            38.57794372821439
          ],
          [
            -9.158196157260534,
            38.57785877600085
          ],
        ]
      });

      map.addLayer({
        "id": "overlay",
        "source": "myImageSource",
        "type": "raster",
        "paint": {
          "raster-opacity": 0.85
        }
      })
    })

    return () => map.remove()
  }, [])
  */