import React from "react"

export function Bold({ style, children }) {
   return <span
      style={{
         ...style,
         fontFamily: "Poppins",
         fontWeight: "700",
         fontSize: "16px"
      }}>
      {children}
   </span>
}
