import React from "react";
import ReactDOM from "react-dom";
import * as turf from "@turf/turf";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { searchHandleMap } from "../../_helpers/ActionHelpers";

export default class Search {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";

    this._buddies = this.props.buddies
      ? this.props.buddies
          .map((option) => {
            return {
              group: "Buddies",
              name: option.displayName,
              ...option,
            };
          })
          .filter((val) => {
            return val.email !== this.props.userEmail;
          })
          .sort((a, b) => {
            return -b.group.localeCompare(a.group);
          })
      : [];

    this._things = this.props.things
      ? this.props.things
          .map((option) => {
            return {
              group: "Things",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    this._zones = this.props.zones
      ? this.props.zones
          .map((option) => {
            return {
              group: "Zones",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    this._pois = this.props.pois
      ? this.props.pois
          .map((option) => {
            return {
              group: "Points of Interest",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    this._sensors = this.props.sensors
      ? this.props.sensors
          .map((option) => {
            return {
              group: "Sensors",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    this.options = [
      ...this._buddies,
      ...this._things,
      ...this._zones,
      ...this._pois,
      ...this._sensors,
    ];

    this.content = (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <CustomAutocomplete
          defaultValue={this.props.defaultValue}
          options={this.options}
          map={map}
          dispatch={this.props.dispatch}
          locations={this.props.locations}
          floorPlan={this.props.floorPlan}
          devices={this.props.devices}
          bleDevices={this.props.bleDevices}
          bleThings={this.props.bleThings}
          setGoTo={this.props.setGoTo}
        />
      </div>
    );

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

function CustomAutocomplete(props) {
  return (
    <Autocomplete
      id='grouped'
      size='small'
      defaultValue={props.defaultValue || ""}
      options={props.options || []}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option.name || ""}
      getOptionSelected={(option, value) => option.name === value?.name}
      // Display "none" if (!selectedLocation or !props.locations)
      style={{ width: 250, display: !props.locations ? "none" : "block" }}
      //className={useStyles().autocomplete}
      renderInput={(params) => {
        return (
          <>
            <TextField {...params} variant='outlined' placeholder='Search' />
          </>
        );
      }}
      onChange={(event, newValue) => {
        handleSearchChange({
          dispatch: props.dispatch,
          map: props.map,
          locations: props.locations,
          floorPlan: props.floorPlan,
          value: newValue,
          devices: props.devices,
          bleDevices: props.bleDevices,
          setGoTo: props.setGoTo,
        });
      }}
    />
  );
}

function handleSearchChange(props) {
  const {
    dispatch,
    map,
    locations,
    floorPlan,
    value,
    devices,
    bleDevices,
    bleThings,
    setGoTo,
  } = props;

  if (!value) {
    searchHandleMap({ map, floorPlan, setGoTo });
    return;
  }

  var coords;
  var polygon;
  switch (value.group) {
    case "Buddies":
      const device =
        devices &&
        devices.find((val) => value.devicesArray?.includes(val.clientMac));
      if (device) {
        coords = [device.gpsData.longitude, device.gpsData.latitude];
        break;
      }
      const bleDevice =
        bleDevices &&
        bleDevices.find((val) => value.blesArray?.includes(val.macAddress));
      if (bleDevice) {
        coords = [bleDevice.gpsData.longitude, bleDevice.gpsData.latitude];
        break;
      }
      break;
    case "Zones":
      /* if (!value.marker) { break }
      coords = [value.marker.longitude, value.marker.latitude] */
      polygon = value.polygon.map((val) =>
        turf.point([val.longitude, val.latitude])
      );
      break;
    case "Points of Interest":
      if (!value.coords) break;
      coords = [value.coords.longitude, value.coords.latitude];
      break;
    case "Things":
      if (!value.gpsData) break;
      coords = [value.gpsData.longitude, value.gpsData.latitude];
      break;
    default:
      break;
  }

  searchHandleMap({
    locations,
    map,
    floorPlan,
    goTo: value,
    setGoTo,
    coords,
    polygon,
    dispatch,
  });
}
