import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import Chart from "react-apexcharts"
import * as turf from "@turf/turf"
import { Button } from "@material-ui/core"
import { Card, CardHeader, CardBody } from "../../../_partials/Card"

export function ZonesOccupancy() {
   const { location, level, zones, devices } = useSelector(
      (state) => ({
         location: state.profile?.currentLocation,
         level: state.profile?.currentLevel,
         zones: state.basePage?.zones,
         devices: state.liveData?.devices,
      }), shallowEqual)

   const [zonesData, setZonesData] = useState()
   const [colors, setColors] = useState([])
   const [currentFloor, setCurrentFloor] = useState(true)

   useEffect(() => {
      if (!zones || !devices || !level) return

      var _zones = [...zones]
      // const employees = currentFloor ?
      //    [devices[level.floorId]?.employees, devices[`${level.floorId}_ble`]?.people].filter(val => val).flat() || [] :
      //    Object.entries(devices).map(val => {
      //       console.log("Val: ", val)
      //       if (val[1].employees) return val[1].employees
      //       if (val[1].people) return val[1].people
      //    }).filter(val => val).flat()

      if (currentFloor) {
         _zones = zones.filter(zone => zone.floorPlanId == level.floorId)
      }

      var count = []
      if (location.customerType == "office") {
         _zones.forEach((zone, i) => {
            const employees = devices[zone.floorPlanId]?.employees || []
            const people = devices[`${zone.floorPlanId}_ble`]?.people || []
            var polygon = zone.polygon.map((coord) => {
               return [coord.longitude, coord.latitude]
            })
            polygon.push(polygon[0])
            var poly = turf.polygon([polygon])
            employees.forEach((d) => {
               var pt = turf.point([d.gpsData.longitude, d.gpsData.latitude])
               var inside = turf.booleanPointInPolygon(pt, poly)
               if (inside) {
                  count[i] = count[i] ? count[i] + 1 : 1
               }
            })
            people.forEach((d) => {
               var pt = turf.point([d.gpsData.longitude, d.gpsData.latitude])
               var inside = turf.booleanPointInPolygon(pt, poly)
               if (inside) {
                  count[i] = count[i] ? count[i] + 1 : 1
               }
            })
         })
      }
      _zones.forEach((zone, i) => {
         const guests = devices[zone.floorPlanId]?.guests || []
         var polygon = zone.polygon.map((coord) => {
            return [coord.longitude, coord.latitude]
         })
         polygon.push(polygon[0])
         var poly = turf.polygon([polygon])
         guests.forEach((d) => {
            var pt = turf.point([d.gpsData.longitude, d.gpsData.latitude])
            var inside = turf.booleanPointInPolygon(pt, poly)
            if (inside) {
               count[i] = count[i] ? count[i] + 1 : 1
            }
         })
      })
      _zones.forEach((zone, i) => {
         const visitors = devices[zone.floorPlanId]?.visitors || []
         var polygon = zone.polygon.map((coord) => {
            return [coord.longitude, coord.latitude]
         })
         polygon.push(polygon[0])
         var poly = turf.polygon([polygon])
         visitors.forEach((d) => {
            var pt = turf.point([d.gpsData.longitude, d.gpsData.latitude])
            var inside = turf.booleanPointInPolygon(pt, poly)
            if (inside) {
               count[i] = count[i] ? count[i] + 1 : 1
            }
         })
      })

      var data = []
      _zones.forEach((zone, i) => {
         count && count[i] > 0 && data.push({ x: zone.name, y: count[i] })
      })
      setZonesData(data)

      var colorsData = []
      data.forEach((value, i) => {
         const elementsIndex = _zones.findIndex(zone => zone.name === value.x)

         var percentage = (value.y * 100) / _zones[elementsIndex].capacity
         if (percentage >= 100) {
            colorsData.push("#D03157")
         } else if (percentage >= 75) {
            colorsData.push("#d6cd54")
         } else {
            colorsData.push("#31D0AA")
         }
      })
      setColors(colorsData)
   }, [location, level, zones, devices, currentFloor])

   return <Card>
      <CardHeader
         title="Zones Occupancy"
         subtitle="Busiest zones"
         toolbar={<>
            <Button
               color={currentFloor ? "primary" : "default"}
               onClick={() => setCurrentFloor(true)}
               style={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "170%",
                  textTransform: "none",
                  color: !currentFloor && "#B8BCCA"
               }}>
               Current floor
            </Button>
            <Button
               color={currentFloor ? "default" : "primary"}
               onClick={() => setCurrentFloor(false)}
               style={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "170%",
                  textTransform: "none",
                  color: currentFloor && "#B8BCCA"
               }}>
               All floors
            </Button>
         </>}
      />
      <CardBody style={{ height: '350px' }}>
         <Chart
            options={getOptions(colors)}
            series={[{ data: zonesData || [] }]}
            type="treemap"
            height="100%"
         />
      </CardBody>
   </Card>
}

function getOptions(colors) {
   return {
      chart: {
         type: 'treemap',
         height: "100%",
         sparkline: { enabled: true },
         toolbar: { show: false },
      },
      legend: { show: false },
      noData: {
         text: "No data to display.",
         style: { fontSize: "16px" },
      },
      colors: colors,
      dataLabels: {
         enabled: true,
         style: {
            fontSize: '12px',
         },
         formatter: function (text, op) {
            return [text, op.value]
         },
         offsetY: -4
      },
      plotOptions: {
         treemap: {
            distributed: true,
            enableShades: false
         }
      }
   }
}