import React, { useState, useRef } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Input, Select } from "../../../../../_metronic/_partials/controls"
import { Input as Input2 } from "../_input/Input"
import Button from '@material-ui/core/Button'
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { deleteCustomer } from "../_helpers/ActionsHelpers"
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import "../CreateCustomerStyle.css"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography/Bold"
import { Small } from "../../../../_partials/typography/Small"
import CloseIcon from '@material-ui/icons/Close'

const EditSchema = Yup.object().shape({
   meraki_key: Yup.string().required("Meraki Key is required"),
   domain: Yup.string().required("Domain is required"),
   admin: Yup.string().required('Admin Email is required.'),
   password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
})

const Step1 = ({ defaultData, setForm, formData, navigation }) => {
   const { next } = navigation
   const { domain, admin, password } = formData
   const { meraki_key, orgId } = formData.merakiDashboard

   const formRef = useRef()
   const [orgs, setOrgs] = useState([])
   const [checkedKey, setCheckedKey] = useState(false)
   const [merakiKey, setMerakiKey] = useState(meraki_key)
   const [checkingKey, setCheckingKey] = useState(false)
   const [open, setOpen] = useState(false)
   const [sentKey, setSentKey] = useState()
   const [emailKey, setEmailKey] = useState("")
   const [emailTo, setEmailTo] = useState("")
   const [wrongKey, setWrongKey] = useState(false)
   const [passwordType, setPasswordType] = useState("password")

   const verifyKey = (values) => {
      if (!values.meraki_key) {
         return
      }
      setMerakiKey(values.meraki_key)
      setCheckingKey(true)
      var myHeaders = new Headers()
      myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
      myHeaders.append("Content-Type", "application/json")

      var raw = JSON.stringify({
         "merakiKey": values.meraki_key
      })

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      }

      fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/getOrg`, requestOptions)
         .then(response => response.text())
         .then(result => {
            var jsonResult = JSON.parse(result)
            setOrgs(jsonResult.message)
            setCheckingKey(false)
            setCheckedKey(true)
         }).catch(error => {
            console.log('error', error)
            setCheckingKey(false)
         })
   }

   const handleSubmit = (values) => {
      var orgName = orgs.find(o => o.id == values.orgId).name
      var merakiDashboard = {
         orgName: orgName,
         orgId: values.orgId,
         api_version: "v1",
         meraki_key: values.meraki_key
      }
      formData.customerId && deleteCustomer(formData.customerId)
      setForm({
         target: {
            name: "data",
            value: defaultData.data
         }
      })
      setForm({
         target: {
            name: "data.merakiDashboard",
            value: merakiDashboard
         }
      })
      const userService = {
         meraki_key: values.meraki_key
      }
      setForm({
         target: {
            name: "data.userService",
            value: userService
         }
      })
      setForm({
         target: {
            name: "data.name",
            value: orgName
         }
      })
      setForm({
         target: {
            name: "data.admin",
            value: values.admin + "@" + values.domain
         }
      })
      setForm({
         target: {
            name: "data.domain",
            value: values.domain
         }
      })
      setForm({
         target: {
            name: "data.completedStep",
            value: 1
         }
      })
      setForm({
         target: {
            name: "data.password",
            value: values.password
         }
      })
      setEmailTo(values.admin + "@" + values.domain)
      sendEmail(values.admin + "@" + values.domain)
      setOpen(true)
   }

   const sendEmail = (adminEmail) => {
      const key = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
      setSentKey(key.toString())
      var myHeaders = new Headers()
      myHeaders.append("token", "MXSDE7DZbpZrMmSbbUZQ1WRQMePamniv")
      myHeaders.append("Content-Type", "application/json")

      var raw = JSON.stringify({
         "to": adminEmail,
         "subject": "Welcome",
         //"html": `<h1>Welcome</h1><p>${key}</p>`
         "html": `
         <div style="background-color: #ECF1F4">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
               <tr>
                  <td align="center">
                     <div style="text-align: left; display: inline-block; background-color: #FAFCFE; padding: 20px; max-width: 300px">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ufind-dev.appspot.com/o/ufind-logo.png?alt=media&token=9e85da00-8d6a-40c9-baab-2cf5776d0fe8"/>
                        <p style="font-style: normal; font-weight: 500; font-size: 16px; line-height: 160%; color: #1C1C3B">Here is the confirmation code to continue with your u-find trial:</p>
                        <div style="text-align: center; background-color: #ECF1F4; border-radius: 5px">
                           <h1><b>${key}</b></h1>
                        </div>
                        <p style="font-style: normal; font-weight: 500; font-size: 16px; line-height: 160%; color: #1C1C3B">Use this code to continue with your trial setup.</p>
                        <p style="font-style: normal; font-weight: 500; font-size: 13px">If you didn't start a trial at u-find, please ignore this message.</p>
                        <p style="font-style: normal; font-weight: 500; font-size: 13px">For support contact support@u-find.me</p>
                     </div>
                  </td>
               </tr>
            </table>
         </div>
         `
      })

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      }

      fetch("https://europe-west2-ufind-dev.cloudfunctions.net/sendEmail?=", requestOptions)
         .catch(error => console.log('error', error))
      setOpen(true)
   }

   const handleClose = () => {
      setOpen(false)
   }

   const handleModalSubmit = () => {
      if (sentKey === emailKey) {
         next()
         setOpen(false)
      } else {
         setWrongKey(true)
      }
   }

   return <Card isLoading={checkingKey} style={{ height: "100%" }}>
      <CardHeader
         title="Setup Org"
         toolbar={
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => checkedKey && formRef.current && formRef.current.handleSubmit()}
            >
               Next
            </Button>}
      />
      <CardBody>
         <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
               meraki_key: merakiKey || "",
               orgId: orgId?.length > 1 ? orgId : orgs[0]?.id,
               domain: domain || "",
               admin: admin.substring(0, admin.lastIndexOf("@")),
               password: password || "",
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => {
               handleSubmit(values)
            }}
         >
            {({ values, handleChange }) => (
               <Form className="form form-label-right">
                  <div className="form-group row mb-4">
                     <div className="col">
                        <b>Meraki key:</b>
                        <div className="input-group">
                           <input
                              value={values.meraki_key}
                              name="meraki_key"
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              style={{ height: "45px" }}
                           />
                           <div className="input-group-append">
                              <button
                                 disabled={checkingKey}
                                 className="btn btn-light-primary"
                                 type="button"
                                 onClick={() => verifyKey(values)}
                              >
                                 Verify key
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="form-group row mb-4">
                     <div className="col">
                        <Select
                           value={values.orgId}
                           name="orgId"
                           label="Organization"
                           inputStyle={["large"]}
                        >
                           {orgs.map((values, i) => {
                              return <option key={i} value={values.id}>{values.name}</option>
                           })}
                        </Select>
                     </div>
                  </div>
                  <div className="form-group row mb-4">
                     <div className="col-12 col-sm mb-4">
                        <Field
                           name="domain"
                           component={Input}
                           label="Domain"
                           inputStyle={["large"]}
                        />
                     </div>
                     <div className="col-12 col-sm mb-4">
                        <Field
                           name="admin"
                           component={Input2}
                           label="Admin email"
                           inputStyle={["large"]}
                           append={`@${values.domain}`}
                        />
                     </div>
                     <div className="col-12 col-sm mb-4">
                        <Field
                           name="password"
                           type={passwordType}
                           component={Input2}
                           label="Password"
                           inputStyle={["large"]}
                           customAppend={
                              <div className="input-group-append">
                                 <span className="input-group-text p-0" id="basic-addon2">
                                    {passwordType === "password" &&
                                       <IconButton
                                          aria-label="show password"
                                          onClick={() => setPasswordType("text")}
                                          style={{
                                             padding: "10px"
                                          }}
                                       >
                                          <VisibilityOffIcon />
                                       </IconButton>
                                    }
                                    {passwordType === "text" &&
                                       <IconButton
                                          aria-label="hide password"
                                          onClick={() => setPasswordType("password")}
                                          style={{
                                             padding: "10px"
                                          }}
                                       >
                                          <VisibilityIcon />
                                       </IconButton>
                                    }
                                 </span>
                              </div>
                           }
                        />
                     </div>
                  </div>
               </Form>
            )}
         </Formik >
         <Modal
            show={open}
            onHide={handleClose}
            contentClassName="bg-transparent"
         >
            <Modal.Body
               style={{
                  padding: "0",
                  backgroundColor: "#ECF1F4",
                  borderRadius: "10px",
                  overflow: "hidden"
               }}
            >
               <div className="pl-7 pr-7 pb-7 pt-2">
                  {/* ━━★  TITLE  ★━━ */}
                  <div className="d-flex align-items-center justify-content-between">
                     <div className="">
                        <Bold>ENTER CONFIRMATION CODE</Bold>
                     </div>
                     <div className="" >
                        <IconButton onClick={handleClose} style={{ left: "12px" }}>
                           <CloseIcon />
                        </IconButton>
                     </div>
                  </div>

                  {/* ━━★  SUBTITLE  ★━━ */}
                  <Small text={`An email has been sent to ${emailTo} with a 6 digit code.`}/>

                  {/* ━━★  BODY  ★━━ */}
                  <div className="input-group mt-2">
                     <input
                        type="text"
                        className="form-control h2 text-center"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={emailKey}
                        style={{
                           borderColor: wrongKey && "#D03157"
                        }}
                        onChange={e => {
                           setEmailKey(e.currentTarget.value)
                           setWrongKey(false)
                        }}
                     />
                  </div>
                  <div style={{
                     textAlign: "start",
                     fontWeight: 500,
                     fontSize: "11px",
                     color: "#D03157"
                  }}>
                     <span>
                        <b>{wrongKey && "Incorrect verification key"}</b>
                     </span>
                  </div>
                  <button
                     type="button"
                     className="btn btn-primary btn-block mt-3 mb-1"
                     onClick={() => handleModalSubmit()}
                  >
                     Next
                  </button>
                  <div style={{ textAlign: "left" }}>
                     <span>Didn't get it?{"  "}
                        <span className="resend" onClick={() => sendEmail(admin)}>Resend</span>
                     </span>
                  </div>
                  {/* ━━★  FOOTER  ★━━ */}
                  {/* <div className="row mt-4">
                     <button
                        type="button"
                        onClick={onHide}
                        className="col btn btn-block btn-light btn-elevate mr-2"
                     >
                        Cancel
                     </button>
                     <button
                        type="button"
                        onClick={handleContinue}
                        className="col btn btn-block btn-primary btn-elevate m-0 ml-2"
                     >
                        Continue
                     </button>
                  </div> */}
               </div>
            </Modal.Body>
         </Modal>
      </CardBody>
   </Card>
}

export default Step1