import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { Select as SelectMUI } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "2px",
  },
  select: {
    backgroundColor: "#FFFFFF",
    height: "45px",
      /* padding: "0px 12px",
    '& .MuiInputBase-root': {
      padding: "0px 12px",
    }, */
    '& .MuiSelect-root': {
      //padding: 0,
      padding: "16px 12px",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF',
      borderRadius: "5px"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8C8CA1',
      borderRadius: "5px"
    },
  },
  selectWithSize: {
    backgroundColor: "#FFFFFF",
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF',
      borderRadius: "5px"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8C8CA1',
      borderRadius: "5px"
    },
  },
  helperText: {
    margin: 0
  },
}))

export function Select({
  label,
  options = [],
  withFeedbackLabel = true,
  form,
  field,
  size,
  ...props
}) {
  const classes = useStyles()
  if (field && form) {
    var { name, value } = field
    var { touched, errors, setFieldValue, setFieldTouched } = form
  }
  const error = (withFeedbackLabel && touched && touched[name] && errors && errors[name])

  return (
    <>
      {label && <b>{label}</b>}
      <div>
        <FormControl
          className={classes.formControl}
          variant="outlined"
          size={size}
          error={error ? true : false}
        >
          <SelectMUI
            name={name}
            id="select-partial"
            className={size ? classes.selectWithSize : classes.select}
            value={value}
            onClose={() => {
              if (!touched && !setFieldTouched) { return }
              /*!touched[name] so it doesn't set touched true when it's already true*/
              /*Without this errors will always be one update behind the real value*/
              !touched[name] && setFieldTouched(name, true)
            }}
            onChange={e => {
              if (!touched && !setFieldTouched && !setFieldValue) { return }
              !touched[name] && setFieldTouched(name, true)
              setFieldValue(name, e.target.value)
            }}
            {...props}
          >
            {options.map((val, i) => {
              return <MenuItem key={i} value={val.value}>
                {val.name}
              </MenuItem>
            })}
          </SelectMUI>
          {error && <FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>}
        </FormControl>
      </div>
    </>
  )
}
