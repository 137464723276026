/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react"

import Leaflet from 'leaflet'
import { Map, Marker, FeatureGroup, TileLayer } from "react-leaflet"
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { EditControl } from "react-leaflet-draw"
import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-draw/dist/leaflet.draw.css'

import { useSelector, useDispatch } from "react-redux"
import * as actions from "../../_redux/mapZones/mapZonesActions"
import classifyPoint from "robust-point-in-polygon"
import { useUIContext } from "../UIContext"
import { firestore } from "../../../../firebase"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import firebase from "firebase/app"
import * as turf from "@turf/turf"

delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

const deskMarker = new Leaflet.Icon({
  iconUrl: require('../../../assets/deskBlack.svg'),
  iconRetinaUrl: require('../../../assets/deskBlack.svg'),
  iconAnchor: [10, 15],
  popupAnchor: [0, -15],
  iconSize: [20, 20],
})


export function MapWidget() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()
  const [map, setMap] = useState()
  const [map2, setMap2] = useState()
  const [map3, setMap3] = useState()
  const [map4, setMap4] = useState()
  const [map5, setMap5] = useState()
  const [editableFG, setEditableFG] = useState()
  const [zones, setZones] = useState([])
  const [layersEdit, setLayersEdit] = useState()
  const [layersDelete, setLayersDelete] = useState()
  const [colorNumber, setColorNumber] = useState(0)
  const [points, setPoints] = useState([])
  const [markers, setMarkers] = useState([])

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
          setPoints(acessPoints)
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData();
  }, [selectedCustomer, UIProps.selectedFloor]);


  {/* UseEffect */ }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      setZones([])
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  useEffect(() => {
    if (floorPlanFiltered && map) {
      currentFloorPlan && map.leafletElement.removeLayer(currentFloorPlan);
      /*let overlay = new Leaflet.imageOverlay.rotated(
        floorPlanFiltered.imageUrl,
        floorPlanFiltered.topLeftCorner,
        floorPlanFiltered.topRightCorner,
        floorPlanFiltered.bottomLeftCorner,
        {
          opacity: 1,
          interactive: true
        }
      ).addTo(map.leafletElement)*/
      //Leaflet.marker([floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]).addTo(map.leafletElement)
      //Leaflet.marker([38.5779716954863, -9.15795894339681]).addTo(map.leafletElement)
      //Leaflet.marker([38.5778762890001, -9.158112337805983]).addTo(map.leafletElement)
      //setCurrentFloorPlan(overlay)

      Leaflet.marker([floorPlanFiltered.topLeftCorner.lat, floorPlanFiltered.topLeftCorner.lng]).addTo(map.leafletElement)
      Leaflet.marker([floorPlanFiltered.topRightCorner.lat, floorPlanFiltered.topRightCorner.lng]).addTo(map.leafletElement)
      Leaflet.marker([floorPlanFiltered.bottomRightCorner.lat, floorPlanFiltered.bottomRightCorner.lng]).addTo(map.leafletElement)
      Leaflet.marker([floorPlanFiltered.bottomLeftCorner.lat, floorPlanFiltered.bottomLeftCorner.lng]).addTo(map.leafletElement)
    }
  }, [floorPlanFiltered, map])

  useEffect(() => {
    if (floorPlanFiltered && map2) {
      currentFloorPlan && map.leafletElement.removeLayer(currentFloorPlan)

      var poly = turf.polygon([[
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat],
        [floorPlanFiltered.topRightCorner.lng, floorPlanFiltered.topRightCorner.lat],
        [floorPlanFiltered.bottomRightCorner.lng, floorPlanFiltered.bottomRightCorner.lat],
        [floorPlanFiltered.bottomLeftCorner.lng, floorPlanFiltered.bottomLeftCorner.lat],
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat]
      ]])
      var options = {
        pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat]
      };
      var rotatedPoly = turf.transformRotate(poly, 110, options)

      let overlay = new Leaflet.imageOverlay.rotated(
        floorPlanFiltered.imageUrl,
        { lat: rotatedPoly.geometry.coordinates[0][0][1], lng: rotatedPoly.geometry.coordinates[0][0][0] },
        { lat: rotatedPoly.geometry.coordinates[0][1][1], lng: rotatedPoly.geometry.coordinates[0][1][0] },
        { lat: rotatedPoly.geometry.coordinates[0][3][1], lng: rotatedPoly.geometry.coordinates[0][3][0] },
      ).addTo(map2.leafletElement)

      Leaflet.marker([floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]).addTo(map2.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]]).addTo(map2.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]]).addTo(map2.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]]).addTo(map2.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]).addTo(map2.leafletElement)

      var l = turf.lineString([[-9.15795894339681, 38.5779716954863], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated = turf.transformRotate(l, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated)
      Leaflet.marker([trufRotated.geometry.coordinates[0][1], trufRotated.geometry.coordinates[0][0]]).addTo(map2.leafletElement)

      var l1 = turf.lineString([[-9.158112337805983, 38.5778762890001], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated1 = turf.transformRotate(l1, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated1)
      Leaflet.marker([trufRotated1.geometry.coordinates[0][1], trufRotated1.geometry.coordinates[0][0]]).addTo(map2.leafletElement)

      setCurrentFloorPlan(overlay)
    }
  }, [floorPlanFiltered, map2])

  useEffect(() => {
    if (floorPlanFiltered && map3) {
      currentFloorPlan && map.leafletElement.removeLayer(currentFloorPlan)

      var poly = turf.polygon([[
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat],
        [floorPlanFiltered.topRightCorner.lng, floorPlanFiltered.topRightCorner.lat],
        [floorPlanFiltered.bottomRightCorner.lng, floorPlanFiltered.bottomRightCorner.lat],
        [floorPlanFiltered.bottomLeftCorner.lng, floorPlanFiltered.bottomLeftCorner.lat],
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat]
      ]])
      var options = {
        pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat]
      };
      var rotatedPoly = turf.transformRotate(poly, 110, options);
      console.log("RotatedPoly floor: ", rotatedPoly.geometry.coordinates)

      let overlay = new Leaflet.imageOverlay(
        floorPlanFiltered.imageUrl,
        [
          [rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]],
          [rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]]
        ]
      ).addTo(map3.leafletElement)
      Leaflet.marker([floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]).addTo(map3.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]]).addTo(map3.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]]).addTo(map3.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]]).addTo(map3.leafletElement)
      Leaflet.marker([rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]).addTo(map3.leafletElement)

      var l = turf.lineString([[-9.15795894339681, 38.5779716954863], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated = turf.transformRotate(l, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated)
      Leaflet.marker([trufRotated.geometry.coordinates[0][1], trufRotated.geometry.coordinates[0][0]]).addTo(map3.leafletElement)

      var l1 = turf.lineString([[-9.158112337805983, 38.5778762890001], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated1 = turf.transformRotate(l1, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated1)
      Leaflet.marker([trufRotated1.geometry.coordinates[0][1], trufRotated1.geometry.coordinates[0][0]]).addTo(map3.leafletElement)

      console.log(overlay)
      setCurrentFloorPlan(overlay)
    }
  }, [floorPlanFiltered, map3])

  useEffect(() => {
    if (floorPlanFiltered && map4) {
      currentFloorPlan && map.leafletElement.removeLayer(currentFloorPlan)

      var poly = turf.polygon([[
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat],
        [floorPlanFiltered.topRightCorner.lng, floorPlanFiltered.topRightCorner.lat],
        [floorPlanFiltered.bottomRightCorner.lng, floorPlanFiltered.bottomRightCorner.lat],
        [floorPlanFiltered.bottomLeftCorner.lng, floorPlanFiltered.bottomLeftCorner.lat],
        [floorPlanFiltered.topLeftCorner.lng, floorPlanFiltered.topLeftCorner.lat]
      ]])
      var options = {
        pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat]
      };
      var rotatedPoly = turf.transformRotate(poly, 110, options)

      var latlngs = [
        [rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]],
        [rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]],
        [rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]],
        [rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]
      ]

      //var polygon = Leaflet.polygon(latlngs, { color: 'red' }).addTo(map4.leafletElement)

      var latlngs = [
        [floorPlanFiltered.topLeftCorner.lat, floorPlanFiltered.topLeftCorner.lng],
        [floorPlanFiltered.topRightCorner.lat, floorPlanFiltered.topRightCorner.lng],
        [floorPlanFiltered.bottomRightCorner.lat, floorPlanFiltered.bottomRightCorner.lng],
        [floorPlanFiltered.bottomLeftCorner.lat, floorPlanFiltered.bottomLeftCorner.lng]
      ]

      var polygon = Leaflet.polygon(latlngs, { color: 'red' }).addTo(map4.leafletElement)

      //Leaflet.marker([floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]).addTo(map4.leafletElement)

      var l = turf.lineString([[-9.15795894339681, 38.5779716954863], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated = turf.transformRotate(l, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated)
      //Leaflet.marker([trufRotated.geometry.coordinates[0][1], trufRotated.geometry.coordinates[0][0]]).addTo(map4.leafletElement)

      var l1 = turf.lineString([[-9.158112337805983, 38.5778762890001], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated1 = turf.transformRotate(l1, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated1)
      //Leaflet.marker([trufRotated1.geometry.coordinates[0][1], trufRotated1.geometry.coordinates[0][0]]).addTo(map4.leafletElement)

      console.log(polygon)
      setCurrentFloorPlan(polygon)
    }
  }, [floorPlanFiltered, map4])

  useEffect(() => {
    if (floorPlanFiltered && map5) {
      currentFloorPlan && map.leafletElement.removeLayer(currentFloorPlan)

      var poly = turf.polygon([[[0, 29], [3.5, 29], [3.5, 32], [0, 32], [0, 29]]])

      var options = {
        pivot: [0,29]
      };
      var rotatedPoly = turf.transformRotate(poly, 110, options)

      var latlngs = [
        [rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]],
        [rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]],
        [rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]],
        [rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]
      ]

      var polygon = Leaflet.polygon(latlngs, { color: 'red' }).addTo(map5.leafletElement)

      var latlngs = [[29, 0], [29, 3.5], [32, 3.5], [32, 0], [29, 0]]

      var polygon = Leaflet.polygon(latlngs, { color: 'red' }).addTo(map5.leafletElement)

      //Leaflet.marker([floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][0][1], rotatedPoly.geometry.coordinates[0][0][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][1][1], rotatedPoly.geometry.coordinates[0][1][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][2][1], rotatedPoly.geometry.coordinates[0][2][0]]).addTo(map4.leafletElement)
      //Leaflet.marker([rotatedPoly.geometry.coordinates[0][3][1], rotatedPoly.geometry.coordinates[0][3][0]]).addTo(map4.leafletElement)

      var l = turf.lineString([[-9.15795894339681, 38.5779716954863], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated = turf.transformRotate(l, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated)
      //Leaflet.marker([trufRotated.geometry.coordinates[0][1], trufRotated.geometry.coordinates[0][0]]).addTo(map4.leafletElement)

      var l1 = turf.lineString([[-9.158112337805983, 38.5778762890001], [floorPlanFiltered.center.lat, floorPlanFiltered.center.lng]])

      // Rotate line around p1
      var trufRotated1 = turf.transformRotate(l1, 110, { pivot: [floorPlanFiltered.center.lng, floorPlanFiltered.center.lat] });
      console.log("Line: ", trufRotated1)
      //Leaflet.marker([trufRotated1.geometry.coordinates[0][1], trufRotated1.geometry.coordinates[0][0]]).addTo(map4.leafletElement)

      console.log(polygon)
      setCurrentFloorPlan(polygon)
    }
  }, [floorPlanFiltered, map5])

  useEffect(() => {
    var poly = turf.polygon([[[0, 29], [3.5, 29], [2.5, 32], [0, 29]]]);
    var options = { pivot: [0, 25] };
    var rotatedPoly = turf.transformRotate(poly, 10, options);
    console.log("RotatedPoly: ", rotatedPoly)

    var center = [-75.343, 39.984];
    var radius = 5;
    var options = { steps: 10, units: 'kilometers', properties: { foo: 'bar' } };
    var circle = turf.circle(center, radius, options);
    console.log("Circle: ", circle)

    var p1 = [41.8121843, 45.2178516];
    var p2 = [41.8124878, 45.2177536];

    var l = turf.lineString([p1, p2]);

    // Rotate line around p1
    var trufRotated = turf.transformRotate(l, -90, { pivot: p1 });
    console.log("Line: ", trufRotated)

  }, [])

  const [zoom, setZoom] = useState(19)

  return <div>
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap(ref) }}
      onClick={e => console.log(e)}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>}
    <br></br>
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap2(ref) }}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>}
    <br></br>
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap3(ref) }}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>}
    <br></br>
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap4(ref) }}
      zoom={20}
      center={floorPlanFiltered.center}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>}
    <br></br>
    {floorPlanFiltered && <Map
      id="map"
      ref={(ref) => { setMap5(ref) }}
      zoom={13}
      center={[29, 0]}
      style={{ height: "500px", zIndex: 1 }}
    >
      <TileLayer
        maxZoom={24}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>}
  </div>
}





/*export function MapWidget() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()
  const [map, setMap] = useState()
  const [editableFG, setEditableFG] = useState()
  const [zones, setZones] = useState([])
  const [layersEdit, setLayersEdit] = useState()
  const [layersDelete, setLayersDelete] = useState()
  const [colorNumber, setColorNumber] = useState(0)
  const [points, setPoints] = useState([])
  const [markers, setMarkers] = useState([])

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
          setPoints(acessPoints)
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData();
  }, [selectedCustomer, UIProps.selectedFloor]);


  {/* UseEffect * }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      setZones([])
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  useEffect(() => {
    var data = []
    console.log("FloorPlan X: ", floorPlanFiltered?.width)
    console.log("FloorPlan Y: ", floorPlanFiltered?.height)
    console.log("Points: ", points)
    floorPlanFiltered && points.forEach((value) => {
      console.log("Point X: ", value.loc.x)
      console.log("Point Y: ", value.loc.y)
      var x = (value.loc.x * 100) / floorPlanFiltered.width
      var y = (value.loc.y * 100) / floorPlanFiltered.height
      console.log("X: ", x + "%")
      console.log("Y: ", (100 - y) + "%")
      data.push({
        id: value.clientMac,
        name: value.clientMac,
        x: x + "%",
        y: (100 - y) + "%",
      })
    })
    setMarkers(data)
  }, [points])

/*
  useEffect(() => {
    if (floorPlanFiltered) {
      var data = []
      console.log("FloorPlan X: ", floorPlanFiltered?.width)
      console.log("FloorPlan Y: ", floorPlanFiltered?.height)
      var from = turf.point([floorPlanFiltered?.bottomLeftCorner.lat, floorPlanFiltered?.bottomLeftCorner.lng])
      var to = turf.point([floorPlanFiltered?.bottomRightCorner.lat, floorPlanFiltered?.bottomRightCorner.lng])
      var options = { units: 'meters' }

      var xDistance = turf.distance(from, to, options)
      var from = turf.point([floorPlanFiltered?.bottomLeftCorner.lat, floorPlanFiltered?.bottomLeftCorner.lng])
      var to = turf.point([floorPlanFiltered?.topLeftCorner.lat, floorPlanFiltered?.topLeftCorner.lng])
      var options = { units: 'meters' }

      var yDistance = turf.distance(from, to, options)
      console.log("FloorPlan X: ", xDistance)
      console.log("FloorPlan Y: ", yDistance)

      points.forEach((value) => {
        console.log("Point X: ", value.loc.x)
        console.log("Point Y: ", value.loc.y)
        var x = (value.loc.x * 100) / xDistance
        var y = (value.loc.y * 100) / yDistance
        console.log("X: ", x + "%")
        console.log("Y: ", (100 - y) + "%")
        console.log("Client: ", value.loc.accurate, value.clientMac)
        data.push({
          id: value.clientMac,
          name: value.clientMac,
          x: x + "%",
          y: (100 - y) + "%",
        })
      })
      setMarkers(data)
    }
  }, [points])
*
  return (
    <>
      {floorPlanFiltered && <div style={{ position: "relative" }}>
        <img
          src={floorPlanFiltered.imageUrl}
          style={{ position: "relative", maxWidth: "100%", height: "auto" }}
        />
        {resources && resources.map((marker) => {
          return marker.percentageX && marker.floorPlanId == UIProps.selectedFloor && <img
            src={toAbsoluteUrl("/deskBlack.svg")}
            style={{ position: "absolute", left: marker.percentageX+"%", top: marker.percentageY+"%" }}
          />
        })}
      </div>
      }
    </>
  )
}
*/