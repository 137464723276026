import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, inputStyle) => {
   const classes = ["form-control"/*, "form-control-solid"*/];
   if (touched && errors) {
      classes.push("is-invalid-select");
   }

   if (touched && !errors) {
      classes.push("is-valid-select");
   }

   if (inputStyle?.includes("solid")) {
      classes.push("form-control-solid")
   }

   if (inputStyle?.includes("large")) {
      classes.push("form-control-lg")
   }

   return classes.join(" ");
};

export function Select({
   label,
   withFeedbackLabel = true,
   type = "text",
   customFeedbackLabel,
   children,
   inputStyle,
   ...props
}) {
   const [field, meta] = useField(props);
   const { touched, error } = meta;
   return (
      <>
         {label && <b>{/*Select*/} {label}:</b>}
         <select
            className={getFieldCSSClasses(touched, error, inputStyle)}
            {...field}
            {...props}
         >
            {children}
         </select>
         {withFeedbackLabel && (
            <FieldFeedbackLabel
               erros={error}
               touched={touched}
               label={label}
               customFeedbackLabel={customFeedbackLabel}
            />
         )}
      </>
   );
}
