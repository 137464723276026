import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../../UIContext";
import * as actions from "../../../_redux/reporting/reportingActions";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import Chart from "react-apexcharts";
import {
  LinearProgress,
  withStyles,
  Badge,
  Popover,
  makeStyles,
} from "@material-ui/core";
import { Nav, Tab } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import moment from "moment";

export function ZoneAvgTimeSpent() {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const { isLoading, customer, zoneAvgTimeSpent } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneAvgTimeSpentLoading,
      customer: state.profile?.currentCustomer,
      zoneAvgTimeSpent: state.reporting.zoneAvgTimeSpent,
    }),
    shallowEqual
  );

  const [graphData, setGraphData] = useState();
  const [key, setKey] = useState("all week");

  const handleSubmit = (value) => {
    if (typeof value === "string") {
      setKey(value);
    }
    setKey(key);
  };

  useEffect(() => {
    if (!zoneAvgTimeSpent) return;
    if (key === "all week" || UIProps.queryParams.timeframe === "day") {
      setGraphData(zoneAvgTimeSpent.allWeekDataFinal);
      return;
    }
    if (key === "working days") {
      setGraphData(zoneAvgTimeSpent.workingDaysDataFinal);
      return;
    }
    if (key === "weekends") {
      setGraphData(zoneAvgTimeSpent.weekendsDataFinal);
      return;
    }
  }, [zoneAvgTimeSpent, UIProps.queryParams.timeframe, key]);

  let filterButtons = (
    <div className='card-toolbar row d-flex justify-content-center mt-n3'>
      <Tab.Container activeKey={key}>
        <Nav
          as='ul'
          onSelect={(_key) => setKey(_key)}
          className='nav nav-pills nav-pills-sm nav-dark-75'
        >
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='all week'
              onClick={() => handleSubmit("all week")}
              className={`nav-link py-2 px-4 ${
                key === "all week" ? "active" : ""
              }`}
            >
              All Week
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='working days'
              onClick={() => handleSubmit("working days")}
              className={`nav-link py-2 px-4 ${
                key === "working days" ? "active" : ""
              }`}
            >
              Working Days
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='weekends'
              onClick={() => handleSubmit("weekends")}
              className={`nav-link py-2 px-4 ${
                key === "weekends" ? "active" : ""
              }`}
            >
              Weekends
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </div>
  );
  if (UIProps.queryParams.timeframe === "day") {
    filterButtons = (
      <div className='card-toolbar row d-flex justify-content-center mt-n3'></div>
    );
  }
  // console.log("graphData: ", graphData);

  return (
    <Card isLoading={isLoading} style={{ height: "475px" }}>
      <CardHeader
        title={<Title />}
        //title="Average Time Spent"
        //subtitle="Busiest zones"
      />
      <CardBody>
        <Chart
          options={getChartOptions()}
          series={graphData || []}
          type='treemap'
          height='350'
        />
        {filterButtons}
      </CardBody>
    </Card>
  );
}

const colors = [
  {
    from: 0,
    to: 5,
    color: "#f79d7b",
    text: "up to 5min",
  },
  {
    from: 5.1,
    to: 15,
    color: "#f47d4f",
    text: "up to 15min",
  },
  {
    from: 15.1,
    to: 30,
    color: "#f25d24",
    text: "up to 30min",
  },
  {
    from: 30.1,
    to: 60,
    color: "#da450c",
    text: "up to 1h",
  },
  {
    from: 60.1,
    to: 240,
    color: "#af370a",
    text: "up to 4h",
  },
  {
    from: 240.1,
    to: 480,
    color: "#832907",
    text: "up to 8h",
  },
  {
    from: 480.1,
    to: 720,
    color: "#571b05",
    text: "up to 12h",
  },
  {
    from: 720.1,
    to: 99999999,
    color: "#2b0d02",
    text: "more than 12h",
  },
];

function getChartOptions() {
  return {
    chart: {
      offsetY: -20,
      animations: {
        enabled: false,
      },
      toolbar: {
        //offsetY: -20,
        offsetX: 10,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "PeopleDistributionByZone",
            columnDelimiter: ",",
            headerCategory: "Zone",
            headerValue: "Value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "PeopleDistributionByZone",
          },
          png: {
            filename: "PeopleDistributionByZone",
          },
        },
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No data to display.",
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        colorScale: {
          ranges: colors,
        },
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      inverseOrder: true,
      fixed: {
        enabled: true,
        position: "topRight",
        offsetX: 150,
        offsetY: 0,
      },
      y: {
        formatter: (val) =>
          moment
            .utc()
            .startOf("day")
            .add(val, "minutes")
            .format("HH:mm"),
        // val + " %",
      },
    },
  };
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    padding: "0",
  },
}))(Badge);

function Title() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  return (
    <StyledBadge
      badgeContent={
        <>
          <span
            className='w-100 text-center'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <BsInfoCircle style={{ fontSize: "1.3rem" }} />
          </span>
          <Popover
            id='mouse-over-popover'
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div style={{ width: "250px", wordWrap: "break-word" }}>
              <div className='pb-3'>
                <b>Average Time Spent: </b>
                <br />
                <p>Represents the average time spent by people on a zone.</p>
                <div
                  className='ml-3 mb-n5'
                  style={{ width: "150px", wordWrap: "break-word" }}
                >
                  {colors.map((val) => {
                    return (
                      <div className='row pb-2 pl-2'>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: val.color,
                          }}
                        ></div>
                        <span className='ml-2 mt-n1'>{val.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Popover>
        </>
      }
    >
      Average Time Spent
    </StyledBadge>
  );
}
