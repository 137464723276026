import { persistReducer, createTransform } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { put, takeLatest /*call*/ } from "redux-saga/effects"
import { /*getUserByToken,*/ getCurrentUser, getUserClaims } from "./authCrud"
import * as routerHelpers from "../../../routes/RouterHelpers"
import { auth /*createUserProfileDocument*/ } from "../../../../firebase"
import JSOG from "jsog"

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  getUserClaims: "GET_USER_CLAIMS",
}

const initialAuthState = {
  user: undefined,
  claims: undefined,
  authToken: undefined,
}

export const JSOGTransform = createTransform(
  (inboundState, key) => JSOG.encode(inboundState),
  (outboundState, key) => JSOG.decode(outboundState)
)

export const reducer = persistReducer(
  { storage, key: "demo1-auth", transforms: [JSOGTransform], whitelist: ["user", "claims", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation()
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload

        return { ...state, user }
      }

      case actionTypes.getUserClaims: {
        //console.log("type: getUserClaims, payload: ", action.payload)
        const { claims } = action.payload
        return { ...state, claims }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  signInFailure: error => ({
    type: actionTypes.SIGN_IN_FAILURE,
    payload: error,
  }),
  setUserClaims: claims => ({ type: actionTypes.getUserClaims, payload: { claims } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, userRequested)

  yield takeLatest(actionTypes.Logout, logoutSaga)
}

function* logoutSaga() {
  try {
    yield auth.signOut()
    console.log("logout success")
  } catch (error) {
    //yield put(signOutFailure(error));
    console.log("sign out fail", error)
  }
}

function* userRequested() {
  const user = yield getCurrentUser()

  const userData = {
    username: user.displayName,
    email: user.email,
    phone: user.phoneNumber,
    pic: user.photoURL,
    accessToken: user.uid,
    refreshToken: user.refreshToken,
    metadata: user.metadata,
    metadata: user.emailVerified,
    id: user.uid,
    fullname: user.displayName,
    tenantId: user.tenantId,
  }

  const userClaims = yield getUserClaims(user)
  const profileLvl = getProfileLvl(userClaims)
  yield put(actions.setUserClaims({ ...userClaims, profileLvl }))
  yield put(actions.fulfillUser(userData))
}

export function getProfileLvl(customClaims) {
  const profile = customClaims.isUser ? 1 : customClaims?.isAdmin ? 2 : customClaims?.isMSP ? 3 : customClaims?.isSuper ? 4 : 0
  return profile
}
