import React from "react";
import ReactDOM from "react-dom";
import {
  Tooltip,
  TextField,
  IconButton,
  Tabs,
  Tab,
  SvgIcon,
} from "@material-ui/core/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { profileSlice } from "../../../../redux/profile/profileSlice";
import CloseIcon from "@material-ui/icons/Close";
import * as turf from "@turf/turf";
import PathFinder from "geojson-path-finder";
import { BsFillGeoFill } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
// import { geometry } from "@turf/turf";
import stairs from "../../../../assets/designStudio/stairs (white).png";
import elevator from "../../../../assets/designStudio/elevator (white).png";
import escalator from "../../../../assets/designStudio/escalator (white).png";
import ramp from "../../../../assets/designStudio/slope-downhill (white).png";
import { GiElevator } from "react-icons/gi";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

const { actions } = profileSlice;

export default class DirectionsControl {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";

    this._buddies = this.props.buddies
      ? this.props.buddies
          .map((option) => {
            return {
              group: "Buddies",
              name: option.displayName,
              ...option,
            };
          })
          .filter((val) => {
            return val.email !== this.props.userEmail;
          })
          .sort((a, b) => {
            return -b.group.localeCompare(a.group);
          })
      : [];

    this._zones = this.props.zones
      ? this.props.zones
          .map((option) => {
            return {
              group: "Zones",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    this._poi = this.props.getDirections.poi
      ? this.props.getDirections.poi
      : null;

    this._pois = this.props.pois
      ? this.props.pois
          .map((option) => {
            return {
              group: "Points of Interest",
              ...option,
            };
          })
          .sort((a, b) => -b.group.localeCompare(a.group))
      : [];

    // this._sensors = this.props.sensors
    //   ? this.props.sensors
    //       .map((option) => {
    //         return {
    //           group: "Points of Interest",
    //           ...option,
    //         };
    //       })
    //       .sort((a, b) => -b.group.localeCompare(a.group))
    //   : [];

    let tabIcon = <GiElevator fontSize={20} />;

    // Existing connectors on this floor
    const existingTabsOnThisFloor = [];

    this.props.floorPlanData &&
      this.props.floorPlanData.forEach((floor) => {
        floor.geoJsonRoutes?.features &&
          floor.geoJsonRoutes.features.forEach((f) => {
            if (f.properties.linkToFloors?.includes(this.props.floorPlan.id)) {
              if (f.properties.connectorType === "ELEVATOR") {
                tabIcon = <GiElevator fontSize={20} />;
              }
              if (f.properties.connectorType === "STAIRS") {
                tabIcon = (
                  <SvgIcon style={{ fontSize: "22px" }}>
                    <path
                      fill='currentColor'
                      d='M15,5V9H11V13H7V17H3V20H10V16H14V12H18V8H22V5H15Z'
                    />
                  </SvgIcon>
                );
              }
              if (f.properties.connectorType === "ESCALATOR") {
                tabIcon = (
                  <SvgIcon style={{ fontSize: "22px" }}>
                    <path
                      fill='currentColor'
                      d='M20,8H18.95L6.95,20H4A2,2 0 0,1 2,18A2,2 0 0,1 4,16H5.29L7,14.29V10A1,1 0 0,1 8,9H9A1,1 0 0,1 10,10V11.29L17.29,4H20A2,2 0 0,1 22,6A2,2 0 0,1 20,8M8.5,5A1.5,1.5 0 0,1 10,6.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 7,6.5A1.5,1.5 0 0,1 8.5,5Z'
                    />
                  </SvgIcon>
                );
              }
              if (f.properties.connectorType === "RAMP") {
                tabIcon = (
                  <SvgIcon style={{ fontSize: "22px" }}>
                    <svg
                      version='1.1'
                      id='Layer_1'
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      viewBox='0 0 512 512'
                      style={{ width: "18px" }}
                    >
                      <g>
                        <path
                          fill='currentColor'
                          // class='st0'
                          d='M505.7,267c-155.4,48.6-310.8,97.2-466.2,145.7c-12.8,4-25.6,7.7-38.5,11.5c0,28.9,0,57.8,0,86.7
		c170,0,340,0,510,0c0-81.6,0-163.3,0-245.3C508.9,266.2,507.3,266.6,505.7,267z'
                        />
                        <path
                          fill='currentColor'
                          // class='st0'
                          d='M42,376.2c2.7,2.3,4.9,2.9,8.4,1.8c18.6-6,37.3-11.7,55.9-17.6c41.9-13.1,83.8-26.3,125.7-39.2
		c4-1.2,5.3-3.6,5.1-7.2c-0.3-4.4-3.4-4.3-6.8-4.3c-25.6,0.1-51.1,0-76.7,0c-36.8,0-62.7-25.8-62.8-62.6c0-23.7,0-47.5,0-71.2
		c0-1.9,0-3.8,0-6.3c-3.1,0.8-5.4,1.3-7.5,2C41.9,186,16.4,215,5.2,256.9C3,265,2.4,273.5,1,281.8c0,3.6,0,7.3,0,11
		c0.3,1.4,0.7,2.9,0.9,4.3C4.6,328.9,18,355.2,42,376.2z'
                        />
                        <path
                          fill='currentColor'
                          // class='st0'
                          d='M147.2,91.1c-17.2,3.4-26.1,14.2-26.2,31.8c-0.1,41.7-0.1,83.3,0,125c0.1,19.6,12.7,31.9,32.2,31.9
		c32.5,0,65.1,0.1,97.6-0.1c4,0,5.8,1.1,7.2,4.9c3.1,8.5,6.6,16.9,10.1,25.4c17.2-5.4,33.9-10.6,50.9-16
		c-10.5-23.6-20.8-46.6-30.9-69.7c-1.5-3.5-3.4-4.5-7-4.5c-31.2,0.1-62.4,0.1-93.6,0.1c-1.9,0-3.9,0-6,0c0-13.3,0-26,0-39.2
		c30,0,59.6,0,89.3,0c0-10.1,0-19.7,0-29.9c-29.8,0-59.5,0-89.7,0c0-9.1,0-17.7,0-26.3c0-18.6-7.6-28.6-25.4-33.4
		c30.6-9.7,43.8-27.4,39.5-53.2C191.5,15.8,172.8,0.5,150,1c-22.7,0.5-40.9,16.8-43.4,38.9C103.7,65.6,117.7,83.5,147.2,91.1z'
                        />
                      </g>
                    </svg>
                  </SvgIcon>
                );
              }
              existingTabsOnThisFloor.push({
                id: f.properties.connectorType + "_TAB",
                name: f.properties.connectorName,
                type: f.properties.connectorType,
                log: "clicked" + " " + f.properties.connectorType,
                icon: tabIcon,
              });
            }
          });

        {
        }
      });

    const sortedExistingTabsOnThisFloor =
      existingTabsOnThisFloor &&
      existingTabsOnThisFloor.sort((a, b) => a.id.localeCompare(b.id));
    // console.log(sortedExistingTabsOnThisFloor, "sortedExistingTabsOnThisFloor");

    this.options = [
      ...this._buddies,
      ...this._zones,
      ...this._pois /* , this._sensors */,
    ];

    this.content = (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          width: "320px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1.5rem",
          // paddingBottom: "3rem",
        }}
      >
        <IconButton
          fontSize='small'
          style={{
            fontSize: "10px",
            position: "absolute",
            top: "5px",
            left: "292px",
            width: "15px",
            height: "15px",
          }}
          onClick={() => {
            // console.log("pressed close, clicked false");
            // ↓ Empty start point on directions box
            this.props.setGetDirections({
              type: null,
              poi: null,
              clicked: false,
            });
            this.props.setTravelTo(); // ← Empty end point on directions box
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <div className='d-flex'>
          <BiTargetLock
            fontSize={14}
            style={{ position: "relative", top: "22px", right: "3px" }}
          />

          <Autocomplete
            id='grouped'
            size='small'
            defaultValue={this.props.defaultValue}
            options={this.options}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value?.name}
            style={{ width: 250, margin: "0 auto", fontSize: 14 }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{ ...params.InputProps, style: { fontSize: 14 } }}
                //   variant='standard'
                margin='normal'
                placeholder='Go to'
              />
            )}
            onChange={(event, newValue) => {
              handleDirectionsChange({
                dispatch: this.props.dispatch,
                map: map,
                locations: this.props.locations,
                floorPlan: this.props.floorPlan,
                value: newValue,
                devices: this.props.devices,
                bleDevices: this.props.bleDevices,
                setTravelTo: this.props.setTravelTo,
                startPoint: this.props.getDirections,
                geoJsonRoutes: this.props.geoJsonRoutes,
                setPathFound: this.props.setPathFound,
                zones: this.props.zones,
                setConnectorAccess: this.props.setConnectorAccess,
                floorPlanData: this.props.floorPlanData,
                floorPlanId: this.props.floorPlan.id,
                typeByDefault: this.props.typeByDefault,
                setGetDirections: this.props.setGetDirections,
                setCopyOfConnectorCoords: this.props.setCopyOfConnectorCoords,
                setPoiToDoorCoords: this.props.setPoiToDoorCoords,
                setPoiToDoorEndPointCoords: this.props
                  .setPoiToDoorEndPointCoords,
              });
            }}
          />
        </div>
        <div className='d-flex'>
          <BsFillGeoFill
            fontSize={14}
            style={{ position: "relative", top: "7px", right: "3px" }}
          />

          <TextField
            style={{ width: 250, margin: "0 auto", height: "4.5rem" }}
            disabled
            //   {...params}
            // variant='standard'
            // InputProps={{ disableUnderline: true }}
            inputProps={{ style: { fontSize: 14, color: "#212121" } }}
            margin='normal'
            //   placeholder='Go to'
            //   label='from'
            value={
              this.props.getDirections.type === "poi"
                ? this._poi.name
                : this.props.getDirections.type === "zone"
                ? this._poi.properties.name
                : null
            }
          />
        </div>
        <div className='tabsDiv'>
          <Tabs
            value={this.props.iconTab}
            onChange={(e, newValue) => this.props.setIconTab(newValue)}
            centered
            indicatorColor='primary'
            textColor='primary'
            aria-label='icon label tabs'
          >
            {sortedExistingTabsOnThisFloor &&
              sortedExistingTabsOnThisFloor.map((tab) => {
                return (
                  <Tooltip title={tab.name} key={tab.name}>
                    <Tab
                      key={tab.name}
                      id={tab.id}
                      onClick={() => {
                        // console.log(tab.log);
                        this.props.setTypeByDefault(tab.type);
                      }}
                      className='tabDashboard'
                      icon={tab.icon}
                    />
                  </Tooltip>
                );
              })}
            {/* <Tab
              id={"ELEVATOR" + "_TAB"}
              onClick={() => {
                console.log("clicked ELEVATOR");
                this.props.setTypeByDefault("ELEVATOR");
              }}
              className='tabDashboard'
              icon={<GiElevator fontSize={20} />}
            />
            <Tab
              id={"STAIRS" + "_TAB"}
              onClick={() => {
                console.log("clicked STAIRS");
                this.props.setTypeByDefault("STAIRS");
              }}
              className='tabDashboard'
              icon={
                <SvgIcon style={{ fontSize: "22px" }}>
                  <path
                    fill='currentColor'
                    d='M15,5V9H11V13H7V17H3V20H10V16H14V12H18V8H22V5H15Z'
                  />
                </SvgIcon>
              }
            />
            <Tab
              id={"ESCALATOR" + "_TAB"}
              onClick={() => {
                console.log("clicked ESCALATOR");
                this.props.setTypeByDefault("ESCALATOR");
              }}
              className='tabDashboard'
              icon={
                <SvgIcon style={{ fontSize: "22px" }}>
                  <path
                    fill='currentColor'
                    d='M20,8H18.95L6.95,20H4A2,2 0 0,1 2,18A2,2 0 0,1 4,16H5.29L7,14.29V10A1,1 0 0,1 8,9H9A1,1 0 0,1 10,10V11.29L17.29,4H20A2,2 0 0,1 22,6A2,2 0 0,1 20,8M8.5,5A1.5,1.5 0 0,1 10,6.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 7,6.5A1.5,1.5 0 0,1 8.5,5Z'
                  />
                </SvgIcon>
              }
            />
            <Tab
              id={"RAMP" + "_TAB"}
              onClick={() => {
                console.log("clicked RAMP");
                this.props.setTypeByDefault("RAMP");
              }}
              className='tabDashboard'
              icon={
                <svg
                  version='1.1'
                  id='Layer_1'
                  xmlns='http://www.w3.org/2000/svg'
                  x='0px'
                  y='0px'
                  viewBox='0 0 512 512'
                  style={{ width: "18px" }}
                >
                  <g>
                    <path
                      fill='currentColor'
                      // class='st0'
                      d='M505.7,267c-155.4,48.6-310.8,97.2-466.2,145.7c-12.8,4-25.6,7.7-38.5,11.5c0,28.9,0,57.8,0,86.7
		c170,0,340,0,510,0c0-81.6,0-163.3,0-245.3C508.9,266.2,507.3,266.6,505.7,267z'
                    />
                    <path
                      fill='currentColor'
                      // class='st0'
                      d='M42,376.2c2.7,2.3,4.9,2.9,8.4,1.8c18.6-6,37.3-11.7,55.9-17.6c41.9-13.1,83.8-26.3,125.7-39.2
		c4-1.2,5.3-3.6,5.1-7.2c-0.3-4.4-3.4-4.3-6.8-4.3c-25.6,0.1-51.1,0-76.7,0c-36.8,0-62.7-25.8-62.8-62.6c0-23.7,0-47.5,0-71.2
		c0-1.9,0-3.8,0-6.3c-3.1,0.8-5.4,1.3-7.5,2C41.9,186,16.4,215,5.2,256.9C3,265,2.4,273.5,1,281.8c0,3.6,0,7.3,0,11
		c0.3,1.4,0.7,2.9,0.9,4.3C4.6,328.9,18,355.2,42,376.2z'
                    />
                    <path
                      fill='currentColor'
                      // class='st0'
                      d='M147.2,91.1c-17.2,3.4-26.1,14.2-26.2,31.8c-0.1,41.7-0.1,83.3,0,125c0.1,19.6,12.7,31.9,32.2,31.9
		c32.5,0,65.1,0.1,97.6-0.1c4,0,5.8,1.1,7.2,4.9c3.1,8.5,6.6,16.9,10.1,25.4c17.2-5.4,33.9-10.6,50.9-16
		c-10.5-23.6-20.8-46.6-30.9-69.7c-1.5-3.5-3.4-4.5-7-4.5c-31.2,0.1-62.4,0.1-93.6,0.1c-1.9,0-3.9,0-6,0c0-13.3,0-26,0-39.2
		c30,0,59.6,0,89.3,0c0-10.1,0-19.7,0-29.9c-29.8,0-59.5,0-89.7,0c0-9.1,0-17.7,0-26.3c0-18.6-7.6-28.6-25.4-33.4
		c30.6-9.7,43.8-27.4,39.5-53.2C191.5,15.8,172.8,0.5,150,1c-22.7,0.5-40.9,16.8-43.4,38.9C103.7,65.6,117.7,83.5,147.2,91.1z'
                    />
                  </g>
                </svg>
              }
            /> */}
          </Tabs>
        </div>
      </div>
    );

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode &&
      this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

function handleDirectionsChange(props) {
  // console.log(props, "props");
  const {
    dispatch,
    map,
    locations,
    floorPlan,
    value,
    devices,
    bleDevices,
    setTravelTo,
    startPoint,
    geoJsonRoutes,
    setPathFound,
    zones,
    setConnectorAccess,
    floorPlanData,
    floorPlanId,
    typeByDefault,
    setGetDirections,
    setCopyOfConnectorCoords,
    setPoiToDoorCoords,
    setPoiToDoorEndPointCoords,
  } = props;

  value && setTravelTo(value); // ← sets the end point to the value of select autocomplete input

  let finalFeaturesLines = [];
  let finalFeaturesPoints = [];
  geoJsonRoutes &&
    geoJsonRoutes.features.forEach((feature, index) => {
      if (feature.geometry.type === "LineString") {
        let coordinates = [];
        feature.geometry.coordinates.forEach((coords) => {
          coordinates.push([coords.longitude, coords.latitude]);
        });
        finalFeaturesLines.push({
          type: geoJsonRoutes.features[index].type,
          properties: {
            ...geoJsonRoutes.features[index].properties,
          },
          geometry: {
            type: geoJsonRoutes.features[index].geometry.type,
            coordinates: coordinates,
          },
        });
      }
      if (feature.geometry.type === "Point") {
        let coordinates = [];
        feature.geometry.coordinates.forEach((coords) => {
          coordinates.push(coords.longitude, coords.latitude);
        });
        finalFeaturesPoints.push({
          type: geoJsonRoutes.features[index].type,
          properties: {
            ...geoJsonRoutes.features[index].properties,
          },
          geometry: {
            type: geoJsonRoutes.features[index].geometry.type,
            coordinates: coordinates,
          },
        });
      }
    });

  // console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
  // console.log("🚀 ~ finalFeaturesPoints", finalFeaturesPoints);

  // const finalLayerPoints = {
  //   type: "FeatureCollection",
  //   features: finalFeaturesPoints,
  // };
  // console.log(finalLayerPoints, "finalLayerPoints");

  const finalLayerLines = {
    type: "FeatureCollection",
    features: finalFeaturesLines,
  };
  // console.log(finalLayerLines, "finalLayerLines");

  // ↓ turn lines into little chunks.
  const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
    units: "kilometers",
  });
  // console.log("🚀 ~ chunk", chunk);

  const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 });
  // console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

  const points = [];

  for (const [key, value] of Object.entries(
    pathFinderOnSameFloor._graph.vertices
  )) {
    // console.log("Key: ", key);
    const latlong = key.split(",");
    const lng = parseFloat(latlong[0]);
    const lat = parseFloat(latlong[1]);
    points.push(turf.point([lng, lat]));
  }
  // console.log("Points: ", JSON.stringify(points));

  if (value && startPoint) {
    // console.log("🚀 ~ value", value); // ← endPoint
    // console.log("🚀 ~ startPoint", startPoint);

    // ↓ End Point (point selected where the user want to go)
    let destinationPointTurfed = null;
    // ↓ Start Point (point selected where the user want to go)
    let startPointTurfed = null;

    // ↓ Connectors in this floor
    const rightFeatures = [];
    floorPlanData &&
      floorPlanData.forEach((floor) => {
        floor.geoJsonRoutes?.features &&
          floor.geoJsonRoutes.features.forEach((f) => {
            if (f.properties.linkToFloors?.includes(floorPlanId)) {
              // console.log(f, "f");

              let coords = [];
              f.geometry.coordinates.forEach((coordinates) => {
                coords.push(coordinates.longitude, coordinates.latitude);
              });
              let finalFeature = {
                ...f,
                geometry: {
                  ...f.geometry,
                  coordinates: coords,
                },
              };
              rightFeatures.push(finalFeature);
            }
          });
      });
    // console.log("🚀 ~ rightFeatures Connectors in this floor", rightFeatures);

    // ↓ Type of connector. By default it is elevator.
    // let typeByDefault = "ELEVATOR"; // Este valor vai ser uma variable q vai vir dos icons que tenho que colocar na directions box para selecionar o connector que o user deseja, by default vai ser o elevator se existir senao passa a frente na array de values. Retirar esta string e colocar o value que vem dos icons ou checkbox ou algo do genero.

    const selectedFeature =
      rightFeatures &&
      rightFeatures.filter(
        (feature) => feature.properties.connectorType === typeByDefault
      );
    // console.log("🚀 ~ selectedFeature", selectedFeature); // ← connector type selected by user
    // console.log("🚀 ~ typeByDefault", typeByDefault);

    // ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
    const connectorPositionInSelectedFloor =
      selectedFeature[0]?.properties?.connectorPositionOnFloors &&
      selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
        (marker) => marker.floorPlanId === floorPlan.floorPlanId
      );
    // console.log(
    //   "🚀 ~ connectorPositionInSelectedFloor",
    //   connectorPositionInSelectedFloor
    // );

    const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
      connectorPositionInSelectedFloor.coords.longitude,
      connectorPositionInSelectedFloor.coords.latitude,
    ];
    // console.log(
    //   "🚀 ~ connectorPositionFetchedCoords",
    //   connectorPositionFetchedCoords
    // );

    const conditionalCoords = connectorPositionFetchedCoords
      ? connectorPositionFetchedCoords
      : selectedFeature[0]?.geometry.coordinates;
    // console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

    if (value.group === "Points of Interest") {
      // ↑ POI Markers
      // console.log("🚀 ~ value.group POI", value.group);
      // console.log("🚀 ~ floorPlan.floorPlanId", floorPlan.floorPlanId);
      // console.log("🚀 ~ value.floorPlanId", value.floorPlanId);

      setPoiToDoorCoords();
      setPoiToDoorEndPointCoords();

      if (value.floorPlanId === floorPlan.floorPlanId) {
        // console.log("floorPlanId EQUAL Directions Control");
        if (!floorPlan.geoJson) {
          return;
        }

        // If POI is inside a polygon
        const poiInsidePolyCoords = [];
        floorPlan &&
          floorPlan.geoJson.features.forEach((feature) => {
            let coordinates = [];
            if (feature.properties.name === "Base Layer") {
              return;
            }
            feature.geometry.coordinates.forEach((coords) => {
              coordinates.push([coords.longitude, coords.latitude]);
            });

            // console.log("🚀 ~ coordinates", coordinates);
            const poly = turf.polygon([coordinates]);
            // console.log(
            //   turf.booleanPointInPolygon(
            //     [value?.coords?.longitude, value?.coords?.latitude],
            //     poly
            //   ),
            //   "BOOLEAN EXISTS"
            // );
            const existsInsidePolygon = turf.booleanPointInPolygon(
              [value?.coords?.longitude, value?.coords?.latitude],
              poly
            );
            if (existsInsidePolygon) {
              // console.log(feature, "feature that exists POI inside polygon");
              // console.log(value, "value that exists POI inside polygon");
              const fetchZoneWithMarkerCoords =
                zones &&
                zones.find((zone) => zone.id === feature.properties.zoneId);
              // console.log(
              //   "🚀 ~ fetchZoneWithMarkerCoords 3",
              //   fetchZoneWithMarkerCoords
              // );
              poiInsidePolyCoords.push(fetchZoneWithMarkerCoords);
            }
          });

        // console.log(poiInsidePolyCoords.length, "length");
        // console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

        // if POI not inside a polygon
        if (poiInsidePolyCoords.length === 0) {
          destinationPointTurfed = turf.point([
            value?.coords?.longitude,
            value?.coords?.latitude,
          ]);
        }

        // if POI is inside a polygon
        if (poiInsidePolyCoords.length !== 0) {
          setPoiToDoorEndPointCoords([
            poiInsidePolyCoords[0]?.doorMarker?.longitude,
            poiInsidePolyCoords[0]?.doorMarker?.latitude,
          ]);
          destinationPointTurfed = turf.point([
            poiInsidePolyCoords[0]?.doorMarker?.longitude,
            poiInsidePolyCoords[0]?.doorMarker?.latitude,
          ]);
        }

        // destinationPointTurfed = turf.point([
        //   value?.coords?.longitude,
        //   value?.coords?.latitude,
        // ]);
      }
      if (value.floorPlanId !== floorPlan.floorPlanId) {
        // console.log("floorPlanId NOT EQUAL Directions Control");

        // ↓ If connector type selected is linked to the endPoint floor condition
        const isLinked =
          selectedFeature[0] &&
          selectedFeature[0].properties?.linkToFloors.some(
            (floor) => floor === value.floorPlanId
          );
        // console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

        // Find end Point floor name
        const endPointName =
          floorPlanData &&
          floorPlanData.find((f) => f.floorPlanId === value.floorPlanId);
        // console.log("🚀 ~ endPointName", endPointName);

        if (!isLinked) {
          const paragraphToaster = (
            <p style={{ textAlign: "center" }}>
              This connector,{" "}
              <b>{selectedFeature[0].properties.connectorName}</b>, is not
              linked to {endPointName.name}. <br />
              Please select another connector.
            </p>
          );

          dispatch({
            type: "SNACKBAR_ERROR",
            payload: paragraphToaster,
          });

          return;
        }

        // ↓ old code
        // destinationPointTurfed =
        //   selectedFeature[0] &&
        //   turf.point(selectedFeature[0]?.geometry.coordinates);

        // ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
        selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords);

        // ↓ if the end Point is on another floor, the end point becomes the connector on this floor
        destinationPointTurfed =
          selectedFeature[0] && turf.point(conditionalCoords);

        // console.log("🚀 ~ destinationPointTurfed POI", destinationPointTurfed);
        selectedFeature[0] &&
          setConnectorAccess({
            clicked: true,
            zIndex: "2",
            id: selectedFeature[0].properties.connectorType,
          });
      }
    }
    if (startPoint.type === "poi") {
      // console.log("🚀 ~ startPoint type poi", startPoint.type);
      if (!floorPlan.geoJson) {
        return;
      }

      // If POI is inside a polygon
      const poiInsidePolyCoords = [];
      floorPlan &&
        floorPlan.geoJson.features.forEach((feature) => {
          let coordinates = [];
          if (feature.properties.name === "Base Layer") {
            return;
          }
          feature.geometry.coordinates.forEach((coords) => {
            coordinates.push([coords.longitude, coords.latitude]);
          });

          // console.log("🚀 ~ coordinates", coordinates);
          const poly = turf.polygon([coordinates]);
          // console.log(
          //   turf.booleanPointInPolygon(
          //     [startPoint.poi.coords.longitude, startPoint.poi.coords.latitude],
          //     poly
          //   ),
          //   "BOOLEAN EXISTS"
          // );
          const existsInsidePolygon = turf.booleanPointInPolygon(
            [startPoint.poi.coords.longitude, startPoint.poi.coords.latitude],
            poly
          );
          if (existsInsidePolygon) {
            // console.log(feature, "feature that exists POI inside polygon");
            // console.log(value, "value that exists POI inside polygon");
            const fetchZoneWithMarkerCoords =
              zones &&
              zones.find((zone) => zone.id === feature.properties.zoneId);
            // console.log(
            //   "🚀 ~ fetchZoneWithMarkerCoords 5",
            //   fetchZoneWithMarkerCoords
            // );
            poiInsidePolyCoords.push(fetchZoneWithMarkerCoords);
          }
        });

      // console.log(poiInsidePolyCoords.length, "length");
      // console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

      // if POI not inside a polygon
      if (poiInsidePolyCoords.length === 0) {
        startPointTurfed = turf.point([
          startPoint.poi.coords.longitude,
          startPoint.poi.coords.latitude,
        ]);
      }

      // if POI is inside a polygon
      if (poiInsidePolyCoords.length !== 0) {
        setPoiToDoorCoords([
          poiInsidePolyCoords[0]?.doorMarker?.longitude,
          poiInsidePolyCoords[0]?.doorMarker?.latitude,
        ]);

        startPointTurfed = turf.point([
          poiInsidePolyCoords[0]?.doorMarker?.longitude,
          poiInsidePolyCoords[0]?.doorMarker?.latitude,
        ]);
      }

      // Old code without poi inside polygon if
      // startPointTurfed = turf.point([
      //   startPoint.poi.coords.longitude,
      //   startPoint.poi.coords.latitude,
      // ]);
    }

    if (value.group === "Zones") {
      // ↑ Zones Markers
      // console.log("🚀 ~ value.group Zones", value.group);
      // console.log("🚀 ~ floorPlan.floorPlanId zones", floorPlan.floorPlanId);
      // console.log("🚀 ~ value.floorPlanId zones", value.floorPlanId);

      setPoiToDoorCoords();
      setPoiToDoorEndPointCoords();

      if (value.floorPlanId === floorPlan.floorPlanId) {
        // console.log("floorPlanId EQUAL zones");
        destinationPointTurfed = turf.point([
          value.doorMarker?.longitude,
          value.doorMarker?.latitude,
        ]);
      }
      if (value.floorPlanId !== floorPlan.floorPlanId) {
        // console.log("floorPlanId NOT EQUAL zones");

        // ↓ If connector type selected is linked to the endPoint floor condition
        const isLinked =
          selectedFeature[0] &&
          selectedFeature[0].properties?.linkToFloors.some(
            (floor) => floor === value.floorPlanId
          );
        // console.log(
        //   "🚀 ~ isLinked connector not linked to this floor",
        //   isLinked
        // );

        // Find end Point floor name
        const endPointName =
          floorPlanData &&
          floorPlanData.find((f) => f.floorPlanId === value.floorPlanId);
        // console.log("🚀 ~ endPointName", endPointName);

        if (!isLinked) {
          const paragraphToaster = (
            <p style={{ textAlign: "center" }}>
              This connector,{" "}
              <b>{selectedFeature[0].properties.connectorName}</b>, is not
              linked to {endPointName.name}. <br />
              Please select another connector.
            </p>
          );

          dispatch({
            type: "SNACKBAR_ERROR",
            payload: paragraphToaster,
          });
          return;
        }

        // ↓ old code
        // destinationPointTurfed =
        //   selectedFeature[0] &&
        //   turf.point(selectedFeature[0]?.geometry.coordinates);

        // ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
        selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords);

        destinationPointTurfed =
          selectedFeature[0] && turf.point(conditionalCoords);

        selectedFeature[0] &&
          setConnectorAccess({
            clicked: true,
            zIndex: "2",
            id: selectedFeature[0].properties.connectorType,
          });
        // console.log(
        //   "🚀 ~ destinationPointTurfed zones",
        //   destinationPointTurfed
        // );
      }
    }

    if (startPoint.type === "zone") {
      // console.log("🚀 ~ startPoint type zone", startPoint.type);
      const fetchZoneWithMarkerCoords =
        zones &&
        zones.find((zone) => zone.id === startPoint.poi.properties.zoneId);
      // console.log(
      //   "🚀 ~ fetchZoneWithMarkerCoords 2",
      //   fetchZoneWithMarkerCoords
      // );

      const fetchedLng = fetchZoneWithMarkerCoords?.doorMarker?.longitude;
      const fetchedLat = fetchZoneWithMarkerCoords?.doorMarker?.latitude;
      startPointTurfed = turf.point([fetchedLng, fetchedLat]);
    }
    // console.log("🚀 ~ destinationPointTurfed", destinationPointTurfed);
    // console.log("🚀 ~ startPointTurfed", startPointTurfed);

    const nearestStartPoint =
      startPointTurfed &&
      turf.nearestPoint(startPointTurfed, turf.featureCollection(points));
    // console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

    const nearestEndPoint =
      destinationPointTurfed &&
      turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points));
    // console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

    const start =
      nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates);
    // console.log("🚀 ~ start", start);

    const end =
      nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates);
    // console.log("🚀 ~ end", end);

    const path =
      start && end
        ? pathFinderOnSameFloor.findPath(start, end)
        : dispatch({
            type: "SNACKBAR_WARNING",
            payload: "No routes or connectors available in this floor.",
          });
    // console.log("Path: ", path);

    // ↓ Sets Path
    path?.path && setPathFound(path);

    // ↓ Closes directions box
    path?.path &&
      setGetDirections({
        type: null,
        poi: null,
        clicked: false,
      });
  }
}
