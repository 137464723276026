import * as requestFromServer from "./mapZonesCrud";
import { mapZonesSlice, callTypes } from "./mapZonesSlice";
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers";

const { actions } = mapZonesSlice;

export const fetchFloorPlans = (customerId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!customerId) {
    dispatch(actions.floorPlansFetched({ entities: [] }));
  }

  return requestFromServer
    .getFloorPlans(customerId)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const cars = convertCollectionSnapshotToMap(response);
      console.log("fetch booking cars success", { response, cars, customerId })

      dispatch(
        actions.floorPlansFetched({ entities: cars })
      );
    })
    .catch((error) => {
      console.log("fetch booking cars", error)
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchZones = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zonesFetched({ entities: [] }));
  }

  return requestFromServer
    .getZones(queryParams)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const zones = convertCollectionSnapshotToMap(response);

      dispatch(
        actions.zonesFetched({ entities: zones })
      )
    }).catch((error) => {
      console.log("fetch zones ", error)
      error.clientMessage = "Can't find zones";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
}

export const saveZones = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .saveZones(queryParams).then(() => {
      dispatch({
        type: "SNACKBAR_SUCCESS",
        payload: `Zones saved`,
      })

    }).catch((error) => {
      error.clientMessage = "Can't save zones";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    })
}