import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
   listLoading: false,
   actionsLoading: false,
   allUsers: null,
   lastError: null,
}

export const callTypes = {
   list: "list",
   action: "action",
}

export const manageUsersSlice = createSlice({
   name: "manageUsers",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false
         } else {
            state.actionsLoading = false
         }
      },
      startCall: (state, action) => {
         state.error = null
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true
         } else {
            state.actionsLoading = true
         }
      },
      usersFetched: (state, action) => {
         state.listLoading = false
         state.error = null
         state.allUsers = action.payload
      },
      departmentsFetched: (state, action) => {
         //state.listLoading = false
         state.error = null
         state.departments = action.payload
      },
      userCreated: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.allUsers = state.allUsers ? [...state.allUsers, { ...action.payload.user, userDoc: action.payload.userDoc }] : [{ ...action.payload.user, userDoc: action.payload.userDoc }]
         state.departments = state.departments && state.departments.map((entity) => {
            if (entity.id === action.payload.department) {
               return {
                  ...entity,
                  employees: [
                     ...entity.employees,
                     action.payload.user.uid
                  ]
               }
            }
            return entity
         })
      },
      usersUploaded: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
      userEdited: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.allUsers = state.allUsers && state.allUsers.map((entity) => {
            if (entity.uid === action.payload.user.uid) {
               return { ...action.payload.user, userDoc: action.payload.userDoc }
            }
            return entity
         })
         state.departments = state.departments && state.departments.map((entity) => {
            if (entity.id === action.payload.department) {
               return {
                  ...entity,
                  employees: [
                     ...entity.employees,
                     action.payload.user.uid
                  ]
               }
            }
            const removeDepartment = action.payload.removeFromDepartment.find(val => val === entity.id)
            if (removeDepartment) {
               return {
                  ...entity,
                  employees: entity.employees.filter(val => val != action.payload.user.uid)
               }
            }
            return entity
         })
      },
      userDeleted: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.allUsers = state.allUsers.filter((val) => val.uid !== action.payload)
      },
      usersDeleted: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
   },
})
