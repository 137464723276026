/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect, useMemo } from "react"

import { useSelector, useDispatch } from "react-redux"
import { useUIContext } from "../UIContext"
import { firestore } from "../../../../firebase"
import firebase from "firebase/app"
import * as turf from "@turf/turf"
import "@turf/angle"

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import MapboxDraw from "@mapbox/mapbox-gl-draw"
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import RotateMode from 'mapbox-gl-draw-rotate-mode'

import { geoFromSVGFile, geoFromSVGXML } from "svg2geojson"

mapboxgl.accessToken = 'pk.eyJ1IjoiZGlvZ29tIiwiYSI6ImNra3p5aXoxeTAxZ3Eybm80aXNhbTN0dDAifQ.QBLSLKu6azLM9HZkUogIRA'

var draw

export function MapWidget4() {
  const dispatch = useDispatch()

  const floorPlanData = useSelector((state) => state.booking?.floorPlans)
  const resources = useSelector((state) => state.booking?.resources)
  const userClaims = useSelector((state) => state.auth.claims)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const selectedLocation = useSelector((state) => state.profile?.currentLocation?.netId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [floorPlanFiltered, setFloorPlanFiltered] = useState()
  const [currentFloorPlan, setCurrentFloorPlan] = useState()

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      changesMade: UIContext.changesMade,
      setChangesMade: UIContext.setChangesMade,
      selectedFloor: UIContext.selectedFloor,
      setSelectedFloor: UIContext.setSelectedFloor,
      openResourceCalendar: UIContext.openResourceCalendar
    }
  }, [UIContext])

  useEffect(() => {
    const fetchData = () => {
      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      firestore
        .collection(`Customers/${customer}/DevicesWiFi_v3`)
        .where(
          "loc.time",
          ">",
          firebase.firestore.Timestamp.fromMillis(
            Date.now() - 30 * 60000
          )
        )
        .get().then((snapshot) => {
          var acessPoints = []
          snapshot.forEach((doc) => {
            doc.data().loc?.x != null && (acessPoints.push(doc.data()))
          })
        }).catch(error => console.error(error))
    }
    selectedCustomer && fetchData()
  }, [selectedCustomer, UIProps.selectedFloor])


  {/* UseEffect */ }

  useEffect(() => {
    if (!selectedCustomer) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Please Select Customer`,
      })
      return
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (floorPlanData) {
      var filter = floorPlanData.filter(data => data.floorPlanId == UIProps.selectedFloor)
      setFloorPlanFiltered(filter[0])
    }
  }, [UIProps.selectedFloor])

  const mapContainerRef = useRef(null)
  const [lng, setLng] = useState(-9.158062711431398)
  const [lat, setLat] = useState(38.57793500061726)
  const [zoom, setZoom] = useState(19)
  const [map, setMap] = useState()
  const [draw, setDraw] = useState()

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
    })

    var drawL = new MapboxDraw({
      defaultMode: 'simple_select',
      modes: Object.assign({
        RotateMode: RotateMode
      }, MapboxDraw.modes)
    })

    map.addControl(drawL)

    setDraw(drawL)

    map.on('load', function () {
      setMap(map)
    })

    return () => map.remove()
  }, [])

  const [inputLat, setInputLat] = useState(38.578212)
  const [inputLng, setInputLng] = useState(-9.158039)
  const [widht, setWidht] = useState(0.0110750444706647997)
  const [height, setHeight] = useState(0.020401377972432)
  const [file, setFile] = useState()
  const [level, setLevel] = useState(1)

  const setMarker = async () => {
    //console.log("Lat: ", inputLat)
    //console.log("Lat: ", inputLng)
    var point = turf.point([inputLng, inputLat])
    var distance = height
    var bearing = 180

    var destination = turf.destination(point, distance, bearing)
    //console.log("Destination: ", destination)

    var point = turf.point([inputLng, inputLat])
    var distance = widht
    var bearing = 90

    var destination2 = turf.destination(point, distance, bearing)
    //console.log("Destination2: ", destination2)

    /*var marker = new mapboxgl.Marker()
      .setLngLat([inputLng, inputLat])
      .addTo(map)

    var marker = new mapboxgl.Marker()
      .setLngLat([destination2.geometry.coordinates[0], destination.geometry.coordinates[1]])
      .addTo(map)*/

    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const parser = new DOMParser();
      const xml = parser.parseFromString(text, 'text/xml')

      var metaInfo = xml.createElement("MetaInfo")
      var geo = xml.createElement("Geo")
      var geoItem = xml.createElement("GeoItem")
      var geoItem2 = xml.createElement("GeoItem")
      geo.appendChild(geoItem)
      geo.appendChild(geoItem2)
      metaInfo.appendChild(geo)

      xml.getElementsByTagName("svg")[0].insertBefore(metaInfo, xml.getElementsByTagName("rect")[0])

      var newAtt = xml.createAttribute("xmlns")
      newAtt.nodeValue = "http://www.prognoz.ru"
      var firstX = xml.createAttribute("X")
      var firstY = xml.createAttribute("Y")
      var firstLat = xml.createAttribute("Latitude")
      var firstLng = xml.createAttribute("Longitude")
      firstX.nodeValue = "0"
      firstY.nodeValue = "0"
      firstLat.nodeValue = inputLat
      firstLng.nodeValue = inputLng

      var secondX = xml.createAttribute("X")
      var secondY = xml.createAttribute("Y")
      var secondLat = xml.createAttribute("Latitude")
      var secondLng = xml.createAttribute("Longitude")
      secondX.nodeValue = xml.getElementsByTagName("svg")[0].getAttribute("width")
      secondY.nodeValue = xml.getElementsByTagName("svg")[0].getAttribute("height")
      secondLat.nodeValue = destination.geometry.coordinates[1]
      secondLng.nodeValue = destination2.geometry.coordinates[0]

      xml.getElementsByTagName("MetaInfo")[0].setAttributeNode(newAtt)
      xml.getElementsByTagName("GeoItem")[0].setAttributeNode(firstX)
      xml.getElementsByTagName("GeoItem")[0].setAttributeNode(firstY)
      xml.getElementsByTagName("GeoItem")[0].setAttributeNode(firstLat)
      xml.getElementsByTagName("GeoItem")[0].setAttributeNode(firstLng)
      xml.getElementsByTagName("GeoItem")[1].setAttributeNode(secondX)
      xml.getElementsByTagName("GeoItem")[1].setAttributeNode(secondY)
      xml.getElementsByTagName("GeoItem")[1].setAttributeNode(secondLat)
      xml.getElementsByTagName("GeoItem")[1].setAttributeNode(secondLng)

      var svg = new XMLSerializer().serializeToString(xml)

      //console.log(xml)
      //console.log(svg)



      var customer = superAdmin ? selectedCustomer : userClaims.customerId
      var snapshot = await firestore
        .doc(`Customers/${customer}/FloorPlans/${selectedLocation}`)
        .get()

      var layer = snapshot.data().layer
      var finalFeatures = []

      layer.features.forEach((feature, index) => {
        console.log("Level: ", feature.properties.level)
        if (feature.properties.level == 0) {
          var coordinates = []
          feature.geometry.coordinates.forEach((coords) => {
            coordinates.push([coords.longitude, coords.latitude])
          })
          layer.features[index].geometry.coordinates = []
          layer.features[index].geometry.coordinates.push(coordinates)

          finalFeatures.push(layer.features[index])
        } else {
          //layer.features.splice(index)
        }
      })

      finalFeatures.forEach((f, i) => {
        if (i != 0) {
          f.properties.color = "#FFFFFF"
          f.properties.outline = "#969696"
          f.properties.lineWidth = 2
        } else {
          f.properties.color = "#bfbfbf"
          f.properties.outline = "#696969"
          f.properties.lineWidth = 4
        }
      })

      var mapLayer = map.getLayer('overlay2')
      if (typeof mapLayer !== 'undefined') {
        map.removeLayer("overlay2")
      }
      var mapLayer2 = map.getSource('layerSource2')
      if (typeof mapLayer2 !== 'undefined') {
        map.removeSource("layerSource2")
      }

      setGeoJson(layer)
      map.addSource("layerSource2", {
        "type": "geojson",
        "data": {
          type: "FeatureCollection",
          features: finalFeatures
        }
      })

      map.addLayer({
        "id": "overlay2",
        "source": "layerSource2",
        "type": "fill",
        'layout': {
          "visibility": "visible"
        },
        'paint': {
          'fill-color': ['get', 'color'],
          "fill-outline-color": ['get', 'outline'],
          'fill-opacity': ['get', 'opacity']
        },
      })

      geoFromSVGXML(svg, layer => {
        let json = JSON.stringify(layer.geo)

        console.log("JSON: ", json)
        console.log("Layer: ", layer)

        var coordinates = []

        layer.features.forEach((feature, index) => {
          coordinates.push([feature.geometry.coordinates[0]])
        })

        var test = {
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            id: "MultyPolyMade",
            properties: {},
            geometry: {
              type: "MultiPolygon",
              coordinates: coordinates
            }
          }]
        }

        console.log("Coords: ", coordinates)
        console.log("Test: ", test)

        draw.add(test)
      })
    }
    reader.readAsText(file)
  }


  const savePlacement = async () => {
    var mapLayer = map.getLayer('overlay2')
    if (typeof mapLayer !== 'undefined') {
      map.removeLayer("overlay2")
    }
    var mapLayer2 = map.getSource('layerSource2')
    if (typeof mapLayer2 !== 'undefined') {
      map.removeSource("layerSource2")
    }
    var layer = draw.getAll()

    var coordinates = []

    layer.features[0].geometry.coordinates.forEach((coords, index) => {
      coordinates.push([coords[0]])
    })

    var test = {
      type: "FeatureCollection",
      features: coordinates.map((coords) => {
        return {
          type: "Feature",
          properties: {
            level: level
          },
          geometry: {
            type: "Polygon",
            coordinates: coords
          }
        }
      })
    }

    test.features.shift()
    console.log("Test: ", test)

    draw.deleteAll()
    draw.add(test)

    test.features.forEach((feature, index) => {
      var coordinates = []
      feature.geometry.coordinates[0].forEach((coords, i) => {
        var geopoint = new firebase.firestore.GeoPoint(coords[1], coords[0])
        coordinates.push(geopoint)
      })
      test.features[index].geometry.coordinates = coordinates
    })


    var customer = superAdmin ? selectedCustomer : userClaims.customerId
    var snapshot = await firestore
      .doc(`Customers/${customer}/FloorPlans/${selectedLocation}`)
      .get()

    var firestoreGeoJson = snapshot.data()

    console.log("Before concat: ", firestoreGeoJson)

    var allFeatures = firestoreGeoJson.layer.features.concat(test.features)

    var tranformation = {
      type: "FeatureCollection",
      features: allFeatures
    }

    console.log("All Features: ", allFeatures)
    console.log("GeoJson Tranform: ", tranformation)
    firestore
      .doc(`Customers/${selectedCustomer}`)
      .collection("FloorPlans")
      .doc(selectedLocation)
      .set({
        layer: tranformation
      }, { merge: true })
  }

  const [geoJson, setGeoJson] = useState()

  const test = () => {
    var thisLayer = geoJson
    thisLayer.features.forEach((feature, i) => {
      if (feature.properties.level == 1) {
        thisLayer.features[i].properties.opacity = 1
      } else {
        thisLayer.features[i].properties.opacity = 0
      }
    })
    map2.getSource('layerSource').setData(thisLayer)
  }


  const mapContainerRef2 = useRef(null)
  const [map2, setMap2] = useState()

  useEffect(() => {
    const map2 = new mapboxgl.Map({
      container: mapContainerRef2.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
    })

    map2.on('load', function () {
      setMap2(map2)
    })

    firestore
      .doc(`Customers/${selectedCustomer}`)
      .collection("FloorPlans")
      .doc(selectedLocation)
      .get()
      .then((doc) => {
        var layer = doc.data().layer
        setGeoJson(layer)
        layer.features.forEach((feature, index) => {
          var coordinates = []
          feature.geometry.coordinates.forEach((coords) => {
            coordinates.push([coords.longitude, coords.latitude])
          })
          layer.features[index].geometry.coordinates = []
          layer.features[index].geometry.coordinates.push(coordinates)
        })

        console.log("Layer: ", layer)

        map2.addSource("layerSource", {
          "type": "geojson",
          "data": layer
        })

        map2.addLayer({
          "id": "overlay",
          "source": "layerSource",
          "type": "fill",
          'layout': {
            "visibility": "visible"
          },
          'paint': {
            'fill-color': ['get', 'color'],
            "fill-outline-color": "#000000",
            'fill-opacity': ['get', 'opacity']
          }
        })
      })


    return () => map2.remove()
  }, [])

  return <div>
    Lat: <input value={inputLat} onChange={e => setInputLat(e.target.value)} />
    Lng: <input value={inputLng} onChange={e => setInputLng(e.target.value)} />
    Widht: <input value={widht} onChange={e => setWidht(e.target.value)} />
    Height: <input value={height} onChange={e => setHeight(e.target.value)} />
    <input type="file" onChange={e => setFile(e.target.files[0])} />
    <button onClick={e => setMarker()}>Submit</button>
    <button onClick={e => savePlacement()}>Save Placement</button>
    <button onClick={e => test()}>Test</button>
    <button onClick={e => draw.changeMode('simple_select')}>Simple</button>
    <button onClick={e => draw.changeMode('RotateMode')}>Rotate</button>
    <br></br>
    <div style={{ height: "500px" }}
      ref={mapContainerRef} />
    <br></br>
    <div style={{ height: "500px" }}
      ref={mapContainerRef2} />
  </div>
}
