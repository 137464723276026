import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import Chart from "react-apexcharts";
import { useUIContext } from "../../../UIContext";
import { Button } from "@material-ui/core";
import { withStyles, Badge, Popover, makeStyles } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function ZonePeopleDistributionCustom({
  showChartZones,
  setShowChartZones,
  hide,
}) {
  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
    };
  }, [UIContext]);

  const { isLoading, zonePeopleDistribution } = useSelector(
    (state) => ({
      isLoading: state.reporting.zonePeopleDistributionLoading,
      zonePeopleDistribution: state.reporting.zonePeopleDistribution,
    }),
    shallowEqual
  );

  const [graphData, setGraphData] = useState();
  const [key, setKey] = useState("all week");

  useEffect(() => {
    if (!zonePeopleDistribution) return;
    if (key === "all week" || UIProps.queryParams.timeframe === "day") {
      setGraphData(zonePeopleDistribution.allWeek);
      return;
    }
    if (key === "working days") {
      setGraphData(zonePeopleDistribution.workingDays);
      return;
    }
    if (key === "weekends") {
      setGraphData(zonePeopleDistribution.weekends);
      return;
    }
  }, [zonePeopleDistribution, UIProps.queryParams.timeframe, key]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        // title={<Title />}
        title='People Distribution'
        //subtitle="Busiest zones"
        toolbar={
          <Toolbar
            timeframe={UIProps.queryParams.timeframe}
            zonePeopleDistribution={zonePeopleDistribution}
            setKey={setKey}
            showChartZones={showChartZones}
            setShowChartZones={setShowChartZones}
            hide={hide}
          />
        }
      />
      <CardBody style={{ height: "375px" }}>
        <Chart
          options={getChartOptions(graphData)}
          series={graphData || []}
          type='treemap'
          // height='105%'
          height='350'
        />
      </CardBody>
    </Card>
  );
}

function Toolbar({
  timeframe,
  zonePeopleDistribution,
  setKey,
  showChartZones,
  setShowChartZones,
  hide,
}) {
  //Use of inside key because normal key was being received undefined
  const [insideKey, setInsideKey] = useState("all week");
  //   if (timeframe === "day") return <div />;
  //   if (!zonePeopleDistribution) return <div />;
  //   if (!zonePeopleDistribution.workingDays || !zonePeopleDistribution.weekends)
  //     return <div />;
  return (
    <>
      {timeframe !== "day" && (
        <>
          <Button
            color={insideKey === "all week" ? "primary" : "default"}
            onClick={() => {
              setKey("all week");
              setInsideKey("all week");
            }}
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "170%",
              textTransform: "none",
              color: insideKey !== "all week" && "#B8BCCA",
            }}
          >
            All Week
          </Button>
          <Button
            color={insideKey === "working days" ? "primary" : "default"}
            onClick={() => {
              setKey("working days");
              setInsideKey("working days");
            }}
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "170%",
              textTransform: "none",
              color: insideKey !== "working days" && "#B8BCCA",
            }}
          >
            Working Days
          </Button>
          <Button
            color={insideKey === "weekends" ? "primary" : "default"}
            onClick={() => {
              setKey("weekends");
              setInsideKey("weekends");
            }}
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "170%",
              textTransform: "none",
              color: insideKey !== "weekends" && "#B8BCCA",
            }}
          >
            Weekends
          </Button>
        </>
      )}

      <>
        <IconButton
          size='small'
          style={{
            top: "-2rem",
            left: "0.5rem",
            display: hide ? "none" : "block",
          }}
          onClick={() =>
            setShowChartZones({
              ...showChartZones,
              ZonePeopleDistributionCustom: false,
            })
          }
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </>
    </>
  );
}

const colors = [
  {
    from: 0,
    to: 10,
    color: "#8e8fd6",
    text: "up to 10%",
  },
  {
    from: 10.1,
    to: 20,
    color: "#696ac8",
    text: "up to 20%",
  },
  {
    from: 20.1,
    to: 35,
    color: "#4445ba",
    text: "up to 35%",
  },
  {
    from: 35.1,
    to: 50,
    color: "#363795",
    text: "up to 50%",
  },
  {
    from: 50.1,
    to: 65,
    color: "#282970",
    text: "up to 65%",
  },
  {
    from: 65.1,
    to: 80,
    color: "#1b1b4a",
    text: "up to 80%",
  },
  {
    from: 80.1,
    to: 100,
    color: "#0d0d25",
    text: "up to 100%",
  },
];

function getChartOptions() {
  return {
    chart: {
      // offsetY: -20,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
        //offsetY: -20,
        // offsetX: 10,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "PeopleDistributionByZone",
            columnDelimiter: ",",
            headerCategory: "Zone",
            headerValue: "Value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "PeopleDistributionByZone",
          },
          png: {
            filename: "PeopleDistributionByZone",
          },
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        colorScale: {
          ranges: colors,
        },
      },
    },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
      fixed: {
        enabled: true,
        position: "topRight",
        // offsetX: 150,
        // offsetY: 0,
      },
      y: {
        formatter: (val) => val + " %",
      },
    },
  };
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    padding: "0",
  },
}))(Badge);

function Title() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  return (
    <StyledBadge
    //   badgeContent={
    //     <>
    //       <span
    //         className='w-100 text-center'
    //         onMouseEnter={handlePopoverOpen}
    //         onMouseLeave={handlePopoverClose}
    //       >
    //         <BsInfoCircle style={{ fontSize: "1.3rem" }} />
    //       </span>
    //       <Popover
    //         id='mouse-over-popover'
    //         className={classes.popover}
    //         classes={{
    //           paper: classes.paper,
    //         }}
    //         open={open}
    //         anchorEl={anchorEl}
    //         anchorOrigin={{
    //           vertical: "bottom",
    //           horizontal: "left",
    //         }}
    //         transformOrigin={{
    //           vertical: "top",
    //           horizontal: "left",
    //         }}
    //         onClose={handlePopoverClose}
    //         disableRestoreFocus
    //       >
    //         <div style={{ width: "250px", wordWrap: "break-word" }}>
    //           <div className='pb-3'>
    //             <b>People Distribution: </b>
    //             <br />
    //             <p>
    //               Represents the total amount of people in the selected zones
    //               distributed by such.
    //             </p>
    //             <div
    //               className='ml-3 mb-n5'
    //               style={{ width: "150px", wordWrap: "break-word" }}
    //             >
    //               {colors.map((val) => {
    //                 return (
    //                   <div className='row pb-2 pl-2'>
    //                     <div
    //                       style={{
    //                         height: "10px",
    //                         width: "10px",
    //                         backgroundColor: val.color,
    //                       }}
    //                     ></div>
    //                     <span className='ml-2 mt-n1'>{val.text}</span>
    //                   </div>
    //                 );
    //               })}
    //             </div>
    //           </div>
    //         </div>
    //       </Popover>
    //     </>
    //   }
    >
      People Distribution
    </StyledBadge>
  );
}
