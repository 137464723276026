import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import * as d3 from "d3";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import { Group } from "@vx/group";
import { Text } from "@vx/text";
import {
  scaleOrdinal,
  schemeCategory10,
  scaleSequential,
  interpolateCool,
  scaleLinear,
} from "d3-scale";
import "./Sankey.css";
import {
  useTooltip,
  useTooltipInPortal,
  withTooltip,
  TooltipWithBounds,
} from "@vx/tooltip";
import { localPoint } from "@vx/event";
import Sankey from "./Sankey";
import { linkHorizontal } from "d3-shape";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export const SankeyChart3 = () => {
  const classes = useStyles();

  const { isLoading, zoneOccupancyForSankey } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneOccupancyFetchedForSankeyLoading,
      zoneOccupancyForSankey: state.reporting.zoneOccupancyForSankey,
    }),
    shallowEqual
  );

  // ____________ States ___________ \\
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [highlightLinkIndexes, setHighlightLinkIndexes] = useState([]);
  const [highlightDropIndexes, setHighlightDropIndexes] = useState([]);
  const [showLegend, setShowLegend] = useState("");
  const [newMargin, setNewMargin] = useState(0);

  // ____________ Functions ___________ \\
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const mockData = {
    nodes: [
      { name: "Babylon Bus" },
      { name: "Hostem" },
      { name: "Library" },
      { name: "Mode de Vue" },
      { name: "Refectory Tower" },
      { name: "Wok Store" },
      { name: "Dropout" },
      // { node: 0, name: "Babylon Bus", drop: 10 },
      // { node: 1, name: "Hostem", drop: 10 },
      // { node: 2, name: "Library", drop: 0 },
      // { node: 3, name: "Mode de Vue", drop: 0 },
      // { node: 4, name: "Refectory Tower", drop: 0 },
      // { node: 5, name: "Wok Store", drop: 0 },
      // { node: 6, name: "Dropout", drop: 0 },
    ],
    links: [
      { source: 0, target: 1, value: 2 },
      { source: 0, target: 2, value: 176 },
      { source: 0, target: 6, value: 150 }, // -
      { source: 0, target: 4, value: 161 },
      { source: 0, target: 5, value: 13 },
      { source: 1, target: 3, value: 1 },
      { source: 1, target: 6, value: 150 }, // -
      { source: 1, target: 4, value: 214 },
      { source: 1, target: 5, value: 2 },
      { source: 2, target: 3, value: 2 },
      { source: 2, target: 4, value: 40 },
      { source: 2, target: 5, value: 1 },
      { source: 3, target: 4, value: 1 },
      { source: 3, target: 5, value: 25 },
      { source: 3, target: 6, value: 150 }, // -
      { source: 4, target: 5, value: 35 },
      // { source: 4, target: 6, value: 150 }, // -
    ],
  };

  const colors1 = [
    "#c7297c",
    "#f1425f",
    "#ff713b",
    "#ffa600",
    "#323389",
    "#0058A9",
    "#0077B8",
    "#0095BA",
    "#00B0B3",
    "#52C8AB",
    "#882e8c",
  ];

  //   const color = scaleLinear().range(colors1);
  const color = d3.interpolateWarm;
  // const color = d3.interpolateCool;

  // if window decreases, put margin-left in chart.
  useEffect(() => {
    // window.onresize = function(event) {
    //   console.log("x: " + window.innerWidth + "      y: " + window.innerHeight);

    //   if (window.innerWidth > 1205) {
    //     setNewMargin(65);
    //   }
    //   if (window.innerWidth < 1205) {
    //     setNewMargin(85);
    //   }
    //   if (window.innerWidth < 768) {
    //     setNewMargin(105);
    //   }
    //   if (window.innerWidth < 600) {
    //     setNewMargin(135);
    //   }
    // };
    if (window.innerWidth > 1205) {
      setNewMargin(65);
    }
    if (window.innerWidth < 1205) {
      setNewMargin(85);
    }
    if (window.innerWidth < 768) {
      setNewMargin(105);
    }
    if (window.innerWidth < 600) {
      setNewMargin(155);
    }
  }, []);

  const width = 800;
  const height = 600;
  const margin = { top: 0, left: 0, right: 0, bottom: 0 };
  // const margin = { top: 100, left: 200, right: 0, bottom: 100 };

  // Parsed data
  const parsedData =
    zoneOccupancyForSankey &&
    JSON.parse(JSON.stringify(zoneOccupancyForSankey));

  // const parsedData = JSON.parse(JSON.stringify(mockData));

  const path = linkHorizontal()
    .source((d) => {
      // console.log(d, "d CORRECTO");
      return [d.source.x1, d.y0];
    })
    .target((d) => [d.target.x0, d.y1]);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const handleMouseOverBar = (event, datum) => {
    // console.log("🚀 ~ datum", datum);
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  };

  const getPercentageFromTotal = (total, val) => {
    const result = (val * 100) / total;
    // console.log("🚀 ~ result", result.toFixed(1));
    return Number(result.toFixed(1));
  };

  const percentageOff = (price, percentageValue) => {
    var num = parseFloat(price);
    var val = (num * percentageValue) / 100;
    return Number(val.toFixed(1));
  };

  // ____________ useEffects ___________ \\

  useEffect(() => {
    // console.log(zoneOccupancyForSankey, "zoneOccupancyForSankey");
    // console.log(parsedData, "parsedData");
    // console.log(parsedData?.links.length, "parsedData?.links.length");
    // console.log(tooltipOpen, "tooltipOpen");
    // console.log(tooltipData, "tooltipData");
    // console.log(tooltipTop, "tooltipTop");
    // console.log(tooltipLeft, "tooltipLeft");
    // console.log(showLegend, "showLegend");
  }, [
    showLegend,
    zoneOccupancyForSankey,
    parsedData,
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
  ]);

  // useEffect(() => {
  //   // `rect-${i}`

  //   if (!parsedData) return;

  //   const svg = d3.selectAll("#svgSankeyIdNotCircular");
  //   // console.log("🚀 ~ svg", svg);

  //   const filteredNodes = parsedData.nodes.filter((l) => l.name !== "Dropout");
  //   // console.log("🚀 ~ filteredNodes", filteredNodes);

  //   const node = svg.selectAll("g").data(filteredNodes);
  //   // console.log("🚀 ~ node", node);

  //   // node.attr("cursor", "move").call(d3.drag().on("drag", getDragHandler));

  //   // filteredNodes.forEach((n, i) => {
  //   //   const node = svg
  //   //     .selectAll(`#rect-${i}`)
  //   //     .attr("cursor", "move")
  //   //     .call(d3.drag().on("drag", getDragHandler));
  //   //   // console.log("🚀 ~ node", node);
  //   // });
  // }, [parsedData]);

  // const getDragHandler = (e, d) => {
  //   console.log("🚀 ~ e", e);
  //   console.log("🚀 ~ d", d);
  //   // moveNodeTo(d, e.x, e.y);
  // };

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='People flow'
        subtitle='People flow through zones'
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Floor Plans
            </Typography>
          </>
        }
      />
      <CardBody style={{ height: "600px", width: "auto" }}>
        <div
          // className='d-flex justify-content-center align-items-center'
          style={
            {
              // height: "100%",
              // width: "100%",
              // textAlign: "center",
              // display: "block",
              // margin: "auto",
            }
          }
          id='svgSankeyContainerNotCircular'
        >
          {parsedData && parsedData?.links.length !== 0 ? (
            <svg
              viewBox={`0 0 ${width} ${height} `}
              preserveAspectRatio='xMidYMid meet'
              width='100%'
              height='100%'
              className='svgSankeyNotCircular'
              id='svgSankeyIdNotCircular'
            >
              {parsedData && (
                <Sankey
                  width={width}
                  height={height}
                  top={margin.top}
                  left={margin.left}
                  right={margin.right}
                  data={parsedData}
                  size={["100%", "100%"]}
                  nodeWidth={15}
                  nodePadding={20}
                  extent={[
                    [1, 1],
                    [width - newMargin, height - 50],
                  ]}
                >
                  {({ data }) => {
                    const filteredLinks = data.links.filter(
                      (l) => l.target.name !== "Dropout"
                    );
                    const filteredNodes = data.nodes.filter(
                      (l) => l.name !== "Dropout"
                    );

                    const dropOuts = data.links.filter(
                      (l) => l.target.name === "Dropout"
                    );
                    // eslint-disable-next-line no-lone-blocks
                    {
                      /* console.log("🚀 ~ dropOuts", dropOuts); */
                    }

                    // eslint-disable-next-line no-lone-blocks
                    {
                      /* const filteredNodesWithDrops = data.nodes.filter((n) =>
                      n.sourceLinks.find((l) => l.target.name === "Dropout")
                    ); */
                    }
                    // eslint-disable-next-line no-lone-blocks
                    {
                      /* const nodeTotal = filteredNodesWithDrops.map(
                      (f) => f.value
                    ); */
                    }

                    return (
                      <Group className='groupSvg'>
                        <Group>
                          {filteredLinks.map((link, i) => {
                            return (
                              <path
                                key={`link-${i}`}
                                // id={`link-${i}`}
                                id={link.source.name + "_" + link.target.name}
                                className='pathSvg'
                                d={path(link)}
                                // d={link.path}
                                stroke={color(i / data.links.length)}
                                // stroke={
                                //   highlightLinkIndexes.includes(i) ? "red" : "black"
                                // }
                                strokeWidth={Math.max(1, link.width)}
                                // stroke-opacity={0.5}
                                opacity={
                                  highlightLinkIndexes.includes(i) ? 0.9 : 0.3
                                }
                                // fill={color(i / data.links.length)}
                                fill='none'
                                onMouseOver={(e) => {
                                  setShowLegend("link");
                                  handleMouseOverBar(e, link);
                                  setHighlightLinkIndexes([i]);
                                }}
                                onMouseOut={(e) => {
                                  hideTooltip();
                                  setHighlightLinkIndexes([]);
                                }}
                              />
                            );
                          })}
                        </Group>
                        {filteredNodes.map((node, i) => (
                          <Group
                            top={node.y0}
                            left={node.x0}
                            key={`node-${i}`}
                            className='nodes'
                          >
                            <rect
                              id={`rect-${i}`}
                              width={node.x1 - node.x0}
                              height={node.y1 - node.y0}
                              fill={color(i / data.nodes.length)}
                              opacity={0.9}
                              stroke='white'
                              strokeWidth={2}
                              onMouseOver={(e) => {
                                setShowLegend("node");
                                handleMouseOverBar(e, node);
                                document.getElementById(
                                  `rect-${i}`
                                ).style.cursor = "pointer";
                                setHighlightLinkIndexes([
                                  ...node.sourceLinks.map((l) => l.index),
                                  ...node.targetLinks.map((l) => l.index),
                                ]);
                              }}
                              onMouseOut={(e) => {
                                hideTooltip();
                                setHighlightLinkIndexes([]);
                              }}
                            />
                          </Group>
                        ))}
                        {/* Dropouts Nodes */}
                        {dropOuts.map((node, i) => {
                          const nodeOriginalHeight =
                            node.source.y1 - node.source.y0;
                          // eslint-disable-next-line no-lone-blocks
                          {
                            /* console.log(node.value, "node.value"); */
                          }

                          const valueSource = node.source.value;

                          const finalValueWithoutDropout =
                            valueSource - node.value;

                          const percentageResult = getPercentageFromTotal(
                            valueSource,
                            finalValueWithoutDropout
                          );

                          const dropOutChunk = percentageOff(
                            nodeOriginalHeight,
                            percentageResult
                          );

                          return (
                            <Group
                              top={node.source.y0}
                              left={node.source.x1}
                              key={`node-${i}`}
                            >
                              <rect
                                style={{ borderRadius: "10px" }}
                                id={`rect-drop${i}`}
                                width={15}
                                height={nodeOriginalHeight - dropOutChunk - 1} // ← Este -1 é para dar um pouco menos de height p ficar mais separado dos outros links
                                fill={
                                  highlightDropIndexes.includes(i)
                                    ? "black"
                                    : "silver"
                                }
                                //   fill={color(i / data.nodes.length)}
                                opacity={0.7}
                                stroke='white'
                                strokeWidth={2}
                                onMouseOver={(e) => {
                                  setShowLegend("drop");
                                  handleMouseOverBar(e, node);

                                  document.getElementById(
                                    `rect-drop${i}`
                                  ).style.cursor = "pointer";
                                  setHighlightDropIndexes([i]);
                                }}
                                onMouseOut={(e) => {
                                  hideTooltip();
                                  setHighlightDropIndexes([]);
                                }}
                              />
                            </Group>
                          );
                        })}
                        {filteredNodes.map((node, i) => {
                          const foundDropout = node.sourceLinks.find(
                            (s) => s.target.name === "Dropout"
                          );
                          return (
                            <>
                              <Group
                                top={node.y0}
                                left={node.x0}
                                key={`node-${i}`}
                              >
                                <Text
                                  x={foundDropout ? 32 : 18} // ← if node has a dropout text is moved more to the right
                                  y={(node.y1 - node.y0) / 2}
                                  verticalAnchor='middle'
                                  fill='#262a2b'
                                  // style={{
                                  //   fontWeight: "500",
                                  //   fontSize: "12px",
                                  //   fontFamily: "Poppins",
                                  // }}
                                  className='textSvg'
                                >
                                  {node.name}
                                </Text>
                              </Group>
                            </>
                          );
                        })}
                      </Group>
                    );
                  }}
                </Sankey>
              )}
            </svg>
          ) : (
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#3B4143",
              }}
            >
              No data to display.
            </p>
          )}

          {tooltipOpen && showLegend === "node" && (
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              <div
                className='row'
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                  paddingRight: "0.5rem",
                  paddingLeft: "0.5em",
                }}
              >
                <div className='col'>
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    {tooltipData && tooltipData?.name}
                  </h6>
                  {zoneOccupancyForSankey &&
                    zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                      (l) => {
                        const nameWithLocation =
                          l.zone === tooltipData.name ? l.floor : null;
                        return (
                          <h6
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              color: "#3B4143",
                            }}
                          >
                            {nameWithLocation}
                          </h6>
                        );
                      }
                    )}
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    People count:{" "}
                    <span>{tooltipData && tooltipData?.value}</span>
                  </p>
                </div>
              </div>
            </TooltipWithBounds>
          )}

          {tooltipOpen && showLegend === "link" && (
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              <div
                className='row'
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                  paddingRight: "0.5rem",
                  paddingLeft: "0.5em",
                }}
              >
                <div className='col'>
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    {tooltipData &&
                      tooltipData?.source.name +
                        " → " +
                        tooltipData?.target.name}
                  </h6>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#3B4143",
                    }}
                  >
                    People count:{" "}
                    <span>{tooltipData && tooltipData?.value}</span>
                  </p>
                </div>
              </div>
            </TooltipWithBounds>
          )}
        </div>

        {tooltipOpen && showLegend === "drop" && (
          <TooltipWithBounds
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <div
              className='row'
              style={{
                textAlign: "center",
                paddingTop: "1rem",
                paddingRight: "0.5rem",
                paddingLeft: "0.5em",
              }}
            >
              <div className='col'>
                <h6
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#3B4143",
                  }}
                >
                  People {tooltipData && tooltipData?.target.name} ⇩
                </h6>

                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    color: "#3B4143",
                  }}
                >
                  People dropout count:{" "}
                  <span>{tooltipData && tooltipData?.value}</span>
                </p>
              </div>
            </div>
          </TooltipWithBounds>
        )}

        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (z, i) => <p key={i}>{z.zone}</p>
                )}
            </div>
            <div style={{ marginRight: "0.5rem", fontWeight: "500" }}>
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (f, i) => <p key={i}>- &nbsp;&nbsp;{f.floor}</p>
                )}
            </div>
            <div>
              {zoneOccupancyForSankey &&
                zoneOccupancyForSankey?.zonesWithFloorsAndLocations.map(
                  (l, i) => <p key={i}>- &nbsp;&nbsp;{l.location}</p>
                )}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
};
