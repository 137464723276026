import React, { createContext, useContext, useState } from "react"

const UIContext = createContext()

export function useUIContext() {
  return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ UIEvents, children }) {
  const [selectedFloor, setSelectedFloor] = useState("")
  const [changesMade, setChangesMade] = useState(false)

  const value = {
    selectedFloor,
    setSelectedFloor,
    changesMade,
    setChangesMade,
    openResourceCalendar: UIEvents.openResourceCalendar,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}