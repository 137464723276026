import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../UIContext";
import { firestore } from "../../../../firebase";
import * as actions from "../../_redux/liveData/liveDataActions";
import { liveDataSlice } from "../../_redux/liveData/liveDataSlice";
import { GeralInfoRow } from "../../../_partials/widgets/GeralInfoRow";
import { LiveMapWidget } from "./LiveMap/LiveMapWidget";
import { ZonesOccupancy } from "./ZonesOccupancy";
import { FloorOccupancy } from "./FloorOccupancy";
import moment from "moment";
import PersonIcon from "@material-ui/icons/Person";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import "../../../_assets/sass/pages/dashboard/_dashboard-geral-info-row.scss";

export function LiveDataSection() {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      liveData: UIContext.liveData,
      setLiveData: UIContext.setLiveData,
      resetSnapshot: UIContext.resetSnapshot,
      setResetSnapshot: UIContext.setResetSnapshot,
    };
  }, [UIContext]);

  //★━━━━━━━━━━━━━★ Selectors ★━━━━━━━━━━━━━★\\

  const {
    selectedCustomer,
    selectedLocation,
    selectedLevel,
    level,
    customerType,
    floorPlans,
    user,
    peopleStats,
    employees,
    guests,
    visitors,
    resources,
    bookings,
    allLocationStatsSummed,
  } = useSelector(
    (state) => ({
      selectedCustomer: state.profile?.currentCustomer,
      selectedLocation: state.profile?.currentLocation,
      selectedLevel: state.profile?.currentLevel,
      level: state.profile?.currentLevel?.level,
      customerType: state.profile?.currentLocation?.customerType,
      floorPlans: state.basePage?.floorPlans,
      user: state.auth.user,
      peopleStats: state.liveData.peopleStats,
      employees: state.liveData.peopleStats?.current_employees || 0,
      guests: state.liveData.peopleStats?.current_guests || 0,
      visitors: state.liveData.peopleStats?.current_visitors || 0,
      resources: state.basePage?.resources,
      bookings: state.basePage?.bookings,
      allLocationStatsSummed:
        state.liveData.allLocationsPeopleStats?.allLocationStatsSummed,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (!selectedCustomer) { return }
  //   UIProps.setLiveData(true)
  //   const unsubscribe = firestore
  //     .doc(`Customers/${selectedCustomer.id}/${selectedLocation.netId}/${selectedLevel.floorId}`)
  //     .onSnapshot((snapshot) => {
  //       const updatedDevices = snapshot.data()
  //       dispatch(liveDataSlice.actions.devicesFetched(updatedDevices))
  //     }, (error) => console.error(error))

  //   const unsubscribe2 = firestore
  //     .doc(`Customers/${selectedCustomer.id}/${selectedLocation.netId}/${selectedLevel.floorId}_ble`)
  //     .onSnapshot((snapshot) => {
  //       const updatedBLEDevices = snapshot.data()
  //       dispatch(liveDataSlice.actions.bleDevicesFetched(updatedBLEDevices)
  //       )
  //     }, (error) => console.error(error))

  //   const unsubscribe3 = firestore
  //     .doc(`Customers/${selectedCustomer.id}/${selectedLocation.netId}/peopleStats`)
  //     .onSnapshot((snapshot) => {
  //       dispatch(liveDataSlice.actions.peopleStatsFetched(snapshot.data()))
  //     }, (error) => console.error(error))

  //   const unsubscribe4 = firestore
  //     .collection(`Customers/${selectedCustomer.id}/Sensors`)
  //     .where("networkId", "==", selectedLocation.netId)
  //     .onSnapshot((snapshot) => {
  //       const sensors = snapshot.docs.map((docSnapshot) => {
  //         return { ...docSnapshot.data(), id: docSnapshot.id }
  //       })
  //       dispatch(liveDataSlice.actions.sensorsFetched(sensors))
  //     }, (error) => console.error(error))

  //   const timer = setTimeout(() => {
  //     UIProps.setLiveData(false)
  //     unsubscribe()
  //     unsubscribe2()
  //     unsubscribe3()
  //     unsubscribe4()
  //   }, 300000)
  //   return () => {
  //     unsubscribe()
  //     unsubscribe2()
  //     unsubscribe3()
  //     unsubscribe4()
  //     clearTimeout(timer)
  //   }
  // }, [selectedCustomer, selectedLocation, selectedLevel, UIProps.resetSnapshot])~

  //★━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━★\\
  const [desks, setDesks] = useState({
    total: 0,
    withBookings: 0,
  });
  const [meetings, setMeetings] = useState({
    total: 0,
    withBookings: 0,
  });
  const [
    differentLocationFloorPlans,
    setDifferentLocationFloorPlans,
  ] = useState();

  //★━━━━━━━━━━━━━★ Functions ★━━━━━━━━━━━━━★\\

  async function getFloorPlans() {
    const floors = [];
    await firestore
      .collection(`Customers`)
      .doc(`${selectedCustomer.id}`)
      .collection("FloorPlans")
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          floors.push(doc.data());
        });
      });
    return floors;
  }

  //★━━━━━━━━━━━━━★ useEffects ★━━━━━━━━━━━━━★\\

  useEffect(() => {
    // console.log(differentLocationFloorPlans, "differentLocationFloorPlans");
    // console.log(allLocationStatsSummed, "allLocationStatsSummed");
  }, [differentLocationFloorPlans, allLocationStatsSummed]);

  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }
    // Fetch all locations people stats and meraki wifi devices (AP's)
    // if (!selectedLocation) {
    // Get one floorPlan per location
    getFloorPlans().then((floorPlans) => {
      var uniq = {};
      var arrFiltered = floorPlans.filter(
        (obj) => !uniq[obj.netId] && (uniq[obj.netId] = true)
      );
      const floorPlansThatHaveNetId = arrFiltered.filter((obj) => obj.netId);
      setDifferentLocationFloorPlans(floorPlansThatHaveNetId);
    });

    // Fetch ppl stats of all locations
    dispatch(
      actions.fetchAllLocationsPeopleStats({
        customerId: selectedCustomer,
      })
    );
    // Fetch Meraki Devices of all locations
    dispatch(
      actions.fetchAllLocationsMerakiDevices({
        customerId: selectedCustomer,
      })
    );
    // return;
    // }

    if (selectedLocation) {
      UIProps.setLiveData(true);
      const unsubscribe = firestore
        .collection(
          `Customers/${selectedCustomer.id}/${selectedLocation.netId}`
        )
        .onSnapshot(
          (snapshot) => {
            var data = snapshot.docs.reduce((obj, doc) => {
              return Object.assign(obj, { [doc.id]: doc.data() });
            }, {});
            dispatch(
              liveDataSlice.actions.peopleStatsFetched(data.peopleStats)
            );
            delete data.peopleStats;
            delete data.weather;
            dispatch(liveDataSlice.actions.devicesFetched(data));
          },
          (error) => console.error(error)
        );

      const unsubscribe2 = firestore
        .collection(`Customers/${selectedCustomer.id}/Sensors`)
        .where("networkId", "==", selectedLocation.netId)
        .onSnapshot(
          (snapshot) => {
            const sensors = snapshot.docs.map((docSnapshot) => {
              return { ...docSnapshot.data(), id: docSnapshot.id };
            });
            dispatch(liveDataSlice.actions.sensorsFetched(sensors));
          },
          (error) => console.error(error)
        );

      const unsubscribe3 = firestore
        .collection(`Customers/${selectedCustomer.id}/Things`)
        .onSnapshot(
          (snapshot) => {
            const things = snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            });
            dispatch(liveDataSlice.actions.thingsFetched(things));
          },
          (error) => console.error(error)
        );

      const timer = setTimeout(() => {
        UIProps.setLiveData(false);
        unsubscribe();
        unsubscribe2();
        unsubscribe3();
      }, 300000);
      return () => {
        unsubscribe();
        unsubscribe2();
        unsubscribe3();
        clearTimeout(timer);
      };
    }
  }, [
    selectedCustomer,
    selectedLocation,
    selectedLevel,
    UIProps.resetSnapshot,
  ]);

  useEffect(() => {
    if (!selectedCustomer || !selectedLocation) {
      return;
    }

    dispatch(
      actions.fetchUsers({ customerId: selectedCustomer.id, userId: user.id })
    );
  }, [selectedCustomer, user, selectedLocation]);

  // useEffect(() => {
  //   if (!selectedCustomer) return

  //   dispatch(actions.fetchThings({ customerId: selectedCustomer.id }))
  // }, [selectedCustomer])

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }
    dispatch(actions.fetchIconsFromDB());
  }, [selectedLocation]);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }
    dispatch(actions.fetchDailyUsers({ selectedCustomer, selectedLocation }));
    dispatch(
      actions.fetchPois({
        selectedCustomer: selectedCustomer.id,
        selectedLocation: selectedLocation.netId,
      })
    );

    const floorPlanSelected =
      floorPlans &&
      floorPlans.find(
        (f) => f.level === level && f.netId === selectedLocation.netId
      );
    if (floorPlans) {
      dispatch(
        actions.fetchResourcesMeetings({
          selectedCustomer: selectedCustomer.id,
          selectedLocation: selectedLocation.netId,
          floorPlanSelected,
        })
      );
    }
  }, [selectedCustomer, selectedLocation, floorPlans, level]);

  useEffect(() => {
    if (!bookings || !resources) {
      return;
    }
    const now = moment();
    const inAWhile = moment().add(30, "minutes");
    const _desks = resources.filter((val) => val.type === "desk");
    const _desksWithBookings = _desks.filter((val) => {
      const resourceBookings = bookings.filter(
        (book) => book.resource === val.id
      );
      return resourceBookings.find((val) => {
        const start = moment(val.start.toDate());
        const end = moment(val.end.toDate());
        const check1 =
          start.isBetween(now, inAWhile) || end.isBetween(now, inAWhile);
        const check2 =
          now.isBetween(start, end) || inAWhile.isBetween(start, end);
        return check1 || check2;
      });
    });
    const _meetings = resources.filter((val) => val.type === "meeting");
    const _meetingsWithBookings = _meetings.filter((val) => {
      const resourceBookings = bookings.filter(
        (book) => book.resource === val.id
      );
      return resourceBookings.find((val) => {
        const start = moment(val.start.toDate());
        const end = moment(val.end.toDate());
        const check1 =
          start.isBetween(now, inAWhile) || end.isBetween(now, inAWhile);
        const check2 =
          now.isBetween(start, end) || inAWhile.isBetween(start, end);
        return check1 || check2;
      });
    });

    setDesks({
      total: _desks.length || 0,
      withBookings: _desksWithBookings.length || 0,
    });
    setMeetings({
      total: _meetings.length || 0,
      withBookings: _meetingsWithBookings.length || 0,
    });
  }, [resources, bookings]);

  const officeData = [
    {
      icon: <PersonIcon style={{ fill: "#8C8CA2" }} />,
      value: employees,
      text: "Current employees at the office",
    },
    {
      icon: <PeopleAltOutlinedIcon style={{ fill: "#8C8CA2" }} />,
      value: visitors,
      text: "Visitors at the office",
    },
    {
      icon: <EventSeatIcon style={{ fill: "#8C8CA2" }} />,
      value: `${desks.withBookings} / ${desks.total}`,
      text: "Desks booked",
      className: "dashboard-geral-info-extra-2",
    },
    {
      icon: <CalendarTodayIcon style={{ fill: "#8C8CA2" }} />,
      value: `${meetings.withBookings} / ${meetings.total}`,
      text: "Meeting rooms booked",
      className: "dashboard-geral-info-extra",
    },
  ];

  const shopData = [
    {
      icon: <PersonIcon style={{ fill: "#8C8CA2" }} />,
      value: visitors,
      text: "Current visitors",
    },
    {
      icon: <PeopleAltOutlinedIcon style={{ fill: "#8C8CA2" }} />,
      value:
        peopleStats?.floor_visitors && selectedLevel?.floorId
          ? peopleStats?.floor_visitors[selectedLevel.floorId]
          : 0,
      text: "Visitors in current floor",
    },
    {
      icon: <EventSeatIcon style={{ fill: "#8C8CA2" }} />,
      value: guests,
      text: "Guests",
      className: "dashboard-geral-info-extra-2",
    },
    {
      icon: <CalendarTodayIcon style={{ fill: "#8C8CA2" }} />,
      value: Math.round(peopleStats?.visitorsMonth || 0 / new Date().getDate()),
      text: "Daily average visitors",
      className: "dashboard-geral-info-extra",
    },
  ];

  // All Locations data
  const globalDataAllLocations = allLocationStatsSummed && [
    {
      icon: <PersonIcon style={{ fill: "#8C8CA2" }} />,
      value: allLocationStatsSummed.current_employees,
      text: "Current employees at the office",
    },
    {
      icon: <PeopleAltOutlinedIcon style={{ fill: "#8C8CA2" }} />,
      value: allLocationStatsSummed.current_visitors,
      text: "Visitors at the office",
    },
    // {
    //   icon: <PersonIcon style={{ fill: "#8C8CA2" }} />,
    //   value: allLocationStatsSummed.employeesDay,
    //   text: "Employees per day",
    //   // className: "dashboard-geral-info-extra-2",
    // },
    // {
    //   icon: <PeopleAltOutlinedIcon style={{ fill: "#8C8CA2" }} />,
    //   value: allLocationStatsSummed.visitorsDay,
    //   text: "Visitors per day",
    // },
    {
      icon: <CalendarTodayIcon style={{ fill: "#8C8CA2" }} />,
      value: Math.round(
        allLocationStatsSummed?.employeesMonth / new Date().getDate()
      ),
      text: "Max employees this month",
      className: "dashboard-geral-info-extra",
    },
    {
      icon: <CalendarTodayIcon style={{ fill: "#8C8CA2" }} />,
      value: Math.round(
        allLocationStatsSummed?.visitorsMonth / new Date().getDate()
      ),
      text: "Max visitors this month",
      className: "dashboard-geral-info-extra",
    },
  ];

  return (
    <>
      <div className='col-12' style={{ marginBottom: "35px" }}>
        {!selectedLocation && <GeralInfoRow data={globalDataAllLocations} />}
        {customerType === "office" && selectedLocation && (
          <GeralInfoRow data={officeData} />
        )}
        {customerType === "shop" && selectedLocation && (
          <GeralInfoRow data={shopData} />
        )}
      </div>
      <div className='col-12'>
        <LiveMapWidget
          differentLocationFloorPlans={differentLocationFloorPlans}
        />
      </div>
      {selectedLocation && (
        <div className='col-lg-6 col-xxl-5'>
          <FloorOccupancy />
        </div>
      )}
      {selectedLocation && (
        <div className='col-lg-6 col-xxl-7'>
          <ZonesOccupancy />
        </div>
      )}
    </>
  );
}

/* {customerType === "office" ? <>
      <div className='col-lg-6 col-xxl-7'>
        <OfficePeopleStatsWidget />
      </div>
      <div className='col-lg-6 col-xxl-5'>
        <OfficeBookingStatsWidget />
      </div>
    </> :
      customerType === "shop" && <>
        <div className='col-lg-6 col-xxl-7'>
          <RetailPeopleStatsWidget />
        </div>
        <div className='col-lg-6 col-xxl-5'>
          <RetailFloorOccupancyWidget />
        </div>
      </>
    } */
