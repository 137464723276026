import { callTypes, reportingSlice } from "../reportingSlice";
import * as ActionHelpers from "./ActionHelpers";
import * as requestFromServer from "./wifiCrud";
import { convertCollectionSnapshotToMap2 } from "../../_helpers/ActionHelpers";

const { actions } = reportingSlice;

//★━━━━━━━━━━━━━━━━★ Fetch Wifi by Floor ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchWifiByFloor = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return Promise.all([
    fetchLocationAccuracy(queryParams, dispatch),
    fetchLocationAccuracyPerDay(queryParams, dispatch),
    fetchFloorRSSI(queryParams, dispatch),
    fetchFloorRSSIPerDay(queryParams, dispatch),
    // fetchFloorRSSIPerDayBadGlobal(queryParams, dispatch),
  ]).catch((error) => {
    console.log("Fetch Wifi By Floor: ", error);
    error.clientMessage = "Can't fetch Wifi By Floor";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

function fetchLocationAccuracy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorLocationAccuracy }));
  if (!queryParams) dispatch(actions.floorLocationAccuracyFetched(null));
  return requestFromServer
    .getLocationAccuracy(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorLocationAccuracyFetched(null));

      const data = ActionHelpers.handleLocationAccuracyResult({
        result,
        queryParams,
      });
      dispatch(actions.floorLocationAccuracyFetched(data));
    })
    .catch((error) => {
      console.log("Fetch location accuracy", error);
      error.clientMessage = "Can't fetch location accuracy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchLocationAccuracyPerDay(queryParams, dispatch) {
  dispatch(
    actions.startCall({ callType: callTypes.floorLocationAccuracyPerDay })
  );
  if (!queryParams) dispatch(actions.floorLocationAccuracyPerDayFetched(null));
  return requestFromServer
    .getLocationAccuracyPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorLocationAccuracyPerDayFetched(null));

      const data = ActionHelpers.handleLocationAccuracyPerDayResult({
        result,
        queryParams,
      });
      dispatch(actions.floorLocationAccuracyPerDayFetched(data));
    })
    .catch((error) => {
      console.log("Fetch location accuracy per day", error);
      error.clientMessage = "Can't fetch location accuracy per day";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchFloorRSSI(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorRSSI }));
  if (!queryParams) dispatch(actions.floorRSSIFetched(null));
  return requestFromServer
    .getFloorRSSI(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorRSSIFetched(null));

      const data = ActionHelpers.handleFloorRSSIResult({ result, queryParams });
      dispatch(actions.floorRSSIFetched(data));
    })
    .catch((error) => {
      console.log("Fetch rssi", error);
      error.clientMessage = "Can't fetch rssi";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchFloorRSSIPerDay(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorRSSIPerDay }));
  if (!queryParams) dispatch(actions.floorRSSIPerDayFetched(null));
  return requestFromServer
    .getFloorRSSIPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorRSSIPerDayFetched(null));

      const data = ActionHelpers.handleFloorRSSIPerDayResult({
        result,
        queryParams,
      });
      dispatch(actions.floorRSSIPerDayFetched(data));
    })
    .catch((error) => {
      console.log("Fetch rssi per day", error);
      error.clientMessage = "Can't fetch rssi per day";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

// function fetchFloorRSSIPerDayBadGlobal(queryParams, dispatch) {
//   dispatch(actions.startCall({ callType: callTypes.floorRSSIPerDayBadGlobal }));
//   if (!queryParams) dispatch(actions.floorRSSIPerDayBadGlobalFetched(null));
//   return requestFromServer
//     .getFloorRSSIPerDay(queryParams)
//     .then((response) => response && response.json())
//     .then((result) => {
//       if (!result) dispatch(actions.floorRSSIPerDayBadGlobalFetched(null));

//       const data = ActionHelpers.handleFloorRSSIPerDayBadGlobalResult({
//         result,
//         queryParams,
//       });
//       dispatch(actions.floorRSSIPerDayBadGlobalFetched(data));
//     })
//     .catch((error) => {
//       console.log("Fetch rssi bad global per day ", error);
//       error.clientMessage = "Can't fetch rssi bad global per day";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// }

//★━━━━━━━━━━━━━━━━★ Fetch Wifi by Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchWifiByZone = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return Promise.all([
    fetchZoneLocationAccuracy(queryParams, dispatch),
    fetchZoneLocationAccuracyPerDay(queryParams, dispatch),
    fetchZoneRSSI(queryParams, dispatch),
    fetchZoneRSSIPerDayPerDay(queryParams, dispatch),
  ]).catch((error) => {
    console.log("Fetch People By Zone: ", error);
    error.clientMessage = "Can't fetch People By Zone";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

function fetchZoneLocationAccuracy(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zoneLocationAccuracy }));
  if (!queryParams) dispatch(actions.zoneLocationAccuracyFetched(null));
  return requestFromServer
    .getZoneLocationAccuracy(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneLocationAccuracyFetched(null));
      const data = ActionHelpers.handleZoneLocationAccuracyResult({
        result,
        queryParams,
      });
      dispatch(actions.zoneLocationAccuracyFetched(data));
    })
    .catch((error) => {
      console.log("Fetch zone location accuracy", error);
      error.clientMessage = "Can't fetch zone location accuracy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchZoneLocationAccuracyPerDay(queryParams, dispatch) {
  dispatch(
    actions.startCall({ callType: callTypes.zoneLocationAccuracyPerDay })
  );
  if (!queryParams) dispatch(actions.zoneLocationAccuracyPerDayFetched(null));
  return requestFromServer
    .getZoneLocationAccuracyPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zoneLocationAccuracyPerDayFetched(null));
      const data = ActionHelpers.handleZoneLocationAccuracyPerDayResult({
        result,
        queryParams,
      });
      dispatch(actions.zoneLocationAccuracyPerDayFetched(data));
    })
    .catch((error) => {
      console.log("Fetch zone location accuracy per day", error);
      error.clientMessage = "Can't fetch zone location accuracy per day";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchZoneRSSI(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zonesRSSI }));
  if (!queryParams) dispatch(actions.zonesRSSIFetched(null));
  return requestFromServer
    .getZoneRSSI(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zonesRSSIFetched(null));
      const data = ActionHelpers.handleZoneRSSIResult({ result, queryParams });
      dispatch(actions.zonesRSSIFetched(data));
    })
    .catch((error) => {
      console.log("Fetch zone rssi", error);
      error.clientMessage = "Can't fetch zone rssi";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

function fetchZoneRSSIPerDayPerDay(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.zonesRSSIPerDay }));
  if (!queryParams) dispatch(actions.zonesRSSIPerDayFetched(null));
  return requestFromServer
    .getZoneRSSIPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.zonesRSSIPerDayFetched(null));
      const data = ActionHelpers.handleZoneRSSIPerDayResult({
        result,
        queryParams,
      });
      dispatch(actions.zonesRSSIPerDayFetched(data));
    })
    .catch((error) => {
      console.log("Fetch zone rssi", error);
      error.clientMessage = "Can't fetch zone rssi";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

//★━━━━━━━━━━━━━━━━★ Fetch AP's all locations ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchAllLocationsMerakiDevices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.allLocationsMerakiDevicesFetched({ entities: [] }));
  }

  return requestFromServer
    .getAllLocationsMerakiDevices(queryParams)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const allLocationMerakiDevices = convertCollectionSnapshotToMap2(
        response
      );

      dispatch(
        actions.allLocationsMerakiDevicesFetched(allLocationMerakiDevices)
      );
    })
    .catch((error) => {
      console.log("Fetch all locations meraki devices: ", error);
      error.clientMessage = "Can't fetch all locations meraki devices";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
