import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../UIContext";
import { firestore } from "../../../../firebase";
import { Card, CardBody } from "../../../_partials/Card";
import { Input } from "../../../_partials/inputs/Input";
import * as actions from "../../_redux/reporting/reportingActions";
import PDFPreviewModal from "../wifi/PDFModalWifi/PDFPreviewModalWifi";
import {
  Checkbox,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment";
import { DatePicker } from "../../../_partials/inputs/DatePicker";
import { Select } from "../../../_partials/inputs/Select";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import _ from "lodash";
import PDFPreviewModalWifi from "../wifi/PDFModalWifi/PDFPreviewModalWifi";
import PDFPreviewModalPeople from "../wifi/PDFModalWifi/PDFPreviewModalPeople";
import PDFPreviewModalWifiAdvanced from "../wifi/PDFModalWifi//PDFPreviewModalWifiAdvanced";
import "../wifi/PDFModalWifi/PDF.css";

export function DataFilter(props) {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  //★━━━━━━━━━━━━━━━★ Selectors ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  const { customer, location, page, group, user } = useSelector(
    (state) => ({
      customer: state.profile.currentCustomer,
      location: state.profile?.currentLocation,
      page: state.persistentReporting.page,
      group: state.persistentReporting.group,
      user: state.auth.user,
    }),
    shallowEqual
  );
  const reduxZones = useSelector((state) => state.basePage?.zones);

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  const [date, setDate] = useState(UIProps.queryParams.date);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSSIDs, setSelectedSSIDs] = useState([]);
  const [displaySSID, setDisplaySSID] = useState([]);

  const [selectableFloors, setSelectableFloors] = useState([]);
  const [selectedFloors, setSelectedFloors] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectableZones, setSelectableZones] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);

  const [selectedPeopleType, setSelectedPeopleType] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState(
    UIProps.queryParams.timeframe
  );
  const [departments, setDepartments] = useState([]);
  const [dropDownFieldsFromDB, setDropDownFieldsFromDB] = useState(null);

  const [networks, setNetworks] = useState([]);
  const [didFirstSetOfParams, setDidFirstSetOfParams] = useState(false);
  const [filterFields, setFilterFields] = useState([
    { value: "employees", name: "Employees" },
    { value: "guests", name: "Guests" },
    { value: "visitors", name: "Visitors" },
    { value: "allConnected", name: "All Connected Devices" },
  ]);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [pdfFormData, setPdfFormData] = useState({
    to: user ? user.email : "",
    startDate: new Date(),
    startTime: "8:00:00",
    repeat: "weekly",
  });
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false);
  const [showPDFPreviewModalPeople, setShowPDFPreviewModalPeople] = useState(
    false
  );
  const [showPDFPreviewModalWifiAdv, setShowPDFPreviewModalWifiAdv] = useState(
    false
  );
  const [zonesFilteredByLocation, setZonesFilteredByLocation] = useState();
  const [hide, setHide] = useState(false);
  const [sensorsFromDB, setSensorsFromDB] = useState([]);

  //★━━━━━━━━━━━━━━━★ Functions ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  //★━━━━━━━━━━━━━━━★ useEffects ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!customer.merakiNetworks) return;
    const _networks = customer.merakiNetworks.filter((val) => !val.disabled);
    if (_networks.length === 0) return;
    setNetworks(_networks);
    const _location = _networks.find((val) => val?.netId === location?.netId);
    if (_location) {
      setSelectedLocations(_location ? [_location] : []);
      setSelectedSSIDs(location?.SSIDs);
    } else {
      setSelectedLocations(_networks);
      setSelectedPeopleType([
        filterFields[0],
        filterFields[1],
        filterFields[2],
      ]);
      const newFloors = _networks.map((loc) => loc.levels).flat();
      handleChangeQueryParams({ locations: _networks, floors: newFloors });
    }
    if (_location?.customerType === "office") {
      setSelectedPeopleType([filterFields[0]]);
    }
    if (_location?.customerType === "shop") {
      setSelectedPeopleType([filterFields[1], filterFields[2]]);
    }
  }, [customer]);

  useEffect(() => {
    var allSSID = [];
    selectedLocations.forEach((loc) => {
      allSSID.push(loc.SSIDs);
    });
    allSSID = _.flatten(allSSID);
    setDisplaySSID(allSSID);
    setSelectedSSIDs(allSSID);

    const newFloors = selectedLocations.map((loc) => loc.levels).flat();
    if (shallowEqual(selectableFloors, newFloors)) return;
    setSelectableFloors(newFloors);
    if (newFloors.length > 5) {
      setSelectedFloors(newFloors.slice(0, 6));
    } else {
      setSelectedFloors(newFloors);
    }
  }, [selectedLocations]);

  /* useEffect(() => {
    if (!floorPlans) return
    if (floorPlans.length > 5) {
      setSelectedFloors(floorPlans.slice(0, 6))
    } else {
      setSelectedFloors(floorPlans)
    }
  }, [floorPlans]) */

  /* useEffect(() => {
    if (!zones) return
    if (zones.length > 5) {
      setSelectedZones(zones.slice(0, 6))
    } else {
      setSelectedZones(zones)
    }
  }, [zones]) */

  // Get dropdown departments from db
  useEffect(() => {
    let mounted = true;

    const departmentsDropdown = [];
    const departmentsFields = firestore
      .collection("Customers")
      .doc(`${customer.customerId}`)
      .collection("Departments");

    departmentsFields
      .get()
      .then((snapshot) => {
        // console.log(snapshot.docs)
        snapshot.docs.forEach((doc) => {
          // console.log(doc.data(), "doc")
          departmentsDropdown.push({ id: doc.id, ...doc.data() });
        });
        if (mounted) {
          // console.log(departmentsDropdown, "departmentsDropdown")
          setDropDownFieldsFromDB(departmentsDropdown);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return () => (mounted = false);
  }, [customer.customerId]);

  // Get dropdown departments from db
  useEffect(() => {
    let mounted = true;

    const sensorsDropdown = [];
    const sensorsFields = firestore
      .collection("Customers")
      .doc(`${customer.customerId}`)
      .collection("Sensors");

    sensorsFields
      .get()
      .then((snapshot) => {
        // console.log(snapshot.docs);
        snapshot &&
          snapshot.docs &&
          snapshot.docs.forEach((doc) => {
            // console.log(doc.data(), "doc");
            sensorsDropdown.push({ id: doc.id, ...doc.data() });
          });
        if (mounted) {
          // console.log(sensorsDropdown, "sensorsDropdown");
          setSensorsFromDB(sensorsDropdown);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return () => (mounted = false);
  }, [customer]);
  /* 
    useEffect(() => {
      dropDownFieldsFromDB &&
        UIProps.setQueryParams({
          ...UIProps.queryParams,
          departments: dropDownFieldsFromDB,
        })
    }, [dropDownFieldsFromDB]) */

  // Filtered Zones from Locations dropDown to wifiSnapshot
  useEffect(() => {
    const filteredZones = [];

    selectedLocations.map((l) => {
      zones.forEach((z) => {
        if (z.netId === l.netId) {
          filteredZones.push(z);
        }
      });
      return null;
    });
    setZonesFilteredByLocation(filteredZones);
  }, [selectedLocations, zones]);

  function handleChangeQueryParams(props) {
    const { locations, floors, zones, timeframe, page } = props || {};
    if (page === "wifiSnapshot") {
      const newQueryParams2 = {
        date: date,
        locations: locations || selectedLocations,
        levels: floors || selectableFloors,
        floors: floors || selectedFloors,
        zones: zonesFilteredByLocation,
        SSIDs: selectedSSIDs,
        peopleType: selectedPeopleType,
        timeframe: timeframe || selectedTimeframe,
      };
      if (shallowEqual(UIProps.queryParams, newQueryParams2)) return;
      UIProps.setQueryParams(newQueryParams2);
    } else {
      const newQueryParams = {
        date: date,
        locations: locations || selectedLocations,
        levels: floors || selectableFloors,
        floors: floors || selectedFloors,
        zones: zones || selectedZones,
        SSIDs: selectedSSIDs,
        peopleType: selectedPeopleType,
        timeframe: timeframe || selectedTimeframe,
      };
      if (shallowEqual(UIProps.queryParams, newQueryParams)) return;
      UIProps.setQueryParams(newQueryParams);
    }
  }

  //When timeframe button is clicked start handleChangeQueryParams
  useEffect(() => {
    if (!didFirstSetOfParams) return;
    handleChangeQueryParams({ timeframe: selectedTimeframe, page: page });
  }, [selectedTimeframe, page]);

  useEffect(() => {
    // console.log(zones, "zones");
    // console.log(selectedLocations, "selectedLocations");
    // console.log(selectedFloors, "selectedFloors");
    // console.log(location, "location");
    // console.log(location?.customerType, "location?.customerType");
    // console.log(zonesFilteredByLocation, "zonesFilteredByLocation");
    // console.log(customer, "customer");
    // console.log(customer?.services?.advWiFi, "customer?.services?.advWifi");
    // console.log(sensorsFromDB, "sensorsFromDB");
    // console.log(sensorsFromDB.length === 0, "sensors === 0");
    // console.log(networks, "networks");
  }, [
    selectedFloors,
    networks,
    zones,
    selectedZones,
    selectedLocations,
    zonesFilteredByLocation,
    customer,
    location,
    sensorsFromDB,
  ]);

  //Filter zones by the selected floors
  useEffect(() => {
    if (!zones || !selectedFloors) return;
    const newSelectableZones = zones.filter((val) => {
      return selectedFloors.some((floor) => floor.floorId === val.floorPlanId);
    });
    if (shallowEqual(selectableZones, newSelectableZones)) return;
    setSelectableZones(newSelectableZones);

    const newSelectedZones = selectedZones.filter((val) => {
      return selectedFloors.some((floor) => floor.floorId === val.floorPlanId);
    });
    if (selectedZones.length === 0) {
      setSelectedZones(newSelectableZones.slice(0, 6));
      handleChangeQueryParams({ zones: newSelectableZones.slice(0, 6) });
      return;
    }
    //Commented so workaround works
    //if (shallowEqual(selectedZones, newSelectedZones)) return
    if (newSelectedZones.length === 0) {
      setSelectedZones(newSelectableZones.slice(0, 6));
      handleChangeQueryParams({ zones: newSelectableZones.slice(0, 6) });
    } else {
      //setSelectedZones(newSelectedZones)
      //Workaround to make some appear selected in dropdown and not only be selected in code
      const workaround = newSelectableZones.filter((val) => {
        return newSelectedZones.some((v) => v.id === val.id);
      });
      setSelectedZones(workaround);
      handleChangeQueryParams({ zones: workaround });
    }
  }, [zones, selectedFloors]);

  useEffect(() => {
    //if (!reduxZones) return
    if (!selectedLocations) return;
    handleZones();
  }, [reduxZones, selectedLocations]);

  async function handleZones() {
    const _zones = await Promise.all(
      selectedLocations.map(async (loc) => {
        // console.log("reduxZones: ", reduxZones)
        if (loc.netId === location?.netId) {
          // console.log("1")
          return reduxZones;
        }
        if (zones.some((val) => val.netId === loc.netId)) {
          // console.log("2")
          return zones.filter((val) => val.netId === loc.netId);
        }
        const snapshot = await firestore
          .collection("Customers")
          .doc(customer.id)
          .collection(`Zones`)
          .where("netId", "==", loc.netId)
          .get();
        return snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
      })
    );
    const map = new Map();
    _zones
      .flat()
      .filter((val) => val)
      .forEach((item) => map.set(item.id, item));
    zones.forEach((item) => map.set(item.id, { ...map.get(item.id), ...item }));
    const newZones = Array.from(map.values());
    setZones(newZones);
  }

  //Wait for default values to load and set params
  useEffect(() => {
    if (didFirstSetOfParams) return;
    if (
      selectedLocations.length === 0 ||
      selectedFloors.length === 0 ||
      selectedZones.length === 0 ||
      selectedPeopleType.length === 0
    )
      return;
    handleChangeQueryParams(selectedTimeframe, page);
    setDidFirstSetOfParams(true);
  }, [
    selectedLocations,
    selectedFloors,
    selectedZones,
    selectedPeopleType,
    page,
  ]);

  const optionsConditions = () => {
    if (
      location?.customerType === "office" &&
      customer?.services?.advWiFi === true &&
      sensorsFromDB &&
      sensorsFromDB.length !== 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
        { name: "Wifi Advanced", value: "wifiSnapshot" },
        // Descomentar Sensors para demonstrar na dropdown  ↓
        // { name: "Sensors", value: "sensors" },
        // Descomentar Teams para demonstrar na dropdown  ↓
        // { name: "Teams", value: "teams" },
      ];
    }
    if (
      location?.customerType !== "office" &&
      customer?.services?.advWiFi === true &&
      sensorsFromDB &&
      sensorsFromDB.length !== 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
        { name: "Wifi Advanced", value: "wifiSnapshot" },
        // Descomentar Sensors para demonstrar na dropdown ↓
        // { name: "Sensors", value: "sensors" },
      ];
    }
    if (
      location?.customerType !== "office" &&
      customer?.services?.advWiFi === true &&
      sensorsFromDB &&
      sensorsFromDB.length === 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
        { name: "Wifi Advanced", value: "wifiSnapshot" },
      ];
    }
    if (
      location?.customerType === "office" &&
      customer?.services?.advWiFi !== true &&
      sensorsFromDB &&
      sensorsFromDB.length !== 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
        // Descomentar Sensors para demonstrar na dropdown ↓
        // { name: "Sensors", value: "sensors" },
      ];
    }
    if (
      location?.customerType === "office" &&
      customer?.services?.advWiFi !== true &&
      sensorsFromDB &&
      sensorsFromDB.length === 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
      ];
    }
    if (
      location?.customerType === "office" &&
      customer?.services?.advWiFi === true &&
      sensorsFromDB &&
      sensorsFromDB.length === 0
    ) {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
        { name: "Wifi Advanced", value: "wifiSnapshot" },
      ];
    } else {
      return [
        { name: "People", value: "people" },
        { name: "Wifi", value: "wifi" },
      ];
    }
  };

  return (
    <Card>
      <CardBody>
        <div className='row' id='dataFilterOuterDiv'>
          <div className='col-12 col-sm-6 col-md-3 col-xl-2'>
            <Select
              id='aboutDropdown'
              label='Reporting about'
              style={{ backgroundColor: "#ECF1F4" }}
              options={optionsConditions()}
              value={page}
              onChange={(e) => dispatch(actions.setPage(e.target.value))}
              onClose={() => handleChangeQueryParams(selectedTimeframe, page)}
            />
          </div>
          {/* Choose Group floors or zones only when wifiSnapshot is not selected */}
          {page !== "sensors" && (
            <div className='col-12 col-sm-6 col-md-3 col-xl-2'>
              <Select
                id='groupDropdown'
                label='Group by'
                style={{ backgroundColor: "#ECF1F4" }}
                options={
                  zones && zones.length !== 0
                    ? [
                        { name: "Floors", value: "floor" },
                        { name: "Zones", value: "zone" },
                      ]
                    : [{ name: "Floors", value: "floor" }]
                }
                value={group}
                onChange={(e) => dispatch(actions.setGroup(e.target.value))}
                onClose={() => handleChangeQueryParams()}
              />
            </div>
          )}
          {page !== "sensors" && (
            <div className='col-12 col-sm-6 col-md-3 col-xl-4'>
              <CustomAutoComplete
                options={networks}
                value={selectedLocations}
                setValue={setSelectedLocations}
                label='Locations'
                name='netName'
                onClose={() => handleChangeQueryParams(selectedTimeframe, page)}
              />
            </div>
          )}

          {page !== "wifiSnapshot" && (
            <div className='col-12 col-sm-6 col-md-3 col-xl-4'>
              <CustomAutoComplete
                options={selectableFloors || []}
                value={selectedFloors}
                setValue={setSelectedFloors}
                label='Floors'
                name='name'
                onClose={handleChangeQueryParams}
              />
            </div>
          )}

          {group === "zone" && page !== "wifiSnapshot" && (
            <div className='col-12 col-sm-8 col-md-5'>
              <CustomAutoComplete
                options={selectableZones || []}
                value={selectedZones}
                setValue={setSelectedZones}
                label='Zones'
                name='name'
                onClose={handleChangeQueryParams}
              />
            </div>
          )}
          {(page === "wifi" || page === "wifiSnapshot") && (
            <div className='col-12 col-sm-4 col-md-3'>
              <CustomAutoComplete
                options={displaySSID}
                value={selectedSSIDs}
                setValue={setSelectedSSIDs}
                label='SSIDs'
                onClose={handleChangeQueryParams}
              />
            </div>
          )}
          {(page === "people" || page === "teams") && (
            <div className='col-12 col-sm-4 col-md-4'>
              <CustomAutoComplete
                options={filterFields}
                value={selectedPeopleType}
                setValue={setSelectedPeopleType}
                label='People'
                name='name'
                onClose={handleChangeQueryParams}
              />
            </div>
          )}
          <div className='col-12 col-sm-3 col-md-3'>
            <DatePicker
              id='datePickerDataFilter'
              variant='dialog'
              fullWidth
              disableFuture
              format='dd/MM/yyyy'
              label='Date'
              value={date}
              onClose={() => handleChangeQueryParams()}
              onChange={(e) => {
                setDate(moment(e));
              }}
              onYearChange={() => null}
              TextFieldComponent={(params) => {
                var value = params.value;
                if (selectedTimeframe === "week") {
                  value = `${moment(params.value, "DD/MM/yyyy")
                    .subtract(1, "weeks")
                    .format("DD/MM/yyyy")} - ${params.value}`;
                }
                if (selectedTimeframe === "month") {
                  value = `${moment(params.value, "DD/MM/yyyy")
                    .subtract(1, "months")
                    .format("DD/MM/yyyy")} - ${params.value}`;
                }
                return (
                  <TextField
                    {...params}
                    value={value}
                    style={{ backgroundColor: "#ECF1F4" }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CalendarTodayIcon style={{ color: "#B8BCCA" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </div>
          <div
            className={
              page === "wifiSnapshot"
                ? "col-12 col-sm-9 col-md-9 d-flex justify-content-center align-items-center"
                : "col-12 col-sm-9 col-md-12 d-flex justify-content-center align-items-center"
            }
            style={{
              marginTop: "1rem",
              // border: "1px solid pink",
            }}
          >
            <Button
              id='dayBtnDataFilter'
              color={selectedTimeframe === "day" ? "primary" : "default"}
              onClick={() => setSelectedTimeframe("day")}
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "170%",
                textTransform: "none",
                color: selectedTimeframe !== "day" && "#B8BCCA",
                backgroundColor: selectedTimeframe === "day" && "#C3C4DD",
              }}
            >
              Day
            </Button>
            <Button
              id='weekBtnDataFilter'
              color={selectedTimeframe === "week" ? "primary" : "default"}
              onClick={() => setSelectedTimeframe("week")}
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "170%",
                textTransform: "none",
                color: selectedTimeframe !== "week" && "#B8BCCA",
                backgroundColor: selectedTimeframe === "week" && "#C3C4DD",
              }}
            >
              Week
            </Button>
            <Button
              id='monthBtnDataFilter'
              color={selectedTimeframe === "month" ? "primary" : "default"}
              onClick={() => setSelectedTimeframe("month")}
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "170%",
                textTransform: "none",
                color: selectedTimeframe !== "month" && "#B8BCCA",
                backgroundColor: selectedTimeframe === "month" && "#C3C4DD",
              }}
            >
              Month
            </Button>

            {/* ━━★  PDF BUTTON PEOPLE  ★━━ */}
            {page === "people" && (
              <Button
                className='dlPDFButton'
                color='primary'
                style={{
                  minHeight: "2.8rem",
                  marginLeft: "3rem",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "170%",
                  textTransform: "none",
                  backgroundColor: "#C3C4DD",
                }}
                onClick={() => {
                  // console.log("clicked DOWNLOAD PDF Button people");
                  setShowPDFPreviewModalPeople(true);
                  // Show close Icons on preview modal
                  setHide(false);
                }}
              >
                DOWNLOAD PDF
              </Button>
            )}

            {/* ━━★  PDF BUTTON WIFI  ★━━ */}
            {page === "wifi" && (
              <Button
                id='dlPDFButtonWifi'
                className='dlPDFButton'
                color='primary'
                style={{
                  minHeight: "2.8rem",
                  marginLeft: "3rem",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "170%",
                  textTransform: "none",
                  backgroundColor: "#C3C4DD",
                }}
                onClick={() => {
                  // console.log("clicked DOWNLOAD PDF Button");
                  setShowPDFPreviewModal(true);
                  // Show close Icons on preview modal
                  setHide(false);

                  const now = moment().format("YYYY-MM-DD HH:mm");
                  // const element = document.getElementById("wifiDivToPdf");

                  // addNewHTML();
                  // const content = `<div id="one" className="one">Aqui vai estar o HTML novo.</div>`;
                  // console.log("🚀 ~ content", content);

                  // Create the new HTML com os charts com medidas fixas.
                  // const html = ReactDOMServer.renderToStaticMarkup(
                  //   <PDFPreviewModal

                  //   // locationAccuracy={<LocationAccuracy {...props} />}
                  //   />
                  // );class="col-12 col-sm-4 col-md d-flex justify-content-center align-items-end"

                  // console.log(html.toString());
                  // const htmlToString = html.toString();
                  // const element = `${htmlToString}`;
                  // console.log("🚀 ~ element", element);

                  // Este É O MELHOR, e dá para page break. Funciona, mas não responde a media queries, nao fica responsivo.
                  const options = {
                    margin: 0,
                    filename: `Wifi_Report_${now}`,
                    jsPDF: {
                      // unit: "in",
                      // format: "letter",
                      unit: "pt",
                      format: "a4",
                      orientation: "p",
                      // hotfixes: ["px_scaling"],
                      // floatPrecision: "smart",
                      // precision: 15,
                    },
                    image: {
                      type: "jpeg",
                      quality: 1,
                      // width: 494,
                      // height: 494,
                    },
                    html2canvas: {
                      scale: 2,
                      scrollX: 0,
                      scrollY: 0,
                      // windowWidth: 1224,
                    },
                    // html2canvas: { scale: 2, windowWidth: 1100 },
                    // makes pages break to another pdf page after chosen elements id.
                    // pagebreak: {
                    //   mode: "avoid-all",
                    //   before: [
                    //     "#wifiDivToPdf2",
                    //     "#wifiDivToPdf3",
                    //     "#wifiDivToPdf4",
                    //   ],
                    //   after: [
                    //     "#wifiDivToPdf1",
                    //     // "#wifiDivToPdf2",
                    //     "#wifiDivToPdf3",
                    //     // "#wifiDivToPdf4",
                    //   ],
                    // },
                  };
                  // Save PDF
                  // html2pdf()
                  //   .set(options)
                  //   .from(htmlToString)
                  //   .save();

                  // Preview PDF
                  // html2pdf()
                  //   .set(options)
                  //   .from(htmlToString)
                  //   .toPdf()
                  //   .get("pdf")
                  //   .then(function(pdf) {
                  //     window.open(pdf.output("bloburl"), "_blank");
                  //   });

                  // Esta funciona qnd fazemos scroll nos gráficos para ver data de outros floors, mas
                  // aparecem as imagens mas ficam squeezed numa so page e n fica responsive
                  // function download() {
                  //   let pdf = new jsPDF("p", "pt", "a4");

                  //   html2canvas(document.querySelector("#wifiDivToPdf"), {
                  //     useCORS: true,
                  //     scale: 2,
                  //   }).then(function(canvas) {
                  //     let img = new Image();
                  //     img.src = canvas.toDataURL("image/png");
                  //     img.onload = function() {
                  //       pdf.addImage(
                  //         img,
                  //         0,
                  //         0,
                  //         pdf.internal.pageSize.width,
                  //         pdf.internal.pageSize.height
                  //       );

                  //       window.open(pdf.output("bloburl"));
                  //       // pdf.save(`Wifi_Report_${now}.pdf`);
                  //     };
                  //   });
                  // }

                  // download();
                }}
              >
                DOWNLOAD PDF
              </Button>
            )}

            {/* ━━★  PDF BUTTON WIFI ADVANCED  ★━━ */}
            {page === "wifiSnapshot" && (
              <Button
                id='dlPDFButtonWifiAdvanced'
                className='dlPDFButton'
                color='primary'
                style={{
                  minHeight: "2.8rem",
                  marginLeft: "3rem",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "170%",
                  textTransform: "none",
                  backgroundColor: "#C3C4DD",
                }}
                onClick={() => {
                  // console.log("clicked DOWNLOAD PDF Button wifi advanced");
                  setShowPDFPreviewModalWifiAdv(true);
                  // Show close Icons on preview modal
                  setHide(false);
                }}
              >
                DOWNLOAD PDF
              </Button>
            )}
          </div>

          {/* <PDFModalWifi
            showPDFModal={showPDFModal}
            setShowPDFModal={setShowPDFModal}
            user={user}
            pdfFormData={pdfFormData}
            setPdfFormData={setPdfFormData}
          /> */}

          {/* PDF Preview Modal People */}
          <PDFPreviewModalPeople
            showPDFPreviewModalPeople={showPDFPreviewModalPeople}
            setShowPDFPreviewModalPeople={setShowPDFPreviewModalPeople}
            user={user}
            pdfFormData={pdfFormData}
            setPdfFormData={setPdfFormData}
            group={group}
            showPDFModal={showPDFModal}
            setShowPDFModal={setShowPDFModal}
            selectedFloors={selectedFloors}
            selectedZones={selectedZones}
            selectedSSIDs={selectedSSIDs}
            date={date}
            selectedTimeframe={selectedTimeframe}
            hide={hide}
            setHide={setHide}
          />

          {/* PDF Preview Modal Wifi  */}
          <PDFPreviewModalWifi
            showPDFPreviewModal={showPDFPreviewModal}
            setShowPDFPreviewModal={setShowPDFPreviewModal}
            user={user}
            pdfFormData={pdfFormData}
            setPdfFormData={setPdfFormData}
            group={group}
            // showPDFModal={showPDFModal}
            // setShowPDFModal={setShowPDFModal}
            selectedFloors={selectedFloors}
            selectedZones={selectedZones}
            selectedSSIDs={selectedSSIDs}
            date={date}
            selectedTimeframe={selectedTimeframe}
            hide={hide}
            setHide={setHide}
          />

          {/* PDF Preview Modal Wifi Advanced */}
          <PDFPreviewModalWifiAdvanced
            showPDFPreviewModalWifiAdv={showPDFPreviewModalWifiAdv}
            setShowPDFPreviewModalWifiAdv={setShowPDFPreviewModalWifiAdv}
            user={user}
            pdfFormData={pdfFormData}
            setPdfFormData={setPdfFormData}
            group={group}
            // showPDFModal={showPDFModal}
            // setShowPDFModal={setShowPDFModal}
            selectedFloors={selectedFloors}
            selectedZones={selectedZones}
            selectedSSIDs={selectedSSIDs}
            date={date}
            selectedTimeframe={selectedTimeframe}
            hide={hide}
            setHide={setHide}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export function CustomAutoComplete(props) {
  const { options, value, setValue, label, name, onClose } = props;
  /* if (label === "Zones") {
    console.log("label: ", label)
    console.log("options: ", options)
    console.log("value: ", value)
  } */

  return (
    <Autocomplete
      id={`${label}-autocomplete`}
      data-cy={`${label}-autocomplete`}
      multiple
      disableClearable
      disableListWrap
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) => (name ? option[name] : option)}
      value={(value && value) || []}
      onChange={(event, value) => {
        if (value.length === 0) return;
        setValue(value);
      }}
      onClose={() => onClose && onClose()}
      renderInput={(params) => {
        // console.log(params, "p");
        return (
          <Input
            {...params}
            label={label}
            style={{ backgroundColor: "#ECF1F4" }}
          />
        );
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            id='checkboxCustom'
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {name ? option[name] : option}
        </React.Fragment>
      )}
      renderTags={(selected) => {
        let renderTagsValue = selected
          .map((elem) => (name ? elem?.[name] : elem))
          .join(", ");
        return (
          <Typography
            style={{ maxWidth: "80%" }}
            noWrap={true}
            color='textPrimary'
          >
            {renderTagsValue}
          </Typography>
        );
      }}
    />
  );
}
