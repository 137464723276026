const devConfig = {
  apiKey: "AIzaSyA5uIMjl0BZbJm2u-A2eT3WDj7aAl3Y2ME",
  authDomain: "ufind-dev.firebaseapp.com",
  databaseURL: "https://ufind-dev.firebaseio.com",
  projectId: "ufind-dev",
  storageBucket: "ufind-dev.appspot.com",
  messagingSenderId: "648996517377",
  appId: "1:648996517377:web:922961075bb71f280ff200",
  measurementId: "G-001KZX0Y2V",
};

export default devConfig;
