import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../UIContext";
import * as actions from "../../_redux/reporting/reportingActions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Chart from "react-apexcharts";
import {
  LinearProgress,
  withStyles,
  Badge,
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Popover,
} from "@material-ui/core";
import { Nav, Tab } from "react-bootstrap";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 160,
    marginLeft: "1vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    // border: "1px solid black",
    padding: "0",
  },
}))(Badge);

export function TeamsCompare() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  //★━━━━━━━━━━━━━━━★ Selectors ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  const { isLoading, customer, busiestFloors } = useSelector(
    (state) => ({
      isLoading: state.reporting.listLoading,
      customer: state.profile?.currentCustomer,
      busiestFloors: state.reporting.busiestFloors,
    }),
    shallowEqual
  );

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  const [graphData, setGraphData] = useState({
    labels: [""],
    series: [0],
    totalValue: 0,
  });
  const [key, setKey] = useState("all week");

  useEffect(() => {
    // dispatch(
    //   actions.fetchBusiestFloors({
    //     customerId: customer.customerId,
    //     queryParams: UIProps.queryParams,
    //   })
    // );
    UIProps.queryParams && console.log(UIProps.queryParams, "Params");
  }, [customer, UIProps.queryParams, dispatch]);

  const handleSubmit = (value) => {
    if (typeof value === "string") {
      setKey(value);
    }
    setKey(key);
  };

  useEffect(() => {
    if (busiestFloors) {
      if (key === "all week") {
        setGraphData({
          series: busiestFloors.allWeekSeries,
          labels: busiestFloors.allWeekLabels,
          // totalValue: busiestFloors.averageAllWeekTotal,
        });
      }
      if (key === "working days") {
        if (busiestFloors.workDaysSeries.length === 0) {
          setGraphData({
            labels: ["It's a weekend day."],
            series: [0],
            totalValue: 0,
          });
        } else {
          setGraphData({
            series: busiestFloors.workDaysSeries,
            labels: busiestFloors.workDaysLabels,
            // totalValue: busiestFloors.averageWorkDaysTotal,
          });
        }
      }
      if (key === "weekends") {
        if (busiestFloors.weekendsSeries.length === 0) {
          setGraphData({
            labels: ["It's a working day."],
            series: [0],
            totalValue: 0,
          });
        } else {
          setGraphData({
            series: busiestFloors.weekendsSeries,
            labels: busiestFloors.weekendsLabels,
            // totalValue: busiestFloors.averageWeekendsTotal,
          });
        }
      }
    }
  }, [busiestFloors, key]);

  let filterButtons = (
    <div className='card-toolbar row d-flex justify-content-center mt-5'>
      <Tab.Container activeKey={key}>
        <Nav
          as='ul'
          onSelect={(_key) => setKey(_key)}
          className='nav nav-pills nav-pills-sm nav-dark-75'
        >
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='all week'
              onClick={() => handleSubmit("all week")}
              className={`nav-link py-2 px-4 ${
                key === "all week" ? "active" : ""
              }`}
            >
              All Week
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='working days'
              onClick={() => handleSubmit("working days")}
              className={`nav-link py-2 px-4 ${
                key === "working days" ? "active" : ""
              }`}
            >
              Working Days
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='nav-item' as='li'>
            <Nav.Link
              eventKey='weekends'
              onClick={() => handleSubmit("weekends")}
              className={`nav-link py-2 px-4 ${
                key === "weekends" ? "active" : ""
              }`}
            >
              Weekends
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </div>
  );

  if (UIProps.queryParams.timeframe === "day") {
    filterButtons = (
      <div className='card-toolbar row d-flex justify-content-center mt-5'></div>
    );
  }

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handlePopoverOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);

  const allTeamsData = [
    { office: 70, home: 30, team: "Engineering" },
    { office: 60, home: 40, team: "Management" },
    { office: 80, home: 20, team: "Marketing" },
    { office: 50, home: 50, team: "Sales" },
  ];

  return (
    <Card style={{ height: "480px" }}>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <CardHeader
            // title='Team A'
            >
              <div className='d-flex  justify-content-start align-items-center'>
                <h3 className='ml-n5 mr-2'>Team A</h3>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Department A
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    //   value={age}
                    //   onChange={handleChange}
                    label='Department A'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </CardHeader>
            <CardBody>
              {isLoading && <LinearProgress color='secondary' />}
              <div className='container overflow-auto'>
                <div className='row flex-row flex-nowrap'>
                  {graphData && (
                    <>
                      <Chart
                        options={getChartOptions(graphData)}
                        series={graphData.series}
                        type='donut'
                        height={350}
                      />
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </div>
          <div className='col'>
            <CardHeader
            // title='Team B '
            >
              <div className='d-flex justify-content-start align-items-center'>
                <h3 className='ml-n5 mr-2'>Team B</h3>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Department B
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    //   value={age}
                    //   onChange={handleChange}
                    label='Department B'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </CardHeader>
            <CardBody>
              {isLoading && <LinearProgress color='secondary' />}
              <div className='container overflow-auto'>
                <div className='row flex-row flex-nowrap'>
                  {graphData && (
                    <>
                      <Chart
                        options={getChartOptions(graphData)}
                        series={graphData.series}
                        type='donut'
                        height={350}
                      />
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </Card>
  );
}

function getChartOptions(r) {
  return {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => val + "%",
      },
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 800,
              color: undefined,
              offsetY: -3,
            },
            value: {
              show: true,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 500,
              color: "black",
              offsetY: 3,
              formatter: (val) => val + "%",
            },
            total: {
              show: true,
              //   label: r.sampleSize + " / " + r.numMacs,
              color: "black",
              fontWeight: 800,
              formatter: (w) => {
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                const average = total / w.globals.seriesTotals.length;
                return Math.floor(average) + "h";
              },
            },
          },
        },
      },
    },
    labels: ["Office", "Home"],
    title: {
      text: r.team,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bolder",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "10px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 800,
                  color: undefined,
                  offsetY: -3,
                },
                value: {
                  show: true,
                  fontSize: "10px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                  color: "black",
                  offsetY: 3,
                  formatter: (val) => val + "%",
                },
                total: {
                  show: true,
                  //   label: r.sampleSize + " / " + r.numMacs,
                  fontSize: "10px",
                  color: "black",
                  fontWeight: 800,
                  formatter: () => "Samples",
                },
              },
            },
          },
        },
      },
    ],
  };
}
