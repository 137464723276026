const prodConfig = {
  apiKey: "AIzaSyDNywPBRwLL7O_4b_rTAPkOM86ZWjUJS48",
  authDomain: "ufind-prod.firebaseapp.com",
  databaseURL: "https://ufind-prod.firebaseio.com",
  projectId: "ufind-prod",
  storageBucket: "ufind-prod.appspot.com",
  messagingSenderId: "1075824714998",
  appId: "1:1075824714998:web:d95bbd5910547028f478d7",
  measurementId: "G-G3NCZ1MJD3",
};

export default prodConfig;
