import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { OverviewGlobalChart } from "./OverviewGlobalChart";

export function OverviewGlobal({ queryParams }) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await OverviewGlobalChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Overview - Global'
        // subtitle='Net failures & SNR'
      ></CardHeader>
      <CardBody style={{ height: "420px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
