import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
   listLoading: false,
   actionsLoading: false,
   allUsers: null,
   lastError: null,
}

export const callTypes = {
   list: "list",
   action: "action",
}

export const manageCustomerSlice = createSlice({
   name: "manageCustomer",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false
         } else {
            state.actionsLoading = false
         }
      },
      startCall: (state, action) => {
         state.error = null
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true
         } else {
            state.actionsLoading = true
         }
      },
      locationFetched: (state, action) => {
         state.listLoading = false
         state.error = null
      },
      locationAdded: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
      locationEdited: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
      locationEnabled: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
      locationDisabled: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
   },
})
