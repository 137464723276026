import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import * as actions from "../../_redux/booking/bookingActions"
import { DropdownSelect } from "../DropdownSelect"
import { ModalProgressBar } from "../../../../_metronic/_partials/controls"
import {
   makeStyles,
   Popover,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
   popover: {
      pointerEvents: "none",
   },
   paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
   },
}))

export function HybridWorkBalance() {
   const dispatch = useDispatch()
   const classes = useStyles()

   //━━━ Get data from redux ━━━\\
   const { isHybridWorkBalanceLoading, customer, usersDocs, department, user, profileLvl, monthDepartmentHybridWork, weekDepartmentHybridWork, monthUserHybridWork, weekUserHybridWork } = useSelector(
      (state) => ({
         isHybridWorkBalanceLoading: state.booking.isHybridWorkBalanceLoading,
         customer: state.profile?.currentCustomer,
         usersDocs: state.booking.usersDocs,
         department: state.basePage?.department,
         user: state.auth?.user,
         profileLvl: state.auth?.claims?.profileLvl,
         monthDepartmentHybridWork: state.booking.monthDepartmentHybridWork,
         weekDepartmentHybridWork: state.booking.weekDepartmentHybridWork,
         monthUserHybridWork: state.booking.monthUserHybridWork,
         weekUserHybridWork: state.booking.weekUserHybridWork,
      }), shallowEqual)

   //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
   //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
   const [hybridWorkBalance, setHybridWorkBalance] = useState([])
   const [userFilter, setUserFilter] = useState({ value: 'me', label: 'Me' })
   const [timeFilter, setTimeFilter] = useState({ value: 'week', label: 'This week' })
   const [anchorEl, setAnchorEl] = useState(null)

   useEffect(() => {
      if (customer && usersDocs) {
         if (userFilter.value === "department") {
            if (timeFilter.value === "month") {
               if (monthDepartmentHybridWork) { return }
               const macAddress = usersDocs.map(val => val.devicesArray).flat().filter(v => v)
               dispatch(actions.fetchHybridWorkBalance({
                  customer,
                  macAddress,
                  getDepartmentData: true,
                  month: true
               }))
            } else {
               if (weekDepartmentHybridWork) { return }
               const macAddress = usersDocs.map(val => val.devicesArray).flat().filter(v => v)
               dispatch(actions.fetchHybridWorkBalance({
                  customer,
                  macAddress,
                  getDepartmentData: true
               }))
            }
         } else {
            if (timeFilter.value === "month") {
               if (monthUserHybridWork) { return }
               const macAddress = usersDocs.find(val => val.id === user.id).devicesArray
               dispatch(actions.fetchHybridWorkBalance({
                  customer,
                  macAddress,
                  month: true
               }))
            } else {
               if (weekUserHybridWork) { return }
               const macAddress = usersDocs.find(val => val.id === user.id).devicesArray
               dispatch(actions.fetchHybridWorkBalance({
                  customer,
                  macAddress
               }))
            }
         }
      }
   }, [customer, usersDocs, userFilter, timeFilter, user])


   useEffect(() => {
      if (userFilter.value === "department") {
         if (timeFilter.value === "month") {
            setHybridWorkBalance(monthDepartmentHybridWork || [])
         } else {
            setHybridWorkBalance(weekDepartmentHybridWork || [])
         }
      } else {
         if (timeFilter.value === "month") {
            setHybridWorkBalance(monthUserHybridWork || [])
         } else {
            setHybridWorkBalance(weekUserHybridWork || [])
         }
      }
   }, [userFilter, timeFilter, monthDepartmentHybridWork, weekDepartmentHybridWork, monthUserHybridWork, weekUserHybridWork])


   const open = Boolean(anchorEl)

   const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget)
   }

   const handlePopoverClose = () => {
      setAnchorEl(null)
   }

   return <Card isLoading={isHybridWorkBalanceLoading}>
      <CardHeader
         title="Hybrid work balance"
         subtitle={
            <>
               <span
                  className='w-100 text-center'
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
               >
                  ...
               </span>
               <Popover
                  id='mouse-over-popover'
                  className={classes.popover}
                  classes={{
                     paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                     vertical: "bottom",
                     horizontal: "left",
                  }}
                  transformOrigin={{
                     vertical: "top",
                     horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
               >
                  <div style={{ width: "350px", wordWrap: "break-word" }}>
                     <b>Hybrid work balance: </b>The balance is the percentage of
                     office days and non office days. It's counted as an office day
                     everyday that you are seen for more than 30 minutes.
                  </div>
               </Popover>
            </>
         }
         toolbar={profileLvl >= 2 && department &&
            <div className="d-flex align-self-start">
               <div className="pr-5">
                  <DropdownSelect
                     dataFilter={userFilter}
                     setDataFilter={setUserFilter}
                     options={[
                        { value: 'me', label: 'Me' },
                        { value: 'department', label: 'Department' }
                     ]}
                  />
               </div>
               <DropdownSelect
                  dataFilter={timeFilter}
                  setDataFilter={setTimeFilter}
                  options={[
                     { value: 'week', label: 'This week' },
                     { value: 'month', label: 'This month' }
                  ]}
               />
            </div>}
      />
      <CardBody>
         <Chart
            options={getChartOptions()}
            series={hybridWorkBalance}
            type="pie"
            height={300}
         />
      </CardBody>
   </Card >
}

function getChartOptions() {
   return {
      chart: {
         type: 'pie',
         toolbar: { show: false }
      },
      colors: ['#31D0AA', "#D6D9E1"],
      dataLabels: {
         enabled: false
      },
      plotOptions: {
         pie: {
            expandOnClick: false
         }
      },
      labels: ['Office time', 'Remote work'],
      legend: {
         position: 'bottom',
         height: 50,
      },
      noData: {
         text: "No data",
         align: 'center',
         verticalAlign: 'top',
         offsetX: 0,
         offsetY: 50,
         style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
         }
      },
      tooltip: {
         y: {
            formatter: (val) => val + "%",
         },
      },
      states: {
         hover: {
            filter: {
               type: 'darken',
               value: 0.50,
            }
         }
      }
   }
}