import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const HistogramFloorsChart = (queryParams) => {
  //   console.log(queryParams, "SNRFloorsChart queryParams");

  return getFloorSNRPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = badSNRFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(data, "data SNRFloorsChart");

      let options = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: [
          "#323389",
          "#0058A9",
          "#0077B8",
          "#0095BA",
          "#00B0B3",
          "#52C8AB",
        ],
        noData: {
          text: "Loading...",
        },
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='bar' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        // console.log(data, "data when data");

        options = {
          chart: {
            height: 350,
            type: "bar",
            toolbar: {
              show: false,
            },
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [
            "#323389",
            "#0058A9",
            "#0077B8",
            "#0095BA",
            "#00B0B3",
            "#52C8AB",
          ],
          xaxis: {
            categories: data.labels || [],
          },
          yaxis: {
            // tickAmount: 5,
            // min: 0,
            // max: 100,
            labels: {
              show: true,
              formatter: (v) => {
                return v + "%";
              },
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          noData: {
            text: "No data to display.",
          },
        };

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={data.series || []}
              type='bar'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch SNR samples ", error);
      error.clientMessage = "Can't fetch SNR samples";
    });
};

// API fetch SNR
function getFloorSNRPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);
  // console.log("🚀 ~ floors", floors);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/snr/${queryParams.customerId}`,
  });
}

// Parse result SNR
export const badSNRFloorsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  //   console.log("🚀 ~ result", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );
    if (val) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        samplesTo05: val.samplesTo05 ? val.samplesTo05 : 0,
        samplesTo10: val.samplesTo10 ? val.samplesTo10 : 0,
        samplesTo15: val.samplesTo15 ? val.samplesTo15 : 0,
        samplesTo20: val.samplesTo20 ? val.samplesTo20 : 0,
        samplesTo25: val.samplesTo25 ? val.samplesTo25 : 0,
        samplesTo30: val.samplesTo30 ? val.samplesTo30 : 0,
        samplesTo35: val.samplesTo35 ? val.samplesTo35 : 0,
        samplesTo40: val.samplesTo40 ? val.samplesTo40 : 0,
        samplesTo45: val.samplesTo45 ? val.samplesTo45 : 0,
        samplesTo50: val.samplesTo50 ? val.samplesTo50 : 0,
      });
    }
  });

  //   console.log("🚀 ~ data", data);

  const newData = [];

  data &&
    data.forEach((d) => {
      newData.push({
        name: d.floorPlan + " - " + d.location,
        data: [
          d.samplesTo05,
          d.samplesTo10,
          d.samplesTo15,
          d.samplesTo20,
          d.samplesTo25,
          d.samplesTo30,
          d.samplesTo35,
          d.samplesTo40,
          d.samplesTo45,
          d.samplesTo50,
        ],
      });
    });

  //   console.log(newData, "newData");

  const finalSeries = [];

  newData &&
    newData.forEach((n) => {
      //   console.log("🚀 ~ n.data", n.data);
      const max = sumProperty(n.data);
      //   console.log("🚀 ~ max", max);
      const newD = convertToPercentages(n.data, max);
      //   console.log("🚀 ~ newD", newD);
      finalSeries.push({
        name: n.name,
        data: newD,
      });
    });

  //   console.log(finalSeries, "finalSeries");

  return {
    labels: ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50"],
    series: finalSeries,
  };
};

const convertToPercentages = function(arr, max) {
  return arr.map(function(d, i) {
    return ((100 * d) / max) | 0;
  });
};

function sumProperty(array) {
  const sum = array.reduce((accumulator, object) => {
    return accumulator + object;
  }, 0);
  return sum;
}

// function sumProperty2(array) {
//   const sum = array.reduce((accumulator, object) => {
//     return accumulator + object.count;
//   }, 0);
//   return sum;
// }

// const getAvgCount = (array) => {
//   if (!array) {
//     return null;
//   } else {
//     const total = array.reduce((acc, c) => acc + c.count, 0);
//     const average = total / array.length;
//     return average;
//   }
// };

// const sumFloors = (array) => {
//   let holderOne = [];
//   array.forEach(function(d) {
//     if (holderOne.some((val) => val.date === d.date)) {
//       const index = holderOne.findIndex((val) => val.date === d.date);

//       holderOne[index] = {
//         ...holderOne[index],
//         count: holderOne[index].count + d.count,
//         counter: holderOne[index].counter + 1,
//       };
//     } else {
//       holderOne.push({
//         date: d.date,
//         location: d.location,
//         count: d.count,
//         counter: 1,
//       });
//     }
//   });
//   return holderOne.map((val, i) => {
//     return {
//       date: val.date,
//       location: val.location,
//       count: (val.count / val.counter).toFixed(2) * 1,
//     };
//   });
// };
