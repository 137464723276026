import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Modal } from "react-bootstrap"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Bold } from "../../../../_partials/typography/Bold"
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
   formControl: {
      marginTop: theme.spacing(2),
      margin: theme.spacing(1),
      width: "100%",
   },
}))

const FourthStep = ({ setForm, formData, navigation }) => {
   const classes = useStyles()

   const { previous, next } = navigation
   const { SSIDs, employeeSSID, guestSSID } = formData.merakiNetworks[0]
   const { netId } = formData.merakiNetworks[0]
   const { meraki_key, orgId } = formData.merakiDashboard

   const [guestSSIDdata, setGuestSSID] = useState(guestSSID)
   const [employeesSSIDdata, setEmployeesSSID] = useState(employeeSSID)
   const [show, setShow] = useState(false)
   const [loading, setLoading] = useState(false)

   function handleSubmit() {
      if (employeesSSIDdata < 1 || guestSSIDdata < 1) {
         setShow(true)
      } else {
         setShow(false)
         var merakiNetworks = [{
            ...formData.merakiNetworks[0],
            employeeSSID: employeesSSIDdata,
            guestSSID: guestSSIDdata
         }]
         setForm({ target: { name: "data.merakiNetworks", value: merakiNetworks } })
         switch (formData.completedStep) {
            case 3: navigation.go("fifthStep")
               break
            case 4: navigation.go("sixthStep")
               break
            case 5: navigation.go("sevenStep")
               break
            default: setForm({
               target: {
                  name: "data.completedStep",
                  value: 3
               }
            })
               next()
               break
         }
      }
   }

   function handleContinue() {
      setShow(false)
      next()
   }

   function onHide() {
      setShow(false)
   }

   const handleChange = (event) => {
      var newSsid = JSON.parse(JSON.stringify(employeesSSIDdata))
      if (event.target.checked) {
         newSsid.push(event.target.name)
      } else {
         newSsid = newSsid.filter(function (item) {
            return item !== event.target.name
         })
      }
      setEmployeesSSID(newSsid)
   }

   const handleChange2 = (event) => {
      var newSsid = JSON.parse(JSON.stringify(guestSSIDdata))
      if (event.target.checked) {
         newSsid.push(event.target.name)
      } else {
         newSsid = newSsid.filter(function (item) {
            return item !== event.target.name
         })
      }
      setGuestSSID(newSsid)
   }

   useEffect(() => {
      if (orgId && meraki_key && netId) {
         setLoading(true)
         var myHeaders = new Headers()
         myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
         myHeaders.append("Content-Type", "application/json")

         var raw = JSON.stringify({
            "merakiKey": meraki_key,
            "orgId": orgId,
            "netId": netId
         })

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
         }

         fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/getLocation`, requestOptions)
            .then(response => response.text())
            .then(result => {
               const jsonResult = JSON.parse(result)
               const geoLocation = jsonResult?.message
               const levels = geoLocation.floors.map(val => {
                  const floor = formData.merakiNetworks[0]?.levels?.find(v => v.floorId === val.floorId)
                  if (floor) {
                     return {
                        ...val,
                        level: floor.level
                     }
                  } else {
                     return val
                  }
               })
               var merakiNetworks = [{
                  ...formData.merakiNetworks[0],
                  city: geoLocation.city,
                  country: geoLocation.country,
                  gpslat: geoLocation.lat,
                  gpslng: geoLocation.lng,
                  levels: levels,
                  SSIDs: geoLocation.ssid
               }]
               setForm({ target: { name: "data.merakiNetworks", value: merakiNetworks } })
               setLoading(false)
            }).catch(error => {
               console.log('error', error)
               setLoading(false)
            })
      }
   }, [meraki_key, orgId, netId])


   return <Card isLoading={loading} margin={false} style={{ height: "100%" }}>
      <CardHeader
         title="Choose SSIDs"
         toolbar={<>
            {formData.completedStep < 3 && <Button
               variant="contained"
               color="default"
               style={{ marginRight: "10px" }}
               size="large"
               disableElevation
               onClick={previous}>
               Previous
            </Button>}
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => handleSubmit()}
            >
               {formData.completedStep >= 3 ? "Submit" : "Next"}
            </Button>
         </>}
      />
      <CardBody>
         <Modal
            show={show}
            onHide={onHide}
            contentClassName="bg-transparent"
         >
            <Modal.Body
               style={{
                  padding: "0",
                  backgroundColor: "#ECF1F4",
                  borderRadius: "10px",
                  overflow: "hidden"
               }}
            >
               <div className="pl-7 pr-7 pb-7 pt-2">
                  {/* ━━★  TITLE  ★━━ */}
                  <div className="row align-items-center">
                     <div className="col-auto mr-auto">
                        <Bold>SSIDs confirmation</Bold>
                     </div>
                     <div className="col-auto" >
                        <IconButton onClick={onHide} style={{ left: "12px" }}>
                           <CloseIcon />
                        </IconButton>
                     </div>
                  </div>

                  {/* ━━★  BODY  ★━━ */}
                  {employeesSSIDdata.length < 1 && guestSSIDdata.length < 1 ?
                     <span>Employees SSID and Guest SSID empty, do you wish to continue?</span> :
                     employeesSSIDdata.length < 1 ?
                        <span>Employees SSID empty, do you wish to continue?</span> :
                        <span>Guest SSID empty, do you wish to continue?</span>}

                  {/* ━━★  FOOTER  ★━━ */}
                  <div className="row mt-4">
                     <button
                        type="button"
                        onClick={onHide}
                        className="col btn btn-block btn-light btn-elevate mr-2"
                     >
                        Cancel
                     </button>
                     <button
                        type="button"
                        onClick={handleContinue}
                        className="col btn btn-block btn-primary btn-elevate m-0 ml-2"
                     >
                        Continue
                     </button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         <div className="row pt-4">
            <div className="col-sm-6 mb-4 mb-sm-0">
               <div className="card">
                  <div className="card-body">
                     <h5 className="card-title">Employees SSID</h5>
                     <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                           {SSIDs && SSIDs.map((value, i) => {
                              return <FormControlLabel key={i}
                                 control={<Checkbox color="primary" checked={employeesSSIDdata.includes(value)} onChange={handleChange} name={value} />}
                                 label={value}
                                 disabled={guestSSIDdata.includes(value)}
                              />
                           })}
                        </FormControl>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-6">
               <div className="card">
                  <div className="card-body">
                     <h5 className="card-title">Guest SSID</h5>
                     <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                           {SSIDs && SSIDs.map((value, i) => {
                              return <FormControlLabel key={i}
                                 control={<Checkbox color="primary" checked={guestSSIDdata.includes(value)} onChange={handleChange2} name={value} />}
                                 label={value}
                                 disabled={employeesSSIDdata.includes(value)}
                              />
                           })}
                        </FormControl>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </CardBody>
   </Card>
}

export default FourthStep