import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { profileSlice } from "../../../../../app/redux/profile/profileSlice";
import { firestore } from "../../../../../firebase";
import { useDocument } from "@nandorojo/swr-firestore";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const { actions } = profileSlice;

export default function HeaderSelections() {
  const dispatch = useDispatch();

  const {
    currentCustomer,
    currentLocation,
    currentLevel,
    userClaims,
  } = useSelector(
    (state) => ({
      currentCustomer: state.profile.currentCustomer,
      currentLocation: state.profile.currentLocation,
      currentLevel: state.profile.currentLevel,
      userClaims: state.auth.claims,
    }),
    shallowEqual
  );

  const [customers, setCustomers] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const { data: customer } = useDocument(
    selectedCustomer ? `Customers/${selectedCustomer}` : null,
    { listen: true }
  );

  useEffect(() => {
    if (!customer?.exists) return;
    dispatch(actions.customerSelected(customer || null));

    if (
      customer.merakiNetworks.some(
        (val) => val.netId === currentLocation?.netId
      )
    )
      return;
    dispatch(actions.locationSelected());
    dispatch(actions.levelSelected());
  }, [customer]);

  useEffect(() => {
    if (userClaims.profileLvl < 3) {
      setSelectedCustomer(userClaims.customerId);
      return;
    }
    firestore
      .collection("Customers")
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setCustomers(data);
      });
  }, [userClaims]);

  return (
    <div className='header-selections'>
      {/*\★━━━━━━━━━━━★ Select customer ★━━━━━━━━━━━★\*/}
      {customers && userClaims.profileLvl >= 3 && (
        <div style={{ marginLeft: "10px" }}>
          <BusinessIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={currentCustomer?.id || ""}
              onChange={(e) => {
                setSelectedCustomer(e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Select customer
              </MenuItem>
              {customers.map((val) => {
                return (
                  <MenuItem key={val.id} value={val.id}>
                    {val.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}

      {userClaims.profileLvl >= 1 && (
        <div style={{ marginLeft: "10px" }}>
          {/*\★━━━━━━━━━━━★ Select location ★━━━━━━━━━━━★\*/}
          <LocationOnIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={currentLocation?.netId || ""}
              onChange={(e) => {
                if (!currentCustomer?.merakiNetworks) return;
                if (currentLocation?.netId === e.target.value) return;
                var l = currentCustomer.merakiNetworks.find(
                  (val) => val.netId == e.target.value
                );
                dispatch(actions.locationSelected(l));
                dispatch(actions.levelSelected());
              }}
            >
              <MenuItem value='' disabled>
                Select location
              </MenuItem>
              {currentCustomer &&
                currentCustomer.merakiNetworks.map((val) => {
                  return (
                    <MenuItem key={val.netId} value={val.netId}>
                      {val.netName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}

      {userClaims.profileLvl >= 1 && (
        <div style={{ marginLeft: "10px" }}>
          {/*\★━━━━━━━━━━━★ Select level ★━━━━━━━━━━━★\*/}
          <ClearAllIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={currentLevel?.floorId ? currentLevel?.floorId : ""}
              onChange={(e) => {
                if (!currentLocation?.levels) return;
                var l = currentLocation.levels.find(
                  (val) => val.floorId == e.target.value
                );
                dispatch(actions.levelSelected(l));
              }}
            >
              <MenuItem value='' disabled>
                Select level
              </MenuItem>
              {currentLocation?.levels &&
                currentLocation.levels.map((val) => {
                  return (
                    <MenuItem key={val.floorId} value={val.floorId}>
                      {val.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}
