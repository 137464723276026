import React, { useEffect } from "react"
import { useForm, useStep } from "react-hooks-helper"
import { deleteCustomer } from "./_helpers/ActionsHelpers"
import LeftCard from "./cards/LeftCard"
import TopCard from "./cards/TopCard"
import Step1 from "./steps/Step1"
import Step2 from "./steps/Step2"
import FourthStep from "./steps/FourthStep"
import FifthStep from "./steps/FifthStep"
import SixthStep from "./steps/SixthStep"
import SevenStep from "./steps/SevenStep"
import Validation from "./steps/Validation"
import "./CreateCustomerStyle.css"

const steps = [
   { id: "setupOrg" },
   { id: "secondStep" },
   { id: "fourthStep" },
   { id: "fifthStep" },
   { id: "sixthStep" },
   { id: "sevenStep" },
   { id: "validation" }
]

const defaultData = {
   data: {
      customerId: null,
      domain: "",
      admin: "",
      name: "",
      tenantID: "",
      merakiNetworks: [
         {
            netId: null,
            netName: "",
            timeZone: "",
            city: "",
            country: "",
            gpslat: "",
            gpslng: "",
            customerType: "",
            levels: [],
            SSIDs: [],
            guestSSID: [],
            employeeSSID: []
         }
      ],
      merakiDashboard: {
         orgName: null,
         orgId: null,
         api_version: "v1",
         meraki_key: null
      },
      merakiScanApi: {
         validator: "",
         validator_url: "",
         api_version: "v3",
         secret_validator: ""
      },
      services: {
         guestService: true,
         userService: true,
         messageService: true,
         SAML: false
      },
      userService: {
         meraki_key: null
      },
      created: {
         createdBy: null,
         createdByOn: null,
      },
      completedStep: 0,
      password: null
   }
}

export const CreateCustomerPage = () => {
   const [formData, setForm] = useForm(defaultData)
   const { step, navigation } = useStep({ initialStep: 0, steps })
   const { id } = step
   const props = { defaultData, formData: formData.data, setForm, navigation }


   function test(val) {
      val && deleteCustomer(val)
   }

   useEffect(() => {
      const customerId = formData?.data?.customerId
      if (customerId) {
         window.onbeforeunload = async (e) => {
            await deleteCustomer(customerId)
            setForm({
               target: {
                  name: "data",
                  value: defaultData.data
               }
            })
            navigation.go("setupOrg")
         }
      }
      return () => {
         window.onbeforeunload = null
      }
   }, [formData.data.customerId])

   useEffect(() => {
      const customerId = formData?.data?.customerId
      return () => { customerId && test() }
   }, [])

   return <div className="p-3 p-sm-10 p-md-20 p-lg-30">
      <div className="d-lg-none mb-5">
         <TopCard id={id} {...props} />
      </div>
      <div className="d-flex">
         <div className="pr-lg-5">
            <LeftCard id={id} {...props} />
         </div>
         <div className="w-100">
            {id === "setupOrg" && <Step1 {...props} />}
            {id === "secondStep" && <Step2 {...props} />}
            {id === "fourthStep" && <FourthStep {...props} />}
            {id === "fifthStep" && <FifthStep {...props} />}
            {id === "sixthStep" && <SixthStep {...props} />}
            {id === "sevenStep" && <SevenStep {...props} />}
            {id === "validation" && <Validation defaultData={defaultData} {...props} />}
         </div>
      </div>
   </div>
}
