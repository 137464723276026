import React from "react"
import { Button as ReactButton } from "react-bootstrap"
import { ButtonTextSmall } from "./typography/ButtonTextSmall"

export function Button({ text, style, ...props }) {
   return <ReactButton
      style={{
         border: "none",
         backgroundColor: "#C3C4DD",
         color: "#323389",
         ...style,
      }}
      {...props}>
      <ButtonTextSmall text={text} />
   </ReactButton>
}
