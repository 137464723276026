import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import { isFragment } from "react-is";
import { ModalProgressBar } from "./ModalProgressBar";


export function Card({ className, isLoading, children, padding = true, margin = true, ...props }) {
   return <>
      {isLoading && <ModalProgressBar
         style={{
            marginBottom: "-6px",
            height: "6px",
            position: "relative",
            zIndex: 999,
            borderRadius: "0.42rem 0.42rem 0 0"
         }}
      />}
      <div
         className={clsx("card card-custom", margin && "mb-8", padding && "p-5", className)}
         {...props}
      >
         {children}
      </div>
   </>
}

export function CardBody({ className, padding, ...props }) {
   return <div
      className={className}
      style={{ padding: padding }}
      {...props}
   />
}


export const CardHeaderIcon = forwardRef(({ className }, ref) => (
   <span ref={ref} className={clsx("card-head-icon", className)} />
));

export const CardHeaderTitle = forwardRef(({ className, ...props }, ref) => (
   // eslint-disable-next-line jsx-a11y/heading-has-content
   <h3 {...props} ref={ref} className={clsx("card-title align-items-start flex-column mt-0 mb-0", className)} />
));

export const CardHeaderToolbar = forwardRef(
   ({ children, className, ...props }, ref) => (
      <div {...props} ref={ref} className={clsx("card-toolbar", className)}>
         {children}
      </div>
   )
);

export const CardHeader = forwardRef(
   (
      {
         children,
         icon,
         title,
         subtitle,
         toolbar,
         className,
         sticky = false,
         labelRef,
         ...props
      },
      ref
   ) => {
      const [top, setTop] = useState(0);
      const [windowHeight, setWindowHeight] = useState(0);

      useEffect(() => {
         handleResize();

         function handleResize() {
            setWindowHeight(window.innerWidth);
         }

         window.addEventListener("resize", handleResize);

         return () => {
            window.removeEventListener("resize", handleResize);
         };
      });

      useEffect(() => {
         // Skip if sticky is disabled or on initial render when we don't know about window height.
         if (!sticky || windowHeight === 0) {
            return;
         }

         const headerElement = document.querySelector(".header");
         const subheaderElement = document.querySelector(".subheader");
         const headerMobileElement = document.querySelector(".header-mobile");

         let nextMarginTop = 0;

         // mobile header
         if (window.getComputedStyle(headerElement).height === "0px") {
            nextMarginTop = headerMobileElement.offsetHeight;
         } else {
            // desktop header
            if (document.body.classList.contains("header-minimize-topbar")) {
               // hardcoded minimized header height
               nextMarginTop = 60;
            } else {
               // normal fixed header
               if (document.body.classList.contains("header-fixed")) {
                  nextMarginTop += headerElement.offsetHeight;
               }

               if (document.body.classList.contains("subheader-fixed")) {
                  nextMarginTop += subheaderElement.offsetHeight;
               }
            }
         }

         setTop(nextMarginTop);
      }, [sticky, windowHeight]);

      return <div
         ref={ref}
         className={subtitle ? "card-header p-0 pb-3" : "card-header p-0"}
         style={{ borderBottom: 0, minHeight: !subtitle && "50px" }}
      >
         {title != null && (
            <div ref={labelRef} className={clsx("card-title m-0", className)}>
               {icon}

               {/* Wrap string and fragments in CardHeaderTitle */
                  typeof title === "string" || isFragment(title) ? (
                     <CardHeaderTitle>
                        <span className="font-weight-bolder text-dark">
                           {title}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                           {subtitle}
                        </span>
                     </CardHeaderTitle>
                  ) : (
                     title
                  )}


            </div>
         )}

         <CardHeaderToolbar>{toolbar}</CardHeaderToolbar>
         {children}
      </div>
   }
);