import { firestore } from "../../../../firebase"
import moment from 'moment'
import { gapi } from 'gapi-script'

export async function checkResourseAvailability(params) {
  var available = true

  if (params.resource.type === "meeting") {
    try {
      var response = await gapi.client.calendar.events.list({
        'calendarId': params.resource.calendarResource,
        'timeMin': moment(params.date).subtract(1, "day").startOf("day").toISOString(),
        'timeMax': moment(params.date).add(1, "day").endOf("day").toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'orderBy': 'startTime'
      })
    } catch (err) {
      if (err.result?.error?.message === "Not Found") {
        return "Resource not found, ask an admin for permission"
      }
      return "Error booking"
    }

    const events = response.result.items

    var available = true
    events.forEach(event => {
      const start = params.start
      const end = params.end
      const eventStart = moment(event.start.dateTime)
      const eventEnd = moment(event.end.dateTime)

      if (start.isBetween(eventStart, eventEnd)) { available = false }
      if (end.isBetween(eventStart, eventEnd)) { available = false }
      if (start.isBefore(eventStart) && end.isAfter(eventEnd)) { available = false }
      if (start.format() == eventStart.format()) { available = false }
      if (end.format() == eventEnd.format()) { available = false }
    })

    if (!available) {
      return { busy: true, message: "Resource busy in selected time frame" }
    }
    return
  }

  const dayResults = await firestore
    .collection("Customers")
    .doc(params.customerId)
    .collection("Bookings")
    .where("deleted", "==", false)
    .where("resource", "==", params.resource.id)
    .where("date", "==", moment(params.date).toDate())
    .get()
  dayResults.forEach(doc => {
    const book = doc.data()
    const start = params.start
    const end = params.end
    const eventStart = moment(book.start.toDate())
    const eventEnd = moment(book.end.toDate())
    if (doc.id === params.bookingId) { return }
    if (start.isBetween(eventStart, eventEnd)) { available = false }
    if (end.isBetween(eventStart, eventEnd)) { available = false }
    if (start.isBefore(eventStart) && end.isAfter(eventEnd)) { available = false }
    if (start.format() == eventStart.format()) { available = false }
    if (end.format() == eventEnd.format()) { available = false }
  })
  if (!available) {
    return { busy: true, message: "Resource busy in selected time frame" }
  }

  const dayBeforeResults = await firestore
    .collection("Customers")
    .doc(params.customerId)
    .collection("Bookings")
    .where("deleted", "==", false)
    .where("resource", "==", params.resource.id)
    .where("date", "==", moment(params.date).subtract(1, "day").toDate())
    .get()
  dayBeforeResults.forEach(doc => {
    const book = doc.data()
    const start = params.start
    const end = params.end
    const eventStart = moment(book.start.toDate())
    const eventEnd = moment(book.end.toDate())
    if (doc.id === params.bookingId) { return }
    if (start.isBetween(eventStart, eventEnd)) { available = false }
    if (end.isBetween(eventStart, eventEnd)) { available = false }
    if (start.isBefore(eventStart) && end.isAfter(eventEnd)) { available = false }
    if (start.format() == eventStart.format()) { available = false }
    if (end.format() == eventEnd.format()) { available = false }
  })
  if (!available) {
    return { busy: true, message: "Resource busy in selected time frame" }
  }

  return
}

export const averageTimeAtTheOfficeResultToGraphData = ({ result, start, end }) => {
  const data = JSON.parse(result).map((val) => {
    return {
      name: val[1],
      count: val[2],
      date: val[0],
    }
  })

  let dates = []
  while (start.isSameOrBefore(end)) {
    dates.push(start.format())
    start.add(1, "day")
  }

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = []
    }
    obj[curr.name][dates.findIndex(val => moment(val).startOf("day").isSame(moment(curr.date)))] = parseInt(curr.count)
    return obj
  }, {})

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0
        } else {
          return prodArr[monthIndex]
        }
      }),
    }
  })

  return { xAxis: dates, yAxis: series.filter(val => val.name !== "null") }
}