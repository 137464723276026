import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { HistogramFloorsChart } from "./HistogramFloorsChart";

export function HistogramFloors({ queryParams }) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(queryParams, "UIProps.queryParams BADFLOORS");
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await HistogramFloorsChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader title='Histogram' subtitle='SNR % each floor'></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
