import React from "react";
import ReactDOM from "react-dom";
import { IconButton, Tooltip } from "@material-ui/core/";
// import "../../DesignStudio.css";
// import { profileSlice } from "../../../../redux/profile/profileSlice";

// const { actions } = profileSlice;

export default class ChangeFloorControlDashboard {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";
    // this.container.id = "mapboxgl-ctrl-bottom-right";

    // Hide if !(props.allFloors / selectedLocation)
    this.content = (
      <div style={{ display: !this.props.allFloors ? "none" : "block" }}>
        <Tooltip title={this.props?.selectedLevel?.name} arrow placement='left'>
          <IconButton
            className='floorsBtnOutside'
            style={{
              borderRadius: "50%",
              width: "3.2rem",
              height: "3.2rem",
              color: "black",
              backgroundColor: "white",
              textAlign: "center",
              fontSize: "14px",
              marginTop: "2px",
              border: "2px solid #C6C3C0",
            }}
            onClick={(e) => {
              this.props.handleClick(e);
            }}
          >
            <b>{this.props?.selectedLevel?.level}</b>
          </IconButton>
        </Tooltip>
      </div>
    );

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
