import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardHeader, CardBody } from "../../../_partials/Card";

export function FloorOccupancy() {
  const { floorPlans, peopleStats } = useSelector(
    (state) => ({
      floorPlans: state.basePage?.floorPlans,
      peopleStats: state.liveData?.peopleStats,
    }),
    shallowEqual
  );

  const [graphData, setGraphData] = useState();
  const [floorMaxOcup, setFloorMaxOcup] = useState("");

  useEffect(() => {
    if (floorPlans && peopleStats) {
      var names = [];
      var values = [];
      floorPlans
        .map((floor) => {
          var employeesCount = peopleStats.floor_employees
            ? peopleStats.floor_employees[floor.id] || 0
            : 0;
          var visitorsCount = peopleStats.floor_visitors
            ? peopleStats.floor_visitors[floor.id] || 0
            : 0;
          var guestsCount = peopleStats.floor_guests
            ? peopleStats.floor_guests[floor.id] || 0
            : 0;
          const count = employeesCount + visitorsCount + guestsCount;
          return { name: floor.name, count: count };
        })
        .filter((val) => {
          return val.count > 0;
        })
        .sort((a, b) => {
          return a.count > b.count;
        })
        .forEach((val) => {
          names.push(val.name);
          values.push(val.count);
        });

      setGraphData({ names, values });

      var i = values.indexOf(Math.max(...values));
      setFloorMaxOcup(names[i]);
    }
  }, [floorPlans, peopleStats]);

  return (
    <Card>
      <CardHeader
        title='Floor ocuppancy'
        subtitle={`Busiest Floor:  ${floorMaxOcup}`}
      />
      <CardBody style={{ height: "350px" }}>
        <Chart
          options={getChartOptions(graphData?.names || [])}
          series={graphData?.values || []}
          type='polarArea'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(labels) {
  return {
    chart: {
      type: "polarArea",
      height: "100%",
    },
    labels: labels,
    noData: {
      text: "No data to display.",
    },
    colors: [
      "#242473",
      "#323389",
      "rgba(50, 51, 137, 0.75)",
      "rgba(50, 51, 137, 0.5)",
      "#EFEFFD",
    ],
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: (val) => val.toFixed(0),
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
  };
}
