import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const FailureStepsChartZones = async (queryParams) => {
  //   console.log(queryParams, "SNRFloorsChart queryParams");

  //   const netFailuresSeries = [];
  //   const netFailuresData = await getFloorNetFailuresPerDay(queryParams)
  //     .then((response) => response && response.json())
  //     .then((result) => {
  //       // console.log(result, "result 2");
  //       const netFailuresParsed = netFailuresFloorsToGraphData({
  //         result,
  //         queryParams,
  //       });
  //       // .then((res) => {
  //       //   console.log("🚀 ~ res", res);
  //       //   netFailuresSeries.push(res);
  //       // });
  //       return netFailuresParsed;
  //     });

  //   netFailuresSeries.push(netFailuresData);

  return getZonesFailureStepsPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = badSNRFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(netFailuresSeries, "netFailuresSeries 2");
      // console.log(data, "data SNRFloorsChart");

      let options = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
          stacked: true,
          stackType: "100%",
        },
        colors: [
          "#323389",
          "#882e8c",
          "#c7297c",
          "#f1425f",
          "#ff713b",
          "#ffa600",
        ],

        noData: {
          text: "Loading...",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='bar' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        // console.log(data, "data");

        const dataObject = data && data[0];
        const xAxis = dataObject.zones.map((z) => z);
        // console.log("🚀 ~ xAxis", xAxis);

        options = {
          ...options,
          xaxis: {
            type: "category",
            categories: xAxis,
            labels: {
              style: {
                fontWeight: 500,
              },
            },
          },
          noData: {
            text: "No data to display.",
          },
        };

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={dataObject.series || []}
              type='bar'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch Failure Steps data", error);
      error.clientMessage = "Can't fetch Failure Steps data";
    });
};

// API fetch SNR
function getZonesFailureStepsPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "zonesGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/netFailures/${queryParams.customerId}`,
  });
}

// Parse result SNR
export const badSNRFloorsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  //   console.log("🚀 ~ result", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );
    if (val && val.zone) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        zoneName: val.zone ? val.zone : "",
        failures: val.failures ? val.failures : "",
        failureSteps: val.failureSteps ? val.failureSteps : {},
        assoc: val.failureSteps.assoc ? val.failureSteps.assoc : 0,
        auth: val.failureSteps.auth ? val.failureSteps.auth : 0,
        dhcp: val.failureSteps.dhcp ? val.failureSteps.dhcp : 0,
        dns: val.failureSteps.dns ? val.failureSteps.dns : 0,
      });
    }
  });

  //   console.log("🚀 ~ data", data);

  const zones = [];
  const assoc = [];
  const auth = [];
  const dhcp = [];
  const dns = [];

  data &&
    data.forEach((d) => {
      zones.push(d.zoneName);
      assoc.push(d.assoc);
      auth.push(d.auth);
      dhcp.push(d.dhcp);
      dns.push(d.dns);
    });

  const newSeriesFinal = [
    { name: "ASSOC", data: assoc },
    { name: "AUTH", data: auth },
    { name: "DHCP", data: dhcp },
    { name: "DNS", data: dns },
  ];
  //   console.log("🚀 ~ newSeriesFinal", newSeriesFinal);

  const finalResult = [{ series: newSeriesFinal, zones: zones }];

  return finalResult;
};
