import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import firebase from "firebase/app";
import { Snackbar } from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";

const ProductEditSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  //const { intl } = props;
  const [loading, setLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  async function signInSAML(values) {
    setLoading(true);
    var domain = values.email.substring(values.email.lastIndexOf("@") + 1);
    var snapshot = await firebase
      .firestore()
      .collection("LoginData")
      .where("domain", "==", domain)
      .get();
    var customerData = snapshot.docs[0]?.data();

    if (customerData?.services?.SAML) {
      firebase.auth().tenantId = customerData.tenantID;

      const provider = new firebase.auth.SAMLAuthProvider(
        customerData.SAML.ProviderID
      );

      var result = await firebase
        .auth()
        .signInWithPopup(provider)
        .catch((error) => console.log("Error: ", error));

      if (result.additionalUserInfo.isNewUser) {
        await firebase
          .auth()
          .currentUser.updateProfile({
            displayName: result.additionalUserInfo.profile?.displayName,
            phoneNumber: result.additionalUserInfo.profile?.phoneNumber,
          })
          .catch((error) => console.log("Error: ", error));

        const customerClaims = {
          isUser: true,
          customerId: customerData.customerId,
        };

        firebase
          .firestore()
          .doc(`user-claims/${result.user.uid}`)
          .set(customerClaims)
          .then(() => {
            props.login(result.user.uid);
            setLoading(false);
          });
      } else {
        setLoading(false);
        props.login(result.user.uid);
      }
    } else {
      setLoading(false);
      setShowPass(true);
    }
  }

  function signIn(values) {
    setLoading(true);
    var domain = values.email.substring(values.email.lastIndexOf("@") + 1);

    login(values.email, values.password, domain)
      .then((user) => {
        //  console.log("User: ", user.user)
        props.login(user.user.uid);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setSnackBarOpen(true);
        setLoading(false);
      });
  }

  function handleClose() {
    setSnackBarOpen(false);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackBarOpen}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleClose}
          severity='error'
        >
          Email or password incorrect!
        </MuiAlert>
      </Snackbar>
      <div className='login-form login-signin' id='kt_login_signin_form'>
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1'>
            <FormattedMessage id='AUTH.LOGIN.TITLE' />
          </h3>
          <p className='text-muted font-weight-bold'>
            Enter your username and password
          </p>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={ProductEditSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            !showPass ? signInSAML(values) : signIn(values);
          }}
        >
          {() => (
            <>
              <Form className='form form-label-right'>
                <div className='form-group row'>
                  <div className='col-lg-12'>
                    <Field
                      id='emailLoginInput'
                      name='email'
                      component={Input}
                      placeholder='Email'
                      label='Email'
                    />
                  </div>
                </div>
                {showPass && (
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='password'
                        component={Input}
                        placeholder='Password'
                        label='Password'
                        type='password'
                      />
                    </div>
                  </div>
                )}
                <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                  <Link
                    to='/auth/forgot-password'
                    className='text-dark-50 text-hover-primary my-3 mr-2'
                    id='kt_login_forgot'
                  >
                    <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
                  </Link>
                  <button
                    id='kt_login_signin_submit'
                    type='submit'
                    className={`btn font-weight-bold px-9 py-4 my-3`}
                    style={{ backgroundColor: "#323389" }}
                  >
                    <span style={{ color: "white" }}>Sign In</span>
                    {loading && (
                      <span className='ml-3 spinner spinner-white'></span>
                    )}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/
/*
const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [openPoper, setPoper] = useState(false)
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  /*
    const formik = useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        enableLoading();
        setTimeout(() => {
          login(values.email, values.password)
            .then(async () => {
              authFirebase.onAuthStateChanged((user) => {
                disableLoading();
                console.log(user);
                if (user) {
                  props.login(user.uid);
                } else {
                  setPoper(true)
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_LOGIN",
                    })
                  );
                }
              });
            })
            .catch(() => {
              console.log("login failure");
              disableLoading();
              setSubmitting(false);
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            });
        }, 500);
      },
    });
  *
   const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((user) => {
            disableLoading();
            console.log(user.user.uid)
            props.login(user.user.uid);
          })
          .catch(() => {
            disableLoading();
            setPoper(true)
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 600);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head *}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head *}

      {/*begin::Form*}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text ">
                Use account <strong>admin@demo.com</strong> and password{" "}
                <strong>demo</strong> to continue.
            </div>
            </div>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
*/
