import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const NetFailuresEachZoneChart = (queryParams) => {
  //   console.log(queryParams, "SNRFloorsChart queryParams");

  return getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = netFailuresEachZoneToGraphData({
        result,
        queryParams,
      });

      let options = {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 6,
        },
        colors: [
          "#323389",
          "#882e8c",
          "#c7297c",
          "#f1425f",
          "#ff713b",
          "#ffa600",
        ],
        noData: {
          text: "Loading...",
        },
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='line' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        // console.log(data, "data when have data ");

        const xAxisDay = {
            type: "category",
            categories: [
              "00h",
              "01h",
              "02h",
              "03h",
              "04h",
              "05h",
              "06h",
              "07h",
              "08h",
              "09h",
              "10h",
              "11h",
              "12h",
              "13h",
              "14h",
              "15h",
              "16h",
              "17h",
              "18h",
              "19h",
              "20h",
              "21h",
              "22h",
              "23h",
            ],
            tickAmount: 5,
            labels: {
              rotate: 0,
              style: {
                colors: ["#626768"],
              },
            },

            noData: {
              text: "No data to display.",
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
          },
          options =
            queryParams.timeframe === "day"
              ? {
                  //   ...options,
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  labels: data?.labels || [],
                  xaxis: xAxisDay,
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function(
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return value;
                      },
                    },
                  },
                  colors: [
                    "#323389",
                    "#882e8c",
                    "#c7297c",
                    "#f1425f",
                    "#ff713b",
                    "#ffa600",
                  ],
                  yaxis: {
                    show: true,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  //   responsive: [
                  //     {
                  //       breakpoint: 1350,
                  //       // breakpoint: 680,
                  //       options: {
                  //         xaxis: {
                  //           overwriteCategories: [
                  //             "00h",
                  //             "04h",
                  //             "08h",
                  //             "12h",
                  //             "16h",
                  //             "20h",
                  //           ],
                  //           labels: {
                  //             show: true,
                  //             hideOverlappingLabels: true,
                  //           },
                  //         },
                  //       },
                  //     },
                  //   ],
                  noData: {
                    text: "No data to display.",
                  },
                }
              : {
                  // ...options,
                  colors: [
                    "#323389",
                    "#882e8c",
                    "#c7297c",
                    "#f1425f",
                    "#ff713b",
                    "#ffa600",
                  ],
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  labels: data?.labels || [],
                  xaxis: {
                    type: "category",
                    categories: data?.labels || [],
                    // tickPlacement: "between", // This gives margins between xAxis
                    labels: {
                      style: {
                        colors: data.color,
                      },
                    },
                  },
                  yaxis: {
                    show: true,
                    // min: 0,
                    // max: 10,
                    // tickAmount: 5,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  //   responsive: [
                  //     {
                  //       breakpoint: 1350,
                  //       options: {},
                  //     },
                  //   ],
                  noData: {
                    text: "No data to display.",
                  },
                };

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={data.series || []}
              type='line'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch SNR individual zone", error);
      error.clientMessage = "Can't fetch SNR individual zone";
    });
};

// API fetch SNR
function getFloorNetFailuresPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  if (queryParams.timeframe === "day") {
    return fetchAPI({
      typechart: "zonesHour",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/netFailures/${queryParams.customerId}`,
    });
  } else {
    return fetchAPI({
      typechart: "zonesDay",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/netFailures/${queryParams.customerId}`,
    });
  }
}

// Parse result SNR
export const netFailuresEachZoneToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  //   console.log("🚀 ~ result", result);

  const data = [];
  const mondaysTotal = [];
  const tuesdaysTotal = [];
  const wednesdaysTotal = [];
  const thursdaysTotal = [];
  const fridaysTotal = [];
  const saturdaysTotal = [];
  const sundaysTotal = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        zone: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Mon")) {
      mondaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Tue")) {
      tuesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Wed")) {
      wednesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Thu")) {
      thursdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Fri")) {
      fridaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Sat")) {
      saturdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
    if (val.date.includes("Sun")) {
      sundaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
        name: val.zone ? val.zone : "",
      });
    }
  });

  //   console.log("🚀 ~ data", data);

  if (queryParams.timeframe === "day" || queryParams.timeframe === "week") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.zone]) {
        obj[curr.zone] = [];
      }
      obj[curr.zone][dates.indexOf(curr.date)] = obj[curr.zone][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.zone][dates.indexOf(curr.date)] <
            curr.count.toFixed(2) * 1 && curr.count.toFixed(2) * 1
        : curr.count.toFixed(2) * 1;
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    // console.log("🚀 ~ dates", dates);
    // console.log(productTotals, "productTotals");
    // console.log(series, "series");

    const finalSeries = series && series.filter((s) => s.name !== "");
    // console.log("🚀 ~ finalSeries", finalSeries);

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: finalSeries,
      color: color,
    };
  }

  if (queryParams.timeframe === "month") {
    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    // console.log(sundaysTotal, "sundaysTotal");
    // console.log(sundayData, "sundayData");

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.zone)) {
        finalSeries.push({
          name: d.zone,
          data: [
            mondayData.find((val) => val.name === d.zone)?.value || 0,
            tuesdayData.find((val) => val.name === d.zone)?.value || 0,
            wednesdayData.find((val) => val.name === d.zone)?.value || 0,
            thursdayData.find((val) => val.name === d.zone)?.value || 0,
            fridayData.find((val) => val.name === d.zone)?.value || 0,
            saturdayData.find((val) => val.name === d.zone)?.value || 0,
            sundayData.find((val) => val.name === d.zone)?.value || 0,
          ],
        });
      }
    });

    // console.log("🚀 ~ finalSeries", finalSeries);

    const finalSeriesFiltered =
      finalSeries && finalSeries.filter((s) => s.name !== "");
    // console.log("🚀 ~ finalSeriesFiltered", finalSeriesFiltered);

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeriesFiltered,
      color: [
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#F9CE1D",
        "#2ec4b6",
      ],
    };
  }
};

const weekdaysSumFloors = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.name === d.name)) {
      const index = holderOne.findIndex((val) => val.name === d.name);

      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        name: d.name,
        count: d.count,
        counter: 1,
      });
    }
  });
  return holderOne.map((val, i) => {
    return { name: val.name, value: (val.count / val.counter).toFixed(0) * 1 };
    // return { name: val.name, value: Math.round(val.count / val.counter) };
  });
};

// function sumProperty(array) {
//   const sum = array.reduce((accumulator, object) => {
//     return accumulator + object.count;
//   }, 0);
//   return sum;
// }

// const getAvgCount = (array) => {
//   if (!array) {
//     return null;
//   } else {
//     const total = array.reduce((acc, c) => acc + c.count, 0);
//     const average = total / array.length;
//     return average;
//   }
// };

// const sumFloors = (array) => {
//   let holderOne = [];
//   array.forEach(function(d) {
//     if (holderOne.some((val) => val.date === d.date)) {
//       const index = holderOne.findIndex((val) => val.date === d.date);

//       holderOne[index] = {
//         ...holderOne[index],
//         count: holderOne[index].count + d.count,
//         counter: holderOne[index].counter + 1,
//       };
//     } else {
//       holderOne.push({
//         date: d.date,
//         location: d.location,
//         count: d.count,
//         counter: 1,
//       });
//     }
//   });
//   return holderOne.map((val, i) => {
//     return {
//       date: val.date,
//       location: val.location,
//       count: (val.count / val.counter).toFixed(2) * 1,
//     };
//   });
// };
