import React, { Suspense, lazy, useEffect, useState } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import { LayoutSplashScreen, ContentRoute } from "./layout"
import { BuilderPage } from "./pages/BuilderPage"
import { MyPage } from "./pages/MyPage"
import { DashboardPage } from "./pages/DashboardPage"
import { ReportingPage } from "./modules/Reporting/ReportingPage"
import { BookingPage } from "./modules/Booking/BookingPage"
import { PlatformUsersCard } from "./pages/platform-users/PlatformUsersCard"
import { Employees } from "./pages/employees/Employees"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import SnackBar from "./globalUI/snackBar/SnackBar"
import * as actions from "./redux/basePage/basePageActions"
import { basePageSlice } from "./redux/basePage/basePageSlice"
import ReactGa from "react-ga"
import firebase from "firebase"
import { firestore } from "../firebase"
import moment from "moment"

import { MapTestPage } from "./modules/Test/MapTestPage"
import Demo from "./Demo"

const AdminPage = lazy(() => import("./modules/Admin/AdminPage"))
const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"))

export default function BasePage() {
  const dispatch = useDispatch()

  const { userClaims, selectedCustomer, selectedLocation, selectedLevel, user, resources } = useSelector(
    state => ({
      userClaims: state.auth.claims,
      selectedCustomer: state.profile?.currentCustomer,
      selectedLocation: state.profile?.currentLocation,
      selectedLevel: state.profile?.currentLevel,
      user: state.auth?.user,
      resources: state.basePage?.resources,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (selectedCustomer && selectedLocation) {
      dispatch(
        actions.fetchFloorPlans({
          customerId: selectedCustomer.customerId,
          locationId: selectedLocation.netId,
        })
      )
      dispatch(
        actions.fetchZones({
          customerId: selectedCustomer.customerId,
          locationId: selectedLocation.netId,
        })
      )
    }
  }, [selectedCustomer, selectedLocation])

  useEffect(() => {
    if (selectedCustomer && user) {
      dispatch(
        actions.fetchDepartments({
          customerId: selectedCustomer.customerId,
          userId: user.id,
        })
      )
    }
  }, [selectedCustomer, user])

  useEffect(() => {
    if (selectedCustomer && selectedLocation) {
      dispatch(
        actions.fetchResources({
          customer: selectedCustomer.customerId,
          location: selectedLocation.netId,
        })
      )
    }
  }, [selectedCustomer, selectedLocation])

  useEffect(() => {
    if (selectedCustomer && resources) {
      const startOfMonth = moment(firebase.firestore.Timestamp.now().toDate())
        .startOf("month")
        .toDate()
      const endOfMonth = moment(firebase.firestore.Timestamp.now().toDate())
        .endOf("month")
        .add(1, "month")
        .toDate()

      Promise.all(
        resources.map(val => {
          return firestore
            .collection(`Customers/${selectedCustomer.customerId}/Bookings`)
            .where("deleted", "==", false)
            .where("resource", "==", val.id)
            .where("date", ">=", startOfMonth)
            .where("date", "<=", endOfMonth)
            .get()
        })
      ).then(results => {
        const _bookings = results
          .map(snapshot => {
            return snapshot.docs.map(docSnapshot => {
              return {
                ...docSnapshot.data(),
                id: docSnapshot.id,
              }
            })
          })
          .flat()
        dispatch(
          basePageSlice.actions.bookingsFetched({
            entities: _bookings,
          })
        )
      })
    }
  }, [selectedCustomer, resources])

  useEffect(() => {
    //Google analitycs
    ReactGa.initialize("UA-163381689-7")
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SnackBar />

      <Demo>
        {!selectedCustomer && <h3>Select Customer</h3>}
        {/* {!selectedCustomer ? <h3>Select Customer</h3> :
        {!selectedLocation ? <h3>Select Location</h3> :
          !selectedLevel && <h3>Select Level</h3> }} */}

        {selectedCustomer && (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/dashboard" />
            }
            {userClaims?.isSuper && <ContentRoute path="/maptest" component={MapTestPage} />}

            <ContentRoute path="/dashboard" component={DashboardPage} />
            <ContentRoute path="/reporting" component={ReportingPage} />
            {selectedLocation?.customerType === "office" && <ContentRoute path="/booking" component={BookingPage} />}
            <Route path="/user-profile" component={UserProfilepage} />

            {(userClaims?.isSuper || userClaims?.isAdmin) && <Route path="/admin" component={AdminPage} />}

            {userClaims?.isSuper && <ContentRoute path="/platform-users" component={PlatformUsersCard} />}

            {/* <ContentRoute path='/employees' component={Employees} />
          <ContentRoute path='/builder' component={BuilderPage} />
          <ContentRoute path='/my-page' component={MyPage} /> */}

            <Redirect to="error/error-v1" />
          </Switch>
        )}
      </Demo>
    </Suspense>
  )
}
