import moment from "moment";

function filterSSIDs(queryParams) {
  if (!queryParams.peopleType) return [];
  let SSIDs = [];
  if (queryParams.peopleType.some((val) => val.value === "visitors")) {
    SSIDs.push("");
  }
  if (queryParams.peopleType.some((val) => val.value === "employees")) {
    queryParams.locations.forEach((l) => {
      if (!l.employeeSSID) return;
      SSIDs = SSIDs.concat(l.employeeSSID);
    });
  }
  if (queryParams.peopleType.includes("guests")) {
    queryParams.locations.forEach((l) => {
      if (!l.guestSSID) return;
      SSIDs = SSIDs.concat(l.guestSSID);
    });
  }
  return SSIDs;
}

function fetchAPI(props) {
  const {
    typechart,
    locations,
    SSIDs,
    floors,
    zones,
    startDate,
    endDate,
    url,
  } = props;
  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    typechart: typechart,
    networkId: locations,
    ssid: SSIDs,
    floorPlanId: floors || [],
    zones: zones || [],
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  // console.log(raw, "raw");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`,
    requestOptions
  ).catch((err) => {
    console.log("Error: ", err);
  });
}

//★━━━━━━━━━━━━━━━━★ Floor ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getFloorPeopleDistribution(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "dailyInfo",
    locations,
    SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/floors/${queryParams.customerId}`,
  });
}

export function getFloorOccupancy(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "dailyInfo",
    locations,
    SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/floors/${queryParams.customerId}`,
  });
}

export function getTotalPeople(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "globalInfo",
    locations,
    SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/floors/${queryParams.customerId}`,
  });
}

export function getFloorAverageOccupancy(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }
  return fetchAPI({
    typechart: "globalHour",
    locations,
    SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/floors/${queryParams.customerId}`,
  });
}

//★━━━━━━━━━━━━━━━━★ Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getZoneOccupancy(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "dailyInfo",
    locations,
    SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/zones/${queryParams.customerId}`,
  });
}

export function getZoneAverageOccupancy(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "hourlyInfo",
    locations,
    SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/zones/${queryParams.customerId}`,
  });
}

export function getZonePeopleDistribution(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "dailyInfo",
    locations,
    SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/zones/${queryParams.customerId}`,
  });
}

export function getAvgTimeSpent(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "zonesDay",
    locations,
    SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/avgtime/${queryParams.customerId}`,
  });
}

export function getZoneOccupancyForChord(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => {
    return location.netId;
  });
  const floors = queryParams.floors.map((floor) => {
    return floor.floorId;
  });
  const zones = queryParams.zones.map((zone) => {
    return zone.name;
  });

  const SSIDs = filterSSIDs(queryParams);
  // if (SSIDs === null || SSIDs.length === 0) {
  //   return Promise.resolve(null);
  // }

  return fetchAPI({
    typechart: "chordDependencyDiagram",
    locations,
    floors,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/customGraphs/${queryParams.customerId}`,
  });
}

export function getZoneOccupancyForSankey(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => {
    return location.netId;
  });
  const floors = queryParams.floors.map((floor) => {
    return floor.floorId;
  });
  const zones = queryParams.zones.map((zone) => {
    return zone.name;
  });

  const SSIDs = filterSSIDs(queryParams);
  // if (SSIDs === null || SSIDs.length === 0) {
  //   return Promise.resolve(null);
  // }

  return fetchAPI({
    typechart: "fromTo",
    locations,
    floors,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/peopleFlow/${queryParams.customerId}`,
  });
}
