import React from "react"
import { Route } from "react-router-dom"
import { UIProvider } from "./UIContext"
import { MapTestCard } from "./MapTestCard"
import { CalendarModal } from "./calendar/Calendar"

const mainRoute = "/maptest"

export const MapTestPage = ({ history }) => {
  const UIEvents = {
    openResourceCalendar: (id) => {
      history.push(`${mainRoute}/${id}/calendar`);
    }
  }

  return (
    <UIProvider UIEvents={UIEvents}>
      <Route path={`${mainRoute}/:id/calendar`}>
        {({ history, match }) => (
          <CalendarModal
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(mainRoute)
            }}
          />
        )}
      </Route>
      <MapTestCard />
    </UIProvider>
  )
}