import React, { useState } from "react"
import Select  from 'react-select'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'

export function DropdownSelect({ dataFilter, setDataFilter, options }) {
   const [isOpen, setIsOpen] = useState(false)

   const toggleOpen = () => {
      setIsOpen(!isOpen)
   }

   const onSelectChange = (value) => {
      toggleOpen()
      setDataFilter(value)
   }

   const Dropdown = ({ children }) => {
      const shadow = 'hsla(218, 50%, 10%, 0.1)';
      return <div style={{ position: 'relative' }}>
         <div className="row m-0" onClick={toggleOpen} style={{ cursor: "pointer" }}>
            <span>{dataFilter.label}</span>
            <KeyboardArrowDownOutlinedIcon />
         </div>
         {/* <button
         //iconAfter={<ChevronDown />}
         onClick={toggleOpen}
      //isSelected={isOpen}
      >
         {value ? `State: ${value.label}` : 'Select a State'}
      </button> */}
         {isOpen ? <div
            style={{
               backgroundColor: 'white',
               borderRadius: 4,
               boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
               marginTop: 8,
               position: 'absolute',
               zIndex: 2,
            }}
         >{children}</div> : null}
         {isOpen ? <div
            style={{
               bottom: 0,
               left: 0,
               top: 0,
               right: 0,
               position: 'fixed',
               zIndex: 1,
            }}
            onClick={toggleOpen}
         /> : null}
      </div>
   }

   const Control = ({ children }) => {
      return <div style={{ display: "none" }}>
         {children}
      </div>
   }

   const Menu = ({ children }) => {
      return <div style={{ display: "contents" }}>
         {children}
      </div>
   }

   return <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
   >
      <Select
         autoFocus
         backspaceRemovesValue={false}
         components={{
            KeyboardArrowDownOutlinedIcon,
            Control: Control,
            Menu: Menu,
            IndicatorSeparator: null
         }}
         controlShouldRenderValue={false}
         hideSelectedOptions={false}
         isClearable={false}
         menuIsOpen
         isSearchable={false}
         onChange={onSelectChange}
         options={options}
         placeholder="Search..."
         tabSelectsValue={false}
         value={dataFilter}
      />
   </Dropdown>
}