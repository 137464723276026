import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Grid from '@material-ui/core/Grid';

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

const propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired
};

export default class Toolbar extends Component {
    static propTypes = propTypes;
    render() {
        let {
            localizer: { messages },
            label,
            //date
        } = this.props;

        return (
            <Grid container style={{ paddingBottom: "10px" }}>
                <Grid container item xs>
                    <ButtonGroup style={{ marginRight: "10px" }}>
                        <Button onClick={this.navigate.bind(null, navigate.TODAY)}>
                            {messages.today}
                        </Button>
                        <Button onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
                            <ArrowLeftIcon />
                        </Button>
                        <Button onClick={this.navigate.bind(null, navigate.NEXT)}>
                            <ArrowRightIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid container item xs justify="center">
                    <div>
                        <h6>{label}</h6>
                    </div>
                </Grid>
                <Grid container item xs justify="flex-end">
                    <ButtonGroup className="pull-right">
                        {this.viewNamesGroup(messages)}
                    </ButtonGroup>
                </Grid>

                {/*<ToolbarDateHeader date={date} onChange={this.toThisDay}>
                    {label}
                </ToolbarDateHeader>*/}
                {/*<TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={date}
                    //className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.toThisDay}
                />*/}

            </Grid>
        );
    }

    toThisDay = date => {
        this.props.onView('day');
        // give it just a tick to 'set' the view, prior to navigating to the proper date
        setTimeout(() => {
            this.props.onNavigate(navigate.DATE, date);
        }, 100);
    };

    navigate = action => {
        this.props.onNavigate(action);
    };

    view = view => {
        this.props.onView(view);
    };

    viewNamesGroup(messages) {
        let viewNames = this.props.views;
        const view = this.props.view;

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <Button
                    key={name}
                    className={cn({
                        active: view === name,
                        'btn-primary': view === name
                    })}
                    onClick={this.view.bind(null, name)}
                >
                    {messages[name]}
                </Button>
            ));
        }
    }
}