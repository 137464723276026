import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
   listLoading: false,
   actionsLoading: false,
   floorPlans: null,
   lastError: null,
};
export const callTypes = {
   list: "list",
   action: "action",
   dailyPeople: "dailyPeople",
   avgTime: "avgTime",
};

export const historicalDataSlice = createSlice({
   name: "historicalData",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`;
         switch (action.payload.callType) {
            case callTypes.list:
               state.listLoading = false;
               break
            case callTypes.action:
               state.actionsLoading = false;
               break
            case callTypes.dailyPeople:
               state.dailyPeopleLoading = false;
               break
            case callTypes.avgTime:
               state.avgTimeLoading = false;
               break
            default:
               state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         state.error = null;
         switch (action.payload.callType) {
            case callTypes.list:
               state.listLoading = true;
               break
            case callTypes.action:
               state.actionsLoading = true;
               break
            case callTypes.dailyPeople:
               state.dailyPeopleLoading = true;
               break
               case callTypes.avgTime:
                  state.avgTimeLoading = true;
                  break
            default:
               state.actionsLoading = true;
         }
      },
      dayFloorDataFetched: (state, action) => {
         const { entities } = action.payload
         state.listLoading = false
         state.error = null
         state.dayFloorData = entities
      },
      weekFloorDataFetched: (state, action) => {
         const { entities } = action.payload
         state.listLoading = false
         state.error = null
         state.weekFloorData = entities
      },
      monthFloorDataFetched: (state, action) => {
         const { entities } = action.payload
         state.listLoading = false
         state.error = null
         state.monthFloorData = entities
      },
      dayZoneDataFetched: (state, action) => {
         const { entities } = action.payload
         state.actionsLoading = false
         state.error = null
         state.dayZoneData = entities
      },
      weekZoneDataFetched: (state, action) => {
         const { entities } = action.payload
         state.actionsLoading = false
         state.error = null
         state.weekZoneData = entities
      },
      monthZoneDataFetched: (state, action) => {
         const { entities } = action.payload
         state.actionsLoading = false
         state.error = null
         state.monthZoneData = entities
      },
      weekDailyPeopleFetched: (state, action) => {
         const { entities } = action.payload
         state.dailyPeopleLoading = false
         state.error = null
         state.weekDailyPeople = entities
      },
      monthDailyPeopleFetched: (state, action) => {
         const { entities } = action.payload
         state.dailyPeopleLoading = false
         state.error = null
         state.monthDailyPeople = entities
      },
      weekAvgTimeFetched: (state, action) => {
         const { entities } = action.payload
         state.avgTimeLoading = false
         state.error = null
         state.weekAvgTime = entities
      },
      monthAvgTimeFetched: (state, action) => {
         const { entities } = action.payload
         state.avgTimeLoading = false
         state.error = null
         state.monthAvgTime = entities
      },
   },
})
