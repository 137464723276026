import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Tab, Tabs } from "@material-ui/core"

export default function LeftCard({ navigation, id, formData }) {
  const [state, setState] = useState(id)

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        formData.completedStep >= 0 && navigation.go("setupOrg")
        break
      case 1:
        formData.completedStep >= 1 && navigation.go("secondStep")
        break
      case 2:
        formData.completedStep >= 2 && navigation.go("fourthStep")
        break
      case 3:
        formData.completedStep >= 3 && navigation.go("fifthStep")
        break
      case 4:
        formData.completedStep >= 4 && navigation.go("sixthStep")
        break
      case 5:
        formData.completedStep >= 5 && navigation.go("sevenStep")
        break
      default:
        break
    }
  }

  useEffect(() => {
    switch (id) {
      case "setupOrg":
        setState(0)
        break
      case "secondStep":
        setState(1)
        break
      case "fourthStep":
        setState(2)
        break
      case "fifthStep":
        setState(3)
        break
      case "sixthStep":
        setState(4)
        break
      case "sevenStep":
        setState(5)
        break
      case "validation":
        setState(6)
        break
      default:
        break
    }
  }, [id])

  return (
    <Card margin={false}>
      {/* <CardHeader title="Create customer" /> */}
      <CardBody>
        <Tabs
          value={state}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
        >
          <Tab label="Setup Org" disabled={formData.completedStep >= 0 ? false : true} />
          <Tab label="Select location" disabled={formData.completedStep >= 1 ? false : true} />
          <Tab label="Choose SSIDs" disabled={formData.completedStep >= 2 ? false : true} />
          <Tab label="Assign levels" disabled={formData.completedStep >= 3 ? false : true} />
          <Tab label="Validator" disabled={formData.completedStep >= 4 ? false : true} />
          <Tab label="Review" disabled={formData.completedStep >= 5 ? false : true} />
          <Tab label="Validation" disabled />
        </Tabs>
      </CardBody>
    </Card>
  )
}
