import React, { useState, useEffect, useMemo, forwardRef } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import moment from "moment"
import DatePicker from "react-datepicker"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { Button } from "../../../_partials/Button"
import { Header3 } from "../../../_partials/typography/Header3"
import { IconButton, makeStyles } from "@material-ui/core"
import "./Timeline.css"

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 5,
  },
}))

export function Timeline() {
  const classes = useStyles()

  const UIContext = useUIContext()
  const UIProps = useMemo(() => {
    return {
      openBookModel: UIContext.openBookModel,
      openBookModelWithBookingId: UIContext.openBookModelWithBookingId,
    }
  }, [UIContext])

  //━━━ Get data from redux ━━━\\
  const { bookings, resources, user, users } = useSelector(
    state => ({
      bookings: state.booking?.bookings,
      resources: state.booking?.resources,
      user: state.auth?.user,
      users: state.booking?.users,
    }),
    shallowEqual
  )

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  const [graphData, setGraphData] = useState([])
  const [date, setDate] = useState(new Date())
  const [editBooking, setEditBooking] = useState()

  useEffect(() => {
    if (users && bookings) {
      var bookingsByResource = []
      users.forEach(val => {
        const bookingsByDate =
          (bookings &&
            bookings.filter(bookingValue => {
              const bookingDate = moment(bookingValue.date.toDate()).startOf("day")
              const bookingEndDate = moment(bookingValue.end.toDate()).startOf("day")
              const selectedDate = moment(date).startOf("day")
              return bookingDate.isSame(selectedDate) || bookingEndDate.isSame(selectedDate)
            })) ||
          []

        bookingsByResource = bookingsByResource.concat(
          resources.map(resource => {
            const booksFiltered = bookingsByDate.filter(e => e.created.id === val.uid && e.resource === resource.id)
            return {
              name: `${resource.id}/${resource.name}`,
              data:
                (booksFiltered &&
                  booksFiltered.map(v => {
                    return {
                      bookingId: v.id,
                      x: `${val.uid}/${val?.displayName || val?.email || "Unkown user"}`,
                      y: [new Date(v.start.toDate()).getTime(), new Date(v.end.toDate()).getTime()],
                    }
                  })) ||
                [],
            }
          })
        )
      })

      const data = bookingsByResource.filter(val => val.data.length > 0)
      setGraphData(data)
    }
  }, [resources, bookings, users, date])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="row align-items-center" ref={ref}>
        <IconButton
          className={classes.iconButton}
          aria-label="Previous day"
          onClick={() =>
            setDate(
              moment(date)
                .subtract(1, "days")
                .toDate()
            )
          }
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <div onClick={onClick} className="timelineDateButton">
          <CalendarTodayOutlinedIcon />
          <span style={{ marginLeft: "2px" }}>
            {moment(value).isSame(moment().startOf("day"))
              ? "Today"
              : moment(value).isSame(
                  moment()
                    .add(1, "days")
                    .startOf("day")
                )
              ? "Tomorrow"
              : moment(value).isSame(
                  moment()
                    .subtract(1, "days")
                    .startOf("day")
                )
              ? "Yesterday"
              : moment(value).format("MMM D")}
          </span>
        </div>
        <IconButton
          className={classes.iconButton}
          aria-label="Next day"
          onClick={() =>
            setDate(
              moment(date)
                .add(1, "days")
                .toDate()
            )
          }
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    )
  })

  useEffect(() => {
    const bookingId = editBooking && graphData[editBooking[0]].data[editBooking[1]].bookingId
    const bookingToEdit = bookings && bookings.find(val => val.id === bookingId)
    if (editBooking && bookingToEdit?.created.id === user?.id) {
      UIProps.openBookModelWithBookingId(bookingId)
      setEditBooking()
    }
  }, [editBooking])

  return (
    <>
      <Header3 text="Booking timeline" />
      <Card className="mt-4" padding={false}>
        <div className="row justify-content-between align-items-center m-0 pt-5 pr-5 pl-8 pb-0">
          <DatePicker selected={date} onChange={date => setDate(date)} customInput={<ExampleCustomInput />} />
          <Button text="ADD BOOKING" onClick={UIProps.openBookModel} />
        </div>
        <CardBody>
          <Chart options={getChartOptions(date, setEditBooking)} series={graphData} type="rangeBar" height={350} />
        </CardBody>
      </Card>
    </>
  )
}

function getChartOptions(date, setEditBooking) {
  return {
    chart: {
      height: 350,
      type: "rangeBar",
      toolbar: { show: false },
      zoom: { enabled: false },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          setEditBooking([config.seriesIndex, config.dataPointIndex])
        },
        dataPointMouseEnter: event => {
          event.target.style.cursor = "pointer"
        },
        dataPointMouseLeave: event => {
          event.target.style.cursor = "default"
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "30%",
      },
    },
    noData: {
      text: "No bookings for today",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    grid: {
      padding: {
        right: 25,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "HH:mm",
      },
      y: {
        formatter: () => {
          return ""
        },
        title: {
          formatter: val => {
            const name = typeof val === "string" ? val.split("/")[1] : ""
            return name
          },
        },
      },
    },
    xaxis: {
      type: "datetime",
      position: "top",
      min: moment(date)
        .startOf("day")
        .toDate()
        .getTime(),
      max: moment(date)
        .endOf("day")
        .toDate()
        .getTime(),
      labels: {
        datetimeUTC: false,
      },
      /* labels: {
        formatter: val => {
          if (
            val === 0 ||
            val === 1 ||
            val === 2 ||
            val === 3 ||
            val === 4 ||
            val === 5
          ) {
            return
          }
          return moment(val).format("HH:mm")
        },
      }, */
    },
    yaxis: {
      labels: {
        formatter: val => {
          const name = typeof val === "string" ? val.split("/")[1] : ""
          return name
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      formatter: val => {
        const name = typeof val === "string" ? val.split("/")[1] : ""
        return name
      },
    },
  }
}
