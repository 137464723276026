import React from "react"
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"
import DateFnsUtils from '@date-io/date-fns';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
} from '@material-ui/pickers';

const getFieldCSSClasses = (touched, errors) => {
   const classes = ["form-control"]
   if (touched && errors) {
      classes.push("is-invalid")
   }

   if (touched && !errors) {
      classes.push("is-valid")
   }

   return classes.join(" ")
}

export function OldDate({
   field,
   form,//: { touched, errors },
   label,
   withFeedbackLabel,
   customFeedbackLabel,
   type = "text",
   inputVariant = "outlined",
   autoOk = false,
   ...props
}) {
   return <>
      {label && <b>{label}</b>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <KeyboardDatePicker
            autoOk={autoOk}
            style={{ margin: 0 }}
            size="small"
            margin="normal"
            inputVariant={inputVariant}
            variant="inline"
            format="yyyy/MM/dd"
            //className={getFieldCSSClasses(touched[field.name], errors[field.name])}
            {...field}
            {...props}
         />
      </MuiPickersUtilsProvider>
      <br />
      {withFeedbackLabel && (
         <FieldFeedbackLabel
            error={form.errors[field.name]}
            touched={form.touched[field.name]}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
         />
      )}
   </>
}
