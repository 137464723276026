import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../UIContext";
import * as actions from "../../_redux/reporting/reportingActions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Chart from "react-apexcharts";
import { LinearProgress } from "@material-ui/core";

export function AllBookings() {
  const dispatch = useDispatch();

  const [graphData, setGraphData] = useState();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
    };
  }, [UIContext]);

  const { isLoading, customer, allBookings } = useSelector(
    (state) => ({
      isLoading: state.reporting.listLoading,
      customer: state.profile?.currentCustomer,
      allBookings: state.reporting.allBookings,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      actions.fetchAllBookings({
        customer: customer,
        queryParams: UIProps.queryParams,
      })
    );
  }, [customer, UIProps.queryParams, dispatch]);

  useEffect(() => {
    if (allBookings) {
      let series = [];

      // console.log(allBookings, "allBookings");
      if (UIProps.queryParams.timeframe.includes("day")) {
        allBookings.series.forEach((val) => {
          series.push({
            name: val.name,
            type: "column",
            data: val.data,
          });
        });
        setGraphData({
          series: series,
          labels: allBookings.labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              //   return moment(val).format("ddd MMM D");
              // },
              style: {
                colors: allBookings.color,
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
            range: 3 * 24 * 3600000,
          },
        });
        // console.log(series, "series");
      }
      if (UIProps.queryParams.timeframe.includes("week")) {
        allBookings.series.forEach((val) => {
          series.push({ name: val.name, type: "column", data: val.data });
        });
        setGraphData({
          series: series,
          labels: allBookings.labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              // moment(val).format("ddd MMM D"),
              style: {
                colors: allBookings.color,
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
          },
        });
        // console.log(series, "series");
      }
      if (UIProps.queryParams.timeframe.includes("month")) {
        series = JSON.parse(JSON.stringify(allBookings.series));

        setGraphData({
          series: series,
          labels: allBookings.labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              style: {
                colors: [
                  "#626768",
                  "#626768",
                  "#626768",
                  "#626768",
                  "#626768",
                  "#2ec4b6",
                  "#F9CE1D",
                ],
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
          },
        });
      }
    } else {
      setGraphData();
    }
  }, [allBookings, UIProps.queryParams.timeframe]);

  return (
    <Card style={{ height: "480px" }}>
      <CardHeader title='All Bookings - daily' />
      <CardBody>
        {isLoading && <LinearProgress color='secondary' />}
        {graphData && graphData.series.length !== 0 ? (
          <Chart
            options={getChartOptions(graphData)}
            series={graphData.series}
            type='bar'
            height={350}
          />
        ) : (
          <p>Chart is not receiving any data to display.</p>
        )}
      </CardBody>
    </Card>
  );
}

function getChartOptions(graphData) {
  // console.log("🚀 ~ graphData", graphData);
  return {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    toolbar: {
      show: true,
    },
    stroke: {
      show: true,
      width: 0,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      position: "bottom",
      enabled: true,
      // ↓ enabledOnSeries: shows only the line chart series total
      // enabledOnSeries: [graphData.series.length - 1],
      // style: {
      //   colors: ["#546E7A"],
      // },
    },
    labels: graphData.labels,
    xaxis: graphData.xAxisRange,
    responsive: [
      {
        breakpoint: 480,
        // options: {
        //   // legend: {
        //   //   position: "bottom",
        //   //   offsetX: -10,
        //   //   offsetY: 0,
        //   // },
        // },
      },
    ],
    yaxis: {
      title: {
        text: "Bookings count",
      },
    },
  };
}
