import {createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  currentCustomer: null,
  currentLocation: null,
  currentLevel: null,
  //userProfile: null,
}

export const callTypes = {
  list: "list",
  action: "action"
}

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialCustomersState,
  reducers: {
    customerSelected: (state, action) => {
      state.currentCustomer = action.payload;
      state.lastError = state.error;
      state.error = null;
    },
    locationSelected: (state, action) => {
      state.currentLocation = action.payload;
      state.lastError = state.error;
      state.error = null;
    },
    levelSelected: (state, action) => {
      state.currentLevel = action.payload;
      state.lastError = state.error;
      state.error = null;
    },
    userProfileFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userProfile = action.payload.userProfile;
    },
    layerTypesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.currentCustomer.layerTypes = action.payload;
    },
  }
})
