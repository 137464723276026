import React, { useState, useMemo } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/booking/bookingActions"
import moment from "moment"
import firebase from "firebase"
import { Select } from "../../../_partials/inputs/Select"
import { TimeSelect } from "../../../_partials/inputs/TimeSelect"
import { Autocomplete } from "../../../_partials/inputs/Autocomplete"
import { DatePicker } from "../../../_partials/inputs/DatePicker"
import { Input } from "../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button } from "react-bootstrap"
import { useUIContext } from "../UIContext"
import { gapi } from "gapi-script"

const isEmail = value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) // or use your own
const EditSchema = Yup.object().shape({
  date: Yup.string().required("Date is required"),
  start: Yup.string()
    .required("Start date is required")
    .nullable(),
  end: Yup.string()
    .required("End date is required")
    .nullable(),
  type: Yup.string().required("Type is required"),
  resource: Yup.string()
    .required("Resource is required")
    .nullable(),
  attendees: Yup.string()
    .transform(value => {
      return Array.from(new Set(value.split(","))).join(",")
    }) // dedupe - optional step
    .test("emails", "Invalid email address", value => {
      if (!value || value?.length === 0) {
        return true
      }
      return value && value.split(",").every(isEmail)
    }),
})

export function BookModalForm({ onHide, initialValues, bookingId }) {
  const dispatch = useDispatch()

  const { user, selectedCustomer, resources, floorPlans, bookings } = useSelector(
    state => ({
      user: state.auth?.user,
      selectedCustomer: state.profile?.currentCustomer?.customerId,
      resources: state.booking?.resources,
      floorPlans: state.basePage?.floorPlans,
      bookings: state.booking?.bookings,
    }),
    shallowEqual
  )

  const UIContext = useUIContext()
  const UIProps = useMemo(() => {
    return {
      mapDate: UIContext.mapDate,
      mapSelectedResource: UIContext.mapSelectedResource,
      calendarSignedInInfo: UIContext.calendarSignedInInfo,
      setCalendarSignedInInfo: UIContext.setCalendarSignedInInfo,
    }
  }, [UIContext])

  const [saving, setSaving] = useState(false)

  const bookResource = values => {
    setSaving(true)

    const resource = resources.find(val => val.id === values.resource)
    const bookingToEdit = bookings.find(val => val.id === bookingId)
    if (bookingId) {
      dispatch(
        actions.editBooking({
          customerId: selectedCustomer,
          booking: bookingToEdit,
          bookingId: bookingId,
          resource: resource,
          date: values.date,
          start: values.start,
          end: values.end,
          summary: values.summary,
          attendees: values.attendees,
          updated: {
            id: user.id,
            email: user.email,
            date: firebase.firestore.Timestamp.now(),
          },
          created: bookingToEdit.created,
        })
      ).then(response => {
        setSaving(false)
        if (response === "busy") {
          return
        }
        onHide()
      })
      return
    }

    dispatch(
      actions.bookResource({
        customerId: selectedCustomer,
        resource: resource,
        date: values.date,
        start: values.start,
        end: values.end,
        summary: values.summary,
        attendees: values.attendees,
        created: {
          id: user.id,
          email: user.email,
          date: firebase.firestore.Timestamp.now(),
        },
      })
    ).then(response => {
      setSaving(false)
      if (response === "busy") {
        return
      }
      onHide()
    })
  }

  function handleCancel() {
    setSaving(true)
    const bookingToEdit = bookings && bookings.find(val => val.id === bookingId)
    const resourceFromBookingToEdit = resources && resources.find(val => val.id === bookingToEdit?.resource)
    bookingToEdit &&
      dispatch(
        actions.cancelBooking({
          customerId: selectedCustomer,
          booking: bookingToEdit,
          user: user,
          resource: resourceFromBookingToEdit,
        })
      ).then(() => {
        setSaving(false)
        onHide()
      })
  }

  function handleAuthClick() {
    gapi.auth2 &&
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .catch(err => {
          console.log("Err: ", err)
          UIProps.setCalendarSignedInInfo({
            ...UIProps.calendarSignedInInfo,
            error: err.error,
          })
        })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={EditSchema}
      onSubmit={values => {
        bookResource(values)
      }}
    >
      {({ values, handleSubmit }) => {
        const resource = resources && resources.find(val => val.id === values.resource)
        const floorPlanName = resource && floorPlans.find(val => val.id === resource.floorPlanId)?.name
        return (
          <Form className="form form-label-right" key={bookingId}>
            <div className="form-group row mb-5">
              <div className="col-5">
                <Field
                  name="date"
                  label="Date"
                  component={DatePicker}
                  autoOk
                  disableToolbar
                  disablePast={!bookingId}
                  minDate={bookingId && moment(values.date)}
                  maxDate={moment().add(2, "months")}
                />
              </div>
              <div className="col-3">
                <Field name="start" label="Start" component={TimeSelect} />
              </div>
              <div className="col-3">
                <Field name="end" label="End" component={TimeSelect} startTime={values.start} />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <Field
                  name="type"
                  label="What would you like to book?"
                  component={Select}
                  options={[
                    { value: "desk", name: "A desk" },
                    { value: "meeting", name: "A meeting room" },
                  ]}
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <Field
                  name="floorPlan"
                  label="What floor plan would you like to see?"
                  component={Select}
                  options={
                    floorPlans && [
                      { value: "all", name: "All" },
                      ...floorPlans.map(f => {
                        return { value: f.id, name: f.name }
                      }),
                    ]
                  }
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <Field
                  name="resource"
                  label="Choose a resource"
                  component={Select}
                  options={
                    resources && [
                      { value: "", name: "" },
                      ...resources
                        .map(r => {
                          if (r.floorPlanId && r.type == values.type && (r.floorPlanId == values.floorPlan || values.floorPlan == "all")) {
                            return { value: r.id, name: r.name }
                          }
                        })
                        .filter(val => val),
                    ]
                  }
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <Field name="summary" label="Summary" component={Input} />
              </div>
            </div>
            {values.type == "meeting" && (
              <div className="form-group row mb-5">
                <div className="col">
                  <Field name="attendees" label="Add attendees" component={Autocomplete} />
                </div>
              </div>
            )}
            {resource?.calendarType && !UIProps.calendarSignedInInfo.signedIn ? (
              <Button
                block
                //type="submit"
                disabled={saving}
                variant="primary"
                onClick={handleAuthClick}
              >
                Calendar Sign In
              </Button>
            ) : (
              <div className="row">
                {bookingId && (
                  <div className="col-6">
                    <Button block disabled={saving} variant="danger" onClick={handleCancel}>
                      CANCEL BOOKING
                    </Button>
                  </div>
                )}
                <div className="col">
                  <Button
                    block
                    //type="submit"
                    disabled={saving}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    {bookingId ? `UPDATE BOOKING` : `ADD BOOKING ${floorPlanName ? `ON ${floorPlanName}` : ""}`}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
