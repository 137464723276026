/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { firestore } from "../../../../firebase";
import PathFinder from "geojson-path-finder";
import * as turf from "@turf/turf";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGlvZ29tIiwiYSI6ImNra3p5aXoxeTAxZ3Eybm80aXNhbTN0dDAifQ.QBLSLKu6azLM9HZkUogIRA";

export function MapWidget6() {
  const mapContainerRef = useRef(null);

  const selectedCustomer = useSelector(
    (state) => state.profile?.currentCustomer?.customerId
  );
  const selectedLocation = useSelector(
    (state) => state.profile?.currentLocation?.netId
  );
  const roomTypes = useSelector(
    (state) => state.profile?.currentCustomer?.roomTypes
  );

  const [map, setMap] = useState();
  const [geoJson, setGeoJson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [-9.15799632668495, 38.57795963862967],
            [-9.157944358885288, 38.57797929654707],
            [-9.15790781378746, 38.57792241962462],
            [-9.158004708588123, 38.577885200600335],
          ],
        },
      },
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [-9.158078134059906, 38.577925826999135],
            [-9.15799632668495, 38.57795963862967],
            [-9.157950058579445, 38.577884938494456],
          ],
        },
      },
    ],
  });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-9.158062711431398, 38.57793500061726],
      zoom: 19,
    });

    map.on("load", function() {
      setMap(map);

      var chunk = turf.lineChunk(geoJson, 0.0005);
      console.log("🚀 ~ chunk", chunk);
      var pathFinder = new PathFinder(chunk, { precision: 1e-5 });

      var points = [];

      for (const [key, value] of Object.entries(pathFinder._graph.vertices)) {
        console.log("Key: ", key);
        var latlong = key.split(",");
        var lng = parseFloat(latlong[0]);
        var lat = parseFloat(latlong[1]);
        points.push(turf.point([lng, lat]));
      }

      console.log("Points: ", JSON.stringify(points));

      var marker = new mapboxgl.Marker()
        .setLngLat([-9.15808416903019, 38.577926875422015])
        .addTo(map);
      var marker2 = new mapboxgl.Marker()
        .setLngLat([-9.157944694161415, 38.577869212140286])
        .addTo(map);

      console.log("???: ", JSON.stringify(turf.featureCollection(points)));

      var nearestStartPoint = turf.nearestPoint(
        [-9.15808416903019, 38.577926875422015],
        turf.featureCollection(points)
      );
      var nearestPoint = turf.nearestPoint(
        [-9.157944694161415, 38.577869212140286],
        turf.featureCollection(points)
      );

      var marker = new mapboxgl.Marker({ color: "#b40219" })
        .setLngLat(nearestPoint.geometry.coordinates)
        .addTo(map);
      var marker2 = new mapboxgl.Marker({ color: "#b40219" })
        .setLngLat(nearestStartPoint.geometry.coordinates)
        .addTo(map);

      console.log("Nearest: ", nearestPoint);

      var start = turf.point(nearestStartPoint.geometry.coordinates);
      console.log("Nearest Start: ", nearestStartPoint);

      var path = pathFinder.findPath(
        start,
        turf.point(nearestPoint.geometry.coordinates)
      );

      console.log("Path: ", path);

      map.addSource("route", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: path?.path,
              },
            },
          ],
        },
      });
      map.addLayer({
        id: "routeLayer",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#ffbb00",
          "line-width": 8,
        },
      });
    });

    firestore
      .doc(`Customers/${selectedCustomer}`)
      .collection("FloorPlans")
      .doc(selectedLocation)
      .get()
      .then((doc) => {
        var layer = doc.data().layer;
        var finalFeatures = [];

        layer.features.forEach((feature, index) => {
          if (feature.properties.level == 0) {
            var coordinates = [];
            feature.geometry.coordinates.forEach((coords) => {
              coordinates.push([coords.longitude, coords.latitude]);
            });
            layer.features[index].geometry.coordinates = [];
            layer.features[index].geometry.coordinates.push(coordinates);

            finalFeatures.push(layer.features[index]);
          }
        });

        finalFeatures.forEach((f, i) => {
          var currentType = roomTypes.find(
            (type) => type.type == f.properties.roomType
          );
          if (i != 0) {
            if (currentType) {
              f.properties.color = currentType.color;
              f.properties.outline = "#969696";
              f.properties.lineWidth = 2;
            } else {
              f.properties.color = "#FFFFFF";
              f.properties.outline = "#969696";
              f.properties.lineWidth = 2;
            }
          } else {
            f.properties.color = "#bfbfbf";
            f.properties.outline = "#696969";
            f.properties.lineWidth = 4;
          }
        });

        // map.addSource("layerSource", {
        //   "type": "geojson",
        //   "data": {
        //     type: "FeatureCollection",
        //     features: finalFeatures
        //   }
        // })

        // map.addLayer({
        //   "id": "overlay",
        //   "source": "layerSource",
        //   "type": "fill",
        //   'layout': {
        //     "visibility": "visible"
        //   },
        //   'paint': {
        //     'fill-color': ['get', 'color'],
        //     "fill-outline-color": ['get', 'outline'],
        //     'fill-opacity': ['get', 'opacity']
        //   }
        // }, "routeLayer")
      });
    return () => map.remove();
  }, []);

  return (
    <div>
      <div style={{ height: "500px" }} ref={mapContainerRef} />
    </div>
  );
}
