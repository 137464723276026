import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import Chart from "react-apexcharts";
import { Carousel } from "react-bootstrap";
import PopoverStickOnHover from "../PopoverStickOnHover";
import PopoverStickOnHoverDashboard from "../LiveMap/PopoverOnHoverDashboard1";
import * as turf from "@turf/turf";
import "@turf/angle";
import PathFinder from "geojson-path-finder";
import RouterIcon from "@material-ui/icons/Router";
import { BsThermometerHalf } from "react-icons/bs";
import { WiHumidity } from "react-icons/wi";
import { BsInfoLg } from "react-icons/bs";
import blueBall from "../../../../assets/markerBalls/stroke2Prancheta 65.png";
import purpleBall from "../../../../assets/markerBalls/stroke2Prancheta 68.png";
import DirectionsIcon from "@material-ui/icons/Directions";
import { IconButton, Tooltip, SvgIcon } from "@material-ui/core";

import { firestore } from "../../../../../firebase";
import { formatString } from "../../../Admin/designStudio/UIHelpers";
import { upperCase } from "../../../Admin/designStudio/UIHelpers";
import PopoverOnHoverDashboard1 from "../LiveMap/PopoverOnHoverDashboard1";
import stairs from "../../../../assets/designStudio/stairs (white).png";
import elevator from "../../../../assets/designStudio/elevator (white).png";
import escalator from "../../../../assets/designStudio/escalator (white).png";
import ramp from "../../../../assets/designStudio/ramp white.  (3).png";
import defaultBuilding from "../../../../assets/designStudio/account_tree_white_36dp.png";
import { ImArrowUp2 } from "react-icons/im";
import { ImArrowDown2 } from "react-icons/im";
import ApartmentIcon from "@material-ui/icons/Apartment";
import StoreIcon from "@material-ui/icons/Store";
import { FaUserTie } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { BsThermometerSun } from "react-icons/bs";

import { profileSlice } from "../../../../redux/profile/profileSlice";

const { actions } = profileSlice;

export const findMinMax = (value, threshold) => {
  // console.log("🚀 ~ threshold function", threshold);
  // console.log(value, "value function");
  let division;
  if (threshold >= 1000) {
    division = value / 17;
    return division;
  }
  if (threshold >= 850) {
    division = value / 16;
    return division;
  }
  if (threshold >= 750) {
    division = value / 15;
    return division;
  }
  if (threshold >= 500) {
    division = value / 10;
    return division;
  }
  if (threshold >= 250) {
    division = value / 7;
    return division;
  }
  if (threshold >= 100) {
    division = value / 3;
    return division;
  }
  if (threshold >= 50) {
    division = value / 1.5;
    return division;
  } else {
    division = value / 12;
    return division;
  }
};

// export function getUsers(queryParams) {
//   return (
//     customersRef
//       .doc(`${queryParams.customerId}/Users/${queryParams.userId}`)
//       // .doc(`${queryParams.customerId}/Users/`)
//       // .where(`${queryParams.userId}`, "array-contains", "devicesArray")
//       .get()
//   );

//   // const user = userDoc.data()
//   // if (!user?.buddies?.length > 0) { return }
//   // var newUsers = [user]

//   // const results = await Promise.all(user.buddies.map((val) => {
//   //   return firestore
//   //     .doc(`Customers/${queryParams.customerId}/Users/${val}`)
//   //     .get()
//   // }))
//   // const newBuddies = results.map((doc) => doc.data())
//   // newUsers = [...newUsers, ...newBuddies]
//   // return newUsers
// }

export const allLocationsInMap = (params) => {
  // console.log("🚀 ~ params", params);
  const {
    map,
    selectedCustomer,
    selectedLocation,
    locationsMarkers,
    sensorMarkers,
    allLocationsPeopleStats,
    differentLocationFloorPlans,
    allLocationsMerakiDevices,
    dispatch,
    setLocationsMarkers,
  } = params;

  locationsMarkers &&
    locationsMarkers.forEach((m) => {
      m.remove();
    });

  let allMarkersLocations = [];

  // Remove sensor markers if !selectedLocation
  sensorMarkers && sensorMarkers.forEach((marker) => marker.remove());

  // Stats with summed employees and visitors
  const statsWithSumArray =
    allLocationsPeopleStats &&
    allLocationsPeopleStats?.allLocationStats.map((s) => {
      return {
        ...s,
        summedEmployeesAndVisitors: s.current_employees + s.current_visitors,
      };
    });

  // console.log("🚀 ~ statsWithSumArray", statsWithSumArray);

  // Find highest sum number to get division number
  const threshold =
    statsWithSumArray &&
    statsWithSumArray.reduce(
      (prev, current) =>
        prev.summedEmployeesAndVisitors > current.summedEmployeesAndVisitors
          ? prev
          : current,
      0
    );
  // console.log("🚀 ~ threshold", threshold?.summedEmployeesAndVisitors);

  // Find center coords between several locations
  const pointsCoords = [];
  differentLocationFloorPlans &&
    differentLocationFloorPlans.forEach((loc) => {
      const lng = loc?.center?.lng; // each location lng
      const lat = loc?.center?.lat; // each location lat
      pointsCoords.push([lng, lat]);

      const nameFound = selectedCustomer.merakiNetworks.find(
        (l) => l?.netId === loc?.netId
      );
      // console.log("🚀 ~ nameFound", nameFound);

      const locFound =
        statsWithSumArray &&
        statsWithSumArray.find((s) => s.locationId === loc.netId);
      // console.log("🚀 ~ locFound", locFound);

      const merakiDevicesFound =
        allLocationsMerakiDevices &&
        allLocationsMerakiDevices.find((s) => s.locationId === loc.netId);
      // console.log("🚀 ~ merakiDevicesFound", merakiDevicesFound);

      const newWidthAndHeight =
        locFound &&
        findMinMax(
          locFound?.summedEmployeesAndVisitors,
          threshold?.summedEmployeesAndVisitors
        );
      // console.log("🚀 ~ NEW WIDTH AND HEIGHT", newWidthAndHeight);

      // Different markers size / stats
      const minMax =
        newWidthAndHeight >= 62
          ? "84px"
          : newWidthAndHeight >= 52
          ? "76px"
          : newWidthAndHeight >= 42
          ? "68px"
          : newWidthAndHeight >= 32
          ? "60px"
          : newWidthAndHeight >= 22
          ? "52px"
          : newWidthAndHeight >= 12
          ? "44px"
          : "36px";

      // Location markers
      let marker = document.createElement("div");
      marker.style.cursor = "pointer";
      marker.style.width = minMax;
      marker.style.height = minMax;
      // marker.style.backgroundSize = "34px";
      marker.className = "locationMarker";

      const popupSticky = (
        <PopoverStickOnHoverDashboard
          component={
            <div className='popoverCardNoLocation' id='popupBorderNoLocation'>
              <div className='popup2'>
                <Carousel
                  indicators={false}
                  controls={
                    nameFound?.imagesUrls && nameFound.imagesUrls.length > 1
                      ? true
                      : false
                  }
                  interval={1500}
                >
                  {nameFound?.imagesUrls &&
                    nameFound.imagesUrls.length > 0 &&
                    nameFound.imagesUrls.map((arrayUrl, i) => (
                      <Carousel.Item key={i} interval={1000}>
                        <img
                          className='d-block w-100'
                          src={arrayUrl}
                          alt={arrayUrl}
                          style={{
                            borderRadius: "8px 8px 0px 0px",
                            height: "14vh",
                            objectFit: "cover",
                            objectPosition: "bottom",
                          }}
                        />
                      </Carousel.Item>
                    ))}{" "}
                </Carousel>
                <div className='popupText'>
                  {/* <p>
                  <b>{loc.netId}</b>
                </p> */}
                  <h4
                    style={{
                      marginTop: "-0.5rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <b>
                      {nameFound?.netName.charAt(0).toUpperCase() +
                        nameFound?.netName.slice(1)}
                    </b>
                    {/* , {nameFound.netId} */}
                  </h4>
                  {/* começa aqui  */}
                  <div
                    className='row mb-2'
                    style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                  >
                    <div className='col-6'>
                      <Tooltip title='Current Employees' placement='bottom'>
                        <SvgIcon className='mr-2'>
                          <FaUserTie style={{ fontSize: "16px" }} />{" "}
                        </SvgIcon>
                      </Tooltip>
                      Employees:{" "}
                      {locFound && locFound?.current_employees
                        ? locFound?.current_employees
                        : 0}
                    </div>
                    <div className='col-6'>
                      <Tooltip title='Current Visitors' placement='bottom'>
                        <SvgIcon className='mr-2'>
                          <BsFillPeopleFill style={{ fontSize: "18px" }} />
                        </SvgIcon>
                      </Tooltip>
                      Visitors:{" "}
                      {locFound && locFound?.current_visitors
                        ? locFound?.current_visitors
                        : 0}
                    </div>
                  </div>
                  <div
                    className='row mb-2'
                    style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                  >
                    <div className='col-6'>
                      <Tooltip title='Current Guests' placement='bottom'>
                        <SvgIcon className='mr-2'>
                          <FaUsers style={{ fontSize: "20px" }} />
                        </SvgIcon>
                      </Tooltip>
                      Guests:{" "}
                      {locFound && locFound?.current_guests
                        ? locFound?.current_guests
                        : 0}
                    </div>
                    <div className='col-6'>
                      {merakiDevicesFound?.wireless.length !== 0 && (
                        <div
                          style={{ fontWeight: 600, marginTop: "2px" }}
                          // className='mr-3'
                        >
                          <Tooltip title='Access Points' placement='bottom'>
                            <SvgIcon
                              fontSize='small'
                              className='mr-3'
                              style={{
                                position: "relative",
                                marginTop: "-8px",
                                // marginLeft: "3px",
                                fontSize: "16px",
                              }}
                            >
                              <RouterIcon />
                            </SvgIcon>
                          </Tooltip>
                          AP's: {merakiDevicesFound?.wireless.length}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className='row'
                    style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                  >
                    <div className='col-6'>
                      {merakiDevicesFound?.sensor.length !== 0 && (
                        <div
                          style={{
                            fontWeight: 600,
                            marginLeft: "0.2rem",
                            marginTop: "2px",
                          }}
                        >
                          <Tooltip title='Sensors' placement='bottom'>
                            <SvgIcon>
                              <BsThermometerSun style={{ fontSize: "19px" }} />
                            </SvgIcon>
                          </Tooltip>
                          <span>
                            {" "}
                            Sensors: {merakiDevicesFound?.sensor.length}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "10px",
                      fontStyle: "italic",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    Press marker to select location.
                  </p>
                </div>
              </div>
            </div>
          }
          placement='top'
          onMouseEnter={() => {}}
          delay={200}
          id='popupStickLiveMapDashboard'
        >
          <div
            style={{
              // border: "2px solid yellow",
              filter: "brightness(0) invert(1)",
              width: "77%",
              height: "77%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {nameFound?.customerType === "shop" ? (
              <StoreIcon style={{ width: "100%", height: "100%" }} />
            ) : (
              <ApartmentIcon style={{ width: "100%", height: "100%" }} />
            )}
          </div>
        </PopoverStickOnHoverDashboard>
      );

      ReactDOM.render(popupSticky, marker);
      const randomNumber = Math.round(Math.random() * 120);

      //TODO:arranjar uma maneira com o turf se os markers estiverem sobrepostos, talvez fazer clusters, de dar o offset e tirar esta condition. Ou fazer clusters
      const conditionFarfetch =
        selectedCustomer.id === "farfetch-ymqgt" ? randomNumber : 0;

      const locationMarker = new mapboxgl.Marker(marker, {
        offset: [conditionFarfetch, conditionFarfetch], // Este offset é só p se tiver um caso de 2 locations mto perto uma da outra os markers não se sobreporem. (Farfetch Tower&Bower)
      })
        .setLngLat([lng, lat])
        .addTo(map);

      allMarkersLocations.push(locationMarker);

      // On Click marker
      locationMarker.getElement().addEventListener("click", () => {
        // console.log(nameFound?.netName, "onClick netName marker ");
        dispatch(actions.locationSelected(nameFound));

        const floor = {
          floorId: loc.floorPlanId,
          level: loc.level,
          name: loc.name,
        };
        dispatch(actions.levelSelected(floor));

        // Hide popup
        if (document.getElementById("popupBorderNoLocation")) {
          const element = document.getElementById("popupBorderNoLocation");
          // removes the element from the DOM
          element.remove();
        }

        // Remove markers on click
        allMarkersLocations &&
          allMarkersLocations.forEach((m) => {
            m.remove();
          });

        locationsMarkers &&
          locationsMarkers.forEach((m) => {
            m.remove();
          });
      });

      setLocationsMarkers(allMarkersLocations);

      // Aqui guardar este código p qnd o Rui colocar no merakiNetworks as coords de cada Location correctas.
      // const pointsCoords = [];
      // selectedCustomer.merakiNetworks.forEach((loc) => {
      //   const lng = loc.gpslng;
      //   const lat = loc.gpslat;
      //   pointsCoords.push([lng, lat]);
      //   //!! Delete this markers at end
      //   const randomNumber = Math.round(Math.random() * 140);
      //   const marker1 = new mapboxgl.Marker({ rotation: randomNumber })
      //     .setLngLat([loc.gpslng, loc.gpslat])
      //     .addTo(map);
      // });

      if (differentLocationFloorPlans) {
        // console.log("🚀 ~ pointsCoords", pointsCoords);
        // console.log(differentLocationFloorPlans, "differentLocationFloorPlans");

        var features = pointsCoords && turf.points(pointsCoords);
        var center = features && turf.center(features);

        map.setCenter(center.geometry.coordinates);
        map.setZoom(11.5);
      }
    });
};

export const addImageLayerToMap = (params) => {
  //━━━ Parameters ━━━\\
  const { map, floorPlan } = params;

  //━━━ Remove sources and layers ━━━\\
  typeof map.getLayer("imageLayer") !== "undefined" &&
    map.removeLayer("imageLayer");
  typeof map.getSource("imageSource") !== "undefined" &&
    map.removeSource("imageSource");
  typeof map.getLayer("geoJsonLayer") !== "undefined" &&
    map.removeLayer("geoJsonLayer");
  typeof map.getSource("geoJsonSource") !== "undefined" &&
    map.removeSource("geoJsonSource");
  typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
    map.removeLayer("geoJsonLinesLayer");
  typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
    map.removeSource("geoJsonLinesSource");

  //━━━ Add image ━━━\\
  map.addSource("imageSource", {
    type: "image",
    url: floorPlan.ufindurl,
    coordinates: [
      [floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
      [floorPlan.topRightCorner.lng, floorPlan.topRightCorner.lat],
      [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
      [floorPlan.bottomLeftCorner.lng, floorPlan.bottomLeftCorner.lat],
    ],
  });
  typeof map.getSource("geoJsonLayer-0") !== "undefined"
    ? map.addLayer(
        {
          id: "imageLayer",
          source: "imageSource",
          type: "raster",
          paint: {
            "raster-opacity": 0.8,
          },
        },
        // "geoJsonLayer-0"
        "waterway-label"
      )
    : map.addLayer(
        {
          id: "imageLayer",
          source: "imageSource",
          type: "raster",
          paint: {
            "raster-opacity": 0.8,
          },
        },
        "waterway-label"
      );

  // ↓ map animation duration at start
  const animationOptions = {
    duration: 0.1,
    animate: true,
  };
  // ↓ If exists is this floorPlan mapOrientation it displays orientation from db
  if (Boolean(floorPlan.mapOrientation) === true) {
    const lat = floorPlan.mapOrientation.center.latitude;
    const lng = floorPlan.mapOrientation.center.longitude;
    // map.setZoom(floorPlan.mapOrientation.zoom);
    // map.setBearing(floorPlan.mapOrientation.bearing);
    // map.setPitch(floorPlan.mapOrientation.pitch);
    // map.setCenter([lng, lat]);
    // ↑ equal to map.easeTo (to give animation fx)
    map.easeTo({
      center: [lng, lat],
      bearing: floorPlan.mapOrientation.bearing,
      pitch: floorPlan.mapOrientation.pitch,
      zoom: floorPlan.mapOrientation.zoom,
      duration: 0,
    });
  } else {
    // Onde é centrado o mapa por default
    map.fitBounds(
      [
        [floorPlan?.topLeftCorner.lng, floorPlan?.topLeftCorner.lat],
        [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
      ],
      // ↓ Duração da animação a iniciar o mapa e o zoom inicial
      {
        padding: { top: 10, bottom: 10, left: 10, right: 10 },
        // animationOptions,
        duration: 0,
      }
    );
  }
};

// Removes geoJson Line nearest Path in map
export const removePathFromMap = (params) => {
  //━━━ Parameters ━━━\\
  const map = params.map;

  //━━━ Remove source and layer ━━━\\
  var layer = map.getLayer("pathLayer");
  if (typeof layer !== "undefined") {
    map.removeLayer("pathLayer");
  }
  var layerArrows = map.getLayer("routeArrows");
  if (typeof layerArrows !== "undefined") {
    map.removeLayer("routeArrows");
  }
  var layerEndPoint = map.getLayer("pathLayerEndPoint");
  if (typeof layerEndPoint !== "undefined") {
    map.removeLayer("pathLayerEndPoint");
  }
  var layerStartPoint = map.getLayer("pathLayerStartPoint");
  if (typeof layerStartPoint !== "undefined") {
    map.removeLayer("pathLayerStartPoint");
  }
  var layerDoorPoint = map.getLayer("pathLayerDoorPoint");
  if (typeof layerDoorPoint !== "undefined") {
    map.removeLayer("pathLayerDoorPoint");
  }
  var layerDoorEndPoint = map.getLayer("pathLayerDoorEndPoint");
  if (typeof layerDoorEndPoint !== "undefined") {
    map.removeLayer("pathLayerDoorEndPoint");
  }

  // Source layers must be removed last, because the layers use it.
  var source = map.getSource("path");
  if (typeof source !== "undefined") {
    map.removeSource("path");
  }
  var sourceEndPoint = map.getSource("pathTillEndPoint");
  if (typeof sourceEndPoint !== "undefined") {
    map.removeSource("pathTillEndPoint");
  }
  var sourceStartPoint = map.getSource("pathTillStartPoint");
  if (typeof sourceStartPoint !== "undefined") {
    map.removeSource("pathTillStartPoint");
  }
  var sourceDoorPoint = map.getSource("pathTillDoorPoint");
  if (typeof sourceDoorPoint !== "undefined") {
    map.removeSource("pathTillDoorPoint");
  }
  var sourceDoorEndPoint = map.getSource("pathTillDoorEndPoint");
  if (typeof sourceDoorEndPoint !== "undefined") {
    map.removeSource("pathTillDoorEndPoint");
  }
};

// Draws geoJson Line nearest Path in map
export const addGeoJsonLayerToMapWithNewPath = (params) => {
  //━━━ Parameters ━━━\\
  const {
    map,
    pathFound,
    travelTo,
    copyOfStartPointCoords,
    floorPlan,
    copyOfConnectorCoords,
    poiToDoorCoords,
    poiToDoorEndPointCoords,
  } = params;

  //━━━ Remove source and layer ━━━\\
  var layer = map.getLayer("pathLayer");
  if (typeof layer !== "undefined") {
    map.removeLayer("pathLayer");
  }
  var layerArrows = map.getLayer("routeArrows");
  if (typeof layerArrows !== "undefined") {
    map.removeLayer("routeArrows");
  }
  var layerEndPoint = map.getLayer("pathLayerEndPoint");
  if (typeof layerEndPoint !== "undefined") {
    map.removeLayer("pathLayerEndPoint");
  }
  var layerStartPoint = map.getLayer("pathLayerStartPoint");
  if (typeof layerStartPoint !== "undefined") {
    map.removeLayer("pathLayerStartPoint");
  }
  var layerDoorPoint = map.getLayer("pathLayerDoorPoint");
  if (typeof layerDoorPoint !== "undefined") {
    map.removeLayer("pathLayerDoorPoint");
  }
  var layerDoorEndPoint = map.getLayer("pathLayerDoorEndPoint");
  if (typeof layerDoorEndPoint !== "undefined") {
    map.removeLayer("pathLayerDoorEndPoint");
  }
  // Source layers must be removed last, because the layers use it.
  var source = map.getSource("path");
  if (typeof source !== "undefined") {
    map.removeSource("path");
  }
  var sourceEndPoint = map.getSource("pathTillEndPoint");
  if (typeof sourceEndPoint !== "undefined") {
    map.removeSource("pathTillEndPoint");
  }
  var sourceStartPoint = map.getSource("pathTillStartPoint");
  if (typeof sourceStartPoint !== "undefined") {
    map.removeSource("pathTillStartPoint");
  }
  var sourceDoorPoint = map.getSource("pathTillDoorPoint");
  if (typeof sourceDoorPoint !== "undefined") {
    map.removeSource("pathTillDoorPoint");
  }
  var sourceDoorEndPoint = map.getSource("pathTillDoorEndPoint");
  if (typeof sourceDoorEndPoint !== "undefined") {
    map.removeSource("pathTillDoorEndPoint");
  }

  //━━━ Add floorplan source and layer ━━━\\
  map.addSource("path", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: pathFound?.path,
          },
        },
      ],
    },
  });

  //━━━ Add Source of line on End Point and Start Point to complete line to the marker ━━━\\
  const copyOfPath = [...pathFound?.path];
  // console.log("🚀 ~ copyOfPath", copyOfPath);
  const lastCoord = copyOfPath[copyOfPath?.length - 1];
  // console.log("🚀 ~ lastCoord", lastCoord);

  let travelToCoords = [];

  if (travelTo?.group === "Points of Interest") {
    // console.log(travelTo, "travelTo dentro do if das Points of Interest");

    if (travelTo.floorPlanId === floorPlan.floorPlanId) {
      // console.log("floorPlanId EQUAL POI");
      travelToCoords.push(
        travelTo?.coords?.longitude,
        travelTo?.coords?.latitude
      );
    }
    if (travelTo.floorPlanId !== floorPlan.floorPlanId) {
      // console.log("floorPlanId NOT EQUAL POI");
      // console.log(copyOfConnectorCoords, "copyOfConnectorCoords POI");
      copyOfConnectorCoords &&
        travelToCoords.push(copyOfConnectorCoords[0], copyOfConnectorCoords[1]);
    }
  }

  if (travelTo?.group === "Zones") {
    // console.log(travelTo, "travelTo dentro do if das zones");
    if (travelTo.floorPlanId === floorPlan.floorPlanId) {
      // console.log("floorPlanId EQUAL ZONES");
      travelToCoords.push(
        travelTo?.doorMarker?.longitude,
        travelTo?.doorMarker?.latitude
      );
    }
    if (travelTo.floorPlanId !== floorPlan.floorPlanId) {
      // console.log("floorPlanId NOT EQUAL ZONES");
      // console.log(copyOfConnectorCoords, "copyOfConnectorCoords zones");
      copyOfConnectorCoords &&
        travelToCoords.push(copyOfConnectorCoords[0], copyOfConnectorCoords[1]);
    }
  }

  // console.log("🚀 ~ travelToCoords", travelToCoords);
  // console.log(poiToDoorEndPointCoords, "poiToDoorEndPointCoords");

  // ↓ If POI is inside a polygon at end Point passes coords from last coord to door, and then from door to travelToCoords
  const finalCoordsEndPoint = poiToDoorEndPointCoords
    ? [poiToDoorEndPointCoords, travelToCoords]
    : [lastCoord, travelToCoords];
  // const finalCoordsEndPoint = [lastCoord, travelToCoords];
  // console.log("🚀 ~ finalCoordsEndPoint", finalCoordsEndPoint);

  if (poiToDoorEndPointCoords) {
    // ↓ If POI is inside a polygon at end Point draws another layer from POI marker to Door
    const finalCoordsDoorPoint = [lastCoord, poiToDoorEndPointCoords];

    map.addSource("pathTillDoorEndPoint", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: finalCoordsDoorPoint,
            },
          },
        ],
      },
    });

    map.addLayer({
      id: "pathLayerDoorEndPoint",
      type: "line",
      source: "pathTillDoorEndPoint",
      layout: {
        visibility: "visible",
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        // "line-color": "#ADD8E6",
        "line-color": "#ffbb00",
        "line-width": 5,
      },
    });
  }

  map.addSource("pathTillEndPoint", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: finalCoordsEndPoint,
          },
        },
      ],
    },
  });

  const firstCoord = copyOfPath[0];
  // console.log("🚀 ~ firstCoord", firstCoord);
  // console.log(copyOfStartPointCoords, "copyOfStartPointCoords");
  // const startPointCoords = [
  //   copyOfStartPoint?.coords?.longitude,
  //   copyOfStartPoint?.coords?.latitude,
  // ];
  // console.log("🚀 ~ startPointCoords", startPointCoords);
  const finalCoordsStartPoint = poiToDoorCoords
    ? [poiToDoorCoords, firstCoord]
    : [copyOfStartPointCoords, firstCoord];

  // console.log("🚀 ~ finalCoordsStartPoint", finalCoordsStartPoint);

  // console.log(poiToDoorCoords, "poiToDoorCoords");
  if (poiToDoorCoords) {
    // If POI is inside a polygon draws another layer from POI marker to Door
    const finalCoordsDoorPoint = [copyOfStartPointCoords, poiToDoorCoords];

    map.addSource("pathTillDoorPoint", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: finalCoordsDoorPoint,
            },
          },
        ],
      },
    });

    map.addLayer({
      id: "pathLayerDoorPoint",
      type: "line",
      source: "pathTillDoorPoint",
      layout: {
        visibility: "visible",
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        // "line-color": "#0000FF",
        "line-color": "#ffbb00",
        "line-width": 5,
      },
    });
  }

  map.addSource("pathTillStartPoint", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: finalCoordsStartPoint,
          },
        },
      ],
    },
  });

  map.addLayer({
    id: "pathLayerStartPoint",
    type: "line",
    source: "pathTillStartPoint",
    layout: {
      visibility: "visible",
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      // "line-color": "#00FF00",
      "line-color": "#ffbb00",
      "line-width": 5,
    },
  });

  map.addLayer({
    id: "pathLayerEndPoint",
    type: "line",
    source: "pathTillEndPoint",
    layout: {
      visibility: "visible",
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      // "line-color": "#FF0000",
      "line-color": "#ffbb00",
      "line-width": 5,
    },
  });

  map.addLayer({
    id: "pathLayer",
    type: "line",
    source: "path",
    layout: {
      visibility: "visible",
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#ffbb00",
      "line-width": 5,
    },
  });
  map.addLayer(
    {
      id: "routeArrows",
      type: "symbol",
      source: "path",
      layout: {
        visibility: "visible",
        "symbol-placement": "line",
        "text-field": "▶",
        "text-size": ["interpolate", ["linear"], ["zoom"], 6, 10, 18, 19],
        "symbol-spacing": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          20,
          22,
          110,
        ],
        "text-keep-upright": false,
      },
      paint: {
        "text-color": "#ffbb00",
        "text-halo-color": "hsl(55, 11%, 96%)",
        "text-halo-width": 2,
      },
    }
    // 'waterway-label'
  );
};

// Draw geoJson Layers Line in map
export const addGeoJsonLayerToMapLine = (params) => {
  //━━━ Parameters ━━━\\
  const map = params.map;
  const geoJson = params.geoJson;

  //━━━ Remove source and layer ━━━\\
  var layer = map.getLayer("geoJsonLineLayerRoutes");
  if (typeof layer !== "undefined") {
    map.removeLayer("geoJsonLineLayerRoutes");
  }
  var source = map.getSource("geoJsonLineSourceRoutes");
  if (typeof source !== "undefined") {
    map.removeSource("geoJsonLineSourceRoutes");
  }

  //━━━ Add floorplan source and layer ━━━\\
  var lineGeoJson = JSON.parse(JSON.stringify(geoJson));

  geoJson.features.forEach((feature, index) => {
    typeof map.getLayer(`geoJsonLineLayerRoutes-${index}`) !== "undefined" &&
      map.removeLayer(`geoJsonLineLayerRoutes-${index}`);
    typeof map.getSource(`geoJsonLineSourceRoutes-${index}`) !== "undefined" &&
      map.removeSource(`geoJsonLineSourceRoutes-${index}`);

    var coordinates = [];
    feature.geometry.coordinates.forEach((coords) => {
      coordinates.push([coords.longitude, coords.latitude]);
    });

    const data = {
      type: geoJson.features[index].type,
      properties: {
        ...geoJson.features[index].properties,
        // color:
        //   /* feature.properties.type === "baseLayer" ?  */ currentType?.color /* : color */,
        // outline: currentType ? "#969696" : "#969696",
        // lineWidth:
        //   feature.properties.type != "baseLayer" ? (currentType ? 2 : 2) : 4,
        color: "#3bb3d0",
        outline: "#3bb3d0",
        opacity: 0.9,
        lineWidth: 3,
      },
      geometry: {
        type: geoJson.features[index].geometry.type,
        coordinates: coordinates,
      },
    };

    //━━━ Add lines ━━━\\
    map.addSource(`geoJsonLineSourceRoutes-${index}`, {
      type: "geojson",
      data: data,
    });
    map.addLayer(
      {
        id: `geoJsonLineLayerRoutes-${index}`,
        source: `geoJsonLineSourceRoutes-${index}`,
        type: "line",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": ["get", "outline"],
          "line-width": ["get", "lineWidth"],
        },
      },
      "country-label"
    );
  });

  // console.log("🚀 ~ lineGeoJson", lineGeoJson);

  //━━━ Add lines source and layer ━━━\\
  map.addSource("geoJsonLineSourceRoutes", {
    type: "geojson",
    data: lineGeoJson,
  });
  map.addLayer(
    {
      id: "geoJsonLineLayerRoutes",
      source: "geoJsonLineSourceRoutes",
      type: "line",
      layout: {
        visibility: "visible",
        "line-join": "round",
        "line-cap": "round",
      },

      paint: {
        // "line-color": "#3bb3d0",
        // "line-width": 4,
        "line-color": ["get", "outline"],
        "line-width": ["get", "lineWidth"],
      },
    },
    // "gl-draw-polygon-fill-inactive.cold"
    "state-label"
    // "country-label"
  );
};

export const addGeoJsonLayerToMap = (params) => {
  const { map, geoJson, layerTypes, devices, guests, visitors } = params;

  typeof map.getLayer("geoJsonLayer") !== "undefined" &&
    map.removeLayer("geoJsonLayer");
  typeof map.getSource("geoJsonSource") !== "undefined" &&
    map.removeSource("geoJsonSource");
  typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
    map.removeLayer("geoJsonLinesLayer");
  typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
    map.removeSource("geoJsonLinesSource");

  var iterateWhile = true;
  var index = 0;

  while (iterateWhile === true) {
    typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
      map.removeLayer(`geoJsonLayer-${index}`);
    typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
      map.removeSource(`geoJsonSource-${index}`);
    typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
      map.removeLayer(`geoJsonLinesLayer-${index}`);
    typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
      map.removeSource(`geoJsonLinesSource-${index}`);
    index++;
    if (
      typeof map.getLayer(`geoJsonLinesLayer-${index}`) === "undefined" &&
      typeof map.getSource(`geoJsonLinesSource-${index}`) === "undefined" &&
      typeof map.getLayer(`geoJsonLayer-${index}`) === "undefined" &&
      typeof map.getSource(`geoJsonSource-${index}`) === "undefined"
    ) {
      iterateWhile = false;
    }
  }

  if (!geoJson) return;

  //━━━ Tranform data for mapbox ━━━\\
  geoJson.features.forEach((feature, index) => {
    typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
      map.removeLayer(`geoJsonLayer-${index}`);
    typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
      map.removeSource(`geoJsonSource-${index}`);
    typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
      map.removeLayer(`geoJsonLinesLayer-${index}`);
    typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
      map.removeSource(`geoJsonLinesSource-${index}`);
    var currentType = layerTypes?.find(
      (type) => type.id === feature.properties.type
    );
    var coordinates = [];
    feature.geometry.coordinates.forEach((coords) => {
      coordinates.push([coords.longitude, coords.latitude]);
    });

    const poly = turf.polygon([coordinates]);

    const devicesInLayer =
      (devices &&
        devices.filter((val) => {
          var pt = turf.point([val.gpsData.longitude, val.gpsData.latitude]);
          return turf.booleanPointInPolygon(pt, poly);
        })?.length) ||
      0;

    const guestsInLayer =
      (guests &&
        guests.filter((val) => {
          var pt = turf.point([val.gpsData.longitude, val.gpsData.latitude]);
          return turf.booleanPointInPolygon(pt, poly);
        })?.length) ||
      0;

    const visitorsInLayer =
      (visitors &&
        visitors.filter((val) => {
          var pt = turf.point([val.gpsData.longitude, val.gpsData.latitude]);
          return turf.booleanPointInPolygon(pt, poly);
        })?.length) ||
      0;

    const totalPeople = devicesInLayer + guestsInLayer + visitorsInLayer;

    const percentage =
      totalPeople === 0 ? 0 : (totalPeople * 100) / feature.properties.capacity;

    const color =
      percentage >= 90 ? "red" : percentage >= 60 ? "#fcba03" : "green";

    const data = {
      type: geoJson.features[index].type,
      properties: {
        ...geoJson.features[index].properties,
        color:
          /* feature.properties.type === "baseLayer" ?  */ currentType?.color /* : color */,
        outline: currentType ? "#969696" : "#969696",
        lineWidth:
          feature.properties.type !== "baseLayer" ? (currentType ? 2 : 2) : 4,
      },
      geometry: {
        type: geoJson.features[index].geometry.type,
        coordinates: [coordinates],
      },
    };

    // console.log(feature.properties.visible, "feature.properties.visible");
    // console.log(feature.properties, "feature.properties");
    const isVisible = feature.properties.visible === true ? "visible" : "none";
    // console.log("🚀 ~ isVisible", isVisible);

    const noPropertyInDBCondition =
      feature.properties.visible !== undefined ? isVisible : "visible";
    // console.log("🚀 ~ noPropertyInDBCondition", noPropertyInDBCondition);

    //━━━ Add floorplan ━━━\\
    map.addSource(`geoJsonSource-${index}`, {
      type: "geojson",
      data: data,
    });

    map.addLayer(
      {
        id: `geoJsonLayer-${index}`,
        source: `geoJsonSource-${index}`,
        type: "fill",
        layout: {
          visibility: `${noPropertyInDBCondition}`,
          // visibility: "visible",
        },
        paint: {
          "fill-color": ["get", "color"],
          "fill-outline-color": ["get", "outline"],
          "fill-opacity": 0.7, //['get', 'opacity'],
        },
      },
      "country-label"
    );

    //━━━ Add lines ━━━\\
    map.addSource(`geoJsonLinesSource-${index}`, {
      type: "geojson",
      data: data,
    });
    map.addLayer(
      {
        id: `geoJsonLinesLayer-${index}`,
        source: `geoJsonLinesSource-${index}`,
        type: "line",
        layout: {
          visibility: `${noPropertyInDBCondition}`,
          // visibility: "visible",
        },
        paint: {
          "line-color": ["get", "outline"],
          "line-width": ["get", "lineWidth"],
        },
      },
      "country-label"
    );
  });
};

export async function addDevicesToMap(props) {
  const {
    map,
    devices,
    blePeople,
    bleThings,
    users,
    guests,
    visitors,
    showEmployees,
    showGuests,
    showVisitors,
  } = props;

  const _devices =
    showEmployees && devices
      ? devices.map((d) => {
          return {
            type: "Feature",
            properties: {
              id: d.clientMac,
              user:
                users &&
                users.find(
                  (val) =>
                    val &&
                    val.devicesArray &&
                    val.devicesArray.includes(d?.clientMac)
                ),
              type: "E",
              color: d.accurate ? "#9ce660" : "#fa4d4d",
              outline: d.accurate ? "#5e9630" : "#b03333",
            },
            geometry: {
              type: "Point",
              coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
            },
          };
        })
      : [];

  const _blePeople =
    showEmployees && blePeople
      ? blePeople.map((d) => {
          return {
            type: "Feature",
            properties: {
              id: d.macAddress,
              type: "E",
              color: d.accurate ? "#9ce660" : "#fa4d4d",
              outline: d.accurate ? "#5e9630" : "#b03333",
            },
            geometry: {
              type: "Point",
              coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
            },
          };
        })
      : [];

  //Dont show things in map
  /* const _bleThings =
  visitors &&  bleThings
    ? bleThings.map((d) => {
        return {
          type: "Feature",
          id: d.clientMac,
          properties: {
            id: d.macAddress,
            //!Remove, only used for testing
            user:
              users &&
              users.find(
                (val) =>
                  val.devicesArray && val.devicesArray.includes(d.macAddress)
              ),
            type: "T",
            color: d.accurate ? "#9ce660" : "#fa4d4d",
            outline: d.accurate ? "#5e9630" : "#b03333",
          },
          geometry: {
            type: "Point",
            coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
          },
        };
      })
    : []; */

  const _guests =
    showGuests && guests
      ? guests.map((d) => {
          return {
            type: "Feature",
            id: d.clientMac,
            properties: {
              id: d.clientMac,
              type: "G",
              color: d.accurate ? "#9ce660" : "#fa4d4d",
              outline: d.accurate ? "#5e9630" : "#b03333",
            },
            geometry: {
              type: "Point",
              coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
            },
          };
        })
      : [];

  const _visistors =
    visitors && showVisitors
      ? visitors.map((d) => {
          return {
            type: "Feature",
            id: d.clientMac,
            properties: {
              id: d.clientMac,
              type: "V",
              color: d.accurate ? "#9ce660" : "#fa4d4d",
              outline: d.accurate ? "#5e9630" : "#b03333",
            },
            geometry: {
              type: "Point",
              coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
            },
          };
        })
      : [];

  const features = [
    ..._devices,
    ..._blePeople,
    //..._bleThings,
    ..._guests,
    ..._visistors,
  ];

  if (typeof map.getLayer("pins") !== "undefined") {
    map.removeLayer("pins");
    map.removeLayer("cluster-pins");
    map.removeLayer("cluster-pins-count");
  }
  if (typeof map.getLayer("pins-with-pulsing-dot") !== "undefined") {
    map.removeLayer("pins-with-pulsing-dot");
  }
  if (typeof map.getSource("pins") !== "undefined") {
    map.removeSource("pins");
  }

  map.addSource("pins", {
    type: "geojson",
    cluster: true,
    clusterRadius: 1,
    // clusterRadius: 5,
    clusterMaxZoom: 25,
    data: {
      type: "FeatureCollection",
      features: features,
    },
  });

  map.addLayer({
    id: "pins",
    type: "symbol",
    source: "pins",
    layout: {
      // "icon-image": "marker-15", //TODO: Parece que isto comentado resolve o problema dos pontos q apareciam.
      visibility: "visible",
    },
    filter: ["all", ["!has", "point_count"]],
  });

  map.addLayer({
    id: "cluster-pins",
    type: "circle",
    source: "pins",
    filter: ["all", ["has", "point_count"]],
    paint: {
      "circle-color": {
        type: "interval",
        property: "point_count",
        stops: [
          [0, "#51bbd6"],
          [20, "#f1f075"],
          [150, "#f28cb1"],
        ],
      },
      "circle-radius": 12,
    },
  });

  map.addLayer({
    id: "cluster-pins-count",
    type: "symbol",
    source: "pins",
    layout: {
      "text-field": "{point_count}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  });

  // map && console.log(map.getStyle().layers, "layers");
}

export function addConnectorsMarkers(params) {
  const {
    map,
    connectorsMarkers,
    floorPlanId,
    floorPlanData,
    allFloors,
    geoJsonRoutes,
    connectorAccess,
    travelTo,
    selectedFloor,
    dispatch,
    setPathFound,
    // directionsControl,
    setGetDirections,
    setConnectorAccess,
    setTravelTo,
    copyOfConnectorCoords,
    setCopyOfStartPointCoords,
    geoJsonRoutesUndefinedSnackBar,
    zones,
    setPoiToDoorEndPointCoords,
    setPoiToDoorCoords,
  } = params;

  // console.log("🚀 ~ selectedFloor", selectedFloor);
  // console.log("🚀 ~ floorPlanData", floorPlanData);
  // console.log("🚀 ~ allFloors", allFloors);
  // console.log("🚀 ~ floorPlanId", floorPlanId);
  // console.log("🚀 ~ geoJsonRoutes", geoJsonRoutes);

  const connectorsMarkersArray = []; // ← Array com todos os markers

  // Find levels that client uses
  const floorPlansThatExist =
    floorPlanData &&
    floorPlanData.filter(
      (floor) => allFloors && allFloors.find((f) => floor.id === f.floorId)
    );
  // console.log(floorPlansThatExist, "floorPlansThatExist");
  // console.log(travelTo, "travelTo helpers");

  const travelToSelectedFloor =
    travelTo &&
    floorPlansThatExist &&
    floorPlansThatExist.find((f) => f.floorPlanId === travelTo.floorPlanId);
  // console.log("🚀 ~ travelToSelectedFloor", travelToSelectedFloor);

  const travelToFloorOfTravelTo =
    travelTo &&
    allFloors &&
    allFloors.find((f) => f.floorId === travelTo.floorPlanId);
  // console.log("🚀 ~ travelToFloorOfTravelTo", travelToFloorOfTravelTo);

  let arrowIcon = <ImArrowUp2 style={{ color: "white" }} />;
  if (
    travelToSelectedFloor &&
    selectedFloor.level > travelToSelectedFloor.level
  ) {
    arrowIcon = <ImArrowDown2 style={{ color: "white" }} />;
  }

  // ↓ Add marker for each floorPlanData that as a linked connector
  floorPlansThatExist &&
    floorPlansThatExist.forEach((floor) => {
      // console.log(floor, "floor");

      const selectedConnector =
        connectorAccess &&
        floor.geoJsonRoutes?.features.find(
          (f) => connectorAccess.id === f.properties.connectorType
        );

      // selectedConnector &&
      //   console.log("🚀 ~ selectedConnector", selectedConnector);

      floor.geoJsonRoutes?.features &&
        floor.geoJsonRoutes.features.forEach((f) => {
          if (f.properties.linkToFloors?.includes(floorPlanId)) {
            // console.log("connector linked to this floor ->", f);
            const coords = [];
            f.geometry.coordinates.forEach((coordinates) => {
              coords.push(coordinates.longitude, coordinates.latitude);
            });

            let connectorTypeImage = defaultBuilding;
            let idForEachType = f.properties.connectorName;

            switch (f.properties.connectorType) {
              case "ELEVATOR":
                connectorTypeImage = elevator;
                break;
              case "STAIRS":
                connectorTypeImage = stairs;
                break;
              case "ESCALATOR":
                connectorTypeImage = escalator;
                break;
              case "RAMP":
                connectorTypeImage = ramp;
                idForEachType = "wheelChairRamp";
                break;
              default:
                connectorTypeImage = defaultBuilding;
            }

            const typeWithoutAllLetterUppercase = formatString(
              f?.properties?.connectorType
            );

            const popupstickyConnectors = f && (
              <PopoverOnHoverDashboard1
                component={
                  <div className='popoverCardRoutes'>
                    <div className='popupMainDiv'>
                      <div className='popup2'>
                        {connectorAccess.id === f.properties.connectorType ? (
                          <div className='popupTextRoutes'>
                            <h5
                              style={{
                                fontSize: "12px",
                                paddingBottom: "1px",
                                paddingTop: "1px",
                              }}
                            >
                              Connector:&nbsp;
                              <b>{f.properties.connectorName}</b>
                            </h5>
                            <h5
                              style={{
                                fontSize: "12px",
                                paddingBottom: "1px",
                                paddingTop: "1px",
                                textDecoration: "underline",
                              }}
                            >
                              Click on&nbsp;
                              <b>{f.properties.connectorName}</b> marker to
                              change floor and follow path.
                            </h5>
                          </div>
                        ) : (
                          <div className='popupTextRoutes'>
                            <h5
                              style={{
                                paddingBottom: "1px",
                                paddingTop: "1px",
                              }}
                            >
                              Connector:&nbsp;
                              <b>{f.properties.connectorName}</b>
                            </h5>
                            <h5
                              style={{
                                paddingBottom: "1px",
                                paddingTop: "1px",
                              }}
                            >
                              Type:&nbsp;
                              <b>{typeWithoutAllLetterUppercase}</b>
                            </h5>
                            <h6
                              style={{
                                paddingBottom: "1px",
                                paddingTop: "1px",
                              }}
                            >
                              Linked to floors:&nbsp;
                            </h6>

                            {f.properties.linkToFloors &&
                              f.properties.linkToFloors.map((res) => {
                                const floorName =
                                  allFloors &&
                                  allFloors.find((f) => f.floorId === res);

                                const linkToFloorsCapitalized = upperCase(
                                  floorName.name
                                );
                                if (res) {
                                  return (
                                    <div key={res}>
                                      <ul key={res}>
                                        <li
                                          style={{
                                            marginBottom: "-1vh",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {linkToFloorsCapitalized}
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        )}

                        <br />
                      </div>
                    </div>
                  </div>
                }
                placement='top'
                onMouseEnter={(e) => {}}
                delay={200}
                id='popoverConnectorsDash'
              >
                <div
                  className='popupLineRoutes'
                  // className={connectorAccess.className}
                  id={
                    connectorAccess.id === f.properties.connectorType
                      ? connectorAccess.id
                      : null
                  }
                  onClick={() => {
                    // console.log("clicked connector");
                    // ↓ if no routes drawn display message to user
                    if (
                      !travelToSelectedFloor?.geoJsonRoutes ||
                      travelToSelectedFloor?.geoJsonRoutes.features.length === 0
                    ) {
                      geoJsonRoutesUndefinedSnackBar(travelToSelectedFloor);
                      return;
                    }
                    if (connectorAccess.id === f.properties.connectorType) {
                      travelToFloorOfTravelTo &&
                        dispatch(
                          actions.levelSelected(travelToFloorOfTravelTo)
                        ); // ← change to floor of travelTo point

                      const floorOfEndPoint =
                        floorPlanData &&
                        floorPlanData.find(
                          (floor) => floor.floorPlanId === travelTo.floorPlanId
                        );
                      // console.log("🚀 ~ floorOfEndPoint", floorOfEndPoint);
                      addPathAfterTravellingConnector({
                        selectedConnector,
                        travelTo,
                        geoJsonRoutes: travelToSelectedFloor?.geoJsonRoutes,
                        dispatch,
                        setPathFound,
                        map,
                        // directionsControl,
                        setGetDirections,
                        setConnectorAccess,
                        setTravelTo,
                        // copyOfConnectorCoords,
                        setCopyOfStartPointCoords,
                        floorPlan: floorOfEndPoint,
                        zones,
                        setPoiToDoorEndPointCoords,
                        setPoiToDoorCoords,
                      });
                      document.getElementById("popoverConnectorsDash").remove(); // ← removes popup when clicked to change floor
                    }
                  }}
                >
                  <div style={{ margin: "2px" }}>
                    <img
                      src={connectorTypeImage}
                      alt={f.properties.connectorName}
                      className='iconImageRoutes'
                      id={idForEachType}
                    />
                  </div>
                  {connectorAccess.id === f.properties.connectorType ? (
                    <div style={{ margin: "0px" }}>
                      <IconButton size='small' style={{ fontSize: "14px" }}>
                        {arrowIcon}
                      </IconButton>
                    </div>
                  ) : null}
                  {connectorAccess.id === f.properties.connectorType ? (
                    <div style={{ margin: "2px" }}>
                      <h4 style={{ color: "white", margin: "0px" }}>
                        <b>
                          {travelToSelectedFloor && travelToSelectedFloor.level}
                        </b>
                      </h4>
                    </div>
                  ) : null}
                </div>
              </PopoverOnHoverDashboard1>
            );

            // ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords com geoJsonRoutes
            const connectorPositionInSelectedFloor =
              f.properties?.connectorPositionOnFloors &&
              f.properties?.connectorPositionOnFloors.find(
                (marker) => marker.floorPlanId === floorPlanId
              );
            // console.log(
            //   "🚀 ~ connectorPositionInSelectedFloor",
            //   connectorPositionInSelectedFloor
            // );

            const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
              connectorPositionInSelectedFloor.coords.longitude,
              connectorPositionInSelectedFloor.coords.latitude,
            ];
            // console.log(
            //   "🚀 ~ connectorPositionFetchedCoords",
            //   connectorPositionFetchedCoords
            // );

            const conditionalCoords = connectorPositionFetchedCoords
              ? connectorPositionFetchedCoords
              : coords;
            // console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

            const div = document.createElement("div");
            div.className = "connectorsMarkerDashboard";
            div.style.cursor = "pointer";
            div.style.zIndex = connectorAccess.zIndex; // qnd o directions é activado o zIndex muda p cima
            ReactDOM.render(popupstickyConnectors, div);

            const markerWithPopup = new mapboxgl.Marker(div, {
              draggable: false,
            });

            markerWithPopup.setLngLat(conditionalCoords).addTo(map);
            connectorsMarkersArray.push(markerWithPopup);
          }
        });
    });

  // console.log("🚀 ~ connectorsMarkersArray", connectorsMarkersArray);
  return connectorsMarkersArray;
}

function addPathAfterTravellingConnector(params) {
  const {
    selectedConnector,
    travelTo,
    geoJsonRoutes,
    setPathFound,
    dispatch,
    map,
    // directionsControl,
    setGetDirections,
    setConnectorAccess,
    setTravelTo,
    setCopyOfStartPointCoords,
    floorPlan,
    zones,
    setPoiToDoorEndPointCoords,
    setPoiToDoorCoords,
  } = params;

  // Add new Path after travelling to another level by connector
  let finalFeaturesLines = [];
  geoJsonRoutes &&
    geoJsonRoutes.features.forEach((feature, index) => {
      if (feature.geometry.type === "LineString") {
        let coordinates = [];
        feature.geometry.coordinates.forEach((coords) => {
          coordinates.push([coords.longitude, coords.latitude]);
        });
        finalFeaturesLines.push({
          type: geoJsonRoutes.features[index].type,
          properties: {
            ...geoJsonRoutes.features[index].properties,
          },
          geometry: {
            type: geoJsonRoutes.features[index].geometry.type,
            coordinates: coordinates,
          },
        });
      }
    });

  // console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

  const finalLayerLines = {
    type: "FeatureCollection",
    features: finalFeaturesLines,
  };
  // console.log(finalLayerLines, "finalLayerLines");

  const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
    // ← turn lines into little chunks.
    units: "kilometers",
  });
  // console.log("🚀 ~ chunk", chunk);

  const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 });
  // console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

  const points = [];

  for (const [key, value] of Object.entries(
    pathFinderOnSameFloor._graph.vertices
  )) {
    // console.log("Key: ", key);
    const latlong = key.split(",");
    const lng = parseFloat(latlong[0]);
    const lat = parseFloat(latlong[1]);
    points.push(turf.point([lng, lat]));
  }
  // console.log("Points: ", JSON.stringify(points));

  if (selectedConnector && travelTo) {
    // console.log("🚀 ~ travelTo", travelTo); // ← End point
    // console.log("🚀 ~ selectedConnector", selectedConnector);

    // ↓ End Point (point selected where the user want to go)
    let coordsEndPoint = [];

    if (travelTo.group === "Points of Interest") {
      if (!floorPlan.geoJson) {
        return;
      }
      // If POI is inside a polygon
      const poiInsidePolyCoords = [];
      floorPlan &&
        floorPlan.geoJson.features.forEach((feature) => {
          let coordinates = [];
          if (feature.properties.name === "Base Layer") {
            return;
          }
          feature.geometry.coordinates.forEach((coords) => {
            coordinates.push([coords.longitude, coords.latitude]);
          });

          // console.log("🚀 ~ coordinates", coordinates);
          const poly = turf.polygon([coordinates]);
          console.log(
            turf.booleanPointInPolygon(
              [travelTo?.coords?.longitude, travelTo?.coords?.latitude],
              poly
            ),
            "BOOLEAN EXISTS"
          );
          const existsInsidePolygon = turf.booleanPointInPolygon(
            [travelTo?.coords?.longitude, travelTo?.coords?.latitude],
            poly
          );
          if (existsInsidePolygon) {
            // console.log(feature, "feature that exists POI inside polygon");
            // console.log(travelTo, "travelTo that exists POI inside polygon");
            const fetchZoneWithMarkerCoords =
              zones &&
              zones.find((zone) => zone.id === feature.properties.zoneId);
            // console.log(
            //   "🚀 ~ fetchZoneWithMarkerCoords 4",
            //   fetchZoneWithMarkerCoords
            // );
            poiInsidePolyCoords.push(fetchZoneWithMarkerCoords);
          }
        });

      // console.log(poiInsidePolyCoords.length, "length");
      // console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

      // if POI not inside a polygon
      if (poiInsidePolyCoords.length === 0) {
        coordsEndPoint = [
          travelTo.coords?.longitude,
          travelTo.coords?.latitude,
        ];
      }

      // if POI is inside a polygon
      if (poiInsidePolyCoords.length !== 0) {
        setPoiToDoorEndPointCoords([
          poiInsidePolyCoords[0]?.doorMarker?.longitude,
          poiInsidePolyCoords[0]?.doorMarker?.latitude,
        ]);
        coordsEndPoint = [
          poiInsidePolyCoords[0]?.doorMarker?.longitude,
          poiInsidePolyCoords[0]?.doorMarker?.latitude,
        ];
      }

      // ↓ Old code without poi inside polygon if
      // coordsEndPoint = [travelTo.coords?.longitude, travelTo.coords?.latitude];
    }
    if (travelTo.group === "Zones") {
      coordsEndPoint = [
        travelTo.doorMarker?.longitude,
        travelTo.doorMarker?.latitude,
      ];
    }

    const destinationPointTurfed = turf.point(coordsEndPoint);
    // console.log("🚀 ~ destinationPointTurfed", destinationPointTurfed);

    // ↓ Start Point (point selected where the user want to go)
    const coordsStartPoint = [];
    selectedConnector.geometry.coordinates.forEach((c) => {
      coordsStartPoint.push(c.longitude, c.latitude);
    });

    // ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
    const connectorPositionInSelectedFloor =
      selectedConnector?.properties?.connectorPositionOnFloors &&
      selectedConnector?.properties?.connectorPositionOnFloors.find(
        (marker) => marker.floorPlanId === travelTo.floorPlanId
      );
    // console.log(
    //   "🚀 ~ connectorPositionInSelectedFloor",
    //   connectorPositionInSelectedFloor
    // );

    const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
      connectorPositionInSelectedFloor.coords.longitude,
      connectorPositionInSelectedFloor.coords.latitude,
    ];
    // console.log(
    //   "🚀 ~ connectorPositionFetchedCoords",
    //   connectorPositionFetchedCoords
    // );

    const conditionalCoords = connectorPositionFetchedCoords
      ? connectorPositionFetchedCoords
      : coordsStartPoint;
    // console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

    setPoiToDoorCoords(conditionalCoords);
    setCopyOfStartPointCoords(conditionalCoords);

    // const startPointTurfed = turf.point(coordsStartPoint);
    const startPointTurfed = turf.point(conditionalCoords);
    // console.log("🚀 ~ startPointTurfed", startPointTurfed);

    const nearestStartPoint =
      startPointTurfed &&
      turf.nearestPoint(startPointTurfed, turf.featureCollection(points));
    // console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

    const nearestEndPoint =
      destinationPointTurfed &&
      turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points));
    // console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

    const start =
      nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates);
    // console.log("🚀 ~ start", start);

    const end =
      nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates);
    // console.log("🚀 ~ end", end);

    const path =
      start && end
        ? pathFinderOnSameFloor.findPath(start, end)
        : dispatch({
            type: "SNACKBAR_WARNING",
            payload: "Something went wrong with this path.",
          });
    // console.log("Path: ", path);
    // ↓ Sets path on different floor
    path?.path && setPathFound(path);

    // ↓ Closes directions box
    path?.path &&
      setGetDirections({
        type: null,
        poi: null,
        clicked: false,
      });

    // ↓ Empty connector Access marker
    path?.path &&
      setConnectorAccess({
        clicked: false,
        zIndex: "0",
        id: null,
      });
  }
}

export function addPOIMarkers(params) {
  const {
    map,
    pointsOfInterest,
    floorPlanId,
    iconsFromDB,
    setGetDirections,
    floorPlan,
    geoJsonRoutesUndefinedSnackBar,
    setTravelTo,
    setCopyOfStartPointCoords,
    setClickedToResetPath,
  } = params;

  var poiMarkers = [];
  pointsOfInterest.forEach((poi) => {
    if (poi.floorPlanId !== floorPlanId) {
      return;
    }

    // let marker = document.createElement("div")
    // marker.className = "poiMarkerDashboard"
    // marker.id = poi.id
    // marker.style.backgroundImage = `url(${blueBall})`

    const iconsMarkerImage =
      iconsFromDB && iconsFromDB.find((icon) => icon.name === poi.type);

    const namesWithoutWhiteSpace = iconsMarkerImage?.name.replace(/ +/g, "");

    // const markerIcon = iconsMarkerImage ? (
    //   <PopoverStickOnHover
    //     component={
    //       <div className='popoverCardDashboard'>
    //         <div className='popupMainDivDashboard'>
    //           <div className='popup2Dashboard'>
    //             {poi.imagesUrls?.length > 0 && <Carousel indicators={false} interval={1500}>
    //               {poi.imagesUrls.map((arrayUrl, i) => {
    //                 return <Carousel.Item key={i} interval={1500}>
    //                   <img
    //                     className='d-block w-100'
    //                     src={arrayUrl}
    //                     alt={poi.type}
    //                     style={{
    //                       borderRadius: "8px 8px 0px 0px",
    //                       height: "12vh",
    //                       objectFit: "cover",
    //                       objectPosition: "50% 50%",
    //                     }}
    //                   />
    //                 </Carousel.Item>
    //               })}
    //             </Carousel>}
    //             <div className='popupTextDashboard'>
    //               <h5 style={{ paddingBottom: "4px", paddingTop: "4px", }}                  >
    //                 <b>{poi.name}</b>
    //               </h5>
    //               <p style={{ marginBottom: "3px" }}>
    //                 Type: {poi.type}
    //               </p>
    //               <p style={{ paddingBottom: "1px" }}>
    //                 Description: {poi.description}
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     }
    //     placement='top'
    //     delay={200}
    //   >
    //     <img
    //       id={namesWithoutWhiteSpace}
    //       alt={iconsMarkerImage.name}
    //       className='iconImageDashboard'
    //       src={iconsMarkerImage.ufindUrl}
    //     />
    //   </PopoverStickOnHover>
    // ) : (
    //   <RoomIcon
    //     style={{
    //       transform: "rotate(45deg)",
    //     }}
    //   />
    // )

    /* ReactDOM.render(markerIcon, marker)
    const markerWithImage = new mapboxgl.Marker(marker)
      .setLngLat([poi.coords.longitude, poi.coords.latitude])
      .addTo(map) */

    const el2 = document.createElement("div");
    el2.style.width = poi.imagesUrls ? "225px" : "150px";
    const popupStick2 = (
      <>
        {poi.imagesUrls?.length > 0 && (
          <Carousel indicators={false} interval={1500}>
            {poi.imagesUrls.map((arrayUrl, i) => {
              return (
                <Carousel.Item key={i} interval={1500}>
                  <img
                    className='d-block w-100'
                    src={arrayUrl}
                    alt={poi.type}
                    style={{
                      borderRadius: "8px 8px 0px 0px",
                      height: "12vh",
                      objectFit: "cover",
                      objectPosition: "50% 50%",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
        <div className='p-4'>
          <div className='d-flex justify-content-between'>
            <h5 style={{ paddingBottom: "4px", paddingTop: "4px" }}>
              <b>{poi.name}</b>
            </h5>
            <Tooltip title='Get directions' placement='bottom'>
              <IconButton
                style={{
                  borderRadius: "50%",
                  border: "1px solid #D9D9D9",
                  marginRight: "1rem",
                  width: "3rem",
                  height: "3rem",
                }}
                onClick={() => {
                  if (!floorPlan?.geoJsonRoutes) {
                    geoJsonRoutesUndefinedSnackBar(floorPlan);
                    return;
                  }
                  // ↓ Select POI has start point in directions box
                  setGetDirections({
                    type: "poi",
                    poi: poi,
                    clicked: true,
                  });

                  // ↓ Set a Copy of Start Point (that doesn't closes the directions box)
                  setCopyOfStartPointCoords([
                    poi.coords.longitude,
                    poi.coords.latitude,
                  ]);
                  // ↓ Empty go to in directions box
                  setTravelTo();
                  // ↓ Reset Path
                  setClickedToResetPath(true);
                }}
              >
                <DirectionsIcon
                  fontSize='default'
                  style={{ color: "#303F9F" }}
                />
              </IconButton>
            </Tooltip>
          </div>
          <p style={{ marginBottom: "3px" }}></p>
          <p style={{ paddingBottom: "1px" }}>Description: {poi.description}</p>
        </div>
      </>
    );

    ReactDOM.render(popupStick2, el2);
    const popup = new mapboxgl.Popup({
      offset: 0,
      className: "pb-3",
    }).setDOMContent(el2);

    const elMarker = document.createElement("div");
    elMarker.className = "poiMarkerDashboard";
    elMarker.id = poi.id;
    elMarker.style.cursor = "pointer";
    elMarker.style.backgroundImage = `url(${blueBall})`;

    const markerHTML = (
      <img
        id={namesWithoutWhiteSpace}
        alt={iconsMarkerImage.name}
        className='iconImageDashboard'
        src={iconsMarkerImage.ufindUrl}
      />
    );
    ReactDOM.render(markerHTML, elMarker);
    const marker = new mapboxgl.Marker(elMarker)
      .setLngLat([poi.coords.longitude, poi.coords.latitude])
      .setPopup(popup)
      .addTo(map);

    const markerDiv = marker.getElement();
    var timeout;
    markerDiv.addEventListener("mouseenter", () => {
      //Clear timeout to prevent acidental removal
      timeout && clearTimeout(timeout);

      //Don't toggle popup if it already exists
      const popupElBefore = popup.getElement();
      !popupElBefore && marker.togglePopup();

      const popupEl = popup.getElement();
      popupEl &&
        popupEl.addEventListener("mouseenter", () => {
          popupEl.id = "over";
        });
      popupEl &&
        popupEl.addEventListener("mouseleave", () => {
          popupEl.id = "";
          popup.remove();
        });
    });
    markerDiv.addEventListener("mouseleave", () => {
      timeout = setTimeout(() => {
        const popupEl = popup.getElement();
        popupEl?.id !== "over" && popup.remove();
      }, 250);
    });

    poiMarkers.push(marker);
  });
  return poiMarkers;
}

export function addSensorMarkers(params) {
  const { map, sensors, floorPlanId, iconsFromDB } = params;

  var sensorMarkers = [];
  sensors.forEach((sensor) => {
    if (
      sensor.floorPlanId !== floorPlanId ||
      (sensor.model !== "MT10" && sensor.model !== "MT11")
    ) {
      return;
    }

    //Get sensor readings and dump all into array
    const sensorData = Object.keys(sensor.readings)
      .map((read) => {
        return Object.keys(sensor.readings[read]).map(
          (val) => sensor.readings[read][val]
        );
      })
      .flat()
      .filter((val) => typeof val.update === "object");

    const sensorTest = sensorData
      .map((val) => [val.update.toDate().getTime(), val.temperature || 0])
      .sort(([a, b], [c, d]) => c - a || b - d);

    const sensorTest2 = sensorData
      .map((val) => [val.update.toDate().getTime(), val.humidity || 0])
      .sort(([a, b], [c, d]) => c - a || b - d);

    const iconsMarkerImage = iconsFromDB.find(
      (icon) => icon.name === "Thermostat"
    );

    const namesWithoutWhiteSpace = iconsMarkerImage?.name.replace(/ +/g, "");

    const el2 = document.createElement("div");
    el2.style.width = "400px";

    const popupStick2 = (
      <>
        <div className='row m-0 p-2 pb-0'>
          <h3 className='col-12 d-flex justify-content-center'>
            {sensor.name}
          </h3>
          <div className='col-12 d-flex justify-content-center align-items-center'>
            <BsThermometerHalf style={{ height: "90%", width: "auto" }} />
            <h3 className='m-0'>
              {sensor.temperature ? sensor.temperature.toFixed(0) : 0}ºC
            </h3>
            <div style={{ width: "10px" }} />
            <WiHumidity style={{ height: "100%", width: "auto" }} />
            <h3 className='m-0'>
              {sensor.humidity ? sensor.humidity.toFixed(0) : 0}%
            </h3>
          </div>
        </div>
        <Chart
          style={{ margin: 0 }}
          options={{
            chart: {
              height: 250,
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
              },
            },
            xaxis: {
              type: "datetime",
              labels: {
                format: "HH:mm",
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: [
              {
                labels: {
                  formatter: function(val) {
                    return val.toFixed(0) + "ºC";
                  },
                },
                title: {
                  text: "Temperature",
                },
              },
              {
                seriesName: "Humidity",
                opposite: true,
                max: 100,
                min: 0,
                tickAmount: 5,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: "#00E396",
                },
                labels: {
                  style: {
                    colors: "#00E396",
                  },
                },
                title: {
                  text: "Humidity",
                },
                labels: {
                  formatter: function(val) {
                    return val.toFixed(0) + "%";
                  },
                },
              },
            ],
            tooltip: {
              shared: false,
              x: {
                format: "MMM dd, HH:mm",
              },
            },
          }}
          series={[
            {
              name: "Temperature",
              data: sensorTest,
            },
            {
              name: "Humidity",
              data: sensorTest2,
            },
          ]}
          type='line'
          height={250}
        />
      </>
    );

    ReactDOM.render(popupStick2, el2);
    const popup = new mapboxgl.Popup({
      offset: 0,
      className: "mw-100 pb-3",
    }).setDOMContent(el2);

    const elMarker = document.createElement("div");
    elMarker.className = "live-icon-marker";
    elMarker.id = sensor.id;
    elMarker.style.backgroundSize = "36px";
    elMarker.style.backgroundPosition = "center";
    elMarker.style.opacity = "0.9";
    elMarker.style.backgroundImage = `url(${purpleBall})`;
    const eventDiv = document.createElement("div");
    eventDiv.style.width = "100%";
    eventDiv.style.height = "100%";
    eventDiv.style.position = "absolute";
    eventDiv.style.left = "50%";
    eventDiv.style.top = "50%";
    eventDiv.style.transform = "translate(-50%, -50%)";
    eventDiv.style.pointerEvents = "auto";
    elMarker.appendChild(eventDiv);

    const markerHTML = (
      <img
        id={namesWithoutWhiteSpace}
        alt={iconsMarkerImage?.name}
        className='iconImageDashboard'
        src={iconsMarkerImage?.ufindUrl}
      />
    );
    ReactDOM.render(markerHTML, eventDiv);
    const marker = new mapboxgl.Marker(elMarker)
      .setLngLat([sensor.lng, sensor.lat])
      .setPopup(popup)
      .addTo(map);

    // const markerDiv = marker.getElement()
    const markerDiv = elMarker.childNodes[0];
    // console.log("elMarker: ", elMarker)
    // console.log("markerDiv: ", markerDiv)
    var timeout;
    markerDiv &&
      markerDiv.addEventListener("mouseenter", () => {
        //Clear timeout to prevent acidental removal
        timeout && clearTimeout(timeout);

        //Don't toggle popup if it already exists
        const popupElBefore = popup.getElement();
        !popupElBefore && marker.togglePopup();

        const popupEl = popup.getElement();
        popupEl &&
          popupEl.addEventListener("mouseenter", () => {
            popupEl.id = "over";
          });
        popupEl &&
          popupEl.addEventListener("mouseleave", () => {
            popupEl.id = "";
            popup.remove();
          });
      });
    markerDiv &&
      markerDiv.addEventListener("mouseleave", () => {
        timeout = setTimeout(() => {
          const popupEl = popup.getElement();
          popupEl?.id !== "over" && popup.remove();
        }, 250);
      });

    sensorMarkers.push(marker);
  });
  return sensorMarkers;
}

export function addApMarkers(params) {
  const { map, aps } = params;

  const filter = aps.filter((ap) => ap.model.startsWith("MR"));

  var apMarkers = [];

  filter.forEach((d) => {
    var markerDiv = document.createElement("div");
    ReactDOM.render(
      <div className='pinBlack'>
        <div className='letterDiv'>
          <a className='letterText'>
            <RouterIcon style={{ fontSize: "17px" }} />
          </a>
        </div>
      </div>,
      markerDiv
    );

    const marker = new mapboxgl.Marker(markerDiv, { anchor: "center" })
      .setLngLat([d.lng, d.lat])
      .addTo(map);

    apMarkers.push(marker);
  });

  return apMarkers;
}

export function addZoneMarkers(params) {
  const {
    map,
    floorPlan,
    zones,
    resources,
    setGetDirections,
    geoJsonRoutesUndefinedSnackBar,
    setTravelTo,
    setCopyOfStartPointCoords,
    setClickedToResetPath,
  } = params;

  const zoneMarkers = [];
  floorPlan.geoJson.features.forEach((feat) => {
    if (feat.properties.type === "baseLayer") {
      return;
    }

    const coordinates = feat.geometry.coordinates.map((coords) => {
      return [coords.longitude, coords.latitude];
    });
    const feature = {
      ...feat,
      geometry: {
        type: feat.geometry.type,
        coordinates: [coordinates],
      },
    };

    // ↓ Zone markers
    const poly2 = turf.polygon(feature?.geometry?.coordinates);
    const pointOnPolygon = turf.pointOnFeature(poly2); // ← Get point inside each polygon.
    const pointOnPolygonLng = pointOnPolygon.geometry.coordinates[0];
    const pointOnPolygonLat = pointOnPolygon.geometry.coordinates[1];

    // Zone in DB with same id as feature
    const fetchZoneWithMarkerCoords =
      zones && zones.find((zone) => zone.id === feat.properties.zoneId);
    const fetchedLng = fetchZoneWithMarkerCoords?.marker?.longitude;
    const fetchedLat = fetchZoneWithMarkerCoords?.marker?.latitude;
    const conditionalLng = fetchedLng ? fetchedLng : pointOnPolygonLng;
    const conditionalLat = fetchedLat ? fetchedLat : pointOnPolygonLat;

    const bookableZone =
      resources &&
      resources.find((r) => {
        return (
          r.zone === feat.properties.zoneId && r.floorPlanId === floorPlan.id
        );
      });

    const isVisible = feat.properties.visible === true ? "block" : "none";

    const noPropertyInDBCondition =
      feature.properties.visible !== undefined ? isVisible : "block";
    // console.log("🚀 ~ noPropertyInDBCondition", noPropertyInDBCondition);

    const el = document.createElement("div");
    el.style.cursor = "pointer";
    el.style.display = noPropertyInDBCondition;
    el.className = bookableZone
      ? "zoneMarkersDashboardBookable"
      : "zoneMarkersDashboard";

    // ↓ Popup in marker
    const popupStick = (
      <PopoverStickOnHover
        component={
          <div className='popoverCardDashboard'>
            <div className='popupMainDivDashboard'>
              <div className='popup2Dashboard'>
                {feat.properties?.zoneImagesUrls &&
                  feat.properties.zoneImagesUrls.length !== 0 && (
                    <Carousel indicators={false} interval={1500}>
                      {feat.properties.zoneImagesUrls.map((arrayUrl, i) => (
                        <Carousel.Item key={i} interval={1500}>
                          <img
                            className='d-block w-100'
                            src={arrayUrl}
                            alt={feat.type}
                            style={{
                              borderRadius: "8px 8px 0px 0px",
                              height: "12vh",
                              objectFit: "cover",
                              objectPosition: "50% 50%",
                            }}
                          />
                        </Carousel.Item>
                      ))}{" "}
                    </Carousel>
                  )}

                <div className='popupTextDashboard'>
                  <div className='d-flex justify-content-between'>
                    <h5 style={{ paddingBottom: "4px", paddingTop: "4px" }}>
                      <b>{feat.properties.name}</b>
                    </h5>
                    <Tooltip title='Get directions' placement='bottom'>
                      <IconButton
                        style={{
                          borderRadius: "50%",
                          border: "1px solid #D9D9D9",
                          marginRight: "1rem",
                          width: "3rem",
                          height: "3rem",
                        }}
                        onClick={() => {
                          // ↓ if no routes drawn display message to user
                          if (
                            !floorPlan?.geoJsonRoutes ||
                            floorPlan?.geoJsonRoutes.features.length === 0
                          ) {
                            geoJsonRoutesUndefinedSnackBar(floorPlan);
                            return;
                          }
                          // ↓ Select Zone has start point in directions box
                          setGetDirections({
                            type: "zone",
                            poi: feat,
                            clicked: true,
                          });
                          // Set a copy of start point (that doesn't close directions box)
                          // Zone in DB with same id as feature
                          const fetchZoneWithMarkerCoords =
                            zones &&
                            zones.find(
                              (zone) => zone.id === feat.properties.zoneId
                            );

                          // console.log(
                          //   "🚀 ~ fetchZoneWithMarkerCoords",
                          //   fetchZoneWithMarkerCoords
                          // );
                          const fetchedLng =
                            fetchZoneWithMarkerCoords?.doorMarker?.longitude;
                          const fetchedLat =
                            fetchZoneWithMarkerCoords?.doorMarker?.latitude;
                          setCopyOfStartPointCoords([fetchedLng, fetchedLat]);
                          // ↓ Empty go to in directions box
                          setTravelTo();
                          // ↓ Reset Path
                          setClickedToResetPath(true);
                        }}
                      >
                        <DirectionsIcon
                          fontSize='default'
                          style={{ color: "#303F9F" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <p style={{ marginBottom: "3px" }}>
                    Type: {feat.properties.type}
                  </p>
                  <p
                    style={{
                      marginBottom: "3px",
                      paddingBottom: bookableZone ? "0px" : "1.5rem",
                    }}
                  >
                    Capacity: {feat.properties.capacity}
                  </p>
                  {bookableZone && (
                    <p style={{ marginBottom: "1.5rem" }}>
                      <b>
                        <span>Bookable zone</span>
                        <a
                          href='/booking'
                          style={{
                            marginLeft: "5px",
                            color: "#323389",
                            textDecoration: "underline",
                          }}
                        >
                          book now
                        </a>
                      </b>
                    </p>
                  )}
                  {/* <Button
                    variant='contained'
                    color='primary'
                    style={{
                      display: "flex",
                      marginTop: "-7px",
                      height: "2rem",
                      marginBottom: "1rem",
                      color: "white",
                      width: "11rem",
                      fontSize: "9px",
                    }}
                    onClick={() => {
                      if (!floorPlan?.geoJsonRoutes) {
                        geoJsonRoutesUndefinedSnackBar();
                        return;
                      }
                      setGetDirections({
                        type: "zone",
                        poi: feat,
                        clicked: true,
                      });
                    }}
                  >
                    Get directions&nbsp; &nbsp;
                    <DirectionsIcon
                      fontSize='small'
                      style={{ fontSize: "11px", marginBottom: "1px" }}
                    />
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        }
        placement='top'
        delay={200}
      >
        <div
          className={
            bookableZone
              ? "markerZoneDivDashboard"
              : "markerZoneDivDashboardSmall"
          }
        >
          {" "}
          <BsInfoLg fontSize={bookableZone ? 14 : 10} />
        </div>
      </PopoverStickOnHover>
    );

    ReactDOM.render(popupStick, el);
    const marker = new mapboxgl.Marker(el)
      .setLngLat([conditionalLng, conditionalLat])
      .addTo(map);
    zoneMarkers.push(marker);
  });
  return zoneMarkers;
}

export function handleZonesColor(params) {
  const {
    map,
    floorPlan,
    layerTypes,
    devices,
    guests,
    visitors,
    showOccupancy,
  } = params;

  floorPlan.geoJson.features.forEach((feat, index) => {
    const allCoords = feat.geometry.coordinates.map((val) => [
      val.longitude,
      val.latitude,
    ]);
    const employeesCount = devices
      ? devices.filter((val) => {
          return turf.booleanContains(
            turf.polygon([allCoords]),
            turf.point([val.gpsData.longitude, val.gpsData.latitude])
          );
        }).length
      : 0;

    const guestsCount = guests
      ? guests.filter((val) => {
          return turf.booleanContains(
            turf.polygon([allCoords]),
            turf.point([val.gpsData.longitude, val.gpsData.latitude])
          );
        }).length
      : 0;

    const visitorsCount = visitors
      ? visitors.filter((val) => {
          return turf.booleanContains(
            turf.polygon([allCoords]),
            turf.point([val.gpsData.longitude, val.gpsData.latitude])
          );
        }).length
      : 0;

    if (showOccupancy) {
      const totalPeople = employeesCount + guestsCount + visitorsCount;
      const percentage =
        totalPeople === 0 ? 0 : (totalPeople * 100) / feat.properties.capacity;
      const color =
        percentage >= 90 ? "red" : percentage >= 60 ? "#fcba03" : "green";
      map.setPaintProperty(`geoJsonLayer-${index}`, "fill-color", color);
    } else {
      const currentType = layerTypes?.find((type) => {
        return type.id === feat.properties.type;
      });

      map.setPaintProperty(
        `geoJsonLayer-${index}`,
        "fill-color",
        currentType?.color || "#000000"
      );
    }
  });
}

export async function addDevicesToMapHeatmap(props) {
  const {
    map,
    devices,
    blePeople,
    bleThings,
    users,
    guests,
    visitors,
    showVisitors,
    // heatCoords,
  } = props;

  // const _heatCoords =
  //   heatCoords &&
  //   heatCoords.map((c) => {
  //     return {
  //       type: "Feature",
  //       properties: {},
  //       geometry: {
  //         type: "Point",
  //         coordinates: [c[0], c[1], 0.0],
  //       },
  //     };
  //   });

  // console.log(_heatCoords, "_heatCoords");

  const _devices = devices
    ? devices.map((d) => {
        return {
          type: "Feature",
          properties: {
            id: d?.clientMac,
            user:
              users &&
              users.find(
                (val) =>
                  val &&
                  val.devicesArray &&
                  val?.devicesArray.includes(d?.clientMac)
              ),
            type: "E",
            color: d.accurate ? "#9ce660" : "#fa4d4d",
            outline: d.accurate ? "#5e9630" : "#b03333",
          },
          geometry: {
            type: "Point",
            coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
          },
        };
      })
    : [];

  const _blePeople = blePeople
    ? blePeople.map((d) => {
        return {
          type: "Feature",
          properties: {
            id: d.macAddress,
            type: "E",
            color: d.accurate ? "#9ce660" : "#fa4d4d",
            outline: d.accurate ? "#5e9630" : "#b03333",
          },
          geometry: {
            type: "Point",
            coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
          },
        };
      })
    : [];

  // const _bleThings = bleThings
  //   ? bleThings.map((d) => {
  //       return {
  //         type: "Feature",
  //         properties: {
  //           id: d.macAddress,
  //           //!Remove, only used for testing
  //           user:
  //             users &&
  //             users.find(
  //               (val) =>
  //                 val.devicesArray && val.devicesArray.includes(d.macAddress)
  //             ),
  //           type: "T",
  //           color: d.accurate ? "#9ce660" : "#fa4d4d",
  //           outline: d.accurate ? "#5e9630" : "#b03333",
  //         },
  //         geometry: {
  //           type: "Point",
  //           coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
  //         },
  //       };
  //     })
  //   : [];

  const _guests = guests
    ? guests.map((d) => {
        return {
          type: "Feature",
          properties: {
            id: d.clientMac,
            type: "G",
            color: d.accurate ? "#9ce660" : "#fa4d4d",
            outline: d.accurate ? "#5e9630" : "#b03333",
          },
          geometry: {
            type: "Point",
            coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
          },
        };
      })
    : [];

  const _visistors = visitors
    ? // && showVisitors
      visitors.map((d) => {
        return {
          type: "Feature",
          properties: {
            id: d.clientMac,
            type: "V",
            color: d.accurate ? "#9ce660" : "#fa4d4d",
            outline: d.accurate ? "#5e9630" : "#b03333",
          },
          geometry: {
            type: "Point",
            coordinates: [d.gpsData.longitude, d.gpsData.latitude, 0.0],
          },
        };
      })
    : [];

  const features = [
    ..._devices,
    ..._blePeople,
    // ..._bleThings,
    ..._guests,
    ..._visistors,
    // ..._heatCoords,
  ];

  // console.log("🚀 ~ _heatCoords.length heatmap", _heatCoords.length);
  // console.log("🚀 ~ _visistors heatmap", _visistors);

  // Remove layer and source if they already exist
  if (typeof map.getLayer("occup-heat") !== "undefined") {
    map.removeLayer("occup-heat");
  }
  if (typeof map.getLayer("heatmap-point") !== "undefined") {
    map.removeLayer("heatmap-point");
  }
  if (typeof map.getSource("occupancy") !== "undefined") {
    map.removeSource("occupancy");
  }

  map.addSource("occupancy", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: features,
    },
  });

  map.addLayer(
    {
      id: "occup-heat",
      // type: "heatmap",
      type: "heatmap",
      source: "occupancy",
      // maxzoom: 15,
      layout: {
        // "icon-image": "marker-15",
        visibility: "visible",
      },
      // paint: {
      //   "circle-radius": 6,
      //   "circle-color": "#B42222",
      // },
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        // "heatmap-weight": [
        //   "interpolate",
        //   ["linear"],
        //   ["get", "mag"],
        //   0,
        //   0,
        //   6,
        //   1,
        // ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight // ↓ More intensity of less
        "heatmap-intensity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0.9,
          0.9,
          0.9,
        ],

        // "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 0, 255, 0)",
          0.1,
          "#ffffb2",
          0.3,
          "#feb24c",
          0.5,
          "#fd8d3c",
          0.7,
          "#fc4e2a",
          1,
          "#e31a1c",
        ],
        // "heatmap-color": [
        //   "interpolate",
        //   ["linear"],
        //   ["heatmap-density"],
        //   0,
        //   "rgba(33,102,172,0)",
        //   0.2,
        //   "rgb(103,169,207)",
        //   0.4,
        //   "rgb(209,229,240)",
        //   0.6,
        //   "rgb(253,219,199)",
        //   0.8,
        //   "rgb(239,138,98)",
        //   1,
        //   "rgb(178,24,43)",
        // ],
        // Adjust the heatmap radius by zoom level
        // "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
        "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
        // Transition from heatmap to circle layer by zoom level
        "heatmap-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          0.7,
          9,
          0.8,
        ],
        // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
      },
    }
    // "country-label"
    // "pins"
  );

  // map && console.log(map.getLayer("occup-heat"), "occup-heat");

  // map && console.log(map.getStyle().layers, "layers");
}

export function searchHandleMap(params) {
  const {
    locations,
    map,
    floorPlan,
    goTo,
    setGoTo,
    coords,
    polygon,
    dispatch,
  } = params;
  const padding = { padding: { top: 10, bottom: 10, left: 10, right: 10 } };

  typeof map.getLayer("goToThingMarker") !== "undefined" &&
    map.removeLayer("goToThingMarker");
  typeof map.getSource("goToThingMarker") !== "undefined" &&
    map.removeSource("goToThingMarker");

  if (!goTo) {
    if (floorPlan.mapOrientation) {
      const lat = floorPlan.mapOrientation.center.latitude;
      const lng = floorPlan.mapOrientation.center.longitude;
      map.flyTo({
        center: [lng, lat],
        zoom: floorPlan.mapOrientation.zoom,
        bearing: floorPlan.mapOrientation.bearing,
        pitch: floorPlan.mapOrientation.pitch,
      });
    } else {
      map.fitBounds(
        [
          [floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
          [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
        ],
        { padding: { top: 10, bottom: 10, left: 10, right: 10 } }
      );
    }
    setGoTo();
    return;
  }

  if (!coords && !polygon) {
    dispatch({
      type: "SNACKBAR_WARNING",
      payload: "No location found",
    });
    if (floorPlan.mapOrientation) {
      const lat = floorPlan.mapOrientation.center.latitude;
      const lng = floorPlan.mapOrientation.center.longitude;
      map.flyTo({
        center: [lng, lat],
        zoom: floorPlan.mapOrientation.zoom,
        bearing: floorPlan.mapOrientation.bearing,
        pitch: floorPlan.mapOrientation.pitch,
      });
    } else {
      map.fitBounds(
        [
          [floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
          [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
        ],
        { padding: { top: 10, bottom: 10, left: 10, right: 10 } }
      );
    }
    return;
  }

  if (floorPlan.id === goTo.floorPlanId) {
    if (polygon) {
      var features = turf.featureCollection(polygon);
      var enveloped = turf.envelope(features);
      if (floorPlan.mapOrientation) {
        map.setPitch(floorPlan.mapOrientation.pitch);
        map.fitBounds(enveloped.bbox, {
          bearing: floorPlan.mapOrientation.bearing,
        });
      } else {
        map.fitBounds(enveloped.bbox, padding);
      }
      return;
    }
    //Add thing to map while it is being searched
    map.addSource("goToThingMarker", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
          },
        ],
      },
    });

    map.addLayer({
      id: "goToThingMarker",
      type: "circle",
      source: "goToThingMarker",
    });

    if (floorPlan.mapOrientation) {
      map.flyTo({
        center: coords,
        zoom: 24,
        bearing: floorPlan.mapOrientation.bearing,
        pitch: floorPlan.mapOrientation.pitch,
      });
    } else {
      map.flyTo({
        center: coords,
        zoom: 24,
      });
    }
    setGoTo(goTo);
    return;
  }

  if (floorPlan.mapOrientation) {
    const lat = floorPlan.mapOrientation.center.latitude;
    const lng = floorPlan.mapOrientation.center.longitude;
    map.flyTo({
      center: [lng, lat],
      zoom: floorPlan.mapOrientation.zoom,
      bearing: floorPlan.mapOrientation.bearing,
      pitch: floorPlan.mapOrientation.pitch,
    });
  } else {
    map.fitBounds(
      [
        [floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
        [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
      ],
      { padding: { top: 10, bottom: 10, left: 10, right: 10 } }
    );
  }

  map.once("moveend", () => {
    const loc = locations?.find((val) => val.floorId === goTo.floorPlanId);
    loc && dispatch(actions.levelSelected(loc));
    setGoTo(goTo);
    setTimeout(() => {
      if (polygon) {
        var features = turf.featureCollection(polygon);
        var enveloped = turf.envelope(features);
        if (floorPlan.mapOrientation) {
          map.setPitch(floorPlan.mapOrientation.pitch);
          map.fitBounds(enveloped.bbox, {
            bearing: floorPlan.mapOrientation.bearing,
          });
        } else {
          map.fitBounds(enveloped.bbox, padding);
        }
        return;
      }

      //Add thing to map while it is being searched
      map.addSource("goToThingMarker", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: coords,
              },
            },
          ],
        },
      });

      map.addLayer({
        id: "goToThingMarker",
        type: "circle",
        source: "goToThingMarker",
      });

      if (floorPlan.mapOrientation) {
        map.flyTo({
          center: coords,
          zoom: 24,
          bearing: floorPlan.mapOrientation.bearing,
          pitch: floorPlan.mapOrientation.pitch,
        });
      } else {
        map.flyTo({
          center: coords,
          zoom: 24,
        });
      }
    }, 1000);
  });
}
