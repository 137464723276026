import React from "react";
import ReactDOM from "react-dom";
import { profileSlice } from "../../../../../redux/profile/profileSlice";
import { Tooltip } from "@material-ui/core";
import { TiArrowBack } from "react-icons/ti";

const { actions } = profileSlice;

export default class BackToAllLocations {
  constructor(props) {
    this.props = props;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";
    this.container.id = "backToAllLocationsControlId";
    // this.fullscreen = false;

    this.content = <OccupancyControlComponent props={this.props} />;

    ReactDOM.render(this.content, this.container);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

class OccupancyControlComponent extends React.Component {
  state = {
    occupancy: this.props.showOccupancy,
  };

  handleClick = (val) => {
    if (document.getElementById("toolTipAllLocGlobal")) {
      const element = document.getElementById("toolTipAllLocGlobal");
      // removes the element from the DOM
      element.remove();
    }
    if (document.getElementById("toolTipAllLocGlobalDiv")) {
      const element2 = document.getElementById("toolTipAllLocGlobalDiv");
      // removes the element from the DOM
      element2.remove();
    }

    this.props.props.dispatch(actions.locationSelected());
  };

  render() {
    // console.log(this.props.props, "props");

    return (
      <Tooltip
        title={"Return to all Locations view"}
        placement='bottom'
        arrow
        id='toolTipAllLocGlobal'
      >
        <div
          style={{
            display: !this.props.props.allFloors
              ? "none"
              : this.props.props.singleLocation
              ? "none"
              : "block",
          }}
          id='toolTipAllLocGlobalDiv'
          className={
            this.state.occupancy
              ? "occupancy-button-pressed"
              : "occupancy-button"
          }
          /*  style={{
          backgroundColor: this.state.occupancy ? "rgb(150, 150, 150)" : "white"
        }} */
        >
          <TiArrowBack
            onClick={this.handleClick}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </Tooltip>
    );
  }
}
