import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useUIContext } from "../UIContext";
import * as actions from "../../_redux/reporting/reportingActions";
import { firestore as db } from "../../../../firebase";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Chart from "react-apexcharts";
import {
  LinearProgress,
  withStyles,
  Badge,
  Popover,
  makeStyles,
} from "@material-ui/core";
import { Nav, Tab } from "react-bootstrap";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -12,
    top: 6,
    fontSize: "12px",
    // border: "1px solid black",
    padding: "0",
  },
}))(Badge);

export function TeamsAtOffice() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  //★━━━━━━━━━━━━━━━★ Selectors ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  const { isLoading, customer, teamsFloorOccupancy } = useSelector(
    (state) => ({
      isLoading: state.reporting.listLoading,
      customer: state.profile?.currentCustomer,
      teamsFloorOccupancy: state?.reporting,
    }),
    shallowEqual
  );

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  const [graphData, setGraphData] = useState();
  const [usersWithDepartment, setUsersWithDepartment] = useState();
  const [teamsData, setTeamsData] = useState();

  // Get all users from db
  useEffect(() => {
    let mounted = true;
    const employees = UIProps.queryParams.departments?.map(
      (val) => val.employees
    );
    const employeesFlat = employees?.flat();

    employeesFlat &&
      Promise.all(
        employeesFlat.map((val) => {
          return db
            .collection("Customers")
            .doc(`${customer.customerId}`)
            .collection("Users")
            .doc(val)
            .get();
        })
      )
        .then((result) => {
          // console.log("result: ", result);
          const finalResult = result.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          if (mounted) {
            // console.log(finalResult, "finalResult");
            setUsersWithDepartment(finalResult);
          }
        })
        .catch((err) => console.log("Error: ", err));
    return () => (mounted = false);
  }, [UIProps.queryParams.departments, customer.customerId]);

  useEffect(() => {
    UIProps.queryParams.departments &&
      UIProps.queryParams.departments.forEach((department) => {
        // console.log(department, "department");
        dispatch(
          actions.fetchAllTeamsOccupancy({
            customer: customer,
            queryParams: UIProps.queryParams,
            users: usersWithDepartment,
            department: department,
          })
        );
      });
  }, [customer, UIProps.queryParams, usersWithDepartment]);

  useEffect(() => {
    const teams =
      UIProps.queryParams.departments &&
      UIProps.queryParams.departments.map((department) => {
        return teamsFloorOccupancy[department.id];
      });
    const flattenTeams = teams?.flat().filter((val) => val);

    setTeamsData(flattenTeams);
    setGraphData();
  }, [UIProps.queryParams.departments, teamsFloorOccupancy]);

  useEffect(() => {
    if (teamsData?.length > 0) {
      const mappedSeries =
        teamsData &&
        teamsData
          .map((val) => {
            return val?.series;
            // console.log(val?.series, "name");
          })
          .flat();

      const labels = teamsData[0].labels;
      const colors = teamsData[0].color;
      if (UIProps.queryParams.timeframe.includes("day")) {
        setGraphData({
          series: mappedSeries,
          labels: labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              //   return moment(val).format("ddd MMM D");
              // },
              style: {
                colors: colors,
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
            range: 3 * 24 * 3600000,
          },
        });
      }
      if (UIProps.queryParams.timeframe.includes("week")) {
        setGraphData({
          series: mappedSeries,
          labels: labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              // moment(val).format("ddd MMM D"),
              style: {
                colors: colors,
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
          },
        });
      }
      if (UIProps.queryParams.timeframe.includes("month")) {
        setGraphData({
          series: mappedSeries,
          labels: labels,
          xAxisRange: {
            type: "category",
            labels: {
              formatter: (val) => val,
              style: {
                colors: [
                  "#626768",
                  "#626768",
                  "#626768",
                  "#626768",
                  "#626768",
                  "#2ec4b6",
                  "#F9CE1D",
                ],
                fontWeight: 500,
              },
            },
            tickPlacement: "between",
          },
        });
      }
    }
  }, [UIProps.queryParams.timeframe, teamsData]);

  useEffect(() => {
    // console.log(teamsData, "teamsData");
    // console.log(graphData, "graphData ");
    // console.log(series, "series");
  }, [teamsData, graphData]);

  // Popover code ↓
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handlePopoverOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  // ↑

  return (
    <Card style={{ height: "480px" }}>
      <CardHeader
        title='Departments'
        // title={
        //   <StyledBadge
        //     badgeContent={
        //       <>
        //         <span
        //           className='w-100 text-center'
        //           onMouseEnter={handlePopoverOpen2}
        //           onMouseLeave={handlePopoverClose2}
        //         >
        //           <HelpOutlineIcon style={{ fontSize: "1.4rem" }} />
        //         </span>
        //         <Popover
        //           id='mouse-over-popover'
        //           className={classes.popover}
        //           classes={{
        //             paper: classes.paper2,
        //           }}
        //           open={open2}
        //           anchorEl={anchorEl2}
        //           anchorOrigin={{
        //             vertical: "bottom",
        //             horizontal: "left",
        //           }}
        //           transformOrigin={{
        //             vertical: "top",
        //             horizontal: "left",
        //           }}
        //           onClose={handlePopoverClose2}
        //           disableRestoreFocus
        //         >
        //           <div style={{ width: "250px", wordWrap: "break-word" }}>
        //             <div className='pb-3'>
        //               <b>People Floor Distribution: </b> <br /> <br />
        //               Represents the average of the total amount of people on
        //               every floor per day.
        //             </div>
        //           </div>
        //         </Popover>
        //       </>
        //     }
        //   >
        //     Team A
        //   </StyledBadge>
        // }
      />
      <CardBody>
        {isLoading && <LinearProgress color='secondary' />}
        {graphData ? (
          <Chart
            options={getChartOptions(graphData)}
            series={graphData.series}
            type='line'
            height={350}
          />
        ) : (
          <p>Not data to display in this chart.</p>
        )}
      </CardBody>
    </Card>
  );
}

function getChartOptions(graphData) {
  // console.log(graphData, "graphData Teams");
  return {
    chart: {
      type: "line",
      height: 350,
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    stroke: {
      show: true,
      width: 0,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: true,
      // enabledOnSeries: [graphData.series.length - 1],
      style: {
        colors: ["#546E7A"],
      },
    },
    labels: graphData.labels,
    xaxis: graphData.xAxisRange,
    responsive: [
      {
        breakpoint: 480,
        // options: {
        //   // legend: {
        //   //   position: "bottom",
        //   //   offsetX: -10,
        //   //   offsetY: 0,
        //   // },
        // },
      },
    ],
    yaxis: {
      title: {
        text: "People count",
      },
    },
  };
}
