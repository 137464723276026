import * as requestFromServer from "./basePageCrud";
import { basePageSlice, callTypes } from "./basePageSlice";
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers";

const { actions } = basePageSlice;

export const fetchFloorPlans = (customerId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!customerId) {
    dispatch(actions.floorPlansFetched({ entities: [] }));
  }

  return requestFromServer
    .getFloorPlans(customerId)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const floorPlans = convertCollectionSnapshotToMap(response);

      floorPlans &&
        dispatch(actions.floorPlansFetched({ entities: floorPlans }));
    })
    .catch((error) => {
      console.log("Fetch floor plans: ", error);
      error.clientMessage = "Can't find floorplans";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZones = (customerId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!customerId) {
    dispatch(actions.zonesFetched({ entities: [] }));
  }

  return requestFromServer
    .getZones(customerId)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const zones = convertCollectionSnapshotToMap(response);

      dispatch(actions.zonesFetched({ entities: zones }));
    })
    .catch((error) => {
      console.log("Fetch zones: ", error);
      error.clientMessage = "Can't find zones";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDepartments = (customerId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!customerId) {
    dispatch(actions.departmentFetched({ entities: [] }));
  }

  return requestFromServer
    .getDepartments(customerId)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const departments =
        response.map((val) => convertCollectionSnapshotToMap(val))?.flat() ||
        [];
      //const departments = convertCollectionSnapshotToMap(response)

      dispatch(actions.departmentFetched(departments[0]));
    })
    .catch((error) => {
      console.log("Fetch departments: ", error);
      error.clientMessage = "Can't find departments";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchResources = (queryparams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryparams) {
    dispatch(actions.resourcesFetched({ entities: [] }));
  }

  return requestFromServer
    .getResources(queryparams)
    .then((response) => {
      if (!response) {
        throw Error("Can't make a request for server");
      }

      const resources = convertCollectionSnapshotToMap(response);

      resources && dispatch(actions.resourcesFetched({ entities: resources }));
    })
    .catch((error) => {
      console.log("fetch zones ", error);
      error.clientMessage = "Can't find zones";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
