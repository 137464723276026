import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
   isLoading: false,
   lastError: null,
}

export const userSlice = createSlice({
   name: "userSlice",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`
         if (action.payload.type === "updatePassword") {
            state.passwordUpdating = false
         } else if (action.payload.type === "forgotPassword") {
            state.sendingEmail = false
         } else {
            state.isLoading = false
         }
      },
      startCall: (state, action) => {
         state.error = null
         if (action.payload === "updatePassword") {
            state.passwordUpdating = true
         } else if (action.payload === "forgotPassword") {
            state.sendingEmail = true
         } else {
            state.isLoading = true
         }
      },
      userFetched: (state, action) => {
         state.error = null
         state.isLoading = false
         state.user = action.payload
      },
      buddiesFetched: (state, action) => {
         state.error = null
         state.isLoading = false
         state.buddies = action.payload
      },
      usersFetched: (state, action) => {
         state.error = null
         state.isLoading = false
         state.users = action.payload
      },
      userUpdated: (state, action) => {
         state.error = null
         state.isLoading = false
      },
      passwordUpdated: (state, action) => {
         state.error = null
         state.passwordUpdating = false
      },
      passwordResetEmailSent: (state, action) => {
         state.error = null
         state.sendingEmail = false
      },
   },
})
