import { all } from "redux-saga/effects"
import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { profileSlice } from "../app/redux/profile/profileSlice";
import { userSlice } from "../app/modules/_redux/user/userSlice";
import { uiReducer } from "../app/globalUI/snackBar.duck";
import { basePageSlice } from "../app/redux/basePage/basePageSlice";
import { liveDataSlice } from "../app/modules/_redux/liveData/liveDataSlice";
import { historicalDataSlice } from "../app/modules/_redux/historicalData/historicalDataSlice";
import { mapZonesSlice } from "../app/modules/_redux/mapZones/mapZonesSlice";
// import { assignResourcesSlice } from "../app/modules/_redux/assignResources/assignResourcesSlice"
import { assignResourcesSlice } from "../app/modules/_redux/admin/designStudio/assignResources/assignResourcesSlice";
import { routesSlice } from "../app/modules/_redux/admin/designStudio/RoutesRedux/routesSlice";
import { bookingSlice } from "../app/modules/_redux/booking/bookingSlice";
import { reportingSlice } from "../app/modules/_redux/reporting/reportingSlice";
import { persistentReportingSlice } from "../app/modules/_redux/reporting/persistentReportingSlice";
import { manageResourcesSlice } from "../app/modules/_redux/manageResources/manageResourcesSlice";
import { DesignStudioSlice } from "../app/modules/_redux/admin/designStudio/DesignStudioSlice";
import { visualDirectorySlice } from "../app/modules/_redux/visual-directory/visualDirectorySlice"
import { manageUsersSlice } from "../app/modules/_redux/manageUsers/manageUsersSlice"
import { manageBLEsSlice } from "../app/modules/_redux/manageBLEs/manageBLEsSlice"
import { manageThingsSlice } from "../app/modules/_redux/manageThings/manageThingsSlice"
import { manageCustomerSlice } from "../app/modules/_redux/manageCustomer/manageCustomerSlice"
import { contactTracingSlice } from "../app/modules/_redux/contactTracing/contactTracingSlice"

const persistedProfileReducer = persistReducer({
  storage,
  key: "userProfile.ufind",
  whitelist: [
    "currentCustomer",
    "currentLocation",
    "currentLevel",
  ],
}, profileSlice.reducer)

const persistedReportingReducer = persistReducer({
  storage,
  key: "reporting.ufind",
  whitelist: ["page", "group"],
}, persistentReportingSlice.reducer)

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: userSlice.reducer,
  profile: persistedProfileReducer,
  basePage: basePageSlice.reducer,
  ui: uiReducer,
  liveData: liveDataSlice.reducer,
  historicalData: historicalDataSlice.reducer,
  mapZones: mapZonesSlice.reducer,
  assignResources: assignResourcesSlice.reducer,
  booking: bookingSlice.reducer,
  reporting: reportingSlice.reducer,
  persistentReporting: persistedReportingReducer,
  manageResources: manageResourcesSlice.reducer,
  manageUsers: manageUsersSlice.reducer,
  manageBLEs: manageBLEsSlice.reducer,
  manageThings: manageThingsSlice.reducer,
  routes: routesSlice.reducer,
  manageCustomer: manageCustomerSlice.reducer,
  designStudio: DesignStudioSlice.reducer,
  visualDirectory: visualDirectorySlice.reducer,
  contactTracing: contactTracingSlice.reducer,
})

export function* rootSaga() {
  yield all([auth.saga()])
}
