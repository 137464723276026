export const convertCollectionSnapshotToMap = (collection) => {
   //if (!collection) return;
   const transformedCollection = collection.docs.map((doc) => {
     const event = doc.data()
     return { ...event, id: doc.id }
   })
 
   return transformedCollection.reduce((accumulator, doc) => {
     accumulator.push(doc)
     return accumulator
   }, [])
 }