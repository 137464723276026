import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import Chart from "react-apexcharts";

export function SensorHourly() {
  const { isLoading, floorSensors } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorSensorsDistributionLoading,
      floorSensors: state.reporting.floorSensorsDistribution,
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log(floorSensors, "floorSensors");
  }, [floorSensors]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='Floor Sensors'
        // subtitle='Hourly average number of people'
      />
      <CardBody style={{ height: "380px" }}>
        <Chart
          options={getChartOptions(floorSensors)}
          series={floorSensors?.series || []}
          type='line'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(floorSensors) {
  return {
    chart: {
      height: 250,
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: "No data to display.",
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    //  labels: floorSensors?.date || [],
    xaxis: {
      type: "category",
      categories: [floorSensors?.date],
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return val.toFixed(0) + "ºC";
          },
        },
        title: {
          text: "Temperature",
        },
      },
      {
        seriesName: "Humidity",
        opposite: true,
        max: 100,
        min: 0,
        tickAmount: 5,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },

        title: {
          text: "Humidity",
        },
        labels: {
          formatter: function(val) {
            return val.toFixed(0) + "%";
          },
        },
      },
    ],
    tooltip: {
      shared: false,
      x: {
        format: "MMM dd, HH:mm",
      },
    },
    //     chart: {
    //       type: "line",
    //       toolbar: {
    //         tools: {
    //           download: true,
    //           selection: false,
    //           zoom: false,
    //           zoomin: false,
    //           zoomout: false,
    //           pan: false,
    //           reset: false,
    //           customIcons: [],
    //         },
    //         export: {
    //           csv: {
    //             filename: "TotalOccupancyByFloor",
    //             columnDelimiter: ",",
    //             headerCategory: "Time",
    //             headerValue: "Value",
    //             dateFormatter(timestamp) {
    //               return new Date(timestamp).toDateString();
    //             },
    //           },
    //           svg: {
    //             filename: "TotalOccupancyByFloor",
    //           },
    //           png: {
    //             filename: "TotalOccupancyByFloor",
    //           },
    //         },
    //       },
    //       zoom: {
    //         enabled: false,
    //       },
    //     },
    //     noData: {
    //       text: "No data to display.",
    //     },
    //     colors: ["#0058A9", "#2ec4b6", "#F9CE1D"],
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     stroke: {
    //       curve: "straight",
    //       width: 6,
    //     },
    //     fill: {
    //       type: "gradient",
    //     },
    //     xaxis: {
    //       type: "category",
    //       categories: [
    //         "00:00",
    //         "01:00",
    //         "02:00",
    //         "03:00",
    //         "04:00",
    //         "05:00",
    //         "06:00",
    //         "07:00",
    //         "08:00",
    //         "09:00",
    //         "10:00",
    //         "11:00",
    //         "12:00",
    //         "13:00",
    //         "14:00",
    //         "15:00",
    //         "16:00",
    //         "17:00",
    //         "18:00",
    //         "19:00",
    //         "20:00",
    //         "21:00",
    //         "22:00",
    //         "23:00",
    //       ],
    //       tickAmount: 10,
    //       labels: {
    //         rotate: 0,
    //       },
    //     },
    //     yaxis: {
    //       title: {
    //         text: "People count",
    //       },
    //     },
    //     responsive: [
    //       {
    //         breakpoint: 680,
    //         options: {
    //           xaxis: {
    //             overwriteCategories: [
    //               "00:00",
    //               "04:00",
    //               "08:00",
    //               "12:00",
    //               "16:00",
    //               "20:00",
    //             ],
    //             labels: {
    //               show: true,
    //               hideOverlappingLabels: true,
    //             },
    //           },
    //         },
    //       },
    //     ],
  };
}
