import React, { createContext, useContext, useState } from "react"
import moment from "moment"

const UIContext = createContext()

export function useUIContext() {
  return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ children }) {
  const [queryParams, setQueryParams] = useState({
    date: moment().subtract(1, "days"),
    timeframe: "day",
  })

  const value = {
    queryParams,
    setQueryParams,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
