import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { LiveDataSection } from "./LiveData/LiveDataSection";
import { HistoricalDataSection } from "./HistoricalData/HistoricalDataSection";
import { UIProvider } from "./UIContext";

export function Dashboard() {
  const { selectedLocation } = useSelector(
    (state) => ({
      selectedLocation: state.profile?.currentLocation,
    }),
    shallowEqual
  );

  return (
    <UIProvider>
      <div className='row'>
        <LiveDataSection />
        {selectedLocation && <HistoricalDataSection />}
      </div>
    </UIProvider>
  );
}
