import React, { createContext, useContext, useState } from "react"

const UIContext = createContext()

export function useUIContext() {
   return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ UIEvents, children }) {
   const [mapDate, setMapDate] = useState(new Date())
   const [mapSelectedResource, setMapSelectedResource] = useState()
   const [calendarSignedInInfo, setCalendarSignedInInfo] = useState({
      signedIn: false,
      email: null,
      error: null
   })

   const value = {
      mapDate,
      setMapDate,
      mapSelectedResource,
      setMapSelectedResource,
      calendarSignedInInfo,
      setCalendarSignedInInfo,
      openBookModel: UIEvents.openBookModel,
      openBookModelWithBookingId: UIEvents.openBookModelWithBookingId,
   }

   return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}