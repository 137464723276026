import moment from "moment";

export function fetchAPI(props) {
  // console.log(props, "props");
  const {
    typechart,
    locations,
    SSIDs,
    floors,
    zones,
    startDate,
    endDate,
    url,
    devices,
  } = props;
  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    typechart: typechart,
    networkId: locations,
    ssid: SSIDs,
    floorPlanId: floors || [],
    zones: zones || [],
    devices: devices || [],
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  // console.log(raw, "raw");

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`,
    requestOptions
  ).catch((err) => {
    console.log("Error: ", err);
  });
}

export const handleSortAndSpliceTop5Data = (array) => {
  if (array) {
    var sorted = array.sort((a, b) => (a.data < b.data ? 1 : -1));
    // ↓ Remove all elements after x index (top5)
    sorted.splice(5);
    return sorted;
  }
};

// export const newHandleSortAndSpliceTop5Data = (array) => {
//   if (array) {
//     console.log("🚀 ~ array", array);
//     const floors = array[0].floors.map((floor) => floor);
//     console.log("🚀 ~ floors", floors);

//     const series = array[0].series.map((s) => s);
//     console.log("🚀 ~ series", series);

//     const dataAvg = series[0].data;
//     console.log("🚀 ~ dataAvg", dataAvg);

//     const dataBad = series[1].data;
//     console.log("🚀 ~ dataBad", dataBad);

//     const indices = Array.from(Array(dataAvg.length).keys()).sort((a, b) =>
//       a < b ? 1 : -1
//     );
//     console.log("🚀 ~ indices", indices);

//     var list = [];
//     for (var j = 0; j < dataAvg.length; j++) {
//       list.push({
//         dataAvg: dataAvg[j],
//         dataBad: dataBad[j],
//         floors: floors[j],
//       });
//     }

//     console.log("🚀 ~ list", list);

//     const summedBadAndAverage = list.map((l) => {
//       const sum = l.dataAvg + l.dataBad;
//       console.log("🚀 ~ sum", sum);
//       const newList = {
//         floors: l.floors,
//         summedFloorData: sum,
//       };
//       return newList;
//     });

//     console.log("🚀 ~ summedBadAndAverage", summedBadAndAverage);

//     var sorted = summedBadAndAverage.sort((a, b) =>
//       a.summedFloorData < b.summedFloorData ? 1 : -1
//     );
//     console.log("🚀 ~ sorted", sorted);

//     sorted.splice(5);

//     const foundedFloors = sorted.map((s) => {
//       const founded = list.find((l) => s.floors === l.floors);
//       return founded;
//     });
//     console.log("🚀 ~ foundedFloors", foundedFloors);

//     const dataAvgFinal = [];
//     const dataBadFinal = [];
//     const newFloorsFinal = [];

//     foundedFloors.forEach((f) => {
//       dataAvgFinal.push(f.dataAvg);
//       dataBadFinal.push(f.dataBad);
//       newFloorsFinal.push(f.floors);
//     });
//     console.log(dataAvgFinal, "dataAvgFinal");
//     console.log(dataBadFinal, "dataBadFinal");
//     console.log(newFloorsFinal, "newFloorsFinal");

//     const newSeriesFinal = [
//       { name: "Average", data: dataAvgFinal },
//       { name: "Bad", data: dataBadFinal },
//     ];
//     console.log("🚀 ~ newSeriesFinal", newSeriesFinal);

//     const newFinal = [{ series: newSeriesFinal, floors: newFloorsFinal }];
//     console.log("🚀 ~ newFinal", newFinal);

//     return newFinal;
//   }

//   // if (array) {
//   //   console.log("🚀 ~ array", array);
//   //   const data = array.map((arr) => arr.data);
//   //   console.log("🚀 ~ data", data);
//   //   var sorted = data.map((ar, i) => {
//   //     console.log("🚀 ~ ar", ar);
//   //     return ar.sort((a, b) => (a < b ? 1 : -1));
//   //   });
//   //   console.log("🚀 ~ sorted", sorted);
//   //   // ↓ Remove all elements after x index (top5)
//   //   sorted.splice(2);
//   //   return sorted;
//   // }
// };
