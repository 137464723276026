import * as requestFromServer from "./sensorsCrud";
import { reportingSlice, callTypes } from "../reportingSlice";
import * as ActionHelpers from "./ActionHelpers";

const { actions } = reportingSlice;

export const fetchSensors = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return Promise.all([
    fetchFloorSensorsDistribution(queryParams, dispatch),
    // fetchFloorOccupancy(queryParams, dispatch),
    // fetchFloorAverageOccupancy(queryParams, dispatch),
  ]).catch((error) => {
    console.log("Fetch Sensors By Floor: ", error);
    error.clientMessage = "Can't fetch Sensors By Floor";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

function fetchFloorSensorsDistribution(queryParams, dispatch) {
  dispatch(actions.startCall({ callType: callTypes.floorSensorsDistribution }));

  if (!queryParams) dispatch(actions.floorSensorsDistributionFetched(null));

  return requestFromServer
    .getFloorSensorsDistribution(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      if (!result) dispatch(actions.floorSensorsDistributionFetched(null));

      //   console.log(result, "result actions");

      const data = ActionHelpers.handleSensorDistributionResult({
        result,
        queryParams,
      });

      dispatch(actions.floorSensorsDistributionFetched(data));
    })
    .catch((error) => {
      console.log("Fetch Sensors Distribution: ", error);
      error.clientMessage = "Can't fetch Sensors Distribution";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}
