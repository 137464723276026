import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";

export function LocationAccuracyPerDay() {
  const { isLoading, floorLocationAccuracyPerDay } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorLocationAccuracyPerDayLoading,
      floorLocationAccuracyPerDay: state.reporting.floorLocationAccuracyPerDay,
    }),
    shallowEqual
  );

  return (
    <Card isLoading={isLoading}>
      <CardHeader title='Location Accuracy' subtitle='Accuracy per day' />
      <CardBody style={{ height: "380px" }}>
        <Chart
          options={getChartOptions(floorLocationAccuracyPerDay)}
          series={floorLocationAccuracyPerDay?.series || []}
          type='bar'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(options) {
  return {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "LocationAccuracyByFloor",
            columnDelimiter: ",",
            headerCategory: "Date",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "LocationAccuracyByFloor",
          },
          png: {
            filename: "LocationAccuracyByFloor",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display.",
    },
    plotOptions: {
      bar: {
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
    labels: options?.labels || [],
    xaxis: {
      //Default xaxis so it doesnt break when new options arrive
      tickPlacement: "between",
      ...options?.xaxis,
    },
    yaxis: {
      tickAmount: 5,
      max: 100,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val + "%";
        },
      },
    },
  };
}
