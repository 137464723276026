import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const FailuresPieChart = (queryParams) => {
  // console.log(queryParams, "SNRFloorsChart queryParams");

  return getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = netFailuresStepsToGraphData({
        result,
        queryParams,
      });

      //   console.log(data, "data netFailuresStepsToGraphData");

      let options = {
        chart: {
          height: 350,
          type: "pie",
        },
        colors: [
          "#323389",
          "#882e8c",
          "#c7297c",
          "#f1425f",
          "#ff713b",
          "#ffa600",
        ],
        noData: {
          text: "Loading...",
        },
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='pie' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        // console.log(data, "data");

        const dataObject = data && data[0];
        const keysSorted = Object.keys(dataObject).sort(function(a, b) {
          return b - a;
        });
        // console.log(keysSorted, "keysSorted");

        const arr = [];
        // Adding the sorted result to an array of object
        for (let i = 0; i < keysSorted.length; i++) {
          const obj = {};
          obj.type = keysSorted[i];
          obj.recordCount = dataObject[keysSorted[i]];
          arr.push(obj);
        }

        // console.log(arr, "arr");

        const series = [];
        const labels = [];

        arr.map((a) => {
          series.push(a.recordCount);
          labels.push(a.type);
        });

        // console.log("🚀 ~ series", series);
        // console.log("🚀 ~ labels", labels);

        options = {
          ...options,

          labels: labels || [],
          noData: {
            text: "No data to display.",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={series || []}
              type='pie'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch net failures steps global", error);
      error.clientMessage = "Can't fetch net failures steps global";
    });
};

// API fetch SNR
function getFloorNetFailuresPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorNetFailuresPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/netFailures/${queryParams.customerId}`,
  });
}

// Parse result SNR
export const netFailuresStepsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  //   console.log("🚀 ~ result floors", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.failures) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        failures: val.failures ? val.failures : "",
        failureSteps: val.failureSteps ? val.failureSteps : {},
        assoc: val.failureSteps.assoc ? val.failureSteps.assoc : 0,
        auth: val.failureSteps.auth ? val.failureSteps.auth : 0,
        dhcp: val.failureSteps.dhcp ? val.failureSteps.dhcp : 0,
        dns: val.failureSteps.dns ? val.failureSteps.dns : 0,
      });
    }
  });

  //   console.log("🚀 ~ data", data);

  const newData = sumNetFailuresTypes(data);
  //   console.log("🚀 ~ newData", newData);

  return newData;
};

const sumNetFailuresTypes = (array) => {
  if (!array) {
    return null;
  } else {
    // const total = array.reduce((acc, c) => acc + c.failures, 0);
    // console.log("🚀 ~ total", total);
    const assoc = array.reduce((acc, c) => acc + c.assoc, 0);
    // console.log("🚀 ~ assoc", assoc);
    const auth = array.reduce((acc, c) => acc + c.auth, 0);
    // console.log("🚀 ~ auth", auth);
    const dhcp = array.reduce((acc, c) => acc + c.dhcp, 0);
    // console.log("🚀 ~ dhcp", dhcp);
    const dns = array.reduce((acc, c) => acc + c.dns, 0);
    // console.log("🚀 ~ dns", dns);

    const result = [
      {
        // failuresTotal: total,
        ASSOC: assoc,
        AUTH: auth,
        DHCP: dhcp,
        DNS: dns,
      },
    ];
    return result;
  }
};
