import * as requestFromServer from "./historicalDataCrud";
import { historicalDataSlice, callTypes } from "./historicalDataSlice";
import {
  historicalResultToGraphData,
  historicalResultToGraphData2,
} from "../_helpers/ActionHelpers";
import moment from "moment";

const { actions } = historicalDataSlice;

export const clearData = () => (dispatch) => {
  dispatch(actions.dayFloorDataFetched({ entities: null }));
  dispatch(actions.weekFloorDataFetched({ entities: null }));
  dispatch(actions.monthFloorDataFetched({ entities: null }));
  dispatch(actions.dayZoneDataFetched({ entities: null }));
  dispatch(actions.weekZoneDataFetched({ entities: null }));
  dispatch(actions.monthZoneDataFetched({ entities: null }));
};

export const getDayFloorData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.dayFloorDataFetched({ entities: null }));
  }

  return requestFromServer
    .getDayFloorData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData2(result, queryParams);

      data &&
        dispatch(
          actions.dayFloorDataFetched({ entities: { ...data, day: true } })
        );
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getWeekFloorData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.weekFloorDataFetched({ entities: null }));
  }

  return requestFromServer
    .getWeekFloorData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData2(result, queryParams);

      dispatch(actions.weekFloorDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch week floor data", error);
      error.clientMessage = "Can't find week floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getMonthFloorData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.monthFloorDataFetched({ entities: null }));
  }

  return requestFromServer
    .getMonthFloorData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData2(result, queryParams);

      dispatch(actions.monthFloorDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch month floor data", error);
      error.clientMessage = "Can't find month floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDayZoneData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  if (!queryParams) {
    dispatch(actions.dayZoneDataFetched({ entities: null }));
  }

  return requestFromServer
    .getDayZoneData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData(result);

      dispatch(actions.dayZoneDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch day zone data", error);
      error.clientMessage = "Can't find day zone data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getWeekZoneData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  if (!queryParams) {
    dispatch(actions.weekZoneDataFetched({ entities: null }));
  }

  return requestFromServer
    .getWeekZoneData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData(result);

      dispatch(actions.weekZoneDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch week zone data", error);
      error.clientMessage = "Can't find week zone data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getMonthZoneData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  if (!queryParams) {
    dispatch(actions.monthZoneDataFetched({ entities: null }));
  }

  return requestFromServer
    .getMonthZoneData(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = historicalResultToGraphData(result);

      dispatch(actions.monthZoneDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch month zone data", error);
      error.clientMessage = "Can't find month zone data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//★━━━━━━━━━━━━━★ Daily People ★━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const clearDailyPeopleData = () => (dispatch) => {
  dispatch(actions.weekDailyPeopleFetched({ entities: null }));
  dispatch(actions.monthDailyPeopleFetched({ entities: null }));
  dispatch(actions.weekAvgTimeFetched({ entities: null }));
  dispatch(actions.monthAvgTimeFetched({ entities: null }));
};

export const fetchWeekDailyPeople = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.dailyPeople }));

  if (!queryParams) {
    dispatch(actions.weekDailyPeopleFetched({ entities: null }));
  }

  return requestFromServer
    .getWeekDailyPeople(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var labels = [];
      var series = [];
      JSON.parse(result).forEach((value) => {
        labels.push(moment(value[0]).format("MMM D"));
        series.push(value[1]);
      });

      dispatch(
        actions.weekDailyPeopleFetched({ entities: { labels, series } })
      );
    })
    .catch((error) => {
      console.log("Fetch week daily people", error);
      error.clientMessage = "Can't find week daily people";
      dispatch(actions.catchError({ error, callType: callTypes.dailyPeople }));
    });
};

export const fetchMonthDailyPeople = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.dailyPeople }));

  if (!queryParams) {
    dispatch(actions.monthDailyPeopleFetched({ entities: null }));
  }

  return requestFromServer
    .getMonthDailyPeople(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var labels = [];
      var series = [];
      JSON.parse(result).forEach((value) => {
        labels.push(moment(value[0]).format("MMM D"));
        series.push(value[1]);
      });

      dispatch(
        actions.monthDailyPeopleFetched({ entities: { labels, series } })
      );
    })
    .catch((error) => {
      console.log("Fetch month daily people", error);
      error.clientMessage = "Can't find month daily people";
      dispatch(actions.catchError({ error, callType: callTypes.dailyPeople }));
    });
};

export const fetchWeekAvgTime = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.avgTime }));

  if (!queryParams) {
    dispatch(actions.weekAvgTimeFetched({ entities: null }));
  }

  return requestFromServer
    .getWeekAvgTime(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var labels = [];
      var series = [];
      JSON.parse(result).forEach((value) => {
        labels.push(moment(value[0]).format("MMM D"));
        series.push(value[2]);
      });

      dispatch(actions.weekAvgTimeFetched({ entities: { labels, series } }));
    })
    .catch((error) => {
      console.log("Fetch week avg time", error);
      error.clientMessage = "Can't find week avg time";
      dispatch(actions.catchError({ error, callType: callTypes.avgTime }));
    });
};

export const fetchMonthAvgTime = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.avgTime }));

  if (!queryParams) {
    dispatch(actions.monthAvgTimeFetched({ entities: null }));
  }

  return requestFromServer
    .getMonthAvgTime(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var labels = [];
      var series = [];
      JSON.parse(result).forEach((value) => {
        labels.push(moment(value[0]).format("MMM D"));
        series.push(value[2]);
      });

      dispatch(actions.monthAvgTimeFetched({ entities: { labels, series } }));
    })
    .catch((error) => {
      console.log("Fetch month avg time", error);
      error.clientMessage = "Can't find month avg time";
      dispatch(actions.catchError({ error, callType: callTypes.avgTime }));
    });
};
