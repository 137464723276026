import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
import { badFloorsChart } from "./BadFloorsChart";
import { Popover, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function RSSIBadFloors({ queryParams }) {
  const classes = useStyles();

  const [graph, setGraph] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "UIProps.queryParams BADFLOORS");
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await badFloorsChart(queryParams);
    // console.log("🚀 ~ res", res);
    setGraph(res);
  };

  const floorsSeries = graph && graph.props.children.props.options;

  const locationsArray = graph && floorsSeries && floorsSeries?.noData?.text[1];

  // useEffect(() => {
  //   console.log(floorsSeries, "floorsSeries");
  //   // graph && console.log(graph.props.children.props.options.xaxis?.categories);
  // }, [graph, floorsSeries]);

  return (
    <Card>
      <CardHeader
        title='Bad RSSI - Floors'
        subtitle='Top 5 RSSI < -90dbm'
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Locations
            </Typography>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {floorsSeries?.xaxis?.categories.map((z, i) => (
                <p key={i}>{z}</p>
              ))}
            </div>

            <div>
              {locationsArray &&
                Array.isArray(locationsArray) &&
                locationsArray.map((l, i) => <p key={i}>- &nbsp;&nbsp;{l}</p>)}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
}
