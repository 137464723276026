import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { UIProvider } from "./UIContext";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { Header3 } from "../../_partials/typography/Header3";
import { DataFilter } from "./data-filter/DataFilter";
import { OccupancyReporting } from "./occupancy/OccupancyReporting";
import { WifiReporting } from "./wifi/WifiReporting";
import { TeamsAndDepartmentsPage } from "./teamsAndDepartments/TeamsAndDepartmentsPage";
import { WifiSnapshotReporting } from "./wifiSnapshot/WifiSnapshotReporting";
import { SensorsReportingPage } from "./sensors/SensorsReportingPage";

export const ReportingPage = () => {
  const { page, selectedLocation } = useSelector(
    (state) => ({
      page: state.persistentReporting?.page,
      selectedLocation: state.profile?.currentLocation?.netId,
    }),
    shallowEqual
  );

  return (
    <UIProvider>
      <div className='mb-8'>
        <div className='d-flex justify-content-start align-items-center'>
          <DataUsageIcon className='ml-3 mr-2' />
          <Header3 text='Reporting' />
        </div>
      </div>
      {!selectedLocation && (
        <Redirect
          exact={true}
          // from='/admin'
          to='/dashboard'
        />
      )}
      {selectedLocation && <DataFilter />}
      {selectedLocation && renderSwitch(page)}
    </UIProvider>
  );
};

function renderSwitch(param) {
  switch (param) {
    case "people":
      return <OccupancyReporting />;
    case "wifi":
      return <WifiReporting />;
    case "teams":
      return <TeamsAndDepartmentsPage />;
    case "wifiSnapshot":
      return <WifiSnapshotReporting />;
    case "sensors":
      return <SensorsReportingPage />;
    default:
      return;
  }
}
