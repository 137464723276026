import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_partials/Card"
import Chart from "react-apexcharts"


export function FloorAverageOccupancy() {
  const { isLoading, totalOccupancy } = useSelector((state) => ({
    isLoading: state.reporting.floorAverageOccupancyLoading,
    totalOccupancy: state.reporting.floorAverageOccupancy,
  }), shallowEqual)

  return <Card isLoading={isLoading}>
    <CardHeader
      title="Average Occupancy"
      subtitle="Hourly average number of people"
    />
    <CardBody style={{ height: "380px" }}>
      <Chart
        options={getChartOptions()}
        series={totalOccupancy || []}
        type='line'
        height="100%"
      />
    </CardBody>
  </Card>
}

function getChartOptions() {
  return {
    chart: {
      type: "line",
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: "TotalOccupancyByFloor",
            columnDelimiter: ',',
            headerCategory: 'Time',
            headerValue: 'Value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: "TotalOccupancyByFloor",
          },
          png: {
            filename: "TotalOccupancyByFloor",
          }
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display."
    },
    colors: ["#0058A9", "#2ec4b6", "#F9CE1D"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 6,
    },
    fill: {
      type: "gradient",
    },
    xaxis: {
      type: "category",
      categories: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      tickAmount: 10,
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: "People count",
      },
    },
    responsive: [
      {
        breakpoint: 680,
        options: {
          xaxis: {
            overwriteCategories: [
              "00:00",
              "04:00",
              "08:00",
              "12:00",
              "16:00",
              "20:00",
            ],
            labels: {
              show: true,
              hideOverlappingLabels: true,
            },
          },
        },
      },
    ],
  }
}
