import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as actions from "../../_redux/booking/bookingActions"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import moment from "moment"
import { ModalProgressBar } from "../../../../_metronic/_partials/controls"
import { DropdownSelect } from "../DropdownSelect"

export function AverageTimeAtTheOffice() {
   const dispatch = useDispatch()

   const { isAverageTimeLoading, customer, usersDocs, department, user, profileLvl, userAverageTimeAtTheOffice, departmentAverageTimeAtTheOffice } = useSelector(
      (state) => ({
         isAverageTimeLoading: state.booking.isAverageTimeLoading,
         customer: state.profile?.currentCustomer,
         usersDocs: state.booking.usersDocs,
         department: state.basePage?.department,
         user: state.auth?.user,
         profileLvl: state.auth?.claims?.profileLvl,
         userAverageTimeAtTheOffice: state.booking.userAverageTimeAtTheOffice,
         departmentAverageTimeAtTheOffice: state.booking.departmentAverageTimeAtTheOffice,
      }), shallowEqual)

   const [dataFilter, setDataFilter] = useState({ value: 'me', label: 'Me' })
   const [averageTimeAtTheOffice, setAverageTimeAtTheOffice] = useState({
      xAxis: [],
      yAxis: []
   })

   useEffect(() => {
      if (customer && usersDocs) {
         if (dataFilter.value === "department") {
            if (departmentAverageTimeAtTheOffice) { return }
            const macAddress = usersDocs.map(val => val.devicesArray).flat()
            dispatch(actions.fetchAverageTimeAtTheOffice({
               customer,
               macAddress,
               getDepartmentData: true
            }))
         } else {
            if (userAverageTimeAtTheOffice) { return }
            const macAddress = usersDocs.find(val => val.id === user.id).devicesArray
            dispatch(actions.fetchAverageTimeAtTheOffice({
               customer,
               macAddress
            }))
         }
      }
   }, [customer, usersDocs, dataFilter, user])

   useEffect(() => {
      if (dataFilter?.value === "department") {
         setAverageTimeAtTheOffice(departmentAverageTimeAtTheOffice || {
            xAxis: [],
            yAxis: []
         })
      } else {
         setAverageTimeAtTheOffice(userAverageTimeAtTheOffice || {
            xAxis: [],
            yAxis: []
         })
      }
   }, [dataFilter, userAverageTimeAtTheOffice, departmentAverageTimeAtTheOffice])

   return <Card isLoading={isAverageTimeLoading}>
      <CardHeader
         title="Average time at the office"
         subtitle="in hours"
         toolbar={profileLvl >= 2 && department &&
            <DropdownSelect
               dataFilter={dataFilter}
               setDataFilter={setDataFilter}
               options={[
                  { value: 'me', label: 'Me' },
                  { value: 'department', label: 'Department' }
               ]}
            />}
      />
      <CardBody style={{height: "250px"}}>
         <Chart
            options={getChartOptions(averageTimeAtTheOffice)}
            series={averageTimeAtTheOffice.yAxis}
            type="bar"
            height="100%"
         />
      </CardBody>
   </Card>
}

function getChartOptions(averageTimeAtTheOffice) {
   return {
      chart: {
         toolbar: { show: false }
      },
      plotOptions: {
         bar: {
            columnWidth: '55%',
         },
      },
      dataLabels: {
         enabled: false
      },
      noData: {
         text: "No data",
         align: 'center',
         verticalAlign: 'middle',
         offsetX: 0,
         offsetY: 0,
         style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
         }
      },
      xaxis: {
         categories: averageTimeAtTheOffice.xAxis,
         labels: {
            formatter: (val) => {
               if (val === 0 || val === 1 || val === 2 || val === 3 || val === 4 || val === 5) { return }
               return moment(val).format("DD MMM")
            }
         }
      },
      yaxis: {
         labels: {
            formatter: (val) => {
               return `0${val / 60 ^ 0}`.slice(-2) + ':' + ('0' + val % 60).slice(-2)
            }
         }
      },
      tooltip: {
         enabled: true,
         y: {
            title: {
               formatter: () => "",
            },
         },
         marker: {
            show: false,
         },
      }
   }
}