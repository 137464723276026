import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import { DataGrid } from "@material-ui/data-grid";

export const FailuresCountChart = (queryParams, showChart) => {
  // console.log("🚀 ~ showChart", showChart);
  // console.log(queryParams, "SNRFloorsChart queryParams");

  return getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = netFailuresStepsToGraphData({
        result,
        queryParams,
      });

      //   console.log(data, "data netFailuresStepsToGraphData");

      const columns = [
        {
          field: "id",
          headerName: "ID",
          width: 47,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "type",
          headerName: "Type",
          width: !showChart ? 200 : 100,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "recordCount",
          headerName: "Record Count",
          type: "number",
          width: !showChart ? 200 : 160,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
      ];

      const rows = [
        {
          id: 1,
          type: "Loading...",
          recordCount: "Loading...",
        },
        {
          id: 2,
          type: "Loading...",
          recordCount: "Loading...",
        },
        {
          id: 3,
          type: "Loading...",
          recordCount: "Loading...",
        },
      ];

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              //   checkboxSelection
              disableSelectionOnClick
            />
          </div>
        );
        return mainDiv;
      } else {
        // console.log(data, "data 2");

        const dataObject = data && data[0];
        const keysSorted = Object.keys(dataObject).sort(function(a, b) {
          return b - a;
        });
        // console.log(keysSorted, "keysSorted");

        const arr = [];
        // Adding the sorted result to an array of object
        for (let i = 0; i < keysSorted.length; i++) {
          const obj = {};
          obj.type = keysSorted[i];
          obj.recordCount = dataObject[keysSorted[i]];
          arr.push(obj);
        }

        // console.log(arr, "arr");

        const sortedRows = arr.sort(function(a, b) {
          return parseFloat(b.recordCount) - parseFloat(a.recordCount);
        });

        // console.log("🚀 ~ sortedRows", sortedRows);

        const sortedRowsWithId = [];
        sortedRows.map((d, i) =>
          sortedRowsWithId.push({
            id: i + 1,
            ...d,
          })
        );
        // console.log(sortedRowsWithId, "sortedRowsWithId");

        const mainDiv = (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            {sortedRowsWithId && sortedRowsWithId.length !== 0 ? (
              <DataGrid
                rows={sortedRowsWithId}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "350px",
                }}
              >
                {" "}
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#3B4143",
                  }}
                >
                  No data to display.
                </p>
              </div>
            )}
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch net failures steps global", error);
      error.clientMessage = "Can't fetch net failures steps global";
    });
};

// API fetch SNR
function getFloorNetFailuresPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorNetFailuresPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/netFailures/${queryParams.customerId}`,
  });
}

// Parse result SNR
export const netFailuresStepsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  //   console.log("🚀 ~ result floors", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.failures) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        failures: val.failures ? val.failures : "",
        failureSteps: val.failureSteps ? val.failureSteps : {},
        assoc: val.failureSteps.assoc ? val.failureSteps.assoc : 0,
        auth: val.failureSteps.auth ? val.failureSteps.auth : 0,
        dhcp: val.failureSteps.dhcp ? val.failureSteps.dhcp : 0,
        dns: val.failureSteps.dns ? val.failureSteps.dns : 0,
      });
    }
  });

  //   console.log("🚀 ~ data", data);

  const newData = sumNetFailuresTypes(data);
  //   console.log("🚀 ~ newData", newData);

  return newData;
};

const sumNetFailuresTypes = (array) => {
  if (!array) {
    return null;
  } else {
    // const total = array.reduce((acc, c) => acc + c.failures, 0);
    // console.log("🚀 ~ total", total);
    const assoc = array.reduce((acc, c) => acc + c.assoc, 0);
    // console.log("🚀 ~ assoc", assoc);
    const auth = array.reduce((acc, c) => acc + c.auth, 0);
    // console.log("🚀 ~ auth", auth);
    const dhcp = array.reduce((acc, c) => acc + c.dhcp, 0);
    // console.log("🚀 ~ dhcp", dhcp);
    const dns = array.reduce((acc, c) => acc + c.dns, 0);
    // console.log("🚀 ~ dns", dns);

    const result = [
      {
        // failuresTotal: total,
        ASSOC: assoc,
        AUTH: auth,
        DHCP: dhcp,
        DNS: dns,
      },
    ];
    return result;
  }
};
