import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  floorPlans: null,
  iconsFromDB: [],
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const liveDataSlice = createSlice({
  name: "liveData",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    iconsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.iconsFromDB = action.payload;
    },
    thingsFetched: (state, action) => {
      state.listLoading = false
      state.error = null
      state.things = action.payload
    },
    devicesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.devices = action.payload;
    },
    bleDevicesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.bleDevices = action.payload;
    },
    visitorsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.visitors = entities;
    },
    guestsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.guests = entities;
    },
    peopleStatsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.peopleStats = action.payload;
    },
    dailyUsersFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dailyUsers = entities;
    },
    usersFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.users = action.payload;
    },
    poisFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.pois = action.payload;
    },
    sensorsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.sensors = action.payload;
    },
    zonesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.zones = action.payload;
    },
    resourcesMeetingsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.resourcesMeetings = action.payload;
    },
    allLocationsPeopleStatsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allLocationsPeopleStats = action.payload;
    },
    allLocationsMerakiDevicesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allLocationsMerakiDevices = action.payload;
    },
  },
});
