import React from "react"
import { PeakOccupancy } from "./PeakOccupancy"
import { Occupancy } from "./Occupancy"
import { DailyPeople } from "./DailyPeople"

export function HistoricalDataSection() {
    return (
        <>
            <div className="col-12 pt-2 pb-2">
                <h3 style={{
                    fontFamily: 'Poppins',
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "36px",
                    letterSpacing: "-0.02em",
                    color: "#4A4A68"
                }}><b>Historical Data</b></h3>
            </div>
            <div className="col-lg-6 col-xxl-5">
                <PeakOccupancy />
            </div>
            <div className="col-lg-6 col-xxl-7">
                <Occupancy />
            </div>
            <div className="col-12">
                <DailyPeople />
            </div>
        </>
    );
}
