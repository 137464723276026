import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
   listLoading: false,
   actionsLoading: false,
   lastError: null,
}

export const callTypes = {
   list: "list",
   action: "action",
}

export const manageBLEsSlice = createSlice({
   name: "manageBLEs",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false
         } else {
            state.actionsLoading = false
         }
      },
      startCall: (state, action) => {
         state.error = null
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true
         } else {
            state.actionsLoading = true
         }
      },
      blesFetched: (state, action) => {
         state.listLoading = false
         state.error = null
         state.bles = action.payload
      },
      bleCreated: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.bles = state.bles ? [...state.bles, action.payload] : [action.payload.user]
      },
      usersUploaded: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
      bleEdited: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.bles = state.bles && state.bles.map((entity) => {
            if (entity.id === action.payload.id) {
               return action.payload
            }
            return entity
         })
      },
      bleDeleted: (state, action) => {
         state.actionsLoading = false
         state.error = null
         state.bles = state.bles.filter((val) => val.id !== action.payload)
      },
      usersDeleted: (state, action) => {
         state.actionsLoading = false
         state.error = null
      },
   },
})
