import * as requestFromServer from "./bookingCrud"
import { bookingSlice, callTypes } from "./bookingSlice"
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"
import moment from "moment"
import { checkResourseAvailability, averageTimeAtTheOfficeResultToGraphData } from "./_bookingHelpers"
import firebase from "firebase"

const { actions } = bookingSlice

export const fetchResources = queryparams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }))

  if (!queryparams) {
    dispatch(actions.resourcesFetched({ entities: [] }))
  }

  return requestFromServer
    .getResources(queryparams)
    .then(response => {
      if (!response) {
        throw Error("Can't make a request for server")
      }

      const resources = convertCollectionSnapshotToMap(response)

      dispatch(actions.resourcesFetched({ entities: resources }))
    })
    .catch(error => {
      console.log("fetch zones ", error)
      error.clientMessage = "Can't find zones"
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const bookResource = queryParams => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))

  const date = moment(queryParams.date).startOf("day")
  const start = moment(queryParams.date).set({ hour: queryParams.start.split(":")[0], minute: queryParams.start.split(":")[1], second: 0 })
  const end = moment(queryParams.date).set({ hour: queryParams.end.split(":")[0], minute: queryParams.end.split(":")[1], second: 0 })
  if (start.isSameOrAfter(end)) {
    end.add(1, "day")
  }

  const message = await checkResourseAvailability({
    ...queryParams,
    date: date,
    start: start,
    end: end,
  })
  if (message) {
    if (message.busy) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: message.message,
      })
      dispatch(actions.catchError({ error: "resource busy", callType: callTypes.action }))
      return "busy"
    }
    dispatch({
      type: "SNACKBAR_WARNING",
      payload: message,
    })
    return
  }

  return requestFromServer
    .bookResource({
      ...queryParams,
      date: date,
      start: start,
      end: end,
    })
    .then(data => {
      dispatch(actions.resourceBooked(data))
      dispatch({
        type: "SNACKBAR_SUCCESS",
        payload: `Resource booked`,
      })
    })
    .catch(error => {
      console.log("Error: ", error)
      dispatch({
        type: "SNACKBAR_ERROR",
        payload: `An error occurred, please try again later`,
      })
      error.clientMessage = "Can't book resource"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const editBooking = queryParams => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  const date = moment(queryParams.date).startOf("day")
  const start = moment(queryParams.date).set({ hour: queryParams.start.split(":")[0], minute: queryParams.start.split(":")[1], second: 0 })
  const end = moment(queryParams.date).set({ hour: queryParams.end.split(":")[0], minute: queryParams.end.split(":")[1], second: 0 })
  if (start.isSameOrAfter(end)) {
    end.add(1, "day")
  }

  const message = await checkResourseAvailability({
    ...queryParams,
    date: date,
    start: start,
    end: end,
  })

  if (message) {
    if (message.busy) {
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: message.message,
      })
      dispatch(actions.catchError({ error: "resource busy", callType: callTypes.action }))
      return "busy"
    }
    dispatch({
      type: "SNACKBAR_WARNING",
      payload: message,
    })
    return
  }

  return requestFromServer
    .editBooking({
      ...queryParams,
      date: date,
      start: start,
      end: end,
    })
    .then(data => {
      dispatch(actions.bookingEdited(data))
      dispatch({
        type: "SNACKBAR_SUCCESS",
        payload: `Booking updated successufuly`,
      })
    })
    .catch(error => {
      console.log("Error: ", error)
      error.clientMessage = "Can't edit booking"
      dispatch({
        type: "SNACKBAR_ERROR",
        payload: `Error updating booking`,
      })
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const cancelBooking = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))

  return requestFromServer
    .cancelBooking(queryParams)
    .then(() => {
      dispatch(actions.bookingCanceled(queryParams.booking.id))
      dispatch({
        type: "SNACKBAR_SUCCESS",
        payload: `Booking canceled successufuly`,
      })
    })
    .catch(error => {
      console.log("Error: ", error)
      error.clientMessage = "Can't cancel booking"
      dispatch({
        type: "SNACKBAR_ERROR",
        payload: `Error canceling booking`,
      })
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const fetchAverageTimeAtTheOffice = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.isAverageTimeLoading }))

  if (!queryParams) {
    dispatch(actions.averageTimeAtTheOfficeFetched(null))
  }

  const start = moment(firebase.firestore.Timestamp.now().toDate()).subtract(7, "days")
  const end = moment(firebase.firestore.Timestamp.now().toDate()).subtract(1, "day")

  return requestFromServer
    .getAverageTimeAtTheOffice({ ...queryParams, start, end })
    .then(response => response.text())
    .then(result => {
      if (!result) {
        dispatch(actions.averageTimeAtTheOfficeFetched(null))
      }

      const data = averageTimeAtTheOfficeResultToGraphData({ result, start, end })

      if (queryParams.getDepartmentData) {
        dispatch(actions.departmentAverageTimeAtTheOfficeResultToGraphData(data))
        return
      }
      dispatch(actions.userAverageTimeAtTheOfficeResultToGraphData(data))
    })
    .catch(error => {
      console.log("Fetch average time at the office: ", error)
      error.clientMessage = "Can't fetch average tima at the office"
      dispatch(actions.catchError({ error, callType: callTypes.isAverageTimeLoading }))
    })
}

export const fetchHybridWorkBalance = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.isHybridWorkBalanceLoading }))

  if (!queryParams) {
    dispatch(actions.hybridWorkBalanceFetched(null))
  }

  const start = moment(firebase.firestore.Timestamp.now().toDate())
  const end = moment(firebase.firestore.Timestamp.now().toDate())

  if (queryParams.month) {
    start.subtract(1, "month").subtract(1, "day")
    end.subtract(1, "day")
  } else {
    start.subtract(7, "days")
    end.subtract(1, "day")
  }

  return requestFromServer
    .getHybridWorkBalance({ ...queryParams, start, end })
    .then(response => response.text())
    .then(result => {
      if (!result) {
        dispatch(actions.hybridWorkBalanceFetched(null))
      }

      const total = JSON.parse(result).length
      const timesAtTheOffice = JSON.parse(result).filter(val => val[2] >= 30)?.length || 0
      const theRestOfDays = JSON.parse(result).length - timesAtTheOffice

      const percentage1 = Math.round((timesAtTheOffice * 100) / total)
      const percentage2 = Math.round((theRestOfDays * 100) / total)
      const data = [percentage1, percentage2]

      dispatch(
        actions.hybridWorkBalanceFetched({
          data,
          getDepartmentData: queryParams.getDepartmentData,
          month: queryParams.month,
        })
      )
    })
    .catch(error => {
      console.log("Fetch hybrid work balance: ", error)
      error.clientMessage = "Can't fetch hybrid work balance"
      dispatch(actions.catchError({ error, callType: callTypes.isHybridWorkBalanceLoading }))
    })
}
