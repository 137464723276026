import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/reporting/wifi/wifiActions";
import { useUIContext } from "../UIContext";
import { Overview } from "./Floor/Overview";
import { OverviewGlobal } from "./APsHealth/OverviewGlobal";
import { FailuresCount } from "./Floor/FailuresCount";
import { FailuresPie } from "./Floor/FailuresPie";
import { HistogramFloors } from "./Floor/HistogramFloors";
// import { Top5WorstNetFailures } from "./APsHealth/Top5WorstNetFailures";
// import { Top5WorstSNR } from "./APsHealth/Top5WorstSNR";
import { RSSIBadFloors } from "./Floor/RSSIBadFloors";
import { SNRFloors } from "./Floor/SNRFloors";
import { FailureStepsZones } from "./Zones/FailureStepsZones";
import { NetFailuresEachZone } from "./Zones/NetFailuresEachZone";
import { OverviewZones } from "./Zones/OverviewZones";
import { RSSIBadZones } from "./Zones/RSSIBadZones";
import { SNREachZone } from "./Zones/SNREachZone";
import { SNRZones } from "./Zones/SNRZones";

export function WifiSnapshotReporting() {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const { group, customer } = useSelector(
    (state) => ({
      group: state.persistentReporting?.group,
      customer: state.profile?.currentCustomer,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (!customer) return;

  //   if (group === "floor") {
  //     dispatch(
  //       actions.fetchWifiByFloor({
  //         customerId: customer.customerId,
  //         ...UIProps.queryParams,
  //       })
  //     );
  //   }
  //   if (group === "zone") {
  //     dispatch(
  //       actions.fetchWifiByZone({
  //         customerId: customer.customerId,
  //         ...UIProps.queryParams,
  //       })
  //     );
  //   }
  // }, [customer, UIProps.queryParams, group]);

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(UIProps.queryParams, "UIProps.queryParams 1");
  }, [UIProps.queryParams, customer, group]);

  const paramsWithCustomer = {
    ...UIProps.queryParams,
    customerId: customer.customerId,
  };

  return (
    <div className='row'>
      {group === "floor" && (
        <>
          <div className='col-lg-6 col-xxl-6'>
            <OverviewGlobal queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <SNRFloors queryParams={paramsWithCustomer} />
          </div>
          <div className='col-12'>
            <Overview queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <FailuresCount queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <FailuresPie queryParams={paramsWithCustomer} />
          </div>
          <div className='col-12'>
            <HistogramFloors queryParams={paramsWithCustomer} />
          </div>
          <div className='col-12'>
            <RSSIBadFloors queryParams={paramsWithCustomer} />
          </div>

          {/* Top5Worst charts not needed */}
          {/* <div className='col-lg-6 col-xxl-6'>
        <Top5WorstSNR queryParams={paramsWithCustomer} />
      </div>
      <div className='col-lg-6 col-xxl-6'>
        <Top5WorstNetFailures queryParams={paramsWithCustomer} />
      </div> */}
        </>
      )}

      {group === "zone" && (
        <>
          <div className='col-lg-6 col-xxl-6'>
            <OverviewGlobal queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <SNRZones queryParams={paramsWithCustomer} />
          </div>

          <div className='col-12'>
            <OverviewZones queryParams={paramsWithCustomer} />
          </div>
          <div className='col-12'>
            <FailureStepsZones queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <SNREachZone queryParams={paramsWithCustomer} />
          </div>
          <div className='col-lg-6 col-xxl-6'>
            <NetFailuresEachZone queryParams={paramsWithCustomer} />
          </div>
          <div className='col-12'>
            <RSSIBadZones queryParams={paramsWithCustomer} />
          </div>
        </>
      )}
    </div>
  );
}
