import React, { useState } from "react"
import Button from '@material-ui/core/Button'
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"

const SevenStep = ({ formData, navigation, setForm }) => {
   const { previous, next } = navigation

   const [showPassword, setShowPassword] = useState(false)

   return <Card style={{ height: "100%" }}>
      <CardHeader title="Review"
         toolbar={<>
            <Button
               variant="contained"
               color="default"
               style={{ marginRight: "10px" }}
               size="large"
               disableElevation
               onClick={previous}>
               Previous
            </Button>
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => {
                  setForm({
                     target: {
                        name: "data.completedStep",
                        value: -1
                     }
                  })
                  next()
               }}
            >
               Create customer
            </Button>
         </>}
      />
      <CardBody>
         <div style={{
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#ECF1F4"
         }}>
            <h4>{formData.name || "Name error"}</h4>
            <b>domain: </b>{formData.domain} <br />
            <b>admin: </b>{formData.admin} <br />
            <b>password: </b>
            <Button
               title={showPassword ? "Hide password" : "Show password"}
               style={{
                  padding: 0,
                  justifyContent: "flex-start",
                  textTransform: "none",
                  minWidth: 0,
                  padding: "0 5px"
               }}
               onClick={() => setShowPassword(!showPassword)}
            >
               {showPassword ? formData.password : "*****"}
            </Button>
            <br />
            <b>orgName: </b>{formData.merakiDashboard.orgName} <br />
         </div>
         <div style={{
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#ECF1F4"
         }}>
            <h4>{formData.merakiNetworks[0].netName || "Network error"}</h4>
            <b>timeZone: </b>{formData.merakiNetworks[0].timeZone} <br />
            <b>city: </b>{formData.merakiNetworks[0].city} <br />
            <b>country: </b>{formData.merakiNetworks[0].country} <br />
            <b>gpslat: </b>{formData.merakiNetworks[0].gpslat} <br />
            <b>gpslng: </b>{formData.merakiNetworks[0].gpslng} <br />
         </div>
         <div style={{
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#ECF1F4"
         }}>
            <h4>Levels</h4>
            {formData.merakiNetworks[0].levels.map((values, i) => {
               return <div key={i}>
                  <div>{values.name}</div>
                  <div>{values.level}</div> <br />
               </div>
            })}
         </div>
         <div style={{
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#ECF1F4"
         }}>
            <h4>SSIDs</h4>
            <b>Not associated:</b>
            {formData.merakiNetworks[0].SSIDs.map((values, i) => {
               if (formData.merakiNetworks[0].employeeSSID.includes(values) || formData.merakiNetworks[0].guestSSID.includes(values)) {
                  return
               }
               return <div key={i}>
                  <div>{values}</div>
               </div>
            })}<br />
            <b>Employees SSIDs:</b>
            {formData.merakiNetworks[0].employeeSSID.map((values, i) => {
               return <div key={i}>
                  <div>{values}</div>
               </div>
            })}<br />
            <b>Guests SSIDs:</b>
            {formData.merakiNetworks[0].guestSSID.map((values, i) => {
               return <div key={i}>
                  <div>{values}</div>
               </div>
            })}<br />
         </div>
         <div style={{
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#ECF1F4"
         }}>
            <h4>Important data</h4>
            <b>Meraky key: </b>{formData.merakiDashboard.meraki_key} <br />
            <b>validator: </b>{formData.merakiScanApi.validator} <br />
            <b>validator_url: </b>{formData.merakiScanApi.validator_url} <br />
            <b>secret_validator: </b>{formData.merakiScanApi.secret_validator} <br />
            <b>api_version: </b>{formData.merakiScanApi.api_version} <br />
         </div>
      </CardBody>
   </Card>
}

export default SevenStep