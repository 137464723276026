import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback" style={{display: "block"}}>{error}</div>;
  }

  if (touched && !error && label) {
    return <div className="valid-feedback">{label} was entered correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
    </div>
  )
}

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel
}) {
      return inputLabel({ label, touched, error, customFeedbackLabel })
}
