/* eslint-disable no-restricted-imports */
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls"
import { useUIContext } from "../UIContext"
import moment from 'moment'

import { Bold } from "../../../_partials/typography/Bold"
import CloseIcon from '@material-ui/icons/Close'
import { BookModalForm } from "./BookModalForm"

import IconButton from '@material-ui/core/IconButton'

export function BookModal({ bookingId, show, onHide }) {
   const { isLoading, bookings, resources } = useSelector(
      (state) => ({
         isLoading: state.booking.actionsLoading,
         bookings: state.booking?.bookings,
         resources: state.booking?.resources,
      }), shallowEqual)

   const UIContext = useUIContext()
   const UIProps = useMemo(() => {
      return {
         mapDate: UIContext.mapDate,
         mapSelectedResource: UIContext.mapSelectedResource,
         calendarSignedInInfo: UIContext.calendarSignedInInfo,
         setCalendarSignedInInfo: UIContext.setCalendarSignedInInfo,
         setMapSelectedResource: UIContext.setMapSelectedResource
      }
   }, [UIContext])

   function hide() {
      UIProps.setMapSelectedResource()
      onHide()
   }

   var initialValues = {
      date: UIProps.mapDate || moment().startOf("day"),
      start: moment().set({ minute: 0, second: 0 }).format("HH:mm"),
      end: moment().set({ minute: 0, second: 0 }).add({ hour: 1 }).format("HH:mm"),
      type: UIProps.mapSelectedResource?.type || "desk",
      floorPlan: UIProps.mapSelectedResource?.floorPlanId || "all",
      resource: UIProps.mapSelectedResource?.id || "",
      summary: "",
   }

   const bookingToEdit = bookings && bookings.find(val => val.id === bookingId)
   const resourceFromBookingToEdit = resources && resources.find(val => val.id === bookingToEdit?.resource)
   if (bookingId && bookingToEdit) {
      initialValues = {
         date: moment(bookingToEdit?.date.toDate()),
         start: moment(bookingToEdit?.start.toDate()).format("HH:mm"),
         end: moment(bookingToEdit?.end.toDate()).format("HH:mm"),
         type: resourceFromBookingToEdit?.type,
         floorPlan: resourceFromBookingToEdit?.floorPlanId,
         resource: resourceFromBookingToEdit?.id,
         summary: resourceFromBookingToEdit?.summary,
         attendees: resourceFromBookingToEdit?.attendees?.length > 0 ? resourceFromBookingToEdit?.attendees : undefined
      }
   }

   return <Modal
      show={show}
      onHide={hide}
   >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Body style={{ paddingTop: "10px", backgroundColor: "#ECF1F4", borderRadius: "10px" }}>
         {/* ━━★  TITLE  ★━━ */}
         <div className="row align-items-center">
            <div className="col-auto mr-auto">
               <Bold>{bookingId ? "EDIT BOOKING" : "ADD BOOKING"}</Bold>
            </div>
            <div className="col-auto" >
               <IconButton onClick={onHide}>
                  <CloseIcon />
               </IconButton>
            </div>
         </div>

         {/* ━━★  BODY  ★━━ */}
         <BookModalForm
            onHide={onHide}
            bookingId={bookingId}
            initialValues={initialValues}
         />
      </Modal.Body>
   </Modal>
}
