import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "../../_redux/historicalData/historicalDataActions";
import { LinearProgress } from "@material-ui/core/";
import Chart from "react-apexcharts";
import moment from "moment";
import { Card, CardBody, CardHeader } from "../../../_partials/Card";
import { Button } from "@material-ui/core";

export function Occupancy() {
  const dispatch = useDispatch();

  const { customer, location, superAdmin } = useSelector(
    (state) => ({
      customer: state.profile?.currentCustomer,
      location: state.profile?.currentLocation,
      superAdmin: state.auth.claims.isSuper,
    }),
    shallowEqual
  );

  const {
    isLoading,
    dayFloorData,
    weekFloorData,
    monthFloorData,
    floorPlans,
  } = useSelector(
    (state) => ({
      isLoading: state.historicalData.listLoading,
      dayFloorData: state.historicalData.dayFloorData,
      weekFloorData: state.historicalData.weekFloorData,
      monthFloorData: state.historicalData.monthFloorData,
      floorPlans: state.basePage.floorPlans,
    }),
    shallowEqual
  );

  const { customerId } = useSelector(
    (state) => ({
      customerId: superAdmin
        ? state.profile?.currentCustomer?.customerId
        : state.auth.claims?.customerId,
    }),
    shallowEqual
  );

  const [key, setKey] = useState("day");
  const [graphData, setGraphData] = useState();
  const [oldCustomer, setOldCustomer] = useState(customerId);
  const [oldLocation, setOldLocation] = useState(location?.netId);

  useEffect(() => {
    if (!oldLocation || !location) {
      return;
    }
    if (customerId === oldCustomer && location.netId === oldLocation) {
      key === "day" &&
        (dayFloorData
          ? setGraphData({
              ...dayFloorData,
              seriesShowed: dayFloorData?.yAxis.slice(0, 4),
            })
          : !isLoading &&
            dispatch(
              actions.getDayFloorData({
                customer: customer,
                location: location,
              })
            ));

      key === "week" &&
        (weekFloorData
          ? setGraphData({
              ...weekFloorData,
              seriesShowed: weekFloorData?.yAxis.slice(0, 4),
            })
          : !isLoading &&
            dispatch(
              actions.getWeekFloorData({
                customer: customer,
                location: location,
              })
            ));

      key === "month" &&
        (monthFloorData
          ? setGraphData({
              ...monthFloorData,
              seriesShowed: monthFloorData?.yAxis.slice(0, 4),
            })
          : !isLoading &&
            dispatch(
              actions.getMonthFloorData({
                customer: customer,
                location: location,
              })
            ));
    } else {
      setOldCustomer(customerId);
      setOldLocation(location.netId);
      setKey("day");
      setGraphData();
      dispatch(actions.clearData());
      dispatch(
        actions.getDayFloorData({
          customer: customer,
          location: location.netId,
        })
      );
    }
  }, [key, customer, location, dayFloorData, weekFloorData, monthFloorData]);

  return (
    <Card>
      <CardHeader
        title='Occupancy'
        subtitle='Most visited'
        toolbar={
          <>
            <Button
              color={key === "day" ? "primary" : "default"}
              onClick={() => setKey("day")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "day" && "#B8BCCA",
              }}
            >
              Day
            </Button>
            <Button
              color={key === "week" ? "primary" : "default"}
              onClick={() => setKey("week")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "week" && "#B8BCCA",
              }}
            >
              Week
            </Button>
            <Button
              color={key === "month" ? "primary" : "default"}
              onClick={() => setKey("month")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key !== "month" && "#B8BCCA",
              }}
            >
              Month
            </Button>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "350px" }}>
        {isLoading && <LinearProgress color='primary' />}
        <Chart
          options={getChartOptions({
            key,
            graphData,
            setGraphData,
            floorPlans,
          })}
          series={graphData?.yAxis || []}
          type='line'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions({ key, graphData, setGraphData, floorPlans }) {
  return {
    chart: {
      type: "line",
      height: "100%",
      toolbar: { show: false },
      zoom: { enabled: false },
      events: {
        updated: (chartContext, config) => {
          // console.log("🚀 ~ chartContext", chartContext);
          const { series } = config?.config;
          series &&
            series.forEach((seriesItem) => {
              const currentSeriesItemName = seriesItem?.name;
              if (
                graphData?.seriesShowed &&
                graphData.seriesShowed.find(
                  (val) => val?.name === seriesItem?.name
                )
              ) {
                chartContext && chartContext.showSeries(currentSeriesItemName);
              } else {
                chartContext && chartContext.hideSeries(currentSeriesItemName);
              }
            });
        },
        legendClick: (chartContext, seriesIndex, config) => {
          if (!graphData?.seriesShowed) return;

          const { series } = config.config;
          const selectedSeriesItemName = series[seriesIndex].name;
          if (
            graphData.seriesShowed.find(
              (val) => val.name === selectedSeriesItemName
            )
          ) {
            const newSeriesShowed = graphData?.seriesShowed.filter(
              (val) => val.name !== selectedSeriesItemName
            );
            setGraphData({ ...graphData, seriesShowed: newSeriesShowed });
          } else {
            const newSeriesShowed = [
              ...graphData?.seriesShowed,
              { name: selectedSeriesItemName },
            ];
            setGraphData({ ...graphData, seriesShowed: newSeriesShowed });
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#323389", "#99378B", "#DC4D79", "#FF7E62", "#FFBA57", "#F9F871"],
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    xaxis: {
      categories: graphData?.xAxis,
      tickAmount: key === "day" && 6,
      labels: {
        rotate: 0,
        formatter: (val) =>
          graphData?.day
            ? moment(val).format("HH:mm")
            : moment(val).format("DD MMM"),
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val && val.toFixed(0),
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: (val) => {
            const floor = floorPlans && floorPlans.find((v) => v.id === val);
            return floor?.name || "";
          },
        },
      },
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: true,
      },
      position: "bottom",
      formatter: (val) => {
        const floor = floorPlans && floorPlans.find((v) => v.id === val);
        return floor?.name || "";
      },
    },
  };
}
