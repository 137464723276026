import React, { useMemo, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useUIContext } from "../UIContext";
import * as actions from "../../_redux/reporting/sensors/sensorsActions";
import { firestore } from "../../../../firebase";
import { SensorHourly } from "./Floor/SensorHourly";

export function SensorsReportingPage() {
  const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const { group, customer } = useSelector(
    (state) => ({
      group: state.persistentReporting?.group,
      customer: state.profile?.currentCustomer,
    }),
    shallowEqual
  );

  // _______________ States _______________ \\
  // _______________________________________ \\
  const [sensorsFromDB, setSensorsFromDB] = useState([]);

  useEffect(() => {
    if (!customer) return;
    dispatch(
      actions.fetchSensors({
        customerId: customer.customerId,
        ...UIProps.queryParams,
      })
    );
  }, [customer, UIProps.queryParams]);

  // Get dropdown departments from db
  useEffect(() => {
    let mounted = true;

    const sensorsDropdown = [];
    const sensorsFields = firestore
      .collection("Customers")
      .doc(`${customer.customerId}`)
      .collection("Sensors");

    sensorsFields
      .get()
      .then((snapshot) => {
        // console.log(snapshot.docs);
        snapshot &&
          snapshot.docs &&
          snapshot.docs.forEach((doc) => {
            // console.log(doc.data(), "doc");
            sensorsDropdown.push({ id: doc.id, ...doc.data() });
          });
        if (mounted) {
          console.log(sensorsDropdown, "sensorsDropdown");
          setSensorsFromDB(sensorsDropdown);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return () => (mounted = false);
  }, [customer]);

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-xxl-12'>
          <SensorHourly />
        </div>
        {/* <div className='col-12'>
          <div className='card-stretch gutter-b pb-5'>
            <div
              style={{
                height: "17px",
                borderBottom: "1px solid black",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <span
                className='font-weight-bolder text-dark font-size-h2'
                style={{
                  backgroundColor: "#EDEFF7",
                  padding: "0 20px",
                }}
              >
                Departments data
              </span>
            </div>
          </div>
        </div> */}
        {/* <div className='col-lg-12 col-xxl-12'>
          <TeamsAtOffice />
        </div> */}
        {/* <div className='col-lg-12 col-xxl-12'>
          <TeamsCompare />
        </div> */}
      </div>
    </>
  );
}
