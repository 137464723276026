import moment from "moment";
import React from "react";
import {
  fetchAPI,
  newHandleSortAndSpliceTop5Data,
} from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const badFloorsChart = (queryParams) => {
  // console.log(queryParams, "badFloorsChart queryParams");

  return getFloorRSSIPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result");

      const data = badRSSIFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(data, "data");

      let options = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
          stacked: true,
        },

        dataLabels: {
          enabled: true,
          formatter: function(val, opts) {
            return val + "%";
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: 100,
          tickAmount: 5,
          labels: {
            formatter: function(val, index) {
              return val + "%";
            },
          },
        },
        noData: {
          text: "Loading...",
        },
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='bar' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        const xAxis = data[0].floors.map((d) => d);
        const floorNames = data[0].floors.map((f) => f);
        const locationNames = data[0].locations.map((l) => l);

        options = {
          ...options,
          noData: {
            text:
              floorNames && floorNames.length !== 0
                ? [floorNames, locationNames]
                : "No data to display.",
          },
          // legend: {
          //   show: false,
          // },
          colors: ["#f70000", "#f7a100", "#00b015", "#09ff00"],
          xaxis: {
            type: "category",
            categories: xAxis,
            labels: {
              style: {
                fontWeight: 500,
              },
            },
          },
        };

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={data[0].series}
              type='bar'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch rssi bad global per day ", error);
      error.clientMessage = "Can't fetch rssi bad global per day";
    });
};

// API fetch
function getFloorRSSIPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorRSSIPerDay", queryParams);
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/rssi/${queryParams.customerId}`,
  });
}

// Parse result
export const badRSSIFloorsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  var data = [];

  // console.log("🚀 ~ result", result);

  result.forEach((value) => {
    if (!value[1]) return;

    const floor = queryParams.levels.find(
      (floor) => floor.floorId === value[1]
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === value[0]
    );

    floor &&
      location &&
      data.push({
        location: location.netName,
        floorPlan: floor.name,
        // sampleSize: value[2],
        // numMacs: value[3],
        bad: Math.round(value[4]),
        average: Math.round(value[5]),
        good: Math.round(value[6]),
        excelent: Math.round(value[7]),
      });
  });
  // console.log("🚀 ~ data", data);

  const summedBadAndAverage = data.map((l) => {
    // console.log("🚀 ~ l", l);
    const sum = l.average + l.bad;
    // console.log("🚀 ~ sum", sum);
    const newList = {
      locations: l.location,
      floors: l.floorPlan,
      excelent: l.excelent,
      good: l.good,
      summedFloorData: sum,
    };
    return newList;
  });
  // console.log("🚀 ~ summedBadAndAverage", summedBadAndAverage);

  const sorted = summedBadAndAverage.sort((a, b) =>
    a.summedFloorData < b.summedFloorData ? 1 : -1
  );
  // console.log("🚀 ~ sorted", sorted);

  // Splice to TOP 5
  sorted.splice(5);

  const foundedFloors = sorted.map((s) => {
    const founded = data.find((l) => s.floors === l.floorPlan);
    return founded;
  });
  // console.log("🚀 ~ foundedFloors", foundedFloors);

  const dataBadFinal = [];
  const dataAverageFinal = [];
  const dataGoodFinal = [];
  const dataExcelentFinal = [];
  const newFloorsFinal = [];
  const newLocationsFinal = [];

  foundedFloors.forEach((f) => {
    dataBadFinal.push(f.bad);
    dataAverageFinal.push(f.average);
    dataGoodFinal.push(f.good);
    dataExcelentFinal.push(f.excelent);
    newFloorsFinal.push(f.floorPlan);
    newLocationsFinal.push(f.location);
  });

  // console.log(dataBadFinal, "dataBadFinal");
  // console.log(dataAverageFinal, "dataAverageFinal");
  // console.log(newFloorsFinal, "newFloorsFinal");

  const newSeriesFinal = [
    { name: "Bad", data: dataBadFinal },
    { name: "Average", data: dataAverageFinal },
    { name: "Good", data: dataGoodFinal },
    { name: "Excelent", data: dataExcelentFinal },
  ];
  // console.log("🚀 ~ newSeriesFinal", newSeriesFinal);

  const newFinal = [
    {
      series: newSeriesFinal,
      floors: newFloorsFinal,
      locations: newLocationsFinal,
    },
  ];
  // console.log("🚀 ~ newFinal", newFinal);

  return newFinal;
};
