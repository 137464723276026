import moment from "moment";
import { getAvg } from "../../Reporting/UIHelpers";
// import { element } from "prop-types";

export const firebaseLooper = (snapshot) => {
  let data = [];
  snapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
    });
  });
  return data;
};

export const convertCollectionSnapshotToMap = (collection) => {
  //if (!collection) return;
  const transformedCollection = collection.docs.map((doc) => {
    const event = doc.data();
    return { ...event, id: doc.id };
  });

  return transformedCollection.reduce((accumulator, doc) => {
    accumulator.push(doc);
    return accumulator;
  }, []);
};

export const convertCollectionSnapshotToMap2 = (collection) => {
  //if (!collection) return;

  const transformedCollection = collection.map((doc) => {
    const locationId = doc.Df.key.path.segments[7];
    // console.log("🚀 ~ locationId", locationId);
    const event = doc.data();
    return { ...event, id: doc.id, locationId: locationId };
  });

  return transformedCollection.reduce((accumulator, doc) => {
    accumulator.push(doc);
    return accumulator;
  }, []);
};

export const historicalResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { xAxis: dates, yAxis: series };
};

export const historicalResultToGraphData2 = (result, queryParams) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  const dataWithoutFloorG_000 = [];
  queryParams &&
    queryParams.location.levels.forEach((l) => {
      data &&
        data.map((d) => {
          if (d.name === l.floorId) {
            dataWithoutFloorG_000.push(d);
          }
        });
    });

  let dates = dataWithoutFloorG_000
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = dataWithoutFloorG_000.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals)
    .map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    })
    .filter((val) => {
      if (val.name === "null") {
        return;
      }
      if (val.name === "") {
        return;
      }
      return true;
    })
    .sort((a, b) => {
      const sumA = a.data.reduce((a, b) => a + b, 0);
      const averageA = sumA / a.data.length || 0;
      const sumB = b.data.reduce((a, b) => a + b, 0);
      const averageB = sumB / b.data.length || 0;
      return averageB - averageA;
    });

  return {
    xAxis: dates,
    yAxis: series,
  };
};

export const wifiAccuracyResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[3],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { xAxis: dates, yAxis: series };
};

export const floorOccupancyResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
      dates.indexOf(curr.date)
    ]
      ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
        parseInt(curr.count)
      : parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { labels: dates, series: series };
};

export const busiestZonesResultToGraphData2 = ({ result, queryParams }) => {
  let allWeekDataByWeek = [];
  let workDaysDataByWeek = [];
  let weekendsDataByWeek = [];

  let allWeekData = [];
  let workDaysData = [];
  let weekendsData = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    allWeekData.push({ x: val[1], y: val[2] });
  });

  JSON.parse(result).forEach((val) => {
    if (
      val[0].includes("Mon") ||
      val[0].includes("Tue") ||
      val[0].includes("Wed") ||
      val[0].includes("Thu") ||
      val[0].includes("Fri")
    ) {
      workDaysData.push({ x: val[1], y: val[2] });
    }
    if (val[0].includes("Sat") || val[0].includes("Sun")) {
      weekendsData.push({ x: val[1], y: val[2] });
    }
  });

  if (queryParams.queryParams.timeframe === "day") {
    const arrayPercentage = (array) => {
      let quartersum = {};
      const getKeys = array.map(function(entry) {
        quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y;
        return getKeys;
      });
      const getPercentage = array.map(function(entry) {
        entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1);
        return entry;
      });
      let allWeekPercentage = [
        {
          data: getPercentage,
        },
      ];
      return allWeekPercentage;
    };

    const allWeekDataFinal = arrayPercentage(allWeekData);
    const workingDaysDataFinal = arrayPercentage(workDaysData);
    const weekendsDataFinal = arrayPercentage(weekendsData);

    return {
      allWeekDataFinal: allWeekDataFinal,
      workingDaysDataFinal: workingDaysDataFinal,
      weekendsDataFinal: weekendsDataFinal,
    };
  }

  if (
    queryParams.queryParams.timeframe === "week" ||
    queryParams.queryParams.timeframe === "month"
  ) {
    JSON.parse(result).forEach((val) => {
      allWeekDataByWeek.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    });

    JSON.parse(result).forEach((val) => {
      if (
        val[0].includes("Mon") ||
        val[0].includes("Tue") ||
        val[0].includes("Wed") ||
        val[0].includes("Thu") ||
        val[0].includes("Fri")
      ) {
        workDaysDataByWeek.push({
          name: val[1],
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Sat") || val[0].includes("Sun")) {
        weekendsDataByWeek.push({
          name: val[1],
          count: val[2],
          date: val[0],
        });
      }
    });

    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });

      return holderOne.map((val, i) => {
        return { x: val.name, y: Math.round(val.count / val.counter) };
      });
    };

    const averageAllWeekDataByWeek = weekdaysSumFloors(allWeekDataByWeek);
    const averageWorkDaysDataByWeek = weekdaysSumFloors(workDaysDataByWeek);
    const averageWeekendsDataByWeek = weekdaysSumFloors(weekendsDataByWeek);

    const arrayPercentage = (array) => {
      let quartersum = {};
      const getKeys = array.map(function(entry) {
        quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y;
        return getKeys;
      });
      const getPercentage = array.map(function(entry) {
        entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1);
        return entry;
      });
      let allWeekPercentage = [
        {
          data: getPercentage,
        },
      ];
      return allWeekPercentage;
    };

    const allWeekDataFinal = arrayPercentage(averageAllWeekDataByWeek);
    const workingDaysDataFinal = arrayPercentage(averageWorkDaysDataByWeek);
    const weekendsDataFinal = arrayPercentage(averageWeekendsDataByWeek);

    return {
      allWeekDataFinal: allWeekDataFinal,
      workingDaysDataFinal: workingDaysDataFinal,
      weekendsDataFinal: weekendsDataFinal,
    };
  }
};

export const busiestFloorsResultToGraphData = ({ result, queryParams }) => {
  let allWeekLabels = [];
  let allWeekSeries = [];
  let workDaysSeries = [];
  let workDaysLabels = [];
  let weekendsSeries = [];
  let weekendsLabels = [];

  let allWeekDataByWeek = [];
  let workDaysDataByWeek = [];
  let weekendsDataByWeek = [];

  let allWeekSeriesByWeekAndMonth = [];
  let allWeekLabelsByWeekAndMonth = [];
  let workDaysSeriesByWeekAndMonth = [];
  let workDaysLabelsByWeekAndMonth = [];
  let weekendsSeriesByWeekAndMonth = [];
  let weekendsLabelsByWeekAndMonth = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    if (val[2] !== null) {
      allWeekSeries.push(val[2]);
    }
    if (val[1] !== null) {
      allWeekLabels.push(val[1]);
    }
  });

  JSON.parse(result).forEach((val) => {
    if (val[1] !== null || val[2] !== null) {
      if (
        val[0].includes("Mon") ||
        val[0].includes("Tue") ||
        val[0].includes("Wed") ||
        val[0].includes("Thu") ||
        val[0].includes("Fri")
      ) {
        workDaysSeries.push(val[2]);
        workDaysLabels.push(val[1]);
      }
      if (val[0].includes("Sat") || val[0].includes("Sun")) {
        weekendsSeries.push(val[2]);
        weekendsLabels.push(val[1]);
      }
    }
  });

  if (queryParams.queryParams.timeframe === "day") {
    return {
      allWeekSeries: allWeekSeries,
      allWeekLabels: allWeekLabels,
      // averageAllWeekTotal: averageAllWeekTotal,
      workDaysSeries: workDaysSeries,
      workDaysLabels: workDaysLabels,
      // averageWorkDaysTotal: averageWorkDaysTotal,
      weekendsSeries: weekendsSeries,
      weekendsLabels: weekendsLabels,
      // averageWeekendsTotal: averageWeekendsTotal,
    };
  }

  if (
    queryParams.queryParams.timeframe === "week" ||
    queryParams.queryParams.timeframe === "month"
  ) {
    JSON.parse(result).forEach((val) => {
      if (val[2] !== null || val[1] !== null) {
        allWeekDataByWeek.push({
          name: val[1],
          count: val[2],
          date: val[0],
        });
      }
    });

    JSON.parse(result).forEach((val) => {
      if (val[2] !== null || val[1] !== null) {
        if (
          val[0].includes("Mon") ||
          val[0].includes("Tue") ||
          val[0].includes("Wed") ||
          val[0].includes("Thu") ||
          val[0].includes("Fri")
        ) {
          workDaysDataByWeek.push({
            name: val[1],
            count: val[2],
            date: val[0],
          });
        }
        if (val[0].includes("Sat") || val[0].includes("Sun")) {
          weekendsDataByWeek.push({
            name: val[1],
            count: val[2],
            date: val[0],
          });
        }
      }
    });

    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const averageAllWeekDataByWeek = weekdaysSumFloors(allWeekDataByWeek);
    const averageWorkDaysDataByWeek = weekdaysSumFloors(workDaysDataByWeek);
    const averageWeekendsDataByWeek = weekdaysSumFloors(weekendsDataByWeek);

    averageAllWeekDataByWeek.forEach((val) => {
      allWeekSeriesByWeekAndMonth.push(val.value);
      allWeekLabelsByWeekAndMonth.push(val.name);
    });
    averageWorkDaysDataByWeek.forEach((val) => {
      workDaysSeriesByWeekAndMonth.push(val.value);
      workDaysLabelsByWeekAndMonth.push(val.name);
    });
    averageWeekendsDataByWeek.forEach((val) => {
      weekendsSeriesByWeekAndMonth.push(val.value);
      weekendsLabelsByWeekAndMonth.push(val.name);
    });

    return {
      allWeekSeries: allWeekSeriesByWeekAndMonth,
      allWeekLabels: allWeekLabelsByWeekAndMonth,
      // averageAllWeekTotal: allWeekByWeekAndMonthTotalAvg,
      workDaysSeries: workDaysSeriesByWeekAndMonth,
      workDaysLabels: workDaysLabelsByWeekAndMonth,
      // averageWorkDaysTotal: workDaysByWeekAndMonthTotalAvg,
      weekendsSeries: weekendsSeriesByWeekAndMonth,
      weekendsLabels: weekendsLabelsByWeekAndMonth,
      // averageWeekendsTotal: weekendsByWeekAndMonthTotalAvg,
    };
  }
};

export const floorOccupancyResultToGraphData2 = ({ result, queryParams }) => {
  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  JSON.parse(result).forEach((val) => {
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  if (queryParams.queryParams.timeframe === "day") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      // console.log(obj, "obj floors");
      // console.log(curr, "curr floors");
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});
    // console.log("🚀 ~ productTotals floors", productTotals);

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = "#626768";
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color = "#F9CE1D";
      }
      if (moment(val).format("dd") === "Sa") {
        color = "#2ec4b6";
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "week") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);

          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
          // holderOne[d.name] + d.count
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
          //holderOne[d.name] = d.count;
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
};

export const getDayTotalResultToGraphData = ({ result, queryParams }) => {
  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];
  let workDaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        count: value[1],
        date: value[0],
      });
  });

  if (queryParams.queryParams.timeframe === "day") {
    JSON.parse(result).forEach((val) => {
      if (
        val[0].includes("Mon") ||
        val[0].includes("Tue") ||
        val[0].includes("Wed") ||
        val[0].includes("Thu") ||
        val[0].includes("Fri")
      ) {
        workDaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Working Days",
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    const workDaysTotalDataCount = [];
    workDaysTotal.map((item) => workDaysTotalDataCount.push(item.count));

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: workDaysTotalDataCount,
      },
    ];

    const saturdaysTotalDataCount = [];
    saturdaysTotal.map((item) => saturdaysTotalDataCount.push(item.count));

    const seriesSaturdays = [
      {
        name: "Saturdays",
        data: saturdaysTotalDataCount,
      },
    ];

    const sundaysTotalDataCount = [];
    sundaysTotal.map((item) => sundaysTotalDataCount.push(item.count));

    const seriesSundays = [
      {
        name: "Sundays",
        data: sundaysTotalDataCount,
      },
    ];

    return {
      series: seriesWorkingDays,
      seriesSaturdays: seriesSaturdays,
      seriesSundays: seriesSundays,
    };
  }

  if (queryParams.queryParams.timeframe === "week") {
    JSON.parse(result).forEach((val) => {
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    let sumObjectsByKey = (...arrays) => {
      let holder = [];
      let holder2 = [].concat(...arrays);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const weeklyAverageTotal = sumObjectsByKey(
      ...mondaysTotal,
      ...tuesdaysTotal,
      ...wednesdaysTotal,
      ...thursdaysTotal,
      ...fridaysTotal
    );

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: weeklyAverageTotal,
      },
    ];

    let datesSaturday = saturdaysTotal
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotalsSaturdaysTotalWeek = saturdaysTotal.reduce(
      (obj, curr) => {
        if (!obj[curr.name]) {
          obj[curr.name] = [];
        }
        obj[curr.name][datesSaturday.indexOf(curr.date)] = parseInt(curr.count);
        return obj;
      },
      {}
    );

    const seriesSaturdaysWeek = Object.entries(
      productTotalsSaturdaysTotalWeek
    ).map(([name, prodArr]) => {
      return {
        name: name,
        data: datesSaturday.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let datesSunday = sundaysTotal
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotalsSundayTotalWeek = sundaysTotal.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][datesSunday.indexOf(curr.date)] = parseInt(curr.count);
      return obj;
    }, {});

    const seriesSundaysWeek = Object.entries(productTotalsSundayTotalWeek).map(
      ([name, prodArr]) => {
        return {
          name: name,
          data: datesSunday.map((month, monthIndex) => {
            if (!prodArr[monthIndex]) {
              return 0;
            } else {
              return prodArr[monthIndex];
            }
          }),
        };
      }
    );

    return {
      seriesWorkingDays: seriesWorkingDays,
      seriesSaturdaysWeek: seriesSaturdaysWeek,
      seriesSundaysWeek: seriesSundaysWeek,
    };
  }

  if (queryParams.queryParams.timeframe === "month") {
    JSON.parse(result).forEach((val) => {
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Working Days",
          count: val[1],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[1],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    let sumObjectsByKey = (...arrays) => {
      let holder = [];
      let holder2 = [].concat(...arrays);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const weeklyAverageTotal = sumObjectsByKey(
      ...mondaysTotal,
      ...tuesdaysTotal,
      ...wednesdaysTotal,
      ...thursdaysTotal,
      ...fridaysTotal
    );

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: weeklyAverageTotal,
      },
    ];

    const sumOfSameHours = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (
          holderOne.some(
            (val) => moment(val.date).hour() === moment(d.date).hour()
          )
        ) {
          const index = holderOne.findIndex(
            (val) => moment(val.date).hour() === moment(d.date).hour()
          );
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            date: holderOne[index].date,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: "Total",
            count: d.count,
            date: d.date,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const summedSaturdaysOfTheMonth = sumOfSameHours(saturdaysTotal);
    const summedSundaysOfTheMonth = sumOfSameHours(sundaysTotal);

    const seriesSaturdays = [
      {
        name: "Saturdays ",
        data: summedSaturdaysOfTheMonth,
      },
    ];

    const seriesSundays = [
      {
        name: "Sundays",
        data: summedSundaysOfTheMonth,
      },
    ];

    return {
      seriesWorkingDays: seriesWorkingDays,
      seriesSaturdays: seriesSaturdays,
      seriesSundays: seriesSundays,
    };
  }
};

export const zoneTotalResultToGraphData = ({ queryParams, result }) => {
  if (!result) {
    return;
  }
};

export const zoneOccupancyByHourResultToGraphData = ({
  queryParams,
  result,
}) => {
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];
  let workDaysTotal = [];

  if (!result) {
    return;
  }

  if (queryParams.queryParams.timeframe === "day") {
    JSON.parse(result).forEach((val) => {
      if (
        val[0].includes("Mon") ||
        val[0].includes("Tue") ||
        val[0].includes("Wed") ||
        val[0].includes("Thu") ||
        val[0].includes("Fri")
      ) {
        workDaysTotal.push({
          count: val[2],
          date: val[0],
          name: "Working Days",
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[2],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[2],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    const sumObjectsByKey = (array) => {
      let holder = [];
      let holder2 = [].concat(array);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const workDaysTotalAverage = sumObjectsByKey(workDaysTotal);
    const saturdaysTotalAverage = sumObjectsByKey(saturdaysTotal);
    const sundaysTotalAverage = sumObjectsByKey(sundaysTotal);

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: workDaysTotalAverage,
      },
    ];

    const seriesSaturdays = [
      {
        name: "Saturdays",
        data: saturdaysTotalAverage,
      },
    ];

    const seriesSundays = [
      {
        name: "Sundays",
        data: sundaysTotalAverage,
      },
    ];

    return {
      series: seriesWorkingDays,
      seriesSaturdays: seriesSaturdays,
      seriesSundays: seriesSundays,
    };
  }

  if (
    queryParams.queryParams.timeframe === "week" ||
    queryParams.queryParams.timeframe === "month"
  ) {
    JSON.parse(result).forEach((val) => {
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Working Days",
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Working Days",
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Working Days",
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Working Days",
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Working Days",
          count: val[2],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[2],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[2],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    let sumObjectsByKey = (...arrays) => {
      let holder = [];
      let holder2 = [].concat(...arrays);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const weeklyAverageTotal = sumObjectsByKey(
      ...mondaysTotal,
      ...tuesdaysTotal,
      ...wednesdaysTotal,
      ...thursdaysTotal,
      ...fridaysTotal
    );

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: weeklyAverageTotal,
      },
    ];

    const sumObjectsByKeySingleArray = (array) => {
      let holder = [];
      let holder2 = [].concat(array);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const saturdaysTotalAverage = sumObjectsByKeySingleArray(saturdaysTotal);
    const sundaysTotalAverage = sumObjectsByKeySingleArray(sundaysTotal);

    const seriesSaturdaysWeek = [
      {
        name: "Saturdays",
        data: saturdaysTotalAverage,
      },
    ];

    const seriesSundaysWeek = [
      {
        name: "Sundays",
        data: sundaysTotalAverage,
      },
    ];

    return {
      seriesWorkingDays: seriesWorkingDays,
      seriesSaturdays: seriesSaturdaysWeek,
      seriesSundays: seriesSundaysWeek,
    };
  }
};

export const floorOccupancyTotalPeopleResultToGraphData = ({
  queryParams,
  result,
}) => {
  let labels = [];
  let series = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thurdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    labels.push(val[0]);
    series.push(val[1]);

    if (val[0].includes("Mon")) {
      mondaysTotal.push(val[1]);
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Thu")) {
      thurdaysTotal.push(val[1]);
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push(val[1]);
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push(val[1]);
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push(val[1]);
    }
  });

  const weeklyAverage = [];

  if (queryParams.queryParams.timeframe.includes("month")) {
    const mondaysAverage = getAvg(mondaysTotal);
    const mondaysRounded = Math.floor(mondaysAverage);
    weeklyAverage.push(mondaysRounded);
    const tuesdaysAverage = getAvg(tuesdaysTotal);
    const tuesdayRounded = Math.floor(tuesdaysAverage);
    weeklyAverage.push(tuesdayRounded);
    const wednesdaysAverage = getAvg(wednesdaysTotal);
    const wednesdayRounded = Math.floor(wednesdaysAverage);
    weeklyAverage.push(wednesdayRounded);
    const thurdaysAverage = getAvg(thurdaysTotal);
    const thurdaysRounded = Math.floor(thurdaysAverage);
    weeklyAverage.push(thurdaysRounded);
    const fridaysAverage = getAvg(fridaysTotal);
    const fridaysRounded = Math.floor(fridaysAverage);
    weeklyAverage.push(fridaysRounded);
    const saturdaysAverage = getAvg(saturdaysTotal);
    const saturdaysRounded = Math.floor(saturdaysAverage);
    weeklyAverage.push(saturdaysRounded);
    const sundaysAverage = getAvg(sundaysTotal);
    const sundaysRounded = Math.floor(sundaysAverage);
    weeklyAverage.push(sundaysRounded);

    return {
      labels: labels,
      series: series,
      weeklyAverage: weeklyAverage,
    };
  }

  return {
    labels: labels,
    series: series,
    weeklyAverage: weeklyAverage,
  };
};

export const zoneOccupancyTotalPeopleResultToGraphData = ({
  queryParams,
  result,
}) => {
  let labels = [];
  let series = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thurdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    labels.push(val[0]);
    series.push(val[1]);

    if (val[0].includes("Mon")) {
      mondaysTotal.push(val[1]);
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Thu")) {
      thurdaysTotal.push(val[1]);
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push(val[1]);
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push(val[1]);
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push(val[1]);
    }
  });

  const weeklyAverage = [];

  if (queryParams.queryParams.timeframe.includes("month")) {
    const mondaysAverage = getAvg(mondaysTotal);
    const mondaysRounded = Math.floor(mondaysAverage);
    weeklyAverage.push(mondaysRounded);
    const tuesdaysAverage = getAvg(tuesdaysTotal);
    const tuesdayRounded = Math.floor(tuesdaysAverage);
    weeklyAverage.push(tuesdayRounded);
    const wednesdaysAverage = getAvg(wednesdaysTotal);
    const wednesdayRounded = Math.floor(wednesdaysAverage);
    weeklyAverage.push(wednesdayRounded);
    const thurdaysAverage = getAvg(thurdaysTotal);
    const thurdaysRounded = Math.floor(thurdaysAverage);
    weeklyAverage.push(thurdaysRounded);
    const fridaysAverage = getAvg(fridaysTotal);
    const fridaysRounded = Math.floor(fridaysAverage);
    weeklyAverage.push(fridaysRounded);
    const saturdaysAverage = getAvg(saturdaysTotal);
    const saturdaysRounded = Math.floor(saturdaysAverage);
    weeklyAverage.push(saturdaysRounded);
    const sundaysAverage = getAvg(sundaysTotal);
    const sundaysRounded = Math.floor(sundaysAverage);
    weeklyAverage.push(sundaysRounded);

    return {
      labels: labels,
      series: series,
      weeklyAverage: weeklyAverage,
    };
  }

  return {
    labels: labels,
    series: series,
    weeklyAverage: weeklyAverage,
  };
};

export const zoneOccupancyTotalPeopleResultToGraphDataByLevel = ({
  queryParams,
  result,
}) => {
  let labels = [];
  let series = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thurdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    labels.push(val[0]);
    series.push(val[1]);

    if (val[0].includes("Mon")) {
      mondaysTotal.push(val[1]);
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Thu")) {
      thurdaysTotal.push(val[1]);
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push(val[1]);
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push(val[1]);
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push(val[1]);
    }
  });

  // console.log(result, "result");
  // console.log(labels, "labels");
  // console.log(series, "series");

  const weeklyAverage = [];

  if (queryParams.queryParams.timeframe.includes("month")) {
    const mondaysAverage = getAvg(mondaysTotal);
    const mondaysRounded = Math.floor(mondaysAverage);
    weeklyAverage.push(mondaysRounded);
    const tuesdaysAverage = getAvg(tuesdaysTotal);
    const tuesdayRounded = Math.floor(tuesdaysAverage);
    weeklyAverage.push(tuesdayRounded);
    const wednesdaysAverage = getAvg(wednesdaysTotal);
    const wednesdayRounded = Math.floor(wednesdaysAverage);
    weeklyAverage.push(wednesdayRounded);
    const thurdaysAverage = getAvg(thurdaysTotal);
    const thurdaysRounded = Math.floor(thurdaysAverage);
    weeklyAverage.push(thurdaysRounded);
    const fridaysAverage = getAvg(fridaysTotal);
    const fridaysRounded = Math.floor(fridaysAverage);
    weeklyAverage.push(fridaysRounded);
    const saturdaysAverage = getAvg(saturdaysTotal);
    const saturdaysRounded = Math.floor(saturdaysAverage);
    weeklyAverage.push(saturdaysRounded);
    const sundaysAverage = getAvg(sundaysTotal);
    const sundaysRounded = Math.floor(sundaysAverage);
    weeklyAverage.push(sundaysRounded);

    return {
      labels: labels,
      series: series,
      weeklyAverage: weeklyAverage,
    };
  }

  return {
    labels: labels,
    series: series,
    weeklyAverage: weeklyAverage,
  };
};

export const zoneOccupancyResultToGraphData2 = ({ queryParams, result }) => {
  let data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  JSON.parse(result).forEach((val) => {
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  if (queryParams.queryParams.timeframe === "day") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = "#626768";
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color = "#F9CE1D";
      }
      if (moment(val).format("dd") === "Sa") {
        color = "#2ec4b6";
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "week") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
};

export const zoneOccupancyResultToGraphData2ByLevel = ({
  queryParams,
  result,
}) => {
  let data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
        selectedLevel: value[3],
      });
  });

  JSON.parse(result).forEach((val) => {
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
        selectedLevel: val[3],
      });
    }
  });

  const filteredData = data.filter(
    (val) => val.selectedLevel === queryParams.selectedLevel.floorId
  );

  if (queryParams.queryParams.timeframe === "day") {
    let dates = filteredData
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = filteredData.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = "#626768";
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color = "#F9CE1D";
      }
      if (moment(val).format("dd") === "Sa") {
        color = "#2ec4b6";
      }
    });

    let formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    // const date = moment();
    // const finalLabel = date.subtract(1, "days").format("ddd MMM D");

    // if (formattedDates.length === 0) {
    //   formattedDates = [finalLabel];
    // }

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "week") {
    let dates = filteredData
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = filteredData.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const filteredArray = (array) => {
      return array.filter(
        (val) => val.selectedLevel === queryParams.selectedLevel.floorId
      );
    };

    const mondayDataFiltered = filteredArray(mondaysTotal);
    const tuesdayDataFiltered = filteredArray(tuesdaysTotal);
    const wednesdayDataFiltered = filteredArray(wednesdaysTotal);
    const thursdayDataFiltered = filteredArray(thursdaysTotal);
    const fridayDataFiltered = filteredArray(fridaysTotal);
    const saturdayDataFiltered = filteredArray(saturdaysTotal);
    const sundayDataFiltered = filteredArray(sundaysTotal);

    const mondayData = weekdaysSumFloors(mondayDataFiltered);
    const tuesdayData = weekdaysSumFloors(tuesdayDataFiltered);
    const wednesdayData = weekdaysSumFloors(wednesdayDataFiltered);
    const thursdayData = weekdaysSumFloors(thursdayDataFiltered);
    const fridayData = weekdaysSumFloors(fridayDataFiltered);
    const saturdayData = weekdaysSumFloors(saturdayDataFiltered);
    const sundayData = weekdaysSumFloors(sundayDataFiltered);

    let finalSeries = [];
    filteredData.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
};

export const zoneOccupancyResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
      dates.indexOf(curr.date)
    ]
      ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
        parseInt(curr.count)
      : parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { options: dates, series: series };
};

export const AvgTimeDayResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
      dates.indexOf(curr.date)
    ]
      ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
        parseInt(curr.count)
      : parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { options: dates, series: series };
};

export const avgTimeZoneOccupancyResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
      dates.indexOf(curr.date)
    ]
      ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
        parseInt(curr.count)
      : parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { options: dates, series: series };
};

export const avgTimeSpentTopZonesResultToGraphData = ({
  result,
  queryParams,
}) => {
  let allWeekDataByWeek = [];
  let workDaysDataByWeek = [];
  let weekendsDataByWeek = [];

  let allWeekData = [];
  let workDaysData = [];
  let weekendsData = [];

  if (!result) {
    return;
  }

  JSON.parse(result).forEach((val) => {
    if (val[2] && val[3]) {
      allWeekData.push({ x: val[2], y: val[3] });
    }
  });

  JSON.parse(result).forEach((val) => {
    if (
      val[0]?.includes("Mon") ||
      val[0]?.includes("Tue") ||
      val[0]?.includes("Wed") ||
      val[0]?.includes("Thu") ||
      val[0]?.includes("Fri")
    ) {
      workDaysData.push({ x: val[2], y: val[3] });
    }
    if (val[0]?.includes("Sat") || val[0]?.includes("Sun")) {
      weekendsData.push({ x: val[2], y: val[3] });
    }
  });

  if (queryParams.queryParams.timeframe === "day") {
    // const arrayPercentage = (array) => {
    //   let quartersum = {};
    //   const getKeys = array.map(function(entry) {
    //     quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y;
    //     return getKeys;
    //   });
    //   const getPercentage = array.map(function(entry) {
    //     entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1);
    //     return entry;
    //   });
    //   let allWeekPercentage = [
    //     {
    //       data: getPercentage,
    //     },
    //   ];
    //   return allWeekPercentage;
    // };
    // const allWeekDataFinal = arrayPercentage(allWeekData);
    // const workingDaysDataFinal = arrayPercentage(workDaysData);
    // const weekendsDataFinal = arrayPercentage(weekendsData);

    const allWeekDataFinalArray = [
      {
        data: allWeekData,
      },
    ];
    const workingDaysDataFinalArray = [
      {
        data: workDaysData,
      },
    ];
    const weekendsDataFinalArray = [
      {
        data: weekendsData,
      },
    ];

    return {
      allWeekDataFinal: allWeekDataFinalArray,
      workingDaysDataFinal: workingDaysDataFinalArray,
      weekendsDataFinal: weekendsDataFinalArray,
    };
  }

  if (
    queryParams.queryParams.timeframe === "week" ||
    queryParams.queryParams.timeframe === "month"
  ) {
    JSON.parse(result).forEach((val) => {
      if (val[0] && val[2] && val[3]) {
        allWeekDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
    });

    JSON.parse(result).forEach((val) => {
      if (
        val[0]?.includes("Mon") ||
        val[0]?.includes("Tue") ||
        val[0]?.includes("Wed") ||
        val[0]?.includes("Thu") ||
        val[0]?.includes("Fri")
      ) {
        workDaysDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
      if (val[0]?.includes("Sat") || val[0]?.includes("Sun")) {
        weekendsDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
    });

    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });

      return holderOne.map((val, i) => {
        return { x: val.name, y: Math.round(val.count / val.counter) };
      });
    };

    const averageAllWeekDataByWeek = weekdaysSumFloors(allWeekDataByWeek);
    const averageWorkDaysDataByWeek = weekdaysSumFloors(workDaysDataByWeek);
    const averageWeekendsDataByWeek = weekdaysSumFloors(weekendsDataByWeek);

    const allWeekDataFinalArray = [
      {
        data: averageAllWeekDataByWeek,
      },
    ];
    const workingDaysDataFinalArray = [
      {
        data: averageWorkDaysDataByWeek,
      },
    ];
    const weekendsDataFinalArray = [
      {
        data: averageWeekendsDataByWeek,
      },
    ];

    // const arrayPercentage = (array) => {
    //   let quartersum = {};
    //   const getKeys = array.map(function(entry) {
    //     quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y;
    //     return getKeys;
    //   });
    //   const getPercentage = array.map(function(entry) {
    //     entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1);
    //     return entry;
    //   });
    //   let allWeekPercentage = [
    //     {
    //       data: getPercentage,
    //     },
    //   ];
    //   return allWeekPercentage;
    // };

    // const allWeekDataFinal = arrayPercentage(averageAllWeekDataByWeek);
    // const workingDaysDataFinal = arrayPercentage(averageWorkDaysDataByWeek);
    // const weekendsDataFinal = arrayPercentage(averageWeekendsDataByWeek);

    return {
      allWeekDataFinal: allWeekDataFinalArray,
      workingDaysDataFinal: workingDaysDataFinalArray,
      weekendsDataFinal: weekendsDataFinalArray,
    };
  }
};

export const avgTimeSpentZoneResultToGraphData = ({ result, queryParams }) => {
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];
  let workDaysTotal = [];

  if (!result) {
    return;
  }

  // console.log(result, "result ACTIONS");

  if (queryParams.queryParams.timeframe === "day") {
    JSON.parse(result).forEach((val) => {
      if (
        val[0].includes("Mon") ||
        val[0].includes("Tue") ||
        val[0].includes("Wed") ||
        val[0].includes("Thu") ||
        val[0].includes("Fri")
      ) {
        workDaysTotal.push({
          count: val[3],
          date: val[0],
          name: "Working Days",
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[3],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[3],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    const sumObjectsByKey = (array) => {
      let holder = [];
      let holder2 = [].concat(array);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const workDaysTotalAverage = sumObjectsByKey(workDaysTotal);
    const saturdaysTotalAverage = sumObjectsByKey(saturdaysTotal);
    const sundaysTotalAverage = sumObjectsByKey(sundaysTotal);

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: workDaysTotalAverage,
      },
    ];

    const seriesSaturdays = [
      {
        name: "Saturdays",
        data: saturdaysTotalAverage,
      },
    ];

    const seriesSundays = [
      {
        name: "Sundays",
        data: sundaysTotalAverage,
      },
    ];

    return {
      series: seriesWorkingDays,
      seriesSaturdays: seriesSaturdays,
      seriesSundays: seriesSundays,
    };
  }

  if (
    queryParams.queryParams.timeframe === "week" ||
    queryParams.queryParams.timeframe === "month"
  ) {
    JSON.parse(result).forEach((val) => {
      if (val[0].includes("Mon")) {
        mondaysTotal.push({
          name: "Working Days",
          count: val[3],
          date: val[0],
        });
      }
      if (val[0].includes("Tue")) {
        tuesdaysTotal.push({
          name: "Working Days",
          count: val[3],
          date: val[0],
        });
      }
      if (val[0].includes("Wed")) {
        wednesdaysTotal.push({
          name: "Working Days",
          count: val[3],
          date: val[0],
        });
      }
      if (val[0].includes("Thu")) {
        thursdaysTotal.push({
          name: "Working Days",
          count: val[3],
          date: val[0],
        });
      }
      if (val[0].includes("Fri")) {
        fridaysTotal.push({
          name: "Working Days",
          count: val[3],
          date: val[0],
        });
      }
      if (val[0].includes("Sat")) {
        saturdaysTotal.push({
          count: val[3],
          date: val[0],
          name: "Saturdays",
        });
      }
      if (val[0].includes("Sun")) {
        sundaysTotal.push({
          count: val[3],
          date: val[0],
          name: "Sundays",
        });
      }
    });

    let sumObjectsByKey = (...arrays) => {
      let holder = [];
      let holder2 = [].concat(...arrays);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const weeklyAverageTotal = sumObjectsByKey(
      ...mondaysTotal,
      ...tuesdaysTotal,
      ...wednesdaysTotal,
      ...thursdaysTotal,
      ...fridaysTotal
    );

    const seriesWorkingDays = [
      {
        name: "Working Days",
        data: weeklyAverageTotal,
      },
    ];

    const sumObjectsByKeySingleArray = (array) => {
      let holder = [];
      let holder2 = [].concat(array);
      holder2.forEach((val) => {
        if (
          holder.some((e) => moment(e.date).hour() === moment(val.date).hour())
        ) {
          let index = holder.findIndex(
            (e) => moment(e.date).hour() === moment(val.date).hour()
          );
          holder[index] = {
            ...holder[index],
            count: holder[index].count + val.count,
            date: holder[index].date,
            counter: holder[index].counter + 1,
          };
        } else {
          holder.push({
            ...val,
            counter: 1,
          });
        }
      });
      return holder.map((val, i) => {
        return Math.round(val.count / val.counter);
      });
    };

    const saturdaysTotalAverage = sumObjectsByKeySingleArray(saturdaysTotal);
    const sundaysTotalAverage = sumObjectsByKeySingleArray(sundaysTotal);

    const seriesSaturdaysWeek = [
      {
        name: "Saturdays",
        data: saturdaysTotalAverage,
      },
    ];

    const seriesSundaysWeek = [
      {
        name: "Sundays",
        data: sundaysTotalAverage,
      },
    ];

    return {
      seriesWorkingDays: seriesWorkingDays,
      seriesSaturdays: seriesSaturdaysWeek,
      seriesSundays: seriesSundaysWeek,
    };
  }
};

export const AvgTimeZoneResultToGraphData = (result) => {
  var data = [];

  JSON.parse(result).forEach((value) => {
    value &&
      value[1] &&
      data.push({
        name: value[1],
        count: value[2],
        date: value[0],
      });
  });

  let dates = data
    .map((item) => item.date)
    .filter((item, index, array) => array.indexOf(item) === index);

  const productTotals = data.reduce((obj, curr) => {
    if (!obj[curr.name]) {
      obj[curr.name] = [];
    }
    obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
      dates.indexOf(curr.date)
    ]
      ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
        parseInt(curr.count)
      : parseInt(curr.count);
    return obj;
  }, {});

  const series = Object.entries(productTotals).map(([name, prodArr]) => {
    return {
      name: name,
      data: dates.map((month, monthIndex) => {
        if (!prodArr[monthIndex]) {
          return 0;
        } else {
          return prodArr[monthIndex];
        }
      }),
    };
  });

  return { options: dates, series: series };
};

//★━━━━━━━━━━━━━━★ Teams ★━━━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const teamsFloorOccupancyResultToGraphData = ({
  result,
  queryParams,
}) => {
  let data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  if (!result) {
    return;
  }

  const departmentName = queryParams.department.name;

  // console.log(result, "result");

  const endDate = moment(queryParams.queryParams.startDate);
  const timeframe = queryParams.queryParams.timeframe;

  const startDate = moment(endDate.toDate()).subtract(
    timeframe === "week"
      ? { days: 6 }
      : timeframe === "month"
      ? { month: 1 }
      : { day: 0 }
  );

  // console.log(endDate, "endDate");
  // console.log(startDate, "startDate");

  var currDate = moment(startDate)
    .startOf("day")
    .subtract(1, "day");
  var lastDate = moment(endDate)
    .startOf("day")
    .add(1, "day");

  let newResult = JSON.parse(result);

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    if (
      !newResult.some((val) =>
        moment(val[0])
          .startOf("day")
          .isSame(currDate)
      )
    ) {
      newResult.push([
        currDate.clone().format("dddd, DD MMM YYYY 00:00:00"),
        0,
      ]);
    }

    // console.log(currDate.toDate());
    // dates.push(currDate.clone().toDate());
  }

  const sortedNewResult = newResult.sort((a, b) =>
    moment(a[0]).isAfter(moment(b[0]))
      ? 1
      : moment(a[0]).isBefore(moment(b[0]))
      ? -1
      : 0
  );

  // console.log(sortedNewResult, "sortedNewResult");
  // console.log(newResult, "newResult");

  // console.log(enumerateDaysBetweenDates, "enumerateDaysBetweenDates");

  sortedNewResult.forEach((value) => {
    // value &&
    //   value[1] &&
    data.push({
      count: value[1],
      date: value[0],
      // department: departmentName,
    });
  });

  sortedNewResult.forEach((value) => {
    if (value[0].includes("Mon")) {
      mondaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Tue")) {
      tuesdaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Wed")) {
      wednesdaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Thu")) {
      thursdaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Fri")) {
      fridaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Sat")) {
      saturdaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
    if (value[0].includes("Sun")) {
      sundaysTotal.push({
        count: value[1],
        date: value[0],
      });
    }
  });

  if (
    queryParams.queryParams.timeframe === "day" ||
    queryParams.queryParams.timeframe === "week"
  ) {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: departmentName,
        type: "column",
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    // let color = "#626768";
    // dates.forEach((val) => {
    //   if (moment(val).format("dd") === "Su") {
    //     color = "#F9CE1D";
    //   }
    //   if (moment(val).format("dd") === "Sa") {
    //     color = "#2ec4b6";
    //   }
    // });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    // console.log(series, "series");
    // console.log(formattedDates, "labels");
    // console.log(color, "color");

    return [
      {
        labels: formattedDates,
        series: series,
        color: color,
      },
    ];
  }

  if (queryParams.queryParams.timeframe === "month") {
    const sumArray = (array) => array.map((e) => e.count);
    const avg = (array) => {
      const result = array.reduce((r, c) => r + c, 0) / array.length;
      return Math.round(result);
    };

    const mondaySummed = sumArray(mondaysTotal);
    const tuesdaySummed = sumArray(tuesdaysTotal);
    const wednesdaySummed = sumArray(wednesdaysTotal);
    const thursdaySummed = sumArray(thursdaysTotal);
    const fridaySummed = sumArray(fridaysTotal);
    const saturdaySummed = sumArray(saturdaysTotal);
    const sundaySummed = sumArray(sundaysTotal);
    const mondaysAvg = mondaySummed.length > 0 ? avg(mondaySummed) : "";
    const tuesdaysAvg = tuesdaySummed.length > 0 ? avg(tuesdaySummed) : "";
    const wednesdaysAvg =
      wednesdaySummed.length > 0 ? avg(wednesdaySummed) : "";
    const thursdaysAvg = thursdaySummed.length > 0 ? avg(thursdaySummed) : "";
    const fridaysAvg = fridaySummed.length > 0 ? avg(fridaySummed) : "";
    const saturdaysAvg = saturdaySummed.length > 0 ? avg(saturdaySummed) : "";
    const sundaysAvg = sundaySummed.length > 0 ? avg(sundaySummed) : "";

    const finalSeries = {
      name: departmentName,
      type: "column",
      data: [
        mondaysAvg,
        tuesdaysAvg,
        wednesdaysAvg,
        thursdaysAvg,
        fridaysAvg,
        saturdaysAvg,
        sundaysAvg,
      ],
    };

    // console.log(finalSeries, "finalSeries");

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
};

//★━━━━━━━━━━━━━━★ Bookings ★━━━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const allBookingsResultToGraphData = ({ result, queryParams }) => {
  if (!result) {
    return;
  }

  let datesFetched = [];
  let data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  JSON.parse(result).forEach((value) => {
    value &&
      data.push(
        {
          name: "Booked",
          count: value[1] ? [value[1]] : [0],
          date: value[0],
        },
        {
          name: "Confirmed",
          count: value[2] ? [value[2]] : [0],
          date: value[0],
        },
        {
          name: "Deleted",
          count: value[3] ? [value[3]] : [0],
          date: value[0],
        }
      );
    datesFetched.push({
      date: value[0],
    });
  });

  // result && console.log(result, "helpers");
  // data && console.log("🚀 ~ data", data);

  if (
    queryParams.queryParams.timeframe === "day" ||
    queryParams.queryParams.timeframe === "week"
  ) {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });
    // console.log("🚀 ~ series", series);

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.queryParams.timeframe === "month") {
    JSON.parse(result).forEach((value) => {
      if (value[0].includes("Mon")) {
        mondaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Tue")) {
        tuesdaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Wed")) {
        wednesdaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Thu")) {
        thursdaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Fri")) {
        fridaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Sat")) {
        saturdaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
      if (value[0].includes("Sun")) {
        sundaysTotal.push(
          {
            name: "Booked",
            count: value[1] ? value[1] : 0,
            date: value[0],
          },
          {
            name: "Confirmed",
            count: value[2] ? value[2] : 0,
            date: value[0],
          },
          {
            name: "Deleted",
            count: value[3] ? value[3] : 0,
            date: value[0],
          }
        );
        datesFetched.push({
          date: value[0],
        });
      }
    });

    // mondaysTotal && console.log(mondaysTotal, "mondaysTotal");
    // tuesdaysTotal && console.log(tuesdaysTotal, "tuesdaysTotal");
    // wednesdaysTotal && console.log(wednesdaysTotal, "wednesdaysTotal");
    // thursdaysTotal && console.log(thursdaysTotal, "thursdaysTotal");
    // fridaysTotal && console.log(fridaysTotal, "fridaysTotal");
    // saturdaysTotal && console.log(saturdaysTotal, "saturdaysTotal");
    // sundaysTotal && console.log(sundaysTotal, "sundaysTotal");

    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
};
