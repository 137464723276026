import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import { DataGrid } from "@material-ui/data-grid";

export const OverviewZonesChart = async (queryParams) => {
  // console.log(queryParams, "SNRFloorsChart queryParams");

  const netFailuresSeries = [];
  const netFailuresData = await getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      const netFailuresParsed = netFailuresFloorsToGraphData({
        result,
        queryParams,
      });

      return netFailuresParsed;
    });

  // console.log(netFailuresData, "netFailuresData");
  netFailuresSeries.push(netFailuresData);

  return getFloorSNRPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = badSNRFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(netFailuresSeries, "netFailuresSeries 2");
      // console.log(data, "data SNRFloorsChart");

      const columns = [
        {
          field: "id",
          headerName: "ID",
          width: 47,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "device",
          headerName: "Serial",
          width: 200,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "netFailures",
          headerName: "Net Failures",
          type: "number",
          width: 200,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "snr",
          headerName: "SNR",
          type: "number",
          width: 200,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "location",
          headerName: "Location",
          type: "number",
          width: 200,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
        {
          field: "floorPlan",
          headerName: "Floor Plan",
          type: "number",
          width: 200,
          editable: true,
          headerClassName: "header-cell",
          cellClassName: "row-cell",
        },
      ];

      const rows = [
        {
          id: 1,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 2,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 3,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 4,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 5,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 6,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
        {
          id: 7,
          device: "Loading...",
          netFailures: "Loading...",
          snr: "Loading...",
          location: "Loading...",
          floorPlan: "Loading...",
        },
      ];

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              //   checkboxSelection
              disableSelectionOnClick
            />
          </div>
        );
        return mainDiv;
      } else {
        const newRows = [];

        // console.log(data, "data");
        data.map((d, i) =>
          newRows.push({
            // id: i + 1,
            device: d.device,
            snr: d.count,
            netFailures: 0,
            location: d.location,
            floorPlan: d.floorPlan,
          })
        );
        // console.log(newRows, "newRows");
        // console.log(netFailuresSeries[0], " netFailuresSeries[0]");

        const failuresArr = netFailuresSeries && netFailuresSeries[0];

        const mergeArr = (arr1, arr2) => {
          const obj = {};

          arr1.forEach((item) => {
            obj[item.device] = item;
          });

          arr2.forEach((item) => {
            // console.log("🚀 ~ item", item);
            // console.log("🚀 ~ obj[item.device]", obj[item.device]);
            let newSerie = {
              // date: item.date,
              device: item.device,
              netFailures: item.count,
              snr: 0,
              location: item.location,
              floorPlan: item.floorPlan,
            };

            obj[item.device]
              ? (obj[item.device] = {
                  ...obj[item.device],
                  netFailures: item.count,
                  location: item.location,
                  floorPlan: item.floorPlan,
                })
              : (obj[item.device] = newSerie);
          });

          return Object.values(obj);
        };

        const output = mergeArr(newRows, failuresArr);
        // console.log(output, "output");

        // const conditionRows =
        //   finalRows && finalRows.length !== 0 ? finalRows : newRows;

        const sortedRows = output.sort((a, b) =>
          a.netFailures < b.netFailures ? 1 : -1
        );
        // console.log("🚀 ~ sortedRows", sortedRows);

        const sortedRowsWithId = [];
        sortedRows.map((d, i) =>
          sortedRowsWithId.push({
            id: i + 1,
            ...d,
          })
        );
        // console.log(sortedRowsWithId, "sortedRowsWithId");

        const mainDiv = (
          <div style={{ minHeight: "400px", width: "100%", height: "100%" }}>
            {sortedRowsWithId && sortedRowsWithId.length !== 0 ? (
              <DataGrid
                rows={sortedRowsWithId}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "350px",
                }}
              >
                {" "}
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#3B4143",
                  }}
                >
                  No data to display.
                </p>
              </div>
            )}
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch SNR bad global", error);
      error.clientMessage = "Can't fetch SNR global";
    });
};

// API fetch SNR
function getFloorSNRPerDay(queryParams) {
  //   console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "zonesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/snr/${queryParams.customerId}`,
  });
}

// API fetch NetFailures
async function getFloorNetFailuresPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorNetFailuresPerDay", queryParams);
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "zonesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/netFailures/${queryParams.customerId}`,
  });
}

// Parse result NetFailures
export const netFailuresFloorsToGraphData = ({ result, queryParams }) => {
  // console.log("🚀 ~ queryParams netFailuresFloorsToGraphData", queryParams);
  if (!result) return;

  // console.log("🚀 ~ result 3", result);

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        date: val.date,
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        device: val.zone ? val.zone : "",
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
  });

  // console.log("🚀 ~ data", data);
  const newData = sumDevicesValues(data);
  // console.log("🚀 ~ newData", newData);

  const filteredData = newData.filter((d) => d.device !== "");
  // console.log("🚀 ~ filteredData", filteredData);

  return filteredData;
};

// Parse result SNR
export const badSNRFloorsToGraphData = ({ result, queryParams }) => {
  // console.log("🚀 ~ badSNRFloorsToGraphData queryParams", queryParams);
  if (!result) return;

  // console.log("🚀 ~ result", result);

  const data = [];
  // const mondaysTotal = [];
  // const tuesdaysTotal = [];
  // const wednesdaysTotal = [];
  // const thursdaysTotal = [];
  // const fridaysTotal = [];
  // const saturdaysTotal = [];
  // const sundaysTotal = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        date: val.date,
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        device: val.zone ? val.zone : "",
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    // if (val[0].includes("Mon")) {
    //   mondaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Tue")) {
    //   tuesdaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Wed")) {
    //   wednesdaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Thu")) {
    //   thursdaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Fri")) {
    //   fridaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Sat")) {
    //   saturdaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
    // if (val[0].includes("Sun")) {
    //   sundaysTotal.push({
    //     date: val[0],
    //     location: location.netName,
    //     floorPlan: floor.name,
    //     device: val[3],
    //     count: val[4].toFixed(2) * 1,
    //   });
    // }
  });

  // console.log("🚀 ~ data", data);

  const newData = sumDevicesValues(data);
  // console.log("🚀 ~ newData", newData);

  const finalSeries = newData && newData.filter((s) => s.device !== "");
  // console.log("🚀 ~ finalSeries", finalSeries);

  return finalSeries;
};

const sumDevicesValues = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.device === d.device)) {
      const index = holderOne.findIndex((val) => val.device === d.device);

      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        date: d.date,
        location: d.location,
        floorPlan: d.floorPlan,
        device: d.device,
        count: d.count,
        counter: 1,
      });
    }
  });
  return holderOne.map((val, i) => {
    return {
      date: val.date,
      location: val.location,
      floorPlan: val.floorPlan,
      device: val.device,
      count: (val.count / val.counter).toFixed(2) * 1,
    };
  });
};
