import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "../../_redux/historicalData/historicalDataActions";
import { LinearProgress } from "@material-ui/core";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../_partials/Card";
import { Button } from "@material-ui/core";

export function DailyPeople() {
  const dispatch = useDispatch();

  const { customer, location } = useSelector(
    (state) => ({
      customer: state.profile?.currentCustomer,
      location: state.profile?.currentLocation,
    }),
    shallowEqual
  );

  const { dailyPeopleLoading, avgTimeLoading } = useSelector(
    (state) => ({
      dailyPeopleLoading: state.historicalData.dailyPeopleLoading,
      avgTimeLoading: state.historicalData.avgTimeLoading,
    }),
    shallowEqual
  );

  const {
    weekDailyPeople,
    monthDailyPeople,
    weekAvgTime,
    monthAvgTime,
  } = useSelector(
    (state) => ({
      weekDailyPeople: state.historicalData.weekDailyPeople,
      monthDailyPeople: state.historicalData.monthDailyPeople,
      weekAvgTime: state.historicalData.weekAvgTime,
      monthAvgTime: state.historicalData.monthAvgTime,
    }),
    shallowEqual
  );

  //★━━━━━━━━━━━━★ States ★━━━━━━━━━━━━★\\

  const [oldCustomer, setOldCustomer] = useState(customer);
  const [oldLocation, setOldLocation] = useState(location);
  const [key, setKey] = useState("week");
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    if (!location) {
      return;
    }
    // console.log("weekDailyPeople: ", weekDailyPeople)
    // console.log("monthDailyPeople: ", monthDailyPeople)
    // console.log("weekAvgTime: ", weekAvgTime)
    // console.log("monthAvgTime: ", monthAvgTime)
    if (customer === oldCustomer && location === oldLocation) {
      key === "week" &&
        (weekDailyPeople && weekAvgTime
          ? setGraphData({ dailyPeople: weekDailyPeople, avgTime: weekAvgTime })
          : (!dailyPeopleLoading || !avgTimeLoading) &&
            dispatch(
              actions.fetchWeekDailyPeople({
                customer: customer,
                location: location,
              })
            ) &&
            dispatch(
              actions.fetchWeekAvgTime({
                customer: customer,
                location: location,
              })
            ));

      key === "month" &&
        (monthDailyPeople && monthAvgTime
          ? setGraphData({
              dailyPeople: monthDailyPeople,
              avgTime: monthAvgTime,
            })
          : (!dailyPeopleLoading || !avgTimeLoading) &&
            dispatch(
              actions.fetchMonthDailyPeople({
                customer: customer,
                location: location,
              })
            ) &&
            dispatch(
              actions.fetchMonthAvgTime({
                customer: customer,
                location: location,
              })
            ));
    } else {
      setOldCustomer(customer);
      setOldLocation(location);
      setKey("week");
      setGraphData();
      dispatch(actions.clearDailyPeopleData());
      dispatch(
        actions.fetchWeekDailyPeople({
          customer: customer,
          location: location,
        })
      );
      dispatch(
        actions.fetchWeekAvgTime({
          customer: customer,
          location: location,
        })
      );
    }
  }, [
    key,
    customer,
    location,
    weekDailyPeople,
    monthDailyPeople,
    weekAvgTime,
    monthAvgTime,
  ]);

  return (
    <Card>
      <CardHeader
        title='People per day'
        subtitle={`${key === "week" ? "Weekly" : "Monthly"} count of people`}
        toolbar={
          <>
            <Button
              color={key === "week" ? "primary" : "default"}
              onClick={() => setKey("week")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key === "month" && "#B8BCCA",
              }}
            >
              Week
            </Button>
            <Button
              color={key === "month" ? "primary" : "default"}
              onClick={() => setKey("month")}
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
                color: key === "week" && "#B8BCCA",
              }}
            >
              Month
            </Button>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "350px" }}>
        {(dailyPeopleLoading || avgTimeLoading) && (
          <LinearProgress color='primary' />
        )}
        {graphData && (
          <Chart
            options={getChartOptions(graphData.dailyPeople.labels)}
            series={[
              {
                name: "People",
                type: "column",
                data: graphData.dailyPeople.series,
              },
              {
                name: "Avg time spent",
                type: "line",
                data: graphData.avgTime.series,
              },
            ]}
            type='line'
            height='100%'
          />
        )}
      </CardBody>
    </Card>
  );
}

function getChartOptions(labels) {
  return {
    chart: {
      height: "100%",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#323389", "#F25D24"],
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: (value) => {
        var date = new Date(value * 1000).toISOString().substr(11, 8);
        return date;
      },
    },
    labels: labels,
    xaxis: {
      type: "category",
    },
    yaxis: [
      {
        title: {
          text: "People count",
        },
      },
      {
        opposite: true,
        title: {
          text: "Avg time spent",
        },
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            var date = new Date(value * 1000).toISOString().substr(11, 8);
            return date;
          },
        },
      },
    ],
  };
}
