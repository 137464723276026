import moment from "moment";
import { firestore } from "../../../../../firebase";

const customersRef = firestore.collection("Customers");

function filterSSIDs(queryParams) {
  if (!queryParams.peopleType) return [];
  let SSIDs = [];
  if (queryParams.peopleType.some((val) => val.value === "visitors")) {
    SSIDs.push("");
  }
  if (queryParams.peopleType.some((val) => val.value === "employees")) {
    queryParams.locations.forEach((l) => {
      if (!l.employeeSSID) return;
      SSIDs = SSIDs.concat(l.employeeSSID);
    });
  }
  if (queryParams.peopleType.includes("guests")) {
    queryParams.locations.forEach((l) => {
      if (!l.guestSSID) return;
      SSIDs = SSIDs.concat(l.guestSSID);
    });
  }
  return SSIDs;
}

function fetchAPI(props) {
  const {
    typechart,
    locations,
    SSIDs,
    floors,
    zones,
    startDate,
    endDate,
    url,
  } = props;
  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    typechart: typechart,
    networkId: locations,
    ssid: SSIDs,
    floorPlanId: floors || [],
    zones: zones || [],
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]"),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`,
    requestOptions
  ).catch((err) => {
    console.log("Error: ", err);
  });
}

//★━━━━━━━━━━━━━━━━★ Floor ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getLocationAccuracy(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/wifi/${queryParams.customerId}`,
  });
}

export function getLocationAccuracyPerDay(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/wifi/${queryParams.customerId}`,
  });
}

export function getFloorRSSI(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/rssi/${queryParams.customerId}`,
  });
}

export function getFloorRSSIPerDay(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.floors.map((floor) => floor.floorId);

  return fetchAPI({
    typechart: "floorsDay",
    locations,
    SSIDs: queryParams.SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/rssi/${queryParams.customerId}`,
  });
}

//★━━━━━━━━━━━━━━━━★ Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getZoneLocationAccuracy(queryParams) {
  // console.log("queryParams: ", queryParams)
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  return fetchAPI({
    typechart: "zonesGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/wifi/${queryParams.customerId}`,
  });
}

export function getZoneLocationAccuracyPerDay(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  return fetchAPI({
    typechart: "zonesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/wifi/${queryParams.customerId}`,
  });
}

export function getZoneRSSI(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  return fetchAPI({
    typechart: "zonesGlobal",
    locations,
    SSIDs: queryParams.SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/rssi/${queryParams.customerId}`,
  });
}

export function getZoneRSSIPerDay(queryParams) {
  if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const zones = queryParams.zones.map((zone) => zone.name);

  return fetchAPI({
    typechart: "zonesDay",
    locations,
    SSIDs: queryParams.SSIDs,
    zones,
    startDate,
    endDate: queryParams.date,
    url: `/reporting/rssi/${queryParams.customerId}`,
  });
}

export function getAllLocationsMerakiDevices(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null);
  }

  return Promise.all(
    queryParams?.locations.map((location) => {
      return customersRef
        .doc(queryParams.customerId)
        .collection(location.netId)
        .doc("merakiDevices")
        .get();
    })
  );
}
