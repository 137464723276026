/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls"
import * as actions from "../../_redux/booking/bookingActions"
import { useUIContext } from "../UIContext"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import Toolbar from "./components/Toolbar"
import moment from 'moment'
import Button from '@material-ui/core/Button'

const localizer = momentLocalizer(moment)

export function CalendarModal({ id, show, onHide }) {
  const customer = useSelector((state) => state.auth.claims?.customerId)
  const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
  const superAdmin = useSelector((state) => state.auth.claims.isSuper)

  const [bookings, setBooking] = useState([
    {
      id: 0,
      title: 'Mónica Martins',
      start: new Date(2021, 0, 6, 7, 0, 0),
      end: new Date(2021, 0, 6, 12, 30, 0),
      status: "free"
    },
    {
      id: 0,
      title: 'Manuel Fernandes',
      start: new Date(2021, 0, 6, 13, 30, 0),
      end: new Date(2021, 0, 6, 18, 0, 0),
      status: "free"
    },
    {
      id: 0,
      title: 'João Sousa',
      start: new Date(2021, 0, 7, 9, 0, 0),
      end: new Date(2021, 0, 7, 16, 0, 0),
      status: "free"
    },
  ])
  const [date, setDate] = useState(new Date())
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [dates, setDates] = useState()

  // Products Redux state
  const dispatch = useDispatch()

  const resources = useSelector((state) => state.booking?.resources)
  const { isLoading, resource } = useSelector(
    (state) => ({
      isLoading: state.assignResources.actionsLoading,
      resource: state.booking?.resources?.find((e) => e.id === id) || "",
    }),
    shallowEqual
  )

  // if !id we should close modal
  useEffect(() => {
    !id && onHide()
  }, [id, resources])

  const handleSelect = (e) => {
    //console.log(e)
    setDates({ start: e.start, end: e.end })
    setShowConfirmation(true)
  }

  const saveBook = () => {
    //console.log(dates)
    setShowConfirmation(false)
  }

  const confirmationPopup = () => <Modal
    show={showConfirmation}
    onHide={() => setShowConfirmation(false)}
    backdrop="static"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {`Book ${resource.name}`}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{`Are you sure you want to book ${resource.name} 
      from ${moment(dates?.start).format("hh:mm A")} 
      to ${moment(dates?.end).format("hh:mm A")}?`}</p>
      <div className="d-flex justify-content-end">
        <Button
          className="mr-2"
          variant="contained"
          color="secondary"
          onClick={saveBook}>
          Confirm </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => setShowConfirmation(false)}>
          Cancel </Button>
      </div>
    </Modal.Body>
  </Modal>


  return <>
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title>
          {`${resource.name} Calendar (Click and drag to select)`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          style={{ height: "500px" }}
          localizer={localizer}
          date={date}
          onNavigate={date => {
            setDate(date)
          }}
          defaultView="week"
          events={bookings}
          selectable
          onSelectSlot={e => handleSelect(e)}
          components={{
            toolbar: Toolbar,
          }}
          formats={{
            eventTimeRangeFormat: () => null,
          }}
          views={{ month: true, week: true, day: true }}
          eventPropGetter={
            (event, start, end, isSelected) => {
              let newStyle = {
                backgroundColor: "lightcoral",
                color: 'black',
                borderRadius: "0px",
                border: "none"
              }

              /*if (event.status == "free") {
                newStyle.backgroundColor = "lightgreen"
              } else if (event.status == "booked") {
                newStyle.backgroundColor = "lightcoral"
              }*/

              return {
                className: "",
                style: newStyle
              }
            }
          }
        />
      </Modal.Body>
    </Modal>
    {confirmationPopup()}
  </>
}
