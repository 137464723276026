import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
   listLoading: false,
   actionsLoading: false,
   users: [],
   lastError: null,
}
export const callTypes = {
   list: "list",
   action: "action",
}

export const contactTracingSlice = createSlice({
   name: "contactTracing",
   initialState: initialProductsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false
         } else {
            state.actionsLoading = false
         }
      },
      startCall: (state, action) => {
         state.error = null
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true
         } else {
            state.actionsLoading = true
         }
      },
      usersFetched: (state, action) => {
         state.error = null
         state.actionsLoading = false
         state.users = action.payload
      },
      contactTracingFetched: (state, action) => {
         state.error = null
         state.listLoading = false
         state.contactTrace = action.payload
      },
   },
})
