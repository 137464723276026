import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  page: null,
  group: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
  floorPeopleDistribution: "floorPeopleDistribution",
  floorOccupancy: "floorOccupancy",
  floorTotalOccupancy: "floorTotalOccupancy",
  zoneOccupancy: "zoneOccupancy",
  zoneAverageOccupancy: "zoneAverageOccupancy",
  zonePeopleDistribution: "zonePeopleDistribution",
  floorLocationAccuracy: "floorLocationAccuracy",
  floorLocationAccuracyPerDay: "floorLocationAccuracyPerDay",
  floorRSSI: "floorRSSI",
  floorRSSIPerDay: "floorRSSIPerDay",
  // floorRSSIPerDayBadGlobal: "floorRSSIPerDayBadGlobal",
  zoneLocationAccuracy: "zoneLocationAccuracy",
  zoneLocationAccuracyPerDay: "zoneLocationAccuracyPerDay",
  zonesRSSI: "zonesRSSI",
  zonesRSSIPerDay: "zonesRSSIPerDay",
  zoneOccupancyForChord: "zoneOccupancyForChord",
  zoneOccupancyForSankeyCircular: "zoneOccupancyForSankeyCircular",
  zoneOccupancyForSankey: "zoneOccupancyForSankey",
  floorSensorsDistribution: "floorSensorsDistribution",
};

export const reportingSlice = createSlice({
  name: "reporting",
  initialState: initialProductsState,
  reducers: {
    //!CATCH ERROR STOP LOADING TO
    //TODO ABOVE
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      switch (action.payload.callType) {
        case callTypes.list:
          state.listLoading = true;
          break;
        case callTypes.action:
          state.actionsLoading = true;
          break;

        // People Floor \\
        case callTypes.floorPeopleDistribution:
          state.floorPeopleDistributionLoading = true;
          break;
        case callTypes.floorOccupancy:
          state.floorOccupancyLoading = true;
          break;
        case callTypes.floorAverageOccupancy:
          state.floorAverageOccupancyLoading = true;
          break;
        // People Zone \\
        case callTypes.zoneOccupancy:
          state.zoneOccupancyLoading = true;
          break;
        case callTypes.zoneAverageOccupancy:
          state.zoneAverageOccupancyLoading = true;
          break;
        case callTypes.zonePeopleDistribution:
          state.zonePeopleDistributionLoading = true;
          break;
        case callTypes.zoneAvgTimeSpent:
          state.zoneAvgTimeSpentLoading = true;
          break;
        case callTypes.zoneOccupancyForChord:
          state.zoneOccupancyFetchedForChordLoading = true;
          break;
        case callTypes.zoneOccupancyForSankeyCircular:
          state.zoneOccupancyFetchedForSankeyCircularLoading = true;
          break;
        case callTypes.zoneOccupancyForSankey:
          state.zoneOccupancyFetchedForSankeyLoading = true;
          break;
        // Wifi Floor \\
        case callTypes.floorLocationAccuracy:
          state.floorLocationAccuracyLoading = true;
          break;
        case callTypes.floorLocationAccuracyPerDay:
          state.floorLocationAccuracyPerDayLoading = true;
          break;
        case callTypes.floorRSSI:
          state.floorRSSILoading = true;
          break;
        case callTypes.floorRSSIPerDay:
          state.floorRSSIPerDayLoading = true;
          break;
        // case callTypes.floorRSSIPerDayBadGlobal:
        //   state.floorRSSIPerDayLoadingBadGlobal = true;
        //   break;
        // Wifi Zone \\
        case callTypes.zoneLocationAccuracy:
          state.zoneLocationAccuracyLoading = true;
          break;
        case callTypes.zoneLocationAccuracyPerDay:
          state.zoneLocationAccuracyPerDayLoading = true;
          break;
        case callTypes.zonesRSSI:
          state.zonesRSSILoading = true;
          break;
        case callTypes.zonesRSSIPerDay:
          state.zonesRSSIPerDayLoading = true;
          break;
        // Sensors Floor \\
        case callTypes.floorSensorsDistribution:
          state.floorSensorsDistributionLoading = true;
          break;
        default:
          break;
      }
    },
    // People Floor \\
    floorPeopleDistributionFetched: (state, action) => {
      state.floorPeopleDistributionLoading = false;
      state.floorPeopleDistribution = action.payload;
    },
    floorOccupancyFetched: (state, action) => {
      state.floorOccupancyLoading = false;
      state.floorOccupancy = action.payload;
    },
    floorAverageOccupancyFetched: (state, action) => {
      state.floorAverageOccupancyLoading = false;
      state.floorAverageOccupancy = action.payload;
    },
    // People Zone \\
    zoneOccupancyFetched: (state, action) => {
      state.zoneOccupancyLoading = false;
      state.zoneOccupancy = action.payload;
    },
    zoneAverageOccupancyFetched: (state, action) => {
      state.zoneAverageOccupancyLoading = false;
      state.zoneAverageOccupancy = action.payload;
    },
    zonePeopleDistributionFetched: (state, action) => {
      state.zonePeopleDistributionLoading = false;
      state.zonePeopleDistribution = action.payload;
    },
    zoneAvgTimeSpentFetched: (state, action) => {
      state.zoneAvgTimeSpentLoading = false;
      state.zoneAvgTimeSpent = action.payload;
    },
    zoneOccupancyFetchedForChord: (state, action) => {
      state.zoneOccupancyFetchedForChordLoading = false;
      state.zoneOccupancyForChord = action.payload;
    },
    zoneOccupancyFetchedForSankeyCircular: (state, action) => {
      state.zoneOccupancyFetchedForSankeyCircularLoading = false;
      state.zoneOccupancyForSankeyCircular = action.payload;
    },
    zoneOccupancyFetchedForSankey: (state, action) => {
      state.zoneOccupancyFetchedForSankeyLoading = false;
      state.zoneOccupancyForSankey = action.payload;
    },
    // Wifi Floor \\
    floorLocationAccuracyFetched: (state, action) => {
      state.floorLocationAccuracyLoading = false;
      state.floorLocationAccuracy = action.payload;
    },
    floorLocationAccuracyPerDayFetched: (state, action) => {
      state.floorLocationAccuracyPerDayLoading = false;
      state.floorLocationAccuracyPerDay = action.payload;
    },
    floorRSSIFetched: (state, action) => {
      state.floorRSSILoading = false;
      state.floorRSSI = action.payload;
    },
    floorRSSIPerDayFetched: (state, action) => {
      state.floorRSSIPerDayLoading = false;
      state.floorRSSIPerDay = action.payload;
    },
    // floorRSSIPerDayBadGlobalFetched: (state, action) => {
    //   state.floorRSSIPerDayLoadingBadGlobal = false;
    //   state.floorRSSIPerDayBadGlobal = action.payload;
    // },
    // Wifi Zone \\
    zoneLocationAccuracyFetched: (state, action) => {
      state.zoneLocationAccuracyLoading = false;
      state.zoneLocationAccuracy = action.payload;
    },
    zoneLocationAccuracyPerDayFetched: (state, action) => {
      state.zoneLocationAccuracyPerDayLoading = false;
      state.zoneLocationAccuracyPerDay = action.payload;
    },
    zonesRSSIFetched: (state, action) => {
      state.zonesRSSILoading = false;
      state.zonesRSSI = action.payload;
    },
    zonesRSSIPerDayFetched: (state, action) => {
      state.zonesRSSIPerDayLoading = false;
      state.zonesRSSIPerDay = action.payload;
    },
    allLocationsMerakiDevicesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allLocationsMerakiDevices = action.payload;
    },
    // Sensors Floor \\
    floorSensorsDistributionFetched: (state, action) => {
      state.floorSensorsDistributionLoading = false;
      state.floorSensorsDistribution = action.payload;
    },
  },
});
