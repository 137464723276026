import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";
import { Bold } from "../../../../_partials/typography/Bold";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  TextField,
  makeStyles,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as actionsPDF from "../../../_redux/reporting/reportingActions";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { DatePicker } from "../../../../_partials/inputs/DatePicker";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import { Select } from "../../../../_partials/inputs/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  textFieldStyles: {
    width: "100%",
    borderRadius: "4px",
    "&&&": {
      padding: "0px",
    },
  },
  datePicker: {
    padding: "0.5rem",
  },
  container: {
    // display: "flex",
    // flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    // height: "3.3rem",
    borderRadius: "4px",
  },
  menu: {
    width: "100%",
    maxHeight: "25rem",
  },
  arrowDownIcon: {
    display: "none",
  },
}));

const PDFModalWifi = ({
  showPDFModal,
  setShowPDFModal,
  user,
  pdfFormData,
  setPdfFormData,
  setShowPDFPreviewModal,
  group,
  selectedFloors,
  selectedZones,
  selectedSSIDs,
  date,
  selectedTimeframe,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    selectedCustomer,
    selectedLocation,
    selectedLevel,
    // floorPlanData,
    // roomTypes,
    // user,
    // pointsOfInterestData,
    // pointOfInterestToUpdateData,
  } = useSelector(
    (state) => ({
      selectedCustomer: state.profile?.currentCustomer?.customerId,
      selectedLocation: state.profile?.currentLocation?.netId,
      selectedLevel: state.profile?.currentLevel,
      // floorPlanData: state.basePage?.floorPlans,
      // roomTypes: state.profile?.currentCustomer?.layerTypes,
      // user: state.auth.user,
      // pointsOfInterestData: state.designStudio?.pointsOfInterest,
      // pointOfInterestToUpdateData: state.designStudio?.pointOfInterestToUpdate,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log(showPDFModal, "showPDFModal");
    // console.log(user, "user");
  }, [showPDFModal, user]);

  // const fakeUsers = [
  //   { title: "Rui Fernandes", email: "ruifernandes@u-factor.io" },
  //   { title: "Diogo Magalhães", email: "diogo@u-factor.io" },
  //   { title: "Miguel Tomé", email: "miguel@u-factor.io" },
  // ];

  const hoursOfTheDay = [
    { time: "8:00 AM", value: "8:00:00" },
    { time: "9:00 AM", value: "9:00:00" },
    { time: "10:00 AM", value: "10:00:00" },
    { time: "11:00 AM", value: "11:00:00" },
    { time: "12:00 PM", value: "12:00:00" },
    { time: "13:00 PM", value: "13:00:00" },
    { time: "14:00 PM", value: "14:00:00" },
    { time: "15:00 PM", value: "15:00:00" },
    { time: "16:00 PM", value: "16:00:00" },
    { time: "17:00 PM", value: "17:00:00" },
    { time: "18:00 PM", value: "18:00:00" },
    { time: "19:00 PM", value: "19:00:00" },
    { time: "20:00 PM", value: "20:00:00" },
    { time: "21:00 PM", value: "21:00:00" },
    { time: "22:00 PM", value: "22:00:00" },
    { time: "23:00 PM", value: "23:00:00" },
    { time: "00:00 AM", value: "00:00:00" },
    { time: "1:00 AM", value: "1:00:00" },
    { time: "2:00 AM", value: "2:00:00" },
    { time: "3:00 AM", value: "3:00:00" },
    { time: "4:00 AM", value: "4:00:00" },
    { time: "5:00 AM", value: "5:00:00" },
    { time: "6:00 AM", value: "6:00:00" },
    { time: "7:00 AM", value: "7:00:00" },
  ];

  const repeatFrame = [
    { time: "Daily", value: "daily" },
    { time: "Weekly", value: "weekly" },
    { time: "Monthly", value: "monthly" },
    // { time: "Quarterly", value: "quarterly" },
    // { time: "Working days (Monday to Friday)", value: "workingDays" },
  ];

  const disableCondition = true ? pdfFormData.to === "" : false;

  return (
    <>
      {" "}
      <Modal
        show={showPDFModal}
        onHide={() => setShowPDFModal(false)}
        contentClassName='bg-transparent'
        backdrop='static'
      >
        <Modal.Body
          style={{
            padding: "0",
            backgroundColor: "#ECF1F4",
            borderRadius: "10px",
            overflow: "hidden",
            // width: "55rem",
          }}
        >
          <div className='pl-7 pr-7 pb-7 pt-2'>
            {/* ━━★  TITLE  ★━━ */}
            <div className='d-flex align-items-center justify-content-between'>
              <div style={{ marginTop: "1.7rem" }}>
                <Bold>Email delivery</Bold>
                <p className='mt-2'>Schedule email delivery for wifi report.</p>
              </div>
              <div style={{ marginTop: "-1.4rem" }}>
                <IconButton
                  onClick={() => {
                    setShowPDFModal(false);
                  }}
                  style={{ left: "12px" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            {/* ━━★  BODY  ★━━ */}
            <div className='m-4' style={{ minHeight: "300px" }}>
              <div
                className='d-flex bd-highlight mb-5'
                // style={{ border: "1px solid red" }}
              >
                <div
                  className='p-2 flex-shrink-1 bd-highlight'
                  style={{
                    // border: "1px solid green",
                    width: "8.5rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ fontSize: "12px", margin: "0" }}>From</h6>
                </div>
                <div
                  className='p-2 w-100 bd-highlight'
                  style={{
                    // border: "1px solid green",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      margin: "0",
                    }}
                  >
                    <b>{user && user.username}</b>
                  </h6>
                </div>
              </div>

              <div
                className='d-flex bd-highlight mb-5'
                style={{
                  // border: "1px solid red",
                  alignItems: "center",
                }}
              >
                <div
                  className='p-2 flex-shrink-1 bd-highlight'
                  style={{
                    // border: "1px solid green",
                    width: "8.5rem",
                  }}
                >
                  <h6 style={{ fontSize: "12px", margin: "0" }}>To</h6>
                </div>
                <div
                  className='p-2 w-100 bd-highlight'
                  // style={{ border: "1px solid green" }}
                >
                  <TextField
                    id='filled-basic'
                    variant='filled'
                    hiddenLabel
                    fullWidth
                    // label='users'
                    placeholder='Add recipient email'
                    size='small'
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        // Override input classes
                        root: `${classes.textFieldStyles}`,
                      },
                    }}
                    required
                    value={pdfFormData.to}
                    onChange={(e) =>
                      setPdfFormData({
                        ...pdfFormData,
                        to: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div
                className='d-flex bd-highlight mb-5'
                style={{
                  // border: "1px solid red",

                  alignItems: "center",
                }}
              >
                <div
                  className='p-2 flex-shrink-1 bd-highlight'
                  style={{
                    //  border: "1px solid green",
                    width: "8.5rem",
                  }}
                >
                  <h6 style={{ fontSize: "12px", margin: "0" }}>Start time</h6>
                </div>
                <div
                  className='p-2 w-100 bd-highlight'
                  // style={{ border: "1px solid green" }}
                >
                  <div className='row'>
                    <div
                      className='col-sm-6'
                      // style={{ border: "1px solid blue" }}
                    >
                      {" "}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disablePast // ← disable past dates
                          disableToolbar
                          size='small'
                          variant='inline'
                          format='MM/dd/yyyy'
                          id='date-picker-inline'
                          // label='Date picker inline'
                          value={pdfFormData.startDate}
                          onChange={(e) =>
                            setPdfFormData({
                              ...pdfFormData,
                              startDate: e,
                            })
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          style={{
                            backgroundColor: "#CED2D5",
                            borderRadius: "4px",
                            width: "100%",
                            margin: "0.5rem 0px 0.5rem 0px",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              // Override input classes
                              root: `${classes.datePicker}`,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div
                      className='col-sm-6'
                      // style={{ border: "1px solid purple" }}
                    >
                      <form
                        className={classes.container}
                        noValidate
                        autoComplete='off'
                      >
                        <TextField
                          hiddenLabel
                          fullWidth
                          size='small'
                          id='outlined-select-gender'
                          select
                          variant='filled'
                          className={classes.textField}
                          // InputLabelProps={{ shrink: false }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              margin: "0.5rem 0px 0.5rem 0px",
                              height: "auto",
                            },
                            classes: {
                              // Override input classes
                              root: `${classes.textField}`,
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          required
                          // label={"8:00 AM"}
                          // placeholder='8:00 AM'
                          value={pdfFormData.startTime}
                          onChange={(e) =>
                            setPdfFormData({
                              ...pdfFormData,
                              startTime: e.target.value,
                            })
                          }
                        >
                          {hoursOfTheDay.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.time}
                            </MenuItem>
                          ))}
                        </TextField>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='d-flex bd-highlight mb-5'
                style={{
                  // border: "1px solid red",
                  alignItems: "center",
                }}
              >
                <div
                  className='p-2 flex-shrink-1 bd-highlight'
                  style={{
                    // border: "1px solid green",
                    width: "8.5rem",
                  }}
                >
                  <h6 style={{ fontSize: "12px", margin: "0" }}>Repeat</h6>
                </div>
                <div
                  className='p-2 w-100 bd-highlight'
                  // style={{ border: "1px solid green" }}
                >
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete='off'
                  >
                    <TextField
                      hiddenLabel
                      fullWidth
                      size='small'
                      id='outlined-select-gender'
                      select
                      variant='filled'
                      className={classes.textField}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          margin: "0.5rem 0px 0.5rem 0px",
                        },
                        classes: {
                          // Override input classes
                          root: `${classes.textField}`,
                        },
                      }}
                      SelectProps={{
                        // ↓ remove select arrow down
                        IconComponent: () => null,
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      value={pdfFormData.repeat}
                      onChange={(e) =>
                        setPdfFormData({
                          ...pdfFormData,
                          repeat: e.target.value,
                        })
                      }
                    >
                      {repeatFrame.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  </form>
                </div>
              </div>
            </div>

            {/* ━━★  FOOTER  ★━━ */}
            <div className='d-flex mt-4'>
              <Button
                fullWidth
                color='default'
                variant='contained'
                onClick={() => {
                  setShowPDFModal(false);
                  // ↓ Reset form
                  setPdfFormData({
                    to: user ? user.email : "",
                    startDate: new Date(),
                    startTime: "8:00:00",
                    repeat: "weekly",
                  });
                  // ↓ Open preview modal
                  setShowPDFPreviewModal(true);
                }}
                className='mr-2'
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                className='ml-2'
                disabled={disableCondition}
                onClick={() => {
                  console.log(pdfFormData, "pdrFormData Complete");
                  // dispatch(
                  //   actionsPDF.createScheduledReportWifi({
                  //     data: pdfFormData,
                  //     netId: selectedLocation,
                  //     customerId: selectedCustomer,
                  //     floorPlanId: selectedLevel.floorId,
                  //     user: user,
                  //     group: group,
                  //     selectedFloors: selectedFloors,
                  //     selectedZones: group === "zone" ? selectedZones : "",
                  //     selectedSSIDs: selectedSSIDs,
                  //     reportDate: date,
                  //     selectedTimeframe: selectedTimeframe,
                  //   })
                  // ).then((res) => {
                  // console.log(res, "Schedule Action Dispatched");
                  setShowPDFModal(false);
                  // });
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PDFModalWifi;
