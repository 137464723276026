/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { useHistory } from "react-router-dom";

export function QuickUserToggler() {
  const { user } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id='quick-user-tooltip'>User profile</Tooltip>}
        >
          <div
            //id="kt_quick_user_toggle"
            onClick={() => history.push("/user-profile")}
            style={{
              cursor: "pointer",
              borderRadius: "25px",
              height: "36px",
              width: "36px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${
                user?.pic ? user.pic : "/media/users/default.jpg"
              }), url(/media/users/default.jpg)`,
            }}
          />
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
