import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import { HistogramFloorsChart } from "../../../wifiSnapshot/Floor/HistogramFloorsChart";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function HistogramFloorsCustom({
  queryParams,
  showChart,
  setShowChart,
  hide,
}) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(queryParams, "UIProps.queryParams BADFLOORS");
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await HistogramFloorsChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Histogram'
        subtitle='Floors'
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChart({
                  ...showChart,
                  HistogramFloorsCustom: false,
                })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "375px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
