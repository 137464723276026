import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Group } from "@vx/group";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";
import { HierarchyDefaultNode as DefaultNode } from "@vx/hierarchy";
import { linkHorizontal } from "d3-shape";
import * as d3 from "d3";
import thickLine from "./thick-line";

Sankey.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.func,
};

export default function Sankey({
  width,
  height,
  top,
  left,
  className,
  data,
  size,

  nodeId,
  nodeAlign,
  nodeWidth,
  nodePadding,
  nodePaddingRatio,
  extent,
  iterations,
  circularLinkGap,

  children,
  nodeComponent = DefaultNode,
  ...restProps
}) {
  const sankey = d3Sankey();
  if (size) sankey.size(size);
  if (nodeId) sankey.nodeId(nodeId);
  if (nodeAlign) sankey.nodeAlign(nodeAlign);
  if (nodeWidth) sankey.nodeWidth(nodeWidth);
  if (nodePadding) sankey.nodePadding(nodePadding);
  if (extent) sankey.extent(extent);
  if (iterations) sankey.iterations(iterations);
  if (circularLinkGap) sankey.circularLinkGap(circularLinkGap);

  // useEffect(() => {
  //   console.log(data, "data no Sankey");
  // }, [data]);

  const sankeyData = sankey(data);

  useEffect(() => {
    if (!data) return;

    const svg = d3.selectAll("#svgSankeyIdNotCircular");
    // console.log("🚀 ~ svg", svg);

    const filteredLinks = data.links.filter((l) => l.target.name !== "Dropout");
    // console.log("🚀 ~ filteredLinks", filteredLinks);
    const filteredNodes = data.nodes.filter((l) => l.name !== "Dropout");
    // console.log("🚀 ~ filteredNodes", filteredNodes);

    // const node = svg.selectAll("g").data(filteredNodes); // com o "g" consigo mover os nodes mas n aparece o certo
    const node = svg.selectAll("rect").data(filteredNodes); // com o "rect" aparece o certo mas só consigo mover o primeiro node
    // console.log("🚀 ~ node", node);

    //
    // node.attr("cursor", "move").call(
    //   d3
    //     .drag()
    //     .subject(function(d) {
    //       return d;
    //     })
    //     .on("start", function() {
    //       this.parentNode.appendChild(this);
    //     })
    //     .on("drag", dragMove)
    // );

    const path = linkHorizontal()
      .source((d) => {
        // console.log(d, "d CORRECTO");
        return [d.source.x1, d.y0];
      })
      .target((d) => [d.target.x0, d.y1]);

    // const path = linkHorizontal() // <- path original
    //   .source((d) => {
    //     // console.log(d, "d CORRECTO");
    //     return [d.source.x1, d.y0];
    //   })
    //   .target((d) => [d.target.x0, d.y1]);

    function dragMove(event, d) {
      // console.log("🚀 ~ EVENT", event);
      // console.log("🚀 ~ d2 -", d);

      //TODO este funciona 1 mas limita os nodes até ao 0
      // var dx = Math.round((d.x = Math.max(0, Math.min(width, event.x))));
      // var dy = Math.round((d.y = Math.max(0, Math.min(height, event.y))));

      //todo: neste FICA PERFEITO, tirei o Math.max porque assim movemos os nodes qualquer ponto sem limites.
      var dx = Math.round((d.x = Math.min(width, event.x)));
      var dy = Math.round((d.y = Math.min(height, event.y)));

      d3.select(this).attr("transform", "translate(" + [dx, dy] + ")");

      console.log("🚀 ~ sankeyData", sankeyData);

      sankey.update(sankeyData); // <- versão que funciona
      // sank.update(graph);

      const newData = sankey.update(sankeyData);
      console.log("🚀 ~ newData", newData);

      // console.log([dx, dy], "[dx, dy]");
      //TODO: aqui tenho q colocar as coords correctas do path
      //!! o que acho que acontece é que a data com os links é sempre a mesma portanto as coords dos links não se alteram consoante os nodes

      //TODO: ↓ este codigo estava a mexer os links
      // const dWidth = d.x1 - d.x0;
      // const dHeidth = d.y1 - d.y0;
      // const toX = Math.min(width, event.x);
      // const toY = Math.min(height, event.y);

      // d.x0 = toX;
      // d.x1 = toX + Math.min(width, event.x);
      // d.y0 = toY;
      // d.y1 = toY + Math.min(height, event.y);

      //TODO:

      const targetLinks = d.targetLinks.map(
        (t) => t.source.name + "_" + t.target.name
      );
      // console.log(targetLinks, "targetLinks");

      const sourceLinks = d.sourceLinks.map(
        (t) => t.source.name + "_" + t.target.name
      );

      // console.log(sourceLinks, "sourceLinks");

      const pathIds = [];

      targetLinks.forEach((t) => {
        if (!t.includes("Dropout")) {
          pathIds.push(t);
        }
      });

      sourceLinks.forEach((s) => {
        if (!s.includes("Dropout")) {
          pathIds.push(s);
        }
      });

      // console.log("🚀 ~ pathIds", pathIds);

      console.log(d, "d");

      pathIds.forEach((l) => {
        // console.log(`#${l}`);

        const element = document.getElementById(l);
        // console.log(element, "element");
        // d3.select(element).attr("d", path(d));
      });

      //todo: este em baixo mexe os links uma vez pq está a selecionar todos os da class pathSvg
      // d.targetLinks.forEach((l, i) => {
      //   const link = svg.select("pathSvg");
      //   link.attr("d", path(l));
      // });

      // for (const element of document.getElementsByClassName("pathSvg")) {
      //   // console.log(element, "element");
      //   d3.select(element).attr("d", path(d));
      // }

      // filteredLinks.map((link) => {
      //   // console.log("🚀 ~ link", link);
      //   const el = document.getElementsByClassName("pathSvg");
      //   d3.select(el).attr("d", thickLine(link));
      // });
    }
  }, [data, height, sankey, sankeyData, width]);

  if (!!children) {
    return (
      <Group top={top} left={left} className={cx("vx-sankey", className)}>
        {children({ data: sankeyData })}
      </Group>
    );
  }
}
