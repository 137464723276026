import moment from "moment";
import {
  getAvg,
  sumFloorsAndAverage,
  arrayWithoutDuplicates,
} from "../../../Reporting/UIHelpers";

export const handleSensorDistributionResult = ({ result, queryParams }) => {
  if (!result) return;
  //   console.log(result, "result 2");

  const data = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find((floor) => floor.floorId === val[2]);
    const location = queryParams.locations.find((loc) => loc.netId === val[1]);

    if (val && val[0]) {
      data.push({
        date: val[0],
        location: location.netName,
        floorPlan: floor.name,
        model: val[3],
        temperature: val[4],
        humidity: val[5],
      });
    }
  });

  //   console.log(data, "data");

  const sortedData = data.sort(function(a, b) {
    return new Date(a.date) - new Date(b.date);
  });
  console.log("🚀 ~ sortedData", sortedData);

  // MT10 - has temperature and humidity
  // MT11 - has only temperature

  const temp = [];
  const humid = [];

  sortedData.forEach((d) => {
    if (d.humidity !== 0) {
      humid.push({ floorPlans: d.floorPlan, data: d.humidity });
    }
    if (d.humidity === 0) {
      temp.push({ floorPlans: d.floorPlan, data: d.temperature });
    }
  });
  // console.log("🚀 ~ temp", temp);
  // console.log("🚀 ~ humid", humid);

  const tempAverage = sumFloorsAndAverage(temp);
  console.log("🚀 ~ tempAverage", tempAverage);

  const humidAverage = sumFloorsAndAverage(humid);
  console.log("🚀 ~ humidAverage", humidAverage);

  const newSeriesFinal = [
    { name: "Temperature", data: [tempAverage[0].value] },
    { name: "Humidity", data: [humidAverage[0].value] },
  ];
  // console.log("🚀 ~ newSeriesFinal", newSeriesFinal);

  const dayDate = arrayWithoutDuplicates(sortedData);
  console.log("🚀 ~ dayDate", dayDate);

  const formattedDate = moment(sortedData[0].date).format("ddd MMM D");
  console.log("🚀 ~ formattedDate", formattedDate);

  return {
    series: newSeriesFinal,
    date: formattedDate,
  };

  //!! FALTA FAZER PARA WEEK E MONTH
};
