import React, { useEffect, useMemo, useState } from "react";
import { useUIContext } from "../../UIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./PDF.css";
import Logo from "../../../../assets/ufind-purple-logo.png";
import { Modal } from "react-bootstrap";
import PDFModalWifi from "./PDFModalWifi";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { Button, CircularProgress } from "@material-ui/core";
import { OverviewGlobalCustom } from "../CustomPDFCharts/WifiAdvancedFloors/OverviewGlobalCustom";
import { SNRFloorsCustom } from "../CustomPDFCharts/WifiAdvancedFloors/SNRFloorsCustom";
import { OverviewCustom } from "../CustomPDFCharts/WifiAdvancedFloors/OverviewCustom";
import { FailuresCountCustom } from "../CustomPDFCharts/WifiAdvancedFloors/FailuresCustom";
import { FailuresPieCustom } from "../CustomPDFCharts/WifiAdvancedFloors/FailuresPieCustom";
import { HistogramFloorsCustom } from "../CustomPDFCharts/WifiAdvancedFloors/HistogramFloorsCustom";
import { RSSIBadFloorsCustom } from "../CustomPDFCharts/WifiAdvancedFloors/RSSIBadFloorsCustom";
import { SNRZonesCustom } from "../CustomPDFCharts/WifiAdvancedZones/SNRZonesCustom";
import { OverviewZonesCustom } from "../CustomPDFCharts/WifiAdvancedZones/OverviewZonesCustom";
import { FailureStepsZonesCustom } from "../CustomPDFCharts/WifiAdvancedZones/FailureStepsZonesCustom";
import { SNREachZoneCustom } from "../CustomPDFCharts/WifiAdvancedZones/SNREachZoneCustom";
import { NetFailuresEachZoneCustom } from "../CustomPDFCharts/WifiAdvancedZones/NetFailuresEachZoneCustom";
import { RSSIBadZonesCustom } from "../CustomPDFCharts/WifiAdvancedZones/RSSIBadZonesCustom";

export default function PDFPreviewModalWifiAdvanced({
  showPDFPreviewModalWifiAdv,
  setShowPDFPreviewModalWifiAdv,
  user,
  pdfFormData,
  setPdfFormData,
  group,
  showPDFModal,
  setShowPDFModal,
  selectedFloors,
  selectedZones,
  selectedSSIDs,
  date,
  selectedTimeframe,
  hide,
  setHide,
}) {
  // const dispatch = useDispatch();

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  const { customer } = useSelector(
    (state) => ({
      customer: state.profile?.currentCustomer,
    }),
    shallowEqual
  );

  // * ____________ States ____________ * \\
  const [showChart, setShowChart] = useState({
    OverviewGlobalCustom: true,
    SNRFloorsCustom: true,
    OverviewCustom: true,
    FailuresCountCustom: true,
    FailuresPieCustom: true,
    HistogramFloorsCustom: true,
    RSSIBadFloorsCustom: true,
  });
  const [showChartZones, setShowChartZones] = useState({
    OverviewGlobalCustom: true,
    SNRZonesCustom: true,
    OverviewZonesCustom: true,
    FailureStepsZonesCustom: true,
    SNREachZoneCustom: true,
    NetFailuresEachZoneCustom: true,
    RSSIBadZonesCustom: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(showChart, "showChart");
    // console.log(showChartZones, "showChartZones");
    // console.log(group, "group");
    // console.log(UIProps.queryParams, "UIProps.queryParams 2");
  }, [group, UIProps, showChart, showChartZones]);

  const paramsWithCustomer = {
    ...UIProps.queryParams,
    customerId: customer.customerId,
  };

  const downloadingPDFData = () => {
    setLoading(true);

    //Faking API call here
    setTimeout(() => {
      setLoading(false);
      // Closes preview Modal
      setShowPDFPreviewModalWifiAdv(false);
    }, 5000);
  };

  const displayingZeroChartsZones =
    !showChartZones.OverviewGlobalCustom &&
    !showChartZones.SNRZonesCustom &&
    !showChartZones.OverviewZonesCustom &&
    !showChartZones.FailureStepsZonesCustom &&
    !showChartZones.SNREachZoneCustom &&
    !showChartZones.NetFailuresEachZoneCustom &&
    !showChartZones.RSSIBadZonesCustom
      ? true
      : false;

  const displayingZeroChartsFloors =
    !showChart.OverviewGlobalCustom &&
    !showChart.SNRFloorsCustom &&
    !showChart.OverviewCustom &&
    !showChart.FailuresCountCustom &&
    !showChart.FailuresPieCustom &&
    !showChart.HistogramFloorsCustom &&
    !showChart.RSSIBadFloorsCustom
      ? true
      : false;

  return (
    <>
      <Modal
        show={showPDFPreviewModalWifiAdv}
        onHide={() => setShowPDFPreviewModalWifiAdv(false)}
        backdrop='static'
        dialogClassName='modal-100w'
        aria-labelledby='example-custom-modal-styling-title'
        size='xl'
        id='modalContent'
      >
        {/* ━━★  BODY  ★━━ */}
        <Modal.Body id='modalBodyPDFWifiAdvanced'>
          <div id='wrapper'>
            <div className='d-flex bd-highlight mb-3'>
              <div className='mr-auto p-2 bd-highlight'>
                <h3 style={{ fontSize: "20px", textDecoration: "underline" }}>
                  Wifi Advanced {group === "floor" ? "Floors" : "Zones"} Report
                </h3>
              </div>
              <div className='p-2 bd-highlight'>
                <img src={Logo} alt='u-find logo' className='ufind-logo' />{" "}
              </div>
            </div>
            <div className='row'>
              {group === "floor" && (
                <>
                  {showChart.OverviewGlobalCustom && (
                    <div
                      className={
                        showChart.SNRFloorsCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                      id='wifiAdvancedDivToPdf1'
                    >
                      <OverviewGlobalCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.SNRFloorsCustom && (
                    <div
                      className={
                        showChart.OverviewGlobalCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                      id='wifiAdvancedDivToPdf2'
                    >
                      <SNRFloorsCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.OverviewCustom && (
                    <div className='col-12' id='wifiAdvancedDivToPdf3'>
                      <OverviewCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.FailuresCountCustom && (
                    <div
                      className={
                        showChart.FailuresPieCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                      id='wifiAdvancedDivToPdf4'
                    >
                      <FailuresCountCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.FailuresPieCustom && (
                    <div
                      className={
                        showChart.FailuresCountCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                      id='wifiAdvancedDivToPdf5'
                    >
                      <FailuresPieCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.HistogramFloorsCustom && (
                    <div className='col-12' id='wifiAdvancedDivToPdf6'>
                      <HistogramFloorsCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.RSSIBadFloorsCustom && (
                    <div className='col-12' id='wifiAdvancedDivToPdf7'>
                      <RSSIBadFloorsCustom
                        queryParams={paramsWithCustomer}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                </>
              )}
              {group === "zone" && (
                <>
                  {showChartZones.OverviewGlobalCustom && (
                    <div
                      className={
                        showChartZones.SNRZonesCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                      id='wifiAdvancedDivToPdf1'
                    >
                      <OverviewGlobalCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}

                  {showChartZones.SNRZonesCustom && (
                    <div
                      className={
                        showChartZones.OverviewGlobalCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                    >
                      <SNRZonesCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf2'
                      />
                    </div>
                  )}
                  {showChartZones.OverviewZonesCustom && (
                    <div className='col-12'>
                      <OverviewZonesCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf3'
                      />
                    </div>
                  )}
                  {showChartZones.FailureStepsZonesCustom && (
                    <div className='col-12'>
                      <FailureStepsZonesCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf4'
                      />
                    </div>
                  )}
                  {showChartZones.SNREachZoneCustom && (
                    <div
                      className={
                        showChartZones.NetFailuresEachZoneCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                    >
                      <SNREachZoneCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf5'
                      />
                    </div>
                  )}
                  {showChartZones.NetFailuresEachZoneCustom && (
                    <div
                      className={
                        showChartZones.SNREachZoneCustom
                          ? "col-lg-6 col-xxl-6"
                          : "col-12"
                      }
                    >
                      <NetFailuresEachZoneCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf6'
                      />
                    </div>
                  )}
                  {showChartZones.RSSIBadZonesCustom && (
                    <div className='col-12'>
                      <RSSIBadZonesCustom
                        queryParams={paramsWithCustomer}
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='wifiAdvancedDivToPdf7'
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id='dlPDFCancelBtnWifiAdvanced'
            disabled={loading}
            color='default'
            variant='contained'
            className='mr-2'
            onClick={() => {
              setShowPDFPreviewModalWifiAdv(false);
              // Reset showing all charts on preview modal
              setShowChart({
                OverviewGlobalCustom: true,
                SNRFloorsCustom: true,
                OverviewCustom: true,
                FailuresCountCustom: true,
                FailuresPieCustom: true,
                HistogramFloorsCustom: true,
                RSSIBadFloorsCustom: true,
              });
              setShowChartZones({
                OverviewGlobalCustom: true,
                SNRZonesCustom: true,
                OverviewZonesCustom: true,
                FailureStepsZonesCustom: true,
                SNREachZoneCustom: true,
                NetFailuresEachZoneCustom: true,
                RSSIBadZonesCustom: true,
              });
            }}
          >
            CANCEL
          </Button>
          {/* Schedule Email delivery */}
          {/* <button
            onClick={() => {
              setShowPDFModal(true);
              setShowPDFPreviewModal(false);
            }}
          >
            EMAIL DELIVERY
          </button> */}
          <Button
            disabled={
              loading || displayingZeroChartsZones || displayingZeroChartsFloors
            }
            color='primary'
            variant='contained'
            className='ml-2'
            onClick={() => {
              // Spinner appears in button
              downloadingPDFData();

              // Hide close Icons
              setHide(true);

              // ↓ Download PDF
              const now = moment().format("YYYY-MM-DD HH:mm");
              const element = document.getElementById(
                "modalBodyPDFWifiAdvanced"
              );

              const cutAfterOptions =
                group === "people"
                  ? ["#wifiAdvancedDivToPdf3"]
                  : ["wifiAdvancedDivToPdf2", "wifiAdvancedDivToPdf3"];

              // console.log("🚀 ~ cutAfterOptions", cutAfterOptions);
              const cutBeforeOptions =
                group === "people" ? null : ["wifiAdvancedDivToPdf3"];

              const options = {
                margin: 0,
                filename: `Wifi_Advanced_Report_${now}`,
                jsPDF: {
                  // unit: "in",
                  // format: "letter",
                  unit: "pt",
                  format: "a4",
                  orientation: "p",
                  // hotfixes: ["px_scaling"],
                  // floatPrecision: "smart",
                  // precision: 15,
                },
                image: {
                  type: "jpeg",
                  quality: 1,
                  // width: 494,
                  // height: 494,
                },
                html2canvas: {
                  scale: 2,
                  scrollX: 0,
                  scrollY: 0,
                  // windowWidth: 1224,
                },
                // html2canvas: { scale: 2, windowWidth: 1100 },
                // makes pages break to another pdf page after chosen elements id.
                pagebreak: {
                  mode: "avoid-all",
                  before: cutBeforeOptions,
                  //   [
                  //     // "#wifiAdvancedDivToPdf2",
                  //     // "#wifiAdvancedDivToPdf3",
                  //     // "#wifiAdvancedDivToPdf3",
                  //     // "#wifiDivToPdf4",
                  //   ],
                  after: cutAfterOptions,
                  //   [
                  //     // "#wifiAdvancedDivToPdf3",
                  //   ],
                },
              };
              // Download PDF
              html2pdf()
                .set(options)
                .from(element)
                .save();

              // Preview PDF on browser
              // html2pdf()
              //   .set(options)
              //   .from(element)
              //   .toPdf()
              //   .get("pdf")
              //   .then(function(pdf) {
              //     window.open(pdf.output("bloburl"), "_blank");
              //   });

              // // Closes preview Modal
              // setShowPDFPreviewModalWifiAdv(false);
              // Reset showing all charts on preview modal
              setShowChart({
                OverviewGlobalCustom: true,
                SNRFloorsCustom: true,
                OverviewCustom: true,
                FailuresCountCustom: true,
                FailuresPieCustom: true,
                HistogramFloorsCustom: true,
                RSSIBadFloorsCustom: true,
              });
              setShowChartZones({
                OverviewGlobalCustom: true,
                SNRZonesCustom: true,
                OverviewZonesCustom: true,
                FailureStepsZonesCustom: true,
                SNREachZoneCustom: true,
                NetFailuresEachZoneCustom: true,
                RSSIBadZonesCustom: true,
              });
            }}
          >
            {loading && (
              <CircularProgress style={{ marginRight: "15px" }} size={15} />
            )}
            {loading && <span>DOWNLOADING...</span>}
            {!loading && <span>DOWNLOAD PDF</span>}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ━━★  PDF MODAL SCHEDULE   ★━━ */}
      {/* <PDFModalWifi
        showPDFModal={showPDFModal}
        setShowPDFModal={setShowPDFModal}
        user={user}
        pdfFormData={pdfFormData}
        setPdfFormData={setPdfFormData}
        setShowPDFPreviewModal={setShowPDFPreviewModal}
        group={group}
        selectedFloors={selectedFloors}
        selectedZones={selectedZones}
        selectedSSIDs={selectedSSIDs}
        date={date}
        selectedTimeframe={selectedTimeframe}
      /> */}
    </>
  );
}
