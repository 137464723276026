import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import moment from "moment"
import { DropdownSelect } from "../DropdownSelect"
import firebase from "firebase"


export function HotBookingDays() {
   const { bookings, user, department, profileLvl } = useSelector(
      (state) => ({
         bookings: state.booking?.bookings,
         user: state.auth?.user,
         department: state.basePage?.department,
         profileLvl: state.auth?.claims?.profileLvl,
      }), shallowEqual)

   //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
   //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
   const [series, setSeries] = useState([])
   const [dataFilter, setDataFilter] = useState({ value: 'me', label: 'Me' })


   useEffect(() => {
      const startDate = moment(firebase.firestore.Timestamp.now().toDate()).startOf("month")
      const endDate = moment(firebase.firestore.Timestamp.now().toDate()).endOf("month")
      const _bookings = bookings &&
         bookings.filter(val =>
            moment(val.end.toDate()).isAfter(startDate) &&
            moment(val.start.toDate()).isBefore(endDate) &&
            (
               dataFilter.value === "me" ?
                  val.created.id === user.id :
                  dataFilter.value === "department" &&
                  (department.employees?.includes(val.created.id) || department.manager === val.created.id)
            )
         ) || []

      let holder = { Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0 }
      _bookings.forEach(val => {
         const weekDay = moment(val.start.toDate()).format("ddd")
         if (Object.keys(holder).includes(weekDay)) {
            holder[weekDay] = holder[weekDay] + 1
         }
      })
      const data = Object.values(holder)

      setSeries([{
         name: 'Bookings',
         data: data
      }])
   }, [bookings, dataFilter, user, department])

   return <Card>
      <CardHeader
         title="Hot booking days"
         subtitle="Most booked days this month"
         toolbar={profileLvl >= 2 && department &&
            <DropdownSelect
               dataFilter={dataFilter}
               setDataFilter={setDataFilter}
               options={[
                  { value: 'me', label: 'Me' },
                  { value: 'department', label: 'Department' }
               ]}
            />
         }
      />
      <CardBody>
         <Chart
            options={getChartOptions()}
            series={series}
            type="bar"
            height={300}
         />
      </CardBody>
   </Card>
}

function getChartOptions() {
   return {
      chart: {
         type: 'bar',
         toolbar: { show: false }
      },
      colors: ['#31D0AA'],
      plotOptions: {
         bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
         },
      },
      dataLabels: {
         enabled: false
      },
      noData: {
         text: "No bookings this month",
         align: 'center',
         verticalAlign: 'middle',
         offsetX: 0,
         offsetY: 0,
         style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
         }
      },
      stroke: {
         show: true,
         width: 2,
         colors: ['transparent']
      },
      xaxis: {
         categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      },
      yaxis: {
         labels: {
            formatter: (val) => val.toFixed(0)
         }
      },
      fill: {
         opacity: 1
      },
      tooltip: {
         enabled: false
      }
   }
}