import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
}

export const callTypes = {
  list: "list",
  action: "action",
  isAverageTimeLoading: "isAverageTimeLoading",
  isHybridWorkBalanceLoading: "isHybridWorkBalanceLoading",
}

export const bookingSlice = createSlice({
  name: "booking",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else if (action.payload.callType === callTypes.isAverageTimeLoading) {
        state.isAverageTimeLoading = false
      } else if (action.payload.callType === callTypes.isHybridWorkBalanceLoading) {
        state.isHybridWorkBalanceLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else if (action.payload.callType === callTypes.isAverageTimeLoading) {
        state.isAverageTimeLoading = true
      } else if (action.payload.callType === callTypes.isHybridWorkBalanceLoading) {
        state.isHybridWorkBalanceLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    resourcesFetched: (state, action) => {
      const { entities } = action.payload
      state.listLoading = false
      state.error = null
      state.resources = entities
    },
    bookingsFetched: (state, action) => {
      const { entities } = action.payload
      state.listLoading = false
      state.error = null;
      state.bookings = entities
    },
    resourceBooked: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.bookings = [...state.bookings, action.payload]
    },
    usersFetched: (state, action) => {
      state.listLoading = false
      state.error = null
      state.users = action.payload
    },
    calendarBookingCreated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.departmentCalendarBookings = action.payload
    },
    bookingEdited: (state, action) => {
       state.error = null
       state.actionsLoading = false
       state.bookings = state.bookings && state.bookings.map((entity) => {
          if (entity.id === action.payload.id) {
             return action.payload
          }
          return entity
       })
    },
    bookingCanceled: (state, action) => {
       state.error = null;
       state.actionsLoading = false;
       state.bookings = state.bookings.filter(
          (el) => el.id !== action.payload
       )
    },
    userAverageTimeAtTheOfficeResultToGraphData: (state, action) => {
      state.error = null
      state.isAverageTimeLoading = false
      state.userAverageTimeAtTheOffice = action.payload
    },
    departmentAverageTimeAtTheOfficeResultToGraphData: (state, action) => {
      state.error = null
      state.isAverageTimeLoading = false
      state.departmentAverageTimeAtTheOffice = action.payload
    },
    usersDocsFetched: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.usersDocs = action.payload
    },
    hybridWorkBalanceFetched: (state, action) => {
      state.error = null
      state.isHybridWorkBalanceLoading = false
      if (action.payload.getDepartmentData) {
        if (action.payload.month) {
          state.monthDepartmentHybridWork = action.payload.data
        } else {
          state.weekDepartmentHybridWork = action.payload.data
        }
      } else {
        if (action.payload.month) {
          state.monthUserHybridWork = action.payload.data
        } else {
          state.weekUserHybridWork = action.payload.data
        }
      }
    },
  },
})