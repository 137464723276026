import React, { useEffect, useState, useRef } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { Select } from "../../../../../_metronic/_partials/controls"
import Button from '@material-ui/core/Button'
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { deleteCustomer } from "../_helpers/ActionsHelpers"

const EditSchema = Yup.object().shape({
   location: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Location is required"),
})

const Step2 = ({ defaultData, setForm, formData, navigation }) => {
   const { previous, next } = navigation
   const { meraki_key, orgId } = formData.merakiDashboard

   const [locationData, setLocationData] = useState([])
   const [loading, setLoading] = useState(false)
   const formRef = useRef()

   useEffect(() => {
      if (meraki_key && orgId) {
         setLoading(true)
         var myHeaders = new Headers()
         myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
         myHeaders.append("Content-Type", "application/json")

         var raw = JSON.stringify({
            "merakiKey": meraki_key,
            "orgId": orgId
         })

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
         }

         fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/getNet`, requestOptions)
            .then(response => response.text())
            .then(result => {
               var jsonResult = JSON.parse(result)
               setLocationData(jsonResult.message)
               setLoading(false)
            }).catch(error => {
               console.log('error', error)
               setLoading(false)
            })
      }
   }, [meraki_key, orgId])

   const handleSubmit = (values) => {
      var finalLocation = locationData.find(l => l.netId === values.location)
      const merakiDashboard = formData.merakiDashboard
      const userService = formData.userService
      const orgName = formData.name
      const admin = formData.admin
      const domain = formData.domain
      const password = formData.password
      formData.customerId && deleteCustomer(formData.customerId)
      setForm({
         target: {
            name: "data",
            value: defaultData.data
         }
      })
      setForm({
         target: {
            name: "data.merakiDashboard",
            value: merakiDashboard
         }
      })
      setForm({
         target: {
            name: "data.userService",
            value: userService
         }
      })
      setForm({
         target: {
            name: "data.name",
            value: orgName
         }
      })
      setForm({
         target: {
            name: "data.admin",
            value: admin
         }
      })
      setForm({
         target: {
            name: "data.domain",
            value: domain
         }
      })
      setForm({
         target: {
            name: "data.password",
            value: password
         }
      })
      setForm({
         target: {
            name: "data.merakiNetworks",
            value: [{
               ...defaultData.data.merakiNetworks[0],
               netId: finalLocation.netId,
               netName: finalLocation.netName,
               timeZone: finalLocation.timeZone,
               customerType: values.customerType
            }]
         }
      })
      setForm({
         target: {
            name: "data.completedStep",
            value: 2
         }
      })
      next()
   }

   return <Card isLoading={loading} margin={false} style={{ height: "100%" }}>
      <CardHeader
         title="Select location"
         toolbar={<>
            <Button
               variant="contained"
               color="default"
               style={{ marginRight: "10px" }}
               size="large"
               disableElevation
               onClick={previous}>
               Previous
            </Button>
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => formRef.current && formRef.current.handleSubmit()}
            >
               Next
            </Button>
         </>}
      />
      <CardBody>
         <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
               location: locationData[0]?.netId,
               customerType: "office"
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => {
               handleSubmit(values)
            }}
         >
            {() => (
               <Form className="form form-label-right">
                  <div className="form-group row mb-0 mt-4">
                     <div className="col-12 col-sm mb-4 mb-sm-0">
                        <Select
                           name="location"
                           label="Select a location"
                        >
                           {locationData.map((values, i) => {
                              return <option key={i} value={values.netId}>{values.netName}</option>
                           })}
                        </Select>
                     </div>
                     <div className="col-12 col-sm">
                        <Select
                           name="customerType"
                           label="Select location type"
                        >
                           <option value="office">Office</option>
                           <option value="shop">Shop</option>
                           <option value="retail">Retail</option>
                        </Select>
                     </div>
                  </div>
               </Form>
            )}
         </Formik >
      </CardBody>
   </Card>
}

export default Step2