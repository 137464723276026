import moment from "moment";
import { getAvg } from "../../../Reporting/UIHelpers";

function sumOfSameHours(array) {
  let holderOne = [];
  array.forEach((d) => {
    if (
      holderOne.some((val) => moment(val.date).hour() === moment(d.date).hour())
    ) {
      const index = holderOne.findIndex(
        (val) => moment(val.date).hour() === moment(d.date).hour()
      );
      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        date: holderOne[index].date,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        name: "Total",
        count: d.count,
        date: d.date,
        counter: 1,
      });
    }
  });
  return holderOne.map((val) => {
    return Math.round(val.count / val.counter);
  });
}

const sumOfSameFloorsZones = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.name === d.name)) {
      const index = holderOne.findIndex((val) => val.name === d.name);
      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        name: d.name,
        count: d.count,
        counter: 1,
      });
    }
  });

  return holderOne.map((val, i) => {
    return { x: val.name, y: Math.round(val.count / val.counter) };
  });
};

export const handlePeopleDistributionResult = ({ result, queryParams }) => {
  if (!result) return;

  let allWeekDataByWeek = [];
  let workDaysDataByWeek = [];
  let weekendsDataByWeek = [];

  let allWeekSeriesByWeekAndMonth = [];
  let allWeekLabelsByWeekAndMonth = [];
  let workDaysSeriesByWeekAndMonth = [];
  let workDaysLabelsByWeekAndMonth = [];
  let weekendsSeriesByWeekAndMonth = [];
  let weekendsLabelsByWeekAndMonth = [];

  if (queryParams.timeframe === "day") {
    let allDaysSeries = [];
    let allDaysLabels = [];
    result.forEach((val) => {
      if (!val[1] || !val[2]) return;
      const floor = queryParams.levels.find(
        (value) => value.floorId === val[1]
      );

      allDaysSeries.push(val[2]);
      allDaysLabels.push(floor?.name || "");
    });
    return {
      allDaysSeries: allDaysSeries,
      allDaysLabels: allDaysLabels,
    };
  }

  result.forEach((val) => {
    if (!val[1] || !val[2]) return;
    allWeekDataByWeek.push({
      name: val[1],
      count: val[2],
      date: val[0],
    });
    if (
      val[0].includes("Mon") ||
      val[0].includes("Tue") ||
      val[0].includes("Wed") ||
      val[0].includes("Thu") ||
      val[0].includes("Fri")
    ) {
      workDaysDataByWeek.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat") || val[0].includes("Sun")) {
      weekendsDataByWeek.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  const weekdaysSumFloors = (array) => {
    let holderOne = [];
    array.forEach(function(d) {
      if (holderOne.some((val) => val.name === d.name)) {
        const index = holderOne.findIndex((val) => val.name === d.name);
        holderOne[index] = {
          ...holderOne[index],
          count: holderOne[index].count + d.count,
          counter: holderOne[index].counter + 1,
        };
      } else {
        holderOne.push({
          name: d.name,
          count: d.count,
          counter: 1,
        });
      }
    });
    return holderOne.map((val, i) => {
      return { name: val.name, value: Math.round(val.count / val.counter) };
    });
  };

  const averageAllWeekDataByWeek = weekdaysSumFloors(allWeekDataByWeek);
  const averageWorkDaysDataByWeek = weekdaysSumFloors(workDaysDataByWeek);
  const averageWeekendsDataByWeek = weekdaysSumFloors(weekendsDataByWeek);

  averageAllWeekDataByWeek.forEach((val) => {
    const floor = queryParams.levels.find(
      (value) => value.floorId === val.name
    );
    allWeekSeriesByWeekAndMonth.push(val.value);
    allWeekLabelsByWeekAndMonth.push(floor?.name || "");
  });
  averageWorkDaysDataByWeek.forEach((val) => {
    const floor = queryParams.levels.find(
      (value) => value.floorId === val.name
    );
    workDaysSeriesByWeekAndMonth.push(val.value);
    workDaysLabelsByWeekAndMonth.push(floor?.name || "");
  });
  averageWeekendsDataByWeek.forEach((val) => {
    const floor = queryParams.levels.find(
      (value) => value.floorId === val.name
    );
    weekendsSeriesByWeekAndMonth.push(val.value);
    weekendsLabelsByWeekAndMonth.push(floor?.name || "");
  });

  return {
    allDaysSeries: allWeekSeriesByWeekAndMonth,
    allDaysLabels: allWeekLabelsByWeekAndMonth,
    //
    workDaysSeries: workDaysSeriesByWeekAndMonth,
    workDaysLabels: workDaysLabelsByWeekAndMonth,
    //
    weekendsSeries: weekendsSeriesByWeekAndMonth,
    weekendsLabels: weekendsLabelsByWeekAndMonth,
  };
};

export const handleFloorOccupancyResult = ({
  floorOccupancyResult,
  totalPeopleResult,
  queryParams,
}) => {
  const floorOccupancy = handleFloorOccupancy({
    result: floorOccupancyResult,
    queryParams,
  });
  const totalPeople = handleFloorTotalPeople({
    result: totalPeopleResult,
    queryParams,
  });

  var series = floorOccupancy.series.map((val) => {
    const floor = queryParams.levels.find(
      (value) => value.floorId === val.name
    );
    return { name: floor?.name || "", type: "column", data: val.data };
  });
  totalPeople &&
    series.push({
      name: "Total",
      type: "line",
      data: totalPeople.series,
    });

  var data = {
    series: series,
    labels: floorOccupancy.labels,
    xaxis: {
      labels: {
        style: {
          fontWeight: 500,
        },
      },
      tickPlacement: "between",
    },
  };

  if (queryParams.timeframe.includes("day")) {
    data.xaxis.range = 3 * 24 * 3600000;
  }
  if (queryParams.timeframe.includes("week")) {
    data.xaxis.labels.style.colors = floorOccupancy.color;
  }
  if (queryParams.timeframe.includes("month")) {
    data.xaxis.labels.style.colors = [
      "#626768",
      "#626768",
      "#626768",
      "#626768",
      "#626768",
      "#2ec4b6",
      "#F9CE1D",
    ];
  }
  return data;
};

function handleFloorOccupancy({ result, queryParams }) {
  if (!result) return;

  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (val && val[1]) {
      data.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  if (queryParams.timeframe === "day") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      // console.log(obj, "obj floors")
      // console.log(curr, "curr floors")
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});
    // console.log("🚀 ~ productTotals floors", productTotals)

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = "#626768";
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color = "#F9CE1D";
      }
      if (moment(val).format("dd") === "Sa") {
        color = "#2ec4b6";
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "week") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);

          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
}

function handleFloorTotalPeople({ result, queryParams }) {
  if (!result) return;

  let labels = [];
  let series = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thurdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    labels.push(val[0]);
    series.push(val[1]);

    if (val[0].includes("Mon")) {
      mondaysTotal.push(val[1]);
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Thu")) {
      thurdaysTotal.push(val[1]);
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push(val[1]);
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push(val[1]);
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push(val[1]);
    }
  });

  const weeklyAverage = [];

  if (queryParams.timeframe.includes("month")) {
    const mondaysAverage = getAvg(mondaysTotal);
    const mondaysRounded = Math.floor(mondaysAverage);
    weeklyAverage.push(mondaysRounded);
    const tuesdaysAverage = getAvg(tuesdaysTotal);
    const tuesdayRounded = Math.floor(tuesdaysAverage);
    weeklyAverage.push(tuesdayRounded);
    const wednesdaysAverage = getAvg(wednesdaysTotal);
    const wednesdayRounded = Math.floor(wednesdaysAverage);
    weeklyAverage.push(wednesdayRounded);
    const thurdaysAverage = getAvg(thurdaysTotal);
    const thurdaysRounded = Math.floor(thurdaysAverage);
    weeklyAverage.push(thurdaysRounded);
    const fridaysAverage = getAvg(fridaysTotal);
    const fridaysRounded = Math.floor(fridaysAverage);
    weeklyAverage.push(fridaysRounded);
    const saturdaysAverage = getAvg(saturdaysTotal);
    const saturdaysRounded = Math.floor(saturdaysAverage);
    weeklyAverage.push(saturdaysRounded);
    const sundaysAverage = getAvg(sundaysTotal);
    const sundaysRounded = Math.floor(sundaysAverage);
    weeklyAverage.push(sundaysRounded);

    return {
      labels: labels,
      series: weeklyAverage,
      weeklyAverage: weeklyAverage,
    };
  }

  return {
    labels: labels,
    series: series,
    weeklyAverage: weeklyAverage,
  };
}

export const handleFloorAverageOccupancyResult = ({ result, queryParams }) => {
  if (!result) return;

  let workDaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (
      val[0].includes("Mon") ||
      val[0].includes("Tue") ||
      val[0].includes("Wed") ||
      val[0].includes("Thu") ||
      val[0].includes("Fri")
    ) {
      workDaysTotal.push({
        count: val[1],
        date: val[0],
        name: "Working Days",
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        count: val[1],
        date: val[0],
        name: "Saturdays",
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        count: val[1],
        date: val[0],
        name: "Sundays",
      });
    }
  });

  if (queryParams.timeframe === "day") {
    const seriesWorkingDays = {
      name: "Working Days",
      data: workDaysTotal.map((item) => {
        return item.count;
      }),
    };

    const seriesSaturdays = {
      name: "Saturdays",
      data: saturdaysTotal.map((item) => {
        return item.count;
      }),
    };

    const seriesSundays = {
      name: "Sundays",
      data: sundaysTotal.map((item) => {
        return item.count;
      }),
    };

    return [seriesWorkingDays, seriesSaturdays, seriesSundays];
  }

  const weeklyAverageTotal = sumOfSameHours(workDaysTotal);
  const summedSaturdaysOfTheMonth = sumOfSameHours(saturdaysTotal);
  const summedSundaysOfTheMonth = sumOfSameHours(sundaysTotal);

  const seriesWorkingDays = {
    name: "Working Days",
    data: weeklyAverageTotal,
  };

  const seriesSaturdays = {
    name: "Saturdays ",
    data: summedSaturdaysOfTheMonth,
  };

  const seriesSundays = {
    name: "Sundays",
    data: summedSundaysOfTheMonth,
  };

  return [seriesWorkingDays, seriesSaturdays, seriesSundays];
};

export const handleZoneOccupancyResult = ({
  zoneOccupancyResult,
  totalPeopleResult,
  queryParams,
}) => {
  const floorOccupancy = handleOccupancy({
    result: zoneOccupancyResult,
    queryParams,
  });
  const totalPeople = handleTotalPeople({
    result: totalPeopleResult,
    queryParams,
  });

  var series = floorOccupancy.series.map((val) => {
    return { name: val.name || "", type: "column", data: val.data };
  });
  series.push({
    name: "Total",
    type: "line",
    data: totalPeople.series,
  });

  var data = {
    series: series,
    labels: floorOccupancy.labels,
    xaxis: {
      labels: {
        style: {
          fontWeight: 500,
        },
      },
      tickPlacement: "between",
    },
  };

  if (queryParams.timeframe.includes("day")) {
    data.xaxis.range = 3 * 24 * 3600000;
  }
  if (queryParams.timeframe.includes("week")) {
    data.xaxis.labels.style.colors = floorOccupancy.color;
  }
  if (queryParams.timeframe.includes("month")) {
    data.xaxis.labels.style.colors = [
      "#626768",
      "#626768",
      "#626768",
      "#626768",
      "#626768",
      "#2ec4b6",
      "#F9CE1D",
    ];
  }
  return data;
};

function handleOccupancy({ result, queryParams }) {
  if (!result) return;

  var data = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thursdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (val && val[1]) {
      data.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Mon")) {
      mondaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Thu")) {
      thursdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  if (queryParams.timeframe === "day") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      // console.log(obj, "obj floors")
      // console.log(curr, "curr floors")
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});
    // console.log("🚀 ~ productTotals floors", productTotals)

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = "#626768";
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color = "#F9CE1D";
      }
      if (moment(val).format("dd") === "Sa") {
        color = "#2ec4b6";
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "week") {
    let dates = data
      .map((item) => item.date)
      .filter((item, index, array) => array.indexOf(item) === index);

    const productTotals = data.reduce((obj, curr) => {
      if (!obj[curr.name]) {
        obj[curr.name] = [];
      }
      obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][
        dates.indexOf(curr.date)
      ]
        ? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) &&
          parseInt(curr.count)
        : parseInt(curr.count);
      return obj;
    }, {});

    const series = Object.entries(productTotals).map(([name, prodArr]) => {
      return {
        name: name,
        data: dates.map((month, monthIndex) => {
          if (!prodArr[monthIndex]) {
            return 0;
          } else {
            return prodArr[monthIndex];
          }
        }),
      };
    });

    let color = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        color.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        color.push("#2ec4b6");
      } else {
        color.push("#626768");
      }
    });

    const formattedDates = dates.map((val) => {
      return moment(val).format("ddd MMM D");
    });

    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "month") {
    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);

          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
          // holderOne[d.name] + d.count
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
          //holderOne[d.name] = d.count
        }
      });
      return holderOne.map((val, i) => {
        return { name: val.name, value: Math.round(val.count / val.counter) };
      });
    };

    const mondayData = weekdaysSumFloors(mondaysTotal);
    const tuesdayData = weekdaysSumFloors(tuesdaysTotal);
    const wednesdayData = weekdaysSumFloors(wednesdaysTotal);
    const thursdayData = weekdaysSumFloors(thursdaysTotal);
    const fridayData = weekdaysSumFloors(fridaysTotal);
    const saturdayData = weekdaysSumFloors(saturdaysTotal);
    const sundayData = weekdaysSumFloors(sundaysTotal);

    let finalSeries = [];
    data.forEach(function(d) {
      if (!finalSeries.some((val) => val.name === d.name)) {
        finalSeries.push({
          name: d.name,
          data: [
            mondayData.find((val) => val.name === d.name)?.value || 0,
            tuesdayData.find((val) => val.name === d.name)?.value || 0,
            wednesdayData.find((val) => val.name === d.name)?.value || 0,
            thursdayData.find((val) => val.name === d.name)?.value || 0,
            fridayData.find((val) => val.name === d.name)?.value || 0,
            saturdayData.find((val) => val.name === d.name)?.value || 0,
            sundayData.find((val) => val.name === d.name)?.value || 0,
          ],
          type: "column",
        });
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: finalSeries,
    };
  }
}

function handleTotalPeople({ result, queryParams }) {
  if (!result) return;

  let labels = [];
  let series = [];
  let mondaysTotal = [];
  let tuesdaysTotal = [];
  let wednesdaysTotal = [];
  let thurdaysTotal = [];
  let fridaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    labels.push(val[0]);
    series.push(val[1]);

    if (val[0].includes("Mon")) {
      mondaysTotal.push(val[1]);
    }
    if (val[0].includes("Tue")) {
      tuesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Wed")) {
      wednesdaysTotal.push(val[1]);
    }
    if (val[0].includes("Thu")) {
      thurdaysTotal.push(val[1]);
    }
    if (val[0].includes("Fri")) {
      fridaysTotal.push(val[1]);
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push(val[1]);
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push(val[1]);
    }
  });

  const weeklyAverage = [];

  if (queryParams.timeframe.includes("month")) {
    const mondaysAverage = getAvg(mondaysTotal);
    const mondaysRounded = Math.floor(mondaysAverage);
    weeklyAverage.push(mondaysRounded);
    const tuesdaysAverage = getAvg(tuesdaysTotal);
    const tuesdayRounded = Math.floor(tuesdaysAverage);
    weeklyAverage.push(tuesdayRounded);
    const wednesdaysAverage = getAvg(wednesdaysTotal);
    const wednesdayRounded = Math.floor(wednesdaysAverage);
    weeklyAverage.push(wednesdayRounded);
    const thurdaysAverage = getAvg(thurdaysTotal);
    const thurdaysRounded = Math.floor(thurdaysAverage);
    weeklyAverage.push(thurdaysRounded);
    const fridaysAverage = getAvg(fridaysTotal);
    const fridaysRounded = Math.floor(fridaysAverage);
    weeklyAverage.push(fridaysRounded);
    const saturdaysAverage = getAvg(saturdaysTotal);
    const saturdaysRounded = Math.floor(saturdaysAverage);
    weeklyAverage.push(saturdaysRounded);
    const sundaysAverage = getAvg(sundaysTotal);
    const sundaysRounded = Math.floor(sundaysAverage);
    weeklyAverage.push(sundaysRounded);

    return {
      labels: labels,
      series: weeklyAverage,
      weeklyAverage: weeklyAverage,
    };
  }

  return {
    labels: labels,
    series: series,
    weeklyAverage: weeklyAverage,
  };
}

export const handleZoneAverageOccupancy = ({ result, queryParams }) => {
  if (!result) return;
  let workDaysTotal = [];
  let saturdaysTotal = [];
  let sundaysTotal = [];

  result.forEach((val) => {
    if (
      val[0].includes("Mon") ||
      val[0].includes("Tue") ||
      val[0].includes("Wed") ||
      val[0].includes("Thu") ||
      val[0].includes("Fri")
    ) {
      workDaysTotal.push({
        count: val[2],
        date: val[0],
        name: "Working Days",
      });
    }
    if (val[0].includes("Sat")) {
      saturdaysTotal.push({
        count: val[2],
        date: val[0],
        name: "Saturdays",
      });
    }
    if (val[0].includes("Sun")) {
      sundaysTotal.push({
        count: val[2],
        date: val[0],
        name: "Sundays",
      });
    }
  });

  const workDaysTotalAverage = sumOfSameHours(workDaysTotal);
  const saturdaysTotalAverage = sumOfSameHours(saturdaysTotal);
  const sundaysTotalAverage = sumOfSameHours(sundaysTotal);

  const seriesWorkingDays = {
    name: "Working Days",
    data: workDaysTotalAverage,
  };

  const seriesSaturdays = {
    name: "Saturdays",
    data: saturdaysTotalAverage,
  };

  const seriesSundays = {
    name: "Sundays",
    data: sundaysTotalAverage,
  };

  return [seriesWorkingDays, seriesSaturdays, seriesSundays];
};

export const handleZonePeopleDistribution = ({ result, queryParams }) => {
  if (!result) return;

  let allWeekData = [];
  let workDaysData = [];
  let weekendsData = [];

  result.forEach((val) => {
    allWeekData.push({
      name: val[1],
      count: val[2],
      date: val[0],
    });
    if (
      val[0].includes("Mon") ||
      val[0].includes("Tue") ||
      val[0].includes("Wed") ||
      val[0].includes("Thu") ||
      val[0].includes("Fri")
    ) {
      workDaysData.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
    if (val[0].includes("Sat") || val[0].includes("Sun")) {
      weekendsData.push({
        name: val[1],
        count: val[2],
        date: val[0],
      });
    }
  });

  const sumOfAllWeekData = sumOfSameFloorsZones(allWeekData);
  const sumOfWorkDaysData = sumOfSameFloorsZones(workDaysData);
  const sumOfWeekendsData = sumOfSameFloorsZones(weekendsData);

  const arrayPercentage = (array) => {
    let quartersum = {};
    const getKeys = array.map(function(entry) {
      quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y;
      return getKeys;
    });
    const getPercentage = array.map(function(entry) {
      entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1);
      return entry;
    });
    let allWeekPercentage = [
      {
        data: getPercentage,
      },
    ];
    return allWeekPercentage;
  };

  const allWeekPercentage = arrayPercentage(sumOfAllWeekData);
  const workingDaysPercentage = arrayPercentage(sumOfWorkDaysData);
  const weekendsPercentage = arrayPercentage(sumOfWeekendsData);

  return {
    allWeek: allWeekPercentage,
    workingDays: workingDaysPercentage,
    weekends: weekendsPercentage,
  };
};

export const avgTimeSpentTopZonesResultToGraphData = ({
  result,
  queryParams,
}) => {
  if (!result) return;

  let allWeekDataByWeek = [];
  let workDaysDataByWeek = [];
  let weekendsDataByWeek = [];

  let allWeekData = [];
  let workDaysData = [];
  let weekendsData = [];

  result.forEach((val) => {
    if (val[2] && val[3]) {
      allWeekData.push({ x: val[2], y: val[3] });
    }
    if (
      val[0]?.includes("Mon") ||
      val[0]?.includes("Tue") ||
      val[0]?.includes("Wed") ||
      val[0]?.includes("Thu") ||
      val[0]?.includes("Fri")
    ) {
      workDaysData.push({ x: val[2], y: val[3] });
    }
    if (val[0]?.includes("Sat") || val[0]?.includes("Sun")) {
      weekendsData.push({ x: val[2], y: val[3] });
    }
  });

  if (queryParams.timeframe === "day") {
    const allWeekDataFinalArray = [
      {
        data: allWeekData,
      },
    ];
    const workingDaysDataFinalArray = [
      {
        data: workDaysData,
      },
    ];
    const weekendsDataFinalArray = [
      {
        data: weekendsData,
      },
    ];

    return {
      allWeekDataFinal: allWeekDataFinalArray,
      workingDaysDataFinal: workingDaysDataFinalArray,
      weekendsDataFinal: weekendsDataFinalArray,
    };
  }

  if (queryParams.timeframe === "week" || queryParams.timeframe === "month") {
    if (!result) return;

    result.forEach((val) => {
      if (val[0] && val[2] && val[3]) {
        allWeekDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
    });

    result.forEach((val) => {
      if (
        val[0]?.includes("Mon") ||
        val[0]?.includes("Tue") ||
        val[0]?.includes("Wed") ||
        val[0]?.includes("Thu") ||
        val[0]?.includes("Fri")
      ) {
        workDaysDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
      if (val[0]?.includes("Sat") || val[0]?.includes("Sun")) {
        weekendsDataByWeek.push({
          name: val[2],
          count: val[3],
          date: val[0],
        });
      }
    });

    const weekdaysSumFloors = (array) => {
      let holderOne = [];
      array.forEach(function(d) {
        if (holderOne.some((val) => val.name === d.name)) {
          const index = holderOne.findIndex((val) => val.name === d.name);
          holderOne[index] = {
            ...holderOne[index],
            count: holderOne[index].count + d.count,
            counter: holderOne[index].counter + 1,
          };
        } else {
          holderOne.push({
            name: d.name,
            count: d.count,
            counter: 1,
          });
        }
      });

      return holderOne.map((val, i) => {
        return { x: val.name, y: Math.round(val.count / val.counter) };
      });
    };

    const averageAllWeekDataByWeek = weekdaysSumFloors(allWeekDataByWeek);
    const averageWorkDaysDataByWeek = weekdaysSumFloors(workDaysDataByWeek);
    const averageWeekendsDataByWeek = weekdaysSumFloors(weekendsDataByWeek);

    const allWeekDataFinalArray = [
      {
        data: averageAllWeekDataByWeek,
      },
    ];

    const workingDaysDataFinalArray = [
      {
        data: averageWorkDaysDataByWeek,
      },
    ];

    const weekendsDataFinalArray = [
      {
        data: averageWeekendsDataByWeek,
      },
    ];

    return {
      allWeekDataFinal: allWeekDataFinalArray,
      workingDaysDataFinal: workingDaysDataFinalArray,
      weekendsDataFinal: weekendsDataFinalArray,
    };
  }
};

export function handleZoneOccupancyForChord({ result, queryParams }) {
  if (!result) return;
  // console.log("🚀 ~ queryParams", queryParams);
  // console.log("🚀 ~ result", result);

  var data = [];

  result.forEach((val) => {
    if (val && val._from) {
      data.push({
        from: val._from,
        to: val._to,
        count: val.count,
      });
    }
  });

  // console.log(data, "data");

  const uniq = {};
  const arrFiltered = data.filter(
    (obj) => !uniq[obj.from] && (uniq[obj.from] = true)
  );
  // console.log("arrFiltered", arrFiltered);

  const names1 = [];

  arrFiltered.forEach((val) => {
    names1.push(val.from);
  });

  // console.log(names1, "names 1");

  // ↓ Equal to names1 array but the order is different
  // const names = Array.from(new Set(data.flatMap((d) => [d.from, d.to])));
  // console.log("🚀 ~ names 2", names);

  const index = new Map(names1.map((name, i) => [name, i]));
  const matrix = Array.from(index, () => new Array(names1.length).fill(0));

  for (const { from, to, count } of data)
    matrix[index.get(from)][index.get(to)] += count;

  // console.log("🚀 ~ matrix", matrix);

  // send also de floors and locations names
  const zonesWithFloorsAndLocations = [];

  queryParams &&
    queryParams.zones.forEach((z) => {
      const floorId = queryParams.levels.find(
        (floor) => floor.floorId === z.floorPlanId
      );
      // console.log("🚀 ~ floorId", floorId);

      const location = queryParams.locations.find((loc) => {
        const hasFloor = loc.levels.some((l) => l.floorId === z.floorPlanId);
        // console.log("🚀 ~ hasFloor", hasFloor);
        if (hasFloor) {
          return loc;
        } else {
          return "";
        }
      });
      // console.log("🚀 ~ location", location);

      zonesWithFloorsAndLocations.push({
        zone: z.name,
        floor: floorId.name,
        location: location ? location.netName : "",
      });
    });

  // console.log("🚀 ~ zonesWithFloorsAndLocations", zonesWithFloorsAndLocations);

  // Filtered result is to show only the zones that have result
  let filteredResult = zonesWithFloorsAndLocations.filter((o1) =>
    names1.some((o2) => o1.zone === o2)
  );
  // console.log("🚀 ~ filteredResult", filteredResult);

  return {
    zones: names1,
    matrix: matrix,
    zonesWithFloorsAndLocations: filteredResult,
  };
}

export function handleZoneOccupancyForSankeyCircular({ result, queryParams }) {
  if (!result) return;
  // console.log("🚀 ~ queryParams", queryParams);
  // console.log("🚀 ~ result Sankey Circular", result);

  var data = [];

  result.forEach((val) => {
    if (val && val._from) {
      data.push({
        source: val._from,
        target: val._to,
        value: val.count,
      });
    }
  });

  // console.log(data, "data");

  // Get uniq values com key obj.from
  const uniq = {};
  const arrFiltered = data.filter(
    (obj) => !uniq[obj.source] && (uniq[obj.source] = true)
  );
  // console.log("arrFiltered", arrFiltered);

  const names1 = [];
  arrFiltered.forEach((val) => {
    names1.push(val.source);
  });
  // console.log(names1, "names 1");

  // Send also de floors and locations names
  const zonesWithFloorsAndLocations = [];

  queryParams &&
    queryParams.zones.forEach((z) => {
      const floorId = queryParams.levels.find(
        (floor) => floor.floorId === z.floorPlanId
      );
      // console.log("🚀 ~ floorId", floorId);

      const location = queryParams.locations.find((loc) => {
        const hasFloor = loc.levels.some((l) => l.floorId === z.floorPlanId);
        // console.log("🚀 ~ hasFloor", hasFloor);
        if (hasFloor) {
          return loc;
        } else {
          return "";
        }
      });
      // console.log("🚀 ~ location", location);

      zonesWithFloorsAndLocations.push({
        zone: z.name,
        floor: floorId.name,
        location: location ? location.netName : "",
      });
    });

  // console.log("🚀 ~ zonesWithFloorsAndLocations", zonesWithFloorsAndLocations);

  // Filtered result is to show only the zones that have result
  let filteredResult = zonesWithFloorsAndLocations.filter((o1) =>
    names1.some((o2) => o1.zone === o2)
  );
  // console.log("🚀 ~ filteredResult", filteredResult);

  const nodes = [];
  filteredResult.forEach((r) => {
    nodes.push(r.zone);
  });
  // console.log("🚀 ~ nodes", nodes);

  // Sort nodes array by original order from the result of the fetch
  const nodesSorted = nodes.sort(function(a, b) {
    return names1.indexOf(a) - names1.indexOf(b);
  });
  // console.log("🚀 ~ nodesSorted", nodesSorted);

  const nodesSortedArray = [];
  nodesSorted.forEach((r) => {
    nodesSortedArray.push({
      name: r,
    });
  });

  // Find if data.target exists has a source if not we have to push that target has a node.
  const targetNodeWithoutSource = data.reduce((acc, curr) => {
    const index = acc.findIndex((item) => item.target === curr.source);

    index > -1
      ? (curr = curr)
      : acc.push({
          source: curr.source,
          target: curr.target,
          value: curr.value,
        });

    return acc;
  }, []);

  // console.log("🚀 ~ targetNodeWithoutSource", targetNodeWithoutSource);

  // Check if data.target exists has a source, if not push that target has a node so that we have that node created.
  targetNodeWithoutSource.forEach((t) => {
    const foundedNodeName = nodesSortedArray.find((n) => n.name === t.target);
    // console.log("🚀 ~ foundedNodeName", foundedNodeName);
    if (!foundedNodeName) {
      // console.log("não encontrou!");
      nodesSortedArray.push({
        name: t.target,
      });
    }
  });

  // console.log("🚀 ~ nodesSortedArray", nodesSortedArray);

  return {
    zonesWithFloorsAndLocations: filteredResult,
    nodes: nodesSortedArray,
    links: data,
  };
}

export function handleZoneOccupancyForSankey({ result, queryParams }) {
  if (!result) return;

  var data = [];

  result.forEach((val) => {
    if (val && val._from) {
      data.push({
        source: val._from,
        target: val._to,
        value: val.count,
      });
    }
  });

  // console.log(data, "data");

  // Get uniq values com key obj.from - finding which nodes exist
  const uniq = {};
  const arrFiltered = data.filter(
    (obj) => !uniq[obj.source] && (uniq[obj.source] = true)
  );
  // console.log("arrFiltered", arrFiltered);

  const names1 = [];
  arrFiltered.forEach((val) => {
    names1.push(val.source);
  });
  // console.log(names1, "names 1");

  // Send also de floors and locations names
  const zonesWithFloorsAndLocations = [];
  queryParams &&
    queryParams.zones.forEach((z) => {
      const floorId = queryParams.levels.find(
        (floor) => floor.floorId === z.floorPlanId
      );
      // console.log("🚀 ~ floorId", floorId);

      const location = queryParams.locations.find((loc) => {
        const hasFloor = loc.levels.some((l) => l.floorId === z.floorPlanId);
        // console.log("🚀 ~ hasFloor", hasFloor);
        if (hasFloor) {
          return loc;
        } else {
          return "";
        }
      });
      // console.log("🚀 ~ location", location);

      zonesWithFloorsAndLocations.push({
        zone: z.name,
        floor: floorId.name,
        location: location ? location.netName : "",
      });
    });

  // console.log("🚀 ~ zonesWithFloorsAndLocations", zonesWithFloorsAndLocations);

  // Filtered result is to show only the zones that have result
  let filteredResult = zonesWithFloorsAndLocations.filter((o1) =>
    names1.some((o2) => o1.zone === o2)
  );
  // console.log("🚀 ~ filteredResult", filteredResult);

  const nodes = [];
  filteredResult.forEach((r) => {
    nodes.push(r.zone);
  });
  // console.log("🚀 ~ nodes", nodes);

  // Sort nodes array by original order from the result of the fetch
  const nodesSorted = nodes.sort(function(a, b) {
    return names1.indexOf(a) - names1.indexOf(b);
  });
  // console.log("🚀 ~ nodesSorted", nodesSorted);

  const nodesSortedArray = [];
  nodesSorted.forEach((r) => {
    nodesSortedArray.push({
      name: r,
    });
  });

  // Find if data.target exists has a source if not we have to push that target has a node.
  const targetNodeWithoutSource = data.reduce((acc, curr) => {
    const index = acc.findIndex((item) => item.target === curr.source);

    index > -1
      ? (curr = curr)
      : acc.push({
          source: curr.source,
          target: curr.target,
          value: curr.value,
        });

    return acc;
  }, []);

  // console.log("🚀 ~ targetNodeWithoutSource", targetNodeWithoutSource);

  // Check if data.target exists has a source, if not push that target has a node so that we have that node created.
  targetNodeWithoutSource.forEach((t) => {
    const foundedNodeName = nodesSortedArray.find((n) => n.name === t.target);
    // console.log("🚀 ~ foundedNodeName", foundedNodeName);
    if (!foundedNodeName) {
      // console.log("não encontrou!");
      nodesSortedArray.push({
        name: t.target,
      });
    }
  });

  // Add Dropout node at end of the nodes array
  nodesSortedArray.push({
    name: "Dropout",
  });

  // console.log("🚀 ~ nodesSortedArray 2", nodesSortedArray);

  const dropOutArray = [];
  // Find if data.source is equal to next object property data.target if it is filter.
  const filteredData = data.reduce((acc, curr) => {
    const index = acc.findIndex((item) => item.source === curr.target);

    index > -1
      ? dropOutArray.push(curr)
      : acc.push({
          source: curr.source,
          target: curr.target,
          value: curr.value,
        });
    return acc;
  }, []);

  // console.log(filteredData, "filteredData");

  // console.log("🚀 ~ dropOutArray", dropOutArray);

  const newArr = [];
  const newArrDropouts = [];

  // ↓ Substituir o nome do f.source pelo index que seja o mesmo nome dos nodes
  nodesSortedArray.forEach((n, index) => {
    filteredData.map((f) => {
      if (f.source === n.name) {
        newArr.push({
          source: index,
          target: f.target,
          value: f.value,
        });
      }
    });

    dropOutArray.map((f) => {
      if (f.source === n.name) {
        newArrDropouts.push({
          source: index,
          target: f.target,
          value: f.value,
        });
      }
    });

    // this for loop inside the forEach cause an error, because we need to wait that source with index is pushed to newArr and then push the target
    // for (let j = 0; j < newArr.length; j++) {
    //   if (newArr[j].target === n.name) {
    //     newArr[j] = {
    //       ...newArr[j],
    //       target: index,
    //     };
    //   }
    // }
  });

  // ↓ Substituir o nome do f.target pelo index que seja o mesmo nome dos nodes.
  nodesSortedArray.forEach((n, index) => {
    for (let j = 0; j < newArr.length; j++) {
      if (newArr[j].target === n.name) {
        newArr[j] = {
          ...newArr[j],
          target: index,
        };
      }
    }
  });

  const lastIndex = nodesSortedArray.findIndex((e) => e.name === "Dropout");
  // console.log("🚀 ~ lastIndex", lastIndex);

  // ↓ Substituir o nome do f.target pelo index que seja o mesmo nome do Dropout node.
  for (let j = 0; j < newArrDropouts.length; j++) {
    newArrDropouts[j] = {
      ...newArrDropouts[j],
      target: lastIndex,
    };
  }

  const endresult = Object.values(
    newArrDropouts.reduce((value, object) => {
      if (value[object.source]) {
        value[object.source].value += object.value;
      } else {
        value[object.source] = { ...object };
      }
      return value;
    }, {})
  );

  // console.log(endresult, "endresult");

  // push dropouts links to links array
  endresult.forEach((d) => {
    newArr.push(d);
  });

  // console.log("🚀 ~ newArr 2", newArr);

  // console.log("🚀 ~ newArrDropouts", newArrDropouts);

  return {
    zonesWithFloorsAndLocations: filteredResult,
    nodes: nodesSortedArray,
    links: newArr,
  };
}
