import * as requestFromServer from "./reportingCrud";
import { persistentReportingSlice } from "./persistentReportingSlice";
import { reportingSlice, callTypes } from "./reportingSlice";
import {
  wifiAccuracyResultToGraphData,
  floorOccupancyResultToGraphData,
  zoneOccupancyResultToGraphData,
  AvgTimeDayResultToGraphData,
  floorOccupancyResultToGraphData2,
  getDayTotalResultToGraphData,
  busiestFloorsResultToGraphData,
  zoneOccupancyResultToGraphData2,
  zoneOccupancyByHourResultToGraphData,
  busiestZonesResultToGraphData2,
  avgTimeSpentZoneResultToGraphData,
  avgTimeSpentTopZonesResultToGraphData,
  zoneOccupancyResultToGraphData2ByLevel,
  zoneOccupancyTotalPeopleResultToGraphData,
  zoneOccupancyTotalPeopleResultToGraphDataByLevel,
  floorOccupancyTotalPeopleResultToGraphData,
  teamsFloorOccupancyResultToGraphData,
  allBookingsResultToGraphData,
} from "../_helpers/ActionHelpers";

const persistentActions = persistentReportingSlice.actions;
const { actions } = reportingSlice;

export const setPage = (page) => (dispatch) => {
  if (!page) dispatch(persistentActions.pageSet(null));
  dispatch(persistentActions.pageSet(page));
};

export const setGroup = (group) => (dispatch) => {
  if (!group) dispatch(persistentActions.groupSet(null));
  dispatch(persistentActions.groupSet(group));
};

//★━━━━━━━━━━━━━━━━★ Wifi ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const createScheduledReportWifi = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  console.log(queryParams, "queryParams actions");
  return requestFromServer
    .scheduledReportWifi(queryParams)
    .then((res) => {
      dispatch({
        type: "SNACKBAR_SUCCESS",
        payload: `Wifi Report scheduled`,
      });
      return res;
    })
    .catch((error) => {
      error.clientMessage = "Can't schedule Wifi Report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getWifiAccuracy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.wifiAccuracyFetched({ entities: null }));
  }

  return requestFromServer
    .getWifiAccuracy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var labels = [];
      var series = [];
      var totalValue = 0;
      var samples = 0;

      JSON.parse(result).forEach((value) => {
        if (value[1]) {
          var floor = queryParams.floorPlans.find(
            (floor) => floor.id === value[1]
          );
          labels.push(floor.name);
          series.push(value[3]);
          totalValue = totalValue + value[2] * value[3];
          samples = samples + value[2];
        }
      });

      var data = {
        labels: labels,
        series: series,
        total: totalValue / samples,
      };

      dispatch(actions.wifiAccuracyFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch wifi accuracy", error);
      error.clientMessage = "Can't find wifi accuracy data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDailyWifiAccuracy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.dailyWifiAccuracyFetched({ entities: null }));
  }

  // console.log(queryParams, "queryParams");

  return requestFromServer
    .getDailyWifiAccuracy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = wifiAccuracyResultToGraphData(result);

      dispatch(actions.dailyWifiAccuracyFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getRSSI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.rssiFetched({ entities: null }));
  }

  return requestFromServer
    .getRSSI(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = [];

      JSON.parse(result).forEach((value) => {
        if (value[1]) {
          var floor = queryParams.floorPlans.find(
            (floor) => floor.id === value[1]
          );

          data.push({
            floorPlan: floor.name,
            sampleSize: value[2],
            numMacs: value[3],
            terrible: value[4],
            bad: value[5],
            good: value[6],
            excelent: value[7],
          });
        }
      });

      dispatch(actions.rssiFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch rssi", error);
      error.clientMessage = "Can't find rssi data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDailyRSSI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.dailyRSSIFetched({ entities: null }));
  }

  return requestFromServer
    .getDailyRSSI(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var unique = [...new Set(JSON.parse(result).map((item) => item[2]))];
      var filter = unique.filter((u) => {
        return u != null && u;
      });
      var final = [];

      filter.forEach((floor) => {
        var floorData = JSON.parse(result).filter((f) => floor === f[2]);
        var data = [];

        floorData.forEach((value) => {
          value &&
            data.push({
              name: "Excelent",
              count: value[8],
              date: value[0],
            });
          value &&
            data.push({
              name: "Good",
              count: value[7],
              date: value[0],
            });
          value &&
            data.push({
              name: "Average",
              count: value[6],
              date: value[0],
            });
          value &&
            data.push({
              name: "Bad",
              count: value[5],
              date: value[0],
            });
        });

        let dates = data
          .map((item) => item.date)
          .filter((item, index, array) => array.indexOf(item) === index);

        const productTotals = data.reduce((obj, curr) => {
          if (!obj[curr.name]) {
            obj[curr.name] = [];
          }
          obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
          return obj;
        }, {});

        const series = Object.entries(productTotals).map(([name, prodArr]) => {
          return {
            name: name,
            data: dates.map((month, monthIndex) => {
              if (!prodArr[monthIndex]) {
                return 0;
              } else {
                return prodArr[monthIndex];
              }
            }),
          };
        });

        var floorName = queryParams.floorPlans.find((f) => f.id === floor);

        final.push({ xAxis: dates, yAxis: series, floorPlan: floorName?.name });
      });

      dispatch(actions.dailyRSSIFetched({ entities: final }));
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getZonesWifiAccuracy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zonesWifiAccuracyFetched({ entities: null }));
  }

  return requestFromServer
    .getZonesWifiAccuracy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var values = [];
      var colors = [];

      JSON.parse(result).forEach((value) => {
        if (value[1]) {
          values.push({ x: value[2], y: value[5] });

          if (value[5] >= 90) {
            colors.push("#09ff00");
          } else if (value[5] >= 75) {
            colors.push("#00b015");
          } else if (value[5] >= 60) {
            colors.push("#e3db00");
          } else if (value[5] >= 40) {
            colors.push("#f7a100");
          } else {
            colors.push("#f70000");
          }
        }
      });

      var data = { values: values, colors: colors };

      dispatch(actions.zonesWifiAccuracyFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch wifi accuracy", error);
      error.clientMessage = "Can't find wifi accuracy data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTreeMapRSSI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.TreeMapRSSIFetched({ entities: null }));
  }

  return requestFromServer
    .getTreeMapRSSI(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var values = [];
      var colors = [];

      JSON.parse(result).forEach((value) => {
        if (value[1]) {
          values.push({ x: value[2], y: value[5] });
          console.log("Value 5: ", value[2]);
          if (value[5] >= -65) {
            colors.push("#008ffb");
          } else if (value[5] >= -80) {
            colors.push("#00e396");
          } else if (value[5] >= -90) {
            colors.push("#feb019");
          } else {
            colors.push("#ff4560");
          }
        }
      });
      console.log("-------");

      var data = { values: values, colors: colors };

      dispatch(actions.TreeMapRSSIFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch TreeMapRSSI", error);
      error.clientMessage = "Can't find TreeMapRSSIy data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getZonesDailyWifiAccuracy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zonesDailyWifiAccuracyFetched({ entities: null }));
  }

  return requestFromServer
    .getZonesDailyWifiAccuracy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = [];

      JSON.parse(result).forEach((value) => {
        if (value[0] !== null || value[3] !== null || value[6] !== null) {
          value &&
            data.push({
              name: value[3],
              count: value[6],
              date: value[0],
            });
        }
      });

      let dates = data
        .map((item) => item.date)
        .filter((item, index, array) => array.indexOf(item) === index);

      const productTotals = data.reduce((obj, curr) => {
        if (!obj[curr.name]) {
          obj[curr.name] = [];
        }
        obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
        return obj;
      }, {});

      const series = Object.entries(productTotals).map(([name, prodArr]) => {
        return {
          name: name,
          data: dates.map((month, monthIndex) => {
            if (!prodArr[monthIndex]) {
              return 0;
            } else {
              return prodArr[monthIndex];
            }
          }),
        };
      });

      dispatch(
        actions.zonesDailyWifiAccuracyFetched({
          entities: { xAxis: dates, yAxis: series },
        })
      );
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getZonesRSSI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zonesRSSIFetched({ entities: null }));
  }

  return requestFromServer
    .getZonesRSSI(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = [];

      JSON.parse(result).forEach((value) => {
        data.push({
          zoneName: value[2],
          sampleSize: value[3],
          numMacs: value[4],
          terrible: value[5],
          bad: value[6],
          good: value[7],
          excelent: value[8],
        });
      });

      dispatch(actions.zonesRSSIFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch zones rssi", error);
      error.clientMessage = "Can't find zones rssi data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getZonesDailyRSSI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zonesDailyRSSIFetched({ entities: null }));
  }

  return requestFromServer
    .getZonesDailyRSSI(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var unique = [...new Set(JSON.parse(result).map((item) => item[3]))];
      var filter = unique.filter((u) => {
        return u != null && u;
      });
      var final = [];

      filter.forEach((zone) => {
        var zoneData = JSON.parse(result).filter((f) => zone === f[3]);
        var data = [];

        zoneData.forEach((value) => {
          value &&
            data.push({
              name: "Excelent",
              count: value[9],
              date: value[0],
            });
          value &&
            data.push({
              name: "Good",
              count: value[8],
              date: value[0],
            });
          value &&
            data.push({
              name: "Average",
              count: value[7],
              date: value[0],
            });
          value &&
            data.push({
              name: "Bad",
              count: value[6],
              date: value[0],
            });
        });

        let dates = data
          .map((item) => item.date)
          .filter((item, index, array) => array.indexOf(item) === index);

        const productTotals = data.reduce((obj, curr) => {
          if (!obj[curr.name]) {
            obj[curr.name] = [];
          }
          obj[curr.name][dates.indexOf(curr.date)] = parseInt(curr.count);
          return obj;
        }, {});

        const series = Object.entries(productTotals).map(([name, prodArr]) => {
          return {
            name: name,
            data: dates.map((month, monthIndex) => {
              if (!prodArr[monthIndex]) {
                return 0;
              } else {
                return prodArr[monthIndex];
              }
            }),
          };
        });

        final.push({ xAxis: dates, yAxis: series, floorPlan: zone });
      });

      dispatch(actions.zonesDailyRSSIFetched({ entities: final }));
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//★━━━━━━━━━━━━━━★ Occupancy ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchBusiestFloors = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.busiestFloorsFetched({ entities: null }));
  }

  return requestFromServer
    .getBusiestFloors(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.busiestFloorsFetched({
            entities: null,
          })
        );
        // throw Error("Can't make a request for server");
      }

      var data = busiestFloorsResultToGraphData({ result, queryParams });

      dispatch(actions.busiestFloorsFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch Busiest Floors: ", error);
      error.clientMessage = "Can't fetch Busiest Floors";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchFloorOccupancy2 = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.floorOccupancyFetched2({ entities: null }));
  }

  return requestFromServer
    .getFloorOccupancy2(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.floorOccupancyFetched2({
            entities: null,
          })
        );
      }

      var data = floorOccupancyResultToGraphData2({ result, queryParams });

      dispatch(actions.floorOccupancyFetched2({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch floor occupancy2: ", error);
      error.clientMessage = "Can't fetch floor occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTotalPeople2 = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.totalPeopleFetched2({ entities: null }));
  }

  return requestFromServer
    .getTotalPeople2(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.totalPeopleFetched2({
            entities: {
              labels: [],
              series: [],
              weeklyAverage: [],
            },
          })
        );
        // throw Error("Can't make a request for server");
      }

      let data = floorOccupancyTotalPeopleResultToGraphData({
        result,
        queryParams,
      });

      dispatch(actions.totalPeopleFetched2({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch Total People: ", error);
      error.clientMessage = "Can't fetch Total People";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTotalPeopleZones = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.totalPeopleFetchedZones({ entities: null }));
  }

  return requestFromServer
    .getTotalPeopleZones(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.totalPeopleFetchedZones({
            entities: {
              labels: [],
              series: [],
              weeklyAverage: [],
            },
          })
        );
        // throw Error("Can't make a request for server");
      }

      let data = zoneOccupancyTotalPeopleResultToGraphData({
        result,
        queryParams,
      });

      dispatch(actions.totalPeopleFetchedZones({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch Total People: ", error);
      error.clientMessage = "Can't fetch Total People";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTotalPeople2ByLevel = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.totalPeopleFetched2ByLevel({ entities: null }));
  }

  return requestFromServer
    .getTotalPeople2ByLevel(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.totalPeopleFetched2ByLevel({
            entities: {
              labels: [],
              series: [],
              weeklyAverage: [],
            },
          })
        );
      }

      let data = zoneOccupancyTotalPeopleResultToGraphDataByLevel({
        result,
        queryParams,
      });

      dispatch(actions.totalPeopleFetched2ByLevel({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch Total People by selected Level: ", error);
      error.clientMessage = "Can't fetch Total People by Level";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchFloorOccupancy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.floorOccupancyFetched({ entities: null }));
  }

  return requestFromServer
    .getFloorOccupancy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = floorOccupancyResultToGraphData(result);

      dispatch(actions.floorOccupancyFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch floor occupancy: ", error);
      error.clientMessage = "Can't fetch floor occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTotalPeople = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.totalPeopleFetched({ entities: null }));
  }

  return requestFromServer
    .getTotalPeople(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }
      var labels = [];
      var series = [];
      JSON.parse(result).forEach((val) => {
        labels.push(val[0]);
        series.push(val[1]);
      });
      dispatch(actions.totalPeopleFetched({ entities: { labels, series } }));
    })
    .catch((error) => {
      console.log("Fetch Total People: ", error);
      error.clientMessage = "Can't fetch Total People";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDayTotalPeople = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.dayTotalPeopleDataFetched({ entities: null }));
  }

  return requestFromServer
    .getDayTotalPeopleData(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.dayTotalPeopleDataFetched({
            entities: { series: [], labels: [] },
          })
        );
        // throw Error("Can't make a request for server");
      }

      let data = getDayTotalResultToGraphData({ result, queryParams });

      dispatch(actions.dayTotalPeopleDataFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("fetch day floor data", error);
      error.clientMessage = "Can't find day floor data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBusiestZones = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.busiestZonesFetched({ entities: null }));
  }

  return requestFromServer
    .getBusiestZones(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.busiestZonesFetched({
            entities: null,
          })
        );
      }

      let data = busiestZonesResultToGraphData2({ result, queryParams });

      dispatch(actions.busiestZonesFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch Busiest Zones: ", error);
      error.clientMessage = "Can't fetch Busiest Zones";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAvgTimeSpentTopZones = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.avgTimeSpentTopZonesFetched({ entities: null }));
  }

  return requestFromServer
    .getAvgTimeSpentTopZones(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.avgTimeSpentTopZonesFetched({
            entities: null,
          })
        );
      }

      let data = avgTimeSpentTopZonesResultToGraphData({ result, queryParams });

      dispatch(actions.avgTimeSpentTopZonesFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch avg time spent top zones: ", error);
      error.clientMessage = "Can't fetch avg time spent top zones";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZoneByHour = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zoneOccupancyFetchedByHour({ entities: null }));
  }

  return requestFromServer
    .getZoneByHour(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.zoneOccupancyFetchedByHour({
            entities: null,
          })
        );
      }

      let data = zoneOccupancyByHourResultToGraphData({ queryParams, result });

      dispatch(actions.zoneOccupancyFetchedByHour({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch zone occupancy by hour: ", error);
      error.clientMessage = "Can't fetch zone occupancy by hour";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZoneOccupancy2 = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zoneOccupancyFetched2({ entities: null }));
  }

  return requestFromServer
    .getZoneOccupancy2(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.zoneOccupancyFetched2({
            entities: null,
          })
        );
      }

      let data = zoneOccupancyResultToGraphData2({ queryParams, result });

      dispatch(actions.zoneOccupancyFetched2({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch zone occupancy: ", error);
      error.clientMessage = "Can't fetch zone occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZoneOccupancy2ByLevel = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zoneOccupancyFetched2ByLevel({ entities: null }));
  }

  return requestFromServer
    .getZoneOccupancy2ByLevel(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.zoneOccupancyFetched2ByLevel({
            entities: null,
          })
        );
      }

      let data = zoneOccupancyResultToGraphData2ByLevel({
        queryParams,
        result,
      });

      dispatch(actions.zoneOccupancyFetched2ByLevel({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch zone occupancy by level: ", error);
      error.clientMessage = "Can't fetch zone occupancy by level";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZoneOccupancy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.zoneOccupancyFetched({ entities: null }));
  }

  return requestFromServer
    .getZoneOccupancy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = zoneOccupancyResultToGraphData(result);

      dispatch(actions.zoneOccupancyFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch zone occupancy: ", error);
      error.clientMessage = "Can't fetch zone occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAvgTimeSpentByHour = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.avgTimeSpentByHourFetched({ entities: null }));
  }

  return requestFromServer
    .getAverageTimeSpentByHour(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.avgTimeSpentByHourFetched({
            entities: null,
          })
        );
      }

      // console.log(queryParams, "queryParams actions");
      // console.log(result, "result actions");
      let data = avgTimeSpentZoneResultToGraphData({ queryParams, result });
      // console.log(data, "data actions");

      dispatch(actions.avgTimeSpentByHourFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch average time spent by hour: ", error);
      error.clientMessage = "Can't fetch average time spent by hour";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAvgTimeZone = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.AvgTimeZoneFetched({ entities: null }));
  }
  return requestFromServer
    .getAvgTimeZone(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      console.log(result, "result segundooooo");

      var values = [];
      var colors = [];

      JSON.parse(result).forEach((value) => {
        if (value[1]) {
          values.push({ x: value[1], y: value[3] });
          if (value[3] >= 90) {
            colors.push("##008ffb");
          } else if (value[3] >= 75) {
            colors.push("#00b015");
          } else if (value[3] >= 60) {
            colors.push("#e3db00");
          } else if (value[3] >= 40) {
            colors.push("#f7a100");
          } else {
            colors.push("#f70000");
          }
        }
      });

      var data = { values: values, colors: colors };

      dispatch(actions.avgTimeZoneFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch AvgTimeZone: ", error);
      error.clientMessage = "Can't fetch AvgTimeZone";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//★━━━━━━━━━━━━━━★ Employee ★━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchAvgTimeDay = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.avgTimeDayFetched({ entities: null }));
  }

  return requestFromServer
    .getAvgTimeDay(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request for server");
      }

      var data = AvgTimeDayResultToGraphData(result);

      dispatch(actions.AvgTimeDayFetched({ entities: data }));
    })
    .catch((error) => {
      console.log("Fetch AvgTimeDay: ", error);
      error.clientMessage = "Can't fetch AvgTimeDay";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//★━━━━━━━━━━━━━━━━━★ Teams ★━━━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchAllTeamsOccupancy = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(
      actions.teamsFloorOccupancyFetched({
        entities: null,
      })
    );
  }

  // (a variável users são todos os users que existem em algum departamento)
  // filteredUsers = users que existem por departamento.
  const filteredUsers = queryParams.users?.filter((user) =>
    queryParams.department.employees?.includes(user.id)
  );
  const macsArray = [];
  const usersMacAdresses = filteredUsers?.map((mac) => mac.devicesArray);

  const flattenMacs = usersMacAdresses?.flat().filter((val) => {
    if (val !== undefined) {
      macsArray.push(val);
    } else {
      macsArray.push("");
    }
    return null;
  });

  // console.log(queryParams.department, "deparments");
  // macsArray && console.log(macsArray, "macsArray actions");

  if (macsArray.length === 0) {
    return;
  }

  return requestFromServer
    .getTeamsOccupancy(queryParams)
    .then((response) => response.text())
    .then((result) => {
      if (!result) {
        throw Error("Can't make a request from server");
      }

      const data = teamsFloorOccupancyResultToGraphData({
        result,
        queryParams,
      });
      // console.log(data, "data nas actions");

      if (data !== undefined) {
        dispatch(
          actions.teamsFloorOccupancyFetched({
            id: queryParams.department.id,
            entities: data,
          })
        );
      }
    })
    .catch((error) => {
      console.log("Fetch teams occupancy: ", error);
      error.clientMessage = "Can't fetch teams occupancy";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//★━━━━━━━━━━━━━━━━━★ Bookings ★━━━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const fetchAllBookings = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  if (!queryParams) {
    dispatch(actions.allBookingsFetched({ entities: null }));
  }

  return requestFromServer
    .getAllBookings(queryParams)
    .then((response) => {
      if (response) {
        return response.text();
      }
      return;
    })
    .then((result) => {
      if (!result) {
        dispatch(
          actions.allBookingsFetched({
            entities: null,
          })
        );
      }

      let data = allBookingsResultToGraphData({ result, queryParams });
      // console.log(data, "data actions");
      if (data !== undefined) {
        dispatch(actions.allBookingsFetched({ entities: data }));
      }
    })
    .catch((error) => {
      console.log("Fetch all bookings: ", error);
      error.clientMessage = "Can't fetch all bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
