// React bootstrap table next =>
// https://react-bootstrap-table.github.io/react-bootstrap-table2/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useRef, useState } from "react";
import { listEmployees, listNextEmployees, listPreviousEmployees, listLastEmployees, changeEmployeesData } from "../../../firebase";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import Edit from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { ROW_SELECT_DISABLED } from "react-bootstrap-table-next";

import Grid from '@material-ui/core/Grid';

import firebase from 'firebase/app';

import Dot from '@material-ui/icons/FiberManualRecord';
import yellow from '@material-ui/core/colors/yellow';
import Tooltip from '@material-ui/core/Tooltip';

import * as FirestoreService from '../../../firebase/index';

import { useSelector } from "react-redux"

import { firestore } from "../../../firebase"

import VisibilityIcon from '@material-ui/icons/Visibility';
import Popover from "@material-ui/core/Popover";


var r

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    textField: {
        //may get used
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 500,
    },
    paper: {
        position: 'absolute',
        maxWidth: "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "none",
    },
    button: {
        marginRight: theme.spacing(1),
    },
}));

function createData(time, clientMac, netName, realName, emailAddress, username, deviceName, deviceModel, optIn, trackDevice, state, time2) {
    return { time, clientMac, netName, realName, emailAddress, username, deviceName, deviceModel, optIn, trackDevice, state, time2 };
}

export function Employees() {

    const userClaims = useSelector(state => state.auth.claims)
    const customer = useSelector((state) => state.auth.claims?.customerId)
    const selectedCustomer = useSelector((state) => state.profile?.currentCustomer?.customerId)
    const superAdmin = useSelector((state) => state.auth.claims.isSuper)

    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [rows, setRows] = React.useState([]);

    const [search, setSearch] = React.useState("");
    const searchHandleChange = (event) => {
        setSearch(event.target.value);
    };
    const [searchField, setSearchField] = React.useState("user.username");
    const searchFieldHandleChange = (event) => {
        setSearchField(event.target.value);
    };

    useEffect(() => {
        fetchData()
    }, [rowsPerPage, search, searchField]);

    const [totalEmployees, setTotalEmployees] = React.useState(0);
    const [error, setError] = React.useState();

    useEffect(() => {
        const fetchData = async () => {
            await firestore
                .collection(`Customers/${userClaims.customerId}/Summary`)
                .doc("userImport")
                .onSnapshot((snapshot) => {
                    setTotalEmployees(snapshot.data())
                }, (error) => console.error(error));
        };
        fetchData()
    }, [setTotalEmployees]);

    async function fetchData() {
        r = await listEmployees(rowsPerPage, search, searchField, superAdmin ? selectedCustomer : customer)
        const rowss = []
        r.forEach(doc => {
            var seen
            if (doc.data().loc == null || doc.data().loc.time == null) {
                seen =
                    <Tooltip title="No Data" placement="right">
                        <Dot style={{ color: red[500] }} />
                    </Tooltip>
            } else if (doc.data().loc.time > firebase.firestore.Timestamp.fromMillis(Date.now() - 5 * 60000)) {
                seen =
                    <Tooltip title="Online" placement="right">
                        <Dot style={{ color: green[500] }} />
                    </Tooltip>
            } else {
                seen =
                    <Tooltip title="Offline" placement="right">
                        <Dot style={{ color: yellow[500] }} />
                    </Tooltip>
            }
            rowss.push(
                createData(
                    seen,
                    doc.id,
                    doc.data().netName,
                    doc.data().user.realName,
                    doc.data().user.emailAddress,
                    doc.data().user.username,
                    doc.data().user.deviceName,
                    doc.data().user.deviceModel,
                    doc.data().optIn,
                    doc.data().trackDevice,
                    doc.data().state,
                    doc.data().loc && doc.data().loc.time && doc.data().loc.time.toDate().toString()
                )
            )
        })
        setRows(rowss)
    }
    async function fetchData2() {
        const last = r.docs[r.docs.length - 1];
        r = await listNextEmployees(last, rowsPerPage, search, searchField, superAdmin ? selectedCustomer : customer)
        if (r.docs == "") {
            console.log("Erro")
        } else {
            console.log("Works")
        }
        const rowss = rows
        r.forEach(doc => {
            var seen
            if (doc.data().loc == null || doc.data().loc.time == null) {
                seen =
                    <Tooltip title="No Data" placement="right">
                        <Dot style={{ color: red[500] }} />
                    </Tooltip>
            } else if (doc.data().loc.time > firebase.firestore.Timestamp.fromMillis(Date.now() - 5 * 60000)) {
                seen =
                    <Tooltip title="Online" placement="right">
                        <Dot style={{ color: green[500] }} />
                    </Tooltip>
            } else {
                seen =
                    <Tooltip title="Offline" placement="right">
                        <Dot style={{ color: yellow[500] }} />
                    </Tooltip>
            }
            rowss.push(
                createData(
                    seen,
                    doc.id,
                    doc.data().netName,
                    doc.data().user.realName,
                    doc.data().user.emailAddress,
                    doc.data().user.username,
                    doc.data().user.deviceName,
                    doc.data().user.deviceModel,
                    doc.data().optIn,
                    doc.data().trackDevice,
                    doc.data().state,
                    doc.data().loc && doc.data().loc.time && doc.data().loc.time.toDate().toString()
                )
            )
        })
        setRows(rowss)
        //console.log(rowss)
    }
    async function fetchData3() {
        const first = r.docs[0];
        r = await listPreviousEmployees(first, rowsPerPage, search, searchField, superAdmin ? selectedCustomer : customer)
        if (r.docs == "") {
            console.log("Erro")
        } else {
            console.log("Works")
        }
        const rowss = []
        r.forEach(doc => {
            var seen
            if (doc.data().loc == null || doc.data().loc.time == null) {
                seen =
                    <Tooltip title="No Data" placement="right">
                        <Dot style={{ color: red[500] }} />
                    </Tooltip>
            } else if (doc.data().loc.time > firebase.firestore.Timestamp.fromMillis(Date.now() - 30 * 60000)) {
                seen =
                    <Tooltip title="Online" placement="right">
                        <Dot style={{ color: green[500] }} />
                    </Tooltip>
            } else {
                seen =
                    <Tooltip title="Offline" placement="right">
                        <Dot style={{ color: yellow[500] }} />
                    </Tooltip>
            }
            rowss.push(
                createData(
                    seen,
                    doc.id,
                    doc.data().netName,
                    doc.data().user.realName,
                    doc.data().user.emailAddress,
                    doc.data().user.username,
                    doc.data().user.deviceName,
                    doc.data().user.deviceModel,
                    doc.data().optIn,
                    doc.data().trackDevice,
                    doc.data().state,
                    doc.data().loc && doc.data().loc.time && doc.data().loc.time.toDate().toString()
                )
            )
        })
        setRows(rowss)
    }
    async function fetchData4() {
        r = await listLastEmployees(rowsPerPage, search, searchField, superAdmin ? selectedCustomer : customer)
        const rowss = []
        r.forEach(doc => {
            var seen
            if (doc.data().loc == null || doc.data().loc.time == null) {
                seen =
                    <Tooltip title="No Data" placement="right">
                        <Dot style={{ color: red[500] }} />
                    </Tooltip>
            } else if (doc.data().loc.time > firebase.firestore.Timestamp.fromMillis(Date.now() - 5 * 60000)) {
                seen =
                    <Tooltip title="Online" placement="right">
                        <Dot style={{ color: green[500] }} />
                    </Tooltip>
            } else {
                seen =
                    <Tooltip title="Offline" placement="right">
                        <Dot style={{ color: yellow[500] }} />
                    </Tooltip>
            }
            rowss.push(
                createData(
                    seen,
                    doc.id,
                    doc.data().netName,
                    doc.data().user.realName,
                    doc.data().user.emailAddress,
                    doc.data().user.username,
                    doc.data().user.deviceName,
                    doc.data().user.deviceModel,
                    doc.data().optIn,
                    doc.data().trackDevice,
                    doc.data().state,
                    doc.data().loc && doc.data().loc.time && doc.data().loc.time.toDate().toString()
                )
            )
        })
        setRows(rowss)
    }

    const [page, setPage] = React.useState(0);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };


    function TablePaginationActions(props) {
        const classes = useStyles();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (event) => {
            //fetchData()
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event) => {
            //fetchData3()
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = async (event) => {
            page >= Math.ceil(count / rowsPerPage) - 1 && await fetchData2()
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            //fetchData4()
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    //disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    //disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = (data) => {
        setOpen(true);
        console.log("Data: ", data)
        setModalData({
            clientMac: data.clientMac,
            realName: data.realName,
            emailAddress: data.emailAddress,
            username: data.username,
            deviceName: data.deviceName,
            deviceModel: data.deviceModel,
            optIn: data.optIn,
            trackDevice: data.trackDevice,
        })
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [modalData, setModalData] = React.useState({});

    const modalDataHandleChange = (event) => {
        setModalData({ ...modalData, [event.target.name]: event.target.value });
    };
    const modalDataHandleSwitchChange = (event) => {
        setModalData({ ...modalData, [event.target.name]: event.target.checked });
    };
    const handleModalSubmit = () => {
        console.log(modalData)
        changeEmployeesData(modalData)
        fetchData()
        setOpen(false)
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Grid container direction={"row"} spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        name="clientMac"
                        label="Mac Adress"
                        value={modalData.clientMac}
                        //onChange={searchHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="realName"
                        label="Real Name"
                        value={modalData.realName}
                        onChange={modalDataHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="emailAddress"
                        label="Email Address"
                        value={modalData.emailAddress}
                        onChange={modalDataHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="username"
                        label="Username"
                        value={modalData.username}
                        onChange={modalDataHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="deviceName"
                        label="Device name"
                        value={modalData.deviceName}
                        onChange={modalDataHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="deviceModel"
                        label="Device model"
                        value={modalData.deviceModel}
                        onChange={modalDataHandleChange}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={modalData.optIn}
                                onChange={modalDataHandleSwitchChange}
                                name="optIn"
                                color="secondary"
                            />
                        }
                        label="Opt In"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={modalData.trackDevice}
                                onChange={modalDataHandleSwitchChange}
                                name="trackDevice"
                                color="secondary"
                            />
                        }
                        label="Track Device"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleClose}>
                        Cancel
            </Button>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={handleModalSubmit}>
                        Submit
            </Button>
                </Grid>
            </Grid>
        </div >
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <TextField
                                    name="search"
                                    label="Search"
                                    value={search}
                                    onChange={searchHandleChange}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell colSpan={3}>
                                <FormControl variant="filled" className={classes.formControl} color="secondary" >
                                    <InputLabel id="demo-simple-select-filled-label">Search Field</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={searchField}
                                        onChange={searchFieldHandleChange}
                                    >
                                        <MenuItem value={"user.username"}>Username</MenuItem>
                                        <MenuItem value={"clientMac"}>Mac Adress</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell align="left">Mac Adress</TableCell>
                            <TableCell align="left">Office</TableCell>
                            <TableCell align="left">Real Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Username</TableCell>
                            <TableCell align="left">Device Name</TableCell>
                            <TableCell align="left">Device Model</TableCell>
                            <TableCell align="left">Opt In</TableCell>
                            <TableCell align="left">Track</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <TableRow key={row.clientMac}>
                                <TableCell style={{ width: 160 }} component="th" scope="row">
                                    {row.time}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.clientMac}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.netName}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.realName}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.emailAddress}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.username}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.deviceName}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.deviceModel}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.optIn ?
                                        (
                                            <span className={`label label-lg label-light-success label-inline`}>
                                                {"Opted In"}
                                            </span>
                                        ) : (
                                            <span className={`label label-lg label-light-danger label-inline`}>
                                                {"Not opted in"}
                                            </span>
                                        )
                                    }
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    {row.trackDevice ?
                                        (
                                            <span className={`label label-lg label-light-success label-inline`}>
                                                {"Track"}
                                            </span>
                                        ) : (
                                            <span className={`label label-lg label-light-danger label-inline`}>
                                                {"Don't track"}
                                            </span>
                                        )
                                    }
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left">
                                    <IconButton color="secondary" aria-label="Edit" component="span" onClick={() => handleOpen(row)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton aria-describedby={id} color="secondary" aria-label="Edit" component="span" onClick={handleClick}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <Popover
                                        id={id}
                                        open={openPop}
                                        anchorEl={anchorEl}
                                        onClose={handleClosePop}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                    >
                                        {(row.state) ? (
                                            <div>
                                                State: {row.state.im_state}
                                                <br></br>
                                                Emoji: {row.state.status_emoji}
                                                <br></br>
                                                Text: {row.state.status_text}
                                                <br></br>
                                                Update: {row.state.update.toDate().toString()}
                                                <br></br>
                                                Time: {row.time2}
                                            </div>
                                        ) : "No Data"}
                                    </Popover>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>

                                <span className={`label label-lg label-inline`}>
                                    Total employees: {totalEmployees && totalEmployees.numUsers}
                                </span>
                            </TableCell>
                        {
                        //TODO: On pages other than first it will open multiple popovers
                        }
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={11}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                //labelDisplayedRows={({ from, to, count }) => ""}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}


/*
    useEffect(() => {
        const unsubscribe = FirestoreService.streamTotalEmployees({
            next: querySnapshot => {
                console.log(querySnapshot.data())
                const updatedFloorPlanData =
                    querySnapshot.data()
                setTotalEmployees(updatedFloorPlanData);
            },
            error: () => setError('grocery-list-item-get-fail')
        });
        return unsubscribe;
    }, [setTotalEmployees]);
    */



/*if(r != undefined) {
    const rowss = []
    r.forEach(doc => {
        rowss.add(createData(doc.data().clientMac))
    })
    console.log(rows)
    console.log("----------------")
    console.log(rows2)
    //setRows(rows2)
    /*const rowss = [
    ].sort((a, b) => (a.calories < b.calories ? -1 : 1));*
    //setRows(rows2)
}*/