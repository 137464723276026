import { firestore } from "../../../firebase"

const customersRef = firestore.collection("Customers")

export function getFloorPlans(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  return customersRef
    .doc(queryParams.customerId)
    .collection(`FloorPlans`)
    .where("netId", "==", queryParams.locationId)
    .get()
}

export function getZones(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  return customersRef
    .doc(queryParams.customerId)
    .collection(`Zones`)
    .where("netId", "==", queryParams.locationId)
    .get()
}

export function getDepartments(queryParams) {
  if (!queryParams) return Promise.resolve(null)

  return Promise.all([
    customersRef
      .doc(queryParams.customerId)
      .collection(`Departments`)
      .where("manager", "==", queryParams.userId)
      .get(),
    customersRef
      .doc(queryParams.customerId)
      .collection(`Departments`)
      .where("employees", "array-contains", queryParams.userId)
      .get(),
  ])
}

export function getResources(queryParams) {
  if (!queryParams) {
    return Promise.resolve(null)
  }

  return customersRef
    .doc(queryParams.customer)
    .collection(`Resources`)
    .where("networkId", "==", queryParams.location)
    .get()
}
