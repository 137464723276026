import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import { OverviewChart } from "../../../wifiSnapshot/Floor/OverviewChart";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function OverviewCustom({ queryParams, showChart, setShowChart, hide }) {
  const [graph, setGraph] = useState();

  useEffect(() => {
    // console.log(group, "group");
    // console.log(customer, "customer");
    // console.log(queryParams, "UIProps.queryParams APsWorstSNR");
    // allDevicesSerial &&
    //   allDevicesSerial.length !== 0 &&
    //   queryParams &&
    //   queryParams.locations &&
    chart();
  }, [queryParams]);

  const chart = async () => {
    const res = await OverviewChart(queryParams);
    setGraph(res);
  };

  return (
    <Card>
      <CardHeader
        title='Overview - Floors'
        subtitle='Net failures & SNR'
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChart({
                  ...showChart,
                  OverviewCustom: false,
                })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      ></CardHeader>
      <CardBody style={{ height: "400px", overflow: "auto" }}>
        {graph && graph}
      </CardBody>
    </Card>
  );
}
