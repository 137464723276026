import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";
// import * as d3 from "d3";
// import { Chord } from "./initChord";
// import "./chordStyles.css";
// import ChordDiagram from "react-chord-diagram";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import ChordDiagram from "react-chord-diagram";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function ChordDependency() {
  const classes = useStyles();

  const { isLoading, zoneOccupancyForChord } = useSelector(
    (state) => ({
      isLoading: state.reporting.zoneOccupancyFetchedForChordLoading,
      zoneOccupancyForChord: state.reporting.zoneOccupancyForChord,
    }),
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //   useLayoutEffect(() => {
  //     Chord();
  //   });

  //   const [data] = useState([200, 250, 60, 150, 100, 175]);
  //   const svgRef = useRef();

  //   useEffect(() => {
  //     // setting up svg container
  //     const w = 400;
  //     const h = 300;
  //     const svg = d3
  //       .select(svgRef.current)
  //       .attr("width", w)
  //       .attr("height", h)
  //       .style("overflow", "visible")
  //       .style("margin-top", "75px");

  //     // setting the scaling
  //     const xScale = d3
  //       .scaleBand()
  //       .domain(data.map((val, i) => i))
  //       .range([0, w])
  //       .padding(0.5);

  //     const yScale = d3
  //       .scaleLinear()
  //       .domain([0, h])
  //       .range([h, 0]);

  //     // setting the axes
  //     const xAxis = d3.axisBottom(xScale).ticks(data.length);

  //     const yAxis = d3.axisLeft(yScale).ticks(5);

  //     svg
  //       .append("g")
  //       .call(xAxis)
  //       .attr("transform", `translate(0, ${h})`);

  //     svg.append("g").call(yAxis);

  //     // setting the svg data
  //     svg
  //       .selectAll(".bar")
  //       .data(data)
  //       .join("rect")
  //       .attr("x", (v, i) => xScale(i))
  //       .attr("y", yScale)
  //       .attr("width", xScale.bandwidth())
  //       .attr("height", (val) => h - yScale(val));
  //   }, [data]);

  // function getRandomColor() {
  //   var letters = "0123456789ABCDEF";
  //   var color = "#";
  //   for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }

  // const matrix = [
  //   [1, 0, 0, 2, 2, 2, 0.5, 0.5],
  //   [2, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5],
  //   [3, 0, 0, 0, 1, 0.5, 0.5, 1],
  //   [4, 0.1, 0, 0.0, 0, 0, 0, 0],
  //   [0.5, 2, 0.1, 0.0, 0, 0, 0, 0],
  //   [0.5, 2, 0.5, 0.0, 0, 0, 0, 0],
  //   [6, 2, 0.5, 0, 0, 0, 0, 0],
  //   [5, 3, 1, 0, 0, 0, 0, 0],
  // ];

  // const Departments = [
  //   "USA",
  //   "Europe",
  //   "Asia",
  //   "Gmail",
  //   "outlook",
  //   "other mail provider",
  //   "Cloud",
  //   "External drives",
  // ];

  const colors1 = [
    "#323389",
    "#0058A9",
    "#0077B8",
    "#0095BA",
    "#00B0B3",
    "#52C8AB",
    "#882e8c",
    "#c7297c",
    "#f1425f",
    "#ff713b",
    "#ffa600",
  ];

  // const ColorDepartments = zoneOccupancyForChord?.zones.map(getRandomColor);
  // const ColorDepartments = Departments.map(getRandomColor);

  // useEffect(() => {
  //   console.log(zoneOccupancyForChord, "zoneOccupancyForChord");
  // }, [zoneOccupancyForChord]);

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='People zone movements'
        //   subtitle="Daily number of people"
        toolbar={
          <>
            <Typography
              className='floorPlansBadZonesRSSI'
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "170%",
                textTransform: "none",
              }}
            >
              Floor Plans
            </Typography>
          </>
        }
      />
      <CardBody style={{ height: "450px" }}>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: "100%" }}
        >
          {zoneOccupancyForChord?.matrix &&
          zoneOccupancyForChord?.matrix.length !== 0 ? (
            <ChordDiagram
              matrix={zoneOccupancyForChord?.matrix || []}
              // matrix={matrix}
              componentId={3}
              width={450}
              height={450}
              style={{
                font: "12px Roboto, sans-serif",
                color: "#0000",
                padding: "1rem",
                letterSpacing: "0.1px",
                // border: "2px solid",
              }}
              groupLabels={zoneOccupancyForChord?.zones || []}
              // groupLabels={Departments}
              groupColors={colors1}
              // groupColors={ColorDepartments}
              persistHoverOnClick={true}
              outerRadius={130}
              innerRadius={115}
            />
          ) : (
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#3B4143",
              }}
            >
              No data to display.
            </p>
          )}
        </div>
        {/* <svg ref={svgRef} style={{ border: "2px solid green" }}></svg> */}

        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                fontWeight: "500",
              }}
            >
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (z, i) => <p key={i}>{z.zone}</p>
                )}
            </div>
            <div style={{ marginRight: "0.5rem", fontWeight: "500" }}>
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (f, i) => <p key={i}>- &nbsp;&nbsp;{f.floor}</p>
                )}
            </div>
            <div>
              {zoneOccupancyForChord &&
                zoneOccupancyForChord?.zonesWithFloorsAndLocations.map(
                  (l, i) => <p key={i}>- &nbsp;&nbsp;{l.location}</p>
                )}
            </div>
          </div>
        </Popover>
      </CardBody>
    </Card>
  );
}
