import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"

export default function LeftCard({ navigation, id, formData }) {
   return <div
      className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
      id="kt_profile_aside"
   >
      <div className="card card-custom card-stretch">
         <div className="card-body pt-4">
            <div className="d-flex align-items-center justify-content-between mb-2">
               <span className="font-weight-bold mr-2 mt-5 h3">Create customer</span>
            </div>
            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 0 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 0 && navigation.go("setupOrg")}
                     className={id === "setupOrg" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/Code/Compiling.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Setup Org
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 1 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 1 && navigation.go("secondStep")}
                     className={id === "secondStep" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/Communication/Shield-user.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Select location
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 2 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 2 && navigation.go("fourthStep")}
                     className={id === "fourthStep" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/General/User.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Choose SSIDs
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 3 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 3 && navigation.go("fifthStep")}
                     className={id === "fifthStep" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/General/User.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Assign levels
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 4 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 4 && navigation.go("sixthStep")}
                     className={id === "sixthStep" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/General/User.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Validator
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep >= 5 ? "100%" : "50%" }}
               >
                  <div
                     onClick={() => formData.completedStep >= 5 && navigation.go("sevenStep")}
                     className={id === "sevenStep" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/General/User.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Review
                     </span>
                  </div>
               </div>
               <div
                  className="navi-item mb-2"
                  style={{ opacity: formData.completedStep == -1 ? "100%" : "50%" }}
               >
                  <div
                     className={id === "validation" ? "navi-link py-4 active" : "navi-link py-4"}
                  >
                     <span className="navi-icon mr-2">
                        <span className="svg-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 "/media/svg/icons/General/User.svg"
                              )}
                           ></SVG>{" "}
                        </span>
                     </span>
                     <span className="navi-text font-size-lg">
                        Validation
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
}
