import React from "react"
import { DashboardPage } from "./pages/DashboardPage"
import { BookingPage } from "./modules/Booking/BookingPage"
import { ReportingPage } from "./modules/Reporting/ReportingPage"
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import DataUsageIcon from "@material-ui/icons/DataUsage"
import { shallowEqual, useSelector } from "react-redux"

export default function Pages() {
  const { selectedLocation, userClaims } = useSelector(
    state => ({
      selectedLocation: state.profile?.currentLocation,
      userClaims: state.auth.claims,
    }),
    shallowEqual
  )

  return [
    {
      name: "Dashboard",
      icon: <HomeOutlinedIcon />,
      path: "/dashboard",
      component: DashboardPage,
    },
    selectedLocation &&
      selectedLocation?.customerType === "office" && {
        name: "Booking",
        icon: <CalendarTodayIcon />,
        path: "/booking",
        component: BookingPage,
      },
    selectedLocation &&
      userClaims?.profileLvl >= 2 && {
        name: "Admin",
        icon: <CalendarTodayIcon />,
        path: "/admin",
        subpages: [
          {
            name: "Manage Customer",
            path: "/admin/manage-customer",
          },
          {
            name: "Design Studio",
            path: "/admin/design-studio",
          },
          {
            name: "Manage Users",
            path: "/admin/manage-users",
          },
          {
            name: "Manage BLE Devices",
            path: "/admin/manage-bles",
          },
          {
            name: "Manage  Things",
            path: "/admin/manage-things",
          },
          {
            name: "Contact Tracing",
            path: "/admin/contact-tracing",
          },
          {
            name: "Create Customer",
            path: "/admin/create-customer",
          },
          {
            name: "Visual Directory",
            path: "/admin/visual-directory",
          },
        ],
      },
    !selectedLocation &&
      userClaims?.profileLvl >= 2 && {
        name: "Admin",
        icon: <CalendarTodayIcon />,
        path: "/admin",
        subpages: [
          {
            name: "Manage Customer",
            path: "/admin/manage-customer",
          },
          {
            name: "Manage Users",
            path: "/admin/manage-users",
          },
          {
            name: "Create Customer",
            path: "/admin/create-customer",
          },
        ],
      },
    selectedLocation && {
      name: "Reporting",
      icon: <DataUsageIcon />,
      path: "/reporting",
      component: ReportingPage,
    },
  ].filter(val => val)
}
