import React from "react"
import { Header3 } from "../../../_partials/typography/Header3"
import { AllBookings } from "./AllBookings"
import { AverageTimeAtTheOffice } from "./AverageTimeAtTheOffice"

export function OfficeDays() {
   return <>
      <Header3 text={"Office days"} />
      <div className="row">
         <div
            className="col-12 col-xl-7 pt-4"
            style={{
               display: "flex",
               flexDirection: "column",
            }}
         >
            <AverageTimeAtTheOffice />
         </div>
         <div className="col-12 col-xl-5 pt-4">
            <AllBookings />
         </div>
      </div>
   </>
}