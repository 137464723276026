import React from "react"
import {
   makeStyles,
   TextField,
   FormHelperText,
   Button,
} from '@material-ui/core'
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
   textField: {
      marginTop: "2px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      margin: 0,
      '& .MuiOutlinedInput-notchedOutline': {
         borderColor: '#FFFFFF',
         borderRadius: "5px"
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
         borderColor: '#8C8CA1',
         borderRadius: "5px",
      },
   },
   textFieldDefaultSize: {
      '& .MuiInputBase-root': {
         height: "45px",
         padding: "0px 12px",
         display: "flex",
         alignItems: "center",
      },
      '& .MuiInputBase-input': {
         padding: "0",
      },
   },
   helperText: {
      margin: 0,
      color: theme.palette.error.main
   },
   textFieldWithAppend: {
      marginTop: "2px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      margin: 0,
      '& .MuiOutlinedInput-notchedOutline': {
         borderColor: '#FFFFFF',
         borderRadius: "5px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
         borderColor: '#8C8CA1',
         borderRadius: "5px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0
      },
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
   },
   button: {
      marginTop: "2px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      textTransform: "none",
      float: "right",
      backgroundColor: "#C3C4DD",
      color: "#323389",
      padding: "5px",
      '&:hover': {
         backgroundColor: '#CECEE3',
         color: "#323389",
      },
      '&:active': {
         backgroundColor: '#ADADD0',
         color: "#323389",
      },
   },
}))

export function Input({
   /* field: { name, value },
   form: { touched, errors, setFieldValue, setFieldTouched }, */
   field,
   form,
   label,
   withFeedbackLabel = true,
   appendButton,
   size,
   ...props
}) {
   if (field && form) {
      var { name, value } = field
      var { touched, errors, setFieldValue, setFieldTouched } = form
   }
   const classes = useStyles()
   const error = (withFeedbackLabel && touched && touched[name] && errors && errors[name])

   return <>
      {label && <b>{label}</b>}
      <div style={{ display: "flex", flexDirection: "row" }}>
         <TextField
            error={error ? true : false}
            fullWidth
            variant="outlined"
            size={size}
            //className={appendButton ? classes.textFieldWithAppend : classes.textField}
            className={clsx(classes.textField, !size && classes.textFieldDefaultSize, appendButton && classes.textFieldWithAppend)}
            name={name}
            value={(value || value === 0) ? value : ""}
            onBlur={() => {
               if (!touched && !setFieldTouched) { return }
               /*!touched[name] so it doesn't set touched true when it's already true*/
               /*Without this errors will always be one update behind the real value*/
               !touched[name] && setFieldTouched(name, true)
            }}
            onChange={e => {
               if (!touched && !setFieldTouched && !setFieldValue) { return }
               !touched[name] && setFieldTouched(name, true)
               setFieldValue(name, e.target.value)
            }}
            {...props}
         />
         {appendButton && <Button
            disableElevation
            variant="contained"
            className={classes.button}
            onClick={appendButton.onClick || null}
         >
            <b style={{
               width: "100%",
               overflow: "hidden",
               whiteSpace: "nowrap",
               textOverflow: "ellipsis",
            }}>{appendButton.text}</b>
         </Button>}
      </div>
      {error && <FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>}
   </>
}
