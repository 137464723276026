import React, { useMemo, useEffect } from "react";
import { useUIContext } from "../UIContext";
import { TeamsAtOffice } from "./TeamsAtOffice";
import { TeamsCompare } from "./TeamsCompare";
import { AllBookings } from "./AllBookings";

export function TeamsAndDepartmentsPage() {
  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
    };
  }, [UIContext]);

  //   useEffect(() => {
  //     UIProps.setQueryParams({
  //       ...UIProps.queryParams,
  //       peopleType: ["guests", "visitors"],
  //     });
  //   }, []);

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='card-stretch gutter-b pb-5'>
            <div
              style={{
                /*width: "40%",*/
                height: "17px",
                borderBottom: "1px solid black",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <span
                className='font-weight-bolder text-dark font-size-h2'
                style={{
                  backgroundColor: "#EDEFF7",
                  padding: "0 20px",
                }}
              >
                Bookings data
              </span>
            </div>
          </div>
        </div>
        <div className='col-lg-12 col-xxl-12'>
          <AllBookings />
        </div>
        <div className='col-12'>
          <div className='card-stretch gutter-b pb-5'>
            <div
              style={{
                height: "17px",
                borderBottom: "1px solid black",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <span
                className='font-weight-bolder text-dark font-size-h2'
                style={{
                  backgroundColor: "#EDEFF7",
                  padding: "0 20px",
                }}
              >
                Departments data
              </span>
            </div>
          </div>
        </div>
        <div className='col-lg-12 col-xxl-12'>
          <TeamsAtOffice />
        </div>
        {/* <div className='col-lg-12 col-xxl-12'>
          <TeamsCompare />
        </div> */}
      </div>
    </>
  );
}
