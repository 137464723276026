import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import * as turf from "@turf/turf";
import "@turf/angle";
import Chart from "react-apexcharts";
import moment from "moment";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Carousel } from "react-bootstrap";
import PopoverStickOnHover from "../PopoverStickOnHover";
import { Small } from "../../../../_partials/typography/Small";
import { MdImageNotSupported } from "react-icons/md";
import { Button } from "../../../../_partials/Button";
import { CSS2DRenderer, CSS2DObject } from "three-css2drender";
import { BsInfoLg } from "react-icons/bs";

export const addFloorPlanToMap = (params) => {
  const { map, floorPlans, selectedFloor } = params;

  if (!map || !floorPlans) return;

  var filter = floorPlans.find((data) => data.floorPlanId == selectedFloor);
  if (!filter) return;

  //━━━ Remove source and layer ━━━\\
  removeLayersSources(map);

  //━━━ Add image source and layer ━━━\\
  map.addSource("floorPlanSource", {
    type: "image",
    url: filter.ufindurl,
    coordinates: [
      [filter.topLeftCorner.lng, filter.topLeftCorner.lat],
      [filter.topRightCorner.lng, filter.topRightCorner.lat],
      [filter.bottomRightCorner.lng, filter.bottomRightCorner.lat],
      [filter.bottomLeftCorner.lng, filter.bottomLeftCorner.lat],
    ],
  });
  map.addLayer(
    {
      id: "floorPlanLayer",
      source: "floorPlanSource",
      type: "raster",
      paint: {
        "raster-opacity": 0.85,
      },
    },
    "settlement-label"
  );
};

export const mapFitBounds = (params) => {
  const { map, floorPlans, merakiImage, straightMap } = params;

  if (!map || !floorPlans) return;

  var floorPlan = floorPlans.find((data) => {
    return data.floorPlanId === params.selectedFloor;
  });
  if (!floorPlan) return;

  if (merakiImage && !straightMap) {
    // ↓ If exists is this floorPlan mapOrientation it displays orientation from db
    if (Boolean(floorPlan.mapOrientation) === true) {
      const lat = floorPlan.mapOrientation.center.latitude;
      const lng = floorPlan.mapOrientation.center.longitude;
      map.setZoom(floorPlan.mapOrientation.zoom);
      map.setBearing(floorPlan.mapOrientation.bearing);
      map.setPitch(floorPlan.mapOrientation.pitch);
      map.setCenter([lng, lat]);
    } else {
      map.fitBounds(
        [
          [floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
          [floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat],
        ],
        { linear: true }
      );
    }
  }

  if (merakiImage && straightMap) {
    var angle = turf.angle(
      [floorPlan.bottomLeftCorner.lat, floorPlan.bottomRightCorner.lng],
      [floorPlan.bottomLeftCorner.lat, floorPlan.bottomLeftCorner.lng],
      [floorPlan.bottomRightCorner.lat, floorPlan.bottomRightCorner.lng]
    );
    map.fitBounds(
      [
        [floorPlan.topRightCorner.lng, floorPlan.topRightCorner.lat],
        [floorPlan.bottomLeftCorner.lng, floorPlan.bottomLeftCorner.lat],
      ],
      { bearing: 0 - angle - 5, linear: true }
    );
  }
};

export const addMarkersToMap = (params) => {
  const {
    map,
    resources,
    selectedFloor,
    markers,
    setMarkers,
    openBookModel,
    setMapSelectedResource,
  } = params;

  if (!map || !resources) return;

  markers &&
    markers.forEach((m) => {
      m.remove();
    });

  function onClick(r) {
    setMapSelectedResource(r);
    openBookModel();
  }

  //Remove polygons from map
  resources.forEach((val) => {
    var layer = map.getLayer(val.id + "-layer");
    if (typeof layer !== "undefined") {
      map.removeLayer(val.id + "-layer");
    }
    var source = map.getSource(val.id + "-source");
    if (typeof source !== "undefined") {
      map.removeSource(val.id + "-source");
    }
    var lineLayer = map.getLayer(val.id + "-lineLayer");
    if (typeof lineLayer !== "undefined") {
      map.removeLayer(val.id + "-lineLayer");
    }
    var lineSource = map.getSource(val.id + "-lineSource");
    if (typeof lineSource !== "undefined") {
      map.removeSource(val.id + "-lineSource");
    }
  });

  const filteredResources = resources.filter(
    (val) => val.floorPlanId === selectedFloor
  );

  var mark = [];
  filteredResources.forEach((r) => {
    if (r.type === "meeting") {
      addMeetingRoomToMap({ ...params, r });
    }
    return;
    var layer = map.getLayer(r.id + "-layer");
    if (typeof layer !== "undefined") {
      map.removeLayer(r.id + "-layer");
    }
    if (r.floorPlanId !== selectedFloor) {
      return;
    }

    const dateBookings =
      params.bookings.filter((val) =>
        moment(val.date.toDate()).isSame(moment(params.date).startOf("day"))
      ) || [];

    const resourceBooking = dateBookings.filter((val) => val.resource === r.id);

    var booked = [];
    var free = [];
    resourceBooking.forEach((val, i) => {
      booked.push({
        x: " ",
        y: [
          moment(val.start.toDate()).format("x"),
          moment(val.end.toDate()).format("x"),
        ],
      });

      if (i == 0) {
        free.push({
          x: " ",
          y: [
            moment(val.start.toDate())
              .set({ hour: 0, minute: 0, second: 0 })
              .format("x"),
            moment(val.start.toDate()).format("x"),
          ],
        });
      }
      if (i == resourceBooking.length - 1) {
        i != 0 &&
          free.push({
            x: " ",
            y: [
              moment(val.start.toDate()).format("x"),
              moment(resourceBooking[i - 1].end.toDate()).format("x"),
            ],
          });
        free.push({
          x: " ",
          y: [
            moment(val.end.toDate()).format("x"),
            moment(val.end.toDate())
              .set({ hour: 24, minute: 0, second: 0 })
              .format("x"),
          ],
        });
      }
      if (i != 0 && i != resourceBooking.length - 1) {
        free.push({
          x: " ",
          y: [
            moment(val.start.toDate()).format("x"),
            moment(resourceBooking[i - 1].end.toDate()).format("x"),
          ],
        });
      }
    });

    resourceBooking.length == 0 &&
      free.push({
        x: " ",
        y: [
          moment(params.date)
            .startOf("day")
            .valueOf(),
          moment(params.date)
            .endOf("day")
            .valueOf(),
        ],
      });

    const markerDiv = document.createElement("div");
    // markerDiv.className = "deskBookingMarker"
    markerDiv.id = r.id;
    //markerDiv.style.backgroundImage = `url(/markers/deskMarker.svg)`

    ReactDOM.render(
      <PopoverStickOnHover
        component={
          <div className='bookingPopoverCard'>
            <div className='row'>
              <div className='col-5'>
                {r.imagesUrls?.length > 0 ? (
                  <Carousel indicators={false}>
                    {r.imagesUrls.map((arrayUrl, i) => {
                      return (
                        <Carousel.Item key={i} interval={400}>
                          <img
                            className='d-block w-100'
                            src={arrayUrl}
                            alt={r.type}
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              objectFit: "cover",
                              objectPosition: "50% 50%",
                            }}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                ) : (
                  <MdImageNotSupported
                    style={{
                      height: "80px",
                      width: "100%",
                      padding: "25px",
                      borderRadius: "10px",
                      backgroundColor: "#dde3ed",
                    }}
                  />
                )}
              </div>
              <div className='d-flex col pl-0'>
                <div className='row'>
                  <div className='col-12'>
                    <h5 style={{ paddingBottom: "5px" }}>
                      <b>{r.name}</b>
                    </h5>
                  </div>
                  <div className='col-12 align-self-end'>
                    <Button
                      text='ADD BOOKING'
                      size='sm'
                      style={{ width: "100%" }}
                      onClick={() => {
                        params.setMapSelectedResource(r);
                        params.openBookModel();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-3'>
              <Small text='Bookings' />
              <Chart
                options={getChartOptions(params.date)}
                series={[
                  {
                    name: "Booked",
                    data: booked,
                  },
                  {
                    name: "Free",
                    data: free,
                  },
                ]}
                type='rangeBar'
                height={70}
              />
            </div>
          </div>
        }
        placement='top-start'
        delay={200}
      >
        <img
          className='deskBookingMarkerText'
          onClick={() => onClick(r)}
          src={"/markers/deskMarker.png"}
        >
          {/* {r.type[0].toUpperCase()} */}
        </img>
      </PopoverStickOnHover>,
      markerDiv
    );

    const marker = new mapboxgl.Marker(markerDiv)
      .setLngLat([r.coords.longitude, r.coords.latitude])
      .addTo(map);

    mark.push(marker);
  });
  setMarkers(mark);
};

function addMeetingRoomToMap(params) {
  const {
    map,
    resources,
    selectedFloor,
    markers,
    setMarkers,
    openBookModel,
    setMapSelectedResource,
    r,
    zones,
  } = params;

  function onClick(r) {
    setMapSelectedResource(r);
    openBookModel();
  }

  const zone = zones.find((val) => val.id === r.zone);

  if (!zone?.polygon) {
    return;
  }

  const geoJson = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [zone.polygon.map((val) => [val.longitude, val.latitude])],
    },
  };
  map.addSource(r.id + "-source", {
    type: "geojson",
    data: geoJson,
  });

  map.addLayer(
    {
      id: r.id + "-layer",
      source: r.id + "-source",
      type: "fill",
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-color": zone.color,
        "fill-outline-color": "#969696",
        "fill-opacity": 0.6,
      },
    },
    "state-label"
  );

  map.addSource(r.id + "-lineSource", {
    type: "geojson",
    data: geoJson,
  });
  map.addLayer(
    {
      id: r.id + "-lineLayer",
      source: r.id + "-lineSource",
      type: "line",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": "#969696",
        "line-width": 2,
      },
    },
    "state-label"
  );
}

export const add3DDesksToMap = (params) => {
  const {
    map,
    resources,
    selectedFloor,
    markers,
    setMarkers,
    openBookModel,
    setMapSelectedResource,
    bookings,
    date,
  } = params;

  if (!map || !resources) return;

  markers &&
    markers.forEach((m) => {
      m.remove();
    });

  resources.forEach((r) => {
    if (r.type !== "desk") {
      return;
    }
    var layer = map.getLayer(r.id + "-layer");
    if (typeof layer !== "undefined") {
      map.removeLayer(r.id + "-layer");
    }
    if (r.floorPlanId === selectedFloor) {
      const origin = [r.coords.longitude, r.coords.latitude];
      const rotationFromDB = r.rotation ?? 0;

      map.addLayer(
        createCustomLayer(
          r.id + "-layer",
          origin,
          rotationFromDB,
          r,
          bookings,
          date,
          setMapSelectedResource,
          openBookModel
        ),
        "country-label"
      );
      // addLayerFunction(r.id + "-layer", origin, i, rotationFromDB)
      //allDesksOnMap.push(r.id)
    }
  });
};

function createCustomLayer(
  layerId,
  origin,
  rotationFromDB,
  resource,
  bookings,
  date,
  setMapSelectedResource,
  openBookModel
) {
  // console.log(rotationFromDB, "rotationFromDBs createCustom")
  //create the layer
  let customLayer3D = {
    id: layerId,
    type: "custom",
    renderingMode: "3d",
    onAdd: function(map, gl) {
      addModel(
        layerId,
        origin,
        rotationFromDB,
        resource,
        bookings,
        date,
        setMapSelectedResource,
        openBookModel
      );
      // we can add threejs code here
    },
    render: function(gl, matrix) {
      //tb.update() is not needed anymore if multiLayer : true
    },
  };
  return customLayer3D;
}

function addModel(
  layerId,
  origin,
  rotationFromDB,
  resource,
  bookings,
  date,
  setMapSelectedResource,
  openBookModel
) {
  // console.log(rotationFromDB, "rotationFromDBs addModel")

  let options = {
    type: "gltf", // ← 'gltf'/'mtl'
    obj: "/uploads_files_2725790_Desk.glb",
    bin: "", // ← replace by mtl attribute
    units: "meters", // ← units in the default values are always in meters
    scale: { x: 0.5, y: 0.5, z: 0.5 }, // ← size of the model
    rotation: { x: 90, y: 0, z: 0 }, // ← default rotation
    anchor: "center", // ← centering the obj
    clone: false, // ← this is necessary so when deleting obj, it won't get any resource from deleted obj
    bbox: false,
  };
  window.tb.loadObj(options, function(model) {
    // console.log(model, "model")
    model.setCoords(origin);
    model.setRotation({ x: 0, y: 0, z: rotationFromDB });
    // const deskColorNew = 0xfe690f
    // const deskColorSaved = 0x5d6666
    // // console.log(!resource.floorPlanId)
    // model.color = !resource.floorPlanId ? deskColorNew : deskColorSaved
    // //let l = map.getLayer(layerId)
    const deskColorSaved = 0x0faae0;
    model.color = deskColorSaved;

    model.addEventListener(
      "ObjectMouseOver",
      (e) => {
        //console.log("Event Over: ", e.detail)
        //console.log("Event Over: ", e.detail.getSize())
        if (e.detail.label) {
          return;
        }

        //Get all desk layers
        const layers = document.querySelectorAll("[class$=-layer]");
        // console.log("Layers: ", layers)
        // If any of them has a className "over" return because there is already a popup being shown
        if (layers && Array.from(layers).find((val) => val.id === "over")) {
          return;
        }

        let divToolTip = drawTooltip(
          resource,
          <div></div>,
          bookings,
          date,
          setMapSelectedResource,
          openBookModel
        );
        model.addLabel(divToolTip, true, model.anchor, 2);
        // model.label.element.style.border = "2px green solid"
        model.label.element.style.position = "relative";
        // model.label.element.style.bottom =
        //   resource.imagesUrls?.length !== 0 ? "10rem" : "8rem" // ← Popup moves up or down
        model.label.element.style.bottom = "9.1rem"; // ← height of the popup
        model.label.element.style.padding = "0.7rem 0.2rem 0.7rem 0.2rem";
        model.label.element.style.width = "220px";
        model.label.element.style.borderRadius = "8px 8px 8px 8px ";
        model.label.element.style.zIndex = "2";
        model.label.element.style.backgroundColor = "#ECF1F4";
        model.label.element.style.cursor = "pointer";
        model.label.element.className = layerId;

        let divToolTip2 = drawTooltip2(
          resource,
          bookings,
          date,
          setMapSelectedResource,
          openBookModel
        );
        model.label.element.children[0].appendChild(divToolTip2);

        // console.log("label: ", model.label)
        /* model.label.element.children[0].onclick = () => {
        console.log("Pew!")
      } */

        //To make zone markers be behind desk modal
        document.getElementById("labelCanvas").style.zIndex = 1;

        model.label.element.onmouseenter = () => {
          model.label.element.id = "over";
          // console.log("Popup mouse enter")
        };
        model.label.element.onmouseleave = () => {
          model.label.element.id = "";
          // console.log("Popup mouse out")
          model.removeLabel();
          // setTimeout(() => {
          //   model.removeLabel() // ← remove label após mouse leave popup div
          // }, 250)
        };
      },
      false
    );

    model.addEventListener(
      "ObjectMouseOut",
      (e) => {
        // console.log("Event Out: ", e.detail.label)
        if (e.detail.label && e.detail.label.element.id !== "over") {
          // console.log("Desk mouse out")
          e.detail.removeLabel();
          // setTimeout(() => {
          //   if (e.detail.label && e.detail.label.element.id !== "over") {
          //     //document.getElementById("labelCanvas").style.zIndex = 0
          //     e.detail.removeLabel() // ← remove label após mouse leave desk model
          //   }
          // }, 250)
        }
      },
      false
    );
    // model.castShadow = true
    // model.receiveShadow = true
    // window.tb.lights.dirLight.target = model
    // model.color = "#ffffff"

    // let divToolTip = drawTooltip(resource, <div></div>, bookings, date)
    // /* ReactDOM.render(
    //   <div>Test</div>, divToolTip
    // ) */
    // let options2 = {
    //   htmlElement: divToolTip,
    //   alwaysVisible: true,
    //   topMargin: -10.5
    // }
    // model.addLabel(divToolTip, false, model.anchor, 2)
    // model.label.visibility  = false
    //console.log("model: ", model)

    //model.addTooltip("Glacier d'Argentière", true)

    window.tb.add(model, layerId);
  });

  /* let divToolTip = document.createElement('div')
  ReactDOM.render(
    <div>Test</div>, divToolTip
  )
  let options2 = {
    htmlElement: "divToolTip",
    alwaysVisible: true
  }
  const labelObj = window.tb.label(options2)
  labelObj.setCoords(origin)
  console.log("labelObj: ", labelObj)
  window.tb.add(labelObj) */
}

function drawTooltip(
  resource,
  bookings,
  date,
  setMapSelectedResource,
  openBookModel
) {
  /* let divToolTip = document.createElement('div')
  divToolTip.style.border = "1px black solid" */

  let divToolTip = document.createElement("div");
  //divToolTip.innerHTML = "awdawdawdawdadadw"
  // divToolTip.style.border = "5px pink solid"
  //divToolTip.addEventListener('click', () => console.log("Test") , false)
  //divToolTip.onclick = function () { console.log("Test") }
  /* ReactDOM.render(
    <button>awdawdawd</button>,
    divToolTip
  ) */
  /* console.log("Resource: ", resource)
  
  ReactDOM.render(
    <div style={{ border: "1px red solid" }}>
      <div className="DeskPopover">
        <div className='bookingPopoverCard'>
        </div>
      </div>
    </div>,
    divToolTip
  ) */

  return divToolTip;
}

function drawTooltip2(
  resource,
  bookings,
  date,
  setMapSelectedResource,
  openBookModel
) {
  let divToolTip = document.createElement("div");
  // divToolTip.style.border = "4px red solid"
  divToolTip.style.position = "relative";

  const booked = [
    {
      x: " ",
      y: ["1647356400557", "1647360000557"],
    },
  ];

  const free = [
    {
      x: " ",
      y: ["1647302400557", "1647356400557"],
    },
    {
      x: " ",
      y: ["1647360000557", "1647388800557"],
    },
  ];

  // console.log("Resource: ", resource)

  ReactDOM.render(
    <div>
      <div
      // className='DeskPopover'
      >
        <div className='bookingPopoverCard'>
          <div className='row'>
            <div className='col-6'>
              {resource.imagesUrls?.length > 0 ? (
                <Carousel indicators={false}>
                  {resource.imagesUrls.map((url, i) => {
                    {
                      /* console.log("Url: ", url) */
                    }
                    return (
                      <Carousel.Item key={i} interval={400}>
                        <img
                          className='d-block w-100'
                          src={url}
                          alt={resource.type}
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            objectFit: "cover",
                            objectPosition: "50% 50%",
                          }}
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              ) : (
                <MdImageNotSupported
                  style={{
                    height: "80px",
                    width: "100%",
                    padding: "25px",
                    borderRadius: "10px",
                    backgroundColor: "#dde3ed",
                  }}
                />
              )}
            </div>
            <div className='d-flex col pl-0'>
              <div className='row'>
                <div className='col-12'>
                  <h5 style={{ paddingBottom: "5px" }}>
                    <b>{resource.name}</b>
                  </h5>
                </div>
                <div className='col-12 align-self-end'>
                  {/* <Button
                    text='ADD BOOKING'
                    size='sm'
                    style={{ width: "100%", height: "3rem" }}
                    onClick={() => {
                      console.log("Test booking")
                      setMapSelectedResource(resource)
                      openBookModel()
                    }}
                  /> */}
                  <button
                    className='addBookingBtn'
                    onClick={() => {
                      console.log("Test booking");
                      setMapSelectedResource(resource);
                      openBookModel();
                    }}
                  >
                    ADD BOOKING
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-3'>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginBottom: "-5px",
              }}
            >
              Bookings
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Chart
                options={getChartOptions(date)}
                series={getGraphData({ bookings, date, resource })}
                // series={[
                //   {
                //     name: "Booked",
                //     data: booked,
                //   },
                //   {
                //     name: "Free",
                //     data: free,
                //   },
                // ]}
                type='rangeBar'
                height={70}
                width={195}
              />
            </div>
          </div>
          <div className='invisibleSpaceToKeepPopupOpen' />
          <div className='triangleDivBooking'>
            <div className='trianglePopupDesksBooking' />
          </div>
        </div>
      </div>
    </div>,
    divToolTip
  );
  return divToolTip;
}

export const add3DDesksToMap2 = (params) => {
  //https://www.cgtrader.com/items/2725790/download-page
  const {
    map,
    resources,
    selectedFloor,
    markers,
    setMarkers,
    openBookModel,
    setMapSelectedResource,
    bookings,
    date,
  } = params;

  if (!map || !resources) return;

  markers &&
    markers.forEach((m) => {
      m.remove();
    });

  resources.forEach((r) => {
    if (r.type !== "desk") {
      return;
    }
    var layer = map.getLayer(r.id + "-layer");
    if (typeof layer !== "undefined") {
      map.removeLayer(r.id + "-layer");
    }
    if (r.floorPlanId == selectedFloor) {
      // parameters to ensure the model is georeferenced correctly on the map
      var modelOrigin = [r.coords.longitude, r.coords.latitude];
      var modelAltitude = 0;
      var modelRotate = [Math.PI / 2, 0, 0];

      var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
      );
      console.log("Rotations: ", r.rotation * (Math.PI / 180));
      // transformation parameters to position, rotate and scale the 3D model onto the map
      var modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: r.rotation ? r.rotation * (Math.PI / 180) : 0,
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };

      //var THREE = window.THREE

      // configuration of the custom layer for a 3D model per the CustomLayerInterface
      var customLayer = {
        id: r.id + "-layer",
        type: "custom",
        renderingMode: "3d",
        onAdd: function(map, gl) {
          this.camera = new THREE.PerspectiveCamera(
            28,
            window.innerWidth / window.innerHeight,
            0.1,
            1e6
          );
          this.scene = new THREE.Scene();

          // create two three.js lights to illuminate the model
          var directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -70, 100).normalize();
          this.scene.add(directionalLight);

          var directionalLight2 = new THREE.DirectionalLight(0xffffff);
          directionalLight2.position.set(0, 70, 100).normalize();
          this.scene.add(directionalLight2);

          // use the three.js GLTF loader to add the 3D model to the three.js scene
          /* var loader = new GLTFLoader()
               loader.load("./forTestingOnly.glb",
                  function (gltf) {
                     this.scene.add(gltf.scene)
                  }.bind(this)
               ) */
          var loader = new GLTFLoader();
          loader.load(
            "./uploads_files_2725790_Desk.glb",
            //"./DeskWithFourLegs1m.glb",
            function(object) {
              //console.log(object)
              object.scene.children[0].name = r.id + "-layer";
              this.scene.add(object.scene);
            }.bind(this)
          );

          this.map = map;

          // use the Mapbox GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          });

          this.renderer.autoClear = false;
          //this.scene.scale.set(0.05, 0.05, 0.05)
          this.scene.scale.set(0.5, 0.5, 0.5);

          this.raycaster = new THREE.Raycaster();
          this.raycaster.near = -1;
          this.raycaster.far = 1e6;

          this.popupRenderer = new CSS2DRenderer();
          this.popupRenderer.setSize(
            this.map.getCanvas().clientWidth,
            this.map.getCanvas().clientHeight
          );
          this.popupRenderer.domElement.style.position = "absolute";
          this.popupRenderer.domElement.style.display = "none";
          this.popupRenderer.domElement.id = r.id + "-layer"; //TODO: this value must come by parameter
          this.popupRenderer.domElement.style.top = 0;

          this.map
            .getCanvasContainer()
            .appendChild(this.popupRenderer.domElement);
          this.divToolTip = drawTooltip(
            r,
            this.popupRenderer.domElement,
            bookings,
            date
          );
          console.log("divToolTip: ", this.divToolTip);
          // this.divToolTip.onmouseover = () => this.popupRenderer.domElement.className = "over"
          // this.divToolTip.onmouseout = () => this.popupRenderer.domElement.className = ""
          this.divToolTip.style.border = "1px transparent solid";
          this.divToolTip.style.padding = "5px";
          this.popupAlt = new CSS2DObject(this.divToolTip);
          this.popupAlt.position.set(0, 1.5, 0);

          //!https://stackoverflow.com/questions/54876630/how-to-make-css2drenderer-labels-behaving-like-spritematerial-for-scaled-objects
          //!Read this for scaling
          //popupAlt.rotation.set(modelRotate[0], r.rotation ? r.rotation * (Math.PI / 180) : 0, modelRotate[2])

          this.scene.add(this.popupAlt);
        },
        render: function(gl, matrix) {
          var rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          var rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          var rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );

          var m = new THREE.Matrix4().fromArray(matrix);
          var l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);

          /* this.map.getContainer().addEventListener('click', (e) => {
            onClick(e, this.camera, this.scene)
          }, false) */
          /* this.renderer.domElement.addEventListener('click', (e) => {
            onClick(e, this.camera, this.scene)
          }, false) */

          this.popupRenderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        },

        raycast(point, isClick) {
          var mouse = new THREE.Vector2();
          // // scale mouse pixel position to a percentage of the screen's width and height
          mouse.x = (point.x / this.map.transform.width) * 2 - 1;
          mouse.y = 1 - (point.y / this.map.transform.height) * 2;

          const camInverseProjection = new THREE.Matrix4()
            .copy(this.camera.projectionMatrix)
            .invert();
          const cameraPosition = new THREE.Vector3().applyMatrix4(
            camInverseProjection
          );
          const mousePosition = new THREE.Vector3(
            mouse.x,
            mouse.y,
            1
          ).applyMatrix4(camInverseProjection);
          const viewDirection = mousePosition
            .clone()
            .sub(cameraPosition)
            .normalize();

          /*  console.log("Camera: ", cameraPosition)
           // Create a Vec3 that holds the label's position
           var start = new THREE.Vector3()
           start.copy(this.popupAlt.position)
 
           // Create a var that holds the distance to the camera
           var dist = start.distanceTo(cameraPosition)
 
           // Apply falloff, then turn that into the label's scale
           var size = 1 / dist
           console.log("Size: ", size)
           this.divToolTip.children[0].style.transform = `scale(${size * 2})`
           this.divToolTip.children[0].style.margin = 0
           //console.log("Test: ", this.divToolTip) */

          this.raycaster.set(cameraPosition, viewDirection);

          // calculate objects intersecting the picking ray
          var intersects = this.raycaster.intersectObjects(
            this.scene.children,
            true
          );

          return { intersects, id: r.id + "-layer" };
          /* $('#info').empty()
          if (intersects.length) {
            for (let i = 0 i < intersects.length ++i) {
              $('#info').append(' ' + JSON.stringify(intersects[i].distance))
              isClick && console.log(intersects[i])
            }

            isClick && $('#info').append('')
          } */
        },

        onZoom() {
          const camInverseProjection = new THREE.Matrix4()
            .copy(this.camera.projectionMatrix)
            .invert();
          const cameraPosition = new THREE.Vector3().applyMatrix4(
            camInverseProjection
          );

          //console.log("Camera: ", cameraPosition)
          // Create a Vec3 that holds the label's position
          var start = new THREE.Vector3();
          start.copy(this.popupAlt.position);

          // Create a var that holds the distance to the camera
          var dist = start.distanceTo(cameraPosition);

          // Apply falloff, then turn that into the label's scale
          var size = 1 / dist;
          console.log("Dist: ", dist);
          console.log("Size: ", size);
          this.divToolTip.children[0].style.transform = `scale(${size * 3})`;

          return;
          /* $('#info').empty()
          if (intersects.length) {
            for (let i = 0 i < intersects.length ++i) {
              $('#info').append(' ' + JSON.stringify(intersects[i].distance))
              isClick && console.log(intersects[i])
            }

            isClick && $('#info').append('')
          } */
        },
      };
      //? https://discourse.threejs.org/t/raycaster-results-in-a-empty-array-in-mapbox/14970 ?\\
      //? https://stackoverflow.com/questions/59163141/raycast-in-three-js-with-only-a-projection-matrix/61642776#61642776 ?\\
      //? https://jsfiddle.net/5vpL7ays/7/ ?\\
      map.addLayer(customLayer);
      map.on("mousemove", (e) => {
        //Get all desk layers
        const layers = document.querySelectorAll("[id$=-layer]");
        //If any of them has a className "over" return because there is already a popup being shown
        if (
          layers &&
          Array.from(layers).find((val) => val.className === "over")
        ) {
          return;
        }

        //Call raycast to get models intersected by current mouse position
        const value = customLayer.raycast(e.point, false);
        //If any model is intersected display popup for the first model intersected
        //Else hide popup
        if (value.intersects.length > 0) {
          document.getElementById(
            value.intersects[0]?.object.name
          ).style.display = "block";
        } else {
          document.getElementById(value.id).style.display = "none";
        }
      });
      map.on("zoom", () => {
        customLayer.onZoom();
      });
      /* const marker1 = new mapboxgl.Marker()
        .setLngLat([r.coords.longitude, r.coords.latitude])
        .addTo(map) */
    }
  });
};

function onClick(event, camera, scene) {
  var raycaster = new THREE.Raycaster();
  var mouse = new THREE.Vector2();

  console.log("Event: ", event);

  event.preventDefault();

  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  console.log(window.innerWidth);
  console.log(mouse.y);

  raycaster.setFromCamera(mouse, camera);

  var intersects = raycaster.intersectObject(scene, true);

  if (intersects.length > 0) {
    console.log("Intersect: ", intersects[0].object);

    var object = intersects[0].object;

    object.material.color.set(Math.random() * 0xffffff);
  }

  //render()
}

/* function render() {

  renderer.render(scene, camera)

} */

export const changeMeetingPopup2 = (params) => {
  const { map, resource, bookings, date } = params;
  // Create a popup, but don't add it to the map yet.
  const popup = new mapboxgl.Popup({
    closeButton: true,
    closeOnClick: true,
  });
  map.on("mouseenter", resource.id + "-layer", () => {
    console.log("???: ", map.getCanvas().style.cursor);
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", resource.id + "-layer", () => {
    console.log("?: ", window.tb);
    map.getCanvas().style.cursor = "";
  });

  map.on("click", resource.id + "-layer", (e) => {
    // Copy coordinates array.
    const coordinates = [e.lngLat.lng, e.lngLat.lat];

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popupDiv = document.createElement("div");
    //popupDiv.id = "bookingPopover"
    popupDiv.id = resource.id;

    ReactDOM.render(
      <div className='bookingPopoverCard'>
        <div className='row'>
          <div className='col-5'>
            {resource.imagesUrls ? (
              <Carousel indicators={false}>
                {resource.imagesUrls.map((arrayUrl, i) => {
                  return (
                    <Carousel.Item key={i} interval={400}>
                      <img
                        className='d-block w-100'
                        src={arrayUrl}
                        alt={resource.type}
                        style={{
                          borderRadius: "10px",
                          height: "80px",
                          objectFit: "cover",
                          objectPosition: "50% 50%",
                        }}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            ) : (
              <MdImageNotSupported
                style={{
                  height: "80px",
                  width: "100%",
                  padding: "25px",
                  borderRadius: "10px",
                  backgroundColor: "#dde3ed",
                }}
              />
            )}
          </div>
          <div className='d-flex col pl-0'>
            <div className='row'>
              <div className='col-12'>
                <h5 style={{ paddingBottom: "5px" }}>
                  <b>{resource.name}</b>
                </h5>
              </div>
              <div
                className='col-12 align-self-end'
                /* style={{
                    position: "relative",
                    height: "100%",
                 }} */
              >
                <Button
                  text='ADD BOOKING'
                  size='sm'
                  style={{ width: "100%" }}
                  onClick={() => {
                    params.setMapSelectedResource(resource);
                    params.openBookModel();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='pt-3'>
          <Small text='Bookings' />
          <Chart
            options={getChartOptions(params.date)}
            series={getGraphData({ bookings, date, resource })}
            type='rangeBar'
            height={70}
          />
        </div>
      </div>,
      popupDiv
    );
    // Populate the popup and set its coordinates
    // based on the feature found.
    popup
      .setLngLat(coordinates)
      .setDOMContent(popupDiv)
      .addTo(map);
  });
};

export function addMeetingRoomZones(params) {
  const { map, zones, resources, selectedLevel } = params;

  resources.forEach((resource) => {
    var layer = map.getLayer(resource.id + "-layer");
    if (typeof layer !== "undefined") {
      map.removeLayer(resource.id + "-layer");
    }
    var source = map.getSource(resource.id + "-source");
    if (typeof source !== "undefined") {
      map.removeSource(resource.id + "-source");
    }
    var lineLayer = map.getLayer(resource.id + "-lineLayer");
    if (typeof lineLayer !== "undefined") {
      map.removeLayer(resource.id + "-lineLayer");
    }
    var lineSource = map.getSource(resource.id + "-lineSource");
    if (typeof lineSource !== "undefined") {
      map.removeSource(resource.id + "-lineSource");
    }

    if (resource.floorPlanId !== selectedLevel || resource.type !== "meeting") {
      return;
    }

    const zone = zones.find((val) => val.id === resource.zone);

    if (!zone?.polygon) {
      return;
    }

    const geoJson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [zone.polygon.map((val) => [val.longitude, val.latitude])],
      },
    };
    map.addSource(resource.id + "-source", {
      type: "geojson",
      data: geoJson,
    });

    map.addLayer(
      {
        id: resource.id + "-layer",
        source: resource.id + "-source",
        type: "fill",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": zone.color,
          "fill-outline-color": "#969696",
          "fill-opacity": 0.6,
        },
      },
      "state-label"
    );

    map.addSource(resource.id + "-lineSource", {
      type: "geojson",
      data: geoJson,
    });
    map.addLayer(
      {
        id: resource.id + "-lineLayer",
        source: resource.id + "-lineSource",
        type: "line",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "#969696",
          "line-width": 2,
        },
      },
      "state-label"
    );
  });
}

export const addMeetingRoomMarkers = (params) => {
  const {
    map,
    zones,
    resources,
    bookings,
    date,
    openBookModal,
    setMapSelectedResource,
  } = params;

  var meetingRoomMarkers = [];
  resources.forEach((resource) => {
    const zone = zones.find((zone) => zone.id === resource?.zone);

    if (!zone?.marker) {
      return;
    }

    const el = document.createElement("div");
    el.className = "zoneMarkersBooking";

    ReactDOM.render(
      <PopoverStickOnHover
        component={
          <div className='popoverCardBookingMeeting'>
            <div className='row'>
              <div className='col-6'>
                {zone.imagesUrls?.length > 0 ? (
                  <Carousel indicators={false}>
                    {zone.imagesUrls.map((url, i) => {
                      return (
                        <Carousel.Item key={i} interval={1500}>
                          <img
                            className='d-block w-100'
                            src={url}
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              objectFit: "cover",
                              objectPosition: "50% 50%",
                            }}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                ) : (
                  <MdImageNotSupported
                    style={{
                      height: "80px",
                      width: "100%",
                      padding: "25px",
                      borderRadius: "10px",
                      backgroundColor: "#dde3ed",
                    }}
                  />
                )}
              </div>
              <div className='d-flex col pl-0'>
                <div className='row'>
                  <div className='col-12'>
                    <h5 style={{ paddingBottom: "5px" }}>
                      <b>{resource.name}</b>
                    </h5>
                  </div>
                  <div className='col-12 align-self-end'>
                    <button
                      className='addBookingBtn'
                      onClick={() => {
                        setMapSelectedResource(resource);
                        openBookModal();
                      }}
                    >
                      ADD BOOKING
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-3'>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  marginBottom: "-5px",
                }}
              >
                Bookings
              </p>
              <div style={{ width: "100%" }}>
                {" "}
                <Chart
                  options={getChartOptions2(date)}
                  series={getGraphData({ bookings, date, resource })}
                  type='rangeBar'
                  height={70}
                />
              </div>
            </div>
          </div>
        }
        placement='top'
        delay={200}
      >
        <div className='markerZoneDivBooking'>
          {" "}
          <BsInfoLg fontSize={14} />
        </div>
      </PopoverStickOnHover>,
      el
    );

    const marker = new mapboxgl.Marker(el)
      .setLngLat([zone.marker.longitude, zone.marker.latitude])
      .addTo(map);
    meetingRoomMarkers.push(marker);
  });

  return meetingRoomMarkers;
};

function getGraphData({ bookings = [], date, resource }) {
  const dateBookings = bookings.filter((val) =>
    moment(val.date.toDate()).isSame(moment(date).startOf("day"))
  );

  const resourceBooking = dateBookings.filter(
    (val) => val.resource === resource.id
  );

  var booked = [];
  var free = [];

  resourceBooking.forEach((val, i) => {
    booked.push({
      x: " ",
      y: [
        moment(val.start.toDate()).format("x"),
        moment(val.end.toDate()).format("x"),
      ],
    });

    if (i === 0) {
      free.push({
        x: " ",
        y: [
          moment(val.start.toDate())
            .set({ hour: 0, minute: 0, second: 0 })
            .format("x"),
          moment(val.start.toDate()).format("x"),
        ],
      });
    }
    if (i === resourceBooking.length - 1) {
      i !== 0 &&
        free.push({
          x: " ",
          y: [
            moment(val.start.toDate()).format("x"),
            moment(resourceBooking[i - 1].end.toDate()).format("x"),
          ],
        });
      free.push({
        x: " ",
        y: [
          moment(val.end.toDate()).format("x"),
          moment(val.end.toDate())
            .set({ hour: 24, minute: 0, second: 0 })
            .format("x"),
        ],
      });
    }
    if (i !== 0 && i !== resourceBooking.length - 1) {
      free.push({
        x: " ",
        y: [
          moment(val.start.toDate()).format("x"),
          moment(resourceBooking[i - 1].end.toDate()).format("x"),
        ],
      });
    }
  });

  resourceBooking.length === 0 &&
    free.push({
      x: " ",
      y: [
        moment(date)
          .startOf("day")
          .valueOf(),
        moment(date)
          .endOf("day")
          .valueOf(),
      ],
    });

  return [
    {
      name: "Booked",
      data: booked,
    },
    {
      name: "Free",
      data: free,
    },
  ];
}

const removeLayersSources = (map) => {
  var layer = map.getLayer("floorPlanLayer");
  if (typeof layer !== "undefined") {
    map.removeLayer("floorPlanLayer");
  }
  var source = map.getSource("floorPlanSource");
  if (typeof source !== "undefined") {
    map.removeSource("floorPlanSource");
  }
  var layer = map.getLayer("linesLayer");
  if (typeof layer !== "undefined") {
    map.removeLayer("linesLayer");
  }
  var source = map.getSource("linesSource");
  if (typeof source !== "undefined") {
    map.removeSource("linesSource");
  }
};

function getChartOptions(date) {
  return {
    chart: {
      // animations: {
      //   enabled: false,
      // },
      width: 195,
      height: 70,
      type: "rangeBar",
      toolbar: { show: false },
      animations: { enabled: false },
      /* offsetY: -15,
         parentHeightOffset: 0, */
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
      padding: {
        top: -15,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "40%",
        rangeBarGroupRows: true,
      },
    },
    legend: {
      show: true,
      fontSize: "11px",
      offsetY: 5,
      onItemClick: { toggleDataSeries: false },
      onItemHover: { highlightDataSeries: false },
    },
    colors: ["#a80000", "#5ca800", "#5ca800"],
    xaxis: {
      type: "datetime",
      min: moment(date)
        .startOf("day")
        .valueOf(),
      max: moment(date)
        .endOf("day")
        .valueOf(),
      labels: {
        show: false,
        formatter: function(value, timestamp) {
          return moment(value).format("HH:mm");
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: "HH:mm",
      },
      y: {
        formatter: () => "",
        title: {
          formatter: (seriesName) => {
            return seriesName;
          },
        },
      },
    },
    yaxis: { show: true },
  };
}

function getChartOptions2(date) {
  return {
    chart: {
      // animations: {
      //   enabled: false,
      // },
      width: "100%",
      height: 70,
      type: "rangeBar",
      toolbar: { show: false },
      animations: { enabled: false },
      /* offsetY: -15,
         parentHeightOffset: 0, */
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
      padding: {
        top: -15,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "40%",
        rangeBarGroupRows: true,
      },
    },
    legend: {
      show: true,
      fontSize: "11px",
      offsetY: 5,
      onItemClick: { toggleDataSeries: false },
      onItemHover: { highlightDataSeries: false },
    },
    colors: ["#a80000", "#5ca800", "#5ca800"],
    xaxis: {
      type: "datetime",
      min: moment(date)
        .startOf("day")
        .valueOf(),
      max: moment(date)
        .endOf("day")
        .valueOf(),
      labels: {
        show: false,
        formatter: function(value, timestamp) {
          return moment(value).format("HH:mm");
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: "HH:mm",
      },
      y: {
        formatter: () => "",
        title: {
          formatter: (seriesName) => {
            return seriesName;
          },
        },
      },
    },
    yaxis: { show: true },
  };
}
