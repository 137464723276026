import React, { useEffect, useMemo, useState } from "react";
// import { useSelector, useDispatch, shallowEqual } from "react-redux";
// import { useUIContext } from "../../UIContext";
// import * as actionsPDF from "../../../_redux/reporting/reportingActions";
// import { LocationAccuracy } from "../Floor/LocationAccuracy";
// import { LocationAccuracyPerDay } from "../Floor/LocationAccuracyPerDay";
// import { RSSI } from "../Floor/RSSI";
// import { RSSIPerDay } from "../Floor/RSSIPerDay";
// import { ZoneLocationAccuracy } from "../Zone/ZoneLocationAccuracy";
// import { ZoneLocationAccuracyPerDay } from "../Zone/ZoneLocationAccuracyPerDay";
// import { ZonesRSSI } from "../Zone/ZonesRSSI";
// import { ZonesRSSIPerDay } from "../Zone/ZonesRSSIPerDay";
import "./PDF.css";
import Logo from "../../../../assets/ufind-purple-logo.png";
import { Modal } from "react-bootstrap";
import PDFModalWifi from "./PDFModalWifi";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { Button, CircularProgress } from "@material-ui/core";
import { PeopleDistributionCustom } from "../CustomPDFCharts/PeopleFloors/PeopleDistributionCustom";
import { OccupancyCustom } from "../CustomPDFCharts/PeopleFloors/OccupancyCustom";
import { FloorAverageOccupancyCustom } from "../CustomPDFCharts/PeopleFloors/FloorAverageOccupancyCustom";
import { ZoneOccupancyCustom } from "../CustomPDFCharts/PeopleZones/ZoneOccupancyCustom";
import { ZoneAverageOccupancyCustom } from "../CustomPDFCharts/PeopleZones/ZoneAverageOccupancyCustom";
import { ZonePeopleDistributionCustom } from "../CustomPDFCharts/PeopleZones/ZonePeopleDistributionCustom";
import { ChordDependencyCustom } from "../CustomPDFCharts/PeopleZones/ChordDependencyCustom";
import { ZoneAvgTimeSpentCustom } from "../CustomPDFCharts/PeopleZones/ZoneAvgTimeSpentCustom";

export default function PDFPreviewModalPeople({
  showPDFPreviewModalPeople,
  setShowPDFPreviewModalPeople,
  user,
  pdfFormData,
  setPdfFormData,
  group,
  showPDFModal,
  setShowPDFModal,
  selectedFloors,
  selectedZones,
  selectedSSIDs,
  date,
  selectedTimeframe,
  hide,
  setHide,
}) {
  // const dispatch = useDispatch();

  // * ____________ States ____________ * \\
  const [showChart, setShowChart] = useState({
    PeopleDistributionCustom: true,
    OccupancyCustom: true,
    FloorAverageOccupancyCustom: true,
  });
  const [showChartZones, setShowChartZones] = useState({
    ZoneOccupancyCustom: true,
    ZoneAverageOccupancyCustom: true,
    ZonePeopleDistributionCustom: true,
    ChordDependencyCustom: true,
    ZoneAvgTimeSpentCustom: true,
  });
  const [loading, setLoading] = useState(false);

  const downloadingPDFData = () => {
    setLoading(true);

    //Faking API call here
    setTimeout(() => {
      setLoading(false);
      // Closes preview Modal
      setShowPDFPreviewModalPeople(false);
    }, 5000);
  };

  //   useEffect(() => {
  //     // console.log(showChart, "showChart");
  //     console.log(group, "group");
  //     // console.log(UIProps, "UIProps");
  //   }, [group, showChart]);

  const displayingZeroChartsZones =
    !showChartZones.ZoneOccupancyCustom &&
    !showChartZones.ZoneAverageOccupancyCustom &&
    !showChartZones.ZonePeopleDistributionCustom &&
    !showChartZones.ChordDependencyCustom &&
    !showChartZones.ZoneAvgTimeSpentCustom
      ? true
      : false;

  const displayingZeroChartsFloors =
    !showChart.PeopleDistributionCustom &&
    !showChart.OccupancyCustom &&
    !showChart.FloorAverageOccupancyCustom
      ? true
      : false;

  return (
    <>
      <Modal
        show={showPDFPreviewModalPeople}
        onHide={() => setShowPDFPreviewModalPeople(false)}
        backdrop='static'
        dialogClassName='modal-100w'
        aria-labelledby='example-custom-modal-styling-title'
        size='xl'
        id='modalContent'
      >
        {/* ━━★  BODY  ★━━ */}
        <Modal.Body id='modalBodyPDFPeople'>
          <div id='wrapper'>
            <div className='d-flex bd-highlight mb-3'>
              <div className='mr-auto p-2 bd-highlight'>
                <h3 style={{ fontSize: "20px", textDecoration: "underline" }}>
                  People {group === "floor" ? "Floors" : "Zones"} Report
                </h3>
              </div>
              <div className='p-2 bd-highlight'>
                <img src={Logo} alt='u-find logo' className='ufind-logo' />{" "}
              </div>
            </div>
            <div className='row'>
              {group === "floor" && (
                <>
                  {showChart.PeopleDistributionCustom && (
                    <div
                      className={
                        showChart.OccupancyCustom
                          ? "col-md-12 col-xxl-4"
                          : "col-12"
                      }
                      id='peopleDivToPdf1'
                    >
                      <PeopleDistributionCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.OccupancyCustom && (
                    <div
                      className={
                        showChart.PeopleDistributionCustom
                          ? "col-md-12 col-xxl-8"
                          : "col-12"
                      }
                      id='peopleDivToPdf2'
                    >
                      <OccupancyCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                  {showChart.FloorAverageOccupancyCustom && (
                    <div className='col-12' id='peopleDivToPdf3'>
                      <FloorAverageOccupancyCustom
                        showChart={showChart}
                        setShowChart={setShowChart}
                        hide={hide}
                      />
                    </div>
                  )}
                </>
              )}
              {group === "zone" && (
                <>
                  {showChartZones.ZoneOccupancyCustom && (
                    <div className='col-12'>
                      <ZoneOccupancyCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='peopleDivToPdf1'
                      />
                    </div>
                  )}
                  {showChartZones.ZoneAverageOccupancyCustom && (
                    <div className='col-12'>
                      <ZoneAverageOccupancyCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='peopleDivToPdf2'
                      />
                    </div>
                  )}
                  {showChartZones.ZonePeopleDistributionCustom && (
                    <div className='col-12'>
                      <ZonePeopleDistributionCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='peopleDivToPdf3'
                      />
                    </div>
                  )}
                  {showChartZones.ChordDependencyCustom && (
                    <div className='col-12'>
                      <ChordDependencyCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='peopleDivToPdf4'
                      />
                    </div>
                  )}
                  {showChartZones.ZoneAvgTimeSpentCustom && (
                    <div className='col-12'>
                      <ZoneAvgTimeSpentCustom
                        showChartZones={showChartZones}
                        setShowChartZones={setShowChartZones}
                        hide={hide}
                        id='peopleDivToPdf5'
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id='dlPDFCancelBtnPeople'
            disabled={loading}
            color='default'
            variant='contained'
            className='mr-2'
            onClick={() => {
              setShowPDFPreviewModalPeople(false);
              // Reset showing all charts on preview modal
              setShowChart({
                PeopleDistributionCustom: true,
                OccupancyCustom: true,
                FloorAverageOccupancyCustom: true,
                // RSSIPerDayCustom: true,
              });
              setShowChartZones({
                ZoneOccupancyCustom: true,
                ZoneAverageOccupancyCustom: true,
                ZonePeopleDistributionCustom: true,
                ChordDependencyCustom: true,
                ZoneAvgTimeSpentCustom: true,
              });
            }}
          >
            CANCEL
          </Button>
          {/* Schedule Email delivery */}
          {/* <button
            onClick={() => {
              setShowPDFModal(true);
              setShowPDFPreviewModal(false);
            }}
          >
            EMAIL DELIVERY
          </button> */}
          <Button
            disabled={
              loading || displayingZeroChartsZones || displayingZeroChartsFloors
            }
            color='primary'
            variant='contained'
            className='ml-2'
            onClick={() => {
              // Spinner appears in button
              downloadingPDFData();

              // Hide close Icons
              setHide(true);

              // ↓ Download PDF
              const now = moment().format("YYYY-MM-DD HH:mm");
              const element = document.getElementById("modalBodyPDFPeople");

              const cutAfterOptions =
                group === "people"
                  ? ["#peopleDivToPdf3"]
                  : ["peopleDivToPdf2", "peopleDivToPdf3"];

              // console.log("🚀 ~ cutAfterOptions", cutAfterOptions);

              const cutBeforeOptions =
                group === "people" ? null : ["peopleDivToPdf3"];

              const options = {
                margin: 0,
                filename: `People_Report_${now}`,
                jsPDF: {
                  // unit: "in",
                  // format: "letter",
                  unit: "pt",
                  format: "a4",
                  orientation: "p",
                  // hotfixes: ["px_scaling"],
                  // floatPrecision: "smart",
                  // precision: 15,
                },
                image: {
                  type: "jpeg",
                  quality: 1,
                  // width: 494,
                  // height: 494,
                },
                html2canvas: {
                  scale: 2,
                  scrollX: 0,
                  scrollY: 0,
                  // windowWidth: 1224,
                },
                // html2canvas: { scale: 2, windowWidth: 1100 },
                // makes pages break to another pdf page after chosen elements id.
                pagebreak: {
                  mode: "avoid-all",
                  before: cutBeforeOptions,
                  //   [
                  //     // "#peopleDivToPdf2",
                  //     // "#peopleDivToPdf3",
                  //     // "#peopleDivToPdf3",
                  //     // "#wifiDivToPdf4",
                  //   ],
                  after: cutAfterOptions,
                  //   [
                  //     // "#peopleDivToPdf3",
                  //   ],
                },
              };
              // Download PDF
              html2pdf()
                .set(options)
                .from(element)
                .save();

              // Preview PDF on browser
              // html2pdf()
              //   .set(options)
              //   .from(element)
              //   .toPdf()
              //   .get("pdf")
              //   .then(function(pdf) {
              //     window.open(pdf.output("bloburl"), "_blank");
              //   });

              // Reset showing all charts on preview modal
              setShowChart({
                PeopleDistributionCustom: true,
                OccupancyCustom: true,
                FloorAverageOccupancyCustom: true,
                // RSSIPerDayCustom: true,
              });
              setShowChartZones({
                ZoneOccupancyCustom: true,
                ZoneAverageOccupancyCustom: true,
                ZonePeopleDistributionCustom: true,
                ChordDependencyCustom: true,
                ZoneAvgTimeSpentCustom: true,
              });
            }}
          >
            {loading && (
              <CircularProgress style={{ marginRight: "15px" }} size={15} />
            )}
            {loading && <span>DOWNLOADING...</span>}
            {!loading && <span>DOWNLOAD PDF</span>}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ━━★  PDF MODAL SCHEDULE   ★━━ */}
      {/* <PDFModalWifi
        showPDFModal={showPDFModal}
        setShowPDFModal={setShowPDFModal}
        user={user}
        pdfFormData={pdfFormData}
        setPdfFormData={setPdfFormData}
        setShowPDFPreviewModal={setShowPDFPreviewModal}
        group={group}
        selectedFloors={selectedFloors}
        selectedZones={selectedZones}
        selectedSSIDs={selectedSSIDs}
        date={date}
        selectedTimeframe={selectedTimeframe}
      /> */}
    </>
  );
}
