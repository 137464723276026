import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch, connect } from "react-redux";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { profileSlice } from "../../../../app/redux/profile/profileSlice";
import { firestore } from "../../../../firebase";
import { useDocument } from "@nandorojo/swr-firestore";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import PersonIcon from "@material-ui/icons/Person";
import * as authRedux from "../../../modules/Auth/_redux/authRedux";
import { injectIntl } from "react-intl";

const { actions } = profileSlice;

function HeaderSelections(props) {
  const dispatch = useDispatch();

  const {
    currentCustomer,
    currentLocation,
    currentLevel,
    userClaims,
  } = useSelector(
    (state) => ({
      currentCustomer: state.profile.currentCustomer,
      currentLocation: state.profile.currentLocation,
      currentLevel: state.profile.currentLevel,
      userClaims: state.auth.claims,
    }),
    shallowEqual
  );

  const [customers, setCustomers] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(
    currentCustomer?.id || null
  );

  const { data: customer } = useDocument(
    selectedCustomer ? `Customers/${selectedCustomer}` : null,
    { listen: true }
  );

  useEffect(() => {
    if (!customer?.exists) return;
    dispatch(actions.customerSelected(customer || null));

    //If selected location is not found in selected customer deselect location
    if (
      customer.merakiNetworks.some(
        (val) => val.netId === currentLocation?.netId
      )
    ) {
      //Update location
      var location = customer.merakiNetworks.find(
        (val) => val.netId === currentLocation?.netId
      );
      dispatch(actions.locationSelected(location));
      return;
    }
    dispatch(actions.locationSelected());
    dispatch(actions.levelSelected());
  }, [customer]);

  useEffect(() => {
    if (userClaims.profileLvl < 3) {
      setSelectedCustomer(userClaims.customerId);
      return;
    }
    firestore
      .collection("Customers")
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setCustomers(data);
      });
  }, [userClaims]);

  const isReporting = window.location.pathname.includes("reporting");

  return (
    <div className='header-selections'>
      {/*\★━━━━━━━━━━━★ Select customer ★━━━━━━━━━━━★\*/}
      {customers && userClaims.profileLvl >= 3 && (
        <div style={{ marginLeft: "10px" }}>
          <BusinessIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={currentCustomer?.id || ""}
              onChange={(e) => {
                setSelectedCustomer(e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Select customer
              </MenuItem>
              {customers.map((val) => {
                return (
                  <MenuItem key={val.id} value={val.id}>
                    {val.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}

      {userClaims.profileLvl >= 1 && !isReporting && (
        <div style={{ marginLeft: "10px" }}>
          {/*\★━━━━━━━━━━━★ Select location ★━━━━━━━━━━━★\*/}
          <LocationOnIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              id='selectLocationDropDown'
              displayEmpty
              disableUnderline
              value={currentLocation?.netId || ""}
              onChange={(e) => {
                if (!currentCustomer?.merakiNetworks) return;
                if (currentLocation?.netId === e.target.value) return;
                var l = currentCustomer.merakiNetworks.find(
                  (val) => val.netId === e.target.value
                );
                dispatch(actions.locationSelected(l));
                dispatch(actions.levelSelected(l?.levels && l.levels[0]));
              }}
            >
              <MenuItem value=''>Select location</MenuItem>
              {currentCustomer &&
                currentCustomer.merakiNetworks.map((val) => {
                  return (
                    <MenuItem key={val.netId} value={val.netId}>
                      {val.netName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}

      {userClaims.profileLvl >= 1 && !isReporting && (
        <div style={{ marginLeft: "10px" }}>
          {/*\★━━━━━━━━━━━★ Select level ★━━━━━━━━━━━★\*/}
          <ClearAllIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={currentLevel?.floorId ? currentLevel?.floorId : ""}
              onChange={(e) => {
                if (!currentLocation?.levels) return;
                var l = currentLocation.levels.find(
                  (val) => val.floorId === e.target.value
                );
                dispatch(actions.levelSelected(l));
              }}
            >
              <MenuItem value='' disabled>
                Select level
              </MenuItem>
              {currentLocation?.levels &&
                currentLocation.levels.map((val) => {
                  return (
                    <MenuItem key={val.floorId} value={val.floorId}>
                      {val.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}

      {userClaims.isSuper && (
        <div style={{ marginLeft: "10px" }}>
          {/*\★━━━━━━━━━━━★ Select level ★━━━━━━━━━━━★\*/}
          <PersonIcon />
          <FormControl size='small' style={{ marginLeft: "5px" }}>
            <Select
              displayEmpty
              disableUnderline
              value={
                userClaims.profileLvl === 3
                  ? "msp"
                  : userClaims.profileLvl === 2
                  ? "admin"
                  : userClaims.profileLvl === 1
                  ? "user"
                  : ""
              }
              onChange={(e) => {
                if (!userClaims.isSuper) return;
                if (e.target.value === "msp") {
                  const claims = {
                    ...userClaims,
                    profileLvl: 3,
                  };
                  props.setUserClaims(claims);
                } else if (e.target.value === "admin") {
                  const claims = {
                    ...userClaims,
                    profileLvl: 2,
                  };
                  props.setUserClaims(claims);
                } else if (e.target.value === "user") {
                  const claims = {
                    ...userClaims,
                    profileLvl: 1,
                  };
                  props.setUserClaims(claims);
                } else {
                  const claims = {
                    ...userClaims,
                    profileLvl: authRedux.getProfileLvl(userClaims),
                  };
                  props.setUserClaims(claims);
                }
              }}
            >
              <MenuItem value=''>Original role</MenuItem>
              <MenuItem value='msp'>MSP</MenuItem>
              <MenuItem value='admin'>Admin</MenuItem>
              <MenuItem value='user'>User</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}

export default injectIntl(connect(null, authRedux.actions)(HeaderSelections));
