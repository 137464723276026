/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { profileSlice } from "../../../../redux/profile/profileSlice";
// import { storage } from "../../../../../firebase";
import {
  Popper,
  IconButton,
  Tooltip,
  Zoom,
  Grid,
  Button,
  SvgIcon,
  makeStyles,
} from "@material-ui/core";
import { Bold } from "../../../../_partials/typography/Bold";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import {
  addImageLayerToMap,
  addGeoJsonLayerToMap,
  addDevicesToMap,
  addPOIMarkers,
  addSensorMarkers,
  addApMarkers,
  addZoneMarkers,
  handleZonesColor,
  addGeoJsonLayerToMapWithNewPath,
  removePathFromMap,
  addConnectorsMarkers,
  addDevicesToMapHeatmap,
  addGeoJsonLayerToMapLine,
  findMinMax,
  allLocationsInMap,
  searchHandleMap,
} from "../_helpers/ActionHelpers";
import { useUIContext } from "../../UIContext";
import Options from "./Controls/Options";
import Fullscreen from "./Controls/Fullscreen";
import Search from "./Controls/Search";
import DirectionsControl from "./DirectionsControl";
import MapboxglSpiderifier from "mapboxgl-spiderifier";
import ChangeFloorControlDashboard from "./ChangeFloorControlDashboard";
import MapControlsButtons from "../../../Admin/designStudio/MapControlButtons/MapControlsButtons";
import { Card, CardHeader, CardBody } from "../../../../_partials/Card";
import "../LiveMapStyle.css";
import "../LiveButton.scss";
import "./Controls/Controls.css";
import "../thermostatMarker.scss";
import OccupancyControl from "./Controls/OccupancyControl";
import * as turf from "@turf/turf";
// import PopoverStickOnHover from "./PopoverOnHoverDashboard1";
// import ApartmentIcon from "@material-ui/icons/Apartment";
// import StoreIcon from "@material-ui/icons/Store";
// import { Carousel } from "react-bootstrap";
// import { FaUserTie } from "react-icons/fa";
// import { FaUsers } from "react-icons/fa";
// import { BsFillPeopleFill } from "react-icons/bs";
// import RouterIcon from "@material-ui/icons/Router";
// import { BsThermometerSun } from "react-icons/bs";
import BackToAllLocations from "./Controls/BackToAllLocations";

const { actions } = profileSlice;

const useStyles = makeStyles((theme) => ({
  button4: {
    margin: theme.spacing(1, 0, 1, 1),
    heigh: "2.7vh !important",
    width: "auto",
  },
}));

export function LiveMapWidget({ differentLocationFloorPlans }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  //━━━ User based information ━━━\\
  const {
    userClaims,
    user,
    selectedCustomerId,
    selectedLocation,
    selectedLevel,
    selectedCustomer,
  } = useSelector(
    (state) => ({
      userClaims: state.auth.claims,
      user: state.auth.user,
      selectedCustomerId: state.profile?.currentCustomer?.customerId,
      selectedLocation: state.profile?.currentLocation,
      selectedLevel: state.profile?.currentLevel,
      selectedCustomer: state.profile?.currentCustomer,
    }),
    shallowEqual
  );

  //━━━ Data to populate live map ━━━\\
  const {
    floorPlanData,
    zones,
    resources,
    iconsFromDB,
    devices,
    visitors,
    guests,
    users,
    things,
    pointsOfInterest,
    layerTypes,
    sensors,
    blePeople,
    bleThings,
    allLocationsPeopleStats,
    allLocationsMerakiDevices,
  } = useSelector(
    (state) => ({
      floorPlanData: state.basePage.floorPlans,
      zones: state.basePage.zones,
      resources: state.basePage.resources,
      iconsFromDB: state.liveData.iconsFromDB,
      devices:
        state.liveData.devices &&
        state.liveData.devices[selectedLevel?.floorId]?.employees,
      visitors:
        state.liveData.devices &&
        state.liveData.devices[selectedLevel?.floorId]?.visitors,
      users: state.liveData.users,
      guests:
        state.liveData.devices &&
        state.liveData.devices[selectedLevel?.floorId]?.guests,
      pointsOfInterest: state.liveData.pois,
      layerTypes: state.profile.currentCustomer?.layerTypes,
      profileLvl: state.auth.claims.profileLvl,
      sensors: state.liveData.sensors,
      things: state.liveData.things,
      blePeople:
        state.liveData.devices &&
        state.liveData.devices[`${selectedLevel?.floorPlanId}_ble`]?.people,
      bleThings:
        state.liveData.devices &&
        state.liveData.devices[`${selectedLevel?.floorPlanId}_ble`]?.things,
      allLocationsPeopleStats: state.liveData.allLocationsPeopleStats,
      allLocationsMerakiDevices: state.liveData.allLocationsMerakiDevices,
    }),
    shallowEqual
  );

  //━━━ Data from UIContext ━━━\\
  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      liveData: UIContext.liveData,
      setLiveData: UIContext.setLiveData,
      resetSnapshot: UIContext.resetSnapshot,
      setResetSnapshot: UIContext.setResetSnapshot,
    };
  }, [UIContext]);

  //★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
  const SPIDERFY_FROM_ZOOM = 19;
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState();
  const [floorPlan, setFloorPlan] = useState();
  const [showAPS, setShowAPS] = useState(false);
  const [showEmployees, setShowEmployees] = useState(true);
  const [showGuests, setShowGuests] = useState(true);
  const [showVisitors, setShowVisitors] = useState(true);
  const [toggleValues, setToggleValues] = useState(() => [
    "showGuests",
    "showVisitors",
    "showZoneInfo",
    "showPOIs",
    "showSensors",
    "showConnectors",
    "showHeatMap",
  ]);
  const [showOccupancy, setShowOccupancy] = useState(false);
  const [zoneMarkers, setZoneMarkers] = useState();
  const [poiMarkers, setPoiMarkers] = useState();
  const [sensorMarkers, setSensorMarkers] = useState();
  const [apMarkers, setApMarkers] = useState();
  const [showZoneInfoMarkers, setShowZoneInfoMarkers] = useState(true);
  const [showPOIs, setShowPOIs] = useState(true);
  const [showSensors, setShowSensors] = useState(true);
  const [searchControl, setSearchControl] = useState();
  const [goTo, setGoTo] = useState();
  const [goToMarker, setGoToMarker] = useState();
  const [markers, setMarkers] = useState({});
  const [markersOnScreen, setMarkersOnScreen] = useState({});
  const [render, setRender] = useState();
  const [directionsControl, setDirectionsControl] = useState();
  const [getDirections, setGetDirections] = useState({
    type: null,
    poi: null,
    clicked: false,
  });
  const [pathFound, setPathFound] = useState();
  const [connectorsMarkers, setConnectorsMarkers] = useState();
  const [showConnectors, setShowConnectors] = useState(true);
  const [connectorAccess, setConnectorAccess] = useState({
    clicked: false,
    zIndex: "0",
    // className: "popupLineRoutes",
    id: null,
  });
  const [iconTab, setIconTab] = useState(0);
  const [typeByDefault, setTypeByDefault] = useState("ELEVATOR");
  const [floorsControl, setFloorsControl] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // const [clickedToHidePath, setClickedToHidePath] = useState(false);
  // const [hidePathControl, setHidePathControl] = useState();
  const [resetPathControl, setResetPathControl] = useState();
  const [clickedToResetPath, setClickedToResetPath] = useState(false);
  const [copyOfStartPointCoords, setCopyOfStartPointCoords] = useState();
  const [copyOfConnectorCoords, setCopyOfConnectorCoords] = useState();
  const [travelTo, setTravelTo] = useState();
  const [poiToDoorCoords, setPoiToDoorCoords] = useState();
  const [poiToDoorEndPointCoords, setPoiToDoorEndPointCoords] = useState();
  const [showHeatMap, setShowHeatMap] = useState(false);
  // const [heatCoords, setHeatCoords] = useState([]);
  const [locationsMarkers, setLocationsMarkers] = useState();
  // const [allImagesInStorage, setAllImagesInStorage] = useState([]);
  const [backToAllLocationsControl, setBackToAllLocationsControl] = useState();
  const [singleLocation, setSingleLocation] = useState(false);

  //★━━━━━━━━━━━━━━━★ UseEffects ★━━━━━━━━━━━━━━━★\\
  //★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

  //★━━━━━━━━━━━━★ Set floorPlan selected ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!floorPlanData || !selectedLevel) {
      return;
    }
    const _floorPlan = floorPlanData.find(
      (floor) => floor.id === selectedLevel.floorId
    );
    setFloorPlan(_floorPlan);
  }, [floorPlanData, selectedLevel]);

  useEffect(() => {
    // ↓ When changing floor without connectors access (elevator, etc) it resets path.
    if (!connectorAccess.clicked) {
      // console.log("Changed floor!!", selectedLevel.floorId);
      // ↓ Reset Path
      pathFound && setClickedToResetPath(true);
    }
  }, [connectorAccess.clicked, pathFound, selectedLevel]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      maxZoom: 24,
      attributionControl: false,
    });

    const spiderifier = new MapboxglSpiderifier(map, {
      customPin: true,
      animate: true,
      animationSpeed: 500,
      circleSpiralSwitchover: 15,
      initializeLeg: initializeSpiderLeg,
    });

    map.on("load", function() {
      map.addControl(new Fullscreen(), "top-right");

      map.addControl(
        new OccupancyControl({
          showOccupancy,
          setShowOccupancy,
        }),
        "top-right"
      );

      map.addControl(
        new Options({
          setShowOccupancy,
          setShowZoneInfoMarkers,
          setShowEmployees,
          setShowGuests,
          setShowVisitors,
          setShowPOIs,
          setShowSensors,
          setShowConnectors,
          setShowAPS,
          setShowHeatMap,
        }),
        "top-right"
      );

      setMap(map);
    });

    map.on("mousemove", (e) => mouseMove(e, map));
    map.on("click", (e) => mouseClick(e, map, spiderifier));
    map.on("zoomstart", () => spiderifier.unspiderfy());

    map.on("wheel", (event) => {
      if (event.originalEvent.ctrlKey) {
        return;
      }
      if (event.originalEvent.metaKey) {
        return;
      }
      if (event.originalEvent.altKey) {
        return;
      }
      dispatch({
        type: "SNACKBAR_WARNING",
        payload: `Use CTRL + Scroll for zoom`,
      });
      event.preventDefault();
    });

    map.on("render", () => setRender(Math.random()));

    return () => map & map.remove();
  }, []);

  //When render occurs it changes a state and this effects runs
  //Running this function inside render would cause problems with states
  useEffect(() => {
    if (!map || !render) {
      return;
    }
    //!https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
    var newMarkersOnScreen = {};
    const _features = map.querySourceFeatures("pins");
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    // console.log("markers: ", Object.keys(markers).length)
    // console.log("markersOnScreen: ", Object.keys(markersOnScreen).length)
    // console.log("_features: ", _features.filter(feature => !feature.properties.cluster))

    var _marker = markers;
    for (const feature of _features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;

      if (props.cluster) continue;
      const id = props.id;

      //console.log("id: ", id)
      //console.log("props: ", props)

      let marker = markers[id];
      if (!marker) {
        const el = createMarker(feature);
        marker = markers[id] = new mapboxgl.Marker({
          element: el,
        }).setLngLat(coords);
        _marker[id] = marker;
      }

      if (!markersOnScreen[id]) marker.addTo(map);

      newMarkersOnScreen[id] = marker;
    }
    setMarkers(_marker);

    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
      if (!newMarkersOnScreen[id]) markersOnScreen[id].remove();
    }

    setMarkersOnScreen(newMarkersOnScreen);
  }, [render]);

  function createMarker(feature) {
    const properties = feature.properties;
    const user = properties.user && JSON.parse(properties.user);
    const el = document.createElement("div");

    const content = user ? (
      <div
        style={{
          width: "24px",
          height: "24px",
          margin: "auto",
          backgroundColor: properties.color,
          border: "solid",
          borderWidth: "2px",
          borderRadius: "50%",
          borderColor: properties.outline,
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <img
          src={user.picture || "/media/users/default.jpg"}
          style={{
            maxWidth: "100%",
            height: "auto",
            borderRadius: "50%",
            objectFit: "cover",
            imageRendering: "pixelated",
          }}
          alt='user'
        />
      </div>
    ) : (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "auto",
          backgroundColor: properties.color,
          border: "solid",
          borderWidth: "2px",
          borderRadius: "50%",
          borderColor: properties.outline,
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        {/* {feature?.properties?.type || "."} */}
      </div>
    );

    ReactDOM.render(content, el);
    return el;
  }

  function initializeSpiderLeg(spiderLeg) {
    var feature = spiderLeg.feature;

    var pinElem = spiderLeg.elements.pin;
    pinElem.className = pinElem.className;

    const content = feature.user ? (
      <div
        style={{
          width: "70%",
          height: "70%",
          margin: "auto",
          backgroundColor: feature.color,
          border: "solid",
          borderWidth: "2px",
          borderRadius: "50%",
          borderColor: feature.outline,
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <img
          src={feature.user.picture || "/media/users/default.jpg"}
          style={{
            maxWidth: "100%",
            height: "auto",
            borderRadius: "50%",
            objectFit: "cover",
            imageRendering: "pixelated",
          }}
          alt='user'
        />
      </div>
    ) : (
      <div
        style={{
          width: "60%",
          height: "60%",
          margin: "auto",
          backgroundColor: feature.color,
          border: "solid",
          borderWidth: "2px",
          borderRadius: "50%",
          borderColor: feature.outline,
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
        }}
      >
        {feature.user?.displayName || feature.type || "."}
      </div>
    );

    ReactDOM.render(content, pinElem);
  }

  function mouseClick(e, map, spiderifier) {
    /* Handle spiderifier of devices in cluster */
    var devicesClusterFeatures = map.queryRenderedFeatures(e.point, {
      layers: ["cluster-pins"],
    });
    spiderifier.unspiderfy();
    if (devicesClusterFeatures.length) {
      if (map.getZoom() < SPIDERFY_FROM_ZOOM) {
        if (map.getZoom() + 2 > SPIDERFY_FROM_ZOOM) {
          map.easeTo({ center: e.lngLat, zoom: SPIDERFY_FROM_ZOOM });
        } else {
          map.easeTo({ center: e.lngLat, zoom: map.getZoom() + 2 });
        }
      } else {
        const clusterId = devicesClusterFeatures[0].properties.cluster_id;
        map
          .getSource("pins")
          .getClusterLeaves(clusterId, 100, 0, (err, leafFeatures) => {
            if (err) {
              return console.error(
                "error while getting leaves of a cluster",
                err
              );
            }
            var markers = leafFeatures.map((leafFeature) => {
              return leafFeature.properties;
            });
            spiderifier.spiderfy(
              devicesClusterFeatures[0].geometry.coordinates,
              markers
            );
          });
      }
      return;
    }
  }

  function mouseMove(e, map) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["cluster-pins"],
    });
    map.getCanvas().style.cursor = features.length ? "pointer" : "";
  }

  useEffect(() => {
    // console.log(images, "images");
    // console.log(allImagesInStorage, "allImagesInStorage");
    // console.log(allLocationsMerakiDevices, "allLocationsMerakiDevices");
    // console.log(differentLocationFloorPlans, "differentLocationFloorPlans");
    // console.log(
    //   differentLocationFloorPlans && differentLocationFloorPlans.length,
    //   "length"
    // );
    // console.log(
    //   differentLocationFloorPlans && differentLocationFloorPlans.length === 1,
    //   "=== 1"
    // );
    // console.log(allLocationsPeopleStats, "allLocationsPeopleStats");
    // console.log(allLocationsPeopleStats?.allLocationStats, "stats");
    // console.log(selectedCustomer, "selectedCustomer LIVE MAP");
    // console.log(singleLocation, "singleLocation ");
    // console.log(selectedLocation, "selectLocation");
    // console.log(user, "user");
  }, [
    user,
    allLocationsMerakiDevices,
    // allImagesInStorage,
    selectedCustomer,
    differentLocationFloorPlans,
    allLocationsPeopleStats,
    singleLocation,
    selectedLocation,
  ]);

  //★━━━━━━━━━━━━━★ No Location Selected Map ★━━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map || !selectedCustomer) return;

    // If customer has only one location, auto select that one by default
    if (
      !selectedLocation &&
      differentLocationFloorPlans &&
      differentLocationFloorPlans.length === 1
    ) {
      setSingleLocation(true);
      const singleLocation = differentLocationFloorPlans[0];
      const nameFound = selectedCustomer.merakiNetworks.find(
        (l) => l?.netId === singleLocation?.netId
      );

      dispatch(actions.locationSelected(nameFound));

      const floor = {
        floorId: singleLocation.floorPlanId,
        level: singleLocation.level,
        name: singleLocation.name,
      };
      dispatch(actions.levelSelected(floor));
    }

    // If customer has only one location,
    if (
      differentLocationFloorPlans &&
      differentLocationFloorPlans.length === 1
    ) {
      setSingleLocation(true);
    }

    // If customer has more than one location
    if (
      !selectedLocation &&
      differentLocationFloorPlans &&
      differentLocationFloorPlans.length > 1
    ) {
      setSingleLocation(false);
      allLocationsInMap({
        map,
        selectedCustomer,
        selectedLocation,
        locationsMarkers,
        sensorMarkers,
        allLocationsPeopleStats,
        differentLocationFloorPlans,
        allLocationsMerakiDevices,
        dispatch,
        setLocationsMarkers,
      });

      // remove info markers and clusters in no location view
      setShowOccupancy(false);
      setShowZoneInfoMarkers(false);
      setShowEmployees(false);
      setShowGuests(false);
      setShowVisitors(false);
      setShowPOIs(false);
      setShowSensors(false);
      setShowConnectors(false);
      setShowAPS(false);
      setShowHeatMap(false);
    }

    if (selectedLocation) {
      // Remove markers if selectedLocation exists
      locationsMarkers &&
        locationsMarkers.forEach((m) => {
          m.remove();
        });
      // display default info markers and clusters in location selected view
      setShowOccupancy(false);
      setShowZoneInfoMarkers(true);
      setShowEmployees(true);
      setShowGuests(true);
      setShowVisitors(true);
      setShowPOIs(true);
      setShowSensors(true);
      setShowConnectors(true);
      setShowAPS(false);
      setShowHeatMap(false);
    }
  }, [
    map,
    selectedLocation,
    selectedCustomer,
    differentLocationFloorPlans,
    allLocationsPeopleStats,
    allLocationsMerakiDevices,
  ]);

  // Hide Map Controls if (!selectedLocation)
  useEffect(() => {
    if (!map) return;
    if (!selectedLocation) {
      // Hide Map Controls
      if (document.getElementById("occupancyControlId")) {
        document.getElementById("occupancyControlId").style.display = "none";
      }
      if (document.getElementById("showOptionsControlId")) {
        document.getElementById("showOptionsControlId").style.display = "none";
      }
    }
    if (selectedLocation) {
      document.getElementById("occupancyControlId").style.display = "block";
      document.getElementById("showOptionsControlId").style.display = "block";
    }
  }, [map, selectedLocation]);

  //★━━━━━━━━━━━━★ Return to all Locations Global View Control Button ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map) return;

    if (backToAllLocationsControl) map.removeControl(backToAllLocationsControl);

    const _backToAllLocationsControl = new BackToAllLocations({
      dispatch: dispatch,
      allFloors: selectedLocation,
      singleLocation: singleLocation,
      // selectedLevel: selectedLevel,
      // handleClick,
    });
    map.addControl(_backToAllLocationsControl, "top-right");
    setBackToAllLocationsControl(_backToAllLocationsControl);
  }, [selectedLocation, map, singleLocation]);

  //★━━━━━━━━━━━━━★ Search ★━━━━━━━━━━━━━★\\
  useEffect(() => {
    //Removes and reads search to update options
    if (!map) return;

    if (searchControl) map.removeControl(searchControl);

    const updatedGoTo = things && things.find((val) => val.id === goTo?.id);
    // console.log("goTo: ", goTo);
    // console.log("updatedGoTo: ", updatedGoTo);

    const _control = new Search({
      dispatch: dispatch,
      locations: selectedLocation?.levels,
      floorPlan: floorPlan,
      userEmail: user.email,
      devices: devices,
      bleDevices: blePeople,
      buddies: users,
      zones: zones,
      things: things && things.filter((val) => val.ble),
      bleThings: bleThings,
      pois: pointsOfInterest,
      sensors: sensors,
      defaultValue: updatedGoTo,
      setGoTo: setGoTo,
    });

    map.addControl(_control, "top-left");
    setSearchControl(_control);
  }, [
    map,
    selectedLocation,
    floorPlan,
    user,
    devices,
    blePeople,
    users,
    zones,
    things,
    bleThings,
    pointsOfInterest,
    sensors,
  ]);

  useEffect(() => {
    // console.log("goTo2: ", goTo);
    // console.log("things: ", things);
    if (!things || !goTo || goTo?.group !== "Things") return;
    const updatedGoTo = things.find((val) => val.id === goTo.id);
    searchHandleMap({
      locations: selectedLocation?.levels,
      map,
      floorPlan,
      goTo: updatedGoTo,
      setGoTo,
      coords: updatedGoTo.gpsData && [
        updatedGoTo.gpsData.longitude,
        updatedGoTo.gpsData.latitude,
      ],
      dispatch,
    });
  }, [things]);

  //★━━━━━━━━━━━★ Floor Plan Image ★━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map || !floorPlan) return;
    addImageLayerToMap({ map, floorPlan });
  }, [map, floorPlan]);

  //★━━━━━━━━━━━━★ GeoJson Zones ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map || !floorPlan) return;
    addGeoJsonLayerToMap({
      map,
      geoJson: floorPlan.geoJson,
      layerTypes,
      devices,
      guests,
      visitors,
    });
  }, [map, floorPlan, layerTypes]);

  //★━━━━━━━━━━━━★ Connectors GeoJsonRoutes and Set Path when they are in other floors ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map || !selectedLocation) return;
    connectorsMarkers && connectorsMarkers.forEach((marker) => marker.remove());
    if (!showConnectors) return;

    const newConnectorsMarkers = addConnectorsMarkers({
      map,
      connectorsMarkers,
      floorPlanId: floorPlan?.id,
      floorPlanData: floorPlanData,
      allFloors: selectedLocation.levels,
      geoJsonRoutes: floorPlan?.geoJsonRoutes,
      connectorAccess: connectorAccess,
      travelTo: travelTo,
      selectedFloor: floorPlan,
      dispatch: dispatch,
      setPathFound: setPathFound,
      setGetDirections: setGetDirections,
      setConnectorAccess: setConnectorAccess,
      setTravelTo: setTravelTo,
      copyOfConnectorCoords: copyOfConnectorCoords,
      setCopyOfStartPointCoords: setCopyOfStartPointCoords,
      geoJsonRoutesUndefinedSnackBar: geoJsonRoutesUndefinedSnackBar,
      zones: zones,
      setPoiToDoorEndPointCoords,
      setPoiToDoorCoords,
    });
    setConnectorsMarkers(newConnectorsMarkers);
  }, [
    copyOfConnectorCoords,
    getDirections,
    directionsControl,
    travelTo,
    floorPlan,
    map,
    selectedLocation,
    showConnectors,
    floorPlanData,
    connectorAccess,
    zones,
  ]);

  //★━━━━━━━━━━━━★ Draw Nearest Path  ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map || !floorPlan?.geoJsonRoutes) {
      return;
    }
    if (pathFound) {
      addGeoJsonLayerToMapWithNewPath({
        map,
        pathFound: pathFound,
        travelTo: travelTo,
        copyOfStartPointCoords: copyOfStartPointCoords,
        floorPlan: floorPlan,
        copyOfConnectorCoords: copyOfConnectorCoords,
        poiToDoorCoords: poiToDoorCoords,
        poiToDoorEndPointCoords: poiToDoorEndPointCoords,
      });
    }
  }, [
    poiToDoorEndPointCoords,
    poiToDoorCoords,
    floorPlan,
    copyOfStartPointCoords,
    travelTo,
    map,
    pathFound,
    copyOfConnectorCoords,
  ]);

  function geoJsonRoutesUndefinedSnackBar(floor) {
    const paragraphToaster = (
      <p>
        No routes available in floor {floor.name}. <br />
        {userClaims.isSuper && (
          <span>Please draw routes in design studio page.</span>
        )}
      </p>
    );
    dispatch({
      type: "SNACKBAR_INFO",
      payload: paragraphToaster,
    });
  }

  // ★━━━━━━━━━━━━★ Reset Path Control Button  ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map) return;
    setClickedToResetPath(false);

    const resetPath = new MapControlsButtons({
      className: "mapbox-gl-reset-path",
      title: "Reset Path",
      eventHandler: resetPathButton,
    });

    if (resetPathControl) {
      map.removeControl(resetPathControl);
    }

    if (pathFound) {
      map.addControl(resetPath, "top-right");
      setResetPathControl(resetPath);
    }
    if (clickedToResetPath) {
      // console.log("🚀 ~ clickedToResetPath", clickedToResetPath)
      if (!map.getLayer("pathLayer") || !map.getLayer("routeArrows")) {
        return;
      }
      //━━━ Remove layer ━━━\\
      var layer = map.getLayer("pathLayer");
      if (typeof layer !== "undefined") {
        map.removeLayer("pathLayer");
      }
      var layerArrows = map.getLayer("routeArrows");
      if (typeof layerArrows !== "undefined") {
        map.removeLayer("routeArrows");
      }
      var layerEndPoint = map.getLayer("pathLayerEndPoint");
      if (typeof layerEndPoint !== "undefined") {
        map.removeLayer("pathLayerEndPoint");
      }
      var layerStartPoint = map.getLayer("pathLayerStartPoint");
      if (typeof layerStartPoint !== "undefined") {
        map.removeLayer("pathLayerStartPoint");
      }
      var layerDoorPoint = map.getLayer("pathLayerDoorPoint");
      if (typeof layerDoorPoint !== "undefined") {
        map.removeLayer("pathLayerDoorPoint");
      }

      var layerDoorEndPoint = map.getLayer("pathLayerDoorEndPoint");
      if (typeof layerDoorEndPoint !== "undefined") {
        map.removeLayer("pathLayerDoorEndPoint");
      }

      //━━━ Remove source  ━━━\\
      var sourceEndPoint = map.getSource("pathTillEndPoint");
      if (typeof sourceEndPoint !== "undefined") {
        map.removeSource("pathTillEndPoint");
      }
      var sourceStartPoint = map.getSource("pathTillStartPoint");
      if (typeof sourceStartPoint !== "undefined") {
        map.removeSource("pathTillStartPoint");
      }
      var source = map.getSource("path");
      if (typeof source !== "undefined") {
        map.removeSource("path");
      }

      var sourceDoorPoint = map.getSource("pathTillDoorPoint");
      if (typeof sourceDoorPoint !== "undefined") {
        map.removeSource("pathTillDoorPoint");
      }

      var sourceDoorEndPoint = map.getSource("pathTillDoorEndPoint");
      if (typeof sourceDoorEndPoint !== "undefined") {
        map.removeSource("pathTillDoorEndPoint");
      }

      pathFound && setPathFound();
      setConnectorAccess({
        clicked: false,
        zIndex: "0",
        id: null,
      });
    }
  }, [map, pathFound, clickedToResetPath]);

  // Reset Path Control Button Function
  const resetPathButton = () => {
    pathFound && setClickedToResetPath(true);
  };

  //★━━━━━━━━━━━━★ Change Floors Control Button ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map) return;

    if (floorsControl) map.removeControl(floorsControl);

    const _floorsControl = new ChangeFloorControlDashboard({
      dispatch: dispatch,
      allFloors: selectedLocation,
      selectedLevel: selectedLevel,
      handleClick,
    });
    map.addControl(_floorsControl, "bottom-right");
    setFloorsControl(_floorsControl);
  }, [selectedLocation, , floorPlan, map, selectedLevel]);

  // Open / close popover floors button
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  //★━━━━━━━━━━━━★ Routes Directions Control Box ★━━━━━━━━━━━━★\\
  useEffect(() => {
    if (
      !map ||
      !floorPlan?.geoJsonRoutes ||
      !selectedLocation ||
      !selectedLevel
    )
      return;

    if (directionsControl) map.removeControl(directionsControl);

    const _directionsControl = new DirectionsControl({
      dispatch: dispatch,
      locations: selectedLocation.levels,
      floorPlan: floorPlan,
      floorPlanData: floorPlanData,
      floorPlanId: floorPlan.id,
      userEmail: user.email,
      devices: devices,
      bleDevices: blePeople,
      buddies: users,
      zones: zones,
      pois: pointsOfInterest,
      // sensors: sensors,
      defaultValue: travelTo,
      setTravelTo: setTravelTo,
      getDirections: getDirections,
      setGetDirections: setGetDirections,
      geoJsonRoutes: floorPlan.geoJsonRoutes,
      setPathFound: setPathFound,
      setConnectorAccess: setConnectorAccess,
      iconTab: iconTab,
      setIconTab: setIconTab,
      typeByDefault: typeByDefault,
      setTypeByDefault: setTypeByDefault,
      setCopyOfConnectorCoords: setCopyOfConnectorCoords,
      setPoiToDoorCoords: setPoiToDoorCoords,
      setPoiToDoorEndPointCoords: setPoiToDoorEndPointCoords,
    });
    if (getDirections.clicked) {
      map.addControl(_directionsControl, "bottom-left");
      setDirectionsControl(_directionsControl);
    }

    //!! ↓ Delete when finish nearest paths. This adds the routes geoJson lines to test
    // addGeoJsonLayerToMapLine({
    //   map,
    //   geoJson: floorPlan.geoJsonRoutes,
    //   // layerTypes,
    //   // devices,
    //   // guests,
    //   // visitors,
    // });
  }, [
    typeByDefault,
    iconTab,
    floorPlanData,
    blePeople,
    devices,
    // directionsControl,
    //  dispatch,
    floorPlan,
    getDirections,
    travelTo,
    map,
    pointsOfInterest,
    selectedLevel,
    selectedLocation,
    user,
    users,
    zones,
  ]);

  // useEffect(() => {
  //   // console.log(poiToDoorCoords, "poiToDoorCoords");
  //   // console.log(clickedToResetPath, "clickedToResetPath");
  //   // travelTo && console.log(travelTo, "travelTo");
  //   // console.log(getDirections, "getDirections");
  //   // console.log(copyOfStartPointCoords, "copyOfStartPointCoords");
  //   // console.log(selectedLocation, "selectedLocation");
  //   // connectorAccess.clicked === true &&
  //   // console.log(connectorAccess, "connectorAccess");
  //   // console.log(zones, "zones");
  // }, [
  //   poiToDoorCoords,
  //   zones,
  //   connectorAccess,
  //   travelTo,
  //   getDirections,
  //   selectedLocation,
  //   copyOfStartPointCoords,
  //   clickedToResetPath,
  // ]);

  //★━━━━━━━━━━━★ Devices ★━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map) return;
    addDevicesToMap({
      map,
      devices,
      blePeople,
      //bleThings,
      users,
      guests,
      visitors,
      showEmployees,
      showGuests,
      showVisitors,
    });
  }, [
    map,
    showEmployees,
    showGuests,
    showVisitors,
    devices,
    guests,
    visitors,
    blePeople,
    //bleThings,
    users,
  ]);

  //★━━━━━━★ Points of interest ★━━━━━━★\\
  useEffect(() => {
    if (!map) return;
    poiMarkers && poiMarkers.forEach((marker) => marker.remove());
    if (!pointsOfInterest || !showPOIs) return;
    const newPoiMarkers = addPOIMarkers({
      map,
      pointsOfInterest,
      floorPlanId: floorPlan?.id,
      iconsFromDB,
      setGetDirections,
      floorPlan: floorPlan,
      geoJsonRoutesUndefinedSnackBar,
      setTravelTo: setTravelTo,
      setCopyOfStartPointCoords: setCopyOfStartPointCoords,
      setClickedToResetPath: setClickedToResetPath,
    });
    setPoiMarkers(newPoiMarkers);
  }, [map, floorPlan, pointsOfInterest, showPOIs, iconsFromDB]);

  //★━━━━━━━━━━━★ Sensors ★━━━━━━━━━━━★\\
  useEffect(() => {
    if (!map) {
      return;
    }
    sensorMarkers && sensorMarkers.forEach((marker) => marker.remove());
    if (!sensors || !showSensors) {
      return;
    }
    const newSensorMarkers = addSensorMarkers({
      map,
      sensors,
      floorPlanId: floorPlan?.id,
      iconsFromDB,
    });
    setSensorMarkers(newSensorMarkers);
  }, [map, floorPlan, sensors, showSensors, iconsFromDB]);

  //★━━━━━━━━★ Access points ★━━━━━━━━★\\
  useEffect(() => {
    if (!map) {
      return;
    }
    apMarkers && apMarkers.forEach((marker) => marker.remove());
    if (!floorPlan?.devices || !showAPS) {
      return;
    }
    const newApMarkers = addApMarkers({ map, aps: floorPlan.devices });
    setApMarkers(newApMarkers);
  }, [map, floorPlan, showAPS]);

  //★━━━━━━━━★ Zone markers ★━━━━━━━━★\\
  useEffect(() => {
    if (!map) return;
    zoneMarkers && zoneMarkers.forEach((marker) => marker.remove());
    if (!floorPlan?.geoJson || !showZoneInfoMarkers) return;
    const newZoneMarkers = addZoneMarkers({
      map,
      floorPlan,
      zones,
      resources,
      setGetDirections,
      geoJsonRoutesUndefinedSnackBar,
      setTravelTo: setTravelTo,
      setCopyOfStartPointCoords,
      setClickedToResetPath,
    });
    setZoneMarkers(newZoneMarkers);
  }, [map, floorPlan, zones, resources, showZoneInfoMarkers]);

  //★━━━━━━━━★ Zone colors ★━━━━━━━━★\\
  useEffect(() => {
    if (!map || !floorPlan?.geoJson) {
      return;
    }

    handleZonesColor({
      map,
      floorPlan,
      layerTypes,
      devices,
      guests,
      visitors,
      showOccupancy,
    });
  }, [map, floorPlan, layerTypes, devices, guests, visitors, showOccupancy]);

  //★━━━━━━━━★ Heatmap ★━━━━━━━━★\\
  useEffect(() => {
    if (!map) {
      return;
    }

    // console.log(showHeatMap, "showHeatMap");

    addDevicesToMapHeatmap({
      map,
      devices,
      blePeople,
      //bleThings,
      users,
      guests,
      visitors,
      showVisitors,
      // heatCoords,
    });

    if (!showHeatMap) {
      // Remove layer and source if they already exist
      if (typeof map.getLayer("occup-heat") !== "undefined") {
        map.removeLayer("occup-heat");
        return;
      }
      // if (typeof map.getSource("occupancy") !== "undefined") {
      //   map.removeSource("occupancy");
      // }
      map.setLayoutProperty("occup-heat", "visibility", "none");
    }

    if (showHeatMap) {
      // console.log(map.getLayer("occup-heat"), "get layer 1");
      // console.log(map.getStyle().layers, "layers");
      map.setLayoutProperty("occup-heat", "visibility", "visible");
    }
  }, [
    showHeatMap,
    devices,
    guests,
    map,
    showVisitors,
    visitors,
    blePeople,
    //bleThings,
    users,
  ]);

  useEffect(() => {
    if (map && !floorPlan?.geoJsonRoutes && directionsControl) {
      map.removeControl(directionsControl); // ← if no routes drawed, remove directions control
      removePathFromMap({ map }); // ← if no routes drawed, removes path
    }
  }, [directionsControl, floorPlan, map]);

  return (
    <Card padding={false}>
      <CardHeader
        className='p-5 pb-0'
        title={
          <div className='d-flex align-items-center'>
            <h3 className='align-items-center flex mb-0'>
              <span className='font-weight-bolder text-dark'>Live Map</span>
            </h3>
            {selectedLocation && (
              <div className='toolbar ml-3'>
                {UIProps.liveData ? (
                  <a href='#' className='btn btn_live'>
                    <span className='live-icon'></span>
                    LIVE
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      UIProps.setResetSnapshot(!UIProps.resetSnapshot)
                    }
                    className='not_btn btn_not_live'
                  >
                    <span className='not_live-icon'></span>
                    LIVE
                  </a>
                )}
              </div>
            )}
          </div>
        }
      />
      <CardBody>
        {/* ★━━━━━━━━★ Change Floor Controls Popper ★━━━━━━━━★ */}
        <Popper open={open} anchorEl={anchorEl} placement={"top"} transition>
          {({ TransitionProps }) => (
            <>
              {selectedLocation &&
                selectedLocation.levels
                  .filter((floor) => {
                    return selectedLevel?.floorId !== floor.floorId;
                  })
                  .map((floor, i) => {
                    return (
                      <Zoom
                        key={floor.floorId}
                        {...TransitionProps}
                        timeout={
                          selectedLocation.levels.length - i > 5
                            ? 1000
                            : 200 * (selectedLocation.levels.length - i)
                        }
                      >
                        <div key={floor.floorId} className='floorsDivCircles'>
                          <Tooltip title={floor.name} arrow placement='left'>
                            <IconButton
                              className='floorsBtnInside'
                              onClick={() => {
                                dispatch(actions.levelSelected(floor));
                                setOpen((prev) => !prev);
                                // ↓ Reset Path
                                pathFound && setClickedToResetPath(true);
                                // ↓ Empty connector Access marker
                                pathFound &&
                                  setConnectorAccess({
                                    clicked: false,
                                    zIndex: "0",
                                    id: null,
                                  });
                                // ↓ Empty go to in directions box
                                pathFound && setTravelTo();
                              }}
                              variant='contained'
                              style={{
                                borderRadius: "50%",
                                width: "3.2rem",
                                height: "3.2rem",
                                color: "white",
                                backgroundColor: "#422D89",
                                textAlign: "center",
                                fontSize: "14px",
                                border: "2px solid #F2F2F2",
                              }}
                            >
                              <b>{floor.level}</b>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Zoom>
                    );
                  })}
            </>
          )}
        </Popper>

        {/* Map */}
        {/* <button onClick={() => console.log("goTo: ", goTo)}>Test</button> */}
        <div
          style={{ height: "600px", borderRadius: "0 0 5px 5px" }}
          ref={mapContainerRef}
        />
      </CardBody>
    </Card>
  );
}
