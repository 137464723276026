import React, { useEffect, useState, useRef } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Input } from "../../../../../_metronic/_partials/controls"
import Button from '@material-ui/core/Button'
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"

const EditSchema = Yup.object().shape({
   validator: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Validator is required"),
   secret_validator: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Secret Validator is required"),
   validator_url: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Validator Url is required"),
})

const SixthStep = ({ setForm, formData, navigation }) => {
   const formRef = useRef()

   const { previous, next } = navigation
   const { domain, admin } = formData
   const { validator, secret_validator, validator_url } = formData.merakiScanApi

   const [loading, setLoading] = useState(false)

   function handleSubmit(values) {
      var merakiScanApi = {
         validator: values.validator,
         secret_validator: values.secret_validator,
         validator_url: values.validator_url,
         api_version: "v3"
      }
      setForm({ target: { name: "data.merakiScanApi", value: merakiScanApi } })
      switch (formData.completedStep) {
         case 5: navigation.go("sevenStep")
            break
         default: setForm({
            target: {
               name: "data.completedStep",
               value: 5
            }
         })
            next()
            break
      }
   }

   useEffect(() => {
      if (domain && admin) {
         setLoading(true)
         var myHeaders = new Headers()
         myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
         myHeaders.append("Content-Type", "application/json")

         var raw = JSON.stringify({
            "domain": domain,
            "email": admin,
            "password": formData.password
         })

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
         }

         fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/createTenant`, requestOptions)
            .then(response => response.text())
            .then(result => {
               var jsonResult = JSON.parse(result)
               var merakiScanApi = {
                  validator: validator,
                  secret_validator: secret_validator,
                  validator_url: jsonResult.message.validator_url,
                  api_version: "v3"
               }
               setForm({ target: { name: "data.merakiScanApi", value: merakiScanApi } })
               setForm({ target: { name: "data.customerId", value: jsonResult.message.customerId } })
               setForm({ target: { name: "data.tenantID", value: jsonResult.message.tenantId } })
               setLoading(false)
            }).catch(error => {
               console.log('error', error)
               setLoading(false)
            })
      }
   }, [domain, admin])

   return <Card isLoading={loading} margin={false} style={{ height: "100%" }}>
      <CardHeader title="Validator"
         toolbar={<>
            {formData.completedStep < 5 && <Button
               variant="contained"
               color="default"
               style={{ marginRight: "10px" }}
               size="large"
               disableElevation
               onClick={previous}>
               Previous
            </Button>}
            <Button
               disableElevation
               variant="contained"
               color="primary"
               size="large"
               onClick={() => formRef.current && formRef.current.handleSubmit()}
            >
               {formData.completedStep >= 5 ? "Submit" : "Next"}
            </Button>
         </>}
      />
      <CardBody>
         <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
               validator: validator,
               secret_validator: secret_validator,
               validator_url: validator_url,
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => {
               handleSubmit(values)
            }}
         >
            {() => (
               <Form className="form form-label-right">
                  <div className="form-group row mb-0">
                     <div className="col-sm-6 mb-4">
                        <Field
                           name="validator"
                           component={Input}
                           label="Validator"
                           inputStyle={["large"]}
                        />
                     </div>
                     <div className="col-sm-6 mb-4">
                        <Field
                           name="secret_validator"
                           component={Input}
                           label="Secret Validator"
                           inputStyle={["large"]}
                        />
                     </div>
                  </div>
                  <div className="form-group row mb-0">
                     <div className="col mb-4 mb-sm-0">
                        <Field
                           disabled
                           name="validator_url"
                           component={Input}
                           label="Validator Url"
                           inputStyle={["large"]}
                        />
                     </div>
                  </div>
               </Form>
            )}
         </Formik >
      </CardBody>
   </Card>
}

export default SixthStep