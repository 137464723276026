import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import Button from '@material-ui/core/Button'
import LinearProgress from "@material-ui/core/LinearProgress"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import firebase from '@firebase/app';
import { firestore, auth } from "../../../../../firebase"
import { deleteCustomer } from "../_helpers/ActionsHelpers"

const Validation = ({ formData, navigation, setForm, defaultData }) => {
   const dispatch = useDispatch()
   const history = useHistory()

   const [loading, setLoading] = useState(false)
   const [isValidated, setIsValidated] = useState(false)
   const [error, setError] = useState()
   const [loggedIn, setLoggedIn] = useState(false)
   const [isCreated, setIsCreated] = useState(false)

   async function handleCancel() {
      //console.log("customerId: ", formData.customerId)
      formData.customerId && deleteCustomer(formData.customerId)
      setLoading(false)
      setForm({
         target: {
            name: "data",
            value: defaultData.data
         }
      })
      navigation.go("setupOrg")
   }

   const handleSubmit = async () => {
      setLoading(true)

      firebase.auth().tenantId = formData.tenantID

      const result = await auth.signInWithEmailAndPassword(formData.admin, formData.password)
         .catch((error) => {
            console.log("Error: ", error)
            setError({
               type: "userLogin",
               error: error
            })
            setLoading(false)
         })

      if (!result) { return }

      setLoggedIn(true)

      var myHeaders = new Headers()
      myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
      myHeaders.append("Content-Type", "application/json")


      var finalData = formData
      delete finalData.completedStep
      delete finalData.password
      finalData.created = {
         createdBy: "trial",
         createdOn: firebase.firestore.FieldValue.serverTimestamp()
      }
      const trialEnd = firebase.firestore.Timestamp.now().toDate()
      trialEnd.setDate(trialEnd.getDate() + 15)
      finalData.trialEnd = firebase.firestore.Timestamp.fromDate(trialEnd)

      const raw = JSON.stringify(finalData)

      const requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      }

      await fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/meraki/customerFlow/newCustomer`, requestOptions)
         .catch(err => {
            console.log("Error saving customer: ", err)
            setError({
               type: "creation",
               error: error
            })
            dispatch({
               type: "SNACKBAR_ERROR",
               payload: `Error creating customer!`,
            })
            return
         })

      var myHeaders2 = new Headers()
      myHeaders2.append("token", process.env.REACT_APP_CREATE_CUSTOMER_TOKEN)
      myHeaders2.append("Content-Type", "application/json")

      const requestOptions2 = {
         method: 'POST',
         headers: myHeaders,
         redirect: 'follow'
      }

      await firestore.collection("Customers")
         .doc(formData.customerId)
         .set(finalData)
         .catch(err => {
            console.log("Error creating user: ", err)
            return
         })

      await fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/update/updateMeraki/${formData.customerId}`, requestOptions2)
         .catch(error => {
            console.log('Error getting meraki info', error)
            setLoading(false)
            setError({
               type: "meraki",
               error: error
            })
            dispatch({
               type: "SNACKBAR_ERROR",
               payload: `Error getting meraki info`,
            })
         })

      /* setForm({
         target: {
            name: "data",
            value: defaultData.data
         }
      }) */

      setIsCreated(true)

      dispatch({
         type: "SNACKBAR_SUCCESS",
         payload: `Customer created`,
      })
   }

   useEffect(() => {
      let unsubscribe
      if (loggedIn) {
         unsubscribe = firestore.collection("Customers")
            .doc(formData.customerId)
            .onSnapshot(doc => {
               var data = doc.data()
               if (data?.merakiScanApi?.validated) {
                  setIsValidated(true)
                  setLoading(false)
               }
            })
      }
      return unsubscribe
   }, [loggedIn])

   useEffect(() => {
      formData.customerId && handleSubmit()
   }, [])

   return <Card isLoading={loading} style={{ height: "100%" }}>
      <CardHeader title="Validation" />
      <CardBody>
         {!error ? <div>
            {isValidated ? <div>
               <span>Customer validated!</span>
               <br />
               <br />
               <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => history.push("/")}
               >
                  Finish
               </Button>
            </div> : <div>
               <span>{isCreated ? "Validating customer..." : "Creating customer..."}</span>
               <br />
               <br />
               <Button
                  disabled={!isCreated}
                  disableElevation
                  variant="contained"
                  color="default"
                  size="large"
                  onClick={() => handleCancel()}
               >
                  Cancel
               </Button>
            </div>}
         </div> : <div>
            {error.type === "creation" && <div>
               <span>Error creating trial.</span>
               <br />
               <br />
               <Button
                  disableElevation
                  variant="contained"
                  color="default"
                  size="large"
                  onClick={() => handleCancel()}
               >
                  Cancel
               </Button>
               {"   "}
               <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
               >
                  Try again
               </Button>
            </div>}
            {error.type === "meraki" && <div>
               <span>Error getting meraki info.</span>
               <br />
               <br />
               <Button
                  disableElevation
                  variant="contained"
                  color="default"
                  size="large"
                  onClick={() => handleCancel()}
               >
                  Cancel
               </Button>
               {"   "}
               <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
               >
                  Try again
               </Button>
            </div>}
            {error.type === "userLogin" && <div>
               <span>Error creating trial. Try again or contact support for detailed help</span>
               <br />
               <br />
               <Button
                  disableElevation
                  variant="contained"
                  color="default"
                  size="large"
                  onClick={() => handleCancel()}
               >
                  Cancel
               </Button>
               {"   "}
               <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
               >
                  Try again
               </Button>
            </div>}
         </div>
         }
      </CardBody>
   </Card>
}

export default Validation