import React from "react"
import { Header3 } from "../../../_partials/typography/Header3"
import { HotBookingDays } from "./HotBookingDays"
import { NumberOfBookings } from "./NumberOfBookings"

export function BookingStatistics() {
   return <>
      <Header3 text={"Booking statistics"} />
      <div className="row">
         <div className="col-12 col-xl-7 pt-4">
            <NumberOfBookings />
         </div>
         <div className="col-12 col-xl-5 pt-4">
            <HotBookingDays />
         </div>
      </div>
   </>
}