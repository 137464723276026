import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../../_partials/Card";

export function LocationAccuracy() {
  const { isLoading, floorLocationAccuracy } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorLocationAccuracyLoading,
      floorLocationAccuracy: state.reporting.floorLocationAccuracy,
    }),
    shallowEqual
  );

  return (
    <Card isLoading={isLoading}>
      <CardHeader title='Location Accuracy' subtitle='Average accuracy' />
      <CardBody style={{ height: "380px" }}>
        <Chart
          options={getChartOptions(floorLocationAccuracy)}
          series={floorLocationAccuracy?.series || []}
          type='radialBar'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(options) {
  return {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "LocationAccuracyByFloor",
            columnDelimiter: ",",
            headerCategory: "Floor",
            headerValue: "Accuracy (%)",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "LocationAccuracyByFloor",
          },
          png: {
            filename: "LocationAccuracyByFloor",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display.",
    },
    colors: ["#323389", "#882e8c", "#c7297c", "#f1425f", "#ff713b", "#ffa600"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function(w) {
              return options?.total.toFixed(1) + "%" || 0;
            },
          },
        },
      },
    },
    labels: options?.labels || [],
    legend: {
      show: true,
      position: "bottom",
    },
  };
}
