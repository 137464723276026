import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import Chart from "react-apexcharts";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function OccupancyCustom({ showChart, setShowChart, hide }) {
  const { isLoading, occupancy } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorOccupancyLoading,
      occupancy: state.reporting.floorOccupancy,
    }),
    shallowEqual
  );

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='Occupancy'
        subtitle='Daily number of people'
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChart({ ...showChart, OccupancyCustom: false })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <CardBody style={{ height: "250px" }}>
        <Chart
          options={getChartOptions(occupancy)}
          series={occupancy?.series || []}
          type='line'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(occupancy) {
  return {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "OccupancyByFloor",
            columnDelimiter: ",",
            headerCategory: "Date",
            headerValue: "Value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "OccupancyByFloor",
          },
          png: {
            filename: "OccupancyByFloor",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display.",
    },
    colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
    // Color palette matching colors from u-find purple 6 colors
    // #323389
    // #0058A9
    // #0077B8
    // #0095BA
    // #00B0B3
    // #52C8AB
    plotOptions: {
      bar: {
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: true,
      // ↓ enabledOnSeries: shows only the line chart series total
      enabledOnSeries: occupancy && [occupancy.series.length - 1],
      style: {
        colors: ["#546E7A"],
      },
    },
    labels: occupancy?.labels || [],
    xaxis: {
      //Default xaxis so it doesnt break when new options arrive
      tickPlacement: "between",
      ...occupancy?.xaxis,
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    yaxis: {
      title: {
        text: "People count",
      },
    },
  };
}
