import moment from "moment";

function filterSSIDs(queryParams) {
  if (!queryParams.peopleType) return [];
  let SSIDs = [];
  if (queryParams.peopleType.some((val) => val.value === "visitors")) {
    SSIDs.push("");
  }
  if (queryParams.peopleType.some((val) => val.value === "employees")) {
    queryParams.locations.forEach((l) => {
      if (!l.employeeSSID) return;
      SSIDs = SSIDs.concat(l.employeeSSID);
    });
  }
  if (queryParams.peopleType.includes("guests")) {
    queryParams.locations.forEach((l) => {
      if (!l.guestSSID) return;
      SSIDs = SSIDs.concat(l.guestSSID);
    });
  }
  return SSIDs;
}

function fetchAPI(props) {
  //   console.log("🚀 ~ props", props);
  const {
    typechart,
    locations,
    SSIDs,
    floors,
    zones,
    startDate,
    endDate,
    url,
  } = props;
  var myHeaders = new Headers();
  myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    typechart: typechart,
    networkId: locations,
    ssid: SSIDs,
    floorPlanId: floors || [],
    zones: zones || [],
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  });

  //   console.log(raw, "raw");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`,
    requestOptions
  ).catch((err) => {
    console.log("Error: ", err);
  });
}

//★━━━━━━━━━━━━━━━━★ Sensors ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getFloorSensorsDistribution(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations2 = queryParams.locations.map((location) => location.netId);
  //   console.log("🚀 ~ locations2", locations2.toString());

  const locations = locations2.toString();
  //!! A API parece quando recebe a array dos vários floors dá um erro no fetch e n recebe o result
  //   const floors = queryParams.floors.map((floor) => floor.floorId);
  const floors = "";

  const SSIDs = filterSSIDs(queryParams);
  if (SSIDs === null || SSIDs.length === 0) {
    return Promise.resolve(null);
  }

  return fetchAPI({
    typechart: "timeseries",
    locations,
    SSIDs,
    floors,
    startDate,
    endDate: queryParams.date,
    url: `/bigquery/reporting/sensors/${queryParams.customerId}`,
  });
}
