import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export function LocationAccuracyCustom({ showChart, setShowChart, hide }) {
  const { isLoading, floorLocationAccuracy } = useSelector(
    (state) => ({
      isLoading: state.reporting.floorLocationAccuracyLoading,
      floorLocationAccuracy: state.reporting.floorLocationAccuracy,
    }),
    shallowEqual
  );

  return (
    <Card isLoading={isLoading}>
      <CardHeader
        title='Location Accuracy'
        subtitle='Average accuracy'
        toolbar={
          <>
            <IconButton
              size='small'
              style={{
                top: "-2rem",
                left: "0.5rem",
                display: hide ? "none" : "block",
              }}
              onClick={() =>
                setShowChart({ ...showChart, LocationAccuracyCustom: false })
              }
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <CardBody style={{ height: "250px" }}>
        <Chart
          options={getChartOptions(floorLocationAccuracy)}
          series={floorLocationAccuracy?.series || []}
          type='radialBar'
          height='100%'
        />
      </CardBody>
    </Card>
  );
}

function getChartOptions(options) {
  return {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: "LocationAccuracyByFloor",
            columnDelimiter: ",",
            headerCategory: "Floor",
            headerValue: "Accuracy (%)",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "LocationAccuracyByFloor",
          },
          png: {
            filename: "LocationAccuracyByFloor",
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No data to display.",
    },
    colors: ["#323389", "#882e8c", "#c7297c", "#f1425f", "#ff713b", "#ffa600"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function(w) {
              return options?.total.toFixed(1) + "%" || 0;
            },
          },
        },
      },
    },
    labels: options?.labels || [],
    track: true,
  };
}
