import moment from "moment";
import React from "react";
import { fetchAPI } from "../WifiSnapshotHelpers/WifiSnapshotHelpers";
import "../WifiSnapshotReporting.css";
import Chart from "react-apexcharts";

export const SNRFloorsChart = async (queryParams) => {
  //   console.log(queryParams, "SNRFloorsChart queryParams");

  const netFailuresSeries = [];
  const netFailuresData = await getFloorNetFailuresPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 2");
      const netFailuresParsed = netFailuresFloorsToGraphData({
        result,
        queryParams,
      });
      // .then((res) => {
      //   console.log("🚀 ~ res", res);
      //   netFailuresSeries.push(res);
      // });
      return netFailuresParsed;
    });

  netFailuresSeries.push(netFailuresData);

  return getFloorSNRPerDay(queryParams)
    .then((response) => response && response.json())
    .then((result) => {
      // console.log(result, "result 1");

      const data = badSNRFloorsToGraphData({
        result,
        queryParams,
      });

      // console.log(netFailuresSeries, "netFailuresSeries 2");
      // console.log(data, "data SNRFloorsChart");

      let options = {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 6,
        },
        colors: [
          "#323389",
          "#f25d24",
          "#0077B8",
          "#0095BA",
          "#00B0B3",
          "#52C8AB",
        ],

        noData: {
          text: "Loading...",
        },
      };

      if (!data) {
        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart options={options} series={[]} type='line' height='100%' />
          </div>
        );
        return mainDiv;
      } else {
        const minXAxis = data.series[0].data.every((val) => val < 2);
        // console.log("🚀 ~ minXAxis", minXAxis);

        const xAxisDay = {
            type: "category",
            categories: [
              "00h",
              "01h",
              "02h",
              "03h",
              "04h",
              "05h",
              "06h",
              "07h",
              "08h",
              "09h",
              "10h",
              "11h",
              "12h",
              "13h",
              "14h",
              "15h",
              "16h",
              "17h",
              "18h",
              "19h",
              "20h",
              "21h",
              "22h",
              "23h",
            ],
            tickAmount: 5,
            labels: {
              rotate: 0,
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
            colors: [
              "#323389",
              "#f25d24",
              "#0077B8",
              "#0095BA",
              "#00B0B3",
              "#52C8AB",
            ],
          },
          options =
            queryParams.timeframe === "day"
              ? {
                  // ...options,
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  labels: data?.labels || [],
                  xaxis: xAxisDay,
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function(
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return value;
                      },
                    },
                  },
                  colors: [
                    "#323389",
                    "#f25d24",
                    "#0077B8",
                    "#0095BA",
                    "#00B0B3",
                    "#52C8AB",
                  ],
                  yaxis: {
                    show: true,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  noData: {
                    text: "No data to display.",
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 1350,
                  //     // breakpoint: 680,
                  //     options: {
                  //       xaxis: {
                  //         overwriteCategories: [
                  //           "00:00",
                  //           "04:00",
                  //           "08:00",
                  //           "12:00",
                  //           "16:00",
                  //           "20:00",
                  //         ],
                  //         labels: {
                  //           show: true,
                  //           hideOverlappingLabels: true,
                  //         },
                  //       },
                  //     },
                  //   },
                  // ],
                }
              : !minXAxis
              ? {
                  // ...options,
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [
                    "#323389",
                    "#f25d24",
                    "#0077B8",
                    "#0095BA",
                    "#00B0B3",
                    "#52C8AB",
                  ],
                  labels: data?.labels || [],
                  xaxis: {
                    type: "category",
                    categories: data?.labels || [],
                    // tickPlacement: "between", // This gives margins between xAxis
                    labels: {
                      style: {
                        colors: data.color,
                      },
                    },
                  },
                  markers: {
                    size: queryParams.timeframe === "day" ? 10 : null,
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function(
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return value;
                      },
                    },
                  },
                  yaxis: {
                    show: true,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  noData: {
                    text: "No data to display.",
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 1350,
                  //     options: {},
                  //   },
                  // ],
                }
              : queryParams.timeframe === "week" || "month"
              ? {
                  // ...options,
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [
                    "#323389",
                    "#f25d24",
                    "#0077B8",
                    "#0095BA",
                    "#00B0B3",
                    "#52C8AB",
                  ],
                  labels: data?.labels || [],
                  xaxis: {
                    type: "category",
                    categories: data?.labels || [],
                    // tickPlacement: "between", // This gives margins between xAxis
                    labels: {
                      style: {
                        colors: data.color,
                      },
                    },
                  },
                  markers: {
                    size: queryParams.timeframe === "day" ? 10 : null,
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function(
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return value;
                      },
                    },
                  },
                  yaxis: {
                    show: true,
                    min: 0,
                    max: 10,
                    tickAmount: 5,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  noData: {
                    text: "No data to display.",
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 1350,
                  //     options: {},
                  //   },
                  // ],
                }
              : {
                  // ...options,
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [
                    "#323389",
                    "#f25d24",
                    "#0077B8",
                    "#0095BA",
                    "#00B0B3",
                    "#52C8AB",
                  ],
                  labels: data?.labels || [],
                  xaxis: {
                    type: "category",
                    categories: data?.labels || [],
                    // tickPlacement: "between", // This gives margins between xAxis
                    labels: {
                      style: {
                        colors: data.color,
                      },
                    },
                  },
                  markers: {
                    size: queryParams.timeframe === "day" ? 10 : null,
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function(
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return value;
                      },
                    },
                  },
                  yaxis: {
                    show: true,
                    min: 0,
                    max: 10,
                    tickAmount: 5,
                    labels: {
                      formatter: function(val, index) {
                        return val.toFixed();
                      },
                    },
                  },
                  noData: {
                    text: "No data to display.",
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 1350,
                  //     options: {},
                  //   },
                  // ],
                };

        // console.log(data, "data");
        // console.log("🚀 ~ data.series[0]", data.series[0]);
        // console.log(
        //   "🚀 ~ netFailuresSeries[0].series[0]",
        //   netFailuresSeries[0].series[0]
        // );

        const finalSeries = [];
        finalSeries.push(data.series[0], netFailuresSeries[0].series[0]);

        // console.log("🚀 ~ finalSeries", finalSeries);

        const mainDiv = (
          <div style={{ minHeight: "350px" }}>
            <Chart
              options={options}
              series={finalSeries || []}
              // series={data.series || []}
              type='line'
              height='100%'
            />
          </div>
        );
        return mainDiv;
      }
    })
    .catch((error) => {
      console.log("Fetch SNR bad global", error);
      error.clientMessage = "Can't fetch SNR global";
    });
};

// API fetch SNR
function getFloorSNRPerDay(queryParams) {
  // console.log("🚀 ~ queryParams getFloorSNRPerDay", queryParams);

  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  if (queryParams.timeframe === "day") {
    return fetchAPI({
      typechart: "floorsHour",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/snr/${queryParams.customerId}`,
    });
  } else {
    return fetchAPI({
      typechart: "floorsDay",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/snr/${queryParams.customerId}`,
    });
  }
}

// API fetch NetFailures
async function getFloorNetFailuresPerDay(queryParams) {
  if (!queryParams?.locations || !queryParams?.date)
    return Promise.resolve(null);

  var startDate = moment(queryParams.date);
  if (queryParams.timeframe === "week") startDate.subtract(6, "days");
  if (queryParams.timeframe === "month") startDate.subtract(1, "months");

  const locations = queryParams.locations.map((location) => location.netId);
  const floors = queryParams.levels.map((floor) => floor.floorId);

  if (queryParams.timeframe === "day") {
    return fetchAPI({
      typechart: "floorsHour",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/netFailures/${queryParams.customerId}`,
    });
  } else {
    return fetchAPI({
      typechart: "floorsDay",
      locations,
      SSIDs: queryParams.SSIDs,
      floors,
      startDate,
      endDate: queryParams.date,
      url: `/reporting/netFailures/${queryParams.customerId}`,
    });
  }
}

// Parse result NetFailures
export const netFailuresFloorsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  // console.log("🚀 ~ result 3", result);

  const data = [];
  const mondaysTotal = [];
  const tuesdaysTotal = [];
  const wednesdaysTotal = [];
  const thursdaysTotal = [];
  const fridaysTotal = [];
  const saturdaysTotal = [];
  const sundaysTotal = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );

    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Mon")) {
      mondaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Tue")) {
      tuesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Wed")) {
      wednesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Thu")) {
      thursdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Fri")) {
      fridaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Sat")) {
      saturdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Sun")) {
      sundaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.failures ? val.failures.toFixed(2) * 1 : 0,
      });
    }
  });

  // console.log("🚀 ~ data", data);

  const newData = sumFloors(data);
  // console.log("🚀 ~ newData", newData);

  let dates = newData.map((item) => item.date);
  // console.log("🚀 ~ dates", dates);

  const series = [
    {
      name: "Net Failures",
      data: newData.map((d) => d.count),
    },
  ];
  // console.log("🚀 ~ series", series);

  let color = "#626768";
  dates.forEach((val) => {
    if (moment(val).format("dd") === "Su") {
      color = "#F9CE1D";
    }
    if (moment(val).format("dd") === "Sa") {
      color = "#2ec4b6";
    }
  });

  const formattedDates = dates.map((val) => {
    return moment(val).format("ddd MMM D");
  });

  if (queryParams.timeframe === "day") {
    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "week") {
    let weekColors = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        weekColors.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        weekColors.push("#2ec4b6");
      } else {
        weekColors.push("#626768");
      }
    });

    return {
      labels: formattedDates,
      series: series,
      color: weekColors,
    };
  }

  if (queryParams.timeframe === "month") {
    const monthAverage = [];
    const mondayAvg = getAvgCount(mondaysTotal);
    monthAverage.push(mondayAvg.toFixed(2) * 1);
    const tuesdayAvg = getAvgCount(tuesdaysTotal);
    monthAverage.push(tuesdayAvg.toFixed(2) * 1);
    const wednesdayAvg = getAvgCount(wednesdaysTotal);
    monthAverage.push(wednesdayAvg.toFixed(2) * 1);
    const thursdayAvg = getAvgCount(thursdaysTotal);
    monthAverage.push(thursdayAvg.toFixed(2) * 1);
    const fridayAvg = getAvgCount(fridaysTotal);
    monthAverage.push(fridayAvg.toFixed(2) * 1);
    const saturdayAvg = getAvgCount(saturdaysTotal);
    monthAverage.push(saturdayAvg.toFixed(2) * 1);
    const sundayAvg = getAvgCount(sundaysTotal);
    monthAverage.push(sundayAvg.toFixed(2) * 1);

    // console.log("🚀 ~ monthAverage", monthAverage);

    const series = [
      {
        name: "Net Failures",
        data: monthAverage,
      },
    ];
    // console.log("🚀 ~ series", series);

    let weekColors = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        weekColors.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        weekColors.push("#2ec4b6");
      } else {
        weekColors.push("#626768");
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: series,
      color: [
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#2ec4b6",
        "#F9CE1D",
      ],
    };
  }
};

// Parse result SNR
export const badSNRFloorsToGraphData = ({ result, queryParams }) => {
  if (!result) return;

  // console.log("🚀 ~ result", result);

  const data = [];
  const mondaysTotal = [];
  const tuesdaysTotal = [];
  const wednesdaysTotal = [];
  const thursdaysTotal = [];
  const fridaysTotal = [];
  const saturdaysTotal = [];
  const sundaysTotal = [];

  result.forEach((val) => {
    const floor = queryParams.levels.find(
      (floor) => floor.floorId === val.floorPlanId
    );
    const location = queryParams.locations.find(
      (loc) => loc.netId === val.networkId
    );

    if (val && val.date) {
      data.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Mon")) {
      mondaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Tue")) {
      tuesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Wed")) {
      wednesdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Thu")) {
      thursdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Fri")) {
      fridaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Sat")) {
      saturdaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
    if (val.date.includes("Sun")) {
      sundaysTotal.push({
        location: location?.netName ? location.netName : "",
        floorPlan: floor?.name ? floor.name : "",
        date: val.date,
        count: val.avgsnr ? val.avgsnr.toFixed(2) * 1 : 0,
      });
    }
  });

  // console.log("🚀 ~ data", data);

  const newData = sumFloors(data);
  // console.log("🚀 ~ newData", newData);

  let dates = newData.map((item) => item.date);
  // console.log("🚀 ~ dates", dates);

  const series = [
    {
      name: "SNR",
      data: newData.map((d) => d.count),
    },
  ];
  // console.log("🚀 ~ series", series);

  let color = "#626768";
  dates.forEach((val) => {
    if (moment(val).format("dd") === "Su") {
      color = "#F9CE1D";
    }
    if (moment(val).format("dd") === "Sa") {
      color = "#2ec4b6";
    }
  });

  const formattedDates = dates.map((val) => {
    return moment(val).format("ddd MMM D");
  });

  if (queryParams.timeframe === "day") {
    return {
      labels: formattedDates,
      series: series,
      color: color,
    };
  }

  if (queryParams.timeframe === "week") {
    let weekColors = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        weekColors.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        weekColors.push("#2ec4b6");
      } else {
        weekColors.push("#626768");
      }
    });

    return {
      labels: formattedDates,
      series: series,
      color: weekColors,
    };
  }

  if (queryParams.timeframe === "month") {
    const monthAverage = [];
    const mondayAvg = getAvgCount(mondaysTotal);
    monthAverage.push(mondayAvg.toFixed(2) * 1);
    const tuesdayAvg = getAvgCount(tuesdaysTotal);
    monthAverage.push(tuesdayAvg.toFixed(2) * 1);
    const wednesdayAvg = getAvgCount(wednesdaysTotal);
    monthAverage.push(wednesdayAvg.toFixed(2) * 1);
    const thursdayAvg = getAvgCount(thursdaysTotal);
    monthAverage.push(thursdayAvg.toFixed(2) * 1);
    const fridayAvg = getAvgCount(fridaysTotal);
    monthAverage.push(fridayAvg.toFixed(2) * 1);
    const saturdayAvg = getAvgCount(saturdaysTotal);
    monthAverage.push(saturdayAvg.toFixed(2) * 1);
    const sundayAvg = getAvgCount(sundaysTotal);
    monthAverage.push(sundayAvg.toFixed(2) * 1);

    // console.log("🚀 ~ monthAverage", monthAverage);

    const series = [
      {
        name: "SNR",
        data: monthAverage,
      },
    ];
    // console.log("🚀 ~ series", series);

    let weekColors = [];
    dates.forEach((val) => {
      if (moment(val).format("dd") === "Su") {
        weekColors.push("#F9CE1D");
      } else if (moment(val).format("dd") === "Sa") {
        weekColors.push("#2ec4b6");
      } else {
        weekColors.push("#626768");
      }
    });

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: series,
      color: [
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#626768",
        "#2ec4b6",
        "#F9CE1D",
      ],
    };
  }
};

// function sumProperty(array) {
//   const sum = array.reduce((accumulator, object) => {
//     return accumulator + object.count;
//   }, 0);
//   return sum;
// }

const getAvgCount = (array) => {
  if (!array) {
    return null;
  } else {
    const total = array.reduce((acc, c) => acc + c.count, 0);
    const average = total / array.length;
    return average;
  }
};

const sumFloors = (array) => {
  let holderOne = [];
  array.forEach(function(d) {
    if (holderOne.some((val) => val.date === d.date)) {
      const index = holderOne.findIndex((val) => val.date === d.date);

      holderOne[index] = {
        ...holderOne[index],
        count: holderOne[index].count + d.count,
        counter: holderOne[index].counter + 1,
      };
    } else {
      holderOne.push({
        date: d.date,
        location: d.location,
        count: d.count,
        counter: 1,
      });
    }
  });
  return holderOne.map((val, i) => {
    return {
      date: val.date,
      location: val.location,
      count: (val.count / val.counter).toFixed(2) * 1,
    };
  });
};
